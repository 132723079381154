import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//
import { MdOutlineSave } from 'react-icons/md';
//import country button
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//icons
import Account from '../countries/sellerbuyericon/account_icon.png';
//
import GobackBouton from '../reusablecomponents/buttons/GobackBouton';
import ForwardButton from '../reusablecomponents/buttons/ForwardButton';
//
import { HideFooter, AlertModal, getCurrentDate, addExpiredDate } from '../../helperfunctions/Helper';

//
import { retrieveTableQuery, retrieveSellDaysQuery } from '../../helperfunctions/utilityfunctions/CountryUtils';
import Axios from 'axios';
import apiUrl from '../../apiconfig/ApiConfig';

//
import {  
    FooterSubTitle,
    LargeInput,
    TextLogin, 
    HandlerContainerDiv, 
    ModalUser,
    ModalUserSpecial,
    SmallHandlerContainerDiv,
    SmallSaveButton,
} from '../ComponentStyles';

function SpecialSubscribe() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertspecialsubscribe";
    const elementDropId = "backdropoutalertspecialsubscribe";
    const buttonHandler = "ouiHandlerAlertSpecialSubscribeBtn";

    // Define state variables for modals
    const [startSpecialSubscribeModalOpen, setStartSpecialSubscribeModalOpen] = useState(true);
    const [specialSubscribeModalOpen, setSpecialSubscribeModalOpen] = useState(false);
    const [specialSubscribe, setSpecialSubscribe] = useState(false);
    //
    const [specialSubscribeDetail, setSpecialSubscribeDetail] = useState([0]);
    //setting input variables
    const [dateStartInput, setDateStartInput] = useState([0]);
    const [dateEndInput, setDateEndInput] = useState([0]);

    const userCodeRef = useRef(null);
    const dateStartRef = useRef(null);
    const dateEndRef = useRef(null);

    const navigateSpecialSubscribe = useNavigate();

    const handleStartSubscribeSpecial = async () => {

        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const dateStart = getCurrentDate();
        const planPinCode = "advert";
        var numberDays = 0;
        numberDays = +(retrieveSellDaysQuery(codecountryDiv, planPinCode));

        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }

        const firstWhere = "code_user";

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableName,
            firstWhere:firstWhere,
            firstParameter:codeutilisateurDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setSpecialSubscribeDetail(response1.data);
                //set date start and date end
                setDateStartInput(dateStart);
                //calculate date end
                var expiredDate = addExpiredDate(dateStart, numberDays);
                setDateEndInput(expiredDate);
                //
                setStartSpecialSubscribeModalOpen(false);
                setSpecialSubscribeModalOpen(true);
                setSpecialSubscribe(true);
            }
        });
    }

    const handleSaveSpecialSubscribe = async () => {

        var tableName = "";
        var signupTable = [];

        const userCode = userCodeRef.current.value;
        const planPincode = "advert";
        const referencePincode = "";
        const numPincode = "";
        const dateStart = dateStartRef.current.value;
        const dateEnd = dateEndRef.current.value;
        const codeReseller = "";
        const imageAdvertise = "";
        const titleAdvertise = "";
        const contentAdvertise = "";
        const linkAdvertise = "";
        const dateAdvertise = getCurrentDate();

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table15;
        }

        await Axios.post(`${apiUrl}/event/special/subscribe`, {
            tableName:tableName,
            userCode:userCode,
            codePays:codecountryDiv,
            planPincode:planPincode,
            referencePincode:referencePincode,
            numPincode:numPincode,
            dateStart:dateStart,
            dateEnd:dateEnd,
            codeReseller:codeReseller,
            imageAdvertise:imageAdvertise,
            titleAdvertise:titleAdvertise,
            contentAdvertise:contentAdvertise,
            linkAdvertise:linkAdvertise,
            dateAdvertise:dateAdvertise,
        }).then(() => {
            navigateSpecialSubscribe('/dashboardseller');
        });
    }

    const handleGoBack = async () => {

        HideFooter();
        navigateSpecialSubscribe(-1);
    }

    const goNextHandler = async () => {

        navigateSpecialSubscribe('/dashboardseller');
    }

    return (
        <div id='specialsubscribe'>
            <ModalUserSpecial>
                <br/>
                <FooterSubTitle>{t("subscribe_special")}</FooterSubTitle>
                <br/>
                {startSpecialSubscribeModalOpen && (
                    <HandlerContainerDiv>
                        <CountryButton 
                            url={Account}
                            countryName={t("start_subscribe")}
                            onConfirm={handleStartSubscribeSpecial}
                        />
                    </HandlerContainerDiv>
                )}

                {specialSubscribeModalOpen && (
                    <div>
                        {specialSubscribeDetail.map((valdetail, indexdetail) => (
                            <ModalUser key={indexdetail}>
                                <TextLogin>{t("user_code")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <LargeInput type='text' ref={userCodeRef} defaultValue={valdetail.code_user} readOnly />
                                </div>
                                <br/>
                                <TextLogin>{t("plan_pincode")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <LargeInput type='text' value={t("type_plan_event")} readOnly />
                                </div>
                                <br/>
                                <TextLogin>{t("date_start")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <LargeInput type='text' ref={dateStartRef} value={dateStartInput} readOnly />
                                </div>
                                <br/>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <LargeInput type='text' ref={dateEndRef} value={dateEndInput} readOnly />
                                </div>
                                <br/>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/>
                {specialSubscribe && (
                    <div>
                        <SmallHandlerContainerDiv>
                            <div>
                                <SmallSaveButton onClick={handleSaveSpecialSubscribe}>
                                    <span style={{ fontSize:'30px'}}><MdOutlineSave /></span>
                                    {t("create")}
                                </SmallSaveButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>
                )}

                <br/>
                <HandlerContainerDiv>
                    <div>
                        <GobackBouton onClick={handleGoBack} ></GobackBouton>
                    </div>
                    <div>
                        <ForwardButton onClick={goNextHandler}></ForwardButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
                <div id="modaloutalertspecialsubscribe"></div>
                <div id="backdropoutalertspecialsubscribe"></div>
            </ModalUserSpecial>
        </div>
    )
}

export default SpecialSubscribe
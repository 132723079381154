import React from 'react';
import { VscDebugContinueSmall } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
//
import { GoNextButton, GoNextText } from '../ReusableStyles';

function ContinueButton({onClick}) {
    const {t} = useTranslation();

    const navigateContinue = useNavigate();

    const goNext = () => {
        if (onClick && typeof onClick === 'function') {
            onClick(); // Call the provided onClick function if available
        } else {
            navigateContinue('/'); // Default behavior: go back in history
        }
    };
    return (
        <GoNextButton onClick={goNext}>
            <GoNextText>{t("continuer")}</GoNextText>
            <VscDebugContinueSmall style={{fontSize:'30px'}} />
        </GoNextButton>
    )
}

export default ContinueButton
import React from 'react';
import { ModalBackDrop, ModalMultiContainer, ModalText, ModalConfirmButton, ModalPauseButton, ModalCancelButton } from '../../ComponentStyles';

function WarningMultiModal({ text, textOk, textPause, textNo, onConfirm, onPause, onCancel }) {
    return (
        <div>
            <ModalBackDrop>
                <ModalMultiContainer>
                    <ModalText>{text}</ModalText>
                    <ModalConfirmButton onClick={onConfirm}>
                        {textOk}
                    </ModalConfirmButton>
                    <ModalPauseButton onClick={onPause}>
                        {textPause}
                    </ModalPauseButton>
                    <br/><br/>
                    <ModalCancelButton onClick={onCancel}>{textNo}</ModalCancelButton>
                </ModalMultiContainer>
            </ModalBackDrop>
        </div>
    )
}

export default WarningMultiModal
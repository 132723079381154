import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//helperfunction
import { ShowFooter } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
//
import { MdSendToMobile, MdOutlineAttachEmail } from 'react-icons/md';
import { AiOutlineWhatsApp } from 'react-icons/ai';
//
import { 
    TermeScroller, 
    FooterSubTitle, 
    HandlerContainerDiv,
    AnimatedComponent,
    ContactLinkDiv,
} from '../ComponentStyles';

function ContactMe() {
    const {t} = useTranslation();

    const navigateContactMe = useNavigate();

    const goBackContactMeHandler = async (e) => {
        ShowFooter();
        navigateContactMe('/');
    }

    return (
        <div id='contactme'>
            <TermeScroller>
                <br/>
                <FooterSubTitle>{t("contactme")}</FooterSubTitle>
                <br/>
                <AnimatedComponent>
                    <br/>
                    {t("contactintro")}
                    <br/>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("contact_1")}</li>
                        <li>{t("contact_2")}</li>
                        <li>{t("contact_3")}</li>
                    </ul>
                    {t("contactconclued_1")}
                    <br/>
                    {t("contactconclued_2")}
                </AnimatedComponent>
                <br/>
                <AnimatedComponent>
                    <ContactLinkDiv>
                        <a href='tel:+447438810727'><MdSendToMobile style={{fontSize:'40px', color:'#63b4cf', cursor:'pointer'}} /></a>
                        <a href='mailto:micarions2023@gmail.com.com'><MdOutlineAttachEmail style={{fontSize:'40px', color:'#63b4cf', cursor:'pointer'}} /></a>
                        <a href='https://api.whatsapp.com/send?phone=++447438810727'><AiOutlineWhatsApp style={{fontSize:'40px', color:'#63b4cf', cursor:'pointer'}} /></a>
                    </ContactLinkDiv>
                </AnimatedComponent>
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackContactMeHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
            </TermeScroller>
        </div>
    )
}

export default ContactMe
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { MdOutlineSave, MdModeEdit, MdDelete, MdPreview } from 'react-icons/md';
//
import CreateEvents from './CreateEvents';
import EditEvents from './EditEvents';
import DeleteEvents from './DeleteEvents';
import ViewEvents from './ViewEvents';

import { 
    FooterSubTitle,
    RightSideButton,
    ModalUser,
    SmallHandlerColContainerDiv,
} from '../../../ComponentStyles';

function CrudEvents() {
    const {t} = useTranslation();

    //div ref
    const crudEventsRef = useRef(null);

    // Define state variables for modals
    const [dashboardCreateEventsModalOpen, setDashboardCreateEventsModalOpen] = useState(false);
    const [dashboardEditEventsModalOpen, setDashboardEditEventsModalOpen] = useState(false);
    const [dashboardDeleteEventsModalOpen, setDashboardDeleteEventsModalOpen] = useState(false);
    const [dashboardViewEventsModalOpen, setDashboardViewEventsModalOpen] = useState(false);

    const handleCrudNewEvents = async (e) => {
        e.preventDefault();

        crudEventsRef.current.style.display = 'none';
        setDashboardCreateEventsModalOpen(true);
        setDashboardEditEventsModalOpen(false);
        setDashboardDeleteEventsModalOpen(false);
        setDashboardViewEventsModalOpen(false);
    }

    const handleCrudEditEvents = async (e) => {
        e.preventDefault();

        crudEventsRef.current.style.display = 'none';
        setDashboardCreateEventsModalOpen(false);
        setDashboardEditEventsModalOpen(true);
        setDashboardDeleteEventsModalOpen(false);
        setDashboardViewEventsModalOpen(false);
    }

    const handleCrudDeleteEvents = async (e) => {
        e.preventDefault();

        crudEventsRef.current.style.display = 'none';
        setDashboardCreateEventsModalOpen(false);
        setDashboardEditEventsModalOpen(false);
        setDashboardDeleteEventsModalOpen(true);
        setDashboardViewEventsModalOpen(false);
    }

    const handleCrudViewEvents = async (e) => {
        e.preventDefault();

        crudEventsRef.current.style.display = 'none';
        setDashboardCreateEventsModalOpen(false);
        setDashboardEditEventsModalOpen(false);
        setDashboardDeleteEventsModalOpen(false);
        setDashboardViewEventsModalOpen(true);
    }

    return (
        <div>
            <ModalUser ref={crudEventsRef}>
                <FooterSubTitle>{t("event_text")}</FooterSubTitle>
                <br/>
                <SmallHandlerColContainerDiv>
                    <div>
                        <RightSideButton onClick={handleCrudNewEvents}>
                            <span style={{fontSize:'30px'}}><MdOutlineSave /></span>
                            {t("dash_new_events")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudEditEvents}>
                            <span style={{fontSize:'30px'}}><MdModeEdit /></span>
                            {t("dash_edit_events")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudDeleteEvents}>
                            <span style={{fontSize:'30px'}}><MdDelete /></span>
                            {t("dash_delete_events")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudViewEvents}>
                            <span style={{fontSize:'30px'}}><MdPreview /></span>
                            {t("dash_view_events")}
                        </RightSideButton>
                    </div>
                </SmallHandlerColContainerDiv>
            </ModalUser>

            {/**create event */}
            {dashboardCreateEventsModalOpen && (
                <CreateEvents />
            )}

            {/**edit event */}
            {dashboardEditEventsModalOpen && (
                <EditEvents />
            )}

            {/**delete event */}
            {dashboardDeleteEventsModalOpen && (
                <DeleteEvents />
            )}

            {/**view event */}
            {dashboardViewEventsModalOpen && (
                <ViewEvents />
            )}
        </div>
    )
}

export default CrudEvents
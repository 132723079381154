import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdModeEdit } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
import { BsUpload } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal, LimitCharacters } from '../../../../helperfunctions/Helper';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallPriceInput,
    SmallCurrencyInput,
    SmallHandlerContainerDiv,
    SmallSaveButton,
    SmallEditButton,
} from '../../../ComponentStyles';

function EditProduct() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalerteditproduct";
    const elementDropId = "backdropoutalerteditproduct";
    const buttonHandler = "ouiHandlerAlertEditProductBtn";

    // Define state variables for modals
    const [startEditProductModalOpen, setStartEditProductModalOpen] = useState(true);
    const [editProductModalOpen, setEditProductModalOpen] = useState(false);

    const idEditProductRef = useRef(null);
    const editProductNameRef = useRef(null);
    const editProductDescriptionRef = useRef(null);
    const editProductPriceRef = useRef(null);
    const editProductCurrencyRef = useRef(null);
    const editProductFileNameRef = useRef(null);
    const editProductImageRef = useRef(null);

    const handleConfirmEditProductRef = useRef(null);
    const editProductRef = useRef(null);

    const [imageEditPreview, setImageEditPreview] = useState(null);

    const [editDetailProduct, setEditDetailProduct] = useState([0]);

    //image sources and resources states
    const [src, setSrc] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    //Limit the total number of characters (not words)
    //var characterLimit = 500; // Set the character limit to 500
    var descriptionWords = 0;
    var newCharacterLimitDescription = 500 - descriptionWords;
    const [textDescription, setTextDescription] = useState('');
    //limit characters for description
    const handleChangeDescription = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, newCharacterLimitDescription);
        setTextDescription(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = newCharacterLimitDescription - textDescription.length;

    const handleStartProduct = async () => {
        
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        var checkResultat = [];

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }

        await Axios.post(`${apiUrl}/edit/product/details`, {
            tableNameSeller:tableName,
            codeUtisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                checkResultat = response1.data;
                //retrieve location words length
                for(let i=0; i<resultat1; i++){
                    descriptionWords = (checkResultat[i].description_product).length;
                }
                setEditDetailProduct(response1.data);
                setStartEditProductModalOpen(false);
                setEditProductModalOpen(true);
                editProductRef.current.style.display = 'block';
            }
        });
    }

    const handleEditImageChange = async (e) => {

        editProductRef.current.style.display = 'none';

        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageEditPreview(reader.result);
            };
            reader.readAsDataURL(file);
            //
            // Transform file into blob URL
            setSrc(URL.createObjectURL(file));
            //
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
        }
    }

    // Helper function to set the subdirectory value
    const setSubDirectory = (value) => {
        Axios.post(`${apiUrl}/set-subdirectory`, {
            tableProduct: value,
        }).then(() => {
            // Log success message or handle response
            const pTextePays = t("image_uplaod");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }).catch(() => {
            // Handle error
            const pTextePays = t("image_failed");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        });
    }

    const handleConfirmEditProduct = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table5; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        editProductRef.current.style.display = 'block';
        handleConfirmEditProductRef.current.style.display = 'none';
    }

    const handleEditProduct = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];

        var tableName = "";
        var signupTable = [];
        //var resultat1 = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table5; //directory to save image in server side
        }
        //
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const idEditProduct = idEditProductRef.current.value;
        const editProductName = editProductNameRef.current.value;
        const editProductDescription = editProductDescriptionRef.current.value;
        const editProductPrice = editProductPriceRef.current.value;
        const editProductCurrency = editProductCurrencyRef.current.value;
        const editProductFileName = editProductFileNameRef.current.value;
        var str = editProductFileName;
        //replace with (.)
        const replaceEditProductFileName = str.replace(`${apiUrl}`, "./uploads");
        const editProductImage = editProductImageRef.current.value;
        //
        if(!codeutilisateurDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editProductName){
            const pTextePays = t("product_name");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!editProductDescription){
            const pTextePays = t("product_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!editProductPrice){
            const pTextePays = t("product_price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editProductCurrency){
            const pTextePays = t("currency_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            if(!editProductImage){
                await Axios.post(`${apiUrl}/edit/seller/product`, {
                    tableName:tableName,
                    editProductName:editProductName,
                    editProductDescription:editProductDescription,
                    editProductPrice:editProductPrice,
                    editProductCurrency:editProductCurrency,
                    editProductFileName:editProductFileName,
                    idEditProduct:idEditProduct,
                }).then(() => {
                    const pTextePays = t("edit_product_success");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                });
            }

            if(editProductImage){
                //upload image and inputs
                const formData = new FormData();

                //inputs
                formData.append("ideditproduct", idEditProduct);
                formData.append("editproductname", editProductName);
                formData.append("editproductdescription", editProductDescription);
                formData.append("editproductprice", editProductPrice);
                formData.append("editproductcurrency", editProductCurrency);
                formData.append("replaceeditproductfilname", replaceEditProductFileName);
                //file
                formData.append("productfile", file);
                formData.append("productfile", fileName);
                //save image in directory belonging
                formData.append("tablename", tableName);
                formData.append("thefile", tableProduct);
                formData.append("apiurl", apiUrl);

                await Axios.post(`${apiUrl}/edit/change/seller/product`, formData).then(() => {
                    const pTextePays = t("edit_product_success");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                });
            }
        }
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_edit_product")}</FooterSubTitle>
                <br/>
                {startEditProductModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartProduct}
                        />
                    </div>
                )}
                <br/>
                {editProductModalOpen && (
                    <div>
                        {editDetailProduct.map((valeditdetail, indexeditdetail) => (
                            <ModalUser key={indexeditdetail}>
                                <input style={{display:'none'}} ref={idEditProductRef} defaultValue={valeditdetail.id_product} />
                                <br/>
                                <SmallTextLogin>{t("product_name")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={editProductNameRef} defaultValue={valeditdetail.name_product} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        value={textDescription || valeditdetail.description_product}
                                        onChange={handleChangeDescription}
                                        ref={editProductDescriptionRef} 
                                        //defaultValue={valeditdetail.description_product} 
                                        maxLength={newCharacterLimitDescription} // Set the maxlength attribute
                                    />
                                    <div>
                                        {remainingCharactersDescription >= 0
                                        ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_price")}</SmallTextLogin>
                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallPriceInput type='number' ref={editProductPriceRef} defaultValue={valeditdetail.price_product}></SmallPriceInput>
                                    </div>
                                    <div>
                                        <SmallCurrencyInput type='text' ref={editProductCurrencyRef} defaultValue={valeditdetail.currency_product} />
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("previous_image")}</SmallTextLogin>
                                <div style={{display:'none',textAlign:'center'}}>
                                    <SmallInput type='text' ref={editProductFileNameRef} defaultValue={valeditdetail.image_product} readOnly />
                                </div>
                        
                                <div src={src} style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                    <img src={valeditdetail.image_product} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                </div>
                                <br/>
                                <div style={{textAlign:'center'}}>
                                    <SmallTextLogin>{t("replace_image")}</SmallTextLogin>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <label htmlFor='upload-btn' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                                            <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                                            {t("upload_image")}
                                        </label>
                                        <SmallInput 
                                            id="upload-btn"
                                            type='file' 
                                            ref={editProductImageRef} 
                                            onChange={handleEditImageChange} 
                                            accept="image/*" 
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                </div>
                                
                                {imageEditPreview && (
                                    <div>
                                        <div /*src={src}*/ style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                            <img src={imageEditPreview} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                        </div>
                                        <SmallHandlerContainerDiv ref={handleConfirmEditProductRef}>
                                            <div>
                                                <SmallSaveButton onClick={handleConfirmEditProduct}>
                                                    <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                                    {t("sellertexte18")}
                                                </SmallSaveButton>
                                            </div>
                                        </SmallHandlerContainerDiv>
                                    </div>  
                                )}
                            </ModalUser>
                        ))}
                    </div>
                )}

                <br/>
                <div ref={editProductRef} style={{display:'none'}}>
                    <SmallHandlerContainerDiv>
                        <div>
                            <SmallEditButton onClick={handleEditProduct}>
                                <span style={{ fontSize:'30px'}}><MdModeEdit /></span>
                                {t("edit")}
                            </SmallEditButton>
                        </div>
                    </SmallHandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalerteditproduct"></div>
                <div id="backdropoutalerteditproduct"></div>
            </ModalUser>
        </div>
    )
}

export default EditProduct
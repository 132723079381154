import React from 'react';
//
import { MdClose } from "react-icons/md";
//
import { 
    CardContainer,
    CardHeader,
    CardImageProduct,
    CardButtonClose,
    CardBody,
    CardTitle,
    CardText,
} from '../../ComponentStyles';

function CardProduct({ url, title, onButtonClickClose, textDescription, textPrice, textCurrency }) {
    return (
        <div>
            <CardContainer>
                <CardHeader>
                    <CardImageProduct>
                        <img src={url} alt='preview' style={{ maxWidth: '200%', maxHeight: '100px', borderRadius: '10px' }} />
                    </CardImageProduct>
                    <CardButtonClose onClick={onButtonClickClose}><MdClose style={{fontSize:'40px'}} /></CardButtonClose>
                </CardHeader>
                <CardBody>
                    <CardTitle>{title}</CardTitle>
                    <CardText>{textDescription}</CardText>
                    <CardText>{textPrice} {textCurrency}</CardText>
                </CardBody>
            </CardContainer>
        </div>
    )
}

export default CardProduct
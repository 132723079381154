import { Suspense } from "react";
import { Layout } from "../layout/Layout";
import { Route, Routes } from 'react-router-dom';
//
//imagecentrale
import ImageCentrale from "../accueil/ImageCentrale";
//import Header from "../accueil/header/Header";
import CountryDetail from "../components/reusablecomponents/userdetails/CountryDetail";
//countries components
import FrenchCountries from "../components/countries/FrenchCountries";
import EnglishCountries from "../components/countries/EnglishCountries";
import PortugueseContries from "../components/countries/PortugueseContries";
//
//import Footer from '../components/footer/Footer';
//about
import About from "../components/footer/About";
//terms
import Terms from "../components/footer/Terms";
//feedback
import Feedback from "../components/footer/Feedback";
//contactme
import ContactMe from "../components/footer/ContactMe";
//faq
import Faq from "../components/footer/Faq";
//privacy
import Privacy from "../components/footer/Privacy";
//starting
import Starting from "../components/starting/Starting";
//------buyer--------
//startingbuyer
import StartingBuyer from "../components/buyer/StartingBuyer";
//------seller--------
//startingseller
import StartingSeller from "../components/seller/StartingSeller";
//nosubscribebuyer
import NoSubscribeBuyer from "../components/buyer/NoSubscribeBuyer";
//userauthentication
//userlogin
import UserLogin from "../components/userauthentication/UserLogin";
//signupbuyer
import SignUpBuyer from "../components/userauthentication/SignUpBuyer";
//subscribebuyer
import SubscribeBuyer from "../components/buyer/SubscribeBuyer";
//signupseller
import SignUpSeller from "../components/userauthentication/SignUpSeller";
//sellerlogin
import SellerLogin from "../components/userauthentication/SellerLogin";
//sellersubscribe
import SellerSubscribe from "../components/userauthentication/SellerSubscribe";
//eventsubscribe
import EventSubscribe from "../components/userauthentication/EventSubscribe";
//specialsubscribe
import SpecialSubscribe from "../components/userauthentication/SpecialSubscribe";
//dashboardseller
import DashboardSeller from "../components/seller/dashboardseller/DashboardSeller";
//product
//import Product from "../components/seller/dashboardseller/dashproduct/Product";
//FooterStarting
//event
import FooterEvent from "../components/footer/FooterEvent";
//promotion
import FooterPromotion from "../components/footer/FooterPromotion";
//application
import FooterApplication from "../components/footer/FooterApplication";

function Home(){
    return(
        <>
            <Layout>
               {/*<Header />*/}
                <CountryDetail />
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<ImageCentrale />} />
                        <Route path="/frenchcountries" element={<FrenchCountries />} />
                        <Route path="/englishcountries" element={<EnglishCountries />} />
                        <Route path="/portuguesecountries" element={<PortugueseContries />} />

                        <Route path="/about" element={<About />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/feedback" element={<Feedback />} />
                        <Route path="/contactme" element={<ContactMe />} />
                        <Route path="/faq" element={<Faq />} />
                        <Route path="/privacy" element={<Privacy />} />

                        <Route path="/starting" element={<Starting />} />
                        {/**startingbuyer */}
                        <Route path="/startingbuyer" element={<StartingBuyer />} />
                        <Route path="/nosubscribebuyer" element={<NoSubscribeBuyer />} />

                        {/**startingseller */}
                        <Route path="/startingseller" element={<StartingSeller />} />
                        {/**userauthentication */}
                        <Route path="/userlogin" element={<UserLogin />} />
                        <Route path="/signupbuyer" element={<SignUpBuyer />} />
                        {/**subscribebuyer */}
                        <Route path="/subscribebuyer" element={<SubscribeBuyer />} />
                        {/**eventsubscribe */}
                        <Route path="/eventsubscribe" element={<EventSubscribe />} />
                        {/**specialsubscribe */}
                        <Route path="/specialsubscribe" element={<SpecialSubscribe />} />
                        {/**signupseller */}
                        <Route path="/signupseller" element={<SignUpSeller />} />
                        {/**sellerlogin */}
                        <Route path="/sellerlogin" element={<SellerLogin />} />
                        {/**seller subscribe */}
                        <Route path="/sellersubscribe" element={<SellerSubscribe />} />
                        
                        {/**Dashboard seller */}
                        <Route path="/dashboardseller" element={<DashboardSeller />} />
                        {/**product */}
                        {/*<Route path="/product" element={<Product />} />*/}
                        {/**FooterEvent */}
                        <Route path="/footerevent" element={<FooterEvent />} />
                        {/**FooterPromotion */}
                        <Route path="/footerpromotion" element={<FooterPromotion />} />
                        {/**FooterApplication */}
                        <Route path="/footerapplication" element={<FooterApplication />} />
                    </Routes>
                </Suspense>
                {/*<Footer />*/}
            </Layout>
        </>
    )
}

export default Home;
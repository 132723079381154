import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background: lightblue;
    color: white;
    top:0;
    position: fixed;
`;

export const HeaderContainerDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    top:80px;
    position: absolute;

    @media screen and (max-width: 640px){
        justify-content: space-around;
    }

    @media screen and (max-width: 768px){
        justify-content: space-around;
    }
`;

export const HeaderContainerHeaderDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    top:110px;
    position: absolute;

    @media screen and (max-width: 640px){
        justify-content: space-around;
    }

    @media screen and (max-width: 768px){
        justify-content: space-around;
    }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  span {
    font-size: 24px;
    font-weight: bold;
  }
`;

export const Year = styled.h5`
  font-size: 18px;
`;

export const LanguageSelectBox = styled.select`
  width: 150;
  height: 40px;
  font-size: 18px;
  padding: 8px 16px;
  border: none;
  border-bottom: 2px solid #3366ff;
  background-color: lightblue;
  color: #246175;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color 0.3s;
`;

export const LanguageOption = styled.option``;

export const ImagePrincipaleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ImagePrincipaleDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImgCentrale = styled.img`
    height: 30vh;
    width: 30vh;
`;
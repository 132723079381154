import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { MdOutlineStart, MdOutlineModeStandby, MdWorkspacePremium, MdOutlineFolderSpecial } from 'react-icons/md';
import { GiPodiumThird } from 'react-icons/gi';
//
import BasicPlan from './BasicPlan';
import StandardPlan from './StandardPlan';
import PremiumPlan from './PremiumPlan';
import SpecialEventPlan from './SpecialEventPlan';
import ThirdPartyPlan from './ThirdPartyPlan';

import { 
    FooterSubTitle,
    RightSideButton,
    ModalUser,
    SmallHandlerColContainerDiv,
} from '../../../ComponentStyles';

function CrudPlan() {
    const {t} = useTranslation();

    //div ref
    const crudPlanRef = useRef(null);

    // Define state variables for modals
    const [dashBasicPlanModalOpen, setDashBasicPlanModalOpen] = useState(false);
    const [dashStandardPlanModalOpen, setDashStandardPlanModalOpen] = useState(false);
    const [dashPremiumPlanModalOpen, setDashPremiumPlanModalOpen] = useState(false);
    const [dashEventPlanModalOpen, setDashEventPlanModalOpen] = useState(false);
    const [dashThirdPlanModalOpen, setDashThirdPlanModalOpen] = useState(false);

    const handleCrudBasicPlan = async (e) => {
        e.preventDefault();

        crudPlanRef.current.style.display = 'none';
        setDashBasicPlanModalOpen(true);
        setDashStandardPlanModalOpen(false);
        setDashPremiumPlanModalOpen(false);
        setDashEventPlanModalOpen(false);
        setDashThirdPlanModalOpen(false);
    }

    const handleCrudStandardPlan = async (e) => {
        e.preventDefault();

        crudPlanRef.current.style.display = 'none';
        setDashBasicPlanModalOpen(false);
        setDashStandardPlanModalOpen(true);
        setDashPremiumPlanModalOpen(false);
        setDashEventPlanModalOpen(false);
        setDashThirdPlanModalOpen(false);
    }

    const handleCrudPremiumPlan = async (e) => {
        e.preventDefault();

        crudPlanRef.current.style.display = 'none';
        setDashBasicPlanModalOpen(false);
        setDashStandardPlanModalOpen(false);
        setDashPremiumPlanModalOpen(true);
        setDashEventPlanModalOpen(false);
        setDashThirdPlanModalOpen(false);
    }

    const handleCrudSpecialEventPlan = async (e) => {
        e.preventDefault();

        crudPlanRef.current.style.display = 'none';
        setDashBasicPlanModalOpen(false);
        setDashStandardPlanModalOpen(false);
        setDashPremiumPlanModalOpen(false);
        setDashEventPlanModalOpen(true);
        setDashThirdPlanModalOpen(false);
    }

    const handleCrudThirdPartyPlan = async (e) => {
        e.preventDefault();

        crudPlanRef.current.style.display = 'none';
        setDashBasicPlanModalOpen(false);
        setDashStandardPlanModalOpen(false);
        setDashPremiumPlanModalOpen(false);
        setDashEventPlanModalOpen(false);
        setDashThirdPlanModalOpen(true);
    }

    return (
        <div>
            <ModalUser ref={crudPlanRef}>
                <FooterSubTitle>{t("dash_plan")}</FooterSubTitle>
                <br/>
                <SmallHandlerColContainerDiv>
                    <div>
                        <RightSideButton onClick={handleCrudBasicPlan}>
                            <span style={{fontSize:'30px'}}><MdOutlineStart /></span>
                            {t("dash_basic_plan")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudStandardPlan}>
                            <span style={{fontSize:'30px'}}><MdOutlineModeStandby /></span>
                            {t("dash_standard_plan")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudPremiumPlan}>
                            <span style={{fontSize:'30px'}}><MdWorkspacePremium /></span>
                            {t("dash_premium_plan")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudSpecialEventPlan}>
                            <span style={{fontSize:'30px'}}><MdOutlineFolderSpecial /></span>
                            {t("dash_special_event")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudThirdPartyPlan}>
                            <span style={{fontSize:'30px'}}><GiPodiumThird /></span>
                            {t("dash_third_party")}
                        </RightSideButton>
                    </div>
                </SmallHandlerColContainerDiv>
            </ModalUser>

            {/**Basic plan */}
            {dashBasicPlanModalOpen && (
                <BasicPlan />
            )}

            {/**Standard plan */}
            {dashStandardPlanModalOpen && (
                <StandardPlan />
            )}

            {/**Premium plan */}
            {dashPremiumPlanModalOpen && (
                <PremiumPlan />
            )}

            {/**Special Event plan */}
            {dashEventPlanModalOpen && (
                <SpecialEventPlan />
            )}

            {/**Third Party plan */}
            {dashThirdPlanModalOpen && (
                <ThirdPartyPlan />
            )}
        </div>
    )
}

export default CrudPlan
import React from 'react';
import { ModalBackDrop, ModalContainer, ModalText, ModalCancelButton} from '../../ComponentStyles';

function WarningSingleModal({ text, textNo, onCancel }) {
    return (
        <div>
            <ModalBackDrop>
                <ModalContainer>
                    <ModalText>{text}</ModalText>
                    <ModalCancelButton onClick={onCancel}>{textNo}</ModalCancelButton>
                </ModalContainer>
            </ModalBackDrop>
        </div>
    )
}

export default WarningSingleModal
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//import country button
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//country flags
import Capvert from '../countries/countryflag/capvert_flag.png';
import GuineeBissau from '../countries/countryflag/guineebissau_flag.png';
//
import { retrieveCodeAndName } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { HideFooter, AlertModal } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
import PopUpForwardButton from '../reusablecomponents/buttons/PopUpForwardButton';
//
import { 
    PrincipalScroller,
    //PrincipalIntro, 
    PrincipalSubTitle,
    //TextLogin, 
    //LargeSelect, 
    HandlerContainerDiv,
} from '../ComponentStyles';

function PortugueseContries() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertportuguesecountries";
    const elementDropId = "backdropoutalertportuguesecountries";
    const buttonHandler = "ouiHandlerAlertportugueseCountriesBtn";

    const navigatePortugueseCountries = useNavigate();

    //cap vert
    const handleCapvert = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const portugueseCountry = "capvert";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = portugueseCountry;
        countryInfo = retrieveCodeAndName(portugueseCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigatePortugueseCountries('/starting');
            HideFooter();
        }
    }

    //guinee bissau
    const handleGuineeBissau = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const portugueseCountry = "guineebissau";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = portugueseCountry;
        countryInfo = retrieveCodeAndName(portugueseCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigatePortugueseCountries('/starting');
            HideFooter();
        }
    }

    const goBackHandler = async () => {

        navigatePortugueseCountries('/');
        //window.location.reload(false);
        document.getElementById('selectlangue').selectedIndex = 0;
    }

    const goNextHandler = async () => {
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const namecountryDiv = document.getElementById('namecountry').innerText;
        const portugueseCountry = namecountryDiv;
        //
        if(!namecountryDiv){
            if(!choixlangueDiv){
                const storedLangue = localStorage.getItem('i18nextLng');
                document.getElementById('choixlangue').innerText = storedLangue ;
                if(!storedLangue){
                    const pTextePays = t("principaltexte1");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    if(!portugueseCountry){
                        const pTextePays = t("principaltexte2");
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                    }else{
                        navigatePortugueseCountries('/starting');
                    }
                }
            }else{
                if(!portugueseCountry){
                    const pTextePays = t("principaltexte2");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else if(!choixlangueDiv){
                    const pTextePays = t("principaltexte1");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    navigatePortugueseCountries('/starting');
                }
            }
        }else{
            navigatePortugueseCountries('/starting');
        }
    }

    return (
        <div id='portuguesecontries'>
            <PrincipalScroller>
                <br/>
                <PrincipalSubTitle>{t("principaltexte2")}</PrincipalSubTitle>
                {/*<br/>
                <PrincipalIntro>{t("select_country_texte1")}</PrincipalIntro>*/}
                <br/>
                <div style={{display:'block',textAlign:'center'}}>
                    <CountryButton 
                        url={Capvert}
                        countryName="Cap Vert"
                        onConfirm={handleCapvert}
                    />
                    <CountryButton 
                        url={GuineeBissau}
                        countryName="Guinée Bissau"
                        onConfirm={handleGuineeBissau}
                    />
                </div>
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackHandler}></PopUpBackButton>
                    </div>
                    <div>
                        <PopUpForwardButton onClick={goNextHandler}></PopUpForwardButton>
                    </div>
                </HandlerContainerDiv>
                <br/>
                <div id="modaloutalertportuguesecountries"></div>
                <div id="backdropoutalertportuguesecountries"></div>
            </PrincipalScroller>
        </div>
    )
}

export default PortugueseContries
import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//
import FooterStarting from '../footer/FooterStarting';
//
import ListEventCard from '../reusablecomponents/cards/ListEventCard';
import ListDiscountCard from '../reusablecomponents/cards/ListDiscountCard';
import LikeButton from '../reusablecomponents/buttons/LikeButton';
//
import { retrieveTableQuery } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal, HideFooter, WhatsappCall } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';

//
import { 
    TermeScroller, 
    ModalUser, 
    MainFooterContainer,
    MainFooterDetailContainer, 
    HandlerContainerDiv,
    EventScrollerContainer,
    EventScrollerMenu,
    ContentScrollerDetails,
    DetailContainerMenu,
    VideoModalUser,
} from '../ComponentStyles';

import apiUrl from '../../apiconfig/ApiConfig';
import Axios from 'axios';

function FooterPromotion() {
    HideFooter();
    const {t} = useTranslation();

    const navigateFooterPromotion = useNavigate();

    //alert
    const elementOutId = "modaloutalertfooterpromotions";
    const elementDropId = "backdropoutalertfooterpromotions";
    const buttonHandler = "ouiHandlerAlertFooterPromotionsBtn";

    const [currentPromotionViews, setCurrentPromotionViews] = useState(0);

    const [productContent, setProductContent] = useState(false);
    const [productPromotion, setProductPromotion] = useState(false);
    const [userFetchPromotion, setFetchPromotion] = useState([0]);

    const [newPromotionsModalOpen, setNewPromotionsModalOpen] = useState(false);
    const [userPromotions, setUserPromotions] = useState([0]);

    //emplementation useEffect to retrieve all online promotion and discount
    useEffect(() => {
        const fetchData = async () => {
            var tableNameDiscount = "";
            var signupTableDiscount = [];
            var resultatPromotions = 0;
            //
            const firstWhere = "date_end";
            const secondWhere = "id_discount";
            //
            const limitNumber = 300;

            const codecountryDiv = document.getElementById('codecountry').innerText;
            const searchcountryDiv = document.getElementById('countrysearch').innerText;
            var countrySelected = '';
            if(searchcountryDiv){
                countrySelected = searchcountryDiv;
            }else{
                countrySelected = codecountryDiv;
            }
            //retrieve table discount
            signupTableDiscount = retrieveTableQuery(countrySelected);
            if(signupTableDiscount){
                tableNameDiscount = signupTableDiscount.table.table7;
            }

            //check all seller subscription where currrent date is < date expiration
            Axios.post(`${apiUrl}/check/valid/product`, {
                tableNameOne:tableNameDiscount,
                firstWhere:firstWhere,
                secondWhere:secondWhere,
                limitNumber:limitNumber,
            }).then((responsePromotions) => {
                resultatPromotions = responsePromotions.data.length;
                if(resultatPromotions === 0){
                    const pTextePays = t("error_promotions");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setFetchPromotion(responsePromotions.data);
                    setProductContent(true);
                    setProductPromotion(true);
                    setNewPromotionsModalOpen(false);
                }
            });
        }
        fetchData();
    }, [t, elementOutId, elementDropId, buttonHandler])
    //
    const handleDiscount = async (promotionId) => {
        var tableNameDiscount = "";
        var signupTableDiscount = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table discount
        signupTableDiscount = retrieveTableQuery(countrySelected);
        if(signupTableDiscount){
            tableNameDiscount = signupTableDiscount.table.table7;
        }
        const firstWhere = "code_user";
        const firstParameter = promotionId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameDiscount, firstWhere, firstParameter),
        ]);

        setUserPromotions(dataTable1);
        var promotionLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<promotionLength; i++){
            currentViews = dataTable1[i].views_discount;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentPromotionViews(updatedViews);
        setProductContent(false);
        setProductPromotion(false);
        setNewPromotionsModalOpen(true);
    }
    //
    const handlerClosePromotion = async () => {
        
        navigateFooterPromotion(-1);
    }
    //
    const handlerDirectCallPromotion = async (allId) => {
        //initialize variables
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";
        //
        const firstWhere = "code_user";
        const firstParameter = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateFooterPromotion('/userlogin');
        }else{
            //retrieve buyer's inter prefixe, phone number and mode contact
            // Use Promise.all to execute multiple requests in parallel
            const [dataTable1] = await Promise.all([
                fetchDataFromTable(tableNameSeller, firstWhere, firstParameter),
            ]);
            resultatCont = dataTable1.length;
            viewContactData = dataTable1;
            for(let i=0; i<resultatCont; i++){
                prefixInter = viewContactData[i].prefixe;
                phoneNumber = viewContactData[i].phone;
            }

            //create a direct call feature
            var completePhone = prefixInter+phoneNumber;
            window.location.href = `tel:${completePhone}`;
        }
    }
    //
    const handlerChatRoomPromotion = async (allId) => {
        //initialize variables
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";
        //
        const firstWhere = "code_user";
        const firstParameter = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateFooterPromotion('/userlogin');
        }else{
            //retrieve buyer's inter prefixe, phone number and mode contact
            // Use Promise.all to execute multiple requests in parallel
            const [dataTable1] = await Promise.all([
                fetchDataFromTable(tableNameSeller, firstWhere, firstParameter),
            ]);
            resultatCont = dataTable1.length;
            viewContactData = dataTable1;

            for(let i=0; i<resultatCont; i++){
                prefixInter = viewContactData[i].prefixe;
                phoneNumber = viewContactData[i].phone;
            }

            //create a whatsapp call feature
            WhatsappCall(prefixInter, phoneNumber);
        }
    }
    //
    const handleLikePromotion = async (promotionId) => {

        var tableNameDiscount = "";
        var signupTableDiscount = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table discount
        signupTableDiscount = retrieveTableQuery(countrySelected);
        if(signupTableDiscount){
            tableNameDiscount = signupTableDiscount.table.table7;
        }

        //edit table advertise incrementing views
        const firstSet = 'views_discount';
        //const firstSetParameter = parseFloat(eventViewsRef.current.value) + 1;
        const firstWhereId = "code_user";
        const firstParameterId = promotionId;
        await Axios.post(`${apiUrl}/update/one/parameter/table`, {
            tableName: tableNameDiscount,
            firstSet: firstSet,
            firstSetParameter: currentPromotionViews.toString(),
            firstWhereId: firstWhereId,
            firstParameterId: firstParameterId,
        });
    }

    //Function to fetch data from the API for each table
    const fetchDataFromTable = async (tableName, firstWhere, firstParameter) => {
        try {
            const result = await Axios.post(`${apiUrl}/check/one/parameter/table`, {
                tableNameSeller:tableName,
                firstWhere:firstWhere,
                firstParameter:firstParameter,
            });
            return result.data;
        } catch (error) {
            const pTextePays = t("error_data");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            throw error;
        }
    }

    const goBackFooterPromotionHandler = async () => {

        navigateFooterPromotion('/starting');
    }

    return (
        <div id='footerpromotion'>
            <TermeScroller>
                {/**new promotions products */}
                {productContent && (
                    <MainFooterContainer>
                        {/**event scroller */}
                        {productPromotion && (
                            <div>
                                <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("promotion_text")}</span>
                                <EventScrollerContainer>
                                    <EventScrollerMenu>
                                        {userFetchPromotion.map((valpromo, indexpromo) => (
                                            <ModalUser key={indexpromo}>
                                                <ListEventCard 
                                                    url={valpromo.image_discount}
                                                    onImageClick={() => handleDiscount(valpromo.code_user)}
                                                    title={valpromo.name_product}
                                                    text={valpromo.price_product}
                                                    currency={valpromo.currency_product}
                                                    views={valpromo.views_discount}
                                                />
                                            </ModalUser>
                                        ))}
                                    </EventScrollerMenu>
                                </EventScrollerContainer>
                            </div>
                        )} 
                    </MainFooterContainer>
                )}
                
                {newPromotionsModalOpen && (
                    <MainFooterDetailContainer>
                        <ContentScrollerDetails>
                            <DetailContainerMenu>
                                <div>
                                    {userPromotions.map((valpromotion, indexpromotion) => (
                                        <VideoModalUser key={indexpromotion}>
                                            <ListDiscountCard
                                                onButtonClickClose={() => handlerClosePromotion()}
                                                onButtonClickPhoneCall={() => handlerDirectCallPromotion(valpromotion.code_user)}
                                                onButtonClickPhone={() => handlerChatRoomPromotion()}
                                                url={valpromotion.image_discount}
                                                title={valpromotion.name_product}
                                                textDescription={valpromotion.description_product}
                                                textPrice={valpromotion.new_price}
                                                textCurrency={valpromotion.currency_product}
                                            />
                                            <LikeButton 
                                                    confirm={() => handleLikePromotion(valpromotion.code_user)}
                                                />
                                        </VideoModalUser> 
                                    ))}
                                </div>
                            </DetailContainerMenu>
                        </ContentScrollerDetails>
                    </MainFooterDetailContainer>
                )}
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackFooterPromotionHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>

                <FooterStarting />
                <br/><br/>
                <div id="modaloutalertfooterpromotions"></div>
                <div id="backdropoutalertfooterpromotions"></div>
            </TermeScroller>
        </div>
    )
}

export default FooterPromotion
import React from 'react';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
//
import { MdClose } from "react-icons/md";
//
import { 
    CardContainer,
    CardHeader,
    CardImageProduct,
    CardButtonClose,
    CardBody,
    CardTitle,
    CardText,
} from '../../ComponentStyles';

function ViewAllDetailCard({ urlProduct, denomination, secteur, description, modeVente, contact, pays, ville, village, quartier, localisation, titleProduct, descriptionProduct, priceProduct, currencyProduct, onButtonClickClose, }) {
    const {t} = useTranslation();
    return (
        <div>
            <CardContainer>
                <CardHeader>
                    <CardButtonClose onClick={onButtonClickClose}><MdClose style={{fontSize:'40px'}} /></CardButtonClose>
                </CardHeader>
                <CardBody>
                    <CardTitle>{denomination}</CardTitle>
                    <CardText><strong>{t("profile_sector")}:</strong> {secteur}</CardText>
                    <CardText><strong>{t("profile_description")}:</strong> {description}</CardText>
                    <CardText><strong>{t("profile_mode")}:</strong> {modeVente}</CardText>
                    <CardTitle>{t("localisation")}</CardTitle>
                    <CardText><strong>{t("profile_contact")}:</strong> {contact}</CardText>
                    <CardText><strong>{t("profile_country")}:</strong> {pays}</CardText>
                    <CardText><strong>{t("profile_ville")}:</strong> {ville}</CardText>
                    <CardText><strong>{t("profile_village")}:</strong> {village}</CardText>
                    <CardText><strong>{t("profile_quartier")}:</strong> {quartier}</CardText>
                    <CardText><strong>{t("profile_location")}:</strong> {localisation}</CardText>
                </CardBody>
            </CardContainer>
            <hr></hr>
        </div>
    )
}

export default ViewAllDetailCard
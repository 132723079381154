import React from 'react';
//import { useTranslation } from 'react-i18next';
//import "../../../translations/i18n";
//
import { MdClose, MdWhatsapp } from "react-icons/md";
import { GiWorld, GiModernCity } from "react-icons/gi";
//
import { 
    CardContainer,
    ContactHeader,
    CardImageProduct,
    CardButtonClose,
    CardBody,
    ContactCardTitle,
    ContactCardText,
    CardButtonPhone,
} from '../../ComponentStyles';

function SubscriberCardContact({ url, denomination, pays, ville, onButtonClickPhone, onButtonClickClose }) {
    return (
        <div>
            <CardContainer>
                <ContactHeader>
                    <CardImageProduct>
                        <img src={url} alt='preview' style={{ maxWidth: '100%', maxHeight: '50px', borderRadius: '50%' }} />
                    </CardImageProduct>
                    <ContactCardTitle>{denomination}</ContactCardTitle>
                    <CardButtonClose onClick={onButtonClickClose}><MdClose style={{fontSize:'25px'}} /></CardButtonClose>
                </ContactHeader>
                <CardBody>
                    <ContactCardText><GiWorld style={{fontSize:'30px'}} /> {pays}</ContactCardText>
                    <ContactCardText><GiModernCity style={{fontSize:'30px'}} /> {ville}</ContactCardText>
                </CardBody>
                <div style={{display:'flex', flexDirection:'row', marginLeft:'150px'}}>
                    <CardButtonPhone onClick={onButtonClickPhone}><MdWhatsapp style={{fontSize:'50px'}} /></CardButtonPhone>
                </div>
                <br/>
            </CardContainer>
        </div>
    )
}

export default SubscriberCardContact
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdPreview } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal } from '../../../../helperfunctions/Helper';
//

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallHandlerContainerDiv,
    SmallViewButton,
} from '../../../ComponentStyles';

function ViewProfile() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertviewprofile";
    const elementDropId = "backdropoutalertviewprofile";
    const buttonHandler = "ouiHandlerAlertViewProfileBtn";

    // Define state variables for modals
    const [startViewProfileModalOpen, setStartViewProfileModalOpen] = useState(true);
    const [viewProfileModalOpen, setViewProfileModalOpen] = useState(false);

    const [viewDetailProfile, setViewDetailProfile] = useState([0]);

    const handleStartProfile = async () => {
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        //var checkResultat = [];

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table3;
        }

        await Axios.post(`${apiUrl}/delete/profile/details`, {
            tableNameSeller:tableName,
            codeUtisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setViewDetailProfile(response1.data);
                setStartViewProfileModalOpen(false);
                setViewProfileModalOpen(true);
            }
        });
    }

    const handleViewProfile = async (e) => {
        e.preventDefault();

        setStartViewProfileModalOpen(true);
        setViewProfileModalOpen(false);
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_view_profile")}</FooterSubTitle>
                <br/>
                {startViewProfileModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartProfile}
                        />
                    </div>
                )}
                <br/>
                {viewProfileModalOpen && (
                    <div>
                        {viewDetailProfile.map((valviewdetail, indexviewdetail) => (
                            <ModalUser key={indexviewdetail}>
                                <SmallTextLogin>{t("profile_ville")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valviewdetail.city_user} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_village")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valviewdetail.village} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_quartier")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valviewdetail.quartier} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_sector")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valviewdetail.secteur} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_principal")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valviewdetail.profile_principal} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_location")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        defaultValue={valviewdetail.localisation}
                                        readOnly
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_mode")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valviewdetail.mode_vente} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        defaultValue={valviewdetail.profile_description}
                                        readOnly
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_contact")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valviewdetail.profile_contact} readOnly />
                                </div>
                                <br/>
                                <SmallHandlerContainerDiv>
                                    <div>
                                        <SmallViewButton onClick={handleViewProfile}>
                                            <span style={{ fontSize:'30px'}}><MdPreview /></span>
                                            OK
                                        </SmallViewButton>
                                    </div>
                                </SmallHandlerContainerDiv>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/><br/>
                <div id="modaloutalertviewprofile"></div>
                <div id="backdropoutalertviewprofile"></div>
            </ModalUser>
        </div>
    )
}

export default ViewProfile
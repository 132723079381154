import React from 'react';
import { ModalBackDrop, ModalContainer, ModalText, ModalButton } from '../../ComponentStyles';

function WarningModal({ text, textOk, textNo, onConfirm, onCancel }) {
    return (
        <div>
            <ModalBackDrop>
                <ModalContainer>
                    <ModalText>{text}</ModalText>
                    <ModalButton danger onClick={onConfirm}>
                        {textOk}
                    </ModalButton>
                    <ModalButton onClick={onCancel}>{textNo}</ModalButton>
                </ModalContainer>
            </ModalBackDrop>
        </div>
    )
}

export default WarningModal
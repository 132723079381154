import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
//
import { GoBackButton, GoBackText } from '../ReusableStyles';

function GobackBouton({onClick}){
    const {t} = useTranslation();

    const navigateBack = useNavigate();

    const goBack = () => {
        if (onClick && typeof onClick === 'function') {
            onClick(); // Call the provided onClick function if available
        } else {
            navigateBack('/'); // Default behavior: go back in history
        }
    };

    return (
        <GoBackButton onClick={goBack}>
            <FiArrowLeft />
            <GoBackText>{t("feedbacktexte6")}</GoBackText>
        </GoBackButton>
    )
}

export default GobackBouton
import React/*, { useState }*/ from 'react';
import { Container } from './LayoutStyles';
import Header from '../accueil/header/Header';
import Footer from '../components/footer/Footer';

export function Layout({children}) {

    return (
        <Container>
            <Header />
            <main>{children}</main>
            <Footer />
        </Container>
    );
}
import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
//
import { InnerGoNextPopUpButton } from '../ReusableStyles';

function InnerPopUpBackButton({onClick}) {

    const navigateBack = useNavigate();

    const goBack = () => {
        if (onClick && typeof onClick === 'function') {
            onClick(); // Call the provided onClick function if available
        } else {
            navigateBack('/'); // Default behavior: go back in history
        }
    };
    return (
        <InnerGoNextPopUpButton onClick={goBack}>
            <FiArrowLeft />
        </InnerGoNextPopUpButton>
    )
}

export default InnerPopUpBackButton
import React, {useRef} from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//import country button
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//
import FooterStarting from '../footer/FooterStarting';
//icons
import Unsubscriber from '../countries/sellerbuyericon/unsubscribe_icon.png';
import Subscriber from '../countries/sellerbuyericon/subscribe_icon.png';
import Account from '../countries/sellerbuyericon/account_icon.png';
//
import { MdOutlineChat } from 'react-icons/md';
import { CgCloseO } from 'react-icons/cg';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
//
import { /*ShowFooter,*/ HideFooter } from '../../helperfunctions/Helper';

//
import { 
    TermeScroller, 
    FooterSubTitle,
    FooterIntro, 
    HandlerContainerDiv,
    HandlerColumnContainerDiv, 
    //BazaliButton,
} from '../ComponentStyles';

function StartingBuyer() {
    HideFooter();

    const {t} = useTranslation();

    const navigateStartingBuyer = useNavigate();

    const startingInfoBuyerRef = useRef(null);
    const handlerStartingRef = useRef(null);

    const handleStartingBuyer = async (e) => {
        e.preventDefault();

        startingInfoBuyerRef.current.style.display = 'block';
        handlerStartingRef.current.style.display = 'none';
    }

    const handleCloseStartingBuyer = async (e) => {
        e.preventDefault();

        startingInfoBuyerRef.current.style.display = 'none';
        handlerStartingRef.current.style.display = 'block';
    }

    const handleNoSubscriberUser = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        HideFooter();
        navigateStartingBuyer('/nosubscribebuyer');
    }

    const handleSubscriberUser = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        HideFooter();
        navigateStartingBuyer('/userlogin');
    }

    const handleCreateNewAccount = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        HideFooter();
        navigateStartingBuyer('/signupbuyer');
    }

    const handleGoBack = async () => {

        //ShowFooter();
        navigateStartingBuyer('/starting');
    }
    //
    /*const goNextHandler = async () => {

        //
    }*/

    return (
        <div id='startingbuyer'>
            <TermeScroller>
                <br/>
                <div style={{textAlign:'center'}}>
                    <FooterSubTitle>{t("buyer_section_1")}</FooterSubTitle>
                </div>
                <div style={{float: 'left'}}>
                    <MdOutlineChat style={{color:'#246175', fontSize:'35px', cursor:'pointer'}} onClick={handleStartingBuyer} />
                </div>
                <div ref={startingInfoBuyerRef} style={{display: 'none'}}>
                    <FooterIntro>{t("buyer_section_2")}</FooterIntro>
                    <FooterSubTitle>1. {t("buyersoustitre1")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("buyersoustitretexte1")}</li>
                    </ul>
                
                    <FooterSubTitle>2. {t("buyersoustitre2")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("buyersoustitretexte2")}</li>
                    </ul>
                
                    <FooterSubTitle>2. {t("buyersoustitre3")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("buyersoustitretexte3")}</li>
                    </ul>
                
                    <FooterIntro>
                        {t("buyersoustitretexte4")}
                    </FooterIntro>
                    <br/>
                    <div style={{marginLeft:'20px'}}>
                        <CgCloseO style={{color:'red', fontSize:'35px', cursor:'pointer'}} onClick={handleCloseStartingBuyer} />
                    </div>
                    <br/><br/>
                </div>
                <div ref={handlerStartingRef}>
                    <HandlerColumnContainerDiv>
                        <CountryButton 
                            url={Unsubscriber}
                            countryName={t("noSubscriberUser")}
                            onConfirm={handleNoSubscriberUser}
                        />
                        <CountryButton 
                            url={Subscriber}l
                            countryName={t("subscriberUser")}
                            onConfirm={handleSubscriberUser}
                        />
                        <CountryButton 
                            url={Account}
                            countryName={t("createNewAccount")}
                            onConfirm={handleCreateNewAccount}
                        />
                    </HandlerColumnContainerDiv>
                </div>
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={handleGoBack} ></PopUpBackButton>
                    </div>
                    {/*<div>
                        <ForwardButton onClick={goNextHandler}></ForwardButton>
                    </div>*/}
                </HandlerContainerDiv>

                <FooterStarting />
                <br/><br/>
            </TermeScroller>
        </div>
    )
}

export default StartingBuyer
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdDelete } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal } from '../../../../helperfunctions/Helper';
//
import CrudSettings from './CrudSettings';
import WarningModal from '../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallHandlerContainerDiv,
    SmallDeleteButton,
} from '../../../ComponentStyles';

function DeleteProfile() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertdeleteprofile";
    const elementDropId = "backdropoutalertdeleteprofile";
    const buttonHandler = "ouiHandlerAlertDeleteProfileBtn";

    // Define state variables for modals
    const [startDeleteProfileModalOpen, setStartDeleteProfileModalOpen] = useState(true);
    const [deleteProfileModalOpen, setDeleteProfileModalOpen] = useState(false);
    //
    const [crudSettingsModalOpen, setCrudSettingsModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [deleteDetailProfile, setDeleteDetailProfile] = useState([0]);

    const idDeleteProfileRef = useRef(null);

    const handleStartProfile = async () => {
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        //var checkResultat = [];

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table3;
        }

        await Axios.post(`${apiUrl}/delete/profile/details`, {
            tableNameSeller:tableName,
            codeUtisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setDeleteDetailProfile(response1.data);
                setStartDeleteProfileModalOpen(false);
                setDeleteProfileModalOpen(true);
            }
        });
    }

    const handleDeleteProfile = async (e) => {
        e.preventDefault();

        setShowModal(true);
    }

    const handleCancelProfile = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setCrudSettingsModalOpen(false);
        setStartDeleteProfileModalOpen(true);
        setDeleteProfileModalOpen(false);
    }

    const handleModalDeleteProfile = async (e) => {
        e.preventDefault();
        
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const idDeleteProfile = idDeleteProfileRef.current.value;
        //
        var tableName = "";
        var signupTable = [];
        //var resultat = '';
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table3;
        }
        await Axios.post(`${apiUrl}/delete/seller/profile`, {
            deleteId:idDeleteProfile,
            tableNameSeller:tableName,
        }).then(() => {
            setCrudSettingsModalOpen(false);
            setStartDeleteProfileModalOpen(true);
            setDeleteProfileModalOpen(false);
        });
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_delete_profile")}</FooterSubTitle>
                <br/>
                {startDeleteProfileModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartProfile}
                        />
                    </div>
                )}
                <br/>
                {deleteProfileModalOpen && (
                    <div>
                        {deleteDetailProfile.map((valdeletedetail, indexdeletedetail) => (
                            <ModalUser key={indexdeletedetail}>
                                <input style={{display:'none'}} ref={idDeleteProfileRef} defaultValue={valdeletedetail.id_profile} />
                                <SmallTextLogin>{t("profile_ville")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.city_user} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_village")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.village} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_quartier")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.quartier} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_sector")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.secteur} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_principal")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.profile_principal} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_location")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        defaultValue={valdeletedetail.localisation}
                                        readOnly
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_mode")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.mode_vente} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        defaultValue={valdeletedetail.profile_description}
                                        readOnly
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_contact")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.profile_contact} readOnly />
                                </div>
                                <br/>
                                <SmallHandlerContainerDiv>
                                    <div>
                                        <SmallDeleteButton onClick={handleDeleteProfile}>
                                            <span style={{ fontSize:'30px'}}><MdDelete /></span>
                                            {t("delete")} ?
                                        </SmallDeleteButton>
                                    </div>
                                </SmallHandlerContainerDiv>
                                {showModal && (
                                    <WarningModal
                                        text={t("text_warning")}
                                        textOk={t("ok_delete")}
                                        textNo={t("no_cancel")}
                                        onConfirm={handleModalDeleteProfile}
                                        onCancel={handleCancelProfile}
                                    />
                                )}
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/><br/>
                <div id="modaloutalertdeleteprofile"></div>
                <div id="backdropoutalertdeleteprofile"></div>
            </ModalUser>
            {/**CrudSettings */}
            {crudSettingsModalOpen && (
                <CrudSettings />
            )}
        </div>
    )
}

export default DeleteProfile
import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//import './buyer.css';
//
import FooterStarting from '../footer/FooterStarting';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
//
import { BiShow } from 'react-icons/bi';
import { MdPersonAddAlt1, MdModeEdit, MdDelete, MdPreview, MdWhatsapp, MdOutlineChat } from 'react-icons/md';
import { CgCloseO } from 'react-icons/cg';
//
//import GobackBouton from '../reusablecomponents/buttons/GobackBouton';
import ContinueButton from '../reusablecomponents/buttons/ContinueButton';
//
import { retrieveTableQuery } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { HideFooter, AlertModal, getCurrentDate } from '../../helperfunctions/Helper';

import apiUrl from '../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    TermeScroller, 
    FooterSubTitle,
    FooterIntro,
    //LargeInput,
    //PasswordInput,
    //SearchInput,
    //FaqContainer,
    //TextLogin, 
    SmallTextLogin,
    SmallCodeTextLogin,
    HandlerContainerDiv, 
    //BazaliButton,
    //Modal
    ModalSign,
    ConfirmButton,
    CancelButton,
    LeftSideDiv,
    RightSideDiv,
    LeftSideButton,
    ModalUser,
    SmallInput,
    SmallPasswordInput,
    SmallHandlerContainerDiv,
} from '../ComponentStyles';

var userCode = "";
var userPhone = "";

function SignUpBuyer() {
    HideFooter();
    
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertuserbuyer";
    const elementDropId = "backdropoutalertuserbuyer";
    const buttonHandler = "ouiHandlerAlertuserbuyerBtn";

    const navigateSignUpBuyer = useNavigate();

    const signupInfoBuyerRef = useRef(null);
    const modalSignupByerRef = useRef(null);

    //create account useRefs
    const createFirstNameBuyerRef = useRef(null);
    const createLastNameBuyerRef = useRef(null);
    const createPhoneBuyerRef = useRef(null);
    const createCityBuyerRef = useRef(null);
    const createPasswordBuyerRef = useRef(null);
    const createCodeBuyerRef = useRef(null);
    //confirm account
    const continueSignUpBuyerRef = useRef(null);
    const sendCodeBuyerRef = useRef(null);
    const activateAccountBuyerRef = useRef(null);

    //edit buyer account
    const authenticateBuyerAccountRef = useRef(null);
    const connectEditPhoneBuyerRef = useRef(null);
    const connectEditPasswordBuyerRef = useRef();
    const continueEditBuyerRef = useRef();
    const detailEditBuyerAccountRef = useRef(null);
    //delete buyer account
    const authenticateDeleteBuyerAccountRef = useRef(null);
    const connectDeletePhoneBuyerRef = useRef(null);
    const connectDeletePasswordBuyerRef = useRef(null);
    const continueDeleteBuyerRef = useRef(null);
    const detailDeleteBuyerAccountRef = useRef(null);
    //view buyer account
    const authenticateViewBuyerAccountRef = useRef(null);
    const connectViewPhoneBuyerRef = useRef(null);
    const connectViewPasswordBuyerRef = useRef(null);
    const continueViewBuyerRef = useRef(null);
    const detailViewBuyerAccountRef = useRef(null);
    //edit
    const idEditBuyerAccountRef = useRef(null);
    const editFirstNameBuyerRef = useRef(null);
    const editLastNameBuyerRef = useRef(null);
    const editPhoneBuyerRef = useRef(null);
    const editCityBuyerRef = useRef(null);
    const editPasswordBuyerRef = useRef(null);
    //delete
    const idDeleteBuyerAccountRef = useRef(null);


    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Define state variables for modals
    const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
    const [editAccountModalOpen, setEditAccountModalOpen] = useState(false);
    const [editDetailBuyerAccount, setEditDetailBuyerAccount] = useState([0]);
    const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
    const [deleteDetailBuyerAccount, setDeleteDetailBuyerAccount] = useState([0]);
    const [viewAccountModalOpen, setViewAccountModalOpen] = useState(false);
    const [viewDetailBuyerAccount, setViewDetailBuyerAccount] = useState([0]);

    // Declare state variables for input data
    //const [SubscribeCreate, setSubscribeCreate] = useState('');

    const handleSignupBuyer = async (e) => {
        e.preventDefault();

        signupInfoBuyerRef.current.style.display = 'block';
        modalSignupByerRef.current.style.display = 'none';
    }

    const handleCloseSignupgBuyer = async (e) => {
        e.preventDefault();

        signupInfoBuyerRef.current.style.display = 'none';
        modalSignupByerRef.current.style.display = 'block';
    }

    // handle create buttons
    const handleCreateBuyerAccount = async (e) => {
        e.preventDefault();

        setCreateAccountModalOpen(true);
        setEditAccountModalOpen(false);
        setDeleteAccountModalOpen(false);
        setViewAccountModalOpen(false);
    }

    //check if the user whatsapp number is valid
    const handleVerifyWhatsapp = async (e) => {
        e.preventDefault();

        //const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        const prefixecountryDiv = '+44'; //for testing purpose
        const createPhoneBuyer = createPhoneBuyerRef.current.value; //use my Uk whatsapp number for testing purpose
        const userPhoneNumber = prefixecountryDiv+createPhoneBuyer;
        //console.log(userPhoneNumber);
        //
        if(!createPhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            const encodedMessage = t("whatsapp_authentication");
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //navigateSignUpBuyer('#');
        }
    }

    //after verifying whatsapp => continiue
    const continueSignUpBuyerHandler = async (e) => {

        const createPhoneBuyer = createPhoneBuyerRef.current.value;
        if(!createPhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            sendCodeBuyerRef.current.style.display = 'block';
        }
        //navigateSignUpBuyer('#');
    }

    //send code handler
    const handleSendCodeBuyer = async (e) => {
        e.preventDefault();

        //check if fields are empty
        const createFirstNameBuyer = createFirstNameBuyerRef.current.value;
        const createLastNameBuyer = createLastNameBuyerRef.current.value;
        const createPhoneBuyer = createPhoneBuyerRef.current.value;
        //const createCityBuyer = createCityBuyerRef.current.value;
        //const createPasswordBuyer = createPasswordBuyerRef.current.value;
        //
        //var createUserCode = "";
        //const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        const prefixecountryDiv = '+44';
        //
        if(!createFirstNameBuyer){
            const pTextePays = t("firstname_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createLastNameBuyer){
            const pTextePays = t("lastname_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createPhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            //generate random user code number
            var randomNumber = Math.floor((Math.random() * 100000) + 1);
            userCode = randomNumber;
            //console.log(userCode);
            const userPhoneNumber = prefixecountryDiv+createPhoneBuyer;
            //const userPhoneNumber = '+447459367632';
            //console.log(userPhoneNumber);
            //send code generated to the user
            const encodedMessage = `Your verification code is: ${userCode}. Copy it and insert it in create account process and create now your new account. `;
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //
            userPhone = createPhoneBuyer;
            //navigateSignUpBuyer('#');
            activateAccountBuyerRef.current.style.display = 'block';
        }
    }

    const handleModalClose = async () => {
        setCreateAccountModalOpen(false);
        setEditAccountModalOpen(false);
        setDeleteAccountModalOpen(false);
        setViewAccountModalOpen(false);
    }

    const handleBuyerSignUp = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;

        const namecountryDiv = document.getElementById('namecountry').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        //
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table1;
        }
        //
        const createFirstNameBuyer = createFirstNameBuyerRef.current.value;
        const createLastNameBuyer = createLastNameBuyerRef.current.value;
        const createPhoneBuyer = userPhone;
        const createSigle = "buyer";
        const createCityBuyer = createCityBuyerRef.current.value;
        const createPasswordBuyer = createPasswordBuyerRef.current.value;
        const createStatus = "online";
        const createDate = getCurrentDate();
        //compare the generated user code to the input user code
        const createCodeBuyer = createCodeBuyerRef.current.value;
        var codeBuyer = parseInt(createCodeBuyer)
        var userCodeBuyer = parseInt(userCode);
        if(codeBuyer === userCodeBuyer){
            if(!namecountryDiv){
                const pTextePays = t("principaltexte2");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!choixlangueDiv){
                const pTextePays = t("principaltexte2");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!createFirstNameBuyer){
                const pTextePays = t("firstname_placeholder");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!createLastNameBuyer){
                const pTextePays = t("lastname_placeholder");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!createPhoneBuyer){
                const pTextePays = t("please_number");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
            }else if(!createCityBuyer){
                const pTextePays = t("searchcity_placeholder");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
            }else if(!createPasswordBuyer){
                const pTextePays = t("buyertexte19");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
            }else{
                //check if this phone number already exist
                await Axios.post(`${apiUrl}/check/user/phone`, {
                    phoneBuyer:createPhoneBuyer,
                    tableNameBuyer:tableName,
                }).then((response1) => {
                    resultat1 = response1.data.length;
                    if(resultat1 === 0){
                        //create new buyer account
                        Axios.post(`${apiUrl}/create/buyer/account`, {
                            namecountry:namecountryDiv,
                            codecountry:codecountryDiv,
                            prefixecountry:prefixecountryDiv,
                            choixlangue:choixlangueDiv,
                            firstNameBuyer:createFirstNameBuyer,
                            lastNameBuyer:createLastNameBuyer,
                            phoneBuyer:createPhoneBuyer,
                            userCodeBuyer:userCode,
                            sigleBuyer:createSigle,
                            cityBuyer:createCityBuyer,
                            passwordBuyer:createPasswordBuyer,
                            statusBuyer:createStatus,
                            dateBuyer:createDate,
                            tableNameBuyer:tableName,
                        }).then(() => {
                            const pTextePays = t("buyer_success");
                            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                            setCreateAccountModalOpen(false);
                            setEditAccountModalOpen(false);
                            setDeleteAccountModalOpen(false);
                            setViewAccountModalOpen(false);
                            //navigateSignUpBuyer(-1);
                        });
                    }else{
                        const pTextePays = t("buyer_exists");
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
                    }
                });
            }
        }else{
            const pTextePays = t("signup_error");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }

    //edit buyer user
    const handleVerifyEditWhatsapp = async (e) => {
        //const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        const prefixecountryDiv = '+44'; //for testing purpose
        const connectEditPhoneBuyer = connectEditPhoneBuyerRef.current.value; //use my Uk whatsapp number for testing purpose
        const userPhoneNumber = prefixecountryDiv+connectEditPhoneBuyer;
        //console.log(userPhoneNumber);
        //
        if(!connectEditPhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            const encodedMessage = t("whatsapp_authentication");
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //navigateSignUpBuyer('#');
        }
    }

    //handle edit buyer account
    const handleEditBuyerAccount = async (e) => {
        e.preventDefault();

        setEditAccountModalOpen(true);
        setCreateAccountModalOpen(false);
        setDeleteAccountModalOpen(false);
        setViewAccountModalOpen(false);
        //
    }

    const connectEditBuyerHandler = async () => {
        //e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat = '';
        
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const connectEditPhoneBuyer = connectEditPhoneBuyerRef.current.value;
        const connectEditPasswordBuyer = connectEditPasswordBuyerRef.current.value;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table1;
        }
        //
        if(!connectEditPhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!connectEditPasswordBuyer){
            const pTextePays = t("buyertexte19");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            await Axios.post(`${apiUrl}/buyer/connexion`, {
                userPhone:connectEditPhoneBuyer,
                userPassword:connectEditPasswordBuyer,
                tableNameBuyer:tableName,
            }).then((response2) => {
                resultat = response2.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_login");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setEditDetailBuyerAccount(response2.data);
                    //
                    authenticateBuyerAccountRef.current.style.display = 'none';
                    detailEditBuyerAccountRef.current.style.display = 'block';
                }
            });
        }
    }

    const handleBuyerEdit = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat1 = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const idEditBuyerAccount = idEditBuyerAccountRef.current.value;
        const editFirstNameBuyer = editFirstNameBuyerRef.current.value;
        const editLastNameBuyer = editLastNameBuyerRef.current.value;
        const editPhoneBuyer = editPhoneBuyerRef.current.value;
        const editCityBuyer = editCityBuyerRef.current.value;
        const editPasswordBuyer = editPasswordBuyerRef.current.value;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table1;
        }

        if(!editFirstNameBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editLastNameBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editPhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editCityBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!editPasswordBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            //check if this phone number already exist
            await Axios.post(`${apiUrl}/check/user/phone`, {
                phoneBuyer:editPhoneBuyer,
                tableNameBuyer:tableName,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    //create new buyer account
                    Axios.post(`${apiUrl}/edit/buyer/account`, {
                        idEditBuyerAccount:idEditBuyerAccount,
                        firstNameBuyer:editFirstNameBuyer,
                        lastNameBuyer:editLastNameBuyer,
                        phoneBuyer:editPhoneBuyer,
                        cityBuyer:editCityBuyer,
                        passwordBuyer:editPasswordBuyer,
                        tableNameBuyer:tableName,
                    }).then(() => {
                        const pTextePays = t("buyer_success");
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                        setCreateAccountModalOpen(false);
                        setEditAccountModalOpen(false);
                        setDeleteAccountModalOpen(false);
                        setViewAccountModalOpen(false);
                        //navigateSignUpBuyer(-1);
                    });
                }else{
                    const pTextePays = t("buyer_exists");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
                    setCreateAccountModalOpen(false);
                    setEditAccountModalOpen(false);
                    setDeleteAccountModalOpen(false);
                    setViewAccountModalOpen(false);
                }
            });
        }
    }

    //delete buyer user
    const handleVerifyDeleteWhatsapp = async (e) => {
        //const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        const prefixecountryDiv = '+44'; //for testing purpose
        const connectDeletePhoneBuyer = connectDeletePhoneBuyerRef.current.value; //use my Uk whatsapp number for testing purpose
        const userPhoneNumber = prefixecountryDiv+connectDeletePhoneBuyer;
        //console.log(userPhoneNumber);
        //
        if(!connectDeletePhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            const encodedMessage = t("whatsapp_authentication");
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //navigateSignUpBuyer('#');
        }
    }

    //handle delete buyer account
    const handleDeleteBuyerAccount = async (e) => {
        e.preventDefault();

        setEditAccountModalOpen(false);
        setCreateAccountModalOpen(false);
        setDeleteAccountModalOpen(true);
        setViewAccountModalOpen(false);
        //
    }

    const connectDeleteBuyerHandler = async () => {
        //e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const connectDeletePhoneBuyer = connectDeletePhoneBuyerRef.current.value;
        const connectDeletePasswordBuyer = connectDeletePasswordBuyerRef.current.value;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table1;
        }
        //
        if(!connectDeletePhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!connectDeletePasswordBuyer){
            const pTextePays = t("buyertexte19");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            await Axios.post(`${apiUrl}/buyer/connexion`, {
                userPhone:connectDeletePhoneBuyer,
                userPassword:connectDeletePasswordBuyer,
                tableNameBuyer:tableName,
            }).then((response3) => {
                resultat = response3.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_login");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setDeleteDetailBuyerAccount(response3.data);
                    //
                    authenticateDeleteBuyerAccountRef.current.style.display = 'none';
                    detailDeleteBuyerAccountRef.current.style.display = 'block';
                }
            });
        }
    }

    const handleBuyerDelete = async (e) => {
        e.preventDefault();

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const idDeleteBuyerAccount = idDeleteBuyerAccountRef.current.value;
        //
        var tableName = "";
        var signupTable = [];
        //var resultat = '';
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table1;
        }
        await Axios.post(`${apiUrl}/delete/account`, {
            deleteId:idDeleteBuyerAccount,
            tableNameBuyer:tableName,
        }).then(() => {
            setEditAccountModalOpen(false);
            setCreateAccountModalOpen(false);
            setDeleteAccountModalOpen(false);
            setViewAccountModalOpen(false);
        });
    }

    //view buyer account
    const handleVerifyViewWhatsapp = async (e) => {
        //const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        const prefixecountryDiv = '+44'; //for testing purpose
        const connectViewPhoneBuyer = connectViewPhoneBuyerRef.current.value; //use my Uk whatsapp number for testing purpose
        const userPhoneNumber = prefixecountryDiv+connectViewPhoneBuyer;
        //console.log(userPhoneNumber);
        //
        if(!connectViewPhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            const encodedMessage = t("whatsapp_authentication");
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //navigateSignUpBuyer('#');
        }
    }

    const connectViewBuyerHandler = async () => {
        //e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const connectViewPhoneBuyer = connectViewPhoneBuyerRef.current.value;
        const connectViewPasswordBuyer = connectViewPasswordBuyerRef.current.value;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table1;
        }
        //
        if(!connectViewPhoneBuyer){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!connectViewPasswordBuyer){
            const pTextePays = t("buyertexte19");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            await Axios.post(`${apiUrl}/buyer/connexion`, {
                userPhone:connectViewPhoneBuyer,
                userPassword:connectViewPasswordBuyer,
                tableNameBuyer:tableName,
            }).then((response4) => {
                resultat = response4.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_login");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setViewDetailBuyerAccount(response4.data);
                    //
                    authenticateViewBuyerAccountRef.current.style.display = 'none';
                    detailViewBuyerAccountRef.current.style.display = 'block';
                }
            });
        }
    }

    //handle view buyer account
    const handleViewBuyerAccount = async (e) => {
        e.preventDefault();

        setEditAccountModalOpen(false);
        setCreateAccountModalOpen(false);
        setDeleteAccountModalOpen(false);
        setViewAccountModalOpen(true);
        //
    }


    const goBackSignUpBuyerHandler = async () => {

        navigateSignUpBuyer(-1);
    }

    return (
        <div id='signupbuyer'>
            <TermeScroller>
                <br/>
                <div style={{textAlign:'center'}}>
                    <FooterSubTitle>{t("buyer")} {t("createNewAccount")}</FooterSubTitle>
                </div>
                <br/>
                <div style={{float: 'left'}}>
                    <MdOutlineChat style={{color:'#246175', fontSize:'35px', cursor:'pointer'}} onClick={handleSignupBuyer} />
                </div>
                <br/>
                <div ref={signupInfoBuyerRef} style={{display: 'none'}}>
                    <FooterIntro>{t("signup_section_1")}</FooterIntro>
                    <br/>
                    <FooterSubTitle>1. {t("signupsoustitre1")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte1")}</li>
                    </ul>
                
                    <FooterSubTitle>2. {t("signupsoustitre2")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte2")}</li>
                        <li>{t("signupsoustitretexte3")}</li>
                        <li>{t("signupsoustitretexte4")}</li>
                        <li>{t("signupsoustitretexte5")}</li>
                    </ul>
                
                    <FooterSubTitle>3. {t("signupsoustitre3")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte6")}</li>
                    </ul>
                
                    <FooterSubTitle>4. {t("signupsoustitre4")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte7")}</li>
                        <li>{t("signupsoustitretexte8")}</li>
                    </ul>
                
                    <FooterSubTitle>5. {t("signupsoustitre5")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte9")}</li>
                    </ul>
                
                    <FooterSubTitle>6. {t("signupsoustitre6")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte10")}</li>
                        <li>{t("signupsoustitretexte11")}</li>
                    </ul>
                
                    <FooterIntro>{t("signup_section_2")}</FooterIntro>
                
                    <FooterIntro>{t("signup_section_3")}</FooterIntro>
                    <br/>
                    <div style={{marginLeft:'20px'}}>
                        <CgCloseO style={{color:'red', fontSize:'35px', cursor:'pointer'}} onClick={handleCloseSignupgBuyer} />
                    </div>
                    <br/><br/>
                </div>
                
                <br/>
                <div ref={modalSignupByerRef}>
                    <ModalSign>
                        <LeftSideDiv>
                            <div>
                                <LeftSideButton onClick={handleCreateBuyerAccount}>
                                    <MdPersonAddAlt1 style={{fontSize:'30px'}} />
                                    {t("create")}
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleEditBuyerAccount}>
                                    <MdModeEdit style={{fontSize:'30px'}} />
                                    {t("edit")}
                                </LeftSideButton>
                            </div>

                            <div>
                                <LeftSideButton onClick={handleDeleteBuyerAccount}>
                                    <MdDelete style={{fontSize:'30px'}} />
                                    {t("delete")}
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleViewBuyerAccount}>
                                    <MdPreview style={{fontSize:'30px'}} />
                                    {t("view")}
                                </LeftSideButton>
                            </div>
                        </LeftSideDiv>
                        <RightSideDiv>
                            {/* Create account Modal */}
                            {createAccountModalOpen && (
                                <ModalUser>
                                    <FooterSubTitle>{t("create_account")}</FooterSubTitle>
                                    <br/>
                                    <SmallTextLogin>{t("first_name")}</SmallTextLogin>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallInput type='text' ref={createFirstNameBuyerRef} placeholder={t("firstname_placeholder")} />
                                    </div>
                                    <br/>
                                    <SmallTextLogin>{t("last_name")}</SmallTextLogin>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallInput type='text' ref={createLastNameBuyerRef} placeholder={t("lastname_placeholder")} />
                                    </div>
                                    <br/>
                                    <SmallTextLogin>{t("buyertexte16")}</SmallTextLogin>
                                    <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallPasswordInput type='number' ref={createPhoneBuyerRef} placeholder={t("buyertexte17")} />
                                        </div>
                                        <div>
                                            <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyWhatsapp} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div ref={continueSignUpBuyerRef} style={{display:'block'}}>
                                        <HandlerContainerDiv>
                                            <div>
                                                <ContinueButton onClick={continueSignUpBuyerHandler} ></ContinueButton>
                                            </div>
                                        </HandlerContainerDiv>
                                    </div>
                                    <br/>
                                    <div ref={sendCodeBuyerRef} style={{display:'none'}}>
                                        <SmallHandlerContainerDiv>
                                            <div>
                                                <ConfirmButton onClick={handleSendCodeBuyer}>{t("send_code")}</ConfirmButton>
                                            </div>
                                            <div>
                                                <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                            </div>
                                        </SmallHandlerContainerDiv>
                                    </div>
                                    <br/>
                                    <div ref={activateAccountBuyerRef} style={{display:'none', marginLeft:'20px'}}>
                                        <SmallCodeTextLogin>{t("code_user")}</SmallCodeTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallPasswordInput type='number' ref={createCodeBuyerRef} placeholder={t("codeuser_placeholder")} />
                                        </div>
                                        <br/>
                                        <SmallTextLogin>{t("filter_city")}</SmallTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallPasswordInput type='text' ref={createCityBuyerRef} placeholder={t("searchcity_placeholder")} />
                                        </div>
                                        <br/>
                                        <SmallTextLogin>Password</SmallTextLogin>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={createPasswordBuyerRef} placeholder={t("buyertexte19")} />
                                            </div>
                                            <div>
                                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </BiShow>
                                            </div>
                                        </div>
                                        <br/>
                                        <SmallHandlerContainerDiv>
                                            <div>
                                                <ConfirmButton onClick={handleBuyerSignUp}>{t("createNewAccount")}</ConfirmButton>
                                            </div>
                                            <div>
                                                <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                            </div>
                                        </SmallHandlerContainerDiv>
                                    </div>
                                    <br/><br/>
                                </ModalUser>
                            )}

                            {/* Edit account Modal */}
                            {editAccountModalOpen && (
                                <ModalUser>
                                    <FooterSubTitle>{t("edit_account")}</FooterSubTitle>
                                    <br/>
                                    <div ref={authenticateBuyerAccountRef}>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type='number' ref={connectEditPhoneBuyerRef} placeholder={t("buyertexte17")} />
                                            </div>
                                            <div>
                                                <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyEditWhatsapp} />
                                            </div> 
                                        </div>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={connectEditPasswordBuyerRef} placeholder={t("buyertexte19")} />
                                            </div>
                                            <div>
                                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </BiShow>
                                            </div>
                                        </div>
                                        <br/>
                                        <div ref={continueEditBuyerRef} style={{display:'block'}}>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ContinueButton onClick={connectEditBuyerHandler} ></ContinueButton>
                                                </div>
                                            </HandlerContainerDiv>
                                        </div>
                                    </div>
                                    <div ref={detailEditBuyerAccountRef} style={{display:'none'}}>
                                        {editDetailBuyerAccount.map((valeditaccount, indexeditaccount) =>(
                                            <div key={indexeditaccount}>
                                                <input style={{display:'none'}} ref={idEditBuyerAccountRef} defaultValue={valeditaccount.id_userbuyer} />
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("first_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' ref={editFirstNameBuyerRef} defaultValue={valeditaccount.nom} />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("last_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' ref={editLastNameBuyerRef} defaultValue={valeditaccount.prenom} />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type='number' ref={editPhoneBuyerRef} defaultValue={valeditaccount.phone} />
                                                    </div>
                                                    <div>
                                                        <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyWhatsapp} />
                                                    </div>
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("filter_city")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' ref={editCityBuyerRef} defaultValue={valeditaccount.city_user} />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={editPasswordBuyerRef} defaultValue={valeditaccount.password_user} />
                                                    </div>
                                                    <div>
                                                        <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                            {showPassword ? 'Hide' : 'Show'}
                                                        </BiShow>
                                                    </div>
                                                </div>
                                                <br/>
                                                <SmallHandlerContainerDiv>
                                                    <div>
                                                        <ConfirmButton onClick={handleBuyerEdit}>{t("edit")}</ConfirmButton>
                                                    </div>
                                                    <div>
                                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                                    </div>
                                                </SmallHandlerContainerDiv>
                                                <br/><br/>
                                            </div>
                                        ))}
                                    </div>
                                </ModalUser>
                            )}

                            {/* Delete account Modal */}
                            {deleteAccountModalOpen && (
                                <ModalUser>
                                    <FooterSubTitle>{t("delete_account")}</FooterSubTitle>
                                    <br/>
                                    <div ref={authenticateDeleteBuyerAccountRef}>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type='number' ref={connectDeletePhoneBuyerRef} placeholder={t("buyertexte17")} />
                                            </div>
                                            <div>
                                                <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyDeleteWhatsapp} />
                                            </div> 
                                        </div>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={connectDeletePasswordBuyerRef} placeholder={t("buyertexte19")} />
                                            </div>
                                            <div>
                                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </BiShow>
                                            </div>
                                        </div>
                                        <br/>
                                        <div ref={continueDeleteBuyerRef} style={{display:'block'}}>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ContinueButton onClick={connectDeleteBuyerHandler} ></ContinueButton>
                                                </div>
                                            </HandlerContainerDiv>
                                        </div>
                                    </div>
                                    <div ref={detailDeleteBuyerAccountRef} style={{display:'none'}}>
                                        {deleteDetailBuyerAccount.map((valdeleteaccount, indexdeleteaccount) =>(
                                            <div key={indexdeleteaccount}>
                                                <input style={{display:'none'}} ref={idDeleteBuyerAccountRef} defaultValue={valdeleteaccount.id_userbuyer} />
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("first_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valdeleteaccount.nom} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("last_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valdeleteaccount.prenom} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type='number' defaultValue={valdeleteaccount.phone} readOnly />
                                                    </div>
                                                    <div>
                                                        <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyWhatsapp} />
                                                    </div>
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("filter_city")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valdeleteaccount.city_user} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type={showPassword ? 'text' : 'password'} defaultValue={valdeleteaccount.password_user} readOnly />
                                                    </div>
                                                    <div>
                                                        <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                            {showPassword ? 'Hide' : 'Show'}
                                                        </BiShow>
                                                    </div>
                                                </div>
                                                <br/>
                                                <SmallHandlerContainerDiv>
                                                    <div>
                                                        <ConfirmButton onClick={handleBuyerDelete}>{t("delete")}</ConfirmButton>
                                                    </div>
                                                    <div>
                                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                                    </div>
                                                </SmallHandlerContainerDiv>
                                                <br/><br/>
                                            </div>
                                        ))}
                                    </div>
                                </ModalUser>
                            )}

                            {/* View account Modal */}
                            {viewAccountModalOpen && (
                                <ModalUser>
                                    <FooterSubTitle>{t("view_account")}</FooterSubTitle>
                                    <br/>
                                    <div ref={authenticateViewBuyerAccountRef}>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type='number' ref={connectViewPhoneBuyerRef} placeholder={t("buyertexte17")} />
                                            </div>
                                            <div>
                                                <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyViewWhatsapp} />
                                            </div> 
                                        </div>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={connectViewPasswordBuyerRef} placeholder={t("buyertexte19")} />
                                            </div>
                                            <div>
                                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </BiShow>
                                            </div>
                                        </div>
                                        <br/>
                                        <div ref={continueViewBuyerRef} style={{display:'block'}}>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ContinueButton onClick={connectViewBuyerHandler} ></ContinueButton>
                                                </div>
                                            </HandlerContainerDiv>
                                        </div>
                                    </div>
                                    <div ref={detailViewBuyerAccountRef} style={{display:'none'}}>
                                        {viewDetailBuyerAccount.map((valviewaccount, indexviewaccount) =>(
                                            <div key={indexviewaccount}>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("first_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text'  defaultValue={valviewaccount.nom} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("last_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valviewaccount.prenom} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type='number' defaultValue={valviewaccount.phone} readOnly />
                                                    </div>
                                                    <div>
                                                        <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyWhatsapp} />
                                                    </div>
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("filter_city")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valviewaccount.city_user} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type={showPassword ? 'text' : 'password'} defaultValue={valviewaccount.password_user} readOnly />
                                                    </div>
                                                    <div>
                                                        <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                            {showPassword ? 'Hide' : 'Show'}
                                                        </BiShow>
                                                    </div>
                                                </div>
                                                <br/>
                                                <SmallHandlerContainerDiv>
                                                    <div>
                                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                                    </div>
                                                </SmallHandlerContainerDiv>
                                                <br/><br/>
                                            </div>
                                        ))}
                                    </div>
                                </ModalUser>
                            )}
                        </RightSideDiv>
                    </ModalSign>
                </div>
                
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackSignUpBuyerHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>

                <FooterStarting />
                <br/><br/>
                <div id="modaloutalertuserbuyer"></div>
                <div id="backdropoutalertuserbuyer"></div>
            </TermeScroller>
        </div>
    )
}

export default SignUpBuyer
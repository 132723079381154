import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//import './buyer.css';
//
import FooterStarting from '../footer/FooterStarting';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
//
import { BiShow } from 'react-icons/bi';
import { MdPersonAddAlt1, MdModeEdit, MdDelete, MdPreview, MdWhatsapp, MdOutlineChat } from 'react-icons/md';
import { CgCloseO } from 'react-icons/cg';
//
//import GobackBouton from '../reusablecomponents/buttons/GobackBouton';
import ContinueButton from '../reusablecomponents/buttons/ContinueButton';
//
import { retrieveTableQuery } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { HideFooter, AlertModal, getCurrentDate } from '../../helperfunctions/Helper';

import apiUrl from '../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    TermeScroller, 
    FooterSubTitle,
    FooterIntro,
    //LargeInput,
    //PasswordInput,
    //SearchInput,
    //FaqContainer,
    //TextLogin, 
    SmallTextLogin,
    SmallCodeTextLogin,
    HandlerContainerDiv, 
    //BazaliButton,
    //Modal
    ModalSign,
    ConfirmButton,
    CancelButton,
    LeftSideDiv,
    RightSideDiv,
    LeftSideButton,
    ModalUser,
    SmallInput,
    SmallPasswordInput,
    SmallHandlerContainerDiv,
} from '../ComponentStyles';

var userCode = "";
var userPhone = "";

function SignUpSeller() {
    HideFooter();
    
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertuserseller";
    const elementDropId = "backdropoutalertuserseller";
    const buttonHandler = "ouiHandlerAlertusersellerBtn";

    const navigateSignUpSeller = useNavigate();

    const signupInfoSellerRef = useRef(null);
    const modalSignupSellerRef = useRef(null);

    //create account useRefs
    const createFirstNameSellerRef = useRef(null);
    const createLastNameSellerRef = useRef(null);
    const createPhoneSellerRef = useRef(null);
    const createCitySellerRef = useRef(null);
    const createPasswordSellerRef = useRef(null);
    const createCodeSellerRef = useRef(null);
    //confirm account
    const continueSignUpSellerRef = useRef(null);
    const sendCodeSellerRef = useRef(null);
    const activateAccountSellerRef = useRef(null);

    //edit seller account
    const authenticateSellerAccountRef = useRef(null);
    const connectEditPhoneSellerRef = useRef(null);
    const connectEditPasswordSellerRef = useRef();
    const continueEditSellerRef = useRef();
    const detailEditSellerAccountRef = useRef(null);
    //delete seller account
    const authenticateDeleteSellerAccountRef = useRef(null);
    const connectDeletePhoneSellerRef = useRef(null);
    const connectDeletePasswordSellerRef = useRef(null);
    const continueDeleteSellerRef = useRef(null);
    const detailDeleteSellerAccountRef = useRef(null);
    //view seller account
    const authenticateViewSellerAccountRef = useRef(null);
    const connectViewPhoneSellerRef = useRef(null);
    const connectViewPasswordSellerRef = useRef(null);
    const continueViewSellerRef = useRef(null);
    const detailViewSellerAccountRef = useRef(null);
    //edit
    const idEditSellerAccountRef = useRef(null);
    const editFirstNameSellerRef = useRef(null);
    const editLastNameSellerRef = useRef(null);
    const editPhoneSellerRef = useRef(null);
    const editCitySellerRef = useRef(null);
    const editPasswordSellerRef = useRef(null);
    //delete
    const idDeleteSellerAccountRef = useRef(null);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Define state variables for modals
    const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
    const [editAccountModalOpen, setEditAccountModalOpen] = useState(false);
    const [editDetailSellerAccount, setEditDetailSellerAccount] = useState([0]);
    const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
    const [deleteDetailSellerAccount, setDeleteDetailSellerAccount] = useState([0]);
    const [viewAccountModalOpen, setViewAccountModalOpen] = useState(false);
    const [viewDetailSellerAccount, setViewDetailSellerAccount] = useState([0]);

    const handleSignupSeller = async (e) => {
        e.preventDefault();

        signupInfoSellerRef.current.style.display = 'block';
        modalSignupSellerRef.current.style.display = 'none';
    }

    const handleCloseSignupgSeller = async (e) => {
        e.preventDefault();

        signupInfoSellerRef.current.style.display = 'none';
        modalSignupSellerRef.current.style.display = 'block';
    }

    // handle create buttons
    const handleCreateSellerAccount = async (e) => {
        e.preventDefault();

        setCreateAccountModalOpen(true);
        setEditAccountModalOpen(false);
        setDeleteAccountModalOpen(false);
        setViewAccountModalOpen(false);
    }

    //check if the user whatsapp number is valid
    const handleVerifyWhatsapp = async (e) => {
        e.preventDefault();

        const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        //const prefixecountryDiv = '+44'; //for testing purpose
        const createPhoneSeller = createPhoneSellerRef.current.value; //use my Uk whatsapp number for testing purpose
        const userPhoneNumber = prefixecountryDiv+createPhoneSeller;
        //console.log(userPhoneNumber);
        //
        if(!createPhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            const encodedMessage = t("whatsapp_authentication");
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //navigateSignUpBuyer('#');
        }
    }

    //after verifying whatsapp => continiue
    const continueSignUpSellerHandler = async (e) => {

        const createPhoneSeller = createPhoneSellerRef.current.value;
        if(!createPhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            sendCodeSellerRef.current.style.display = 'block';
        }
        //navigateSignUpBuyer('#');
    }

    //send code handler
    const handleSendCodeSeller = async (e) => {
        e.preventDefault();

        //check if fields are empty
        const createFirstNameSeller = createFirstNameSellerRef.current.value;
        const createLastNameSeller = createLastNameSellerRef.current.value;
        const createPhoneSeller = createPhoneSellerRef.current.value;
        //
        const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        //const prefixecountryDiv = '+44';
        //
        if(!createFirstNameSeller){
            const pTextePays = t("firstname_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createLastNameSeller){
            const pTextePays = t("lastname_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createPhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            //generate random user code number
            var randomNumber = Math.floor((Math.random() * 100000) + 1);
            userCode = randomNumber;
            //console.log(userCode);
            const userPhoneNumber = prefixecountryDiv+createPhoneSeller;
            //const userPhoneNumber = '+447459367632';
            //console.log(userPhoneNumber);
            //send code generated to the user
            const encodedMessage = `Your verification code is: ${userCode}. Copy it and insert it in create account process and create now your new account. `;
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //
            userPhone = createPhoneSeller;
            //navigateSignUpBuyer('#');
            activateAccountSellerRef.current.style.display = 'block';
        }
    }

    const handleModalClose = async () => {
        setCreateAccountModalOpen(false);
        setEditAccountModalOpen(false);
        setDeleteAccountModalOpen(false);
        setViewAccountModalOpen(false);
    }

    const handleSellerSignUp = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;
        var choixlangueDiv = "";

        const namecountryDiv = document.getElementById('namecountry').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        const langueDiv = document.getElementById('choixlangue').innerText;
        if(!langueDiv){
            choixlangueDiv = document.getElementById('languecountry').innerText;
        }else{
            choixlangueDiv = document.getElementById('choixlangue').innerText;
        }
        //
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }
        //
        const createFirstNameSeller = createFirstNameSellerRef.current.value;
        const createLastNameSeller = createLastNameSellerRef.current.value;
        const createPhoneSeller = userPhone;
        const createSigle = "seller";
        const createCitySeller = createCitySellerRef.current.value;
        const createPasswordSeller = createPasswordSellerRef.current.value;
        const createStatus = "online";
        const createDate = getCurrentDate();
        //compare the generated user code to the input user code
        const createCodeSeller = createCodeSellerRef.current.value;
        var codeSeller = parseInt(createCodeSeller)
        var userCodeSeller = parseInt(userCode);
        if(codeSeller === userCodeSeller){
            if(!namecountryDiv){
                const pTextePays = t("principaltexte2");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!createFirstNameSeller){
                const pTextePays = t("firstname_placeholder");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!createLastNameSeller){
                const pTextePays = t("lastname_placeholder");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!createPhoneSeller){
                const pTextePays = t("please_number");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
            }else if(!createCitySeller){
                const pTextePays = t("searchcity_placeholder");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
            }else if(!createPasswordSeller){
                const pTextePays = t("buyertexte19");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
            }else{
                //check if this phone number already exist
                await Axios.post(`${apiUrl}/check/seller/phone`, {
                    phoneSeller:createPhoneSeller,
                    tableNameSeller:tableName,
                }).then((response1) => {
                    resultat1 = response1.data.length;
                    if(resultat1 === 0){
                        //create new use the same axios parameters account
                        Axios.post(`${apiUrl}/create/seller/account`, {
                            namecountry:namecountryDiv,
                            codecountry:codecountryDiv,
                            prefixecountry:prefixecountryDiv,
                            choixlangue:choixlangueDiv,
                            firstNameSeller:createFirstNameSeller,
                            lastNameSeller:createLastNameSeller,
                            phoneSeller:createPhoneSeller,
                            userCodeSeller:userCode,
                            sigleSeller:createSigle,
                            citySeller:createCitySeller,
                            passwordSeller:createPasswordSeller,
                            statusSeller:createStatus,
                            dateSeller:createDate,
                            tableNameSeller:tableName,
                        }).then(() => {
                            const pTextePays = t("buyer_success");
                            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                            setCreateAccountModalOpen(false);
                            setEditAccountModalOpen(false);
                            setDeleteAccountModalOpen(false);
                            setViewAccountModalOpen(false);
                            //navigateSignUpBuyer(-1);
                        });
                    }else{
                        const pTextePays = t("buyer_exists");
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
                    }
                });
            }
        }else{
            const pTextePays = t("signup_error");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }

    //edit seller user
    const handleVerifyEditWhatsapp = async (e) => {
        const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        //const prefixecountryDiv = '+44'; //for testing purpose
        const connectEditPhoneSeller = connectEditPhoneSellerRef.current.value; //use my Uk whatsapp number for testing purpose
        const userPhoneNumber = prefixecountryDiv+connectEditPhoneSeller;
        //console.log(userPhoneNumber);
        //
        if(!connectEditPhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            const encodedMessage = t("whatsapp_authentication");
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //navigateSignUpBuyer('#');
        }
    }

    //handle edit seller account
    const handleEditSellerAccount = async (e) => {
        e.preventDefault();

        setEditAccountModalOpen(true);
        setCreateAccountModalOpen(false);
        setDeleteAccountModalOpen(false);
        setViewAccountModalOpen(false);
        //
    }

    const connectEditSellerHandler = async () => {
        //e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat = '';
        
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const connectEditPhoneSeller = connectEditPhoneSellerRef.current.value;
        const connectEditPasswordSeller = connectEditPasswordSellerRef.current.value;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }
        //
        if(!connectEditPhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!connectEditPasswordSeller){
            const pTextePays = t("buyertexte19");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            await Axios.post(`${apiUrl}/seller/connexion`, {
                userPhone:connectEditPhoneSeller,
                userPassword:connectEditPasswordSeller,
                tableNameSeller:tableName,
            }).then((response2) => {
                resultat = response2.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_login");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setEditDetailSellerAccount(response2.data);
                    //
                    authenticateSellerAccountRef.current.style.display = 'none';
                    detailEditSellerAccountRef.current.style.display = 'block';
                }
            });
        }
    }

    const handleSellerEdit = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat1 = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const idEditSellerAccount = idEditSellerAccountRef.current.value;
        const editFirstNameSeller = editFirstNameSellerRef.current.value;
        const editLastNameSeller = editLastNameSellerRef.current.value;
        const editPhoneSeller = editPhoneSellerRef.current.value;
        const editCitySeller = editCitySellerRef.current.value;
        const editPasswordSeller = editPasswordSellerRef.current.value;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }

        if(!editFirstNameSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editLastNameSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editPhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editCitySeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!editPasswordSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            //check if this phone number already exist
            await Axios.post(`${apiUrl}/check/user/phone`, {
                phoneSeller:editPhoneSeller,
                tableNameSeller:tableName,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    //create new buyer account
                    Axios.post(`${apiUrl}/edit/seller/account`, {
                        idEditSellerAccount:idEditSellerAccount,
                        firstNameSeller:editFirstNameSeller,
                        lastNameSeller:editLastNameSeller,
                        phoneSeller:editPhoneSeller,
                        citySeller:editCitySeller,
                        passwordSeller:editPasswordSeller,
                        tableNameSeller:tableName,
                    }).then(() => {
                        const pTextePays = t("buyer_success");
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                        setCreateAccountModalOpen(false);
                        setEditAccountModalOpen(false);
                        setDeleteAccountModalOpen(false);
                        setViewAccountModalOpen(false);
                        //navigateSignUpBuyer(-1);
                    });
                }else{
                    const pTextePays = t("buyer_exists");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
                    setCreateAccountModalOpen(false);
                    setEditAccountModalOpen(false);
                    setDeleteAccountModalOpen(false);
                    setViewAccountModalOpen(false);
                }
            });
        }
    }

    //delete seller user
    const handleVerifyDeleteWhatsapp = async (e) => {
        const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        //const prefixecountryDiv = '+44'; //for testing purpose
        const connectDeletePhoneSeller = connectDeletePhoneSellerRef.current.value; //use my Uk whatsapp number for testing purpose
        const userPhoneNumber = prefixecountryDiv+connectDeletePhoneSeller;
        //console.log(userPhoneNumber);
        //
        if(!connectDeletePhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            const encodedMessage = t("whatsapp_authentication");
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //navigateSignUpBuyer('#');
        }
    }

    //handle delete seller account
    const handleDeleteSellerAccount = async (e) => {
        e.preventDefault();

        setEditAccountModalOpen(false);
        setCreateAccountModalOpen(false);
        setDeleteAccountModalOpen(true);
        setViewAccountModalOpen(false);
        //
    }

    const connectDeleteSellerHandler = async () => {
        //e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const connectDeletePhoneSeller = connectDeletePhoneSellerRef.current.value;
        const connectDeletePasswordSeller = connectDeletePasswordSellerRef.current.value;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }
        //
        if(!connectDeletePhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!connectDeletePasswordSeller){
            const pTextePays = t("buyertexte19");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            await Axios.post(`${apiUrl}/seller/connexion`, {
                userPhone:connectDeletePhoneSeller,
                userPassword:connectDeletePasswordSeller,
                tableNameSeller:tableName,
            }).then((response3) => {
                resultat = response3.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_login");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setDeleteDetailSellerAccount(response3.data);
                    //
                    authenticateDeleteSellerAccountRef.current.style.display = 'none';
                    detailDeleteSellerAccountRef.current.style.display = 'block';
                }
            });
        }
    }

    const handleSellerDelete = async (e) => {
        e.preventDefault();

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const idDeleteSellerAccount = idDeleteSellerAccountRef.current.value;
        //
        var tableName = "";
        var signupTable = [];
        //var resultat = '';
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }
        await Axios.post(`${apiUrl}/delete/seller/account`, {
            deleteId:idDeleteSellerAccount,
            tableNameSeller:tableName,
        }).then(() => {
            setEditAccountModalOpen(false);
            setCreateAccountModalOpen(false);
            setDeleteAccountModalOpen(false);
            setViewAccountModalOpen(false);
        });
    }

    //view buyer account
    const handleVerifyViewWhatsapp = async (e) => {
        const prefixecountryDiv = document.getElementById('prefixecountry').innerText;
        //const prefixecountryDiv = '+44'; //for testing purpose
        const connectViewPhoneSeller = connectViewPhoneSellerRef.current.value; //use my Uk whatsapp number for testing purpose
        const userPhoneNumber = prefixecountryDiv+connectViewPhoneSeller;
        //console.log(userPhoneNumber);
        //
        if(!connectViewPhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            const encodedMessage = t("whatsapp_authentication");
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${userPhoneNumber}&text=${encodedMessage}`;
            //window.location.href = whatsappUrl;
            window.open(whatsappUrl, '_blank');
            //navigateSignUpBuyer('#');
        }
    }

    const connectViewSellerHandler = async () => {
        //e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const connectViewPhoneSeller = connectViewPhoneSellerRef.current.value;
        const connectViewPasswordSeller = connectViewPasswordSellerRef.current.value;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }
        //
        if(!connectViewPhoneSeller){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!connectViewPasswordSeller){
            const pTextePays = t("buyertexte19");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            await Axios.post(`${apiUrl}/seller/connexion`, {
                userPhone:connectViewPhoneSeller,
                userPassword:connectViewPasswordSeller,
                tableNameSeller:tableName,
            }).then((response4) => {
                resultat = response4.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_login");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setViewDetailSellerAccount(response4.data);
                    //
                    authenticateViewSellerAccountRef.current.style.display = 'none';
                    detailViewSellerAccountRef.current.style.display = 'block';
                }
            });
        }
    }

    //handle view buyer account
    const handleViewSellerAccount = async (e) => {
        e.preventDefault();

        setEditAccountModalOpen(false);
        setCreateAccountModalOpen(false);
        setDeleteAccountModalOpen(false);
        setViewAccountModalOpen(true);
        //
    }


    const goBackSignUpSellerHandler = async () => {

        navigateSignUpSeller(-1);
    }

    return (
        <div id='signupseller'>
            <TermeScroller>
                <br/>
                <div style={{textAlign:'center'}}>
                    <FooterSubTitle>{t("seller")} {t("createNewAccount")}</FooterSubTitle>
                </div>
                <br/>
                <div style={{float: 'left'}}>
                    <MdOutlineChat style={{color:'#246175', fontSize:'35px', cursor:'pointer'}} onClick={handleSignupSeller} />
                </div>
                <br/>
                <div ref={signupInfoSellerRef} style={{display: 'none'}}>
                    <FooterIntro>{t("signup_section_1")}</FooterIntro>
                    <br/>
                    <FooterSubTitle>1. {t("signupsoustitre1")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte1")}</li>
                    </ul>
                
                    <FooterSubTitle>2. {t("signupsoustitre2")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte2")}</li>
                        <li>{t("signupsoustitretexte3")}</li>
                        <li>{t("signupsoustitretexte4")}</li>
                        <li>{t("signupsoustitretexte5")}</li>
                    </ul>
                
                    <FooterSubTitle>3. {t("signupsoustitre3")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte6")}</li>
                    </ul>
                
                    <FooterSubTitle>4. {t("signupsoustitre4")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte7")}</li>
                        <li>{t("signupsoustitretexte8")}</li>
                    </ul>
                
                    <FooterSubTitle>5. {t("signupsoustitre5")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte9")}</li>
                    </ul>
                
                    <FooterSubTitle>6. {t("signupsoustitre6")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("signupsoustitretexte10")}</li>
                        <li>{t("signupsoustitretexte11")}</li>
                    </ul>
                
                    <FooterIntro>{t("signup_section_2")}</FooterIntro>
                
                    <FooterIntro>{t("signup_section_3")}</FooterIntro>
                    <br/>
                    <div style={{marginLeft:'20px'}}>
                        <CgCloseO style={{color:'red', fontSize:'35px', cursor:'pointer'}} onClick={handleCloseSignupgSeller} />
                    </div>
                    <br/><br/>
                </div>

                <br/>
                <div ref={modalSignupSellerRef}>
                    <ModalSign>
                        <LeftSideDiv>
                            <div>
                                <LeftSideButton onClick={handleCreateSellerAccount}>
                                    <MdPersonAddAlt1 style={{fontSize:'30px'}} />
                                    {t("create")}
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleEditSellerAccount}>
                                    <MdModeEdit style={{fontSize:'30px'}} />
                                    {t("edit")}
                                </LeftSideButton>
                            </div>

                            <div>
                                <LeftSideButton onClick={handleDeleteSellerAccount}>
                                    <MdDelete style={{fontSize:'30px'}} />
                                    {t("delete")}
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleViewSellerAccount}>
                                    <MdPreview style={{fontSize:'30px'}} />
                                    {t("view")}
                                </LeftSideButton>
                            </div>
                        </LeftSideDiv>
                        <RightSideDiv>
                            {/* Create account Modal */}
                            {createAccountModalOpen && (
                                <ModalUser>
                                    <FooterSubTitle>{t("create_account")}</FooterSubTitle>
                                    <br/>
                                    <SmallTextLogin>{t("first_name")}</SmallTextLogin>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallInput type='text' ref={createFirstNameSellerRef} placeholder={t("firstname_placeholder")} />
                                    </div>
                                    <br/>
                                    <SmallTextLogin>{t("last_name")}</SmallTextLogin>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallInput type='text' ref={createLastNameSellerRef} placeholder={t("lastname_placeholder")} />
                                    </div>
                                    <br/>
                                    <SmallTextLogin>{t("buyertexte16")}</SmallTextLogin>
                                    <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallPasswordInput type='number' ref={createPhoneSellerRef} placeholder={t("buyertexte17")} />
                                        </div>
                                        <div>
                                            <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyWhatsapp} />
                                        </div>
                                    </div>
                                    <br/>
                                    <div ref={continueSignUpSellerRef} style={{display:'block'}}>
                                        <HandlerContainerDiv>
                                            <div>
                                                <ContinueButton onClick={continueSignUpSellerHandler} ></ContinueButton>
                                            </div>
                                        </HandlerContainerDiv>
                                    </div>
                                    <br/>
                                    <div ref={sendCodeSellerRef} style={{display:'none'}}>
                                        <SmallHandlerContainerDiv>
                                            <div>
                                                <ConfirmButton onClick={handleSendCodeSeller}>{t("send_code")}</ConfirmButton>
                                            </div>
                                            <div>
                                                <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                            </div>
                                        </SmallHandlerContainerDiv>
                                    </div>
                                    <br/>
                                    <div ref={activateAccountSellerRef} style={{display:'none', marginLeft:'20px'}}>
                                        <SmallCodeTextLogin>{t("code_user")}</SmallCodeTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallPasswordInput type='number' ref={createCodeSellerRef} placeholder={t("codeuser_placeholder")} />
                                        </div>
                                        <br/>
                                        <SmallTextLogin>{t("filter_city")}</SmallTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallPasswordInput type='text' ref={createCitySellerRef} placeholder={t("searchcity_placeholder")} />
                                        </div>
                                        <br/>
                                        <SmallTextLogin>Password</SmallTextLogin>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={createPasswordSellerRef} placeholder={t("buyertexte19")} />
                                            </div>
                                            <div>
                                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </BiShow>
                                            </div>
                                        </div>
                                        <br/>
                                        <SmallHandlerContainerDiv>
                                            <div>
                                                <ConfirmButton onClick={handleSellerSignUp}>{t("createNewAccount")}</ConfirmButton>
                                            </div>
                                            <div>
                                                <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                            </div>
                                        </SmallHandlerContainerDiv>
                                    </div>
                                    <br/><br/>
                                </ModalUser>
                            )}

                            {/* Edit account Modal */}
                            {editAccountModalOpen && (
                                <ModalUser>
                                    <FooterSubTitle>{t("edit_account")}</FooterSubTitle>
                                    <br/>
                                    <div ref={authenticateSellerAccountRef}>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type='number' ref={connectEditPhoneSellerRef} placeholder={t("buyertexte17")} />
                                            </div>
                                            <div>
                                                <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyEditWhatsapp} />
                                            </div> 
                                        </div>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={connectEditPasswordSellerRef} placeholder={t("buyertexte19")} />
                                            </div>
                                            <div>
                                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </BiShow>
                                            </div>
                                        </div>
                                        <br/>
                                        <div ref={continueEditSellerRef} style={{display:'block'}}>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ContinueButton onClick={connectEditSellerHandler} ></ContinueButton>
                                                </div>
                                            </HandlerContainerDiv>
                                        </div>
                                    </div>
                                    <div ref={detailEditSellerAccountRef} style={{display:'none'}}>
                                        {editDetailSellerAccount.map((valeditaccount, indexeditaccount) =>(
                                            <div key={indexeditaccount}>
                                                <input style={{display:'none'}} ref={idEditSellerAccountRef} defaultValue={valeditaccount.id_userseller} />
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("first_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' ref={editFirstNameSellerRef} defaultValue={valeditaccount.nom} />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("last_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' ref={editLastNameSellerRef} defaultValue={valeditaccount.prenom} />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type='number' ref={editPhoneSellerRef} defaultValue={valeditaccount.phone} />
                                                    </div>
                                                    <div>
                                                        <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyWhatsapp} />
                                                    </div>
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("filter_city")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' ref={editCitySellerRef} defaultValue={valeditaccount.city_user} />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={editPasswordSellerRef} defaultValue={valeditaccount.password_user} />
                                                    </div>
                                                    <div>
                                                        <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                            {showPassword ? 'Hide' : 'Show'}
                                                        </BiShow>
                                                    </div>
                                                </div>
                                                <br/>
                                                <SmallHandlerContainerDiv>
                                                    <div>
                                                        <ConfirmButton onClick={handleSellerEdit}>{t("edit")}</ConfirmButton>
                                                    </div>
                                                    <div>
                                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                                    </div>
                                                </SmallHandlerContainerDiv>
                                                <br/><br/>
                                            </div>
                                        ))}
                                    </div>
                                </ModalUser>
                            )}

                            {/* Delete account Modal */}
                            {deleteAccountModalOpen && (
                                <ModalUser>
                                    <FooterSubTitle>{t("delete_account")}</FooterSubTitle>
                                    <br/>
                                    <div ref={authenticateDeleteSellerAccountRef}>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type='number' ref={connectDeletePhoneSellerRef} placeholder={t("buyertexte17")} />
                                            </div>
                                            <div>
                                                <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyDeleteWhatsapp} />
                                            </div> 
                                        </div>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={connectDeletePasswordSellerRef} placeholder={t("buyertexte19")} />
                                            </div>
                                            <div>
                                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </BiShow>
                                            </div>
                                        </div>
                                        <br/>
                                        <div ref={continueDeleteSellerRef} style={{display:'block'}}>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ContinueButton onClick={connectDeleteSellerHandler} ></ContinueButton>
                                                </div>
                                            </HandlerContainerDiv>
                                        </div>
                                    </div>
                                    <div ref={detailDeleteSellerAccountRef} style={{display:'none'}}>
                                        {deleteDetailSellerAccount.map((valdeleteaccount, indexdeleteaccount) =>(
                                            <div key={indexdeleteaccount}>
                                                <input style={{display:'none'}} ref={idDeleteSellerAccountRef} defaultValue={valdeleteaccount.id_userseller} />
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("first_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valdeleteaccount.nom} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("last_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valdeleteaccount.prenom} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type='number' defaultValue={valdeleteaccount.phone} readOnly />
                                                    </div>
                                                    <div>
                                                        <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyWhatsapp} />
                                                    </div>
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("filter_city")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valdeleteaccount.city_user} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type={showPassword ? 'text' : 'password'} defaultValue={valdeleteaccount.password_user} readOnly />
                                                    </div>
                                                    <div>
                                                        <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                            {showPassword ? 'Hide' : 'Show'}
                                                        </BiShow>
                                                    </div>
                                                </div>
                                                <br/>
                                                <SmallHandlerContainerDiv>
                                                    <div>
                                                        <ConfirmButton onClick={handleSellerDelete}>{t("delete")}</ConfirmButton>
                                                    </div>
                                                    <div>
                                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                                    </div>
                                                </SmallHandlerContainerDiv>
                                                <br/><br/>
                                            </div>
                                        ))}    
                                    </div>
                                </ModalUser>
                            )}

                            {/* View account Modal */}
                            {viewAccountModalOpen && (
                                <ModalUser>
                                    <FooterSubTitle>{t("view_account")}</FooterSubTitle>
                                    <br/>
                                    <div ref={authenticateViewSellerAccountRef}>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type='number' ref={connectViewPhoneSellerRef} placeholder={t("buyertexte17")} />
                                            </div>
                                            <div>
                                                <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyViewWhatsapp} />
                                            </div> 
                                        </div>
                                        <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                        <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallPasswordInput type={showPassword ? 'text' : 'password'} ref={connectViewPasswordSellerRef} placeholder={t("buyertexte19")} />
                                            </div>
                                            <div>
                                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                    {showPassword ? 'Hide' : 'Show'}
                                                </BiShow>
                                            </div>
                                        </div>
                                        <br/>
                                        <div ref={continueViewSellerRef} style={{display:'block'}}>
                                            <HandlerContainerDiv>
                                                <div>
                                                    <ContinueButton onClick={connectViewSellerHandler} ></ContinueButton>
                                                </div>
                                            </HandlerContainerDiv>
                                        </div>
                                    </div>
                                    <div ref={detailViewSellerAccountRef} style={{display:'none'}}>
                                        {viewDetailSellerAccount.map((valviewaccount, indexviewaccount) =>(
                                            <div key={indexviewaccount}>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("first_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text'  defaultValue={valviewaccount.nom} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("last_name")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valviewaccount.prenom} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("buyertexte16")}</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type='number' defaultValue={valviewaccount.phone} readOnly />
                                                    </div>
                                                    <div>
                                                        <MdWhatsapp style={{color:'#00e600', fontSize:'35px'}} onClick={handleVerifyWhatsapp} />
                                                    </div>
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>{t("filter_city")}</span>
                                                <div style={{display:'block',textAlign:'center'}}>
                                                    <SmallInput type='text' defaultValue={valviewaccount.city_user} readOnly />
                                                </div>
                                                <br/>
                                                <span style={{fontSize:'0.9rem', fontWeight:'600'}}>Password</span>
                                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                                    <div style={{display:'block',textAlign:'center'}}>
                                                        <SmallPasswordInput type={showPassword ? 'text' : 'password'} defaultValue={valviewaccount.password_user} readOnly />
                                                    </div>
                                                    <div>
                                                        <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                                            {showPassword ? 'Hide' : 'Show'}
                                                        </BiShow>
                                                    </div>
                                                </div>
                                                <br/>
                                                <SmallHandlerContainerDiv>
                                                    <div>
                                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                                    </div>
                                                </SmallHandlerContainerDiv>
                                                <br/><br/>
                                            </div>
                                        ))}
                                    </div>
                                </ModalUser>
                            )}
                        </RightSideDiv>
                    </ModalSign>
                </div>
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackSignUpSellerHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>

                <FooterStarting />
                <br/><br/>
                <div id="modaloutalertuserseller"></div>
                <div id="backdropoutalertuserseller"></div>
            </TermeScroller>
        </div>
    )
}

export default SignUpSeller
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//import necessary components to render
import EventsTopUp from '../dashtopup/EventsTopUp';
import CreateEvents from './CreateEvents';
import CrudEvents from './CrudEvents';
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdModeEdit } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
import { BsUpload } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal, LimitCharacters, differenceTwoDates, formatDateData, frenchDateFormat, ValidateVideoDuration } from '../../../../helperfunctions/Helper';
import WarningRefundModal from '../../../reusablecomponents/componentutils/WarningRefundModal';
import WarningMultiModal from '../../../reusablecomponents/componentutils/WarningMultiModal';
import WarningModal from '../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallHandlerContainerDiv,
    SmallSaveButton,
    SmallEditButton,
} from '../../../ComponentStyles';

function EditEvents() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalerteditevents";
    const elementDropId = "backdropoutalerteditevents";
    const buttonHandler = "ouiHandlerAlertEditEventsBtn";

    // Define state variables for modals
    const [titleModalOpen, setTitleModalOpen] = useState(true);
    const [startEditEventsModalOpen, setStartEditEventsModalOpen] = useState(true);
    const [editEventsModalOpen, setEditEventsModalOpen] = useState(false);

    const [imagePreview, setImagePreview] = useState(false);
    const [videoPreview, setVideoPreview] = useState(false);
    //
    const [dateEndSubscribeInput, setDateEndSubscribeInput] = useState([0]);
    //
    const [showModalSubscribe, setShowModalSubscribe] = useState(false);
    const [showModalExpired, setShowModalExpired] = useState(false);
    const [showModalCreateEvent, setShowModalCreateEvent] = useState(false);
    //
    const [eventsTopUpModalOpen, setEventsTopUpModalOpen] = useState(false);
    const [crudEventsModalOpen, setCrudEventsModalOpen] = useState(false);
    const [createEventsModalOpen, setCreateEventsModalOpen] = useState(false);

    //image sources and resources states
    const [src, setSrc] = useState("");
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    //video sources and resources states
    const [srcVideo, setSrcVideo] = useState("");
    const [fileVideo, setFileVideo] = useState("");
    const [fileNameVideo, setFileNameVideo] = useState("");

    const [userEditEvents, setUserEditEvents] = useState([0]);

    const idEditAdvertiseRef = useRef(null);
    const editEventsImageRef = useRef(null);
    const eventsEditNameRef = useRef(null);
    const eventsEditDescriptionRef = useRef(null);
    const eventsEditVideoRef = useRef(null);
    //
    const editImageFileNameRef = useRef(null);
    const editVideoFileNameRef = useRef(null);

    const handleEditConfirmLogoRef = useRef(null);
    const handleEditConfirmVideoRef = useRef(null);

    //
    const [eventsEditContent, setEventEditContents] = useState(false);
    const [editEvents, setEditEvents] = useState(false);

    const handleStartEvents = async () => {

        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        var detailEvents = [];
        var endDate = "";
        var titleEvent = "";
        const firstWhere = "code_user";

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table15;
        }

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableName,
            firstWhere:firstWhere,
            firstParameter:codeutilisateurDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_event");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                detailEvents = response1.data;
                //retrieve data
                for (let i=0; i<resultat1; i++){
                    endDate = formatDateData(detailEvents[i].date_end);
                    titleEvent = detailEvents[i].title_advertise;
                }
                //check if subscription still valid
                var resultDiference = differenceTwoDates(endDate);
                if(resultDiference < 0){
                    setShowModalSubscribe(false);
                    setShowModalExpired(true);
                    //
                    setEventsTopUpModalOpen(false);
                    setCrudEventsModalOpen(false);
                    setCreateEventsModalOpen(false);
                }else{
                    //check if an event already exists
                    if(titleEvent === ""){
                        setShowModalCreateEvent(true);
                        setShowModalSubscribe(false);
                        setShowModalExpired(false);
                        //
                        setEventsTopUpModalOpen(false);
                        setCrudEventsModalOpen(false);
                        setCreateEventsModalOpen(false);
                    }else{
                        setUserEditEvents(response1.data);
                        setTitleModalOpen(true);
                        setStartEditEventsModalOpen(false);
                        setEditEventsModalOpen(true);
                        setShowModalSubscribe(false);
                        setShowModalExpired(false);
                        //
                        setEventsTopUpModalOpen(false);
                        setCrudEventsModalOpen(false);
                        setCreateEventsModalOpen(false);
                    }
                }
            }
        });
    }
    //

    const handleModalCreate = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartEditEventsModalOpen(false);
        setEditEventsModalOpen(false);
        setEditEvents(false);
        setShowModalSubscribe(false);
        setShowModalExpired(false);
        setCreateEventsModalOpen(true);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(false);
        setShowModalCreateEvent(false);
    }
    //
    const handleModalNotNow = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartEditEventsModalOpen(false);
        setEditEventsModalOpen(false);
        setEditEvents(false);
        setShowModalSubscribe(false);
        setShowModalExpired(false);
        setShowModalCreateEvent(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setShowModalCreateEvent(false);
    }
    //
    const handleCancelModal = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartEditEventsModalOpen(false);
        setEditEventsModalOpen(false);
        setEditEvents(false);
        setShowModalSubscribe(false);
        setShowModalExpired(false);
        setShowModalCreateEvent(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setShowModalCreateEvent(false);
    }

    //Limit the total number of characters (not words)
    var descriptionWords = 0;
    var newCharacterLimitDescription = 200 - descriptionWords;
    const [textDescription, setTextDescription] = useState('');
    //limit characters for description
    const handleChangeDescription = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, newCharacterLimitDescription);
        setTextDescription(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = newCharacterLimitDescription - textDescription.length;

    const handleEditImageChange = async (e) => {
        
        const file = e.target.files[0];
        if(file){
            // Transform file into blob URL
            setSrc(URL.createObjectURL(file));
            //
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
            //
            setImagePreview(true);
            setEventEditContents(false);
            setEditEvents(false);
        }
    }

    // Helper function to set the subdirectory value
    const setSubDirectory = (value) => {
        Axios.post(`${apiUrl}/set-subdirectory`, {
            tableProduct: value,
        }).then(() => {
            // Log success message or handle response
            const pTextePays = t("image_uplaod");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }).catch(() => {
            // Handle error
            const pTextePays = t("image_failed");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        });
    }

    const handleEditConfirmLogo = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table16; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        setEventEditContents(true);
        setEditEvents(false);
        handleEditConfirmLogoRef.current.style.display = 'none';
    }

    const handleEditVideoChange = async (e) => {

        // Get the uploaded file
        const file = e.target.files[0];

        //error text
        const errorText = t("error_limit");
        // Validate video duration
        const isValidDuration = await ValidateVideoDuration(file, errorText);
        //
        if(isValidDuration){
            // Transform file into blob URL
            setSrcVideo(URL.createObjectURL(file));
            //
            setFileVideo(e.target.files[0]);
            setFileNameVideo(e.target.files[0].name);
            //
            setVideoPreview(true);
            setEventEditContents(true);
            setEditEvents(false);
        }else{
            //Video duration exceeds the allowed limit
            const pTextePays = t("alert_duration");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            e.target.value = '';
        };
    }

    const handleEditConfirmVideo = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table16; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        setEditEvents(true);
        setEventEditContents(true);
        handleEditConfirmVideoRef.current.style.display = 'none';
    }

    const handleEditEvents = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat2 = 0;
        var detailEvents = [];
        var endDate = "";
        //
        var tableProduct = "";
        var productTable = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table16; //directory to save image in server side
        }
        //
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table15;
        }

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const firstWhere = "code_user";
        //
        const idEditAdvertise = idEditAdvertiseRef.current.value;
        const editEventsImage = editEventsImageRef.current.value;
        const eventsEditName = eventsEditNameRef.current.value;
        const eventsEditDescription = eventsEditDescriptionRef.current.value;
        const eventsEditVideo = eventsEditVideoRef.current.value;
        const editImageFileName = editImageFileNameRef.current.value;
        var strImage = editImageFileName;
        const editVideoFileName = editVideoFileNameRef.current.value;
        var strVideo = editVideoFileName;
        //replace with (.)
        const replaceEditImageFileName = strImage.replace(`${apiUrl}`, "./uploads");
        const replaceEditVideoFileName = strVideo.replace(`${apiUrl}`, "./uploads");

        if(!codeutilisateurDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editEventsImage){
            const pTextePays = t("upload_logo");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!eventsEditName){
            const pTextePays = t("eventname_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!eventsEditDescription){
            const pTextePays = t("the_event_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!eventsEditVideo){
            const pTextePays = t("upload_video");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            //check if seller subscription not expired
            await Axios.post(`${apiUrl}/check/one/parameter/table`, {
                tableNameSeller:tableName,
                firstWhere:firstWhere,
                firstParameter:codeutilisateurDiv,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    const pTextePays = t("error_data");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    detailEvents = response2.data;
                    //retrieve data
                    for (let i=0; i<resultat2; i++){
                        endDate = formatDateData(detailEvents[i].date_end);
                    }
                    //
                    setDateEndSubscribeInput(endDate);
                    //check if subscription still valid
                    var resultDiference = differenceTwoDates(endDate);
                    if(resultDiference < 0){
                        setShowModalSubscribe(false);
                        setShowModalExpired(false);
                        //
                        setEventsTopUpModalOpen(false);
                        setCrudEventsModalOpen(false);
                        setCreateEventsModalOpen(false);
                    }else{
                        //upload image, video and inputs
                        const formData = new FormData();
                        //inputs
                        formData.append("eventseditname", eventsEditName);
                        formData.append("eventseditdescription", eventsEditDescription);
                        formData.append("ideditadvertise", idEditAdvertise);
                        formData.append("replaceeditimagefilname", replaceEditImageFileName);
                        formData.append("replaceeditvideofilname", replaceEditVideoFileName);
                        //file image
                        formData.append("productfile", file);
                        formData.append("productfile", fileName);
                        //file video
                        formData.append("productfile", fileVideo);
                        formData.append("productfile", fileNameVideo);
                        //save image and video in directory belonging
                        formData.append("tablename", tableName);
                        formData.append("thefile", tableProduct);
                        formData.append("apiurl", apiUrl);

                        //update andsave event
                        Axios.post(`${apiUrl}/edit/event/advertise`, formData).then(() => {
                            setTitleModalOpen(false);
                            setStartEditEventsModalOpen(false);
                            setEditEventsModalOpen(false);
                            setEditEvents(false);
                            setShowModalSubscribe(false);
                            setShowModalExpired(false);
                            setShowModalExpired(false);
                            setShowModalCreateEvent(false);
                            //
                            setEventsTopUpModalOpen(false);
                            setCrudEventsModalOpen(true);
                            setCreateEventsModalOpen(false);
                        });
                    }
                }
            });
        }
    }
    //
    
    const handleModalEndSubscribe = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartEditEventsModalOpen(false);
        setEditEventsModalOpen(false);
        setEditEvents(false);
        setShowModalExpired(false);
        setShowModalCreateEvent(false);
        setCrudEventsModalOpen(true);
        setShowModalSubscribe(false);
        setShowModalExpired(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setCreateEventsModalOpen(false);
    }
    //
    const handleCancelModalEndSubscribe = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartEditEventsModalOpen(false);
        setEditEventsModalOpen(false);
        setEditEvents(false);
        setShowModalExpired(false);
        setShowModalCreateEvent(false);
        setCrudEventsModalOpen(true);
        setShowModalSubscribe(false);
        setShowModalExpired(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setCreateEventsModalOpen(false);
    }
    //
    const handleModalExpired = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartEditEventsModalOpen(false);
        setEditEventsModalOpen(false);
        setEditEvents(false);
        setShowModalSubscribe(false);
        setShowModalExpired(false);
        setCreateEventsModalOpen(false);
        //
        setEventsTopUpModalOpen(true);
        setCrudEventsModalOpen(false);
        setShowModalCreateEvent(false);
    }
    //
    const handleCancelModalExpired = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartEditEventsModalOpen(false);
        setEditEventsModalOpen(false);
        setEditEvents(false);
        setShowModalSubscribe(false);
        setShowModalExpired(false);
        setCreateEventsModalOpen(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setShowModalCreateEvent(false);
    }

    return (
        <div>
            <ModalUser>
                {titleModalOpen && (
                    <div>
                        <FooterSubTitle>{t("dash_edit_events")}</FooterSubTitle>
                        <br/>
                    </div>
                )}
                
                {startEditEventsModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartEvents}
                        />
                    </div>
                )}

                {editEventsModalOpen && (
                    <div>
                        {userEditEvents.map((valeditdetail, indexeditdetail) => (
                            <ModalUser key={indexeditdetail}>
                                <input style={{display:'none'}} ref={idEditAdvertiseRef} defaultValue={valeditdetail.id_advertise} />
                                <SmallTextLogin>{t("event_logo")}</SmallTextLogin>
                                <div style={{display:'none',textAlign:'center'}}>
                                    <SmallInput type='text' ref={editImageFileNameRef} defaultValue={valeditdetail.image_advertise} readOnly />
                                </div>
                                <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                    <img src={valeditdetail.image_advertise} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                </div>
                                <br/>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <label htmlFor='upload-btn' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                                        <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                                        {t("upload_logo")}
                                    </label>
                                    <SmallInput 
                                        id="upload-btn"
                                        type='file' 
                                        ref={editEventsImageRef} 
                                        onChange={handleEditImageChange} 
                                        accept="image/*" 
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                {imagePreview && (
                                    <div>
                                        <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                            <img src={src} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                        </div>
                                        <SmallHandlerContainerDiv ref={handleEditConfirmLogoRef}>
                                            <div>
                                                <SmallSaveButton onClick={handleEditConfirmLogo}>
                                                    <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                                    {t("sellertexte18")}
                                                </SmallSaveButton>
                                            </div>
                                        </SmallHandlerContainerDiv>
                                    </div>
                                )}
                                <br/>
                                {eventsEditContent && (
                                    <ModalUser>
                                        <SmallTextLogin>{t("event_name")}</SmallTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallInput type='text' ref={eventsEditNameRef} defaultValue={valeditdetail.title_advertise} readOnly />
                                        </div>
                                        <br/>
                                        <SmallTextLogin>{t("event_description")}</SmallTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallTextArea 
                                                type='text' 
                                                value={textDescription || valeditdetail.content_advertise}
                                                onChange={handleChangeDescription}
                                                ref={eventsEditDescriptionRef} 
                                                maxLength={newCharacterLimitDescription} // Set the maxlength attribute
                                            />
                                            <div>
                                                {remainingCharactersDescription >= 0
                                                ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                                                : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                                            </div>
                                        </div>
                                        <br/>
                                        <SmallTextLogin>{t("event_video")}</SmallTextLogin>
                                        <div style={{display:'none',textAlign:'center'}}>
                                            <SmallInput type='text' ref={editVideoFileNameRef} defaultValue={valeditdetail.link_advertise} readOnly />
                                        </div>
                                        <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                            <video src={valeditdetail.link_advertise} controls width="90%">
                                                {t("unsupported_browser")}
                                            </video>
                                        </div>
                                        <br/>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <label htmlFor='upload-btn-video' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                                                <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                                                {t("upload_video")}
                                            </label>
                                            <SmallInput 
                                                id="upload-btn-video"
                                                type='file' 
                                                ref={eventsEditVideoRef} 
                                                onChange={handleEditVideoChange}
                                                accept="video/*" 
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                        <br/>
                                        {videoPreview && (
                                            <div>
                                                <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                                    <video src={srcVideo} controls width="90%">
                                                        {t("unsupported_browser")}
                                                    </video>
                                                </div>
                                                <SmallHandlerContainerDiv ref={handleEditConfirmVideoRef}>
                                                    <div>
                                                        <SmallSaveButton onClick={handleEditConfirmVideo}>
                                                            <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                                            {t("sellertexte18")}
                                                        </SmallSaveButton>
                                                    </div>
                                                </SmallHandlerContainerDiv>
                                            </div>
                                        )}
                                        <br/>
                                    </ModalUser>
                                )}
                            </ModalUser>
                        ))}
                    </div>
                )}
                {editEvents && (
                    <div>
                        <SmallHandlerContainerDiv>
                            <div>
                                <SmallEditButton onClick={handleEditEvents}>
                                    <span style={{ fontSize:'30px'}}><MdModeEdit /></span>
                                    {t("edit")}
                                </SmallEditButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>
                )}
                
                {showModalExpired && (
                    <WarningModal
                        text={t("text_expired_warning")}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalExpired}
                        onCancel={handleCancelModalExpired}
                    />
                )}
                {showModalCreateEvent && (
                    <WarningMultiModal
                        text={t("create_event_warning")}
                        textOk={t("ok_create")}
                        textPause={t("not_now")}
                        textNo={t("no_cancel")}
                        onConfirm={handleModalCreate}
                        onPause={handleModalNotNow}
                        onCancel={handleCancelModal}
                    />
                )}
                {showModalSubscribe && (
                    <WarningRefundModal
                        text={t("success_subscription")}
                        textRefund={frenchDateFormat(dateEndSubscribeInput)}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalEndSubscribe}
                        onCancel={handleCancelModalEndSubscribe}
                    />
                )}

                {/**Modal Events topUp */}
                {eventsTopUpModalOpen && (
                    <EventsTopUp />
                )}
                {/**Modal Create Events */}
                {createEventsModalOpen && (
                    <CreateEvents />
                )}
                {/**Modal Crud Events */}
                {crudEventsModalOpen && (
                    <CrudEvents />
                )}
                <br/><br/>
                <div id="modaloutalerteditevents"></div>
                <div id="backdropoutalerteditevents"></div>
            </ModalUser>
        </div>
    )
}

export default EditEvents
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//

import { MdOutlineSave, MdModeEdit, MdDelete, MdPreview } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import NewCategory from './NewCategory';
import EditCategory from './EditCategory';
import DeleteCategory from './DeleteCategory';
import ViewCategory from './ViewCategory';

import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal } from '../../../../helperfunctions/Helper';
import WarningModal from '../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

import { 
    FooterSubTitle,
    RightSideButton,
    ModalUser,
    SmallHandlerColContainerDiv,
} from '../../../ComponentStyles';

function CrudCategory() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertcategoryproduct";
    const elementDropId = "backdropoutalertcategoryproduct";
    const buttonHandler = "ouiHandlerAlertCategoryProductBtn";

    // Define state variables for modals
    const [startCategoryProductModalOpen, setStartCategoryProductModalOpen] = useState(true);
    const [categoryProductModalOpen, setCategoryProductModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    //
    const [dashboardCreateCategoryModalOpen, setDashboardCreateCategoryModalOpen] = useState(false);
    const [dashboardEditCategoryModalOpen, setDashboardEditCategoryModalOpen] = useState(false);
    const [dashboardDeleteCategoryModalOpen, setDashboardDeleteCategoryModalOpen] = useState(false);
    const [dashboardViewCategoryModalOpen, setDashboardViewCategoryModalOpen] = useState(false);

    const crudCategoryRef = useRef(null);

    const handleStartCategory = async () => {
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        var checkResultat = [];
        var codeProduit = "";

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //set code product
        const codeProduitDiv = document.getElementById('codeproduit');
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }

        //use the same axios.post edit/product/detals to retrieve data before showing crud discount product 
        await Axios.post(`${apiUrl}/edit/product/details`, {
            tableNameSeller:tableName,
            codeUtisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_product");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                checkResultat = response1.data;
                //retrieve code produit
                for(let i=0; i<resultat1; i++){
                    codeProduit = checkResultat[i].code_product;
                }
                //console.log(codeProduit);
                codeProduitDiv.innerText = codeProduit;
                //
                setStartCategoryProductModalOpen(false);
                setCategoryProductModalOpen(false);
                setShowModal(true);
                //
                setDashboardCreateCategoryModalOpen(false);
                setDashboardEditCategoryModalOpen(false);
                setDashboardDeleteCategoryModalOpen(false);
                setDashboardViewCategoryModalOpen(false);
            }
        });
    }

    const handleModalCategoryProduct = async (e) => {
        e.preventDefault();

        setStartCategoryProductModalOpen(false);
        setCategoryProductModalOpen(true);
        setShowModal(false);
        //
        setDashboardCreateCategoryModalOpen(false);
        setDashboardEditCategoryModalOpen(false);
        setDashboardDeleteCategoryModalOpen(false);
        setDashboardViewCategoryModalOpen(false);
    }

    const handleCancelProduct = async (e) => {
        e.preventDefault();

        setStartCategoryProductModalOpen(true);
        setCategoryProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateCategoryModalOpen(false);
        setDashboardEditCategoryModalOpen(false);
        setDashboardDeleteCategoryModalOpen(false);
        setDashboardViewCategoryModalOpen(false);
    }

    const handleCrudNewCategory = async (e) => {
        e.preventDefault();

        setStartCategoryProductModalOpen(false);
        setCategoryProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateCategoryModalOpen(true);
        setDashboardEditCategoryModalOpen(false);
        setDashboardDeleteCategoryModalOpen(false);
        setDashboardViewCategoryModalOpen(false);
    }

    const handleCrudEditCategory = async (e) => {
        e.preventDefault();

        setStartCategoryProductModalOpen(false);
        setCategoryProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateCategoryModalOpen(false);
        setDashboardEditCategoryModalOpen(true);
        setDashboardDeleteCategoryModalOpen(false);
        setDashboardViewCategoryModalOpen(false);
    }

    const handleCrudDeleteCategory = async (e) => {
        e.preventDefault();

        setStartCategoryProductModalOpen(false);
        setCategoryProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateCategoryModalOpen(false);
        setDashboardEditCategoryModalOpen(false);
        setDashboardDeleteCategoryModalOpen(true);
        setDashboardViewCategoryModalOpen(false);
    }

    const handleCrudViewCategory = async (e) => {
        e.preventDefault();

        setStartCategoryProductModalOpen(false);
        setCategoryProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateCategoryModalOpen(false);
        setDashboardEditCategoryModalOpen(false);
        setDashboardDeleteCategoryModalOpen(false);
        setDashboardViewCategoryModalOpen(true);
    }

    return (
        <div>
            <ModalUser ref={crudCategoryRef}>
                <FooterSubTitle>{t("dash_category")}</FooterSubTitle>
                <br/>
                {startCategoryProductModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartCategory}
                        />
                    </div>
                )}
                <br/>
                {categoryProductModalOpen && (
                    <div>
                        <ModalUser>
                            <SmallHandlerColContainerDiv>
                                <div>
                                    <RightSideButton onClick={handleCrudNewCategory}>
                                        <span style={{fontSize:'30px'}}><MdOutlineSave /></span>
                                        {t("dash_new_category")}
                                    </RightSideButton>
                                </div>
                                <br/>
                                <div>
                                    <RightSideButton  onClick={handleCrudEditCategory}>
                                        <span style={{fontSize:'30px'}}><MdModeEdit /></span>
                                        {t("dash_edit_category")}
                                    </RightSideButton>
                                </div>
                                <br/>
                                <div>
                                    <RightSideButton onClick={handleCrudDeleteCategory}>
                                        <span style={{fontSize:'30px'}}><MdDelete /></span>
                                        {t("dash_delete_category")}
                                    </RightSideButton>
                                </div>
                                <br/>
                                <div>
                                    <RightSideButton onClick={handleCrudViewCategory}>
                                        <span style={{fontSize:'30px'}}><MdPreview /></span>
                                        {t("dash_view_category")}
                                    </RightSideButton>
                                </div>
                            </SmallHandlerColContainerDiv>
                        </ModalUser> 
                    </div>
                )}
                {showModal && (
                    <WarningModal
                        text={t("text_category_product_warning")}
                        textOk={t("ok_discount")}
                        textNo={t("no_cancel")}
                        onConfirm={handleModalCategoryProduct}
                        onCancel={handleCancelProduct}
                    />
                )}
                
                {/**Add new category */}
                {dashboardCreateCategoryModalOpen && (
                    <NewCategory />
                )}

                {/**Edit category */}
                {dashboardEditCategoryModalOpen && (
                    <EditCategory />
                )}

                {/**Delete category */}
                {dashboardDeleteCategoryModalOpen && (
                    <DeleteCategory />
                )}

                {/**View category */}
                {dashboardViewCategoryModalOpen && (
                    <ViewCategory />
                )}
                <br/><br/>
                <div id="modaloutalertcategoryproduct"></div>
                <div id="backdropoutalertcategoryproduct"></div>
            </ModalUser>
        </div>
    )
}

export default CrudCategory
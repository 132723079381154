import React from 'react';
//
import { MdProductionQuantityLimits, MdCategory, MdOutlineContactPhone } from "react-icons/md";
import { ImProfile } from "react-icons/im";
//
import { 
    ImageProductWrapper,
    ImageWrapper,
    CardProductImage,
    CardTextImageProductWrapper,
    CardTextProductBody,
    CardTextProductTitle,
    CardButtonProductWrapper,
    CardButton,
} from '../../ComponentStyles';

function ListImageCardSubscriber({ url, onImageClick, title, text, onButtonClickOne, onButtonClickTwo, onButtonClickThree, onButtonClickFour }) {
    return (
        <div>
            <ImageProductWrapper>
                <ImageWrapper style={{ backgroundImage: `url(${url})` }} onClick={onImageClick}>
                    <CardTextImageProductWrapper>
                        <CardProductImage>
                            <img src={url} alt='preview' style={{ maxWidth: '50%', maxHeight: '100px', borderRadius: '50px' }} />
                        </CardProductImage>
                    </CardTextImageProductWrapper>
                </ImageWrapper>
                
                <CardTextProductBody>
                    <CardTextProductTitle>{title}</CardTextProductTitle>
                    <p style={{width:'250p', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', fontSize:'17px', color:'#000000'}}>{text}</p>
                    <CardButtonProductWrapper>
                        <CardButton onClick={onButtonClickOne}><MdProductionQuantityLimits /></CardButton>
                        <CardButton onClick={onButtonClickTwo}><MdCategory /></CardButton>
                        <CardButton onClick={onButtonClickThree}><ImProfile /></CardButton>
                        <CardButton onClick={onButtonClickFour}><MdOutlineContactPhone /></CardButton>
                    </CardButtonProductWrapper>
                </CardTextProductBody>
            </ImageProductWrapper>
        </div>
    )
}

export default ListImageCardSubscriber
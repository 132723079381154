import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
//
import { GoNextButton, GoNextText } from '../ReusableStyles';

function ForwardButton({onClick}) {
    const {t} = useTranslation();

    const navigateNext = useNavigate();

    const goNext = () => {
        if (onClick && typeof onClick === 'function') {
            onClick(); // Call the provided onClick function if available
        } else {
            navigateNext('/'); // Default behavior: go back in history
        }
    };
    return (
        <GoNextButton onClick={goNext}>
            <GoNextText>{t("feedbacktexte7")}</GoNextText>
            <FiArrowRight />
        </GoNextButton>
    )
}

export default ForwardButton
import React from 'react';
import { MdOutgoingMail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
//
import { GoSendButton, GoSendText } from '../ReusableStyles';

function SendButton({onClick}) {
    const {t} = useTranslation();

    const navigateSend = useNavigate();

    const goSend = () => {
        if (onClick && typeof onClick === 'function') {
            onClick(); // Call the provided onClick function if available
        } else {
            navigateSend('/'); // Default behavior: go back in history
        }
    };

    return (
        <GoSendButton onClick={goSend}>
            <MdOutgoingMail />
            <GoSendText>{t("feedbacktexte5")}</GoSendText>
        </GoSendButton>
    )
}

export default SendButton
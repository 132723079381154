import React from 'react';
import { useNavigate } from "react-router-dom";
import './footer.css';
//
import { MdOutlineEventRepeat, MdOutlineDiscount, MdSettingsApplications } from "react-icons/md";
//
import { FooterLink, FooterLinkDiv } from '../ComponentStyles';

function FooterStarting() {

    const navigateFooterStarting = useNavigate();

    //***************List events products handler*************
    const onEventHandler = async (e) => {
        e.preventDefault();
        
        navigateFooterStarting('/footerevent');
    }

    //***************List promotions products handler*************
    const onPromotionHandler = async (e) => {
        e.preventDefault();
        
        navigateFooterStarting('/footerpromotion');
    }

    //****List Applications Handler ******************
    const onApplicationHandler = async (e) => {
        e.preventDefault();
        
        navigateFooterStarting('/footerapplication');
    }

    return (
        <div id='footerstarting'>
            <div className="footer_accueilmenu">
                <div className="footer_scrollermenu">
                    <FooterLinkDiv>
                        <FooterLink onClick={onEventHandler}><MdOutlineEventRepeat style={{fontSize:'35px'}}/></FooterLink>
                    </FooterLinkDiv>
                    <FooterLinkDiv>
                        <FooterLink onClick={onPromotionHandler}><MdOutlineDiscount style={{fontSize:'35px'}} /></FooterLink>
                    </FooterLinkDiv>
                    <FooterLinkDiv>
                        <FooterLink onClick={onApplicationHandler}><MdSettingsApplications style={{fontSize: '35px'}} /></FooterLink>
                    </FooterLinkDiv>
                </div>
            </div>
        </div>
    )
}

export default FooterStarting
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import { GiConfirmed } from 'react-icons/gi';
//
import { retrieveTableQuery, retrieveSellDaysQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal, formatDateData, differenceTwoDates, frenchDateFormat, addStartDate } from '../../../../helperfunctions/Helper';
import WarningRefundModal from '../../../reusablecomponents/componentutils/WarningRefundModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    FaqAnswerContent,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallHandlerContainerDiv,
    SmallSaveButton,
} from '../../../ComponentStyles';

function BasicTopUp() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertbasic";
    const elementDropId = "backdropoutalertbasic";
    const buttonHandler = "ouiHandlerAlertBasicBtn";

    const [showModal, setShowModal] = useState(false);
    const [showModalSubscribe, setShowModalSubscribe] = useState(false);
    const [dateEndInput, setDateEndInput] = useState([0]);
    const [dateEndSubscribeInput, setDateEndSubscribeInput] = useState([0]);

    const basicCodePinRef = useRef(null);

    const handleConfirmBasicPin = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;
        var sellerDetail = [];
        var idSubscribe = "";
        var numberDays = 0;
        var startDate = "";
        var endDate = "";
        //set pincode retrieval
        var tablePincode = "";
        var signupTablePincode = [];
        var resultat2 = 0;
        var detailIdPin = [];
        //declare variables
        var codeCountry = "";
        var statusCountry = "";
        var codeCity = "";
        var statusCity = "";
        var codeReseller = "";
        var statusReseller = "";
        var referencePin = "";
        var statusSeller = "";
        var dateEndPin = "";
        var idPinCode = "";
        var numeroPinCode = "";
        var planPinCode = "";

        //first check if seller has acitvated his subscription and retrieve the plan pincode
        const basicCodePin = basicCodePinRef.current.value; //used for pincode as third parameter
        const firstWhere = "code_user";
        const firstParameter = document.getElementById('codeutilisateur').innerText; //used for pincode
        //pincode parameters
        const firstWherePin = "code_seller";
        const secondWherePin = "plan_pincode";
        const thirdWherePin = "numero_pincode";
        //
        const sellerPlanPin = "basic";
        //variable update pincode
        const firstSetPin = "status_seller";
        const firstSetParameterPin = "used";
        const firstWhereIdPin = "id_pincode";

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table10;
        }

        //fetch pincode table based on country code
        signupTablePincode = retrieveTableQuery(codecountryDiv);
        if(signupTablePincode){
            tablePincode = signupTablePincode.table.table11;
        }

        if(!basicCodePin){
            const pTextePays = t("codepin_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            await Axios.post(`${apiUrl}/check/one/parameter/table`, {
                tableNameSeller:tableName,
                firstWhere:firstWhere,
                firstParameter:firstParameter,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTextePays = t("error_data");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    sellerDetail = response1.data;
                    //retrieve data
                    for (let i=0; i<resultat1; i++){
                        idSubscribe = sellerDetail[i].id_subscribe;
                        endDate = formatDateData(sellerDetail[i].date_end);
                    }
                    //
                    var resultDiference = differenceTwoDates(endDate);
                    //
                    if(resultDiference < 0){
                        setShowModal(false);
                        //check if pincode exists and belong to this seller based on his seller code
                        Axios.post(`${apiUrl}/check/three/parameter/table`, {
                            tableNameSeller:tablePincode,
                            firstWhere:firstWherePin,
                            firstParameter:firstParameter,
                            secondWhere:secondWherePin,
                            secondParameter:sellerPlanPin,
                            thirdWhere:thirdWherePin,
                            thirdParameter:basicCodePin,
                        }).then((response2) => {
                            resultat2 = response2.data.length;
                            if(resultat2 === 0){
                                const pTextePays = t("error_codepin");
                                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                            }else{
                                //retrieve data
                                detailIdPin = response2.data;
                                for (let j=0; j<resultat2; j++){
                                    idPinCode = detailIdPin[j].id_pincode;
                                    codeCountry = detailIdPin[j].code_user;
                                    statusCountry = detailIdPin[j].status_country;
                                    codeCity = detailIdPin[j].code_city;
                                    statusCity = detailIdPin[j].status_city;
                                    codeReseller = detailIdPin[j].code_reseller;
                                    statusReseller = detailIdPin[j].status_reseller;
                                    planPinCode = detailIdPin[j].plan_pincode;
                                    referencePin = detailIdPin[j].reference;
                                    numeroPinCode = detailIdPin[j].numero_pincode;
                                    statusSeller = detailIdPin[j].status_seller;
                                    dateEndPin = formatDateData(detailIdPin[j].date_seller);
                                }
                                //Test if all parameters are correct
                                if(codeCountry && statusCountry === "used" && codeCity && statusCity === "used" && codeReseller && statusReseller === "used"){
                                    //end date subscription
                                    setDateEndSubscribeInput(dateEndPin);
                                    //retrieve number days
                                    numberDays = retrieveSellDaysQuery(codecountryDiv, planPinCode);
                                    //calculate start date
                                    startDate = formatDateData(addStartDate(dateEndPin, numberDays));
                                    //if status seller is not used then update pincode to used
                                    if (statusSeller !== "used"){
                                        //update pincode setting status seller to used
                                        Axios.post(`${apiUrl}/update/one/parameter/table`, {
                                            tableName:tablePincode,
                                            firstSet:firstSetPin,
                                            firstSetParameter:firstSetParameterPin,
                                            firstWhereId:firstWhereIdPin,
                                            firstParameterId:idPinCode,
                                        }).then(() => {
                                            //update subscribe
                                            Axios.post(`${apiUrl}/update/seller/subscribe`, {
                                                tableNameSeller:tableName,
                                                planPinCode:planPinCode,
                                                reference:referencePin,
                                                numeroPinCode:numeroPinCode,
                                                dateStart:startDate,
                                                endDate:dateEndPin,
                                                codeReseller:codeReseller,
                                                idSubscribe:idSubscribe,
                                            }).then(() => {
                                                setShowModalSubscribe(true);
                                                setShowModal(false);
                                            });
                                        });
                                    }else{
                                        //update only subscribe
                                        //update subscribe
                                        Axios.post(`${apiUrl}/update/seller/subscribe`, {
                                            tableNameSeller:tableName,
                                            planPinCode:planPinCode,
                                            reference:referencePin,
                                            numeroPinCode:numeroPinCode,
                                            dateStart:startDate,
                                            endDate:dateEndPin,
                                            codeReseller:codeReseller,
                                            idSubscribe:idSubscribe,
                                        }).then(() => {
                                            setShowModalSubscribe(true);
                                            setShowModal(false);
                                        });
                                    }
                                }else{
                                    const pTextePays = t("error_codepin");
                                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                                }
                            }
                        });
                    }else{
                        setDateEndInput(endDate);
                        setShowModalSubscribe(false);
                        setShowModal(true);
                    }
                }
            });
        }
    }

    const handleModalNotExpired = async (e) => {
        e.preventDefault();

        setShowModalSubscribe(false);
        setShowModal(false);
    }

    const handleCancelModalNotExpired = async (e) => {
        e.preventDefault();

        setShowModalSubscribe(false);
        setShowModal(false);
    }

    //end subscribe
    const handleModalEndSubscribe = async (e) => {
        e.preventDefault();

        setShowModalSubscribe(false);
        setShowModal(false);
    }

    const handleCancelModalEndSubscribe = async (e) => {
        e.preventDefault();

        setShowModalSubscribe(false);
        setShowModal(false);
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_basic_topup")}</FooterSubTitle>
                <br/>
                <FaqAnswerContent>{t("code_pin_texte")}</FaqAnswerContent>
                <br/>
                <SmallTextLogin>{t("code_pin")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <SmallInput type='number' ref={basicCodePinRef} placeholder={t("codepin_placeholder")} />
                </div>
                <br/>
                <SmallHandlerContainerDiv>
                    <div>
                        <SmallSaveButton onClick={handleConfirmBasicPin}>
                            <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                            {t("sellertexte18")}
                        </SmallSaveButton>
                    </div>
                </SmallHandlerContainerDiv>
                {showModal && (
                    <WarningRefundModal
                        text={t("text_notexpired_warning")}
                        textRefund={frenchDateFormat(dateEndInput)}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalNotExpired}
                        onCancel={handleCancelModalNotExpired}
                    />
                )}
                {showModalSubscribe && (
                    <WarningRefundModal
                        text={t("success_subscription")}
                        textRefund={frenchDateFormat(dateEndSubscribeInput)}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalEndSubscribe}
                        onCancel={handleCancelModalEndSubscribe}
                    />
                )}
                <br/><br/>
                <div id="modaloutalertbasic"></div>
                <div id="backdropoutalertbasic"></div>
            </ModalUser>
        </div>
    )
}

export default BasicTopUp








import React from 'react';
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
//
import InnerPopUpBackButton from '../buttons/InnerPopUpBackButton';
import { AiOutlineProfile } from "react-icons/ai";
//
import { 
    CardDetailProfileContainer,
    CardVideoText,
    HandlerOneContainerDiv,
    //CardButtonPhone,
    CardTextVideoBody,
    CardHeader,
    CardImageProduct,
    //CardButton,
    StyledParagraph,
} from '../../ComponentStyles';

function ListProfileCard({ denomination, secteur, description, modeVente, contact, pays, ville, village, quartier, localisation, onButtonClickClose }) {
    const {t} = useTranslation();

    return (
        <div>
            <CardDetailProfileContainer>
                <CardHeader>
                    <CardImageProduct>
                        <AiOutlineProfile style={{ width: '100%', height: '50px', borderRadius: '10px' }} />
                        <CardVideoText><strong style={{fontSize:'25px'}}>{denomination}</strong></CardVideoText>
                    </CardImageProduct>
                </CardHeader>
                <HandlerOneContainerDiv>
                    <div>
                        <InnerPopUpBackButton onClick={onButtonClickClose}></InnerPopUpBackButton>
                    </div>
                </HandlerOneContainerDiv>
                <CardTextVideoBody>
                    <StyledParagraph><strong>{t("profile_sector")}:</strong> {secteur}</StyledParagraph>
                    <StyledParagraph><strong>{t("profile_description")}:</strong> {description}</StyledParagraph>
                    <StyledParagraph><strong>{t("profile_mode")}:</strong> {modeVente}</StyledParagraph>
                    <StyledParagraph><strong>{t("localisation")}</strong></StyledParagraph>
                    <StyledParagraph><strong>{t("profile_contact")}:</strong> {contact}</StyledParagraph>
                    <StyledParagraph><strong>{t("profile_country")}:</strong> {pays}</StyledParagraph>
                    <StyledParagraph><strong>{t("profile_ville")}:</strong> {ville}</StyledParagraph>
                    <StyledParagraph><strong>{t("profile_village")}:</strong> {village}</StyledParagraph>
                    <StyledParagraph><strong>{t("profile_quartier")}:</strong> {quartier}</StyledParagraph>
                    <StyledParagraph><strong>{t("profile_location")}:</strong> {localisation}</StyledParagraph>
                </CardTextVideoBody>
            </CardDetailProfileContainer>
        </div>
    )
}

export default ListProfileCard
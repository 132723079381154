import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//
import CountryButton from '../reusablecomponents/buttons/CountryButton';

import FooterStarting from '../footer/FooterStarting';

//icons
import Buyer from '../countries/sellerbuyericon/buyer_icon.png';
import Seller from '../countries/sellerbuyericon/seller_icon.png';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
import PopUpForwardButton from '../reusablecomponents/buttons/PopUpForwardButton';

import { HideFooter, AlertModal } from '../../helperfunctions/Helper';

//
import { 
    TermeScroller, 
    FooterIntro, 
    HandlerContainerDiv,
} from '../ComponentStyles';

function Starting() {
    HideFooter();

    const {t} = useTranslation();

    const navigateStarting = useNavigate();

    //alert
    const elementOutId = "modaloutalertstarting";
    const elementDropId = "backdropoutalertstarting";
    const buttonHandler = "ouiHandlerAlertStartingBtn";

    //set starting
    const [startingModalOpen, setStartingModalOpen] = useState(true);

    const startingBuyerHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeutilisateur').innerText = 'buyer';

        setStartingModalOpen(false);
        HideFooter();
        navigateStarting('/nosubscribebuyer');
    }
    //

    const startingSellerHandler = async (e) => {
        e.preventDefault();

        document.getElementById('typeutilisateur').innerText = 'seller';

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            HideFooter();
            navigateStarting('/startingseller');
        }else{
            HideFooter();
            navigateStarting('/dashboardseller');
        }
    }

    const goBackStartingHandler = async () => {

        navigateStarting('/');
        document.getElementById('selectlangue').selectedIndex = 0;
        document.getElementById('namecountry').innerText = "";
        document.getElementById('codecountry').innerText = "";
        document.getElementById('codeutilisateur').innerText = "";
        document.getElementById('usernom').innerText = "";
        document.getElementById('searchcountry').innerText = "";
    }

    const goNextStartingHandler = async () => {
        const typeutilisateurDiv = document.getElementById('typeutilisateur').innerText;
        const nameCountryDiv = document.getElementById('namecountry').innerText;
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(nameCountryDiv === ''){
            navigateStarting('/');
            document.getElementById('selectlangue').selectedIndex = 0;
            document.getElementById('namecountry').innerText = "";
        }else{
            //
            if(typeutilisateurDiv === ''){
                const pTextePays = t("demarrertexte1");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                if(typeutilisateurDiv === 'buyer'){
                    HideFooter();
                    navigateStarting('/nosubscribebuyer');
                }else if(typeutilisateurDiv === 'seller'){
                    if(codeutilisateurDiv === ''){
                        HideFooter();
                        navigateStarting('/startingseller');
                    }else{
                        HideFooter();
                        navigateStarting('/dashboardseller');
                    }
                }
            }
        }
    }

    return (
        <div id='starting'>
            <TermeScroller>
                <br/>
                {/*<FooterSubTitle>{t("demarrersubtitle")}</FooterSubTitle>*/}
                <br/>
                <FooterIntro>{t("demarrertexte1")}</FooterIntro>
                <br/>
                <br/><br/>
                {startingModalOpen && (
                    <div style={{display:'block',textAlign:'center'}}>
                        <CountryButton 
                            url={Buyer}
                            countryName={t("buyer")}
                            onConfirm={startingBuyerHandler}
                        />
                        <CountryButton 
                            url={Seller}
                            countryName={t("seller")}
                            onConfirm={startingSellerHandler}
                        />
                    </div>
                )}

                <FooterStarting />

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackStartingHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>
                <HandlerContainerDiv>
                    <div>
                        <PopUpForwardButton onClick={goNextStartingHandler} ></PopUpForwardButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
                <div id="modaloutalertstarting"></div>
                <div id="backdropoutalertstarting"></div>
            </TermeScroller>
        </div>
    )
}

export default Starting
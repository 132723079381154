import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//import necessary components to render
import EventsTopUp from '../dashtopup/EventsTopUp';
import CreateEvents from './CreateEvents';
import CrudEvents from './CrudEvents';
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdPreview } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal, differenceTwoDates, formatDateData } from '../../../../helperfunctions/Helper';
import WarningMultiModal from '../../../reusablecomponents/componentutils/WarningMultiModal';
import WarningModal from '../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallHandlerContainerDiv,
    SmallViewButton,
} from '../../../ComponentStyles';

function ViewEvents() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertviewevents";
    const elementDropId = "backdropoutalertviewevents";
    const buttonHandler = "ouiHandlerAlertViewEventsBtn";

    // Define state variables for modals
    const [titleModalOpen, setTitleModalOpen] = useState(true);
    const [startViewEventsModalOpen, setStartViewEventsModalOpen] = useState(true);
    const [viewEventsModalOpen, setViewEventsModalOpen] = useState(false);
    //
    //const [showModal, setShowModal] = useState(false);
    const [showModalExpired, setShowModalExpired] = useState(false);
    const [showModalCreateEvent, setShowModalCreateEvent] = useState(false);
    //
    const [eventsTopUpModalOpen, setEventsTopUpModalOpen] = useState(false);
    const [crudEventsModalOpen, setCrudEventsModalOpen] = useState(false);
    const [createEventsModalOpen, setCreateEventsModalOpen] = useState(false);

    const [userViewEvents, setUserViewEvents] = useState([0]);

    const [viewEvents, setViewEvents] = useState(false);

    const handleStartEvents = async () => {

        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        var detailEvents = [];
        var endDate = "";
        var titleEvent = "";
        const firstWhere = "code_user";

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table15;
        }

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableName,
            firstWhere:firstWhere,
            firstParameter:codeutilisateurDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_event");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                detailEvents = response1.data;
                //retrieve data
                for (let i=0; i<resultat1; i++){
                    endDate = formatDateData(detailEvents[i].date_end);
                    titleEvent = detailEvents[i].title_advertise;
                }
                //check if subscription still valid
                var resultDiference = differenceTwoDates(endDate);
                if(resultDiference < 0){
                    setShowModalExpired(true);
                    //
                    setEventsTopUpModalOpen(false);
                    setCrudEventsModalOpen(false);
                    setCreateEventsModalOpen(false);
                }else{
                    //check if an event already exists
                    if(titleEvent === ""){
                        setShowModalExpired(false);
                        setShowModalCreateEvent(true);
                        //
                        setEventsTopUpModalOpen(false);
                        setCrudEventsModalOpen(false);
                        setCreateEventsModalOpen(false);
                    }else{
                        setUserViewEvents(response1.data);
                        setTitleModalOpen(true);
                        setStartViewEventsModalOpen(false);
                        setViewEventsModalOpen(true);
                        setViewEvents(true);
                        //
                        setEventsTopUpModalOpen(false);
                        setCrudEventsModalOpen(false);
                        setCreateEventsModalOpen(false);
                    }
                }
            }
        });
    }
    //
    const handleModalExpired = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartViewEventsModalOpen(false);
        setViewEventsModalOpen(false);
        setViewEvents(false);
        //setShowModal(false);
        setShowModalExpired(false);
        setCreateEventsModalOpen(false);
        //
        setEventsTopUpModalOpen(true);
        setCrudEventsModalOpen(false);
        setShowModalCreateEvent(false);
    }
    //
    const handleCancelModalExpired = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartViewEventsModalOpen(false);
        setViewEventsModalOpen(false);
        setViewEvents(false);
        //setShowModal(false);
        setShowModalExpired(false);
        setCreateEventsModalOpen(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setShowModalCreateEvent(false);
    }
    //
    const handleModalCreate = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartViewEventsModalOpen(false);
        setViewEventsModalOpen(false);
        setViewEvents(false);
        //setShowModal(false);
        setShowModalExpired(false);
        setCreateEventsModalOpen(true);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(false);
        setShowModalCreateEvent(false);
    }
    //
    const handleModalNotNow = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartViewEventsModalOpen(false);
        setViewEventsModalOpen(false);
        setViewEvents(false);
        //setShowModal(false);
        setShowModalExpired(false);
        setShowModalCreateEvent(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setShowModalCreateEvent(false);
    }
    //
    const handleCancelModal = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartViewEventsModalOpen(false);
        setViewEventsModalOpen(false);
        setViewEvents(false);
        //setShowModal(false);
        setShowModalExpired(false);
        setShowModalCreateEvent(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setShowModalCreateEvent(false);
    }

    const handleViewEvents = async (e) => {
        e.preventDefault();

        setTitleModalOpen(false);
        setStartViewEventsModalOpen(false);
        setViewEventsModalOpen(false);
        setViewEvents(false);
        //setShowModal(false);
        setShowModalExpired(false);
        setShowModalCreateEvent(false);
        //
        setEventsTopUpModalOpen(false);
        setCrudEventsModalOpen(true);
        setShowModalCreateEvent(false);
    }

    return (
        <div>
            <ModalUser>
                {titleModalOpen && (
                    <div>
                        <FooterSubTitle>{t("dash_view_events")}</FooterSubTitle>
                        <br/>
                    </div>
                )}

                {startViewEventsModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartEvents}
                        />
                    </div>
                )}

                {viewEventsModalOpen && (
                    <div>
                        {userViewEvents.map((valeditdetail, indexeditdetail) => (
                            <ModalUser key={indexeditdetail}>
                                <SmallTextLogin>{t("event_logo")}</SmallTextLogin>
                                <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                    <img src={valeditdetail.image_advertise} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("event_name")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valeditdetail.title_advertise} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("event_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        defaultValue={valeditdetail.content_advertise}
                                        readOnly
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("event_video")}</SmallTextLogin>
                                <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                    <video src={valeditdetail.link_advertise} controls width="90%">
                                        {t("unsupported_browser")}
                                    </video>
                                </div>
                            </ModalUser>
                        ))}
                    </div>
                )}
                {viewEvents && (
                    <div>
                        <SmallHandlerContainerDiv>
                            <div>
                                <SmallViewButton onClick={handleViewEvents}>
                                    <span style={{ fontSize:'30px'}}><MdPreview /></span>
                                    OK
                                </SmallViewButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>
                )}

                {showModalExpired && (
                    <WarningModal
                        text={t("text_expired_warning")}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalExpired}
                        onCancel={handleCancelModalExpired}
                    />
                )}
                {showModalCreateEvent && (
                    <WarningMultiModal
                        text={t("create_event_warning")}
                        textOk={t("ok_create")}
                        textPause={t("not_now")}
                        textNo={t("no_cancel")}
                        onConfirm={handleModalCreate}
                        onPause={handleModalNotNow}
                        onCancel={handleCancelModal}
                    />
                )}

                {/**Modal Events topUp */}
                {eventsTopUpModalOpen && (
                    <EventsTopUp />
                )}
                {/**Modal Create Events */}
                {createEventsModalOpen && (
                    <CreateEvents />
                )}
                {/**Modal Crud Events */}
                {crudEventsModalOpen && (
                    <CrudEvents />
                )}
                <br/><br/>
                <div id="modaloutalertviewevents"></div>
                <div id="backdropoutalertviewevents"></div>
            </ModalUser>
        </div>
    )
}

export default ViewEvents
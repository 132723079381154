import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import { MdOutlineSave } from 'react-icons/md';
import { BsUpload } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal, LimitCharacters, differenceTwoDates, /*getCurrentDate,*/ formatDateData, /*addDays,*/ frenchDateFormat, ValidateVideoDuration } from '../../../../helperfunctions/Helper';
import WarningRefundModal from '../../../reusablecomponents/componentutils/WarningRefundModal';
import WarningMultiModal from '../../../reusablecomponents/componentutils/WarningMultiModal';
import WarningModal from '../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallHandlerContainerDiv,
    SmallSaveButton,
} from '../../../ComponentStyles';

function CreateEvents() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertcreateevents";
    const elementDropId = "backdropoutalertcreateevents";
    const buttonHandler = "ouiHandlerAlertCreateEventsBtn";

    const navigateCreateEvents = useNavigate();

    const eventsImageRef = useRef(null);
    const eventsNameRef = useRef(null);
    const eventsDescriptionRef = useRef(null);
    const eventsVideoRef = useRef(null);
    //
    const handleConfirmLogoRef = useRef(null);
    const handleConfirmVideoRef = useRef(null);

    //
    const [eventsContent, setEventContents] = useState(false);
    const [createEvents, setCreateEvents] = useState(false);
    //
    const [showModal, setShowModal] = useState(false);
    const [showModalSubscribe, setShowModalSubscribe] = useState(false);
    const [showModalExpired, setShowModalExpired] = useState(false);
    //
    const [dateEndSubscribeInput, setDateEndSubscribeInput] = useState([0]);

    const [imagePreview, setImagePreview] = useState(false);
    const [videoPreview, setVideoPreview] = useState(false);

    //image sources and resources states
    const [src, setSrc] = useState("");
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    //video sources and resources states
    const [srcVideo, setSrcVideo] = useState("");
    const [fileVideo, setFileVideo] = useState("");
    const [fileNameVideo, setFileNameVideo] = useState("");

    const handleImageChange = async (e) => {

        const file = e.target.files[0];
        if(file){
            // Transform file into blob URL
            setSrc(URL.createObjectURL(file));
            //
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);
            //
            setImagePreview(true);
            setEventContents(false);
            setCreateEvents(false);
        }
    }

    const handleVideoChange = async (e) => {
        
        // Get the uploaded file
        const file = e.target.files[0];

        //error text
        const errorText = t("error_limit");
        // Validate video duration
        const isValidDuration = await ValidateVideoDuration(file, errorText);
        //
        if(isValidDuration){
            //Transform file into blob URL
            setSrcVideo(URL.createObjectURL(file));
            //
            setFileVideo(e.target.files[0]);
            setFileNameVideo(e.target.files[0].name);
            //
            setVideoPreview(true);
            setEventContents(true);
            setCreateEvents(false);
        }else{
            //Video duration exceeds the allowed limit
            const pTextePays = t("alert_duration");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            e.target.value = '';
        };
    }

    // Helper function to set the subdirectory value
    const setSubDirectory = (value) => {
        Axios.post(`${apiUrl}/set-subdirectory`, {
            tableProduct: value,
        }).then(() => {
            // Log success message or handle response
            const pTextePays = t("image_uplaod");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }).catch(() => {
            // Handle error
            const pTextePays = t("image_failed");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        });
    }

    //Limit the total number of characters (not words)
    const characterLimit = 200; // Set the character limit to 200
    const [textDescription, setTextDescription] = useState('');
    //limit characters for description
    const handleChangeDescription = async (e) => {

        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, characterLimit);
        setTextDescription(limitedText);
    }
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = characterLimit - textDescription.length;

    const handleConfirmLogo = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table16; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        setEventContents(true);
        setCreateEvents(false);
        handleConfirmLogoRef.current.style.display = 'none';
    }

    const handleConfirmVideo = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table16; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        setCreateEvents(true);
        setEventContents(true);
        handleConfirmVideoRef.current.style.display = 'none';
    }

    const handleSaveEvents = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;
        var detailEvents = [];
        var idSubscribe = "";
        var endDate = "";
        var titleEvent = "";
        //
        var tableProduct = "";
        var productTable = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table15;
        }
        //
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table16; //directory to save image in server side
        }

        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const firstWhere = "code_user";
        //
        const eventsImage = eventsImageRef.current.value;
        const eventsName = eventsNameRef.current.value;
        const eventsDescription = eventsDescriptionRef.current.value;
        const eventsViews = "1";
        const eventsVideo = eventsVideoRef.current.value;
        //
        if(!codeutilisateurDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!eventsImage){
            const pTextePays = t("upload_logo");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!eventsName){
            const pTextePays = t("eventname_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!eventsDescription){
            const pTextePays = t("the_event_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!eventsVideo){
            const pTextePays = t("upload_video");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            //check if seller subscription not expired
            await Axios.post(`${apiUrl}/check/one/parameter/table`, {
                tableNameSeller:tableName,
                firstWhere:firstWhere,
                firstParameter:codeutilisateurDiv,
            }).then((response1) => {
                resultat1 = response1.data.length;
                if(resultat1 === 0){
                    const pTextePays = t("error_event");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    detailEvents = response1.data;
                    //retrieve data
                    for (let i=0; i<resultat1; i++){
                        idSubscribe = detailEvents[i].id_advertise;
                        endDate = formatDateData(detailEvents[i].date_end);
                        titleEvent = detailEvents[i].title_advertise;
                    }
                    //
                    setDateEndSubscribeInput(endDate);
                    //check if subscription still valid
                    var resultDiference = differenceTwoDates(endDate);
                    if(resultDiference < 0){
                        setShowModal(true);
                        setShowModalSubscribe(false);
                        setShowModalExpired(false);
                    }else{
                        //check if an event already exists
                        if(titleEvent === ""){
                            //upload image, video and inputs
                            const formData = new FormData();
                            //inputs
                            formData.append("eventsname", eventsName);
                            formData.append("eventsdescription", eventsDescription);
                            formData.append("eventsviews", eventsViews);
                            formData.append("idadvertise", idSubscribe);
                            //file image
                            formData.append("productfile", file);
                            formData.append("productfile", fileName);
                            //file video
                            formData.append("productfile", fileVideo);
                            formData.append("productfile", fileNameVideo);
                            //save image and video in directory belonging
                            formData.append("tablename", tableName);
                            formData.append("thefile", tableProduct);
                            formData.append("apiurl", apiUrl);

                            //update andsave event
                            Axios.post(`${apiUrl}/create/event/advertise`, formData).then(() => {
                                setShowModal(false);
                                setShowModalSubscribe(true);
                                setShowModalExpired(false);
                            });
                        }else{
                            setShowModal(false);
                            setShowModalSubscribe(true);
                            setShowModalExpired(false);
                        }
                    }
                }
            });
        }
    }
    //
    const handleModalSubscribe = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalSubscribe(false);
        setShowModalExpired(false);
        navigateCreateEvents("/specialsubscribe");
    }
    //
    const handleModalNotNow = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        setShowModalSubscribe(false);
    }
    //
    const handleCancelModal = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        setShowModalSubscribe(false);
    }
    //subscription modal
    const handleModalEndSubscribe = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalSubscribe(false);
        setShowModalExpired(true);
    }
    //
    const handleCancelModalEndSubscribe = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        setShowModalSubscribe(false);
    }
    //end event date expired
    const handleModalExpired = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        setShowModalSubscribe(false);
        navigateCreateEvents('/dashboardseller');
    }
    //
    const handleCancelModalExpired = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        setShowModalSubscribe(false);
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_new_events")}</FooterSubTitle>
                <br/>
                <SmallTextLogin>{t("event_logo")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <label htmlFor='upload-btn' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                        <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                        {t("upload_logo")}
                    </label>
                    <SmallInput 
                        id="upload-btn"
                        type='file' 
                        ref={eventsImageRef} 
                        onChange={handleImageChange} 
                        accept="image/*" 
                        style={{ display: 'none' }}
                    />
                </div>
                {imagePreview && (
                    <div>
                        <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                            <img src={src} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </div>
                        <SmallHandlerContainerDiv ref={handleConfirmLogoRef}>
                            <div>
                                <SmallSaveButton onClick={handleConfirmLogo}>
                                    <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                    {t("sellertexte18")}
                                </SmallSaveButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>
                )}
                <br/>
                {eventsContent && (
                    <ModalUser>
                        <SmallTextLogin>{t("event_name")}</SmallTextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <SmallInput type='text' ref={eventsNameRef} placeholder={t("eventname_placeholder")} />
                        </div>
                        <br/>
                        <SmallTextLogin>{t("event_description")}</SmallTextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <SmallTextArea 
                                type='text' 
                                value={textDescription}
                                onChange={handleChangeDescription}
                                ref={eventsDescriptionRef} 
                                placeholder={t('the_event_description')} 
                                maxLength={characterLimit} // Set the maxlength attribute
                            />
                            <div>
                                {remainingCharactersDescription >= 0
                                ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                                : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                            </div>    
                        </div>
                        <br/>
                        <SmallTextLogin>{t("event_video")}</SmallTextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <label htmlFor='upload-btn-video' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                                <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                                {t("upload_video")}
                            </label>
                            <SmallInput 
                                id="upload-btn-video"
                                type='file' 
                                ref={eventsVideoRef} 
                                onChange={handleVideoChange} 
                                accept="video/*" 
                                style={{ display: 'none' }}
                            />
                        </div>
                        <br/>
                        {videoPreview && (
                            <div>
                                <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                    <video src={srcVideo} controls width="90%">
                                        {t("unsupported_browser")}
                                    </video>
                                </div>
                                <SmallHandlerContainerDiv ref={handleConfirmVideoRef}>
                                    <div>
                                        <SmallSaveButton onClick={handleConfirmVideo}>
                                            <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                            {t("sellertexte18")}
                                        </SmallSaveButton>
                                    </div>
                                </SmallHandlerContainerDiv>
                            </div>
                        )}
                        <br/>
                    </ModalUser>
                )}
                {createEvents && (
                    <div>
                        <SmallHandlerContainerDiv>
                            <div>
                                <SmallSaveButton onClick={handleSaveEvents}>
                                    <span style={{ fontSize:'30px'}}><MdOutlineSave /></span>
                                    {t("create")}
                                </SmallSaveButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>
                )}
                {showModal && (
                    <WarningMultiModal
                        text={t("text_subscribe_warning")}
                        textOk={t("ok_create")}
                        textPause={t("not_now")}
                        textNo={t("no_cancel")}
                        onConfirm={handleModalSubscribe}
                        onPause={handleModalNotNow}
                        onCancel={handleCancelModal}
                    />
                )}
                {showModalSubscribe && (
                    <WarningRefundModal
                        text={t("success_subscription")}
                        textRefund={frenchDateFormat(dateEndSubscribeInput)}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalEndSubscribe}
                        onCancel={handleCancelModalEndSubscribe}
                    />
                )}
                {showModalExpired && (
                    <WarningModal
                        text={t("text_expired_warning")}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalExpired}
                        onCancel={handleCancelModalExpired}
                    />
                )}
                <br/><br/>
                <div id="modaloutalertcreateevents"></div>
                <div id="backdropoutalertcreateevents"></div>
            </ModalUser>
        </div>
    )
}

export default CreateEvents
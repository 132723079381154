//import { useState, useEffect } from 'react';
import i18next from "i18next";
//import { useTranslation } from 'react-i18next';
//import "../translations/i18n";

//alertmodal function
export const AlertModal = (elementOutId, elementDropId, pTexte, buttonHandler) =>{
  var divClearModal = document.getElementById(elementOutId);
  let divModal = document.createElement("div");
  divModal.innerHTML = `<div class="modalalert">
        <p class="p_modal">${pTexte}</p>
        <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
    </div>`;
  divClearModal.appendChild(divModal);

  var divClearBackdrop = document.getElementById(elementDropId);
  let divBack = document.createElement("div");
  divBack.innerHTML = `<div class="backdropalert"></div>`;
  divClearBackdrop.appendChild(divBack);

  var buttonChange = document.getElementById(buttonHandler);
  buttonChange.addEventListener("click", (e) => {
    e.preventDefault();

    var divClearModal = document.getElementById(elementOutId);
    while (divClearModal.hasChildNodes()) {
      divClearModal.removeChild(divClearModal.childNodes[0]);
    }

    var divClearModalDrop = document.getElementById(elementDropId);
    while (divClearModalDrop.hasChildNodes()) {
      divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
    }
  });
};

//modal popup hint function to get information based on questionnaire in procedd
export const PopupModal = (modalOutId, modalDropId, hTitle, pModalTexte, modalButtonHandler) => {
  var divClearModal = document.getElementById(modalOutId);
  let divModal = document.createElement("div");
  divModal.innerHTML = `<div class="modalcontainer">
      <button id="${modalButtonHandler}">OK</button>
      <p class="p_modalcontainer">
        <strong>${hTitle}</strong><br/>
        ${pModalTexte}</p>
    </div>`;
  divClearModal.appendChild(divModal);

  var divClearBackdrop = document.getElementById(modalDropId);
  let divBack = document.createElement("div");
  divBack.innerHTML = `<div class="backdropalert"></div>`;
  divClearBackdrop.appendChild(divBack);

  var buttonChange = document.getElementById(modalButtonHandler);
  buttonChange.addEventListener("click", (e) => {
    e.preventDefault();

    var divClearModal = document.getElementById(modalOutId);
    while (divClearModal.hasChildNodes()) {
      divClearModal.removeChild(divClearModal.childNodes[0]);
    }

    var divClearModalDrop = document.getElementById(modalDropId);
    while (divClearModalDrop.hasChildNodes()) {
      divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
    }
  });
}

//handle language change and set local storage for the language selected
export const handleLanguageChange = async (lang) => {
  // Clear localStorage before setting the new language
  localStorage.clear();

  // Set the new language in the localStorage
  localStorage.setItem('choixlangue', lang);

  // Change the language in i18next
  await i18next.changeLanguage(lang);
}

export const downloadFichier = (filename, text) => {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

//add days to current date
export const addCurrentDate = (days) => {
  var newDate = new Date();
  newDate.setDate(newDate.getDate() + days);
  var dd = newDate.getDate();
  var mm = newDate.getMonth() + 1;
  var yyyy = newDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current new Date
  var date = yyyy + "-" + mm + "-" + dd;
  return date;
}

//calculate expired date based on date input
export const addExpiredDate = (theDate,days) => {
  var newDate = new Date(theDate);
  newDate.setDate(newDate.getDate() + days);

  // Ensure the newDate object represents the next day after adding days
  newDate.setHours(0, 0, 0, 0);
  
  // Adjust month (remember that months are zero-based)
  var dd = newDate.getDate();
  var mm = newDate.getMonth() + 1;
  var yyyy = newDate.getFullYear();
  
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  // Current new Date
  var date = yyyy + "-" + mm + "-" + dd;
  return date;
}

//********calculate start date based on date input*********
export const addStartDate = (theDate,days) => {
  var newDate = new Date(theDate);
  newDate.setDate(newDate.getDate() - days);
  var dd = newDate.getDate();
  var mm = newDate.getMonth() + 1;
  var yyyy = newDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current new Date
  var date = yyyy + "-" + mm + "-" + dd;
  return date;
}

//add days to date
export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

//formate date
export const formatDateData = (ladate) => {
  // Date object
  var today = new Date(ladate);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current Date
  var date = yyyy + "-" + mm + "-" + dd;
  return date;
}

//convert database date to local french date format
export const frenchDateFormat = (ladate) => {
  // Date object
  var today = new Date(ladate);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current Date
  var date = dd + "/" + mm + "/" + yyyy;
  return date;
}

export const getCurrentDate = () => {
    // Date object
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    // Current Date
    var date = yyyy + "-" + mm + "-" + dd;
    return date;
}

//calculate days between two dates using javascript
export const differenceTwoDates = (enddate) => {
  let date_1 = new Date(enddate);
  let date_2 = new Date();
  //
  let difference = date_1.getTime() - date_2.getTime();
  //convert difference in miliseconds into days
  let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  //return result totalDays
  return totalDays;
}

//Building a Text Similarity checker using Cosine Similarity in JavaScript and HTML

//1.split the text into words and loop through each word and count how many times the word
//appears in the text
function wordCountMap(str){
  let words = str.split(' ');
  let wordCount = {};
  words.forEach((w) => {
    wordCount[w] = (wordCount[w] || 0) +1;
  });
  return wordCount;
}
//2.Next, we need to make a dictionary of all the words that are present in both 
//the texts we are checking for similarity.
function addWordsToDictionary(wordCountmap, dict){
  for(let key in wordCountmap){
      dict[key] = true;
  }
}
//3.Now we can change our word count map into a vector using our dictionary. 
//The dimensions of our vectors will depend on the number of words we have in our dictionary.
function wordMapToVector(map,dict){
  let wordCountVector = [];
  for (let term in dict){
      wordCountVector.push(map[term] || 0);
  }
  return wordCountVector;
}
//4.Now that we have the function to change text stings into vector, 
//we can start working on calculating their cosine similarity. As you recall from before, 
//cosine similarity is the dot products of the two vectors divided by the product of their magnitude. 
//We add three more functions to calculate the cosine similarity.
function dotProduct(vecA, vecB){
  let product = 0;
  for(let i=0;i<vecA.length;i++){
      product += vecA[i] * vecB[i];
  }
  return product;
}

function magnitude(vec){
  let sum = 0;
  for (let i = 0;i<vec.length;i++){
      sum += vec[i] * vec[i];
  }
  return Math.sqrt(sum);
}

function cosineSimilarity(vecA,vecB){
  return dotProduct(vecA,vecB)/ (magnitude(vecA) * magnitude(vecB));
}
//5.Now we have everything we need, but lets make our life more easier by adding a 
//function that takes two strings rather than vectors.
function textCosineSimilarity(txtA,txtB){
  const wordCountA = wordCountMap(txtA);
  const wordCountB = wordCountMap(txtB);
  let dict = {};
  addWordsToDictionary(wordCountA,dict);
  addWordsToDictionary(wordCountB,dict);
  const vectorA = wordMapToVector(wordCountA,dict);
  const vectorB = wordMapToVector(wordCountB,dict);
  return cosineSimilarity(vectorA, vectorB);
}
//6.We just need to add two more functions to display similarity result to our web page:
function getSimilarityScore(val){
  return Math.round(val * 100)
}
//7.Export the last function
export const checkSimilarity = (text1, text2) =>{
  const similarity = getSimilarityScore(textCosineSimilarity(text1,text2));
  return similarity;
}

//Retrieve country name based on international select value
export const NomPays = (codepays) => {
  var nom = '';
  if(codepays === 'benin'){
    nom = "Bénin";
  };
  if(codepays === 'burkina'){
    nom = "Burkina Faso";
  };
  if(codepays === 'guinee'){
    nom = "Guinée-Conakry";
  };
  if(codepays === 'ivoire'){
    nom = "Côte d'Ivoire";
  };
  if(codepays === 'mali'){
    nom = "Mali";
  };
  if(codepays === 'niger'){
  nom = "Niger";
  };
  if(codepays === 'senegal'){
    nom = "Sénégal";
  };
  if(codepays === 'togo'){
    nom = "Togo";
  };
  if(codepays === 'gambie'){
    nom = "Gambie";
  };
  if(codepays === 'ghana'){
    nom = "Ghana";
  };
  if(codepays === 'liberia'){
    nom = "Liberia";
  };
  if(codepays === 'nigeria'){
      nom = "Nigeria";
  };
  if(codepays === 'sierraleone'){
    nom = "Sierra Leone";
  };
  if(codepays === 'capvert'){
    nom = "Cap Vert";
  };
  if(codepays === 'guineebissau'){
    nom = "Guinée Bissau";
  };
  return nom;
}

//retrieve code pays based on country name
export const RetrieveCodePays = (country) => {
  var codePays = '';
  if(country === 'Bénin'){
    codePays = "benin";
  };
  if(country === 'Burkina Faso'){
    codePays = "burkina";
  };
  if(country === 'Guinée-Conakry'){
    codePays = "guinee";
  };
  if(country === "Côte d'Ivoire"){
    codePays = "ivoire";
  };
  if(country === 'Mali'){
    codePays = "mali";
  };
  if(country === 'Niger'){
    codePays = "niger";
  };
  if(country === 'Sénégal'){
    codePays = "senegal";
  };
  if(country === 'Togo'){
    codePays = "togo";
  };
  if(country === 'Gambie'){
    codePays = "gambie";
  };
  if(country === 'Ghana'){
    codePays = "ghana";
  };
  if(country === 'liberia'){
    codePays = "Liberia";
  };
  if(country === 'Nigeria'){
    codePays = "nigeria";
  };
  if(country === 'Sierra Leone'){
    codePays = "sierraleone";
  };
  if(country === 'Cap Vert'){
    codePays = "capvert";
  };
  if(country === 'Guinée Bissau'){
    codePays = "guineebissau";
  };
  return codePays;
}

//add readonly set attribute to true an input tag
export const ReadOnlyTrue = (elementDiv) => {
  document.getElementById(elementDiv).disabled = true;
}

//add readonly set attribute to false an input tag
export const ReadOnlyFalse = (elementDiv) => {
  document.getElementById(elementDiv).disabled = false;
}

//retrieve international prefixe based on country name
export const CountryPrefixe = (countryDiv) => {
  var prefixe = "";
  if(countryDiv === 'benin'){
    prefixe = "00229";
  };
  if(countryDiv === 'Burkina Faso'){
    prefixe = "00226";
  };
  if(countryDiv === 'Guinée-Conakry'){
    prefixe = "00224";
  };
  if(countryDiv === "Côte d'Ivoire"){
    prefixe = "00225";
  };
  if(countryDiv === 'Mali'){
    prefixe = "00223";
  };
  if(countryDiv === 'Niger'){
    prefixe = "00227";
  };
  if(countryDiv === 'Sénégal'){
    prefixe = "00221";
  };
  if(countryDiv === 'Togo'){
    prefixe = "00228";
  };
  if(countryDiv === 'Gambie'){
    prefixe = "00220";
  };
  if(countryDiv === 'Ghana'){
    prefixe = "00233";
  };
  if(countryDiv === 'Liberia'){
    prefixe = "00231";
  };
  if(countryDiv === 'Nigeria'){
    prefixe = "00234";
  };
  if(countryDiv === 'Sierra Leone'){
    prefixe = "00232";
  };
  if(countryDiv === 'Cap Vert'){
    prefixe = "00238";
  };
  if(countryDiv === 'Guinée Bissau'){
    prefixe = "00245";
  };
  return prefixe;
}

export const createMarkup = (contenu) => {
  return {__html:contenu}
};

//footer hide and show functions
export const HideFooter = () => {
  const footerContainer = document.getElementById('footer');
  if (footerContainer) {
    footerContainer.style.display = 'none';
  }
};

export const ShowFooter = () => {
  const footerContainer = document.getElementById('footer');
  if (footerContainer) {
    footerContainer.style.display = 'block';
  }
};

//assign language to div choixlangue
export const useLanguageSelection = () => {

  const language = document.getElementById('choixlangue').innerText;

  return language;
}

//assign codepays to div
export const CodePays = () => {
  const paysCode = document.getElementById('codepays').innerText;

  return paysCode;
}

//Limit the total number of characters (not words)
export const LimitCharacters = (inputText, characterLimit) => {
  // Check if the number of characters exceeds the character limit
  if (inputText.length <= characterLimit) {
    return inputText;
  } else {
    return inputText.slice(0, characterLimit);
  }
}

//Whatsapp call function
export const WhatsappCall = (prefixe, phone) => {
  //User's phone number (include the country code)
  const userPhoneNumber = prefixe+phone;
  // Create the WhatsApp call link
  const whatsappCallUrl = `https://wa.me/${userPhoneNumber}`;

  // Open the link in a new tab (or your preferred method)
  window.open(whatsappCallUrl, '_blank');
}

//Whatsapp group call function
export const WhatsappGroupCall = (grouplink) => {
  //Replace 'phone number' with the actual WhatsApp group invite link or phone number
  const groupIdentifier = grouplink;
  // Create the WhatsApp group link
  //const whatsappGroupUrl = `https://wa.me/${groupIdentifier}`;

  // Open the link in a new tab (or your preferred method)
  window.open(groupIdentifier, '_blank');
}

//function translate word type from database based on the language
export const TranslateWord = (mot) => {
  //const {t} = useTranslation();

  var resultat = "";
  if(mot === "all"){
    resultat = "cat_all";
  };
  if(mot === "food"){
    resultat = "cat_food";
  };
  if(mot === 'beverage'){
    resultat = "cat_beverage";
  };

  if(mot === 'apparel'){
    resultat = "cat_apparel";
  };

  if(mot === 'health'){
    resultat = "cat_health";
  };

  if(mot === 'beauty'){
    resultat = "cat_beauty";
  };

  if(mot === 'services'){
    resultat = "cat_services";
  };

  if(mot === 'electronic'){
    resultat = "cat_electronic";
  };

  if(mot === 'automotive'){
    resultat = "cat_automotive";
  };

  if(mot === 'agriculture'){
    resultat = "cat_agriculture";
  };

  if(mot === 'house'){
    resultat = "cat_house";
  };

  if(mot === 'sport'){
    resultat = "cat_sport";
  };

  if(mot === 'decor'){
    resultat = "cat_decor";
  };

  if(mot === 'cash'){
    resultat = "profile_cash";
  };

  if(mot === 'online'){
    resultat = "profile_online";
  };

  if(mot === 'correspondance'){
    resultat = "profile_correspondance";
  };

  if(mot === 'order'){
    resultat = "profile_order";
  };

  if(mot === 'commission'){
    resultat = "profile_commission";
  };

  if(mot === 'affiliate'){
    resultat = "profile_affiliate";
  };

  if(mot === 'private'){
    resultat = "profile_private";
  };

  if(mot === 'public'){
    resultat = "profile_public";
  };

  return resultat;
}

//translate mode vente and contact
export const TranslateModeContact = (mot) => {
  var langue = document.getElementById('languecountry').innerText;
  var resultat = "";
  if(langue === "fr"){
    if(mot === "all"){
      resultat = "Tout";
    };
    if(mot === "food"){
      resultat = "Aliment";
    };
    if(mot === 'beverage'){
      resultat = "Boissons";
    };
    if(mot === 'apparel'){
      resultat = "Vêtements";
    };
    if(mot === 'health'){
      resultat = "Santé";
    };
    if(mot === 'beauty'){
      resultat = "Beauté";
    };
    if(mot === 'services'){
      resultat = "Prestations";
    };
    if(mot === 'electronic'){
      resultat = "Électronique";
    };
    if(mot === 'automotive'){
      resultat = "Automobile";
    };
    if(mot === 'agriculture'){
      resultat = "Agriculture";
    };
    if(mot === 'breeding'){
      resultat = "Élevage";
    };
    if(mot === 'house'){
      resultat = "Maisons";
    };
    if(mot === 'sport'){
      resultat = "Sport";
    };
    if(mot === 'decor'){
      resultat = "Décor";
    };
    if(mot === 'cash'){
      resultat = "Espèces";
    };
    if(mot === 'online'){
      resultat = "En ligne";
    };
    if(mot === 'correspondance'){
      resultat = "Correspondance";
    };
    if(mot === 'order'){
      resultat = "Commande";
    };
    if(mot === 'commission'){
      resultat = "Commission";
    };
    if(mot === 'affiliate'){
      resultat = "Affiliation";
    };
    if(mot === 'private'){
      resultat = "Privé";
    };
    if(mot === 'public'){
      resultat = "Publique";
    };
  }

  if(langue === "en"){
    if(mot === "all"){
      resultat = "All";
    };
    if(mot === "food"){
      resultat = "Foods";
    };
    if(mot === 'beverage'){
      resultat = "Beverages";
    };
    if(mot === 'apparel'){
      resultat = "Apparel";
    };
    if(mot === 'health'){
      resultat = "Health";
    };
    if(mot === 'beauty'){
      resultat = "Beauty";
    };
    if(mot === 'services'){
      resultat = "Services";
    };
    if(mot === 'electronic'){
      resultat = "Electronics";
    };
    if(mot === 'automotive'){
      resultat = "Automotive";
    };
    if(mot === 'agriculture'){
      resultat = "Agriculture";
    };
    if(mot === 'breeding'){
      resultat = "Breeding";
    };
    if(mot === 'house'){
      resultat = "Houses";
    };
    if(mot === 'sport'){
      resultat = "Sports";
    };
    if(mot === 'decor'){
      resultat = "Decor";
    };
    if(mot === 'cash'){
      resultat = "Cash";
    };
    if(mot === 'online'){
      resultat = "Online";
    };
    if(mot === 'correspondance'){
      resultat = "Correspondance";
    };
    if(mot === 'order'){
      resultat = "Order";
    };
    if(mot === 'commission'){
      resultat = "Commision";
    };
    if(mot === 'affiliate'){
      resultat = "Affiliation";
    };
    if(mot === 'private'){
      resultat = "Private";
    };
    if(mot === 'public'){
      resultat = "Public";
    };
  }

  if(langue === "pt"){
    if(mot === "all"){
      resultat = "Todos";
    };
    if(mot === "food"){
      resultat = "Alimentos";
    };
    if(mot === 'beverage'){
      resultat = "Bebidas";
    };
    if(mot === 'apparel'){
      resultat = "Vestuário";
    };
    if(mot === 'health'){
      resultat = "Saúde";
    };
    if(mot === 'beauty'){
      resultat = "Beleza";
    };
    if(mot === 'services'){
      resultat = "Serviços";
    };
    if(mot === 'electronic'){
      resultat = "Eletrônica";
    };
    if(mot === 'automotive'){
      resultat = "Automotivo";
    };
    if(mot === 'agriculture'){
      resultat = "Agricultura";
    };
    if(mot === 'breeding'){
      resultat = "Reprodução";
    };
    if(mot === 'house'){
      resultat = "Casas";
    };
    if(mot === 'sport'){
      resultat = "Esportes";
    };
    if(mot === 'decor'){
      resultat = "Decoração";
    };
    if(mot === 'cash'){
      resultat = "Cash";
    };
    if(mot === 'online'){
      resultat = "On-line";
    };
    if(mot === 'correspondance'){
      resultat = "Correspondência";
    };
    if(mot === 'order'){
      resultat = "Ordem";
    };
    if(mot === 'commission'){
      resultat = "Comissão";
    };
    if(mot === 'affiliate'){
      resultat = "Afiliação";
    };
    if(mot === 'private'){
      resultat = "Privado";
    };
    if(mot === 'public'){
      resultat = "Público";
    };
  }

  return resultat;
}

//Limit video size to 2 minutes function
export const ValidateVideoDuration = async (file, errorText) => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    const objectURL = URL.createObjectURL(file);

    video.src = objectURL;

    video.addEventListener('loadedmetadata', function () {
      // Specify the maximum duration (2 minutes = 120 seconds)
      const maxDuration = 120;
      const durationInSeconds = video.duration;

      // Release the object URL
      URL.revokeObjectURL(objectURL);

      // Resolve the promise with the validation result
      resolve(durationInSeconds <= maxDuration);
    });

    video.addEventListener('error', function () {
      // Error loading the video
      console.error(errorText);
      // Resolve the promise with false (validation fails)
      resolve(false);
    });
  });
}

//check if user online function
export const isOnline = () => {
  return navigator.onLine;
}

//replace single apostrophes with double apostrophes
export const EscapeApostrophe = (inputString) => {
  return inputString.replace(/'/g, "''");
}
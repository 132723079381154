import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../translations/i18n";
import image from '../bazali.jpg';
//
import { ShowFooter, handleLanguageChange } from '../helperfunctions/Helper';
//
import './header/imagecentrale.css';
//
import { 
    ImagePrincipaleContainer, 
    ImagePrincipaleDiv, 
    ImgCentrale 
} from './header/HeaderStyles';

function ImageCentrale() {
    const {t} = useTranslation();

    const [showRedirect, setShowRedirect] = useState(false); // State to control the redirect

    const navigateCentrale = useNavigate();

    // Function to clear localStorage
    const clearLocalStorage = () => {
        localStorage.clear();
    };
    
    useEffect(() => {

        // Clear localStorage on mount
        clearLocalStorage();

        // Get the user's device language
        const userLanguage = navigator.language.substring(0, 2);

        // Call the handleLanguageChange function to set the language in localStorage and i18n
        handleLanguageChange(userLanguage);

        // Set a timeout to redirect after 2-3 seconds
        const timeout = setTimeout(() => {
            setShowRedirect(true);
        }, 2000); // Change this value to set the duration in milliseconds

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            clearTimeout(timeout);
        };

    }, []);

    // Redirect based on the device language after the timeout
    useEffect(() => {
        if (showRedirect) {
            const storedLangue = localStorage.getItem('i18nextLng');
            const navigateByLanguage = (language) => {
                switch (language){
                    case "fr":
                        navigateCentrale('/frenchcountries');
                        ShowFooter();
                        break;
                    case "en":
                        navigateCentrale('/englishcountries'); 
                        ShowFooter();
                        break;
                    case "pt":
                        navigateCentrale('/portuguesecountries');
                        ShowFooter();
                        break;
                    default:
                        navigateCentrale('/');
                        ShowFooter();
                        break;
                }
            };

            navigateByLanguage(storedLangue);
        }
    }, [showRedirect,navigateCentrale]);

    return (
        <div>
            {/*<br/>
            <PrincipalSubTitle>{t("principaltitle")}</PrincipalSubTitle>
            <PrincipalIntro>{t("principaltexte1")}</PrincipalIntro>
            <br/>*/}
            <ImagePrincipaleContainer id='imagecentrale'>
                <div style={{width:'250px', fontSize:'20px', color:'blue', fontWeight:'600'}}>{t("slogan_one")}</div>
                <ImagePrincipaleDiv className='image_centrale'>
                    <ImgCentrale 
                        className="image_center" 
                        src={image} 
                        alt='logocenter' 
                        loading="lazy" // Lazy loading for the image
                    />
                </ImagePrincipaleDiv>
                <div style={{width:'250px', fontSize:'20px', color:'blue', fontWeight:'600'}}>{t("slogan_two")}</div>
                <div className="circle" style={{animationDelay:'-3s'}}></div>
                <div className="circle" style={{animationDelay:'-2s'}}></div>
                <div className="circle" style={{animationDelay:'-1s'}}></div>
                <div className="circle" style={{animationDelay:'0s'}}></div>
            </ImagePrincipaleContainer>
        </div>
    )
}

export default ImageCentrale
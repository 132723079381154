import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import i18next from "i18next";
import { 
    Container, 
    HeaderContainerHeaderDiv,
    HeaderContainerDiv, 
    Logo, 
    Year, 
    LanguageSelectBox, 
    LanguageOption
 } from './HeaderStyles';
//import { HandlerContainerDiv } from '../../components/ComponentStyles';
import logoImage from '../../bazali.jpg';

import { ShowFooter, isOnline } from '../../helperfunctions/Helper';

function Header() {
    const {t} = useTranslation();

    const navigateHeader = useNavigate();

    const choixlangueRef = useRef(null);
    const codepaysRef = useRef(null);
    const selectlangueRef = useRef(null);

    const [selectedLanguage, setSelectedLanguage] = useState("");

    //check if user's device online
    // Effect to change color when the component mounts
    useEffect(() => {
        if(isOnline()){
            document.getElementById('imagecolor').style.color = 'white';
        }else{
            document.getElementById('imagecolor').style.color = 'red';
        }
    });

    function getCurrentYear() {
        return new Date().getFullYear();
    }

    const handleLanguageChange = async (e) => {
        e.preventDefault();

        const selectlangue = selectlangueRef.current.value;
        //
        i18next.changeLanguage(selectlangue);
        // Store the selected option in localStorage
        localStorage.setItem('choixlangue', selectlangue);
        //
        setSelectedLanguage(selectlangue);
        if(selectlangue === 'fr'){
            navigateHeader('/frenchcountries');
            ShowFooter();
        }else if(selectlangue === 'en'){
            navigateHeader('/englishcountries'); 
            ShowFooter();
        }else if(selectlangue === 'pt'){
           navigateHeader('/portuguesecountries');
           ShowFooter();
        }
    }

    return (
        <div>
            <Container>
                <Logo>
                    <img src={logoImage} alt="Logo" />
                    <span id='imagecolor'>BAZALI</span>
                </Logo>
                <Year>{getCurrentYear()}</Year>
                <div style={{display:'none'}}>
                    <div ref={choixlangueRef} id="choixlangue">{selectedLanguage}</div>
                    <div ref={codepaysRef} id="codepays"></div>
                </div>
                <LanguageSelectBox id='selectlangue' ref={selectlangueRef} onChange={handleLanguageChange} >
                    <LanguageOption value="">{t('langue')}</LanguageOption>
                    <LanguageOption value="fr">{t('francais')}</LanguageOption>
                    <LanguageOption value="en">{t('anglais')}</LanguageOption>
                    <LanguageOption value="pt">{t('portugais')}</LanguageOption>
                </LanguageSelectBox>
            </Container>
            <HeaderContainerDiv>
                <div style={{color:'#246175', fontSize:'20px'}} id='namecountry'></div>
                <div style={{display:'none'}} id='codecountry'></div>
                <div style={{display:'none'}} id='prefixecountry'></div>
                <div style={{display:'none'}} id='languecountry'></div>
                <div style={{color:'#246175', fontSize:'20px'}} id='codeutilisateur'></div>
                <div style={{color:'#246175', fontSize:'20px'}} id='usernom'></div>
                <div style={{color:'#3366ff', fontSize:'20px', display:'none'}} id='typeutilisateur'></div>
                <div style={{display:'none'}} id='codeproduit'></div> {/**this allows to set the main product code */}
                <div style={{display:'none'}} id='typeproduit'></div> {/**this allows to set the main product type selected */}
                <div style={{color:'#3366ff', fontSize:'20px', fontWeight:'600', display:'none'}} id='categoryutilisateur'></div>
            </HeaderContainerDiv>
            
            <HeaderContainerHeaderDiv>
                <label id='searchcountry' style={{fontSize:'20px', color:'blue', fontWeight:'bold'}}></label>
                <div id='countrysearch' style={{display:'none'}}></div>
            </HeaderContainerHeaderDiv>
        </div>
    )
}

export default Header
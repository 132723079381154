import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../../translations/i18n";
//
import { MdOutlineCalculate, MdOutlineSave } from 'react-icons/md';
import { BsUpload } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
//
import { retrieveTableQuery } from '../../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal, LimitCharacters, getCurrentDate, formatDateData, addDays, frenchDateFormat } from '../../../../../helperfunctions/Helper';

import apiUrl from '../../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallPriceInput,
    SmallCurrencyInput,
    //SmallProductLeftInput,
    //SmallProductRightInput,
    SmallHandlerContainerDiv,
    SmallSaveButton,
} from '../../../../ComponentStyles';

function CreateDiscount() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertcreatediscount";
    const elementDropId = "backdropoutalertcreatediscount";
    const buttonHandler = "ouiHandlerAlertCreateDiscountBtn";

    const discountNameRef = useRef(null);
    const discountDescriptionRef = useRef(null);
    const discountPriceRef = useRef(null);
    const discountCurrencyRef = useRef(null);
    const discountRateRef = useRef(null);
    const discountNewPriceRef = useRef(null);
    const discountStartDateRef = useRef(null);
    const discountEndDateRef = useRef(null);
    const discountImageRef = useRef(null);
    //
    const handleConfirmDiscountRef = useRef(null);
    const createDiscountRef = useRef(null);

    const [discountSuiteModalOpen, setDiscountSuiteModalOpen] = useState(false);
    const [discountNewPrice, setDiscountNewPrice] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [imagePreview, setImagePreview] = useState(null);

    //image sources and resources states
    const [src, setSrc] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    //Limit the total number of characters (not words)
    const characterLimit = 500; // Set the character limit to 500
    const [textDescription, setTextDescription] = useState('');
    //limit characters for description
    const handleChangeDescription = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, characterLimit);
        setTextDescription(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = characterLimit - textDescription.length;

    const handleImageChange = async (e) => {
        
        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            //
            // Transform file into blob URL
            setSrc(URL.createObjectURL(file));
            //
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

        }
    }

    // Helper function to set the subdirectory value
    const setSubDirectory = (value) => {
        Axios.post(`${apiUrl}/set-subdirectory`, {
            tableProduct: value,
        }).then(() => {
            // Log success message or handle response
            const pTextePays = t("image_uplaod");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }).catch(() => {
            // Handle error
            const pTextePays = t("image_failed");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        });
    }

    const handleNewPrice = async (e) => {
        e.preventDefault();

        //calculate new price
        var previousPrice = parseFloat(discountPriceRef.current.value);
        var percentDiscount = parseFloat(discountRateRef.current.value);
        if(!previousPrice){
            const pTextePays = t("product_Previous_price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!percentDiscount){
            const pTextePays = t("discount_rate");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            var totalDiscount = (previousPrice * (percentDiscount / 100))
            var newPrice = Math.round((previousPrice - totalDiscount));
            // Update the discountNewPrice state with the calculated new price
            setDiscountNewPrice(newPrice);
            setDiscountSuiteModalOpen(true);
        }
    }

    const handleEndDate = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultatEndDate = 0;
        var checkEndDate = [];
        var startDate = '';
        var expireDate = '';
        var numberDays = 14;
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codeproduitDiv = document.getElementById('codeproduit').innerText;
        const discountStartDate = discountStartDateRef.current.value;
        
        if(!discountStartDate){
            const pTextePays = t("date_start");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            await Axios.post(`${apiUrl}/check/end/product`, {
                tableNameSeller:tableName,
                codeUtilisateur:codeutilisateurDiv,
                codeProduct:codeproduitDiv,
            }).then((responseDate) => {
                resultatEndDate = responseDate.data.length;
                //console.log(resultatEndDate);
                if(resultatEndDate === 0){
                    const pTextePays = t("error_product");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    checkEndDate = responseDate.data;
                    for(let i=0; i<resultatEndDate; i++){
                        startDate = formatDateData(checkEndDate[i].date_product);
                        expireDate = formatDateData(checkEndDate[i].date_expired);
                    }
                    //console.log(startDate)
                    //console.log(expireDate);
                    var endDate = formatDateData(addDays(discountStartDate,numberDays));
                    //console.log(endDate);
                    if(discountStartDate < startDate){
                        const pTextePays = t("error_date") + ` ${frenchDateFormat(startDate)}`;
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                    }else{
                        if(endDate <= expireDate){
                            setEndDate(endDate);
                        }else if(endDate > expireDate){
                            const pTextePays = t("error_end_date");
                            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                            setEndDate(0);
                        }
                    }
                }
            });
        }
    }

    const handleConfirmDiscount = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table8; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        createDiscountRef.current.style.display = 'block';
        handleConfirmDiscountRef.current.style.display = 'none';
    }

    const handleSaveDiscount = async (e) => {
        e.preventDefault();

        var tableProduit = "";
        var produitTable = [];
        var resultatProduit = 0;
        var checkStatus = [];
        var planSeller = "";
        //
        var tableProduct = "";
        var productTable = [];
        //var resultatDiscount = 0;
        //
        var tableName = "";
        var signupTable = [];
        var resultatTable = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table1 to use in backend
        tableProduit = retrieveTableQuery(codecountryDiv);
        if(produitTable){
            tableProduit = tableProduit.table.table4;
        }
        //
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table8; //directory to save image in server side
        }
        //
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table7;
        }
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codeproduitDiv = document.getElementById('codeproduit').innerText;
        //
        const discountName = discountNameRef.current.value;
        const discountDescription = discountDescriptionRef.current.value;
        const discountPrice = discountPriceRef.current.value;
        const discountCurrency = discountCurrencyRef.current.value;
        const discountRate = discountRateRef.current.value;
        const discountNewPrice = discountNewPriceRef.current.value;
        const discountStartDate = discountStartDateRef.current.value;
        const discountEndDate = discountEndDateRef.current.value;
        const discountImage = discountImageRef.current.value;
        const discountStatus = 'created';
        const discountDate = getCurrentDate();
        const discountViews = "1";
        //
        if(!codeutilisateurDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!codeproduitDiv){
            const pTextePays = t("error_product");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountName){
            const pTextePays = t("product_name");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!discountDescription){
            const pTextePays = t("product_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountPrice){
            const pTextePays = t("product_Previous_price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!discountCurrency){
            const pTextePays = t("currency_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountRate){
            const pTextePays = t("discount_rate");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountNewPrice){
            const pTextePays = t("New price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountStartDate){
            const pTextePays = t("date_start");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountEndDate){
            const pTextePays = t("date_end");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountImage){
            const pTextePays = t("upload_image");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            //check the main product status
            await Axios.post(`${apiUrl}/check/status/product`, {
                tableNameSeller:tableProduit,
                codeUtilisateur:codeutilisateurDiv,
                codeProduct:codeproduitDiv,
            }).then((responseProduit) => {
                resultatProduit = responseProduit.data.length;
                if(resultatProduit === 0){
                    const pTextePays = t("error_product");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    checkStatus = responseProduit.data;
                    for(let i=0; i<resultatProduit; i++){
                        planSeller = checkStatus[i].plan_seller;
                    }
                    //if product planSeller = 'trial' then only one product discount to create
                    if(planSeller === 'trial'){
                        //check discount table if already exists
                        Axios.post(`${apiUrl}/check/code/discount`, {
                            tableNameSeller:tableName,
                            codeUtilisateur:codeutilisateurDiv,
                            codeProduct:codeproduitDiv,
                        }).then((responsediscount) => {
                            resultatTable = responsediscount.data.length;
                            if(resultatTable === 0){
                                //insert into table discount
                                //upload image and inputs
                                const formData = new FormData();

                                //inputs
                                formData.append("usercode", codeutilisateurDiv);
                                formData.append("codepays", codecountryDiv);
                                formData.append("codeproduct", codeproduitDiv);
                                formData.append("productname", discountName);
                                formData.append("productdescription", discountDescription);
                                formData.append("productprice", discountPrice);
                                formData.append("productcurrency", discountCurrency);
                                formData.append("percentproduct", discountRate);
                                formData.append("newprice", discountNewPrice);
                                formData.append("datestart", discountStartDate);
                                formData.append("dateend", discountEndDate);
                                formData.append("discountviews", discountViews);
                                //file
                                formData.append("productfile", file);
                                formData.append("productfile", fileName);
                                //other inputs
                                formData.append("productstatus", discountStatus);
                                formData.append("productdate", discountDate);
                                //save image in directory belonging
                                formData.append("tablename", tableName);
                                formData.append("thefile", tableProduct);
                                formData.append("apiurl", apiUrl);

                                Axios.post(`${apiUrl}/create/discount/product`, formData).then(() => {
                                    const pTextePays = t("discount_success");
                                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                                });
                            }else{
                                const pTextePays = t("limit_product_discount");
                                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                            }
                        });
                    }else{
                        const pTextePays = "Based on type plan, check the number of discount allowed and check if one of them already exists to procees.To emplement after";
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                    }
                }
            });
        }
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_new_discount")}</FooterSubTitle>
                <br/>
                <SmallTextLogin>{t("product_name")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <SmallInput type='text' ref={discountNameRef} placeholder={t("productname_placeholder")} />
                </div>
                <br/>
                <SmallTextLogin>{t("product_description")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <SmallTextArea 
                        type='text' 
                        value={textDescription}
                        onChange={handleChangeDescription}
                        ref={discountDescriptionRef} 
                        placeholder={t('the_product_description')} 
                        maxLength={characterLimit} // Set the maxlength attribute
                    />
                    <div>
                        {remainingCharactersDescription >= 0
                        ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                    </div>    
                </div>
                <br/>
                <SmallTextLogin>{t("product_Previous_price")}</SmallTextLogin>
                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                    <div style={{display:'block',textAlign:'center'}}>
                        <SmallPriceInput type='number' ref={discountPriceRef}></SmallPriceInput>
                    </div>
                    <div>
                        <SmallCurrencyInput type='text' ref={discountCurrencyRef} placeholder={t("currency_placeholder")} />
                    </div>
                </div>
                <br/>
                <SmallTextLogin>{t("discount_rate")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <SmallInput type='number' ref={discountRateRef} placeholder={t("rate_placeholder")} />
                </div>
                <br/>
                <SmallHandlerContainerDiv>
                    <div>
                        <SmallSaveButton onClick={handleNewPrice}>
                            <span style={{ fontSize:'30px'}}><MdOutlineCalculate /></span>
                            {t("calculate")}
                        </SmallSaveButton>
                    </div>
                </SmallHandlerContainerDiv>
                <br/>
                {discountSuiteModalOpen && (
                    <ModalUser>
                        <SmallTextLogin>{t("new_price")}</SmallTextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <SmallInput 
                                type='number' 
                                ref={discountNewPriceRef} 
                                value={discountNewPrice}
                                onChange={(e) => setDiscountNewPrice(e.target.value)}
                                readOnly
                            />
                        </div>
                        <br/>
                        <SmallTextLogin>{t("date_start")}</SmallTextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <SmallInput type='date' ref={discountStartDateRef} />
                        </div>
                        <br/>
                        <SmallTextLogin>{t("date_end")}</SmallTextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <SmallInput 
                                type='date' 
                                ref={discountEndDateRef} 
                                value={endDate}
                                onClick={handleEndDate}
                                readOnly
                            />
                        </div>
                        <br/>
                        <SmallTextLogin>{t("product_image")}</SmallTextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <label htmlFor='upload-btn' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                                <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                                {t("upload_image")}
                            </label>
                            <SmallInput 
                                id="upload-btn"
                                type='file' 
                                ref={discountImageRef} 
                                onChange={handleImageChange} 
                                accept="image/*" 
                                style={{ display: 'none' }}
                            />
                        </div>
                    </ModalUser>
                )}
                {imagePreview && (
                    <div>
                        <div src={src} style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                            <img src={imagePreview} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </div>
                        <SmallHandlerContainerDiv ref={handleConfirmDiscountRef}>
                            <div>
                                <SmallSaveButton onClick={handleConfirmDiscount}>
                                    <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                    {t("sellertexte18")}
                                </SmallSaveButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>  
                )}

                <br/>
                <div ref={createDiscountRef} style={{display:'none'}}>
                    <SmallHandlerContainerDiv>
                        <div>
                            <SmallSaveButton onClick={handleSaveDiscount}>
                                <span style={{ fontSize:'30px'}}><MdOutlineSave /></span>
                                {t("save_product")}
                            </SmallSaveButton>
                        </div>
                    </SmallHandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalertcreatediscount"></div>
                <div id="backdropoutalertcreatediscount"></div>
            </ModalUser>
        </div>
    )
}

export default CreateDiscount
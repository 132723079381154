import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../../translations/i18n";
//
import OpenButton from '../../../../reusablecomponents/buttons/OpenButton';
//
import { MdOutlineCalculate, MdModeEdit } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
import { BsUpload } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
//
import { retrieveTableQuery } from '../../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal, LimitCharacters, /*getCurrentDate,*/ formatDateData, addDays, frenchDateFormat } from '../../../../../helperfunctions/Helper';

import apiUrl from '../../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    ModalUser,
    FormScrollerWidgetSm,
    TableWidgetSmTable,
    TableThead,
    ThWidgetSmTh,
    TableTbody,
    TableTd,
    TableButton,
    SmallTextLogin,
    SmallInput,
    SmallTextArea,
    SmallPriceInput,
    SmallCurrencyInput,
    SmallHandlerContainerDiv,
    SmallSaveButton,
    SmallEditButton,
} from '../../../../ComponentStyles';

function EditDiscount() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalerteditdiscount";
    const elementDropId = "backdropoutalerteditdiscount";
    const buttonHandler = "ouiHandlerAlertEditDiscountBtn";

    // Define state variables for modals
    const [startEditDiscountModalOpen, setStartEditDiscountModalOpen] = useState(true);
    const [editDiscountTableModalOpen, setEditDiscountTableModalOpen] = useState(false);
    const [editDiscountModalOpen, setEditDiscountModalOpen] = useState(false);
    const [discountSuiteModalOpen, setDiscountSuiteModalOpen] = useState(false);

    const [userEditDiscount, setUserEditDiscount] = useState([0]);
    const [editDetailDiscount, setEditDetailDiscount] = useState([0]);
    const [discountNewPrice, setDiscountNewPrice] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [imageEditPreview, setImageEditPreview] = useState(null);

    //image sources and resources states
    const [src, setSrc] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    const idEditDiscountRef = useRef(null);
    const discountEditNameRef = useRef(null);
    const discountEditDescriptionRef = useRef(null);
    const discountEditPriceRef = useRef(null);
    const discountEditCurrencyRef = useRef(null);
    const discountEditRateRef = useRef(null);
    const discountEditNewPriceRef = useRef(null);
    const discountEditStartDateRef = useRef(null);
    const discountEditEndDateRef = useRef(null);
    const editDiscountFileNameRef = useRef(null);
    const discountEditImageRef = useRef(null);

    const handleConfirmEditDiscountRef = useRef(null);
    const editDiscountRef = useRef(null);

    const handleStartDiscount = async () => {
        
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        //var checkResultat = [];

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const codeproduitDiv = document.getElementById('codeproduit').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table7;
        }

        await Axios.post(`${apiUrl}/edit/discount/table`, {
            tableNameSeller:tableName,
            codeUtilisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
            codeProduct:codeproduitDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                
                setUserEditDiscount(response1.data);
                setStartEditDiscountModalOpen(false);
                setEditDiscountTableModalOpen(true);
                setEditDiscountModalOpen(false);
                setDiscountSuiteModalOpen(false);
            }
        });
    }

    const handleEditDiscount = async (discountId) => {

        var tableName = "";
        var signupTable = [];
        var resultat2 = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table7;
        }

        var idDiscount = discountId;

        await Axios.post(`${apiUrl}/edit/discount/detail`, {
            tableNameSeller:tableName,
            idDiscount:idDiscount,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                
                setEditDetailDiscount(response2.data);
                setStartEditDiscountModalOpen(false);
                setEditDiscountTableModalOpen(false);
                setEditDiscountModalOpen(true);
                setDiscountSuiteModalOpen(false);
            }
        });
    }

    //Limit the total number of characters (not words)
    //var characterLimit = 500; // Set the character limit to 500
    var descriptionWords = 0;
    var newCharacterLimitDescription = 500 - descriptionWords;
    const [textDescription, setTextDescription] = useState('');
    //limit characters for description
    const handleChangeDescription = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, newCharacterLimitDescription);
        setTextDescription(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = newCharacterLimitDescription - textDescription.length;

    const handleEditNewPrice = async (e) => {
        e.preventDefault();

        //calculate new price
        var previousPrice = parseFloat(discountEditPriceRef.current.value);
        var percentDiscount = parseFloat(discountEditRateRef.current.value);
        if(!previousPrice){
            const pTextePays = t("product_Previous_price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!percentDiscount){
            const pTextePays = t("discount_rate");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            var totalDiscount = (previousPrice * (percentDiscount / 100))
            var newPrice = Math.round((previousPrice - totalDiscount));
            // Update the discountNewPrice state with the calculated new price
            setDiscountNewPrice(newPrice);
            setStartEditDiscountModalOpen(false);
            setEditDiscountTableModalOpen(false);
            setEditDiscountModalOpen(true);
            setDiscountSuiteModalOpen(true);
        }
    }

    const handleEndDate = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultatEndDate = 0;
        var checkEndDate = [];
        var startDate = '';
        var expireDate = '';
        var numberDays = 14;
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codeproduitDiv = document.getElementById('codeproduit').innerText;
        const discountStartDate = discountEditStartDateRef.current.value;

        if(!discountStartDate){
            const pTextePays = t("date_start");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            await Axios.post(`${apiUrl}/check/end/product`, {
                tableNameSeller:tableName,
                codeUtilisateur:codeutilisateurDiv,
                codeProduct:codeproduitDiv,
            }).then((responseDate) => {
                resultatEndDate = responseDate.data.length;
                //console.log(resultatEndDate);
                if(resultatEndDate === 0){
                    const pTextePays = t("error_product");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    checkEndDate = responseDate.data;
                    for(let i=0; i<resultatEndDate; i++){
                        startDate = formatDateData(checkEndDate[i].date_product);
                        expireDate = formatDateData(checkEndDate[i].date_expired);
                    }
                    var endDate = formatDateData(addDays(discountStartDate,numberDays));
                    if(discountStartDate < startDate){
                        const pTextePays = t("error_date") + `${frenchDateFormat(startDate)}`;
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                    }else{
                        if(endDate <= expireDate){
                            setEndDate(endDate);
                        }else if(endDate > expireDate){
                            const pTextePays = t("error_end_date");
                            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                            setEndDate(0);
                        }
                    }
                }
            });
        }
    }

    const handleConfirmEditDiscount = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table8; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        editDiscountRef.current.style.display = 'block';
        handleConfirmEditDiscountRef.current.style.display = 'none';
    }

    const handleImageChange = async (e) => {
        e.preventDefault();

        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageEditPreview(reader.result);
            };
            reader.readAsDataURL(file);
            //
            // Transform file into blob URL
            setSrc(URL.createObjectURL(file));
            //
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

        }
    }

    // Helper function to set the subdirectory value
    const setSubDirectory = (value) => {
        Axios.post(`${apiUrl}/set-subdirectory`, {
            tableProduct: value,
        }).then(() => {
            // Log success message or handle response
            const pTextePays = t("image_uplaod");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }).catch(() => {
            // Handle error
            const pTextePays = t("image_failed");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        });
    }

    const handleEditProductDiscount = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];

        var tableName = "";
        var signupTable = [];
        //var resultat1 = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table8; //directory to save image in server side
        }
        //
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table7;
        }

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const idEditDiscount = idEditDiscountRef.current.value;
        const discountEditName = discountEditNameRef.current.value;
        const discountEditDescription = discountEditDescriptionRef.current.value;
        const discountEditPrice = discountEditPriceRef.current.value;
        const discountEditCurrency = discountEditCurrencyRef.current.value;
        const discountEditRate = discountEditRateRef.current.value;
        const discountEditNewPrice = discountEditNewPriceRef.current.value;
        const discountEditStartDate = discountEditStartDateRef.current.value;
        const discountEditEndDate = discountEditEndDateRef.current.value;
        //const discountEditImage = discountEditImageRef.current.value;
        const editDiscountFileName = editDiscountFileNameRef.current.value;
        var str = editDiscountFileName;
        //replace with (.)
        const replaceEditProductFileName = str.replace(`${apiUrl}`, "./uploads");
        const discountEditImage = discountEditImageRef.current.value;
        //
        if(!codeutilisateurDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountEditName){
            const pTextePays = t("product_name");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!discountEditDescription){
            const pTextePays = t("product_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountEditPrice){
            const pTextePays = t("product_Previous_price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!discountEditCurrency){
            const pTextePays = t("currency_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountEditRate){
            const pTextePays = t("discount_rate");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountEditNewPrice){
            const pTextePays = t("New price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountEditStartDate){
            const pTextePays = t("date_start");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!discountEditEndDate){
            const pTextePays = t("date_end");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            if(!discountEditImage){
                await Axios.post(`${apiUrl}/edit/seller/discount`, {
                    tableName:tableName,
                    discountEditName:discountEditName,
                    discountEditDescription:discountEditDescription,
                    discountEditPrice:discountEditPrice,
                    discountEditCurrency:discountEditCurrency,
                    discountEditRate:discountEditRate,
                    discountEditNewPrice:discountEditNewPrice,
                    discountEditStartDate:discountEditStartDate,
                    discountEditEndDate:discountEditEndDate,
                    editDiscountFileName:editDiscountFileName,
                    idEditDiscount:idEditDiscount,
                }).then(() => {
                    const pTextePays = t("edit_product_success");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                });
            }

            if(discountEditImage){
                //upload image and inputs
                const formData = new FormData();

                //inputs
                formData.append("ideditdiscount", idEditDiscount);
                formData.append("discounteditname",  discountEditName);
                formData.append("discounteditdescription", discountEditDescription);
                formData.append("discounteditprice", discountEditPrice);
                formData.append("discounteditcurrency", discountEditCurrency);
                formData.append("discounteditrate", discountEditRate);
                formData.append("discounteditnewprice", discountEditNewPrice);
                formData.append("discounteditstartdate", discountEditStartDate);
                formData.append("discounteditenddate", discountEditEndDate)
                formData.append("replaceeditproductfilname", replaceEditProductFileName);
                //file
                formData.append("productfile", file);
                formData.append("productfile", fileName);
                //save image in directory belonging
                formData.append("tablename", tableName);
                formData.append("thefile", tableProduct);
                formData.append("apiurl", apiUrl);

                await Axios.post(`${apiUrl}/edit/change/seller/discount`, formData).then(() => {
                    const pTextePays = t("edit_product_success");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                });
            }
        }
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_edit_discount")}</FooterSubTitle>
                <br/>
                {startEditDiscountModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartDiscount}
                        />
                    </div>
                )}
                
                {editDiscountTableModalOpen && (
                    <div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <TableWidgetSmTable>
                                <TableThead>
                                    <tr>
                                        <ThWidgetSmTh>{t("product")}</ThWidgetSmTh>
                                        <ThWidgetSmTh>{t("action")}</ThWidgetSmTh>
                                    </tr>
                                </TableThead>
                                <TableTbody>
                                    {userEditDiscount.map((valedit, index) => {
                                        return(
                                            <tr key={index}>
                                                <TableTd>{valedit.name_product}</TableTd>
                                                <TableTd ><TableButton type="button" onClick={() => handleEditDiscount(valedit.id_discount)}>{t("edit")}</TableButton></TableTd>
                                            </tr>
                                        )
                                    })}
                                </TableTbody>
                            </TableWidgetSmTable>
                        </FormScrollerWidgetSm>
                    </div>
                )}
                {editDiscountModalOpen && (
                    <div>
                        {editDetailDiscount.map((valeditdetail, indexeditdetail) => (
                            <ModalUser key={indexeditdetail}>
                                <input style={{display:'none'}} ref={idEditDiscountRef} defaultValue={valeditdetail.id_discount} />
                                <br/>
                                <SmallTextLogin>{t("product_name")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={discountEditNameRef} defaultValue={valeditdetail.name_product} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        value={textDescription || valeditdetail.description_product}
                                        onChange={handleChangeDescription}
                                        ref={discountEditDescriptionRef} 
                                        maxLength={newCharacterLimitDescription} // Set the maxlength attribute
                                    />
                                    <div>
                                        {remainingCharactersDescription >= 0
                                        ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_Previous_price")}</SmallTextLogin>
                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallPriceInput type='number' ref={discountEditPriceRef} defaultValue={valeditdetail.price_product}></SmallPriceInput>
                                    </div>
                                    <div>
                                        <SmallCurrencyInput type='text' ref={discountEditCurrencyRef} defaultValue={valeditdetail.currency_product} />
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("previous_image")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={editDiscountFileNameRef} defaultValue={valeditdetail.image_discount} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("discount_rate")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='number' ref={discountEditRateRef} defaultValue={valeditdetail.percent_discount} />
                                </div>
                                <br/>
                                <SmallHandlerContainerDiv>
                                    <div>
                                        <SmallSaveButton onClick={handleEditNewPrice}>
                                            <span style={{ fontSize:'30px'}}><MdOutlineCalculate /></span>
                                            {t("calculate")}
                                        </SmallSaveButton>
                                    </div>
                                </SmallHandlerContainerDiv>
                                <br/>
                                {discountSuiteModalOpen && (
                                    <ModalUser>
                                        <SmallTextLogin>{t("new_price")}</SmallTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallInput 
                                                type='number' 
                                                ref={discountEditNewPriceRef} 
                                                value={discountNewPrice || valeditdetail.new_price}
                                                onChange={(e) => setDiscountNewPrice(e.target.value)}
                                                readOnly
                                            />
                                        </div>
                                        <br/>
                                        <SmallTextLogin>{t("date_start")}</SmallTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallInput type='date' ref={discountEditStartDateRef} defaultValue={valeditdetail.date_start} />
                                        </div>
                                        <br/>
                                        <SmallTextLogin>{t("date_end")}</SmallTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <SmallInput 
                                                type='date' 
                                                ref={discountEditEndDateRef} 
                                                value={endDate || valeditdetail.date_end}
                                                onClick={handleEndDate}
                                                readOnly
                                            />
                                        </div>
                                        <br/>
                                        <SmallTextLogin>{t("product_image")}</SmallTextLogin>
                                        <div style={{display:'block',textAlign:'center'}}>
                                            <label htmlFor='upload-btn' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                                                <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                                                {t("upload_image")}
                                            </label>
                                            <SmallInput 
                                                id="upload-btn"
                                                type='file' 
                                                ref={discountEditImageRef} 
                                                onChange={handleImageChange} 
                                                accept="image/*" 
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </ModalUser>
                                )}

                                {imageEditPreview && (
                                    <div>
                                        <div src={src} style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                            <img src={imageEditPreview} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                        </div>
                                        <SmallHandlerContainerDiv ref={handleConfirmEditDiscountRef}>
                                            <div>
                                                <SmallSaveButton onClick={handleConfirmEditDiscount}>
                                                    <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                                    {t("sellertexte18")}
                                                </SmallSaveButton>
                                            </div>
                                        </SmallHandlerContainerDiv>
                                    </div>
                                )}                              
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/>
                <div ref={editDiscountRef} style={{display:'none'}}>
                    <SmallHandlerContainerDiv>
                        <div>
                            <SmallEditButton onClick={handleEditProductDiscount}>
                                <span style={{ fontSize:'30px'}}><MdModeEdit /></span>
                                {t("edit")}
                            </SmallEditButton>
                        </div>
                    </SmallHandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalerteditdiscount"></div>
                <div id="backdropoutalerteditdiscount"></div>
            </ModalUser>
        </div>
    )
}

export default EditDiscount
export const TRANSLATIONS_PT = {
    langue:"Língua ?",
    francais:"Francês",
    anglais:"Inglês",
    portugais:"Português",
    //
    allright:"Todos direitos reservados",
    apropos:"Sobre",
    contact:"Contate-nos",
    faq:"FAQ",
    privacy:"Privacidade",
    termes:"Termos",
    //translate a propos
    apropostitre:"Sobre Bazali",
    aproposrevision:"Última revisão setembro 2023",
    aproposintro:"A lógica comercial dita que todo vendedor é um comprador, mas nem todo comprador é um vendedor.",
    aproposintrosuite:"Bazali é um termo da língua de rua da Costa do Marfim (nouchi ou noussia) derivado da língua francesa e que significa: Vender algo rápido e barato, até mesmo se livrar de algo jogando fora ou oferecendo-o gratuitamente a outra pessoa .",
    aproposintroensuite:"Bazali é uma plataforma de interligação e intercâmbio entre vendedores e compradores de um determinado país, bem como com os 15 países da CEDEAO para uma população estimada em mais de 350 milhões de habitantes onde a comercialização e comércio de diversos produtos estagnou desde 1960.",
    //
    soustitre1:"Porquê Bazali?",
    soustitretexte1:"Ninguém poderia negar que nunca se viu em uma situação em que fosse difícil ou mesmo impossível comprar algo sem saber que a coisa não estava longe de onde ele estava.",
    soustitretexte2:"Quantas vezes nossas donas de casa são obrigadas a percorrer todo o mercado por horas, com o simples propósito de encontrar bens e produtos a preços vantajosos para o consumo diário da família.",
    soustitretexte3:"Quão difícil é para os nossos operadores económicos negociar e trocar com os seus pares nos países da sub-região da CEDEAO quando falamos de integração económica sub-regional de pessoas e bens.",
    soustitretexte4:"Vários cenários são muitos e a lista não é exaustiva...",
    //
    soustitre2:"Especificidades",
    soustitretexte5:"Bazali é um aplicativo móvel híbrido capaz de funcionar mesmo em caso de perda de rede durante seu uso.",
    soustitretexte6:"Destina-se a todos os compradores, vendedores, operadores económicos e indústrias dos sectores primário, secundário e terciário.",
    soustitretexte7:"Fácil de usar, você só precisa saber manusear um celular ou tablet e também ser capaz de escrever mensagens ou enviar mensagens através de dispositivos móveis para descobrir o potencial do Bazali em seu país e também vender ou comprar internacionalmente CEDEAO.",
    soustitretexte8:"Bazali é uma aplicação multilingue (francês, inglês, português) porque as línguas oficiais veiculadas nos 15 países da CEDEAO são as mencionadas acima entre parênteses. E qualquer usuário em caso de necessidade com outro usuário de idioma diferente também pode usar o tradutor integrado de seu dispositivo móvel para seus resultados de pesquisa.",
    //
    soustitre3:"Objetivos",
    soustitretexte9:"Permitir e oferecer a oportunidade ao cliente de ser realmente rei como diz o ditado e comprar de forma inteligente a um preço mais baixo.",
    soustitretexte10:"Fluidez de compras e trocas entre consumidores e operadores económicos nos países membros.",
    soustitretexte11:"Crie novas oportunidades de negócios para todos os usuários deste aplicativo.",
    soustitretexte12:"Dar aos consumidores a oportunidade de serem orientados para as melhores ofertas do mercado local e intercomunitário.",
    //translate termes
    termestitre:"Termos de uso",
    termesrevision:"Bem-vindo ao",
    termestexte1:"operado por",
    termestexte2:"Ao acessar o aplicativo Bazali ou seu site, seja por meio de um dispositivo móvel, aplicativo móvel ou computador, você concorda em ficar vinculado a estes Termos de Uso (este “Contrato”), independentemente de criar ou não uma conta Bazali. Se você deseja criar uma conta Bazali e fazer uso do Serviço, leia estes Termos de Uso.",
    termessoustitre1:"Sobre Bazali",
    termessoustitretexte1:"O Bazali é um mercado que permite aos usuários oferecer, vender e comprar praticamente qualquer coisa em uma variedade de formatos e locais de preços.",
    termessoustitretexte2:"A Bazali não possui posse de nada listado ou vendido através da Bazali e não está envolvido na transação real entre compradores e vendedores. O contrato para a venda é diretamente entre comprador e vendedor. Ela não faz parte da transação e não é uma leiloeira tradicional.",
    termessoustitretexte3:"Embora possamos fornecer preços, postagem, listagem e outras orientações em nossos Serviços, essas orientações são apenas informativas e você pode decidir segui-las ou não. Bazali não revisa as listagens ou o conteúdo dos usuários.",
    termessoustitretexte4:"A Bazali não tem controle e não garante a existência, qualidade, segurança ou legalidade dos itens anunciados; a veracidade ou precisão do conteúdo, listagens ou feedback dos usuários; a capacidade dos vendedores de vender itens; a capacidade dos compradores de pagar pelos itens; ou que um comprador ou vendedor realmente concluirá uma transação ou devolverá um item.",
    termessoustitre2:"Aceitação dos Termos de Uso",
    termessoustitretexte5:"Este Contrato é um contrato eletrônico que estabelece os termos juridicamente vinculativos que você deve aceitar para usar o Serviço. Este Contrato inclui a Política de Privacidade da Empresa, nossas Dicas de Segurança e termos divulgados e acordados por você se você comprar produtos ou serviços que oferecemos no Serviço.",
    termessoustitretexte6:"Ao acessar ou usar o Serviço, você aceita este Contrato e concorda com os termos, condições e avisos contidos ou mencionados neste documento e consente que este Contrato e todos os avisos sejam fornecidos a você em formato eletrônico. Para retirar esse consentimento, você deve deixar de usar o Serviço e encerrar sua conta. Por favor, imprima uma cópia deste Contrato para seus registros. Este Contrato pode ser modificado pela Empresa de tempos em tempos, tais modificações entrarão em vigor após a publicação pela Empresa no Serviço.",
    termessoustitre3:"Elegibilidade",
    termessoustitretexte7:"Nenhuma parte do Bazali é direcionada a menores de 18 anos. Você deve ter pelo menos 18 anos de idade para acessar e usar o Serviço. Qualquer uso do Serviço é nulo onde for proibido. Ao acessar e usar o Serviço, você declara e garante que tem o direito, autoridade e capacidade para celebrar este Contrato e cumprir todos os termos e condições deste Contrato. Se você criar uma conta, você declara e garante que nunca foi condenado por um crime e que você não é obrigado a se registrar como um criminoso sexual com qualquer entidade governamental.",
    termessoustitre4:"Criando uma conta",
    termessoustitretexte8:"Para usar o Bazali, você deve fazer login ao fazer sua pesquisa de namoro. Se você fizer isso, você nos autoriza a acessar e usar certas informações da conta, incluindo, mas não se limitando ao seu perfil público e informações sobre amigos que você pode compartilhar em comum outros usuários do Rishta. Para obter mais informações sobre as informações que coletamos de você e como as usamos, consulte nossa Política de Privacidade.",
    termessoustitre5:"Prazo e Rescisão",
    termessoustitretexte9:"Este Contrato permanecerá em pleno vigor e efeito enquanto você usar o Serviço e/ou tiver uma conta Bazali. Você pode desativar sua conta a qualquer momento, por qualquer motivo, seguindo as instruções em Configurações no Serviço. A Empresa pode encerrar ou suspender sua conta a qualquer momento, sem aviso prévio, se a Empresa acreditar que você violou este Contrato, ou por qualquer outro motivo, com ou sem justa causa, a seu exclusivo critério.",
    termessoustitretexte10:"Após tal rescisão ou suspensão, você não terá direito a nenhum reembolso de quaisquer produtos ou serviços adquiridos. A Empresa não é obrigada a divulgar, e pode ser proibida por lei de divulgar, o motivo do encerramento ou suspensão de sua conta. Depois que sua conta for encerrada por qualquer motivo, todos os termos deste contrato sobreviver a tal rescisão e continuar em pleno vigor e efeito, exceto por quaisquer termos que por sua natureza expirem ou sejam totalmente satisfeitos.",
    termessoustitre6:"Uso não-comercial",
    termessoustitretexte11:"O Serviço é apenas para uso pessoal. Os usuários não podem usar o Serviço ou qualquer conteúdo contido no Serviço (incluindo, mas não limitado a, conteúdo de outros usuários, designs, texto, gráficos, imagens, vídeo, informações, logotipos, software, arquivos de áudio e código de computador) em conexão com quaisquer empreendimentos comerciais, como publicidade ou solicitação de qualquer usuário para comprar ou vender quaisquer produtos ou serviços não oferecidos pela Empresa ou solicitar outros para participar de festas ou outros eventos sociais",
    termessoustitretexte12:"funções, ou rede, para fins comerciais. Os usuários do Serviço não podem usar nenhuma informação obtida do Serviço para entrar em contato, anunciar, solicitar ou vender a qualquer outro usuário sem seu consentimento prévio e explícito. Organizações, empresas e/ou negócios não podem usar o Serviço ou o Serviço para qualquer finalidade.",
    termessoustitretexte13:"A Empresa pode investigar e tomar qualquer ação legal disponível em resposta a usos ilegais e/ou não autorizados do Serviço, incluindo a coleta de nomes de usuário e/ou endereços de e-mail de usuários por meios eletrônicos ou outros para fins de envio não solicitado e-mail e enquadramento não autorizado ou link para o Serviço.",
    termessoustitre7:"Segurança da conta",
    termessoustitretexte14:"Você é responsável por manter a confidencialidade do nome de usuário e senha que designar durante o processo de registro e é o único responsável por todas as atividades que ocorram com seu nome de usuário e senha. Você concorda em imediatamente notificar a Empresa de qualquer divulgação ou uso não autorizado de seu nome de usuário ou senha ou qualquer outra violação de segurança e garantir que você saia da sua conta no final de cada sessão.",
    termessoustitre8:"Direitos do proprietário",
    termessoustitretexte15:"A Empresa possui e retém todos os direitos de propriedade do Serviço e de todo o conteúdo, marcas registradas, nomes comerciais, marcas de serviço e outros direitos de propriedade intelectual relacionados a ele. O serviço contém o material protegido por direitos autorais, marcas registradas e outros informações da Empresa e seus licenciadores.",
    termessoustitretexte16:"Você concorda em não copiar, modificar, transmitir, criar quaisquer trabalhos derivados, fazer uso ou reproduzir de qualquer forma qualquer material protegido por direitos autorais, marcas registradas, nomes comerciais, marcas de serviço ou outros propriedade intelectual ou informações proprietárias acessíveis através do Serviço, sem primeiro obter o consentimento prévio por escrito da Empresa ou, se tal propriedade não for de propriedade da Empresa, do proprietário de tal propriedade intelectual ou direitos de propriedade.",
    termessoustitretexte17:"Você concorda em não remover, ocultar ou alterar quaisquer avisos de propriedade que apareçam em qualquer conteúdo, incluindo direitos autorais, marcas registradas e outros avisos de propriedade intelectual.",
    termessoustitre9:"Conteúdo postado por você",
    termessoustitretexte18:"Você é o único responsável pelo conteúdo e informações que você postar, carregar, publicar, vincular, transmitir, gravar, exibir ou disponibilizar (doravante, postar) no Serviço ou transmitir a outros usuários, incluindo mensagens de texto, bate-papo ou texto do perfil. Você não pode postar como parte do Serviço, ou transmitir à Empresa ou a qualquer outro usuário (dentro ou fora do Serviço), qualquer conteúdo ofensivo, impreciso, incompleto, abusivo, obsceno, profano, ameaçador, intimidador, material de assédio, racialmente ofensivo ou ilegal, ou qualquer material que infrinja ou viole os direitos de outra pessoa (incluindo direitos de propriedade intelectual e direitos de privacidade e publicidade). Você declara e garante que todas as informações enviadas na criação de sua conta, incluindo as informações enviadas, são precisas e verdadeiro.",
    termessoustitretexte19:"Você entende e concorda que a Empresa pode, mas não é obrigada a, monitorar ou revisar qualquer Conteúdo que você publicar como parte de um Serviço. A Empresa pode excluir qualquer Conteúdo, no todo ou em parte, que, a critério exclusivo da Empresa, viole este Contrato ou possa prejudicar a reputação do Serviço ou da Empresa.",
    termessoustitretexte20:"Ao publicar Conteúdo como parte do Serviço, você automaticamente concede à Empresa, suas afiliadas, licenciados e sucessores, um direito e licença mundial irrevogável, perpétuo, não exclusivo, transferível, sublicenciável, totalmente integralizado e mundial para uso, copiar, armazenar, executar, exibir, reproduzir, gravar, reproduzir, adaptar, modificar e distribuir o Conteúdo, preparar trabalhos derivados do Conteúdo ou incorporar o Conteúdo em outras obras, e conceder e autorizar sublicenças do precedente em qualquer mídia agora conhecida ou criada no futuro.Você declara e garante que qualquer postagem e uso de seu Conteúdo pela Empresa não infringirá ou violará os direitos de terceiros.",
    termessoustitretexte21:"Além dos tipos de Conteúdo descritos na Seção acima, a seguir está uma lista parcial do tipo de Conteúdo que é proibido no Serviço. Você não pode postar, fazer upload, exibir ou disponibilizar Conteúdo que:",
    termessoustitretexte22:"que promova racismo, intolerância, ódio ou dano físico de qualquer tipo contra qualquer grupo ou indivíduo;",
    termessoustitretexte23:"defende o assédio ou intimidação de outra pessoa;",
    termessoustitretexte24:"solicita dinheiro de, ou tem a intenção de fraudar, outros usuários do Serviço;",
    termessoustitretexte25:"promova informações falsas ou enganosas, ou promova atividades ilegais ou conduta que seja difamatória, caluniosa ou censurável;",
    termessoustitretexte26:"promova uma cópia ilegal ou não autorizada do trabalho protegido por direitos autorais de outra pessoa, como fornecer programas de computador piratas ou links para eles, fornecendo informações para contornar dispositivos de proteção contra cópia instalados pelo fabricante;",
    termessoustitretexte27:"contém páginas de acesso restrito ou somente por senha, ou páginas ou imagens ocultas (aquelas não vinculadas a ou de outra página acessível);",
    termessoustitretexte28:"fornece material que explora pessoas de forma sexual, violenta ou ilegal, ou solicita informações pessoais de menores de 18 anos;",
    termessoustitretexte29:"fornece informações instrutivas sobre atividades ilegais, como fabricar ou comprar armas ou drogas ilegais, violar a privacidade de alguém ou fornecer, disseminar ou criar vírus de computador;",
    termessoustitretexte30:"contém vírus, bombas-relógio, cavalos de tróia, cancelbots, worms ou outros códigos, componentes ou dispositivos prejudiciais ou disruptivos;",
    termessoustitretexte31:"personifica, ou de outra forma deturpa a afiliação, conexão ou associação com qualquer pessoa ou entidade;",
    termessoustitretexte32:"fornece informações ou dados que você não tem o direito de disponibilizar de acordo com a lei ou sob relações contratuais ou fiduciárias (como informações privilegiadas, informações proprietárias e confidenciais em formação);",
    termessoustitretexte33:"interrompe o fluxo normal de diálogo, faz com que uma tela “role” mais rápido do que outros usuários são capazes de digitar ou de outra forma negativamente afeta a capacidade de outros usuários de se envolver em trocas em tempo real;",
    termessoustitretexte34:"solicita senhas ou informações de identificação pessoal para fins comerciais ou ilegais de outros usuários ou divulga informações pessoais de outra pessoa sem sua permissão; e",
    termessoustitretexte35:"divulga ou promove atividades comerciais e/ou vendas sem nosso consentimento prévio por escrito, como concursos, sorteios, trocas, publicidade e esquemas de pirâmide.",
    termessoustitretexte36:"A Empresa reserva-se o direito, a seu exclusivo critério, de investigar e tomar qualquer ação legal contra qualquer pessoa que viole esta disposição, incluindo remover a comunicação ofensiva do Serviço e encerrar ou suspender a conta de tais infratores.",
    termessoustitretexte37:"Seu uso do Serviço, incluindo todo o Conteúdo que você publica por meio do Serviço, deve cumprir todas as leis e regulamentos aplicáveis. Você concorda que a Empresa pode acessar, preservar e divulgar as informações de sua conta e Conteúdo se exigido por lei ou em boa fé que tal acesso, preservação ou divulgação é razoavelmente necessário, tais como:",
    termessoustitretexte38:"cumprir o processo legal; fazer cumprir este Contrato; responder a reclamações de que qualquer Conteúdo viola os direitos de terceiros; responder às suas solicitações de atendimento ao cliente ou permitir que você use o Serviço no futuro; ou proteger os direitos, propriedade ou segurança pessoal da Empresa ou de qualquer outra pessoa.",
    termessoustitretexte39:"Você concorda que qualquer Conteúdo que você colocar no Serviço pode ser visto por outros usuários e pode ser visto por qualquer pessoa que visite ou participe do Serviço.",
    termessoustitretexte40:"Atividades proibidas",
    termessoustitretexte41:"A Empresa reserva-se o direito de investigar, suspender e/ou encerrar sua conta se você tiver feito uso indevido do Serviço ou se comportado de uma maneira que a Empresa considere inadequada ou ilegal, incluindo ações ou comunicações que ocorram fora do Serviço, mas envolvam usuários que você conhece por meio do Serviço. A seguir está uma lista parcial do tipo de ações que você não pode realizar em relação ao Serviço. Você não vai:",
    termessoustitretexte42:"personificar qualquer pessoa ou entidade.",
    termessoustitretexte43:"solicitar dinheiro de qualquer usuário.",
    termessoustitretexte44:"publicar qualquer Conteúdo que seja proibido pela Seção.",
    termessoustitretexte45:"perseguir ou assediar qualquer pessoa.",
    termessoustitretexte46:"expressar ou sugerir que quaisquer declarações que você fizer são endossadas pela Empresa sem nosso consentimento prévio específico por escrito.",
    termessoustitretexte47:"usar o Serviço de maneira ilegal ou cometer um ato ilegal;",
    termessoustitretexte48:"pedir ou usar os usuários para ocultar a identidade, origem ou destino de qualquer dinheiro ou produtos obtidos ilegalmente.",
    termessoustitretexte49:"usar qualquer robô, spider, aplicativo de pesquisa/recuperação de sites ou outro dispositivo ou processo manual ou automático para recuperar, indexar, extrair dados ou de qualquer forma reproduzir ou burlar a estrutura de navegação ou apresentação do Serviço ou seu conteúdo.",
    termessoustitretexte50:"coletar nomes de usuário e/ou endereços de e-mail de usuários por meios eletrônicos ou outros com a finalidade de enviar e-mail não solicitado ou enquadramento não autorizado ou link para o Serviço.",
    termessoustitretexte51:"interferir ou interromper o Serviço ou os servidores ou redes conectadas ao Serviço.",
    termessoustitretexte52:"enviar e-mail ou transmitir qualquer material que contenha vírus de software ou qualquer outro código de computador, arquivos ou programas projetados para interromper, destruir ou limitar a funcionalidade de qualquer software ou hardware de computador ou equipamento de telecomunicações.",
    termessoustitretexte53:"forjar cabeçalhos ou manipular identificadores para disfarçar a origem de qualquer informação transmitida para ou através do Serviço (direta ou indiretamente através do uso de terceiros Programas).",
    termessoustitretexte54:"enquadrar ou espelhar qualquer parte do Serviço, sem autorização prévia por escrito da Empresa.",
    termessoustitretexte55:"usar meta tags ou código ou outros dispositivos que contenham qualquer referência à Empresa ou ao Serviço (ou qualquer marca comercial, nome comercial, marca de serviço, logotipo ou slogan da Empresa) para direcionar qualquer pessoa a qualquer outro site para qualquer finalidade.",
    termessoustitretexte56:"modificar, adaptar, sublicenciar, traduzir, vender, fazer engenharia reversa, decifrar, descompilar ou desmontar qualquer parte do Serviço, qualquer software usado no Serviço ou para o Serviço, ou fazer com que outros o façam.",
    termessoustitretexte57:"publicar, usar, transmitir ou distribuir, direta ou indiretamente (por exemplo, screen scrape) de qualquer maneira ou mídia, qualquer conteúdo ou informação obtida do Serviço que não seja exclusivamente em conexão com o uso do Serviço de acordo com este Contrato.",
    termessoustitretexte58:"Atendimento ao Cliente",
    termessoustitretexte59:"através de seus representantes de atendimento ao cliente. Ao se comunicar com nossos representantes de atendimento ao cliente, você concorda em não ser abusivo, obsceno, profano, ofensivo, sexista, ameaçador, assediar, racialmente ofensivo ou não se comportar de forma inadequada. Se sentirmos que seu comportamento em relação a qualquer um de nossos representantes de atendimento ao cliente ou outros funcionários é ameaçador ou ofensivo a qualquer momento, nos reservamos o direito de encerrar sua conta imediatamente.",
    termessoustitretexte60:"Modificações no serviço",
    termessoustitretexte61:"A Empresa reserva-se o direito de, a qualquer momento, modificar ou descontinuar, temporária ou permanentemente, o Serviço (ou qualquer parte dele) com ou sem aviso prévio. Você concorda que a Empresa não será responsável perante você ou terceiros por qualquer modificação, suspensão ou descontinuação do Serviço. Para proteger a integridade do Serviço, a Empresa reserva-se o direito, a qualquer momento, a seu exclusivo critério, de bloquear usuários de determinados endereços IP de acessar o Serviço.",
    termessoustitretexte62:"Política de direitos autorais; Notificação e procedimento para fazer reclamações de violação de direitos autorais",
    termessoustitretexte63:"Você não pode postar, distribuir ou reproduzir de qualquer forma qualquer material protegido por direitos autorais, marcas registradas ou outras informações proprietárias sem obter o consentimento prévio por escrito do proprietário de tais direitos de propriedade. Sem limitar o precedente, se você acredita que seu trabalho foi copiado e publicado no Serviço de uma forma que constitua violação de direitos autorais, forneça nosso Agente com as seguintes informações:",
    termessoustitretexte64:"uma assinatura eletrônica ou física da pessoa autorizada a agir em nome do proprietário do direito autoral;",
    termessoustitretexte65:"uma descrição do trabalho protegido por direitos autorais que você alega ter sido violado;",
    termessoustitretexte66:"uma descrição de onde o material que você alega estar infringindo está localizado no Serviço (e essa descrição deve ser razoavelmente suficiente para permitir que a Empresa encontre o material supostamente infrator, como um URL);",
    termessoustitretexte67:"seu endereço, número de telefone, e endereço de e-mail;",
    termessoustitretexte68:"uma declaração por escrito sua de que você acredita de boa fé que o uso contestado não está autorizado pelo proprietário dos direitos autorais, seu agente ou pela lei; e",
    termessoustitretexte69:"uma declaração sua, feita sob pena de perjúrio, de que as informações acima em seu aviso são precisas e que você é o proprietário dos direitos autorais ou está autorizado a agir em nome do proprietário dos direitos autorais.",
    termessoustitretexte70:"A Empresa encerrará as contas de infratores reincidentes.",
    termessoustitretexte71:"Você reconhece e concorda que nem a Empresa nem suas afiliadas e parceiros terceirizados são responsáveis e não terão qualquer responsabilidade, direta ou indiretamente, por qualquer perda ou dano, incluindo ferimentos pessoais ou morte, como resultado ou alegadamente resultado de qualquer Conteúdo incorreto ou impreciso postado no Serviço, seja causado por usuários ou qualquer equipamento ou programação associada ou utilizada no Serviço;",
    termessoustitretexte72:"a pontualidade, exclusão ou remoção, entrega incorreta ou falha no armazenamento de qualquer Conteúdo, comunicação ou configurações de personalização; a conduta, seja online ou offline, de qualquer usuário; qualquer erro, omissão ou defeito, interrupção, exclusão, alteração, atraso na operação ou transmissão, roubo ou destruição ou acesso não autorizado a qualquer usuário ou comunicações do usuário;",
    termessoustitretexte73:"ou quaisquer problemas, falhas ou avarias técnicas de qualquer rede ou linhas telefónicas, sistemas informáticos online, servidores ou fornecedores, equipamento informático, software, falha de e-mail ou jogadores e devido a problemas técnicos ou congestionamento de tráfego na Internet ou em qualquer site ou combinação deles, incluindo lesões ou danos aos usuários ou ao computador ou dispositivo de qualquer outra pessoa relacionados ou resultantes da participação ou download de materiais em conexão com a Internet e/ou em conexão com o Serviço.",
    termessoustitretexte74:"NA EXTENSÃO MÁXIMA PERMITIDA PELA LEI APLICÁVEL, A EMPRESA FORNECE O SERVIÇO “COMO ESTÁ” E “CONFORME DISPONÍVEL” E NÃO CONCEDE GARANTIAS DE QUALQUER TIPO, SEJAM EXPRESSAS, IMPLÍCITAS, ESTATUTÁRIAS OU DE OUTRA FORMA COM RELAÇÃO AO SERVIÇO (INCLUINDO TODOS OS CONTEÚDO NELE CONTIDO), INCLUINDO (SEM LIMITAÇÃO) QUALQUER GARANTIAS DE QUALIDADE SATISFATÓRIA, COMERCIALIZAÇÃO, ADEQUAÇÃO A UM DETERMINADO FIM OU NÃO VIOLAÇÃO. A EMPRESA NÃO DECLARA NEM GARANTE QUE O SERVIÇO SERÁ ININTERRUPTO OU LIVRE DE ERROS, SEGURO OU QUE QUAISQUER DEFEITOS OU ERROS NO SERVIÇO SERÃO CORRIGIDOS.",
    termessoustitretexte75:"QUALQUER MATERIAL BAIXADO OU OBTIDO DE OUTRA FORMA ATRAVÉS DO USO DO SERVIÇO É ACESSADO POR SUA PRÓPRIA CONTA E RISCO, E VOCÊ SERÁ O ÚNICO RESPONSÁVEL POR E RENUNCIA A TODAS E QUAISQUER REIVINDICAÇÕES E CAUSAS DE AÇÃO COM RELAÇÃO A QUALQUER DANO A SEU DISPOSITIVO, SISTEMA DE COMPUTADOR , ACESSO À INTERNET, DOWNLOAD OU DISPOSITIVO DE EXIBIÇÃO, OU PERDA OU CORRUPÇÃO DE DADOS QUE RESULTEM OU PODEM RESULTAR DO DOWNLOAD DE QUALQUER MATERIAL. SE VOCÊ NÃO ACEITAR ESTA LIMITAÇÃO DE RESPONSABILIDADE, VOCÊ NÃO ESTÁ AUTORIZADO A BAIXAR OU OBTER QUALQUER MATERIAL ATRAVÉS DO SERVIÇO.",
    termessoustitretexte76:"De tempos em tempos, a Empresa pode disponibilizar opiniões, conselhos, declarações, ofertas ou outras informações ou conteúdos de terceiros por meio do Serviço. Todo o conteúdo de terceiros é de responsabilidade dos respectivos autores e não deve ser necessariamente confiável. Esses autores terceiros são os únicos responsáveis por tal conteúdo.",
    termessoustitretexte77:"A EMPRESA NÃO: GARANTE A PRECISÃO, INTEGRIDADE OU UTILIDADE DE QUALQUER CONTEÚDO DE TERCEIROS FORNECIDO ATRAVÉS DO SERVIÇO, NEM ADOTA, ENDOSSAMOS OU ACEITAMOS A RESPONSABILIDADE PELA PRECISÃO OU CONFIABILIDADE DE QUALQUER OPINIÃO, CONSELHO OU DECLARAÇÃO FEITA POR QUALQUER PARTE QUE APAREÇA NO SERVIÇO. SOB NÃO CIRCUNSTÂNCIAS A EMPRESA OU SUAS AFILIADAS SERÃO RESPONSÁVEIS POR QUALQUER PERDA OU DANO DECORRENTE DE SUA CONFIANÇA EM INFORMAÇÕES OU OUTROS CONTEÚDOS POSTADOS NO SERVIÇO, OU TRANSMITIDOS PARA OU POR QUAISQUER USUÁRIOS.",
    termessoustitretexte78:"Além do parágrafo anterior e de outras disposições deste Contrato, qualquer conselho que possa ser publicado no Serviço é apenas para fins informativos e de entretenimento e não se destina a substituir ou substituir qualquer conselho profissional financeiro, médico, jurídico ou outro. A Empresa não faz representações ou garante e se isenta expressamente de toda e qualquer responsabilidade relativa a qualquer tratamento, ação ou efeito sobre qualquer pessoa seguindo informações oferecidas ou fornecidas dentro ou através do Serviço. Se tiver preocupações específicas ou surgir uma situação em que necessite de aconselhamento profissional ou médico, deve consultar um especialista devidamente treinado e qualificado.",
    termessoustitretexte79:"Links",
    termessoustitretexte80:"O Serviço pode conter, e o Serviço ou terceiros podem fornecer, anúncios e promoções oferecidos por terceiros e links para outros sites ou recursos. Você reconhece e concorda que a Empresa não é responsável pela disponibilidade de tais sites ou recursos externos e não endossa e não é responsável por qualquer conteúdo, informação, declarações, publicidade, bens ou serviços ou outros materiais disponíveis ou desses sites ou recursos. Sua correspondência ou negócios com terceiros, ou participação em promoções de terceiros encontrados no Serviço ou por meio dele, incluindo pagamento e entrega de bens ou serviços relacionados, e quaisquer outros termos, condições, garantias ou representações associadas a tais negócios, são exclusivamente entre você e tal terceiro. Você também reconhece e concorda que a Empresa não será responsável, direta ou indiretamente, por qualquer dano ou perda causada ou alegadamente causada por ou em conexão com o uso ou confiança em qualquer conteúdo, informação, declarações , publicidade, bens ou serviços ou outros materiais disponíveis em ou através de qualquer site ou recurso.",
    termessoustitretexte81:"Limitação de Responsabilidade. NA MÁXIMA EXTENSÃO PERMITIDA PELA LEI APLICÁVEL, EM NENHUMA HIPÓTESE A EMPRESA, SUAS AFILIADAS, PARCEIROS DE NEGÓCIOS, LICENCIADORES OU SERVIÇO OS FORNECEDORES SERÃO RESPONSÁVEIS PERANTE VOCÊ OU QUALQUER TERCEIRO PESSOA POR QUAISQUER DANOS INDIRETOS, DE CONFIANÇA, CONSEQUENCIAIS, EXEMPLARES, INCIDENTAIS, ESPECIAIS OU PUNITIVOS, INCLUINDO, SEM LIMITAÇÃO, LUCROS CESSANTES, PERDA DE GOODWILL, DANOS POR PERDA, CORRUPÇÃO OU VIOLAÇÃO DE DADOS OU PROGRAMAS , INTERRUPÇÕES DE SERVIÇO E AQUISIÇÃO DE SUBSTITUTO SERVIÇOS, MESMO QUE A EMPRESA TENHA SIDO AVISADA DA POSSIBILIDADE DE TAIS DANOS. NÃO OBSTANTE QUALQUER DISPOSIÇÃO EM CONTRÁRIO AQUI CONTIDOS, A COMPANHIA A RESPONSABILIDADE PARA COM VOCÊ POR QUALQUER CAUSA, E INDEPENDENTEMENTE DA FORMA DA AÇÃO, SERÁ SEMPRE LIMITADA AO VALOR PAGO, SE HOUVER, POR VOCÊ AO EMPRESA PARA O SERVIÇO ENQUANTO VOCÊ TEM UMA CONTA. VOCÊ CONCORDA QUE, INDEPENDENTEMENTE DE QUALQUER ESTATUTO OU LEI DO AO CONTRÁRIO, QUALQUER REIVINDICAÇÃO OU CAUSA DE AÇÃO DECORRENTE OU RELACIONADA AO USO DO SERVIÇO OU AOS TERMOS DESTE ACORDO DEVEM SER ARQUIVADOS DENTRO DE UM ANO APÓS A OCORRÊNCIA DE TAL RECLAMAÇÃO OU CAUSA DE AÇÃO OU SER PARA SEMPRE PREJUDICADA.",
    termessoustitretexte82:"O meio exclusivo de resolver qualquer disputa ou reclamação decorrente ou relacionada a este Contrato (incluindo qualquer suposta violação do mesmo) ou ao Serviço será a ARBITRAGEM OBRIGATÓRIA administrada pela American Arbitration Association. A única exceção à exclusividade da arbitragem é que você tem o direito de trazer um reclamação individual contra a Empresa em um tribunal de pequenas causas de jurisdição competente. Mas, quer você escolha a arbitragem ou o tribunal de pequenas causas, você não pode, sob nenhuma circunstância, iniciar ou manter contra a Empresa qualquer ação coletiva, arbitragem coletiva ou outra ação ou processo representativo.",
    termessoustitretexte83:"Ao usar o Serviço de qualquer maneira, você concorda com o acordo de arbitragem acima. Ao fazê-lo, VOCÊ ABANDONE SEU DIREITO DE IR AO TRIBUNAL para reivindicar ou defender quaisquer reivindicações entre você e a Empresa (exceto para questões que possam ser levadas ao tribunal de pequenas causas). VOCÊ TAMBÉM ABANDONE SEU DIREITO DE PARTICIPAR DE UM AÇÃO DE CLASSE OU OUTRO PROCESSO DE CLASSE. Seus direitos serão determinados por um ÁRBITRO NEUTRO, NÃO UM JUIZ OU JÚRI. Você tem direito a uma audiência justa perante o árbitro. O árbitro pode conceder qualquer alívio que um tribunal possa, mas você deve observar que os procedimentos de arbitragem geralmente são mais simples e mais simplificados do que julgamentos e outros procedimentos judiciais. As decisões do árbitro são executáveis em tribunal e podem ser anuladas por um tribunal apenas por razões muito limitadas. Para obter detalhes sobre o processo de arbitragem, consulte nossos Procedimentos de Arbitragem.",
    termessoustitretexte84:"Qualquer processo para fazer cumprir este acordo de arbitragem, incluindo qualquer processo para confirmar, modificar ou anular uma sentença de arbitragem, pode ser iniciado em qualquer tribunal de jurisdição competente.",
    termessoustitretexte85:"Perceber",
    termessoustitretexte86:"A Empresa pode fornecer avisos, incluindo aqueles em relação a alterações neste Contrato, usando qualquer meio razoável agora conhecido ou desenvolvido no futuro, inclusive por e-mail, correio normal, SMS, MMS, mensagem de texto ou postagens no Serviço. Esses avisos podem não ser recebidos se você violar este Contrato acessando o Serviço de maneira não autorizada. Você concorda que se considera que recebeu todos e quaisquer avisos que teriam sido entregues se você tivesse acessado o Serviço de maneira autorizada.",
    termessoustitretexte87:"Total acordo; Outro",
    termessoustitretexte88:"Este Contrato, com a Política de Privacidade e quaisquer diretrizes ou regras específicas publicadas separadamente para determinados serviços ou ofertas no Serviço, contém todo o contrato entre você e a Empresa em relação ao uso do Serviço. Se qualquer disposição deste Contrato for considerada inválida, o restante deste Contrato continuará em pleno vigor e efeito. A falha da Empresa em exercer ou fazer cumprir qualquer direito ou disposição deste Contrato não constituirá uma renúncia de tal direito ou disposição. Você concorda que sua conta online é intransferível e todos os seus direitos sobre seu perfil ou conteúdo dentro de sua conta terminam após sua morte. Nenhuma agência, parceria, joint venture ou emprego é criado como resultado deste Contrato e você não pode fazer nenhuma representação ou vincular a Empresa de nenhuma maneira.",
    termessoustitretexte89:"Alteração",
    termessoustitretexte90:"Este Contrato está sujeito a alterações pela Empresa a qualquer momento.",

    //feedback
    feddbacktitle:"Comentários",
    feedbackdear:"Caros utilizadores",
    feedbacktexte1:"Bazali convida você a apoiá-lo fornecendo sugestões e propostas que podem nos permitir melhorar sua experiência de usuário e desenvolver este aplicativo.",
    feedbacktexte2:"Sua avaliação",
    feedbacktexte3:"Selecione sua classificação",
    feedbacktexte4:"Por que essa classificação",
    feedbacktexte5:"Enviar",
    feedbacktexte6:"Página anterior",
    feedbacktexte7:"Página seguinte",

    //principal
    principaltitle:"Selecione sua lingua",
    principaltexte1:"Selecione seu idioma sempre que iniciar o bazali.",
    principaltexte2:"Selecione seu país",
    select_country_texte1:"Selecione seu país com base no idioma selecionado e clique em Próxima página.",
    principalbutton:"Confirme",

    //starteruser
    demarrersubtitle:"Começar",
    demarrertexte1:"Selecione sua ação para começar",
    demarrertexte2:"Comprador",
    demarrertexte3:"Vendedor",

    //buyer principal
    buyertexte1:"É obrigatório ter uma conta de comprador antes de continuar. Prossiga se você tiver um, caso contrário, crie um e use seus dados para continuar.",
    buyertexte2:"Acesso Comprador",
    buyertexte3:"Conto Comprador",
    //crud buyer account
    buyertexte4:"Gestão de conta Comprador",
    buyertexte5:"Crie, visualize, modifique e exclua sua conta de comprador gratuitamente",
    buyertexte6:"Criar Conta",
    buyertexte7:"Visualizar Conta",
    buyertexte8:"Editar Conta",
    buyertexte9:"Deletar Conta",
    //add/create buyer account
    buyertexte10:"Criar Conta Gratuita",
    buyertexte11:"Todos os campos com asterisco (*) são obrigatórios.",
    buyertexte12:"nome",
    buyertexte13:"Digite seu nome",
    buyertexte14:"Primeiros nomes",
    buyertexte15:"Digite seus primeiros nomes",
    buyertexte16:"Número de celular",
    buyertexte17:"Sem prefixo internacional",
    buyertexte18:"Digite seu e-mail",
    buyertexte19:"Digite sua senha",
    buyertexte20:"Confirmar senha",
    buyertexte21:"Confirme sua senha",
    buyertexte22:"Criar",
    //edit buyer account
    buyertexte23:"Editar conta",
    buyertexte24:"Editar",
    //view buyer account
    buyertexte25:"Visualizar Conta",
    buyertexte26:"Consultar",
    //delete buyer account
    buyertexte27:"Deletar conta",
    buyertexte28:"Deletar",
    //connection buyer
    buyertexte29:"Login do comprador",
    buyertexte30:"Login",
    //Acheteur
    buyertexte31:"Ou comprar?",
    buyertexte32:"Você tem a opção de comprar nacional ou internacional (países da CEDEAO). Faça a sua escolha.",
    buyertexte33:"Compre nacional",
    buyertexte34:"Compre internacional",
    //acheter national
    buyertexte35:"O que comprar?",
    buyertexte36:"Você tem as opções de comprar no seu país de residência ou até mesmo conhecer várias tendências e ajudar em como fazer suas pesquisas.",
    buyertexte37:"Compra todas categorias",
    buyertexte38:"Comprar local",
    buyertexte39:"Compre outras cidades",
    buyertexte40:"tendências populares",
    buyertexte41:"Novas tendências",
    buyertexte42:"E aí ?",
    buyertexte43:"Preciso de ajuda?",
    //acheter international
    buyertexte44:"Você tem a opção de comprar em qualquer país da CEDEAO mesmo, para conhecer várias tendências e ajudar em como realizar sua pesquisa.",
    buyertexte45:"Explorar",
    //vendeur national et international
    sellertexte1:"É obrigatório ter uma conta de vendedor antes de continuar. A conta de vendedor internacional, por outro lado, permite que você venda em outros países da CEDEAO, prossiga se tiver uma, caso contrário, crie uma e use seus dados para continuar.",
    sellertexte2:"Acesso vendedor",
    sellertexte3:"Conta vendedor",
    sellertexte4:"Crie, visualize, modifique e exclua sua conta de vendedor gratuitamente.",
    sellertexte5:"Gestão de conta Vandedor",
    sellertexte6:"Selecione o tipo de conta",
    sellertexte7:"Conta Nacional",
    sellertexte8:"Conta CEDEAO",
    sellertexte9:"Validar",
    sellertexte10:"Login do vendedor",
    sellertexte11:"Login",
    //vendeur
    sellertexte12:"Painel",
    sellertexte13:"Todas as suas atividades como vendedor podem ser encontradas aqui. Clique para interagir.",
    sellertexte14:"Meus créditos unitários",
    sellertexte15:"Minha vitrine",
    sellertexte16:"Minhas mensagens",
    sellertexte17:"País",
    sellertexte18:"Confirmar",
    sellertexte19:"Tem certeza de que deseja excluir esta conta?",
    sellertexte20:"Tipo de conta",
    //contactme
    your_name:"Digite seu nome",
    //contactme
    contactme: "Contate-nos",
    contactintro: "Você tem perguntas, comentários ou sugestões? nós adoraríamos ouvir de você! Sinta-se à vontade para entrar em contato com o atendimento ao cliente da Bazali usando qualquer um dos seguintes métodos:",
    contact_1: "Chamada direta: você pode ligar diretamente para o número de telefone de atendimento ao cliente da Bazali.",
    contact_2: "Enviar e-mail: Se preferir se comunicar por e-mail, envie uma mensagem.",
    contact_3: "Chamada do WhatsApp: se você usa o WhatsApp, também pode ligar ou conversar neste número do WhatsApp.",
    contactconclued_1: "Estamos sempre felizes em ouvir novas pessoas e faremos o possível para responder o mais rápido possível.",
    contactconclued_2: "Ansioso para ouvir de você em breve!",
    //
    buyer:"Comprador",
    seller:"Vendedor",
    languageInitials:"pt",
    //privacy
    privacytitre:"Política de Privacidade",
    privacytexte2:"Na Bazali, estamos comprometidos em proteger a privacidade e a segurança de nossos usuários. Esta Política de Privacidade explica como coletamos, usamos e protegemos suas informações pessoais quando você usa nossa plataforma. Ao usar o Bazali, você concorda com as práticas descritas nesta política.",
    privacysoustitre1:"Informações que coletamos:",
    privacysoustitretexte1:"Informações pessoais: quando você cria uma conta no Bazali, podemos coletar certas informações pessoais, como seu nome, endereço de e-mail e detalhes de contato. Essas informações são necessárias para facilitar a comunicação entre compradores e vendedores.",
    privacysoustitretexte2:"Informações de transação: quando você faz uma compra ou venda no Bazali, coletamos detalhes da transação, incluindo os produtos envolvidos e informações de pagamento. No entanto, não armazenamos nenhuma informação de cartão de pagamento. Todas as transações de pagamento são processadas com segurança por nossos parceiros confiáveis de gateway de pagamento.",
    privacysoustitre2:"Como usamos suas informações:",
    privacysoustitretexte3:"Comunicação: podemos usar suas informações pessoais para nos comunicarmos com você sobre sua conta, pedidos e consultas.",
    privacysoustitretexte4:"Melhorando a experiência do usuário: analisamos o comportamento e as preferências do usuário para aprimorar a experiência do usuário no Bazali. Isso inclui personalizar recomendações de produtos e exibir conteúdo relevante.",
    privacysoustitretexte5:"Prevenção de Fraude: Podemos usar as informações coletadas para detectar e prevenir atividades fraudulentas ou acesso não autorizado à nossa plataforma.",
    privacysoustitre3:"Compartilhamento de informações:",
    privacysoustitretexte6:"Comunicação vendedor-comprador: quando você realiza transações no Bazali, certas informações pessoais podem ser compartilhadas com o vendedor ou comprador relevante para facilitar a transação.",
    privacysoustitretexte7:"Provedores de serviços terceirizados: podemos compartilhar suas informações com provedores de serviços terceirizados confiáveis que nos ajudam a operar nossa plataforma, processar pagamentos e fornecer suporte ao cliente.",
    privacysoustitre4:"Segurança de Dados:",
    privacysoustitretexte8:"Empregamos medidas de segurança padrão do setor para proteger suas informações pessoais contra acesso, divulgação ou alteração não autorizados. No entanto, observe que nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro.",
    privacysoustitre5:"Suas escolhas e direitos:",
    privacysoustitretexte9:"Configurações da conta: você pode revisar e atualizar as informações da sua conta na página de configurações da conta no Bazali.",
    privacysoustitretexte10:"Comunicações de marketing: você tem a opção de optar por não receber comunicações de marketing da Bazali.",
    privacysoustitre6:"Retenção de dados:",
    privacysoustitretexte11:"Retemos suas informações pessoais pelo tempo necessário para cumprir os propósitos descritos nesta Política de Privacidade, a menos que um período de retenção mais longo seja exigido ou permitido por lei.",
    //startingbuyer
    buyer_section_1:"Bem-vindo Comprador!",
    buyer_section_2:"Por favor, escolha uma das seguintes opções:",
    buyersoustitre1:"Não registrado:",
    buyersoustitretexte1:"Caso não seja um utilizador registado, pode explorar a nossa plataforma e navegar pelos produtos e serviços disponíveis. No entanto, observe que alguns recursos podem ser limitados para não registrados.",
    buyersoustitre2:"Registrado:",
    buyersoustitretexte2:"Se você já se inscreveu, clique neste botão para acessar todos os recursos e benefícios premium disponíveis exclusivamente para nossos usuários registrados. Desfrute de uma experiência perfeita com funcionalidade aprimorada.",
    buyersoustitre3:"Inscreva-se:",
    buyersoustitretexte3:"Se você é novo aqui, clique neste botão para criar uma nova conta gratuitamente. Ao criar uma conta, você pode desbloquear recursos adicionais, personalizar sua experiência e gerenciar facilmente suas preferências.",
    buyersoustitretexte4:"Sinta-se à vontade para clicar em qualquer um dos botões acima para continuar. Se você quiser voltar para a página anterior, clique no botão <<Página Anterior>>.",
    noSubscriberUser:"Não registrado",
    subscriberUser:"Registrado",
    createNewAccount:"Inscrever-se",
    //startingseller
    seller_section_1:"Bem-vindo Vendedor!",
    seller_section_2:"Por favor, escolha uma das seguintes opções:",
    sellersoustitre1:"Faça login:",
    sellersoustitretexte1:"Se você já possui uma conta, clique neste botão para fazer login e acessar o painel do vendedor. Você pode gerenciar seus produtos, acompanhar as vendas e interagir com os compradores.",
    sellersoustitre2:"Inscreva-se:",
    sellersoustitretexte2:"Se você é novo em nossa plataforma, clique neste botão para criar uma conta de vendedor. Ao se inscrever, você pode começar a vender seus produtos ou serviços para nossa ampla base de compradores. Ganhe visibilidade, alcance compradores em potencial e expanda seus negócios.",
    sellersoustitre3:"Subscrição:",
    sellersoustitretexte3:"Clique neste botão para assinar nosso plano de vendedor básico, padrão e premium. Como assinante, você desbloqueará recursos e benefícios adicionais projetados para aumentar suas vendas e simplificar sua experiência de venda.",
    sellersoustitretexte4:"Sinta-se à vontade para clicar em qualquer um dos botões acima para continuar. Se você quiser voltar para a página anterior, clique no botão <<Página Anterior>>.",
    singinUser:"Faça login",
    signUpUser:"Inscreva-se",
    subscribe:"Subscrição",
    search_placeholder:"Escreva apenas o nome do produto",
    filter_city:"Cidade",
    filter_neighborhood:"Vizinhança",
    filter_best_prices:"Preço",
    play_ok:"OK",
    buyer_search_texte1:"Por favor, digite o nome do produto que você está procurando...",
    buyer_search_texte2:"Você pode refinar ainda mais sua pesquisa aplicando filtros, se necessário.",
    buyer_search_texte3:"Clique em <<🔍>> para exibir a lista de produtos que você está procurando.",
    product_name:"Nome do produto",
    searchcity_placeholder:"Digite o nome da cidade",
    searchneighborhood_placeholder:"Digite o nome do bairro",
    searchprice_placeholder:"Insira seu melhor preço preferido",
    confirm_button:"Confirme",
    cancel_button:"Cancelar",
    //authentication
    user_section_1:"Bem-vindo, compradores registrados! 😊",
    user_phone_texte:"Digite seu número de celular (sem o prefixo internacional).",
    user_password_texte:"Por favor, insira sua senha.",
    user_section_2:"Depois de inserir todos os campos obrigatórios, clique no botão <<Faça login>>.",
    //
    signup_section_1:"Aqui está um pequeno guia sobre como você pode se inscrever para uma nova conta no formulário de inscrição Bazali:",
    signupsoustitre1:"Bem-vindo ao Bazali Inscreva-se!",
    signupsoustitretexte1:"Obrigado por escolher a Bazali! Estamos entusiasmados por você se juntar à nossa plataforma. Siga as etapas abaixo para criar e gerenciar sua nova conta de comprador gratuita.",
    signupsoustitre2:"Informações Pessoais:",
    signupsoustitretexte2:"Digite seu nome e sobrenome nos campos fornecidos.",
    signupsoustitretexte3:"Digite seu número válido de celular whatsapp sem o prefixo internacional. Usaremos esse número para autenticá-lo e enviar notificações e atualizações importantes.",
    signupsoustitretexte4:"Forneça sua cidade. Essas informações nos ajudarão a adaptar nossos serviços à sua localização.",
    signupsoustitretexte5:"Escolha uma senha forte para proteger sua conta. Certifique-se de que ele atenda aos requisitos especificados de comprimento e complexidade que sejam capazes e fáceis de lembrar.",
    signupsoustitre3:"Termos e Condições:",
    signupsoustitretexte6:"Leia os Termos e Condições e a Política de Privacidade. É essencial entender nossas políticas e como seus dados serão usados.",
    signupsoustitre4:"Inscrição completa:",
    signupsoustitretexte7:"Verifique todas as informações que você inseriu para precisão.",
    signupsoustitretexte8:"Quando estiver pronto, clique no botão <<Sign Up>> para criar sua conta.",
    signupsoustitre5:"Verificação da conta:",
    signupsoustitretexte9:"Depois de se inscrever, você receberá uma mensagem do Whatsapp em seu telefone com seu código de usuário secreto. Copie-o e volte para bazali e insira esse código uma vez para verificar seu código e ativar sua conta.",
    signupsoustitre6:"Parabéns! Agora você é um usuário do Bazali.",
    signupsoustitretexte10:"Depois que sua conta for verificada, você poderá fazer login usando seu número de telefone e senha.",
    signupsoustitretexte11:"Explore nossa plataforma, descubra produtos e aproveite os benefícios de ser um usuário Bazali.",
    signup_section_2:"Se você tiver alguma dúvida ou encontrar algum problema durante o processo de inscrição, sinta-se à vontade para entrar em contato com nossa equipe de suporte para obter assistência.",
    signup_section_3:"Observação: certifique-se de fornecer informações precisas e atualizadas durante o processo de inscrição para garantir uma experiência tranquila e personalizada na plataforma Bazali.",
    create:"Crio",
    edit:"Atualizar",
    delete:"Excluir",
    view:"Visualizar",
    //
    create_account:"Criar Conta",
    edit_account:"Atualizar Conta",
    delete_account:"Excluir Conta",
    view_account:"Visualizar Conta",
    first_name:"Sobrenome",
    firstname_placeholder:"Insira sobrenome",
    last_name:"Primeiro nome",
    lastname_placeholder:"Digite primeiro nome",
    send_code:"Enviar Code",
    code_user:"Código usuário",
    codeuser_placeholder:"Inserir código usuário",
    //
    continuer:"Continuar depois de verificar",
    please_number:"Preencha todos os campos antes de continuar.",
    whatsapp_authentication:"Seu número do Whatsapp é válido. Agora, volte para o Bazali e clique em <<Continuar após a autenticação>>.",
    signup_error:"O código de usuário inserido não está correto. Verifique sua mensagem do Whatsapp para obter o código de usuário correto ou repita o processo de inscrição.",
    buyer_exists:"Você recebe este aviso porque não pode usar o mesmo número de telefone para criar duas contas.",
    buyer_success:"Você criou com sucesso sua conta gratuita. Lembre-se de usar seu número de telefone e senha para fazer login todas as vezes.",
    error_login:"Suas credenciais de login não estão corretas. Verifique e tente novamente. Caso contrário, crie sua conta de usuário gratuita.",
    //
    cat_all:"Todos",
    cat_food:"Alimentos",
    cat_beverage:"Bebidas",
    cat_apparel:"Vestuário",
    cat_health:"Saúde",
    cat_beauty:"Beleza",
    cat_services:"Serviços",
    cat_electronic:"Eletrônica",
    cat_automotive:"Automotivo",
    cat_agriculture:"Agricultura",
    cat_breeding:"Reprodução",
    cat_house:"Casas",
    cat_sport:"Esportes",
    cat_decor:"Decoração",
    //
    subscribe_section_2:"Bem-vindo à seção de Comprador Registrado!",
    subscribe_section_3:"Para procurar um produto, siga estes passos simples:",
    subscribesoustitre1:"Selecione a categoria apropriada:",
    subscribe_search_texte1:"Use a barra de ferramentas rolável para navegar pelas diferentes categorias. Escolha a categoria que melhor corresponde ao seu produto desejado.",
    subscribesoustitre2:"Explore as categorias:",
    subscribe_search_texte2:"Cada categoria representa um grupo específico de produtos. Navegue pelas opções disponíveis para encontrar o que procura.",
    subscribesoustitre3:"Não consegue definir a categoria?",
    subscribe_search_texte3:"Use o botão <<Todos>>: Se você não tiver certeza sobre a categoria específica, clique no botão <<Todos>>. Ele irá redirecioná-lo para a barra de pesquisa global.",
    subscribesoustitre4:"Digite o nome do produto:",
    subscribe_search_texte4:"Na barra de pesquisa global, digite o nome do produto no qual está interessado. Pressione enter ou clique no ícone de pesquisa para iniciar a pesquisa.",
    //Dashboard
    dash_titre:"Gerencie sua presença",
    dash_section_1:"Gerenciar sua presença no Bazali permite que você alcance um público maior e mostre seus produtos a potenciais compradores de vários países. Com a interface amigável do Bazali, você pode facilmente criar e personalizar sua loja online, tornando-a atraente e única. Ao aproveitar os recursos promocionais e de marketing da Bazali, você pode aumentar a visibilidade de sua marca e aumentar as vendas. Além disso, o Bazali oferece suporte confiável ao cliente e opções seguras de assinatura, garantindo uma experiência de visibilidade tranquila e confiável para você.",
    dashsoustitre1:"Administração",
    dashsoustitretexte1:"No painel de administração do vendedor Bazali, você tem acesso a um conjunto abrangente de ferramentas e recursos para gerenciar com eficiência sua loja online e a visibilidade dos produtos.",
    dashsoustitre2:"Painel",
    dashsoustitretexte2:"No painel do Bazali, você pode gerenciar facilmente seus produtos, atualizar o estoque e acompanhar o desempenho das vendas. você também pode monitorar as interações com os clientes, responder a consultas e processar pedidos com eficiência. O painel fornece informações valiosas por meio de ferramentas de análise e relatórios, ajudando você a tomar decisões baseadas em dados para aumentar sua presença on-line com sucesso. Além disso, você pode acessar recursos de marketing e ferramentas promocionais para aumentar a visibilidade de sua marca e atrair potenciais compradores.",
    dashsoustitre3:"Planos",
    dashsoustitretexte3:"Com os serviços de plano pago da Bazali, você pode desbloquear uma variedade de recursos básicos, padrão e premium, como suporte prioritário ao cliente, análises avançadas e ferramentas de marketing aprimoradas. Os assinantes podem desfrutar de armazenamento expandido e acesso a modelos exclusivos para criar páginas de produtos impressionantes. Além disso, o plano pago permite a integração com aplicativos de terceiros e gateways de pagamento para transações seguras e seguras. Além disso, você pode se beneficiar da listagem prioritária nos resultados de pesquisa, dando aos seus produtos maior visibilidade e potencial para vendas mais altas. Depois que o período de avaliação expirar, você poderá continuar aproveitando esses recursos e serviços assinando um de nossos planos pagos acessíveis, personalizados para atender às suas necessidades de negócios.",
    dashsoustitre4:"Produtos",
    dashsoustitretexte4:"Nos produtos da Bazali, você pode criar e gerenciar sua loja online com facilidade, adicionando, atualizando, excluindo, visualizando e exibindo os detalhes dos produtos de maneira visualmente atraente. Você tem a flexibilidade de organizar os produtos em categorias e aplicar vários filtros para ajudar os compradores a encontrar o que precisam rapidamente. Você também pode definir preços, oferecer descontos e gerenciar opções de envio para fornecer uma experiência de compra perfeita para seus compradores. Com os produtos da Bazali, você pode alcançar o público da ECOWA, expandir o alcance da sua marca e aumentar as vendas por meio de uma plataforma fácil de usar e rica em recursos.",
    dashsoustitre5:"Recarregar",
    dashsoustitretexte5:"No recurso de recarga do Bazali, você pode facilmente adicionar fundos à sua conta, permitindo que você pague por assinaturas, acesse recursos premium e aumente a visibilidade de sua loja. O carregamento fornece um processo de pagamento seguro e contínuo, permitindo que você gerencie suas finanças convenientemente dentro da plataforma. Você pode monitorar o saldo da sua conta e o histórico de transações para acompanhar seus gastos e garantir uma experiência tranquila e ininterrupta no Bazali. Com a recarga, você pode aproveitar ao máximo os serviços pagos da Bazali e aprimorar sua presença online para alcançar um público mais amplo e gerar mais vendas.",
    dashsoustitre6:"Configurar",
    dashsoustitretexte6:"Nas configurações do Bazali, você pode personalizar sua conta atualizando as informações do perfil, como nome, detalhes de contato e descrição da loja. Você também pode configurar as preferências de notificação para se manter informado sobre atualizações de pedidos, mensagens e anúncios da plataforma. A seção de configurações também permite ajustar as configurações de privacidade e conectar contas de mídia social, permitindo uma experiência personalizada e segura no Bazali.",
    dashsoustitre7:"Mensagens",
    dashsoustitretexte7:"Nos bate-papos da Bazali, você pode se comunicar diretamente com compradores ou outros vendedores para discutir detalhes do produto, negociar preços e esclarecer qualquer dúvida. O recurso de bate-papo facilita as interações em tempo real, promovendo transações mais suaves e construindo confiança entre as partes. Você também pode acessar o histórico de bate-papo anterior para consultar discussões importantes e manter uma comunicação contínua durante o processo de compra e venda. Além disso, o sistema de bate-papo garante uma plataforma segura e privada para todas as conversas, aprimorando sua experiência e facilitando transações bem-sucedidas no Bazali.",
    dash_section_2:"Em conclusão, gerir a sua presença no Bazali permite-lhe expandir o seu alcance e ligar-se a um público diversificado de potenciais compradores da CEDEAO. Com ferramentas fáceis de usar e opções de personalização, você pode criar uma loja online atraente e exclusiva. Aproveitar os recursos de marketing da Bazali ajuda a aumentar a visibilidade da marca e impulsionar as vendas.",
    dash_section_3:"Além disso, nosso suporte ao cliente confiável e opções de assinatura segura garantem uma experiência perfeita e confiável, permitindo que você prospere no comércio eletrônico da CEDEAO.",
    //left side
    dash_admin:"Admin",
    dash_board:"A bordo",
    dash_plan:"Planos",
    dash_product:"Produtos",
    dash_order:"Recarregar",
    dash_settings:"Configurar",
    dash_chat:"Mensagens",
    //
    dash_manage_product:"Gerenciamento de Produtos",
    dash_new_product:"Novo Produto",
    dash_discount_product:"Promoção/Vendas",
    dash_edit_product:"Editar Produto",
    dash_delete_product:"Excluir Produto",
    dash_view_product:"Ver Produto",
    product_image:"Baixe Imagem",
    upload_image:"Selecione Imagem",
    //
    productname_placeholder:"Digite nome produto",
    product_description:"Descrição",
    product_price:"Preço",
    currency_placeholder:"Moeda",
    save_product:"Salvar",
    //admin panel
    dash_admin_panel:"Painel Administrativo",
    dash_product_magement:"Gestão Produtos",
    dash_order_processing:"processamento Pedido",
    dash_inventory_control:"Controle Inventário",
    dash_marketing_promotion:"Marketing & promoções",
    dash_customer_management:"Gestão Clientes",
    dash_analytic_reports:"Análise & relatórios",
    dash_customer_support:"Suporte Cliente",
    //dashboard
    dash_dashboard:"Painel",
    dash_update_inventory:"Atualizar Inventário",
    dash_sales_performance:"Performance Vendas",
    dash_customer_interaction:"Interações Cliente",
    dash_customer_inquiries:"Solicitações Cliente",
    //plan
    dash_basic_plan:"Plano Básico",
    dash_standard_plan:"Plano Standard",
    dash_premium_plan:"Plano Premium",
    dash_special_event:"Evento Especial",
    dash_third_party:"Aplicativo Terceiros",
    //top-up
    dash_basic_topup:"Recarga básica",
    dash_standard_topup:"Recarga Standard",
    dash_premium_topup:"Recarga Premium",
    dash_special_topup:"Recarga Especial",
    dash_third_topup:"Recarga Terceiros",
    //settings
    dash_create_profile:"Criar perfil",
    dash_edit_profile:"Editar Perfil",
    dash_delete_profile:"Excluir perfil",
    dash_view_profile:"Ver perfil",
    //chats
    dash_buyer_chats:"Mensagens Comprador",
    dash_seller_chats:"Mensagens Vendedor",
    //category
    dash_category:"Categoria",
    dash_new_category:"Nova Categoria",
    dash_discount_category:"Promoção Vendas",
    dash_edit_category:"Editar Categoria",
    dash_delete_category:"Excluir Categoria",
    dash_view_category:"Ver Categoria",
    //
    category_name:"Nome Categoria",
    categoryname_placeholder:"Insira Nome Categoria",
    category_description:"Descrição",
    category_price:"Preço",
    //profile
    profile_country:"País",
    profile_ville:"Cidade",
    profile_village:"Vila",
    place_village_option:"Nome da Vila (opcional)",
    profile_quartier:"Bairro",
    place_quartier_option:"Bairro (opcional)",
    profile_sector:"Setor da atividade",
    profile_sector_select:"Selecione o setor da atividade",
    profile_principal:"Nome da atividade",
    place_principal:"Digite o nome da atividade",
    profile_location:"Localização",
    place_location:"Explique aos compradores onde eles podem encontrar você",
    profile_mode:"Modo de vendas",
    select_profile_mode:"Selecione o modo de vendas",
    profile_description:"Descrição",
    place_description:"Descreva sua atividade",
    profile_contact:"Como ser contatado?",
    profile_mode_contact:"Selecione o modo de contato",
    profile_private:"Privado",
    profile_public:"Público",
    //
    profile_cash:"Cash",
    profile_online:"On-line",
    profile_correspondance:"Correspondência",
    profile_order:"Ordem",
    profile_commission:"Comissão",
    profile_affiliate:"Afiliação",
    characters_remaining:"caracteres restantes",
    Exceeded_character:"Limite de caracteres excedido",
    profile_success:"Você salvou seu perfil com sucesso",
    profile_exists:"Seu perfil já existe. Você só pode editá-lo.",
    //warning
    text_warning:"Tem certeza de que deseja excluir seu perfil? Essa ação não pode ser desfeita.",
    ok_delete:"Excluir",
    no_cancel:"Cancelar",
    //produit
    product_exists:"Você já criou seu produto principal. Você pode editá-lo, se necessário",
    image_uplaod:"Imagem enviada com sucesso",
    image_failed:"Falha no upload da imagem",
    product_success:"Seu produto principal salvo com sucesso",
    previous_image:"Imagem anterior",
    replace_image:"Substituir imagem",
    image:"Imagen",
    edit_product_success:"Seu produto principal atualizado com sucesso",
    text_product_warning:"Tem certeza de que deseja excluir seu produto principal? Esta ação irá desativá-lo e ativá-lo, se necessário.",
    text_view_product_warning:"Deseja habilitar seu produto principal? Em seguida, clique em habilitar para fazê-lo.",
    ok_enable:"Habilitar",
    //discount product
    error_product:"Antes de começar a criar produtos para vendas ou promoções, é essencial configurar seu produto principal.",
    dash_new_discount:"Criar vendas/promoção",
    dash_edit_discount:"Editar vendas/promoção",
    dash_delete_discount:"Excluir vendas/promoção",
    dash_view_discount:"Ver vendas/promoção",
    //
    text_discount_product_warning:"Observe que as vendas e promoções estão ativas por duas semanas. Além disso, há uma taxa de assinatura nominal necessária para garantir que suas ofertas estejam online e acessíveis a compradores em potencial.",
    ok_discount:"Confirme",
    //
    the_product_description:"Descreva seu produto",
    product_Previous_price:"Preço anterior",
    discount_rate:"Taxa de desconto %",
    calculate:"Calcular",
    new_price:"Novo preço",
    rate_placeholder:"Apenas número",
    date_start:"Data de início",
    date_end:"Data final",
    new_price_placeholder:"Novo preço",
    limit_product_discount:"Durante o período de teste, você pode criar e publicar um único produto de vendas ou promocional.",
    error_date:"Selecione a data após:",
    error_end_date:"Selecione outra data de início porque está fora do intervalo",
    discount_success:"Seu produto de vendas ou promocional salvo com sucesso",
    //Category
    text_category_product_warning:"Suas categorias de produtos funcionam como subprodutos de seu produto principal. Com nossos planos trial, básico, padrão e premium, você recebe categorias complementares válidas até o mesmo dia do seu produto principal. Para acesso expandido a categorias adicionais, disponíveis para potenciais compradores, é necessária uma taxa de assinatura nominal.",
    category_success:"Sua categoria de produto salva com sucesso",
    limit_category:"Você tem direito a uma categoria de produto no plano experimental.",
    edit_category_success:"Sua categoria de produto foi editada com sucesso",
    text_category_warning:"Tem certeza de que deseja excluir sua categoria de produto? Esta ação irá excluí-lo para sempre e todos os dados pertencentes a esta categoria.",
    error_data:"Sua consulta não está disponível.",
    //
    product:"Produtos",
    action:"Ação",
    //
    code_pin_texte:"Por favor, insira o código PIN do seu plano sem espaço e confirme",
    code_pin:"Código PIN",
    codepin_placeholder:"Seu código PIN",
    open:"Abrir",
    //subscibe seller
    error_connection:"Faça login antes de acessar a sessão de inscrição.",
    //
    text_subscribe_warning:"Parece que você não criou sua conta de assinante gratuita de 28 dias. Você quer fazer isso agora?",
    ok_create:"Sim",
    not_now:"Depois",
    //subscription expired
    text_expired_warning:"Sua assinatura expirou. Por favor, entre em contato com o revendedor mais próximo para recarregar e renovar seu plano de assinatura para aumentar sua visibilidade e vendas.",
    //
    start_subscribe:"Ativar",
    user_code:"Código Vendedor",
    plan_pincode:"Plano Assinatura",
    type_plan:"Assinatura Teste",
    success_subscribe:"Sua assinatura de avaliação gratuita foi criada com sucesso.",
    //
    text_notexpired_warning:"Sua assinatura não expirou. Aguarde até o dia seguinte à data de expiração para renovar sua assinatura. Data de validade:",
    //
    error_codepin:"Este código PIN inserido não é válido, tente novamente com o código correto que foi enviado após a recarga.",
    //
    success_subscription:"Sua assinatura foi renovada com sucesso. Terminará na data:",
    //
    subscribe_event:"Ativar Evento",
    type_plan_event:"Evento especial",
    //
    dash_private_seller_chats:"Conversa Privada",
    dash_seller_group_chats:"Conversa Grupo",
    //chat messages
    dash_meetings:"Encontros",
    chat_admin:"Gestão Executiva",
    chat_country:"Representante País",
    chat_city:"Gerente Cidade",
    chat_reseller:"Agente Revendedor",
    //
    chat_group_admin:"Grupo Admin",
    chat_group_country:"Grupo País",
    chat_group_city:"Grupo Cidade",
    chat_group_reseller:"Grupo Revendedor",
    //
    private_seller_chat:"Clicar em Acessar abre sua conta whatsaap e permite que você visualize todas as mensagens de seus compradores, converse com eles ou faça ligações para discutir suas dúvidas para definir melhores vendas e satisfazer suas necessidades.",
    meeting_info:"Todas as reuniões são marcadas em dia e horário específicos. Verifique em Notificação para ver quando isso acontecerá. Todas as reuniões estarão ativas 30 minutos antes de seu início. Clique no tipo relativo de reunião para entrar na sala de reunião se você for convidado e confirmado.",
    meeting_info_group:"Todas as reuniões são marcadas em dia e horário específicos. Verifique em Notificação para ver quando isso acontecerá. Todas as reuniões estarão ativas 30 minutos antes de seu início. Clique no tipo relativo de reunião para entrar na sala de reunião se você for convidado e confirmado.",
    open_meeting:"Acessar",
    close_meeting:"Fechar",
    //
    chat_Seller:"Vendedor",
    chat_group_seller:"grupo Vendedor",
    //notifications
    title:"Título",
    content_notify:"Conteúdo Notificação",
    due_date:"Data Vencimento",
    due_time:"Prazo Devido",
    status_notify:"Status Notificação",
    status_offline:"Off-line",
    status_online:"On-line",
    level_notify:"Nível Notificação",
    select_level:"Selecione Nível",
    level_urgent:"Urgente",
    level_normal:"Normal",
    state_notify:"Estado Notificação",
    select_state:"Selecione Estado",
    state_waiting:"Esperando",
    state_confirmed:"Confirmado",
    notifiy:"Notificar",
    activate:"Ativar",
    //
    notify_success:"Alvo notificado com sucesso",
    already_notified:"Este alvo já foi notificado",
    //
    dash_schedule:"Encontros",
    //
    schedule_activate:"Ativar Reuniões",
    schedule_edit:"Editar Reuniões",
    schedule_delete:"Excluir Reuniões",
    schedule_view:"Ver Reuniões",
    //
    error_meeting:"Não há reunião marcada para hoje",
    success_delete:"Esta coluna foi excluída com sucesso.",
    success_pause:"Esta coluna foi desativada com sucesso.",
    //
    list_schedule:"Lista Reuniões",
    //call
    whatsapp_call:"Chamar",
    //Buyers
    info_new_product:"Novos vendedores online por categorias de produtos",
    filter_search_texte1:"Filtre sua pesquisa se necessário para obter o máximo ou simplesmente clique em OK para gerar todas as visualizações desse produto.",
    //
    error_detail:"O vendedor não publicou nada sobre este conteúdo.",
    localisation:"Localização geográfica",
    //autocomplete search errors
    error_suggestion:"Desculpe, nenhum resultado para esta consulta de pesquisa. Verifique a ortografia e tente novamente. Se a palavra que você está procurando contém um apóstrofo, escreva-a com dois apóstrofos. Por exemplo, se você estiver procurando por N'gattakro, digite-o como N''gattakro no campo de pesquisa.",
    //
    please_connect:"Por favor faça login primeiro.",
    //
    error_contact:"O vendedor não permitiu que os compradores ligassem e conversassem.",
    //
    close_page:"Deseja fechar esta página?",
    yes:"Sim",
    no:"Não",
    //notifications
    notification_content:"Aqui estão todos os eventos e atividades promocionais. Toque em para visualizar ou pule para continuar suas tarefas.",
    event_text:"Eventos",
    promotion_text:"Promoções",
    skip_text:"Pular",
    //special events
    subscribe_special:"Ativar Evento Especial",
    dash_events_topup:"Recarga Eventos",
    dash_new_events:"Criar Evento",
    dash_edit_events:"Editar evento",
    dash_delete_events:"Excluir evento",
    dash_view_events:"Ver Evento",
    //
    event_logo:"Logotipo Evento",
    upload_logo:"Carregar Logotipo",
    event_name:"Título Evento",
    eventname_placeholder:"Insira o Título",
    event_description:"Descrever Evento",
    the_event_description:"Descreva resumidamente o seu Evento",
    event_video:"Vídeo Evento",
    upload_video:"Carregar Vídeo",
    //
    unsupported_browser:"Desculpe, seu navegador não suporta vídeos incorporados.",
    //
    error_limit:"Erro ao carregar o vídeo.",
    alert_duration:"A duração do vídeo excede o limite permitido. Escolha um vídeo com duração de 2 minutos ou menos.",
    //
    text_event_warning:"Esta ação excluirá todo o conteúdo do seu anúncio e não estará disponível durante o período coberto. Você deve reescrevê-lo novamente para estar online antes que a data expire. Tem certeza de que deseja excluir o anúncio?",
    //
    create_event_warning:"Seu anúncio não existe. Você quer criá-lo agora?",
    //
    error_event:"Você não pode prosseguir porque não configurou sua assinatura de evento/anúncio. Por favor, faça isso antes.",
    //
    application_text:"Programas",
    //
    text_by_country:"Quer fazer uma busca por outro país?",
    //
    search_country:"País de pesquisa: ",
    //landing page slogan
    slogan_one:"Compradores e Vendedores da CEDEAO",
    slogan_two:"Interconectem-se e Prosperem Juntos!",
    //offline warning
    offline_connection:"Ops, sem internet. Tente se reconectar antes.",
    //error event promotion application
    error_events:"Nenhum evento disponível no momento.",
    error_promotions:"Nenhuma promoção de vendedores disponível no momento.",
    error_applications:"Nenhum software disponível no momento.",
    //
    product_text:"Vendas recentes",
}
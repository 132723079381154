import React from 'react';
//
import { MdClose } from "react-icons/md";
import { 
    ImageDetalWrapper,
    ImageWrapper,
    CardProductImage,
    CardTextImageProductWrapper,
    CardTextProductBody,
    CardTextProductTitle,
    CardText,
    CardButtonClose,
} from '../../ComponentStyles';

function ListImageDetailCard({ url, onButtonClickClose, title, textDescription, textPrice, textCurrency }) {
  return (
    <div>
        <ImageDetalWrapper>
            <ImageWrapper style={{ backgroundImage: `url(${url})` }}>
                <CardTextImageProductWrapper>
                    <CardProductImage>
                        <img src={url} alt='preview' style={{ maxWidth: '50%', maxHeight: '100px', borderRadius: '50px' }} />
                    </CardProductImage>
                    <CardButtonClose onClick={onButtonClickClose}><MdClose style={{fontSize:'40px'}} /></CardButtonClose>
                </CardTextImageProductWrapper>
            </ImageWrapper>
            <CardTextProductBody>
                <CardTextProductTitle>{title}</CardTextProductTitle>
                <p style={{fontSize:'17px', color:'#000000'}}>{textDescription}</p>
                <CardText>{textPrice} {textCurrency}</CardText>
            </CardTextProductBody>
        </ImageDetalWrapper>
    </div>
  )
}

export default ListImageDetailCard
import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//import country button
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//
import FooterStarting from '../footer/FooterStarting';
//icons
import Connection from '../countries/sellerbuyericon/connection_icon.png';
import Subscriber from '../countries/sellerbuyericon/subscribe_icon.png';
//import Account from '../countries/sellerbuyericon/account_icon.png';
//
import { MdOutlineChat } from 'react-icons/md';
import { CgCloseO } from 'react-icons/cg';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
//
//import GobackBouton from '../reusablecomponents/buttons/GobackBouton';
import { HideFooter, /*ShowFooter, AlertModal*/ } from '../../helperfunctions/Helper';

//
import { 
    TermeScroller, 
    FooterSubTitle,
    FooterIntro, 
    HandlerContainerDiv, 
    HandlerColumnContainerDiv
    //BazaliButton,
} from '../ComponentStyles';

function StartingSeller() {
    HideFooter();

    const {t} = useTranslation();

    //alert
    //const elementOutId = "modaloutalertstartingseller";
    //const elementDropId = "backdropoutalertstartingseller";
    //const buttonHandler = "ouiHandlerAlertStartingSellerBtn";

    const navigateStartingSeller = useNavigate();

    const startingInfoSellerRef = useRef(null);
    const handlerStartingSellerRef = useRef(null);

    const handleStartingSeller = async (e) => {
        e.preventDefault();

        startingInfoSellerRef.current.style.display = 'block';
        handlerStartingSellerRef.current.style.display = 'none';
    }

    const handleCloseStartingSeller = async (e) => {
        e.preventDefault();

        startingInfoSellerRef.current.style.display = 'none';
        handlerStartingSellerRef.current.style.display = 'block';
    }

    const handleSubscriberSeller = async (e) => {
        e.preventDefault();

        navigateStartingSeller('/signupseller');
    }

    const handleNoSubscriberSeller = async (e) => {
        e.preventDefault();

        navigateStartingSeller('/sellerlogin');
    }

    /*const handleSubscribeSeller = async (e) => {
        e.preventDefault();

        //check if seller has already login
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            const pTextePays = t("error_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            //check in subscribe table if this user code already exist
        }
    }*/

    const handleGoBack = async () => {

        //ShowFooter();
        navigateStartingSeller('/starting');
    }
    return (
        <div id='startingseller'>
            <TermeScroller>
                <br/>
                <div style={{textAlign:'center'}}>
                    <FooterSubTitle>{t("seller_section_1")}</FooterSubTitle>
                </div>
                <div style={{float: 'left'}}>
                    <MdOutlineChat style={{color:'#246175', fontSize:'35px', cursor:'pointer'}} onClick={handleStartingSeller} />
                </div>
                <div ref={startingInfoSellerRef} style={{display: 'none'}}>
                    <FooterIntro>{t("seller_section_2")}</FooterIntro>
                    <FooterSubTitle>1. {t("sellersoustitre1")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("sellersoustitretexte1")}</li>
                    </ul>
                
                    <FooterSubTitle>2. {t("sellersoustitre2")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("sellersoustitretexte2")}</li>
                    </ul>
                
                    <FooterSubTitle>2. {t("sellersoustitre3")}</FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("sellersoustitretexte3")}</li>
                    </ul>
                
                    <FooterIntro>
                        {t("sellersoustitretexte4")}
                    </FooterIntro>
                    <div style={{marginLeft:'20px'}}>
                        <CgCloseO style={{color:'red', fontSize:'35px', cursor:'pointer'}} onClick={handleCloseStartingSeller} />
                    </div>
                    <br/><br/>
                </div>
                <div ref={handlerStartingSellerRef}>
                    <HandlerColumnContainerDiv>
                        <CountryButton 
                            url={Connection}
                            countryName={t("singinUser")}
                            onConfirm={handleNoSubscriberSeller}
                        />
                        <CountryButton 
                            url={Subscriber}
                            countryName={t("signUpUser")}
                            onConfirm={handleSubscriberSeller}
                        />
                        {/*<CountryButton 
                            url={Account}
                            countryName={t("subscribe")}
                            onConfirm={handleSubscribeSeller}
                        />*/}
                    </HandlerColumnContainerDiv>
                    {/*<HandlerContainerDiv>
                        <div>
                            <BazaliButton onClick={handleNoSubscriberSeller}>{t("singinUser")}</BazaliButton>
                        </div>
                        <div>
                            <BazaliButton onClick={handleSubscriberSeller}>{t("signUpUser")}</BazaliButton>
                        </div>
                        <div>
                            <BazaliButton onClick={handleSubscribeSeller}>{t("subscribe")}</BazaliButton>
                        </div>
                    </HandlerContainerDiv>*/}
                </div>
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={handleGoBack} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>

                <FooterStarting />
                <br/><br/>
                {/*<div id="modaloutalertstartingseller"></div>
                <div id="backdropoutalertstartingseller"></div>*/}
            </TermeScroller>
        </div>
    )
}

export default StartingSeller
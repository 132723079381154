import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//helperfunction
import { ShowFooter } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';

//
import { 
    TermeScroller, 
    FooterTitle, 
    FooterUnderTitle, 
    FooterIntro, 
    FooterSubTitle, 
    HandlerContainerDiv,
} from '../ComponentStyles';

function Terms() {
    const {t} = useTranslation();

    const navigateTerms = useNavigate();

    const goBackTermsHandler = async () => {
        ShowFooter();
        navigateTerms('/');
    }

    return (
        <div id='terms'>
            <TermeScroller>
                <br/>
                <FooterTitle>{t("termestitre")}</FooterTitle>
                <br/>
                <FooterUnderTitle>{t("aproposrevision")}</FooterUnderTitle>
                <br/><br/>
                <FooterIntro>
                    {t("termesrevision")} <strong>Bazali</strong>, {t("termestexte1")} <a style={{color:'#3f77ac', textDecoration:'none', fontWeight:'600'}} href="https://www.micarions.carions.net/">Carion's.</a>
                    <br/><br/>
                    {t("termestexte2")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>1. {t("termessoustitre1")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("termessoustitretexte1")}</li>
                    <li>{t("termessoustitretexte2")}</li>
                    <li>{t("termessoustitretexte3")}</li>
                    <li>{t("termessoustitretexte4")}</li>
                </ul>
                <br/>
                <FooterSubTitle>2. {t("termessoustitre2")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("termessoustitretexte5")}</li>
                    <li>{t("termessoustitretexte6")}</li>
                </ul>
                <br/>
                <FooterSubTitle>3. {t("termessoustitre3")}</FooterSubTitle>
                <FooterIntro>
                    {t("termessoustitretexte7")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>4. {t("termessoustitre4")}</FooterSubTitle>
                <FooterIntro>
                    {t("termessoustitretexte8")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>5. {t("termessoustitre5")}</FooterSubTitle>
                <FooterIntro>
                    {t("termessoustitretexte9")}
                    <br/>
                    {t("termessoustitretexte10")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>6. {t("termessoustitre6")}</FooterSubTitle>
                <FooterIntro>
                    {t("termessoustitretexte11")} {t("termessoustitretexte12")}
                    <br/>
                    {t("termessoustitretexte13")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>7. {t("termessoustitre7")}</FooterSubTitle>
                <FooterIntro>
                    {t("termessoustitretexte14")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>8. {t("termessoustitre8")}</FooterSubTitle>
                <FooterIntro>
                    {t("termessoustitretexte15")} {t("termessoustitretexte16")} {t("termessoustitretexte17")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>9. {t("termessoustitre9")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("termessoustitretexte18")}</li>
                    <li>{t("termessoustitretexte19")}</li>
                    <li>{t("termessoustitretexte20")}</li>
                    <li>{t("termessoustitretexte21")}</li>
                    <li>{t("termessoustitretexte22")}</li>
                    <li>{t("termessoustitretexte23")}</li>
                    <li>{t("termessoustitretexte24")}</li>
                    <li>{t("termessoustitretexte25")}</li>
                    <li>{t("termessoustitretexte26")}</li>
                    <li>{t("termessoustitretexte27")}</li>
                    <li>{t("termessoustitretexte28")}</li>
                    <li>{t("termessoustitretexte29")}</li>
                    <li>{t("termessoustitretexte30")}</li>
                    <li>{t("termessoustitretexte31")}</li>
                    <li>{t("termessoustitretexte32")}</li>
                    <li>{t("termessoustitretexte33")}</li>
                    <li>{t("termessoustitretexte34")}</li>
                    <li>{t("termessoustitretexte35")}</li>
                    <li>{t("termessoustitretexte36")}</li>
                    <li>{t("termessoustitretexte37")}</li>
                    <li>{t("termessoustitretexte38")}</li>
                    <li>{t("termessoustitretexte39")}</li>
                    <li><strong>{t("termessoustitretexte40")}</strong>. {t("termessoustitretexte41")}</li>
                    <li>{t("termessoustitretexte42")}</li>
                    <li>{t("termessoustitretexte43")}</li>
                    <li>{t("termessoustitretexte44")}</li>
                    <li>{t("termessoustitretexte45")}</li>
                    <li>{t("termessoustitretexte46")}</li>
                    <li>{t("termessoustitretexte47")}</li>
                    <li>{t("termessoustitretexte48")}</li>
                    <li>{t("termessoustitretexte49")}</li>
                    <li>{t("termessoustitretexte50")}</li>
                    <li>{t("termessoustitretexte51")}</li>
                    <li>{t("termessoustitretexte52")}</li>
                    <li>{t("termessoustitretexte53")}</li>
                    <li>{t("termessoustitretexte54")}</li>
                    <li>{t("termessoustitretexte55")}</li>
                    <li>{t("termessoustitretexte56")}</li>
                    <li>{t("termessoustitretexte57")}</li>
                    <li><strong>{t("termessoustitretexte58")}</strong>. {t("termessoustitretexte59")}</li>
                    <li><strong>{t("Modifications to Service")}</strong>. {t("termessoustitretexte61")}</li>
                    <li><strong>{t("termessoustitretexte62")}</strong>. {t("termessoustitretexte63")}</li>
                    <li>{t("termessoustitretexte64")}</li>
                    <li>{t("termessoustitretexte65")}</li>
                    <li>{t("termessoustitretexte66")}</li>
                    <li>{t("termessoustitretexte67")}</li>
                    <li>{t("termessoustitretexte68")}</li>
                    <li>{t("termessoustitretexte69")}</li>
                    <li>{t("termessoustitretexte70")}</li>
                    <li>{t("termessoustitretexte71")}</li>
                    <li>{t("termessoustitretexte72")}</li>
                    <li>{t("termessoustitretexte73")}</li>
                    <li><strong>{t("termessoustitretexte74")}</strong></li>
                    <li>{t("termessoustitretexte75")}</li>
                    <li>{t("termessoustitretexte76")} {t("termessoustitretexte77")}</li>
                    <li>{t("termessoustitretexte78")}</li>
                    <li><strong>{t("termessoustitretexte79")}</strong>. {t("termessoustitretexte80")}</li>
                    <li><strong>{t("termessoustitretexte81")}</strong></li>
                    <li>{t("termessoustitretexte82")}</li>
                    <li>{t("termessoustitretexte84")}</li>
                    <li>{t("termessoustitretexte83")}</li>
                    <li>{t("termessoustitretexte84")}</li>
                    <li><strong>{t("termessoustitretexte85")}</strong>. {t("termessoustitretexte86")}</li>
                    <li><strong>{t("termessoustitretexte87")}</strong>. {t("termessoustitretexte88")}</li>
                    <li><strong>{t("termessoustitretexte89")}</strong>. {t("termessoustitretexte90")}</li>
                </ul>

                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackTermsHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
            </TermeScroller>
        </div>
    )
}

export default Terms
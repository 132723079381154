import React, { useRef, useState, useEffect/*, useCallback*/ } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import './buyer.css';
//warning
import WarningSingleModal from '../reusablecomponents/componentutils/WarningSingleModal';
import WarningModal from '../reusablecomponents/componentutils/WarningModal';
//pagination
import SearchPagination from '../reusablecomponents/componentutils/SearchPagination';
//Cards
//import ListProductCard from '../reusablecomponents/cards/ListProductCard';
//card product
import CardProduct from '../reusablecomponents/cards/CardProduct';
import CardCategory from '../reusablecomponents/cards/CardCategory';
import CardProfile from '../reusablecomponents/cards/CardProfile'; 
//image card
import ListImageCardSubscriber from '../reusablecomponents/cards/ListImageCardSubscriber';
import ViewAllDetailCard from '../reusablecomponents/cards/ViewAllDetailCard';
import ListImageDetailCard from '../reusablecomponents/cards/ListImageDetailCard';
//contact card
//import SubscriberListProductCard from '../reusablecomponents/cards/SubscriberListProductCard';
import SubscriberCardContact from '../reusablecomponents/cards/SubscriberCardContact';
//
import { retrieveTableQuery } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { MdOutlineSearch/*, MdOutlineChat*/ } from 'react-icons/md';
//import { CgCloseO } from 'react-icons/cg';
//
import GobackBouton from '../reusablecomponents/buttons/GobackBouton';
import PlayButtonSearch from '../reusablecomponents/buttons/PlayButtonSearch';
import { HideFooter, AlertModal, TranslateWord, NomPays, WhatsappCall, /*RetrieveCodePays,*/ isOnline, EscapeApostrophe } from '../../helperfunctions/Helper';
import Trie from '../../helperfunctions/Trie'; //used for autocomplete search functionalities

import apiUrl from '../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    TermeScroller, 
    //FooterSubTitle,
    //FooterIntro,
    AutoCompleteSearchOne,
    AutoCompleteSearchTwo,
    TextLogin, 
    HandlerContainerDiv, 
    BazaliButton,
    ModalUser,
    ModalFilter,
    ConfirmButton,
    CancelButton,
    AccueilButton,
    SmallSelectBox,
} from '../ComponentStyles';

//import PouchDB from 'pouchdb-browser';
//import PouchFind from 'pouchdb-find';
// Enable pouch-find plugin
//PouchDB.plugin(PouchFind);

function SubscribeBuyer() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertsubscribebuyer";
    const elementDropId = "backdropoutalertsubscribebuyer";
    const buttonHandler = "ouiHandlerAlertSubscribebuyerBtn";

    const navigateSubscribeBuyer = useNavigate();

    //const searchBuyerRef = useRef(null);
    const subscribeSearchRef = useRef(null);
    //modal
    const subscribeSearchCityRef = useRef(null);
    const subscribeSearchNeighborhoodRef = useRef(null);
    const subscribeSearchPriceRef = useRef(null);
    //contact small input data
    //all
    const prefixeInterAllRef = useRef(null);
    const phoneAllRef = useRef(null);
    const profileAllRef = useRef(null);
    //all detail
    const prefixeInterAllDetailRef = useRef(null);
    const phoneAllDetailRef = useRef(null);
    const profileAllDetailRef = useRef(null);
    //food
    const prefixeInterFoodRef = useRef(null);
    const phoneFoodRef = useRef(null);
    const profileFoodRef = useRef(null);
    //beverage
    const prefixeInterBeverageRef = useRef(null);
    const phoneBeverageRef = useRef(null);
    const profileBeverageRef = useRef(null);
    //apprel
    const prefixeInterApparelRef = useRef(null);
    const phoneApparelRef = useRef(null);
    const profileApparelRef = useRef(null);
    //health
    const prefixeInterHealthRef = useRef(null);
    const phoneHealthRef = useRef(null);
    const profileHealthRef = useRef(null);
    //beauty
    const prefixeInterBeautyRef = useRef(null);
    const phoneBeautyRef = useRef(null);
    const profileBeautyRef = useRef(null);
    //services
    const prefixeInterServicesRef = useRef(null);
    const phoneServicesRef = useRef(null);
    const profileServicesRef = useRef(null);
    //electronic
    const prefixeInterElectronicRef = useRef(null);
    const phoneElectronicRef = useRef(null);
    const profileElectronicRef = useRef(null);
    //automotive
    const prefixeInterAutomotiveRef = useRef(null);
    const phoneAutomotiveRef = useRef(null);
    const profileAutomotiveRef = useRef(null);
    //agriculture
    const prefixeInterAgricultureRef = useRef(null);
    const phoneAgricultureRef = useRef(null);
    const profileAgricultureRef = useRef(null);
    //breeding
    const prefixeInterBreedingRef = useRef(null);
    const phoneBreedingRef = useRef(null);
    const profileBreedingRef = useRef(null);
    //house
    const prefixeInterHouseRef = useRef(null);
    const phoneHouseRef = useRef(null);
    const profileHouseRef = useRef(null);
    //sport
    const prefixeInterSportRef = useRef(null);
    const phoneSportRef = useRef(null);
    const profileSportRef = useRef(null);
    //decor
    const prefixeInterDecorRef = useRef(null);
    const phoneDecorRef = useRef(null);
    const profileDecorRef = useRef(null);
    //search
    const prefixeInterSearchRef = useRef(null);
    const phoneSearchRef = useRef(null);
    const profileSearchRef = useRef(null);

    //list of new products online
    const [newAllProductsModalOpen, setNewAllProductsModalOpen] = useState(false);
    const [newFoodProductsModalOpen, setNewFoodProductsModalOpen] = useState(false);
    const [newBeverageProductsModalOpen, setNewBeverageProductsModalOpen] = useState(false);
    const [newApparelProductsModalOpen, setNewApparelProductsModalOpen] = useState(false);
    const [newHealthProductsModalOpen, setNewHealthProductsModalOpen] = useState(false);
    const [newBeautyProductsModalOpen, setNewBeautyProductsModalOpen] = useState(false);
    const [newServiceProductsModalOpen, setNewServiceProductsModalOpen] = useState(false);
    const [newElectronicProductsModalOpen, setNewElectronicProductsModalOpen] = useState(false);
    const [newAutomotiveProductsModalOpen, setNewAutomotiveProductsModalOpen] = useState(false);
    const [newAgricultureProductsModalOpen, setNewAgricultureProductsModalOpen] = useState(false);
    const [newBreedingProductsModalOpen, setNewBreedingProductsModalOpen] = useState(false);
    const [newHouseProductsModalOpen, setNewHouseProductsModalOpen] = useState(false);
    const [newSportProductsModalOpen, setNewSportProductsModalOpen] = useState(false);
    const [newDecorProductsModalOpen, setNewDecorProductsModalOpen] = useState(false);
    //card product
    const [productsDetailModalOpen, setProductsDetailModalOpen] = useState(false);
    const [categoryDetailModalOpen, setCategoryDetailModalOpen] = useState(false);
    const [profileDetailModalOpen, setProfileDetailModalOpen] = useState(false);
    const [contactDetailModalOpen, setContactDetailModalOpen] = useState(false);
    //card food
    const [productsFoodDetailModalOpen, setProductsFoodDetailModalOpen] = useState(false);
    const [categoryFoodDetailModalOpen, setCategoryFoodDetailModalOpen] = useState(false);
    const [profileFoodDetailModalOpen, setProfileFoodDetailModalOpen] = useState(false);
    const [contactFoodModalOpen, setContactFoodModalOpen] = useState(false);
    //card beverage
    const [productsBeverageDetailModalOpen, setProductsBeverageDetailModalOpen] = useState(false);
    const [categoryBeverageDetailModalOpen, setCategoryBeverageDetailModalOpen] = useState(false);
    const [profileBeverageDetailModalOpen, setProfileBeverageDetailModalOpen] = useState(false);
    const [contactBeverageModalOpen, setContactBeverageModalOpen] = useState(false);
    //card apparel
    const [productsApparelDetailModalOpen, setProductsApparelDetailModalOpen] = useState(false);
    const [categoryApparelDetailModalOpen, setCategoryApparelDetailModalOpen] = useState(false);
    const [profileApparelDetailModalOpen, setProfileApparelDetailModalOpen] = useState(false);
    const [contactApparelModalOpen, setContactApparelModalOpen] = useState(false);
    //card health
    const [productsHealthDetailModalOpen, setProductsHealthDetailModalOpen] = useState(false);
    const [categoryHealthDetailModalOpen, setCategoryHealthDetailModalOpen] = useState(false);
    const [profileHealthDetailModalOpen, setProfileHealthDetailModalOpen] = useState(false);
    const [contactHealthModalOpen, setContactHealthModalOpen] = useState(false);
    //card beauty
    const [productsBeautyDetailModalOpen, setProductsBeautyDetailModalOpen] = useState(false);
    const [categoryBeautyDetailModalOpen, setCategoryBeautyDetailModalOpen] = useState(false);
    const [profileBeautyDetailModalOpen, setProfileBeautyDetailModalOpen] = useState(false);
    const [contactBeautyModalOpen, setContactBeautyModalOpen] = useState(false);
    //card services
    const [productsServicesDetailModalOpen, setProductsServicesDetailModalOpen] = useState(false);
    const [categoryServicesDetailModalOpen, setCategoryServicesDetailModalOpen] = useState(false);
    const [profileServicesDetailModalOpen, setProfileServicesDetailModalOpen] = useState(false);
    const [contactServicesModalOpen, setContactServicesModalOpen] = useState(false);
    //card electronic
    const [productsElectronicDetailModalOpen, setProductsElectronicDetailModalOpen] = useState(false);
    const [categoryElectronicDetailModalOpen, setCategoryElectronicDetailModalOpen] = useState(false);
    const [profileElectronicDetailModalOpen, setProfileElectronicDetailModalOpen] = useState(false);
    const [contactElectronicModalOpen, setContactElectronicModalOpen] = useState(false);
    //card automotive
    const [productsAutomotiveDetailModalOpen, setProductsAutomotiveDetailModalOpen] = useState(false);
    const [categoryAutomotiveDetailModalOpen, setCategoryAutomotiveDetailModalOpen] = useState(false);
    const [profileAutomotiveDetailModalOpen, setProfileAutomotiveDetailModalOpen] = useState(false);
    const [contactAutomotiveModalOpen, setContactAutomotiveModalOpen] = useState(false);
    //card agriculture
    const [productsAgricultureDetailModalOpen, setProductsAgricultureDetailModalOpen] = useState(false);
    const [categoryAgricultureDetailModalOpen, setCategoryAgricultureDetailModalOpen] = useState(false);
    const [profileAgricultureDetailModalOpen, setProfileAgricultureDetailModalOpen] = useState(false);
    const [contactAgricultureModalOpen, setContactAgricultureModalOpen] = useState(false);
    //card breeding
    const [productsBreedingDetailModalOpen, setProductsBreedingDetailModalOpen] = useState(false);
    const [categoryBreedingDetailModalOpen, setCategoryBreedingDetailModalOpen] = useState(false);
    const [profileBreedingDetailModalOpen, setProfileBreedingDetailModalOpen] = useState(false);
    const [contactBreedingModalOpen, setContactBreedingModalOpen] = useState(false);
    //card house
    const [productsHouseDetailModalOpen, setProductsHouseDetailModalOpen] = useState(false);
    const [categoryHouseDetailModalOpen, setCategoryHouseDetailModalOpen] = useState(false);
    const [profileHouseDetailModalOpen, setProfileHouseDetailModalOpen] = useState(false);
    const [contactHouseModalOpen, setContactHouseModalOpen] = useState(false);
    //card sport
    const [productsSportDetailModalOpen, setProductsSportDetailModalOpen] = useState(false);
    const [categorySportDetailModalOpen, setCategorySportDetailModalOpen] = useState(false);
    const [profileSportDetailModalOpen, setProfileSportDetailModalOpen] = useState(false);
    const [contactSportModalOpen, setContactSportModalOpen] = useState(false);
    //card decor
    const [productsDecorDetailModalOpen, setProductsDecorDetailModalOpen] = useState(false);
    const [categoryDecorDetailModalOpen, setCategoryDecorDetailModalOpen] = useState(false);
    const [profileDecorDetailModalOpen, setProfileDecorDetailModalOpen] = useState(false);
    const [contactDecorModalOpen, setContactDecorModalOpen] = useState(false);
    //Search Results
    const [searchResultModalOpen, setSearchResultModalOpen] = useState(false);
    //
    //const [paginationSearchDetailModalOpen, setPaginationSearchDetailModalOpen] = useState(false);
    const [productsSearchDetailModalOpen, setProductsSearchDetailModalOpen] = useState(false);
    const [categorySearchDetailModalOpen, setCategorySearchDetailModalOpen] = useState(false);
    const [profileSearchDetailModalOpen, setProfileSearchDetailModalOpen] = useState(false);
    const [contactSearchDetailModalOpen, setContactSearchDetailModalOpen] = useState(false);
    //
    const [userSearchProductsDetail, setSearchProductsDetail] = useState([0]);
    const [userSearchProductsCategory, setSearchProductsCategory] = useState([0]);
    const [userSearchProductsProfile, setSearchProductsProfile] = useState([0]);
    const [userSearchProductsContact, setSearchProductsContact] = useState([0]);
    //
    const [confirmSearchDetail, setConfirmSearchDetail] = useState([0]);
    //pagination set up
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    //search input
    const [subscribeSearchModalOpen, setSubscribeSearchModalOpen] = useState(true);
    //
    const [cityModalOpen, setCityModalOpen] = useState(false);
    const [listcity, setListCity] = useState([0]);
    const [neighborhoodModalOpen, setNeighborhoodModalOpen] = useState(false);
    const [listNeighbord, setListNeighbord] = useState([0]);
    const [bestPricesModalOpen, setBestPricesModalOpen] = useState(false);
    const [listPrice, setListPrice] = useState([0]);

    // Define state variables for modals
    const [scrollSubscribeModalOpen, setScrollSubscribeModalOpen] = useState(true);
    // Declare state variables for input data
    const [subscribeSearch, setSubscribeSearch] = useState('');
    const [subscribeSearchCity, setSubscribeSearchCity] = useState('');
    const [subscribeSearchNeighborhood, setSubscribeSearchNeighborhood] = useState('');
    const [subscribeSearchPrice, setSubscribeSearchPrice] = useState('');

    //declare no subscribe button
    const [subscribeBuyerButtonModalOpen, setSubscribeBuyerButtonModalOpen] = useState(false);

    //display product states
    const [userAllProducts, setAllProducts] = useState([0]);
    const [userAllProductsDetail, setAllProductsDetail] = useState([0]);
    const [userAllProductsCategory, setAllProductsCategory] = useState([0]);
    const [userAllProductsProfile, setAllProductsProfile] = useState([0]);
    const [userAllProductsContact, setAllProductsContact] = useState([0]);
    //display food product states
    const [userFoodProducts, setUserFoodProducts] = useState([0]);
    const [userFoodProductsDetail, setUserFoodProductsDetail] = useState([0]);
    const [userFoodProductsCategory, setUserFoodProductsCategory] = useState([0]);
    const [userFoodProductsProfile, setUserFoodProductsProfile] = useState([0]);
    const [userFoodProductsContact, setFoodProductsContact] = useState([0]);
    //display beverage product states
    const [userBeverageProducts, setUserBeverageProducts] = useState([0]);
    const [userBeverageProductsDetail, setUserBeverageProductsDetail] = useState([0]);
    const [userBeverageProductsCategory, setUserBeverageProductsCategory] = useState([0]);
    const [userBeverageProductsProfile, setUserBeverageProductsProfile] = useState([0]);
    const [userBeverageProductsContact, setBeverageProductsContact] = useState([0]);
    //display apparel product states
    const [userApparelProducts, setUserApparelProducts] = useState([0]);
    const [userApparelProductsDetail, setUserApparelProductsDetail] = useState([0]);
    const [userApparelProductsCategory, setUserApparelProductsCategory] = useState([0]);
    const [userApparelProductsProfile, setUserApparelProductsProfile] = useState([0]);
    const [userApparelProductsContact, setApparelProductsContact] = useState([0]);
    //display health product states
    const [userHealthProducts, setUserHealthProducts] = useState([0]);
    const [userHealthProductsDetail, setUserHealthProductsDetail] = useState([0]);
    const [userHealthProductsCategory, setUserHealthProductsCategory] = useState([0]);
    const [userHealthProductsProfile, setUserHealthProductsProfile] = useState([0]);
    const [userHealthProductsContact, setHealthProductsContact] = useState([0]);
    //display beauty product states
    const [userBeautyProducts, setUserBeautyProducts] = useState([0]);
    const [userBeautyProductsDetail, setUserBeautyProductsDetail] = useState([0]);
    const [userBeautyProductsCategory, setUserBeautyProductsCategory] = useState([0]);
    const [userBeautyProductsProfile, setUserBeautyProductsProfile] = useState([0]);
    const [userBeautyProductsContact, setBeautyProductsContact] = useState([0]);
    //display services product states
    const [userServicesProducts, setUserServicesProducts] = useState([0]);
    const [userServicesProductsDetail, setUserServicesProductsDetail] = useState([0]);
    const [userServicesProductsCategory, setUserServicesProductsCategory] = useState([0]);
    const [userServicesProductsProfile, setUserServicesProductsProfile] = useState([0]);
    const [userServicesProductsContact, setServicesProductsContact] = useState([0]);
    //display electronic product states
    const [userElectronicProducts, setUserElectronicProducts] = useState([0]);
    const [userElectronicProductsDetail, setUserElectronicProductsDetail] = useState([0]);
    const [userElectronicProductsCategory, setUserElectronicProductsCategory] = useState([0]);
    const [userElectronicProductsProfile, setUserElectronicProductsProfile] = useState([0]);
    const [userElectronicProductsContact, setElectronicProductsContact] = useState([0]);
    //display automotive product states
    const [userAutomotiveProducts, setUserAutomotiveProducts] = useState([0]);
    const [userAutomotiveProductsDetail, setUserAutomotiveProductsDetail] = useState([0]);
    const [userAutomotiveProductsCategory, setUserAutomotiveProductsCategory] = useState([0]);
    const [userAutomotiveProductsProfile, setUserAutomotiveProductsProfile] = useState([0]);
    const [userAutomotiveProductsContact, setAutomotiveProductsContact] = useState([0]);
    //display agriculture product states
    const [userAgricultureProducts, setUserAgricultureProducts] = useState([0]);
    const [userAgricultureProductsDetail, setUserAgricultureProductsDetail] = useState([0]);
    const [userAgricultureProductsCategory, setUserAgricultureProductsCategory] = useState([0]);
    const [userAgricultureProductsProfile, setUserAgricultureProductsProfile] = useState([0]);
    const [userAgricultureProductsContact, setAgricultureProductsContact] = useState([0]);
    //display breeding product states
    const [userBreedingProducts, setUserBreedingProducts] = useState([0]);
    const [userBreedingProductsDetail, setUserBreedingProductsDetail] = useState([0]);
    const [userBreedingProductsCategory, setUserBreedingProductsCategory] = useState([0]);
    const [userBreedingProductsProfile, setUserBreedingProductsProfile] = useState([0]);
    const [userBreedingProductsContact, setBreedingProductsContact] = useState([0]);
    //display house product states
    const [userHouseProducts, setUserHouseProducts] = useState([0]);
    const [userHouseProductsDetail, setUserHouseProductsDetail] = useState([0]);
    const [userHouseProductsCategory, setUserHouseProductsCategory] = useState([0]);
    const [userHouseProductsProfile, setUserHouseProductsProfile] = useState([0]);
    const [userHouseProductsContact, setHouseProductsContact] = useState([0]);
    //display sport product states
    const [userSportProducts, setUserSportProducts] = useState([0]);
    const [userSportProductsDetail, setUserSportProductsDetail] = useState([0]);
    const [userSportProductsCategory, setUserSportProductsCategory] = useState([0]);
    const [userSportProductsProfile, setUserSportProductsProfile] = useState([0]);
    const [userSportProductsContact, setSportProductsContact] = useState([0]);
    //display decor product states
    const [userDecorProducts, setUserDecorProducts] = useState([0]);
    const [userDecorProductsDetail, setUserDecorProductsDetail] = useState([0]);
    const [userDecorProductsCategory, setUserDecorProductsCategory] = useState([0]);
    const [userDecorProductsProfile, setUserDecorProductsProfile] = useState([0]);
    const [userDecorProductsContact, setDecorProductsContact] = useState([0]);
    //show modal
    const [showModal, setShowModal] = useState(false);
    const [showModalContact, setShowModalContact] = useState(false);
    const [showModalClose, setShowModalClose] = useState(false);
    //
    //const [subscribeBuyerModalOpen, setSubscribeBuyerModalOpen] = useState(false);
    //create pouchDb instance
    //const [pouchDBInstance, setPouchDBInstance] = useState(new PouchDB('mydb'));
    //
    const [userSearchProducts, setUserSearchProducts] = useState([0]);
    //
    const [dictionary, setDictionary] = useState([0]);
    const [prefix, setPrefix] = useState("");
    const [suggestion, setSuggestion] = useState("");
    //render all new 30 all product when page mounts
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [viewAllProducts, setViewAllProducts] = useState([0]);
    const [showAllDetail, setShowAllDetail] = useState(false);
    const [viewAllDetailProfile, setViewAllDetailProfile] = useState([0]);
    const [viewAllDetailProduct, setViewAllDetailProduct] = useState([0]);
    const [viewAllDetailCategory, setViewAllDetailCategory] = useState([0]);
    //view detail into list Image card
    const [viewDetailProduct, setViewDetailProduct] = useState(false);
    const [viewDetailCategory, setViewDetailCategory] = useState(false);
    const [viewDetailProfile, setViewDetailProfile] = useState(false);
    const [viewDetailContact, setViewDetailContact] = useState(false);
    const [viewProductData, setViewProductData] = useState([0]);
    const [viewCategoryData, setViewCategoryData] = useState([0]);
    const [viewProfileData, setViewProfileData] = useState([0]);
    const [viewContactData, setViewContactData] = useState([0]);

    //emplementation useEffect to retrieve all 30 new products
    useEffect(() => {
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultatView = 0;
        //
        var tableNameProduct = "";
        var signupTableProduct = [];
        //
        const variableOne = "p";
        const variableTwo = "s";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "date_end";
        const fourthWhere = "id_product";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        //check all seller subscription where currrent date is < date expiration
        Axios.post(`${apiUrl}/check/valid/subscriber/product`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            limitNumber:limitNumber,
        }).then((responseView) => {
            resultatView = responseView.data.length;
            if(resultatView === 0){
                //const pTextePays = t("error_data");
                //AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                //set show and view all 30 new products
                setViewAllProducts(responseView.data);
                setShowAllProducts(true);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //set the belonging inner states to true and the others to false
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }, []);

    //Function to fetch data from the API for each table
    const fetchDataFromTable = async (tableName, firstWhere, firstParameter) => {
        try {
            const result = await Axios.post(`${apiUrl}/check/one/parameter/table`, {
                tableNameSeller:tableName,
                firstWhere:firstWhere,
                firstParameter:firstParameter,
            });
            return result.data;
        } catch (error) {
            const pTextePays = t("error_data");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            throw error;
        }
    }

    //handle event all 30 new products
    const handleAllImage = async (allId) => {

        var tableNameProduct = "";
        var signupTableProduct = "";

        var tableNameCategory = "";
        var signupTableCategory = [];

        var tableNameProfile = "";
        var signupTableProfile = [];
        //
        const firstWhere = "code_user";
        const firstParameter = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if (signupTableProduct) {
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1, dataTable2, dataTable3] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
            fetchDataFromTable(tableNameCategory, firstWhere, firstParameter),
            fetchDataFromTable(tableNameProfile, firstWhere, firstParameter),
        ]);

        setViewAllDetailProfile(dataTable3);
        setViewAllDetailProduct(dataTable1);
        setViewAllDetailCategory(dataTable2);

        // Now you have the data from all three tables
        setShowAllProducts(false);
        setShowAllDetail(true);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //set the belonging inner states to true and the others to false
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }

    const handleAllProduct = async (allId) => {

        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = allId;

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setViewProductData(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(true);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handlerCloseDetailProduct = async () => {

        setShowAllProducts(true);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //set the belonging inner states to true and the others to false
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handleAllCategory = async (allId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = allId;

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setViewCategoryData(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(true);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handlerCloseDetailCategory = async () => {

        setShowAllProducts(true);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //set the belonging inner states to true and the others to false
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }

    //
    const handleAllProfile = async (allId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = allId;

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setViewProfileData(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(true);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handlerCloseDetailProfile = async () => {

        setShowAllProducts(true);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //set the belonging inner states to true and the others to false
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }

    const handleAllDetailPhone = async (allId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = allId;
        const productUserCode = allId;
        const profileUserCode = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((responseCont) => {
            resultatCont = responseCont.data.length;
            if(resultatCont === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setViewContactData(responseCont.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(true);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }

    const handleDetailContact = async () => {
        
        const prefixeInterAllDetail = prefixeInterAllDetailRef.current.value;
        const phoneAllDetail = phoneAllDetailRef.current.value;
        const profileAllDetail = profileAllDetailRef.current.value;
        //
        if(profileAllDetail === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterAllDetail, phoneAllDetail);
        }
    }
    //
    const handlerCloseDetailContact = async () => {

        setShowAllProducts(true);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //set the belonging inner states to true and the others to false
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }

    //
    const handlerCloseAllProfileDetail = async () => {

        //this allows to set the main product type selected and render based on value
        var typeProduct = document.getElementById('typeproduit').innerText;
        if(typeProduct === ''){
            setShowAllProducts(true);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'all'){
            setNewAllProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'food'){
            setNewFoodProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'beverage'){
            setNewBeverageProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'apparel'){
            setNewApparelProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'health'){
            setNewHealthProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'beauty'){
            setNewBeautyProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'services'){
            setNewServiceProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'electronic'){
            setNewElectronicProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'automotive'){
            setNewAutomotiveProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'agriculture'){
            setNewAgricultureProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'breeding'){
            setNewBreedingProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'house'){
            setNewHouseProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'sport'){
            setNewSportProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'decor'){
            setNewDecorProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
        }

        //setShowAllProducts(true);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //set the belonging inner states to true and the others to false
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
    }
    //
    const handlerCloseAllDetailProduct = async () => {

        //this allows to set the main product type selected and render based on value
        var typeProduct = document.getElementById('typeproduit').innerText;
        if(typeProduct === ''){
            setShowAllProducts(true);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'all'){
            setNewAllProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'food'){
            setNewFoodProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'beverage'){
            setNewBeverageProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'apparel'){
            setNewApparelProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'health'){
            setNewHealthProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'beauty'){
            setNewBeautyProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'services'){
            setNewServiceProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'electronic'){
            setNewElectronicProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'automotive'){
            setNewAutomotiveProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'agriculture'){
            setNewAgricultureProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'breeding'){
            setNewBreedingProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'house'){
            setNewHouseProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'sport'){
            setNewSportProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'decor'){
            setNewDecorProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
        }

        //setShowAllProducts(true);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //set the belonging inner states to true and the others to false
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
    }
    //
    const handlerCloseAllDetailCategory = async () => {

        //this allows to set the main product type selected and render based on value
        var typeProduct = document.getElementById('typeproduit').innerText;
        if(typeProduct === ''){
            setShowAllProducts(true);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'all'){
            setNewAllProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'food'){
            setNewFoodProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'beverage'){
            setNewBeverageProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'apparel'){
            setNewApparelProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'health'){
            setNewHealthProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'beauty'){
            setNewBeautyProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'services'){
            setNewServiceProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'electronic'){
            setNewElectronicProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'automotive'){
            setNewAutomotiveProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'agriculture'){
            setNewAgricultureProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'breeding'){
            setNewBreedingProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'house'){
            setNewHouseProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'sport'){
            setNewSportProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
        }else if(typeProduct === 'decor'){
            setNewDecorProductsModalOpen(true);
            setShowAllProducts(false);
            //
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
        }

        //setShowAllProducts(true);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //set the belonging inner states to true and the others to false
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
    }

    //
    var myTrie = new Trie();
    //
    useEffect(() => {
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultat1 = 0;
        const columnName = "description_product";
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        // Fetch product descriptions from the backend
        Axios.post(`${apiUrl}/product/description/search/table`, {
            tableName:tableNameProduct,
            columnName:columnName,
        }).then((responseProduct) => {
            resultat1 = responseProduct.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                const descriptions = responseProduct.data.map(row => row[columnName].split(' ')).flat();
                //setDescriptions(descriptions);
                setDictionary(descriptions);
            }
        });
    }, [t]);

    (async () => {
        const words = dictionary;
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            myTrie.insert(word)
        }
    }) ();

    const onChange = (e) => {
        var value = e.target.value;
        setPrefix(value);
        var words = value.split(' ');
        var trie_prefix = words[words.length - 1].toLowerCase();
        var found_words = myTrie.find(trie_prefix).sort((a, b) => {
            return a.length - b.length;
        });
        var first_word = found_words[0];
        if(
            found_words.length !== 0 &&
            value !== '' &&
            value[value.length - 1] !== ' '
        ){
            if (first_word != null){
                var remainder = first_word.slice(trie_prefix.length);
                setSuggestion(value + remainder);
            }
        }else{
            setSuggestion(value);
        }
    };

    const handleKeyDown = (e) => {
        // Key codes for arrow keys
        const leftArrow = 37;
        const upArrow = 38;
        const rightArrow = 39;
        const downArrow = 40;
        const enter = 13;
        const escape = 27;
        const space = 32;

        if (e.keyCode === leftArrow || e.keyCode === upArrow || e.keyCode === rightArrow || e.keyCode === downArrow || e.keyCode === enter || e.keyCode === escape || e.keyCode === space){
            setPrefix(suggestion);
        }
    }

    /*const getFromPouchDB = useCallback(async () => {
        const result = await pouchDBInstance.allDocs({ include_docs: true });
        return result.rows.map((row) => row.doc);
    }, [pouchDBInstance]);*/
    
    /*useEffect(() => {
        // Get data from PouchDB when the component mounts
        const fetchData = async () => {
            const result = await getFromPouchDB();
            setUserSearchProducts(result);
        };
    
        fetchData();
    }, [getFromPouchDB]);*/

    // Function to save data to PouchDB
    /*const saveToPouchDB = async (data) => {
        return pouchDBInstance.bulkDocs(data);
    };*/

    // Function to destroy the PouchDB instance
    /*const destroyPouchDB = async () => {
        return pouchDBInstance.destroy()
    };*/

    /*const handleInfoBuyer = async (e) => {
        e.preventDefault();

        setSubscribeBuyerModalOpen(true);
        setScrollSubscribeModalOpen(false);
        searchBuyerRef.current.style.display = 'none';
    }*/

    /*const handleCloseInfoBuyer = async (e) => {
        e.preventDefault();

        setSubscribeBuyerModalOpen(false);
        setScrollSubscribeModalOpen(true);
        searchBuyerRef.current.style.display = 'block';
    }*/

    //Encapsulate the logic view all product inside a function that you can call when needed.
    const fetchNewProducts = async () => {
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultatFetch = 0;
        //
        var tableNameProduct = "";
        var signupTableProduct = "";
        //
        const variableOne = "p";
        const variableTwo = "s";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "date_end";
        const fourthWhere = "id_product";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if (signupTableSubscribe) {
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if (signupTableProduct) {
            tableNameProduct = signupTableProduct.table.table4;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/valid/subscriber/product`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            limitNumber:limitNumber,
        }).then((responseFetch) => {
            resultatFetch = responseFetch.data.length;
            if(resultatFetch === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                //set show and view all 30 new products
                setViewAllProducts(responseFetch.data);
                setShowAllProducts(true);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //set the belonging inner states to true and the others to false
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
            }
        });
    }

    //******List All products handler**********
    const subscribeAllHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "all";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        // declare subscribe variables 
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat1 = 0;
        //
        var tableNameProduct = "";
        var signupTableProduct = [];
        //
        const variableOne = "p";
        const variableTwo = "s";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "date_end";
        const fourthWhere = "id_product";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/valid/subscriber/product`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            limitNumber:limitNumber,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setAllProducts(response1.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(true);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    const handleProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setAllProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(true);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    const handleCategory = async (categoryId) => {
        
        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setAllProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(true);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    const handleProfile = async (profileId) => {
        
        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setAllProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(true);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    const handleContact = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setAllProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(true);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handlerCloseAll = async () => {
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(true);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);  
        setContactSearchDetailModalOpen(false);       
    }
    //
    const handleAllPhone = async () => {

        const prefixeInterAll = prefixeInterAllRef.current.value;
        const phoneAll = phoneAllRef.current.value;
        const profileAll = profileAllRef.current.value;
        //
        if(profileAll === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterAll, phoneAll);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseDetail = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(true);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategory = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(true);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfile = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(true);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Food products handler*************
    const subscribeFoodHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "food";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserFoodProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(true);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductFood = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserFoodProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(true);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryFood = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserFoodProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(true);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileFood = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserFoodProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(true);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactFood = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setFoodProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(true);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleFoodPhone = async () => {

        const prefixeInterFood = prefixeInterFoodRef.current.value;
        const phoneFood = phoneFoodRef.current.value;
        const profileFood = profileFoodRef.current.value;
        //
        if(profileFood === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterFood, phoneFood);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseFood = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(true);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailFood = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(true);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryFood = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(true);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileFood = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(true);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Beverage products handler*************
    const subscribeBeverageHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "beverage";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBeverageProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(true);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductBeverage = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBeverageProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(true);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryBeverage = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBeverageProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(true);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileBeverage = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBeverageProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(true);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactBeverage = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setBeverageProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(true);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleBeveragePhone = async () => {

        const prefixeInterBeverage = prefixeInterBeverageRef.current.value;
        const phoneBeverage = phoneBeverageRef.current.value;
        const profileBeverage = profileBeverageRef.current.value;
        //
        if(profileBeverage === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterBeverage, phoneBeverage);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseBeverage = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(true);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailBeverage = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(true);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryBeverage = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(true);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileBeverage = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(true);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Apparel products handler*************
    const subscribeApparelHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "apparel";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserApparelProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(true);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductApparel = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserApparelProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(true);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryApparel = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserApparelProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(true);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileApparel = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserApparelProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(true);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactApparel = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setApparelProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(true);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleApparelPhone = async () => {

        const prefixeInterApparel = prefixeInterApparelRef.current.value;
        const phoneApparel = phoneApparelRef.current.value;
        const profileApparel = profileApparelRef.current.value;
        //
        if(profileApparel === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterApparel, phoneApparel);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseApparel = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(true);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailApparel = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(true);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryApparel = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(true);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileApparel = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(true);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Health products handler*************
    const subscribeHealthHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "health";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserHealthProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(true);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductHealth = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserHealthProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(true);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryHealth = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserHealthProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(true);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileHealth = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserHealthProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(true);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactHealth = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setHealthProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(true);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleHealthPhone = async () => {

        const prefixeInterHealth = prefixeInterHealthRef.current.value;
        const phoneHealth = phoneHealthRef.current.value;
        const profileHealth = profileHealthRef.current.value;
        //
        if(profileHealth === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterHealth, phoneHealth);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseHealth = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(true);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailHealth = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(true);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryHealth = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(true);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileHealth = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(true);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Beauty products handler*************
    const subscribeBeautyHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "beauty";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBeautyProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(true);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductBeauty = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBeautyProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(true);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryBeauty = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBeautyProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(true);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileBeauty = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBeautyProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(true);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactBeauty = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setBeautyProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(true);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleBeautyPhone = async () => {

        const prefixeInterBeauty = prefixeInterBeautyRef.current.value;
        const phoneBeauty = phoneBeautyRef.current.value;
        const profileBeauty = profileBeautyRef.current.value;
        //
        if(profileBeauty === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterBeauty, phoneBeauty);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseBeauty = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(true);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailBeauty = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(true);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryBeauty = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(true);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileBeauty = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(true);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Services products handler*************
    const subscribeServicesHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "services";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserServicesProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(true);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductServices = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserServicesProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(true);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryServices = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserServicesProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(true);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileServices = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserServicesProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(true);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactServices = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setServicesProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(true);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleServicesPhone = async () => {

        const prefixeInterServices = prefixeInterServicesRef.current.value;
        const phoneServices = phoneServicesRef.current.value;
        const profileServices = profileServicesRef.current.value;
        //
        if(profileServices === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterServices, phoneServices);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseServices = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(true);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailServices = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(true);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryServices = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(true);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileServices = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(true);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Electronic products handler*************
    const subscribeElectronicHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "electronic";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserElectronicProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(true);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductElectronic = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserElectronicProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(true);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryElectronic = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserElectronicProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(true);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileElectronic = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserElectronicProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(true);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactElectronic = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setElectronicProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(true);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleElectronicPhone = async () => {

        const prefixeInterElectronic = prefixeInterElectronicRef.current.value;
        const phoneElectronic = phoneElectronicRef.current.value;
        const profileElectronic = profileElectronicRef.current.value;
        //
        if(profileElectronic === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterElectronic, phoneElectronic);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseElectronic = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(true);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailElectronic = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(true);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryElectronic = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(true);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileElectronic = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(true);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Automotive products handler*************
    const subscribeAutomotiveHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "automotive";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserAutomotiveProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(true);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductAutomotive = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserAutomotiveProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(true);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryAutomotive = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserAutomotiveProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(true);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileAutomotive = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserAutomotiveProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(true);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactAutomotive = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setAutomotiveProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(true);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleAutomotivePhone = async () => {

        const prefixeInterAutomotive = prefixeInterAutomotiveRef.current.value;
        const phoneAutomotive = phoneAutomotiveRef.current.value;
        const profileAutomotive = profileAutomotiveRef.current.value;
        //
        if(profileAutomotive === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterAutomotive, phoneAutomotive);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseAutomotive = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(true);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailAutomotive = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(true);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryAutomotive = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(true);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileAutomotive = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(true);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Agriculture products handler*************
    const subscribeAgricultureHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "agriculture";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserAgricultureProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(true);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductAgriculture = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserAgricultureProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(true);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryAgriculture = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserAgricultureProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(true);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileAgriculture = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserAgricultureProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(true);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactAgriculture = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setAgricultureProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(true);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleAgriculturePhone = async () => {

        const prefixeInterAgriculture = prefixeInterAgricultureRef.current.value;
        const phoneAgriculture = phoneAgricultureRef.current.value;
        const profileAgriculture = profileAgricultureRef.current.value;
        //
        if(profileAgriculture === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterAgriculture, phoneAgriculture);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseAgriculture = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(true);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailAgriculture = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(true);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryAgriculture = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(true);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileAgriculture = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(true);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Breeding products handler*************
    const subscribeBreedingHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "elevage";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBreedingProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(true);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductBreeding = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBreedingProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(true);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryBreeding = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBreedingProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(true);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileBreeding = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserBreedingProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(true);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactBreeding = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setBreedingProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(true);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleBreedingPhone = async () => {

        const prefixeInterBreeding = prefixeInterBreedingRef.current.value;
        const phoneBreeding = phoneBreedingRef.current.value;
        const profileBreeding = profileBreedingRef.current.value;
        //
        if(profileBreeding === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterBreeding, phoneBreeding);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseBreeding = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(true);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailBreeding = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(true);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryBreeding = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(true);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileBreeding = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(true);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List House products handler*************
    const subscribeHouseHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "house";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserHouseProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(true);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductHouse = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserHouseProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(true);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryHouse = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserHouseProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(true);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileHouse = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserHouseProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(true);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactHouse = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setHouseProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(true);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleHousePhone = async () => {

        const prefixeInterHouse = prefixeInterHouseRef.current.value;
        const phoneHouse = phoneHouseRef.current.value;
        const profileHouse = profileHouseRef.current.value;
        //
        if(profileHouse === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterHouse, phoneHouse);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseHouse = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(true);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailHouse = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(true);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryHouse = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(true);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileHouse = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(true);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Sport products handler*************
    const subscribeSportHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "sport";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserSportProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(true);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductSport = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserSportProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(true);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategorySport = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserSportProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(true);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileSport = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserSportProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(true);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactSport = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setSportProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(true);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleSportPhone = async () => {

        const prefixeInterSport = prefixeInterSportRef.current.value;
        const phoneSport = phoneSportRef.current.value;
        const profileSport = profileSportRef.current.value;
        //
        if(profileSport === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterSport, phoneSport);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseSport = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(true);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailSport = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(true);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategorySport = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(true);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileSport = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(true);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    //***************List Decor products handler*************
    const subscribeDecorHandler = async (e) => {
        e.preventDefault();

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "decor";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserDecorProducts(response2.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(true);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProductDecor = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserDecorProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(true);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategoryDecor = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserDecorProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(true);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileDecor = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId; 
        
        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserDecorProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(true);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactDecor = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setDecorProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(true);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(false);
        });
    }
    //
    const handleDecorPhone = async () => {

        const prefixeInterDecor = prefixeInterDecorRef.current.value;
        const phoneDecor = phoneDecorRef.current.value;
        const profileDecor = profileDecorRef.current.value;
        //
        if(profileDecor === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterDecor, phoneDecor);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseDecor = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(true);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close button hanlder
    const handlerCloseDetailDecor = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(true);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseCategoryDecor = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(true);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseProfileDecor = async () => {

        //set the belonging inner states to true and the others to false
        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(true);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(false);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }


    /**XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
    const handleSubscribeSearch = async (e) => {
        e.preventDefault();

        // declare product variables
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultat = 0;
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        //
        var tableNameProfile = "";
        var signupTableProfile = [];
        //
        const variableOne = "p"; //product indent
        const variableTwo = "s"; //subscribe indent
        const variableThree = "f"; //profile indent
        //
        const productUserCode = "code_user";
        const subscribeUserCode = "code_user";
        //
        const columnName = "description_product";
        //var searchWords = searchTerm;
        const dateEndColumn = "date_end";
        const idColumn = "id_product";
        //*****Use those data to query the database after******
        var nameSearchDiv = document.getElementById('namesearch');
        nameSearchDiv.innerText = subscribeSearchRef.current.value;
        var nameSearch = document.getElementById('namesearch').innerText;
        var searchWords = nameSearch;
        //const searchWords = subscribeSearchRef.current.value;

        //search all product variable for pouchDB
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "date_end";
        const fourthWhere = "id_product";

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        if(!searchWords){
            const pTextePays = t("search_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            if(isOnline()){
                await Axios.post(`${apiUrl}/search/join/one/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    columnName:columnName,
                    searchWords:searchWords,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((response) => {
                    resultat = response.data.length;
                    if(resultat === 0){
                        setShowModal(true);
                    }else{
                        //search all product belonging to this country
                        Axios.post(`${apiUrl}/check/valid/subscriber/product/all`, {
                            tableNameOne:tableNameProduct,
                            variableOne:variableOne,
                            tableNameTwo:tableNameSubscribe,
                            variableTwo:variableTwo,
                            firstWhere:firstWhere,
                            secondWhere:secondWhere,
                            thirdWhere:thirdWhere,
                            fourthWhere:fourthWhere,
                            //limitNumber:limitNumber,
                        }).then((responseAll) => {
                            //Save data to PouchDB
                            //saveToPouchDB(responseAll.data);
                        });
                        //
                        //setSubscribeSearch(subscribeSearchRef.current.value);
                        //setSubscribeBuyerButtonModalOpen(true);
                        var nameSearchDiv = document.getElementById('namesearch');
                        nameSearchDiv.innerText = subscribeSearchRef.current.value;
                        var nameSearch = document.getElementById('namesearch').innerText;
                        setSubscribeSearch(nameSearch);
                        setSubscribeBuyerButtonModalOpen(true);
                        //search input
                        setSubscribeSearchModalOpen(true);
                        //set the belonging inner states to true and the others to false
                        //view all product on page mount
                        setShowAllProducts(false);
                        setShowAllDetail(false);
                        //view detail into list Image card
                        setViewDetailProduct(false);
                        setViewDetailCategory(false);
                        setViewDetailProfile(false);
                        setViewDetailContact(false);
                        //product
                        setProductsDetailModalOpen(false);
                        setCategoryDetailModalOpen(false);
                        setProfileDetailModalOpen(false);
                        //food
                        setProductsFoodDetailModalOpen(false);
                        setCategoryFoodDetailModalOpen(false);
                        setProfileFoodDetailModalOpen(false);
                        //beverage
                        setProductsBeverageDetailModalOpen(false);
                        setCategoryBeverageDetailModalOpen(false);
                        setProfileBeverageDetailModalOpen(false);
                        //apparel
                        setProductsApparelDetailModalOpen(false);
                        setCategoryApparelDetailModalOpen(false);
                        setProfileApparelDetailModalOpen(false);
                        //health
                        setProductsHealthDetailModalOpen(false);
                        setCategoryHealthDetailModalOpen(false);
                        setProfileHealthDetailModalOpen(false);
                        //beauty
                        setProductsBeautyDetailModalOpen(false);
                        setCategoryBeautyDetailModalOpen(false);
                        setProfileBeautyDetailModalOpen(false);
                        //services
                        setProductsServicesDetailModalOpen(false);
                        setCategoryServicesDetailModalOpen(false);
                        setProfileServicesDetailModalOpen(false);
                        //electronic
                        setProductsElectronicDetailModalOpen(false);
                        setCategoryElectronicDetailModalOpen(false);
                        setProfileElectronicDetailModalOpen(false);
                        //automotive
                        setProductsAutomotiveDetailModalOpen(false);
                        setCategoryAutomotiveDetailModalOpen(false);
                        setProfileAutomotiveDetailModalOpen(false);
                        //agriculture
                        setProductsAgricultureDetailModalOpen(false);
                        setCategoryAgricultureDetailModalOpen(false);
                        setProfileAgricultureDetailModalOpen(false);
                        //breeding
                        setProductsBreedingDetailModalOpen(false);
                        setCategoryBreedingDetailModalOpen(false);
                        setProfileBreedingDetailModalOpen(false);
                        //house
                        setProductsHouseDetailModalOpen(false);
                        setCategoryHouseDetailModalOpen(false);
                        setProfileHouseDetailModalOpen(false);
                        //sport
                        setProductsSportDetailModalOpen(false);
                        setCategorySportDetailModalOpen(false);
                        setProfileSportDetailModalOpen(false);
                        //decor
                        setProductsDecorDetailModalOpen(false);
                        setCategoryDecorDetailModalOpen(false);
                        setProfileDecorDetailModalOpen(false);
                        //
                        setScrollSubscribeModalOpen(true);
                        setNewAllProductsModalOpen(false);
                        setNewFoodProductsModalOpen(false);
                        setNewBeverageProductsModalOpen(false);
                        setNewApparelProductsModalOpen(false);
                        setNewHealthProductsModalOpen(false);
                        setNewBeautyProductsModalOpen(false);
                        setNewServiceProductsModalOpen(false);
                        setNewElectronicProductsModalOpen(false);
                        setNewAutomotiveProductsModalOpen(false);
                        setNewAgricultureProductsModalOpen(false);
                        setNewBreedingProductsModalOpen(false);
                        setNewHouseProductsModalOpen(false);
                        setNewSportProductsModalOpen(false);
                        setNewDecorProductsModalOpen(false);
                        //inner search
                        setCityModalOpen(false);
                        setNeighborhoodModalOpen(false);
                        setBestPricesModalOpen(false);
                        //search detail
                        setSearchResultModalOpen(false);
                        setProductsSearchDetailModalOpen(false);
                        setCategorySearchDetailModalOpen(false);
                        setProfileSearchDetailModalOpen(false);
                    }
                });
            }else{
                const pTextePays = t("offline_connection");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }
        }
    }

    const handleSubscribeCityFilter = async () => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultat = 0;
        const firstParameter = "city_user";
	    const variableOne = "s";
	    const userCodeOne = "code_user";
	    const idColumn = "id_profile";
        //
        var tableNameProduct = "";
        var signupTableProduct = [];
	    const variableTwo = "p";
	    const userCodeTwo = "code_user";
	    const columnName = "description_product";
	    var nameSearch = document.getElementById('namesearch').innerText;
	    const searchWords = nameSearch;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        if(isOnline()){
            await Axios.post(`${apiUrl}/select/distinct/join/one/two/tables`, {
                firstParameter:firstParameter,
                tableNameOne:tableNameProfile,
                variableOne:variableOne,
                userCodeOne:userCodeOne,
                tableNameTwo:tableNameProduct,
                variableTwo:variableTwo,
                userCodeTwo:userCodeTwo,
                columnName:columnName,
                searchWords:searchWords,
                idColumn:idColumn,
            }).then((responseCity) => {
                resultat = responseCity.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_data");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    //console.log(responseCity.data);
                    setListCity(responseCity.data);
                    setCityModalOpen(true);
                }
            });//
        }else{
            const pTextePays = t("offline_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }

    const handleSubscribeNeighbordFilter = async () => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultat = 0;
        const firstParameter = "quartier";
	    const variableOne = "s";
	    const userCodeOne = "code_user";
	    const idColumn = "id_profile";
        //
        var tableNameProduct = "";
        var signupTableProduct = [];
	    const variableTwo = "p";
	    const userCodeTwo = "code_user";
	    const columnName = "description_product";
	    var nameSearch = document.getElementById('namesearch').innerText;
	    const searchWords = nameSearch;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        if(isOnline()){
            await Axios.post(`${apiUrl}/select/distinct/join/one/two/tables`, {
                firstParameter:firstParameter,
                tableNameOne:tableNameProfile,
                variableOne:variableOne,
                userCodeOne:userCodeOne,
                tableNameTwo:tableNameProduct,
                variableTwo:variableTwo,
                userCodeTwo:userCodeTwo,
                columnName:columnName,
                searchWords:searchWords,
                idColumn:idColumn,
            }).then((responseNeighbord) => {
                resultat = responseNeighbord.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_data");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setListNeighbord(responseNeighbord.data);
                    setNeighborhoodModalOpen(true);
                }
            });
        }else{
            const pTextePays = t("offline_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }

    const handleSubscribeBestPriceFilter = async () => {

        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultat = 0;
        const firstParameter = "price_product";
		const columnName = "description_product";
		var nameSearch = document.getElementById('namesearch').innerText;
	    const searchWords = nameSearch;
		const idColumn = "id_product";

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table profile
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        if(isOnline()){
            await Axios.post(`${apiUrl}/select/distinct/price/one/table`, {
                firstParameter:firstParameter,
                tableNameOne:tableNameProduct,
                columnName:columnName,
                searchWords:searchWords,
                idColumn:idColumn,
            }).then((responsePrice) => {
                resultat = responsePrice.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_data");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    //console.log(responsePrice.data);
                    setListPrice(responsePrice.data);
                    setBestPricesModalOpen(true);
                }
            });
        }else{
            const pTextePays = t("offline_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }

    const handleModalClose = () => {
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
    };

    const handleModalCityConfirm = async () => {

        // Handle the input value from the modal
        // Apply the filter and update the product list state accordingly
        var nameCityDiv = document.getElementById('namecity');
        nameCityDiv.innerText = subscribeSearchCityRef.current.value;
        var nameCity = document.getElementById('namecity').innerText;
        setSubscribeSearchCity(EscapeApostrophe(nameCity));
        handleModalClose();
    }

    const handleModalNeighborhoodConfirm = async () => {

        // Handle the input value from the modal
        // Apply the filter and update the product list state accordingly
        var nameNeighborhoodDiv = document.getElementById('nameneighborhood');
        nameNeighborhoodDiv.innerText = subscribeSearchNeighborhoodRef.current.value;
        var nameNeighborhood = document.getElementById('nameneighborhood').innerText;
        setSubscribeSearchNeighborhood(EscapeApostrophe(nameNeighborhood));
        handleModalClose();
    }

    const handleModalPricesConfirm = async () => {

        // Handle the input value from the modal
        // Apply the filter and update the product list state accordingly
        var namePriceDiv = document.getElementById('nameprice');
        namePriceDiv.innerText = subscribeSearchPriceRef.current.value;
        var namePrice = document.getElementById('nameprice').innerText;
        setSubscribeSearchPrice(parseFloat(namePrice));
        handleModalClose();
    }

    //Search emplementation processes
    const handleGoSearchSubscribeBuyer = async () => {

        // declare product variables
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatSearch = 0;
        // declare subscribe variables 
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        //
        var tableNameProfile = "";
        var signupTableProfile = [];
        //
        const variableOne = "p"; //product indent
        const variableTwo = "s"; //subscribe indent
        const variableThree = "f"; //profile indent
        //
        const productUserCode = "code_user";
        const subscribeUserCode = "code_user";
        const profileUserCode = "code_user";
        //
        const columnName = "description_product";
        const dateEndColumn = "date_end";
        const idColumn = "id_product";
        //*****Use those data to query the database after******
        const searchWords = subscribeSearch;
        const searchCity = subscribeSearchCity;
        const searchNeighborhood = subscribeSearchNeighborhood;
        const searchPrice = subscribeSearchPrice;
        //const searchWords = noSubscribeSearch;
        //const searchCity = document.getElementById('namecity').innerText;
        //const searchNeighborhood = document.getElementById('nameneighborhood').innerText;
        //const searchPrice = document.getElementById('nameprice').innerText;

        //search join two fields
        const columnNameOne = "description_product";
        const searchWordsOne = subscribeSearch;
        const columnNameTwo = "quartier";
        const searchWordsTwo = searchNeighborhood;
        const columnNameThree = "price_product";
        const searchWordsThree = searchPrice;
        const searchWordsFour = searchCity;
        const columnNameFour = "city_user";
        
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        if(isOnline()){
            //use case
            //case 1 only search words (OK)
            if(searchWords && !searchCity && !searchNeighborhood && !searchPrice){
                await Axios.post(`${apiUrl}/search/join/one/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    columnName:columnName,
                    searchWords:EscapeApostrophe(searchWords),
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setConfirmSearchDetail(responseSearch.data);
                        //
                        setUserSearchProducts(responseSearch.data);
                        
                    }
                });
            }
            //case 2 using searchNeighborhood
            if(searchWords && !searchCity && searchNeighborhood && !searchPrice){
                await Axios.post(`${apiUrl}/search/join/two/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameTwo,
                    searchWordsTwo:EscapeApostrophe(searchWordsTwo),
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    console.log(responseSearch.data);
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setConfirmSearchDetail(responseSearch.data);
                        //
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 3 using searchNeighborhood & price
            if(searchWords && !searchCity && searchNeighborhood && searchPrice){
            
                await Axios.post(`${apiUrl}/search/join/three/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameTwo,
                    searchWordsTwo:EscapeApostrophe(searchWordsTwo),
                    columnNameThree:columnNameThree,
                    searchWordsThree:searchWordsThree,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setConfirmSearchDetail(responseSearch.data);
                        //
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 4 using only city (OK)
            if(searchWords && searchCity && !searchNeighborhood && !searchPrice){
                await Axios.post(`${apiUrl}/search/join/two/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameFour,
                    searchWordsTwo:EscapeApostrophe(searchWordsFour),
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setConfirmSearchDetail(responseSearch.data);
                        //
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 5 using city & neiboborhood (OK)
            if(searchWords && searchCity && searchNeighborhood && !searchPrice){
                await Axios.post(`${apiUrl}/search/join/city/neighbord/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameTwo,
                    searchWordsTwo:EscapeApostrophe(searchWordsTwo),
                    columnNameFour:columnNameFour,
                    searchWordsFour:EscapeApostrophe(searchWordsFour),
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setConfirmSearchDetail(responseSearch.data);
                        //
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 6 using city & neighbordhood & price OK
            if(searchWords && searchCity && searchNeighborhood && searchPrice){
            
                await Axios.post(`${apiUrl}/search/join/city/neighbord/price/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameTwo,
                    searchWordsTwo:EscapeApostrophe(searchWordsTwo),
                    columnNameFour:columnNameFour,
                    searchWordsFour:EscapeApostrophe(searchWordsFour),
                    columnNamePrice: columnNameThree,
                    searchWordsPrice:searchWordsThree,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setConfirmSearchDetail(responseSearch.data);
                        //
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 7 using only price (OK)
            if(searchWords && !searchCity && !searchNeighborhood && searchPrice){
            
                await Axios.post(`${apiUrl}/search/join/price/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:searchWordsOne,
                    columnNameThree:columnNameThree,
                    searchWordsThree:searchWordsThree,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setConfirmSearchDetail(responseSearch.data);
                        //
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 8 using city & price (OK)
            if(searchWords && searchCity && !searchNeighborhood && searchPrice){
            
                await Axios.post(`${apiUrl}/search/join/three/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameFour,//
                    searchWordsTwo:EscapeApostrophe(searchWordsFour),//
                    columnNameThree:columnNameThree,
                    searchWordsThree:searchWordsThree,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setConfirmSearchDetail(responseSearch.data);
                        //
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //set states
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(true);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
        }else{
            const pTextePays = t("offline_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }
    //suite pagination
    const nPages = Math.ceil(confirmSearchDetail.length / recordsPerPage);
    const itemsPerPage = 10;

    //search
    const handleProductSearch = async (productId) => {

        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatProduct = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        const firstWhere = "code_user";
        const firstParameter = productId;

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProduct,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProd) => {
            resultatProduct = responseProd.data.length;
            if(resultatProduct === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                //
                setSearchProductsDetail(responseProd.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(true);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleCategorySearch = async (categoryId) => {

        var tableNameCategory = "";
        var signupTableCategory = [];
        var resultatCategory = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableCategory = retrieveTableQuery(codecountryDiv);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }

        const firstWhere = "code_user";
        const firstParameter = categoryId;

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameCategory,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseCat) => {
            resultatCategory = responseCat.data.length;
            if(resultatCategory === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                //
                setSearchProductsCategory(responseCat.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(true);
                setProfileSearchDetailModalOpen(false);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleProfileSearch = async (profileId) => {

        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultatProfile = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        const firstWhere = "code_user";
        const firstParameter = profileId;

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableNameProfile,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((responseProf) => {
            resultatProfile = responseProf.data.length;
            if(resultatProfile === 0){
                const pTextePays = t("error_detail");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                //
                setSearchProductsProfile(responseProf.data);
                //set the belonging inner states to true and the others to false
                //view all product on page mount
                setShowAllProducts(false);
                setShowAllDetail(false);
                //view detail into list Image card
                setViewDetailProduct(false);
                setViewDetailCategory(false);
                setViewDetailProfile(false);
                setViewDetailContact(false);
                //product
                setProductsDetailModalOpen(false);
                setCategoryDetailModalOpen(false);
                setProfileDetailModalOpen(false);
                setContactDetailModalOpen(false);
                //food
                setProductsFoodDetailModalOpen(false);
                setCategoryFoodDetailModalOpen(false);
                setProfileFoodDetailModalOpen(false);
                setContactFoodModalOpen(false);
                //beverage
                setProductsBeverageDetailModalOpen(false);
                setCategoryBeverageDetailModalOpen(false);
                setProfileBeverageDetailModalOpen(false);
                setContactBeverageModalOpen(false);
                //apparel
                setProductsApparelDetailModalOpen(false);
                setCategoryApparelDetailModalOpen(false);
                setProfileApparelDetailModalOpen(false);
                setContactApparelModalOpen(false);
                //health
                setProductsHealthDetailModalOpen(false);
                setCategoryHealthDetailModalOpen(false);
                setProfileHealthDetailModalOpen(false);
                setContactHealthModalOpen(false);
                //beauty
                setProductsBeautyDetailModalOpen(false);
                setCategoryBeautyDetailModalOpen(false);
                setProfileBeautyDetailModalOpen(false);
                setContactBeautyModalOpen(false);
                //services
                setProductsServicesDetailModalOpen(false);
                setCategoryServicesDetailModalOpen(false);
                setProfileServicesDetailModalOpen(false);
                setContactServicesModalOpen(false);
                //electronic
                setProductsElectronicDetailModalOpen(false);
                setCategoryElectronicDetailModalOpen(false);
                setProfileElectronicDetailModalOpen(false);
                setContactElectronicModalOpen(false);
                //automotive
                setProductsAutomotiveDetailModalOpen(false);
                setCategoryAutomotiveDetailModalOpen(false);
                setProfileAutomotiveDetailModalOpen(false);
                setContactAutomotiveModalOpen(false);
                //agriculture
                setProductsAgricultureDetailModalOpen(false);
                setCategoryAgricultureDetailModalOpen(false);
                setProfileAgricultureDetailModalOpen(false);
                setContactAgricultureModalOpen(false);
                //breeding
                setProductsBreedingDetailModalOpen(false);
                setCategoryBreedingDetailModalOpen(false);
                setProfileBreedingDetailModalOpen(false);
                setContactBreedingModalOpen(false);
                //house
                setProductsHouseDetailModalOpen(false);
                setCategoryHouseDetailModalOpen(false);
                setProfileHouseDetailModalOpen(false);
                setContactHouseModalOpen(false);
                //sport
                setProductsSportDetailModalOpen(false);
                setCategorySportDetailModalOpen(false);
                setProfileSportDetailModalOpen(false);
                setContactSportModalOpen(false);
                //decor
                setProductsDecorDetailModalOpen(false);
                setCategoryDecorDetailModalOpen(false);
                setProfileDecorDetailModalOpen(false);
                setContactDecorModalOpen(false);
                //
                setScrollSubscribeModalOpen(true);
                setNewAllProductsModalOpen(false);
                setNewFoodProductsModalOpen(false);
                setNewBeverageProductsModalOpen(false);
                setNewApparelProductsModalOpen(false);
                setNewHealthProductsModalOpen(false);
                setNewBeautyProductsModalOpen(false);
                setNewServiceProductsModalOpen(false);
                setNewElectronicProductsModalOpen(false);
                setNewAutomotiveProductsModalOpen(false);
                setNewAgricultureProductsModalOpen(false);
                setNewBreedingProductsModalOpen(false);
                setNewHouseProductsModalOpen(false);
                setNewSportProductsModalOpen(false);
                setNewDecorProductsModalOpen(false);
                //search input
                setSubscribeSearchModalOpen(false);
                //inner search
                setCityModalOpen(false);
                setNeighborhoodModalOpen(false);
                setBestPricesModalOpen(false);
                //search detail
                setSearchResultModalOpen(false);
                setProductsSearchDetailModalOpen(false);
                setCategorySearchDetailModalOpen(false);
                setProfileSearchDetailModalOpen(true);
                setContactSearchDetailModalOpen(false);
            }
        });
    }
    //
    const handleContactSearch = async (contactId) => {

        var tableNameSeller = "";
        var signupTableSeller = [];
        //var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = contactId;
        const productUserCode = contactId;
        const profileUserCode = contactId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(codecountryDiv);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(codecountryDiv);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //join these three tables to retrieve specific data
        await Axios.post(`${apiUrl}/search/join/three/tables`, {
            tableNameSeller:tableNameSeller,
            variableSeller:variableOne,
            columnNameOne:columnNameOne,
            sellerUserCode:sellerUserCode,
            tableNameProduct:tableNameProduct,
            variableProduct:variableTwo,
            columnNameTwo:columnNameTwo,
            productUserCode:productUserCode,
            tableNameProfile:tableNameProfile,
            variableProfile:variableThree,
            columnNameThree:columnNameThree,
            profileUserCode:profileUserCode,
        }).then((response3) => {
            setSearchProductsContact(response3.data);
            //set the belonging inner states to true and the others to false
            //view all product on page mount
            setShowAllProducts(false);
            setShowAllDetail(false);
            //view detail into list Image card
            setViewDetailProduct(false);
            setViewDetailCategory(false);
            setViewDetailProfile(false);
            setViewDetailContact(false);
            //product
            setProductsDetailModalOpen(false);
            setCategoryDetailModalOpen(false);
            setProfileDetailModalOpen(false);
            setContactDetailModalOpen(false);
            //food
            setProductsFoodDetailModalOpen(false);
            setCategoryFoodDetailModalOpen(false);
            setProfileFoodDetailModalOpen(false);
            setContactFoodModalOpen(false);
            //beverage
            setProductsBeverageDetailModalOpen(false);
            setCategoryBeverageDetailModalOpen(false);
            setProfileBeverageDetailModalOpen(false);
            setContactBeverageModalOpen(false);
            //apparel
            setProductsApparelDetailModalOpen(false);
            setCategoryApparelDetailModalOpen(false);
            setProfileApparelDetailModalOpen(false);
            setContactApparelModalOpen(false);
            //health
            setProductsHealthDetailModalOpen(false);
            setCategoryHealthDetailModalOpen(false);
            setProfileHealthDetailModalOpen(false);
            setContactHealthModalOpen(false);
            //beauty
            setProductsBeautyDetailModalOpen(false);
            setCategoryBeautyDetailModalOpen(false);
            setProfileBeautyDetailModalOpen(false);
            setContactBeautyModalOpen(false);
            //services
            setProductsServicesDetailModalOpen(false);
            setCategoryServicesDetailModalOpen(false);
            setProfileServicesDetailModalOpen(false);
            setContactServicesModalOpen(false);
            //electronic
            setProductsElectronicDetailModalOpen(false);
            setCategoryElectronicDetailModalOpen(false);
            setProfileElectronicDetailModalOpen(false);
            setContactElectronicModalOpen(false);
            //automotive
            setProductsAutomotiveDetailModalOpen(false);
            setCategoryAutomotiveDetailModalOpen(false);
            setProfileAutomotiveDetailModalOpen(false);
            setContactAutomotiveModalOpen(false);
            //agriculture
            setProductsAgricultureDetailModalOpen(false);
            setCategoryAgricultureDetailModalOpen(false);
            setProfileAgricultureDetailModalOpen(false);
            setContactAgricultureModalOpen(false);
            //breeding
            setProductsBreedingDetailModalOpen(false);
            setCategoryBreedingDetailModalOpen(false);
            setProfileBreedingDetailModalOpen(false);
            setContactBreedingModalOpen(false);
            //house
            setProductsHouseDetailModalOpen(false);
            setCategoryHouseDetailModalOpen(false);
            setProfileHouseDetailModalOpen(false);
            setContactHouseModalOpen(false);
            //sport
            setProductsSportDetailModalOpen(false);
            setCategorySportDetailModalOpen(false);
            setProfileSportDetailModalOpen(false);
            setContactSportModalOpen(false);
            //decor
            setProductsDecorDetailModalOpen(false);
            setCategoryDecorDetailModalOpen(false);
            setProfileDecorDetailModalOpen(false);
            setContactDecorModalOpen(false);
            //
            setScrollSubscribeModalOpen(true);
            setNewAllProductsModalOpen(false);
            setNewFoodProductsModalOpen(false);
            setNewBeverageProductsModalOpen(false);
            setNewApparelProductsModalOpen(false);
            setNewHealthProductsModalOpen(false);
            setNewBeautyProductsModalOpen(false);
            setNewServiceProductsModalOpen(false);
            setNewElectronicProductsModalOpen(false);
            setNewAutomotiveProductsModalOpen(false);
            setNewAgricultureProductsModalOpen(false);
            setNewBreedingProductsModalOpen(false);
            setNewHouseProductsModalOpen(false);
            setNewSportProductsModalOpen(false);
            setNewDecorProductsModalOpen(false);
            //search input
            setSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(false);
            setProductsSearchDetailModalOpen(false);
            setCategorySearchDetailModalOpen(false);
            setProfileSearchDetailModalOpen(false);
            setContactSearchDetailModalOpen(true);
        });
    }
    //
    const handleSearchPhone = async () => {

        const prefixeInterSearch = prefixeInterSearchRef.current.value;
        const phoneSearch = phoneSearchRef.current.value;
        const profileSearch = profileSearchRef.current.value;
        //
        if(profileSearch === 'private'){
            setShowModalContact(true);
        }else{
            WhatsappCall(prefixeInterSearch, phoneSearch);
        }
    }
    //these close handler are used for all close buttons
    const handlerCloseSearch = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(true);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //close search
    const handlerCloseSearchDetail = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(true);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseSearchCategory = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(true);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }
    //
    const handlerCloseSearchProfile = async () => {

        //view all product on page mount
        setShowAllProducts(false);
        setShowAllDetail(false);
        //view detail into list Image card
        setViewDetailProduct(false);
        setViewDetailCategory(false);
        setViewDetailProfile(false);
        setViewDetailContact(false);
        //product
        setProductsDetailModalOpen(false);
        setCategoryDetailModalOpen(false);
        setProfileDetailModalOpen(false);
        setContactDetailModalOpen(false);
        //food
        setProductsFoodDetailModalOpen(false);
        setCategoryFoodDetailModalOpen(false);
        setProfileFoodDetailModalOpen(false);
        setContactFoodModalOpen(false);
        //beverage
        setProductsBeverageDetailModalOpen(false);
        setCategoryBeverageDetailModalOpen(false);
        setProfileBeverageDetailModalOpen(false);
        setContactBeverageModalOpen(false);
        //apparel
        setProductsApparelDetailModalOpen(false);
        setCategoryApparelDetailModalOpen(false);
        setProfileApparelDetailModalOpen(false);
        setContactApparelModalOpen(false);
        //health
        setProductsHealthDetailModalOpen(false);
        setCategoryHealthDetailModalOpen(false);
        setProfileHealthDetailModalOpen(false);
        setContactHealthModalOpen(false);
        //beauty
        setProductsBeautyDetailModalOpen(false);
        setCategoryBeautyDetailModalOpen(false);
        setProfileBeautyDetailModalOpen(false);
        setContactBeautyModalOpen(false);
        //services
        setProductsServicesDetailModalOpen(false);
        setCategoryServicesDetailModalOpen(false);
        setProfileServicesDetailModalOpen(false);
        setContactServicesModalOpen(false);
        //electronic
        setProductsElectronicDetailModalOpen(false);
        setCategoryElectronicDetailModalOpen(false);
        setProfileElectronicDetailModalOpen(false);
        setContactElectronicModalOpen(false);
        //automotive
        setProductsAutomotiveDetailModalOpen(false);
        setCategoryAutomotiveDetailModalOpen(false);
        setProfileAutomotiveDetailModalOpen(false);
        setContactAutomotiveModalOpen(false);
        //agriculture
        setProductsAgricultureDetailModalOpen(false);
        setCategoryAgricultureDetailModalOpen(false);
        setProfileAgricultureDetailModalOpen(false);
        setContactAgricultureModalOpen(false);
        //breeding
        setProductsBreedingDetailModalOpen(false);
        setCategoryBreedingDetailModalOpen(false);
        setProfileBreedingDetailModalOpen(false);
        setContactBreedingModalOpen(false);
        //house
        setProductsHouseDetailModalOpen(false);
        setCategoryHouseDetailModalOpen(false);
        setProfileHouseDetailModalOpen(false);
        setContactHouseModalOpen(false);
        //sport
        setProductsSportDetailModalOpen(false);
        setCategorySportDetailModalOpen(false);
        setProfileSportDetailModalOpen(false);
        setContactSportModalOpen(false);
        //decor
        setProductsDecorDetailModalOpen(false);
        setCategoryDecorDetailModalOpen(false);
        setProfileDecorDetailModalOpen(false);
        setContactDecorModalOpen(false);
        //
        setScrollSubscribeModalOpen(true);
        setNewAllProductsModalOpen(false);
        setNewFoodProductsModalOpen(false);
        setNewBeverageProductsModalOpen(false);
        setNewApparelProductsModalOpen(false);
        setNewHealthProductsModalOpen(false);
        setNewBeautyProductsModalOpen(false);
        setNewServiceProductsModalOpen(false);
        setNewElectronicProductsModalOpen(false);
        setNewAutomotiveProductsModalOpen(false);
        setNewAgricultureProductsModalOpen(false);
        setNewBreedingProductsModalOpen(false);
        setNewHouseProductsModalOpen(false);
        setNewSportProductsModalOpen(false);
        setNewDecorProductsModalOpen(false);
        //search input
        setSubscribeSearchModalOpen(false);
        //inner search
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
        //search detail
        setSearchResultModalOpen(true);
        setProductsSearchDetailModalOpen(false);
        setCategorySearchDetailModalOpen(false);
        setProfileSearchDetailModalOpen(false);
        setContactSearchDetailModalOpen(false);
    }

    //warning
    const handleCancelSearch = async () => {
        //clear all ref based on city, neighborhood and price
        var searchName = "";
        var cityName = "";
        var neighborhood = "";
        var pricename = "";
        var searchNameDiv = document.getElementById('namesearch');
        searchNameDiv.innerText = searchName;
        var nameCityDiv = document.getElementById('namecity');
        nameCityDiv.innerText = cityName;
        var nameNeighborhoodDiv = document.getElementById('nameneighborhood');
        nameNeighborhoodDiv.innerText = neighborhood;
        var namePriceDiv = document.getElementById('nameprice');
        namePriceDiv.innerText = pricename;
        //
        HideFooter();
        setShowModal(false);
        fetchNewProducts();
    }

    // contact
    const handleCancelContact = async () => {
        //
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;
        //clear all ref based on city, neighborhood and price
        var searchName = "";
        var cityName = "";
        var neighborhood = "";
        var pricename = "";
        var searchNameDiv = document.getElementById('namesearch');
        searchNameDiv.innerText = searchName;
        var nameCityDiv = document.getElementById('namecity');
        nameCityDiv.innerText = cityName;
        var nameNeighborhoodDiv = document.getElementById('nameneighborhood');
        nameNeighborhoodDiv.innerText = neighborhood;
        var namePriceDiv = document.getElementById('nameprice');
        namePriceDiv.innerText = pricename;
        //
        setShowModalClose(false);
        HideFooter();
        navigateSubscribeBuyer('/starting');
    }

    //close page
    const handleClosePage = async () => {
        //
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;
        //clear all ref based on city, neighborhood and price
        var searchName = "";
        var cityName = "";
        var neighborhood = "";
        var pricename = "";
        var searchNameDiv = document.getElementById('namesearch');
        searchNameDiv.innerText = searchName;
        var nameCityDiv = document.getElementById('namecity');
        nameCityDiv.innerText = cityName;
        var nameNeighborhoodDiv = document.getElementById('nameneighborhood');
        nameNeighborhoodDiv.innerText = neighborhood;
        var namePriceDiv = document.getElementById('nameprice');
        namePriceDiv.innerText = pricename;
        //
        setShowModalClose(false);
        HideFooter();
        navigateSubscribeBuyer('/starting');
    }
    //
    const handleCancelPage = async () => {

        //
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;
        //clear all ref based on city, neighborhood and price
        var searchName = "";
        var cityName = "";
        var neighborhood = "";
        var pricename = "";
        var searchNameDiv = document.getElementById('namesearch');
        searchNameDiv.innerText = searchName;
        var nameCityDiv = document.getElementById('namecity');
        nameCityDiv.innerText = cityName;
        var nameNeighborhoodDiv = document.getElementById('nameneighborhood');
        nameNeighborhoodDiv.innerText = neighborhood;
        var namePriceDiv = document.getElementById('nameprice');
        namePriceDiv.innerText = pricename;
        //
        setShowModalClose(false);
        //recall function
        fetchNewProducts();
    }

    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    const handleGoBackSubscribe = async () => {
        
        setShowModalClose(true);
    }

    return (
        <div id='subscribebuyer'>
            <br/>
            <div>
                <div style={{display:'flex', flexDirection:'row', marginLeft:'10px', justifyContent:'space-between'}}>
                    <div style={{display:'block',textAlign:'center'}}>
                        <AutoCompleteSearchOne 
                            type="text"
                            name="search-bar"
                            id="search-bar"
                            placeholder={t("search_placeholder")}
                            ref={subscribeSearchRef}
                            defaultValue={prefix}
                            onChange={onChange}
                            onKeyDown={handleKeyDown}
                        />
                        <AutoCompleteSearchTwo 
                            type="text"
                            name="search-bar"
                            id="search-bar2"
                            value={suggestion}
                            readOnly
                        />
                    </div>
                    <div>
                        <MdOutlineSearch style={{color:'#246175', fontSize:'35px'}} onClick={handleSubscribeSearch} />
                    </div>
                </div>
                <HandlerContainerDiv>
                    <div style={{color:'#246175', fontSize:'17px', display:'none'}} id='namesearch'></div>
                    <div style={{color:'#246175', fontSize:'17px'}} id='namecity'></div>
                    <div style={{color:'#246175', fontSize:'17px'}} id='nameneighborhood'></div>
                    <div style={{color:'#246175', fontSize:'17px'}} id='nameprice'></div>
                </HandlerContainerDiv>
            </div>
            <TermeScroller>
                <br/>
                {scrollSubscribeModalOpen && (
                    <div>
                        <div className="accueilmenu">
                            <div className="scrollermenu">
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeAllHandler}>{t("cat_all")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeFoodHandler}>{t("cat_food")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeBeverageHandler}>{t("cat_beverage")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeApparelHandler}>{t("cat_apparel")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeHealthHandler}>{t("cat_health")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeBeautyHandler}>{t("cat_beauty")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeServicesHandler}>{t("cat_services")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeElectronicHandler}>{t("cat_electronic")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeAutomotiveHandler}>{t("cat_automotive")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeAgricultureHandler}>{t("cat_agriculture")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeBreedingHandler}>{t("cat_breeding")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeHouseHandler}>{t("cat_house")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeSportHandler}>{t("cat_sport")}</AccueilButton>
                                </div>
                                <div style={{marginRight:'15px'}}>
                                    <AccueilButton onClick={subscribeDecorHandler}>{t("cat_decor")}</AccueilButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/**show all 30 new products */}
                {showAllProducts && (
                    <div>
                        {viewAllProducts.map((valall, indexall) => (
                            <ModalUser key={indexall}>
                                <ListImageCardSubscriber
                                    url={valall.image_product}
                                    onImageClick={() => handleAllImage(valall.code_user)}
                                    title={valall.name_product}
                                    text={valall.description_product}
                                    onButtonClickOne={() => handleAllProduct(valall.code_user)}
                                    onButtonClickTwo={() => handleAllCategory(valall.code_user)}
                                    onButtonClickThree={() => handleAllProfile(valall.code_user)}
                                    onButtonClickFour={() => handleAllDetailPhone(valall.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}

                {showAllDetail && (
                    <div>
                        {viewAllDetailProfile.map((valdetail, indexdetail) => (
                            <ModalUser key={indexdetail}>
                                <ViewAllDetailCard 
                                    onButtonClickClose={() => handlerCloseAllProfileDetail()}
                                    denomination={valdetail.profile_principal}
                                    secteur={t(TranslateWord(valdetail.secteur))}
                                    description={valdetail.profile_description}
                                    modeVente={t(TranslateWord(valdetail.mode_vente))}
                                    contact={t(TranslateWord(valdetail.profile_contact))}
                                    pays={NomPays(valdetail.code_pays)}
                                    ville={valdetail.city_user}
                                    village={valdetail.village}
                                    quartier={valdetail.quartier}
                                    localisation={valdetail.localisation}
                                />
                            </ModalUser>
                        ))}

                        {viewAllDetailProduct.map((valallproduct, indexallproduct) => (
                            <ModalUser key={indexallproduct}>
                                <CardProduct 
                                    onButtonClickClose={() => handlerCloseAllDetailProduct()}
                                    url={valallproduct.image_product}
                                    title={valallproduct.name_product}
                                    textDescription={valallproduct.description_product}
                                    textPrice={valallproduct.price_product}
                                    textCurrency={valallproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                        
                        {viewAllDetailCategory.map((valallcategory, indexallcategory) => (
                            <ModalUser key={indexallcategory}>
                                <CardCategory 
                                    onButtonClickClose={() => handlerCloseAllDetailCategory()}
                                    url={valallcategory.image_product}
                                    title={valallcategory.name_product}
                                    textDescription={valallcategory.description_product}
                                    textPrice={valallcategory.price_product}
                                    textCurrency={valallcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}

                {/** view detail into list Image card*/}
                {viewDetailProduct && (
                    <div>
                        {viewProductData.map((valdetaildata, indexvaldetaildata) => (
                            <ModalUser key={indexvaldetaildata}>
                                <ListImageDetailCard 
                                    url={valdetaildata.image_product}
                                    onButtonClickClose={() => handlerCloseDetailProduct()}
                                    title={valdetaildata.name_product}
                                    textDescription={valdetaildata.description_product}
                                    textPrice={valdetaildata.price_product}
                                    textCurrency={valdetaildata.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}

                {viewDetailCategory && (
                    <div>
                        {viewCategoryData.map((valdetaildata, indexvaldetaildata) => (
                            <ModalUser key={indexvaldetaildata}>
                                <ListImageDetailCard 
                                    url={valdetaildata.image_product}
                                    onButtonClickClose={() => handlerCloseDetailCategory()}
                                    title={valdetaildata.name_product}
                                    textDescription={valdetaildata.description_product}
                                    textPrice={valdetaildata.price_product}
                                    textCurrency={valdetaildata.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}

                {viewDetailProfile && (
                    <div>
                        {viewProfileData.map((valdetaildata, indexvaldetaildata) => (
                            <ModalUser key={indexvaldetaildata}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseDetailProfile()}
                                    denomination={valdetaildata.profile_principal}
                                    secteur={t(TranslateWord(valdetaildata.secteur))}
                                    description={valdetaildata.profile_description}
                                    modeVente={t(TranslateWord(valdetaildata.mode_vente))}
                                    contact={t(TranslateWord(valdetaildata.profile_contact))}
                                    pays={NomPays(valdetaildata.code_pays)}
                                    ville={valdetaildata.city_user}
                                    village={valdetaildata.village}
                                    quartier={valdetaildata.quartier}
                                    localisation={valdetaildata.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}

                {viewDetailContact && (
                    <div>
                        {viewContactData.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseDetailContact()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleDetailContact()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterAllDetailRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneAllDetailRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileAllDetailRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                
                {subscribeSearchModalOpen && (
                    <div>
                        {subscribeBuyerButtonModalOpen && (
                            <div>
                                <ul style={{color:'#3366ff'}}>
                                    <li>{t("filter_search_texte1")}</li>
                                </ul>
                                <HandlerContainerDiv>
                                    <div>
                                        <BazaliButton onClick={handleSubscribeCityFilter}>{t("filter_city")}</BazaliButton>
                                    </div>
                                    <div>
                                        <BazaliButton onClick={handleSubscribeNeighbordFilter}>{t("filter_neighborhood")}</BazaliButton>
                                    </div>
                                    <div>
                                        <BazaliButton onClick={handleSubscribeBestPriceFilter}>{t("filter_best_prices")}</BazaliButton>
                                    </div>
                                    <div>
                                        <PlayButtonSearch onClick={handleGoSearchSubscribeBuyer}></PlayButtonSearch>
                                    </div>
                                </HandlerContainerDiv>
                            </div>
                        )}
                    </div>
                )}

                {/* City Filter Modal */}
                {cityModalOpen && (
                    <ModalFilter>
                        <TextLogin>{t("filter_city")}</TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <div>
                                <SmallSelectBox ref={subscribeSearchCityRef}>
                                    {listcity.map((valcity, indexcity) => (
                                        <option key={indexcity} value={valcity.city_user}>{valcity.city_user}</option>
                                    ))}
                                </SmallSelectBox>
                            </div>
                        </div>
                        <br/>
                        <HandlerContainerDiv>
                            <div>
                                <ConfirmButton onClick={handleModalCityConfirm}>{t("confirm_button")}</ConfirmButton>
                            </div>
                            <div>
                                <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                            </div>
                        </HandlerContainerDiv>
                    </ModalFilter>
                )}

                {/* Neighborhood Filter Modal */}
                {neighborhoodModalOpen && (
                    <ModalFilter>
                        <TextLogin>{t("filter_neighborhood")}</TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <div>
                                <SmallSelectBox ref={subscribeSearchNeighborhoodRef}>
                                    {listNeighbord.map((valneighbord, indexneighbord) => (
                                        <option key={indexneighbord} value={valneighbord.quartier}>{valneighbord.quartier}</option>
                                    ))}
                                </SmallSelectBox>
                            </div>
                        </div>
                        <br/>
                        <HandlerContainerDiv>
                            <div>
                                <ConfirmButton onClick={handleModalNeighborhoodConfirm}>{t("confirm_button")}</ConfirmButton>
                            </div>
                            <div>
                                <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                            </div>
                        </HandlerContainerDiv>
                    </ModalFilter>
                )}

                {/* Best Prices Filter Modal */}
                {bestPricesModalOpen && (
                    <ModalFilter>
                        <TextLogin>{t("filter_best_prices")}</TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <div>
                                <SmallSelectBox ref={subscribeSearchPriceRef}>
                                    {listPrice.map((valprice, indexprice) => (
                                        <option key={indexprice} value={valprice.price_product}>{valprice.price_product}</option>
                                    ))}
                                </SmallSelectBox>
                            </div>
                        </div>
                        <br/>
                        <HandlerContainerDiv>
                            <div>
                                <ConfirmButton onClick={handleModalPricesConfirm}>{t("confirm_button")}</ConfirmButton>
                            </div>
                            <div>
                                <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                            </div>
                        </HandlerContainerDiv>
                    </ModalFilter>
                )}

                {/* Render the list of products */}
                {/**new all products */}
                {newAllProductsModalOpen && (
                    <div>
                        {userAllProducts.map((valall, indexall) => (
                            <ModalUser key={indexall}>
                                <ListImageCardSubscriber
                                    url={valall.image_product}
                                    onImageClick={() => handleAllImage(valall.code_user)}
                                    title={valall.name_product}
                                    text={valall.description_product}
                                    onButtonClickOne={() => handleProduct(valall.code_user)}
                                    onButtonClickTwo={() => handleCategory(valall.code_user)}
                                    onButtonClickThree={() => handleProfile(valall.code_user)}
                                    onButtonClickFour={() => handleContact(valall.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsDetailModalOpen && (
                    <div>
                        {userAllProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetail()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryDetailModalOpen && (
                    <div>
                        {userAllProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategory()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileDetailModalOpen && (
                    <div>
                        {userAllProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfile()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactDetailModalOpen && (
                    <div>
                        {userAllProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseAll()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleAllPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterAllRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneAllRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileAllRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new foods products */}
                {newFoodProductsModalOpen && (
                    <div>
                        {userFoodProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductFood(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryFood(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileFood(valfood.code_user)}
                                    onButtonClickFour={() => handleContactFood(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsFoodDetailModalOpen && (
                    <div>
                        {userFoodProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetailFood()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryFoodDetailModalOpen && (
                    <div>
                        {userFoodProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryFood()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileFoodDetailModalOpen && (
                    <div>
                        {userFoodProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileFood()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactFoodModalOpen && (
                    <div>
                        {userFoodProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseFood()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleFoodPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterFoodRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneFoodRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileFoodRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new beverage products */}
                {newBeverageProductsModalOpen && (
                    <div>
                        {userBeverageProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductBeverage(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryBeverage(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileBeverage(valfood.code_user)}
                                    onButtonClickFour={() => handleContactBeverage(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsBeverageDetailModalOpen && (
                    <div>
                        {userBeverageProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetailBeverage()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryBeverageDetailModalOpen && (
                    <div>
                        {userBeverageProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryBeverage()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileBeverageDetailModalOpen && (
                    <div>
                        {userBeverageProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileBeverage()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactBeverageModalOpen && (
                    <div>
                        {userBeverageProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseBeverage()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleBeveragePhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterBeverageRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneBeverageRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileBeverageRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new apparel products */}
                {newApparelProductsModalOpen && (
                    <div>
                        {userApparelProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductApparel(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryApparel(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileApparel(valfood.code_user)}
                                    onButtonClickFour={() => handleContactApparel(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsApparelDetailModalOpen && (
                    <div>
                        {userApparelProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    onButtonClickClose={() => handlerCloseDetailApparel()}
                                    url={valproduct.image_product}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryApparelDetailModalOpen && (
                    <div>
                        {userApparelProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryApparel()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileApparelDetailModalOpen && (
                    <div>
                        {userApparelProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileApparel()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactApparelModalOpen && (
                    <div>
                        {userApparelProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseApparel()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleApparelPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterApparelRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneApparelRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileApparelRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new health products */}
                {newHealthProductsModalOpen && (
                    <div>
                        {userHealthProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductHealth(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryHealth(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileHealth(valfood.code_user)}
                                    onButtonClickFour={() => handleContactHealth(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsHealthDetailModalOpen && (
                    <div>
                        {userHealthProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard
                                    url={valproduct.image_product} 
                                    onButtonClickClose={() => handlerCloseDetailHealth()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryHealthDetailModalOpen && (
                    <div>
                        {userHealthProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryHealth()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileHealthDetailModalOpen && (
                    <div>
                        {userHealthProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileHealth()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactHealthModalOpen && (
                    <div>
                        {userHealthProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseHealth()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleHealthPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterHealthRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneHealthRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileHealthRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new beauty products */}
                {newBeautyProductsModalOpen && (
                    <div>
                        {userBeautyProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductBeauty(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryBeauty(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileBeauty(valfood.code_user)}
                                    onButtonClickFour={() => handleContactBeauty(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsBeautyDetailModalOpen && (
                    <div>
                        {userBeautyProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetailBeauty()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryBeautyDetailModalOpen && (
                    <div>
                        {userBeautyProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryBeauty()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileBeautyDetailModalOpen && (
                    <div>
                        {userBeautyProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileBeauty()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactBeautyModalOpen && (
                    <div>
                        {userBeautyProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseBeauty()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleBeautyPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterBeautyRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneBeautyRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileBeautyRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new service products */}
                {newServiceProductsModalOpen && (
                    <div>
                        {userServicesProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductServices(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryServices(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileServices(valfood.code_user)}
                                    onButtonClickFour={() => handleContactServices(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsServicesDetailModalOpen && (
                    <div>
                        {userServicesProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard
                                    url={valproduct.image_product} 
                                    onButtonClickClose={() => handlerCloseDetailServices()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryServicesDetailModalOpen && (
                    <div>
                        {userServicesProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard
                                    url={valcategory.image_product} 
                                    onButtonClickClose={() => handlerCloseCategoryServices()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileServicesDetailModalOpen && (
                    <div>
                        {userServicesProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileServices()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactServicesModalOpen && (
                    <div>
                        {userServicesProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseServices()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleServicesPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterServicesRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneServicesRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileServicesRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new electronic products */}
                {newElectronicProductsModalOpen && (
                    <div>
                        {userElectronicProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductElectronic(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryElectronic(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileElectronic(valfood.code_user)}
                                    onButtonClickFour={() => handleContactElectronic(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsElectronicDetailModalOpen && (
                    <div>
                        {userElectronicProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetailElectronic()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryElectronicDetailModalOpen && (
                    <div>
                        {userElectronicProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryElectronic()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileElectronicDetailModalOpen && (
                    <div>
                        {userElectronicProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileElectronic()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactElectronicModalOpen && (
                    <div>
                        {userElectronicProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseElectronic()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleElectronicPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterElectronicRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneElectronicRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileElectronicRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new automotive products */}
                {newAutomotiveProductsModalOpen && (
                    <div>
                        {userAutomotiveProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductAutomotive(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryAutomotive(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileAutomotive(valfood.code_user)}
                                    onButtonClickFour={() => handleContactAutomotive(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsAutomotiveDetailModalOpen && (
                    <div>
                        {userAutomotiveProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetailAutomotive()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryAutomotiveDetailModalOpen && (
                    <div>
                        {userAutomotiveProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard
                                    url={valcategory.image_product} 
                                    onButtonClickClose={() => handlerCloseCategoryAutomotive()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileAutomotiveDetailModalOpen && (
                    <div>
                        {userAutomotiveProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileAutomotive()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactAutomotiveModalOpen && (
                    <div>
                        {userAutomotiveProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseAutomotive()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleAutomotivePhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterAutomotiveRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneAutomotiveRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileAutomotiveRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new agriculture products */}
                {newAgricultureProductsModalOpen && (
                    <div>
                        {userAgricultureProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductAgriculture(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryAgriculture(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileAgriculture(valfood.code_user)}
                                    onButtonClickFour={() => handleContactAgriculture(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsAgricultureDetailModalOpen && (
                    <div>
                        {userAgricultureProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetailAgriculture()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryAgricultureDetailModalOpen && (
                    <div>
                        {userAgricultureProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryAgriculture()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileAgricultureDetailModalOpen && (
                    <div>
                        {userAgricultureProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileAgriculture()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactAgricultureModalOpen && (
                    <div>
                        {userAgricultureProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseAgriculture()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleAgriculturePhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterAgricultureRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneAgricultureRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileAgricultureRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new breeding products */}
                {newBreedingProductsModalOpen && (
                    <div>
                        {userBreedingProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductBreeding(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryBreeding(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileBreeding(valfood.code_user)}
                                    onButtonClickFour={() => handleContactBreeding(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsBreedingDetailModalOpen && (
                    <div>
                        {userBreedingProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard
                                    url={valproduct.image_product} 
                                    onButtonClickClose={() => handlerCloseDetailBreeding()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryBreedingDetailModalOpen && (
                    <div>
                        {userBreedingProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard
                                    url={valcategory.image_product} 
                                    onButtonClickClose={() => handlerCloseCategoryBreeding()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileBreedingDetailModalOpen && (
                    <div>
                        {userBreedingProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileBreeding()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactBreedingModalOpen && (
                    <div>
                        {userBreedingProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseBreeding()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleBreedingPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterBreedingRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneBreedingRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileBreedingRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new house products */}
                {newHouseProductsModalOpen && (
                    <div>
                        {userHouseProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductHouse(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryHouse(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileHouse(valfood.code_user)}
                                    onButtonClickFour={() => handleContactHouse(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsHouseDetailModalOpen && (
                    <div>
                        {userHouseProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetailHouse()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryHouseDetailModalOpen && (
                    <div>
                        {userHouseProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryHouse()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileHouseDetailModalOpen && (
                    <div>
                        {userHouseProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileHouse()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactHouseModalOpen && (
                    <div>
                        {userHouseProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseHouse()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleHousePhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterHouseRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneHouseRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileHouseRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new sport products */}
                {newSportProductsModalOpen && (
                    <div>
                        {userSportProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductSport(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategorySport(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileSport(valfood.code_user)}
                                    onButtonClickFour={() => handleContactSport(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsSportDetailModalOpen && (
                    <div>
                        {userSportProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard
                                    url={valproduct.image_product} 
                                    onButtonClickClose={() => handlerCloseDetailSport()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categorySportDetailModalOpen && (
                    <div>
                        {userSportProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategorySport()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileSportDetailModalOpen && (
                    <div>
                        {userSportProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileSport()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactSportModalOpen && (
                    <div>
                        {userSportProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseSport()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleSportPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterSportRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneSportRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileSportRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new decor products */}
                {newDecorProductsModalOpen && (
                    <div>
                        {userDecorProducts.map((valfood, indexfood) => (
                            <ModalUser key={indexfood}>
                                <ListImageCardSubscriber
                                    url={valfood.image_product}
                                    onImageClick={() => handleAllImage(valfood.code_user)}
                                    title={valfood.name_product}
                                    onButtonClickOne={() => handleProductDecor(valfood.code_user)}
                                    onButtonClickTwo={() => handleCategoryDecor(valfood.code_user)}
                                    onButtonClickThree={() => handleProfileDecor(valfood.code_user)}
                                    onButtonClickFour={() => handleContactDecor(valfood.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsDecorDetailModalOpen && (
                    <div>
                        {userDecorProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseDetailDecor()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categoryDecorDetailModalOpen && (
                    <div>
                        {userDecorProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseCategoryDecor()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileDecorDetailModalOpen && (
                    <div>
                        {userDecorProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseProfileDecor()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactDecorModalOpen && (
                    <div>
                        {userDecorProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseDecor()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleDecorPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterDecorRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneDecorRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileDecorRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**SEARCH RESULTS */}
                {searchResultModalOpen && (
                    <div>
                        <SearchPagination
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage} 
                        />

                        {userSearchProducts
                        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                        .map((valsearch, indexsearch) => (
                            <ModalUser key={indexsearch}>
                                <ListImageCardSubscriber
                                    url={valsearch.image_product}
                                    onImageClick={() => handleAllImage(valsearch.code_user)}
                                    title={valsearch.name_product}
                                    onButtonClickOne={() => handleProductSearch(valsearch.code_user)}
                                    onButtonClickTwo={() => handleCategorySearch(valsearch.code_user)}
                                    onButtonClickThree={() => handleProfileSearch(valsearch.code_user)}
                                    onButtonClickFour={() => handleContactSearch(valsearch.code_user)}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail product */}
                {productsSearchDetailModalOpen && (
                    <div>
                        {userSearchProductsDetail.map((valproduct, indexproduct) => (
                            <ModalUser key={indexproduct}>
                                <ListImageDetailCard 
                                    url={valproduct.image_product}
                                    onButtonClickClose={() => handlerCloseSearchDetail()}
                                    title={valproduct.name_product}
                                    textDescription={valproduct.description_product}
                                    textPrice={valproduct.price_product}
                                    textCurrency={valproduct.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail category */}
                {categorySearchDetailModalOpen && (
                    <div>
                        {userSearchProductsCategory.map((valcategory, indexcategory) => (
                            <ModalUser key={indexcategory}>
                                <ListImageDetailCard 
                                    url={valcategory.image_product}
                                    onButtonClickClose={() => handlerCloseSearchCategory()}
                                    title={valcategory.name_product}
                                    textDescription={valcategory.description_product}
                                    textPrice={valcategory.price_product}
                                    textCurrency={valcategory.currency_product}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**detail profile */}
                {profileSearchDetailModalOpen && (
                    <div>
                        {userSearchProductsProfile.map((valprofile, indexprofile) => (
                            <ModalUser key={indexprofile}>
                                <CardProfile 
                                    onButtonClickClose={() => handlerCloseSearchProfile()}
                                    denomination={valprofile.profile_principal}
                                    secteur={t(TranslateWord(valprofile.secteur))}
                                    description={valprofile.profile_description}
                                    modeVente={t(TranslateWord(valprofile.mode_vente))}
                                    contact={t(TranslateWord(valprofile.profile_contact))}
                                    pays={NomPays(valprofile.code_pays)}
                                    ville={valprofile.city_user}
                                    village={valprofile.village}
                                    quartier={valprofile.quartier}
                                    localisation={valprofile.localisation}
                                />
                            </ModalUser>
                        ))}
                    </div>
                )}
                {/**contact details */}
                {contactSearchDetailModalOpen && (
                    <div>
                        {userSearchProductsContact.map((valcontact, indexcontact) => (
                            <ModalUser key={indexcontact}>
                                <SubscriberCardContact 
                                    url={valcontact.image_product}
                                    denomination={valcontact.profile_principal}
                                    onButtonClickClose={() => handlerCloseSearch()}
                                    pays={valcontact.pays}
                                    ville={valcontact.city_user}
                                    onButtonClickPhone={() => handleSearchPhone()}
                                />
                                <div style={{display:"none"}}>
                                    <input defaultValue={valcontact.prefixe} ref={prefixeInterSearchRef} readOnly />
                                    <input defaultValue={valcontact.phone} ref={phoneSearchRef} readOnly />
                                    <input defaultValue={valcontact.profile_contact} ref={profileSearchRef} readOnly />
                                </div>
                            </ModalUser>
                            
                        ))}
                    </div> 
                )}
                {/**XXXXXXXXXXXXXXXXXXXXX */}

                {/**show modal */}
                {showModal && (
                    <WarningSingleModal 
                        text={t("error_suggestion")}
                        textNo="OK"
                        onCancel={handleCancelSearch}
                    />
                )}

                {/**show modal contact */}
                {showModalContact && (
                    <WarningSingleModal 
                        text={t("error_contact")}
                        textNo="OK"
                        onCancel={handleCancelContact}
                    />
                )}

                {/**Close */}
                {showModalClose && (
                    <WarningModal 
                        text={t("close_page")}
                        onConfirm={handleClosePage}
                        textOk={t('yes')}
                        textNo={t("no")}
                        onCancel={handleCancelPage}
                    />
                )}

                <br/>
                <HandlerContainerDiv>
                    <div>
                        <GobackBouton onClick={handleGoBackSubscribe} ></GobackBouton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
                <div id="modaloutalertsubscribebuyer"></div>
                <div id="backdropoutalertsubscribebuyer"></div>
            </TermeScroller>
        </div>
    )
}

export default SubscribeBuyer
import React from 'react';
//
import InnerPopUpBackButton from '../buttons/InnerPopUpBackButton';
import { MdPhoneEnabled, MdWhatsapp } from "react-icons/md";
//
import { 
    CardDetailContainer,
    CardVideoText,
    HandlerVideoContainerDiv,
    CardButtonPhone,
    CardTextVideoBody,
    CardHeader,
    CardImageProduct,
    StyledParagraph,
} from '../../ComponentStyles';

function ListDiscountCard({ url, title, textPrice, textCurrency, textDescription,  onButtonClickClose, onButtonClickPhoneCall, onButtonClickPhone }) {
    return (
        <div>
            <CardDetailContainer>
                <CardHeader>
                    <CardImageProduct>
                        <br/><br/>
                        <img src={url} alt='preview' style={{ maxWidth: '100px', maxHeight: '50px', borderRadius: '10px' }} />
                    </CardImageProduct>
                    <CardVideoText>{title}</CardVideoText>
                </CardHeader>
                <HandlerVideoContainerDiv>
                    <div>
                        <InnerPopUpBackButton onClick={onButtonClickClose}></InnerPopUpBackButton>
                    </div>
                    <div>
                        <CardButtonPhone onClick={onButtonClickPhoneCall}><MdPhoneEnabled style={{fontSize:'50px'}} /></CardButtonPhone>
                    </div>
                    <div>
                        <CardButtonPhone onClick={onButtonClickPhone}><MdWhatsapp style={{fontSize:'50px'}} /></CardButtonPhone>
                    </div>
                </HandlerVideoContainerDiv>
                <CardTextVideoBody>
                    <StyledParagraph>{textDescription}</StyledParagraph>
                    <StyledParagraph>{textPrice} {textCurrency}</StyledParagraph>
                </CardTextVideoBody>
            </CardDetailContainer>
        </div>
    )
}

export default ListDiscountCard
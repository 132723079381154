import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//
import { MdOutlineSave } from 'react-icons/md';
//import country button
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//icons
import Account from '../countries/sellerbuyericon/account_icon.png';
//
import GobackBouton from '../reusablecomponents/buttons/GobackBouton';
import ForwardButton from '../reusablecomponents/buttons/ForwardButton';
//
import { HideFooter, AlertModal, getCurrentDate, addExpiredDate } from '../../helperfunctions/Helper';

//
import { retrieveTableQuery } from '../../helperfunctions/utilityfunctions/CountryUtils';
import Axios from 'axios';
import apiUrl from '../../apiconfig/ApiConfig';

//
import {  
    FooterSubTitle,
    LargeInput,
    TextLogin, 
    HandlerContainerDiv, 
    ModalUser,
    SmallHandlerContainerDiv,
    SmallSaveButton,
} from '../ComponentStyles';

function SellerSubscribe() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertsellersubscribe";
    const elementDropId = "backdropoutalertsellersubscribe";
    const buttonHandler = "ouiHandlerAlertSellerSubscribeBtn";

    // Define state variables for modals
    const [startSellerSubscribeModalOpen, setStartSellerSubscribeModalOpen] = useState(true);
    const [sellerSubscribeModalOpen, setSellerSubscribeModalOpen] = useState(false);
    const [sellerSubscribe, setSellerSubscribe] = useState(false);
    //
    const [sellerSubscribeDetail, setSellerSubscribeDetail] = useState([0]);
    //setting input variables
    const [dateStartInput, setDateStartInput] = useState([0]);
    const [dateEndInput, setDateEndInput] = useState([0]);

    const userCodeRef = useRef(null);
    const dateStartRef = useRef(null);
    const dateEndRef = useRef(null);

    const navigateSellerSubscribe = useNavigate();

    const handleStartSubscribeSeller = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        //
        const dateStart = getCurrentDate();
        var numberDays = +28;

        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }

        const firstWhere = "code_user";

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableName,
            firstWhere:firstWhere,
            firstParameter:codeutilisateurDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setSellerSubscribeDetail(response1.data);
                //set date start and date end
                setDateStartInput(dateStart);
                //calculate date end
                setDateEndInput(addExpiredDate(dateStart, numberDays))
                //
                setStartSellerSubscribeModalOpen(false);
                setSellerSubscribeModalOpen(true);
                setSellerSubscribe(true);
            }
        });
    }

    const handleSaveSellerSubscribe = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];

        const userCode = userCodeRef.current.value;
        const planPincode = "trial";
        const referencePincode = "";
        const numPincode = "";
        const dateStart = dateStartRef.current.value;
        const dateEnd = dateEndRef.current.value;
        const codeReseller = "";

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table10;
        }

        await Axios.post(`${apiUrl}/seller/subscribe`, {
            tableName:tableName,
            userCode:userCode,
            planPincode:planPincode,
            referencePincode:referencePincode,
            numPincode:numPincode,
            dateStart:dateStart,
            dateEnd:dateEnd,
            codeReseller:codeReseller,
        }).then(() => {
            navigateSellerSubscribe('/dashboardseller');
        });
    }

    const handleGoBack = async () => {

        HideFooter();
        navigateSellerSubscribe(-1);
    }

    const goNextHandler = async () => {

        navigateSellerSubscribe('/dashboardseller');
    }

    return (
        <div id='sellersubscribe'>
            <ModalUser>
                <br/>
                <FooterSubTitle>{t("subscribe")}</FooterSubTitle>
                <br/>
                {startSellerSubscribeModalOpen && (
                    <HandlerContainerDiv>
                        <CountryButton 
                            url={Account}
                            countryName={t("start_subscribe")}
                            onConfirm={handleStartSubscribeSeller}
                        />
                    </HandlerContainerDiv>
                )}

                {sellerSubscribeModalOpen && (
                    <div>
                        {sellerSubscribeDetail.map((valdetail, indexdetail) => (
                            <ModalUser key={indexdetail}>
                                <TextLogin>{t("user_code")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <LargeInput type='text' ref={userCodeRef} defaultValue={valdetail.code_user} readOnly />
                                </div>
                                <br/>
                                <TextLogin>{t("plan_pincode")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <LargeInput type='text' value={t("type_plan")} readOnly />
                                </div>
                                <br/>
                                <TextLogin>{t("date_start")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <LargeInput type='text' ref={dateStartRef} value={dateStartInput} readOnly />
                                </div>
                                <br/>
                                <br/>
                                <TextLogin>{t("date_end")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <LargeInput type='text' ref={dateEndRef} value={dateEndInput} readOnly />
                                </div>
                                <br/>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/>
                {sellerSubscribe && (
                    <div>
                        <SmallHandlerContainerDiv>
                            <div>
                                <SmallSaveButton onClick={handleSaveSellerSubscribe}>
                                    <span style={{ fontSize:'30px'}}><MdOutlineSave /></span>
                                    {t("create")}
                                </SmallSaveButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>
                )}

                <br/>
                <HandlerContainerDiv>
                    <div>
                        <GobackBouton onClick={handleGoBack} ></GobackBouton>
                    </div>
                    <div>
                        <ForwardButton onClick={goNextHandler}></ForwardButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
                <div id="modaloutalertsellersubscribe"></div>
                <div id="backdropoutalertsellersubscribe"></div>
            </ModalUser>
        </div>
    )
}

export default SellerSubscribe
import React from 'react';

//
import { 
    CardWrapper,
    CardTextWrapper,
    CardTextTitle,
    CardTextBody,
    CardTextDate,
} from '../../ComponentStyles';

function NotifyCard({ title, textcontent, datetext, timetext, leveltext, typetext}) {
  return (
    <div>
        <CardWrapper>
            <CardTextWrapper>
                <CardTextTitle>{title}</CardTextTitle>
                <CardTextBody>{textcontent}</CardTextBody>
                <CardTextTitle>{leveltext} : {typetext}</CardTextTitle>
                <CardTextDate>{datetext} : {timetext}</CardTextDate>
            </CardTextWrapper>
        </CardWrapper>
    </div>
  )
}

export default NotifyCard
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
import NotifyCard from '../../../reusablecomponents/cards/NotifyCard';
import ListCard from '../../../reusablecomponents/cards/ListCard';
//
import { MdOutlineChat, MdClose, MdPreview } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal, formatDateData, getCurrentDate, WhatsappCall } from '../../../../helperfunctions/Helper';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    FooterIntro,
    FormScrollerWidgetSm,
    TableWidgetSmTable,
    TableThead,
    ThWidgetSmTh,
    TableTbody,
    TableTd,
    TableButton,
    SmallInput,
    SmallHandlerContainerDiv,
    CancelButton,
    SmallSaveButton,
    SmallViewButton,
} from '../../../ComponentStyles';

function SellerChatGroup() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertsellerchatgroup";
    const elementDropId = "backdropoutalertsellerchatgroup";
    const buttonHandler = "ouiHandlerAlertSellerChatGroupBtn";

    //setting input variables
    const [firstNameInput, setFirstNameInput] = useState([0]);
    const [lastNameInput, setLastNameInput] = useState([0]);
    const [phoneNumerInput, setPhoneNumberInput] = useState([0]);
    const [prefixeInterInput, setPrefixeInterInput] = useState([0]);
    //
    const [phoneNumerLeaderInput, setPhoneNumberLeaderInput] = useState([0]);
    const [prefixeInterLeaderInput, setPrefixeInterLeaderInput] = useState([0]);

    // Define state variables for modals
    const [startSellerChatGroupModalOpen, setStartSellerChatGroupModalOpen] = useState(true);
    const [sellerChatGroupTableModalOpen, setSellerChatGroupTableModalOpen] = useState(false);
    const [sellerChatGroupModalOpen, setSellerChatGroupModalOpen] = useState(false);
    const [sellerChatGroupDetails, setSellerChatGroupDetails] = useState(false);
    const [sellerChatGroupUserDetails, setSellerChatGroupUserDetails] = useState(false);
    const [sellerChatGroup, setSellerChatGroup] = useState(false);

    const [userSellerChatGroup, setUserSellerChatGroup] = useState([0]);
    const [sellerChatGroupDetail, setSellerChatGroupDetail] = useState([0]);

    const idSellerChatGroupRef = useRef(null);
    const userPhoneRef = useRef(null);
    const userPrefixeRef = useRef(null);
    //team leader ref
    const userPhoneLeaderGroupRef = useRef(null);
    const userPrefixeLeaderGroupRef = useRef(null);

    const handleStartSellerChatGroup = async (e) => {
        e.preventDefault();

        setStartSellerChatGroupModalOpen(false);
        setSellerChatGroupTableModalOpen(true);
        setSellerChatGroupModalOpen(false);
        setSellerChatGroupDetails(false);
        setSellerChatGroupUserDetails(false);
        setSellerChatGroup(false);
    }

    const handleOpenChat = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const firstParameter = document.getElementById('namecountry').innerText;
        const secondParameter = "staff"; 
        const thirdParameter = getCurrentDate();
        const fourthParameter = "online";
        const fithParameter = "confirmed";
        const sixthParameter = "seller";
        const seventhParameter = "group";
        

        //retrieve table name based on country
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table14;
        }

        const firstWhere = "pays";
        const secondWhere = "code_user";
        const thirdWhere = "date_due";
        const fourthWhere = "status_notify";
        const fithWhere = "state_notify";
        const sixthWhere = "titre";
        const seventhWhere = "type_notify";

        //check and retrieve all meetings set up this day
        await Axios.post(`${apiUrl}/check/seventh/parameter/table`, {
            tableNameSeller:tableName,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
            secondWhere:secondWhere,
            secondParameter:secondParameter,
            thirdWhere:thirdWhere,
            thirdParameter:thirdParameter,
            fourthWhere:fourthWhere,
            fourthParameter:fourthParameter,
            fithWhere:fithWhere,
            fithParameter:fithParameter,
            sixthWhere:sixthWhere,
            sixthParameter:sixthParameter,
            seventhWhere:seventhWhere,
            seventhParameter:seventhParameter,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_meeting");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserSellerChatGroup(response1.data);
                setStartSellerChatGroupModalOpen(false);
                setSellerChatGroupTableModalOpen(false);
                setSellerChatGroupModalOpen(true);
                setSellerChatGroupDetails(false);
                setSellerChatGroupUserDetails(false);
                setSellerChatGroup(false);
            }
        });
    }

    const handleCloseChat = async (e) => {
        e.preventDefault();

        setStartSellerChatGroupModalOpen(true);
        setSellerChatGroupTableModalOpen(false);
        setSellerChatGroupModalOpen(false);
        setSellerChatGroupDetails(false);
        setSellerChatGroupUserDetails(false);
        setSellerChatGroup(false);
    }

    const handleCodeNotification = async (managerId) => {

        var tableName = "";
        var signupTable = [];
        var resultat2 = 0;
        var resultatCheck = [];
        var typeNotify = "";
        var titreNotify = "";
        var codeUser = "";
        //
        var tableNameDetail = "";
        var signupTableDetail = [];
        var resultat3 = 0;
        var resultatDetail = [];
        //retrieve team leader details
        var tableNameLeader = "bazali_admin"; 
        var resultat4 = 0;
        var resultatLeader = [];
        var teamLeader = "";
        //
        const firstWhereLeader = "code_user";

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table name based on country
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table14;
        }

        const firstWhere = "id_notify";
        const firstParameter = managerId;
        //set retrieval data
        const firstWhereDetail = "code_user";

        await Axios.post(`${apiUrl}/check/one/parameter/table`, {
            tableNameSeller:tableName,
            firstWhere:firstWhere,
            firstParameter:firstParameter,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                //check schedule detail
                resultatCheck = response2.data;
                for (let i=0; i<resultat2; i++){
                    typeNotify = resultatCheck[i].type_notify;
                    titreNotify = resultatCheck[i].titre;
                    codeUser = resultatCheck[i].code_user;
                    teamLeader = resultatCheck[i].team_leader;
                }
                //
                if(typeNotify === 'group'){
                    //set titre and proceed
                    if(titreNotify === "seller"){
                        signupTableDetail = retrieveTableQuery(codecountryDiv);
                        if(signupTableDetail){
                            tableNameDetail = signupTableDetail.table.table2;
                        }
                    }else if(titreNotify === "AD"){
                        tableNameDetail = "bazali_admin";
                    }else if(titreNotify === "PP"){
                        tableNameDetail = "bazali_admin";
                    }else if(titreNotify === "RP"){
                        signupTableDetail = retrieveTableQuery(codecountryDiv);
                        if(signupTableDetail){
                            tableNameDetail = signupTableDetail.table.table11;
                        }
                    }else if(titreNotify === "CR"){
                        signupTableDetail = retrieveTableQuery(codecountryDiv);
                        if(signupTableDetail){
                            tableNameDetail = signupTableDetail.table.table12;
                        }
                    }
                    //retrieve person data based on his user code
                    Axios.post(`${apiUrl}/check/one/parameter/table`, {
                        tableNameSeller:tableNameDetail,
                        firstWhere:firstWhereDetail,
                        firstParameter:codeUser,
                    }).then((response3) => {
                        resultat3 = response3.data.length;
                        resultatDetail = response3.data;
                        for (let j=0; j<resultat3; j++){
                            setFirstNameInput(resultatDetail[j].nom);
                            setLastNameInput(resultatDetail[j].prenom);
                            setPhoneNumberInput(resultatDetail[j].phone);
                            setPrefixeInterInput(resultatDetail[j].prefixe);
                        }

                        //retrieve team leader prefixe inter and phone number
                        Axios.post(`${apiUrl}/check/one/parameter/table`, {
                            tableNameSeller:tableNameLeader,
                            firstWhere:firstWhereLeader,
                            firstParameter:teamLeader,
                        }).then((response4) => {
                            resultat4 = response4.data.length;
                            resultatLeader = response4.data;
                            for (let k=0; k<resultat4; k++){
                                setPhoneNumberLeaderInput(resultatLeader[k].phone);
                                setPrefixeInterLeaderInput(resultatLeader[k].prefixe_inter);
                            }
                        });

                        setSellerChatGroupDetail(response2.data);
                        setStartSellerChatGroupModalOpen(false);
                        setSellerChatGroupTableModalOpen(false);
                        setSellerChatGroupModalOpen(false);
                        setSellerChatGroupDetails(true);
                        setSellerChatGroupUserDetails(false);
                        setSellerChatGroup(true);
                    });
                }else{
                    setSellerChatGroupDetail(response2.data);
                    setStartSellerChatGroupModalOpen(false);
                    setSellerChatGroupTableModalOpen(false);
                    setSellerChatGroupModalOpen(false);
                    setSellerChatGroupDetails(true);
                    setSellerChatGroupUserDetails(false);
                    setSellerChatGroup(true); 
                }
            }
        })
    }

    const handleSendSellerChatGroup = async (e) => {
        e.preventDefault();

        //User's phone number (include the country code)
        const userPrefixe = userPrefixeLeaderGroupRef.current.value;
        const userPhone = userPhoneLeaderGroupRef.current.value;
        // Call WhatsApp call link function
        WhatsappCall(userPrefixe, userPhone);
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_seller_group_chats")}</FooterSubTitle>
                <br/>
                {startSellerChatGroupModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartSellerChatGroup}
                        />
                    </div>
                )}

                {sellerChatGroupTableModalOpen && (
                    <div>
                        <ModalUser>
                            <FooterIntro>{t("meeting_info_group")}</FooterIntro>
                            <br/>
                            <SmallHandlerContainerDiv>
                                <div>
                                    <SmallSaveButton onClick={handleOpenChat}>
                                        <span style={{ fontSize:'30px'}}><MdOutlineChat /></span>
                                        {t("open_meeting")}
                                    </SmallSaveButton>
                                </div>
                            </SmallHandlerContainerDiv>
                            <br/>
                            <SmallHandlerContainerDiv>
                                <div>
                                    <CancelButton onClick={handleCloseChat}>
                                        <span style={{ fontSize:'30px'}}><MdClose /></span>
                                        {t("close_meeting")}
                                    </CancelButton>
                                </div>
                            </SmallHandlerContainerDiv>
                        </ModalUser>
                    </div>
                )}

                {sellerChatGroupModalOpen && (
                    <div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <TableWidgetSmTable>
                                <TableThead>
                                    <tr>
                                        <ThWidgetSmTh>{t("list_schedule")}</ThWidgetSmTh>
                                    </tr>
                                    <tr style={{display:'none'}}>
                                        <ThWidgetSmTh>{t("order_action")}</ThWidgetSmTh>
                                    </tr>
                                </TableThead>
                                <TableTbody>
                                    {userSellerChatGroup.map((valedit, index) => {
                                        return(
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <TableTd>
                                                        <ListCard 
                                                            title={valedit.title_notify}
                                                            datetext={formatDateData(valedit.date_due)}
                                                            timetext={valedit.time_due}
                                                            leveltext={valedit.level_notify}
                                                            typetext={valedit.type_notify}
                                                        />
                                                    </TableTd>
                                                </tr>
                                                <tr>
                                                    <TableTd ><TableButton type="button" onClick={() => handleCodeNotification(valedit.id_notify)}>OK</TableButton></TableTd>
                                                </tr>
                                            </React.Fragment>
                                            
                                        )
                                    })}
                                </TableTbody>
                            </TableWidgetSmTable>
                        </FormScrollerWidgetSm>
                    </div>
                )}

                {sellerChatGroupDetails && (
                    <div>
                        {sellerChatGroupDetail.map((valeditdetail, indexeditdetail) => (
                            <ModalUser key={indexeditdetail}>
                                <input style={{display:'block'}} ref={idSellerChatGroupRef} defaultValue={valeditdetail.id_notify} />
                                <NotifyCard 
                                  title={valeditdetail.title_notify}
                                  textcontent={valeditdetail.content_notify}
                                  leveltext={valeditdetail.level_notify}
                                  typetext={valeditdetail.type_notify}
                                  datetext={formatDateData(valeditdetail.date_due)}
                                  timetext={valeditdetail.time_due}
                                />
                                <br/>
                                {sellerChatGroupUserDetails && (
                                    <div>
                                        <ModalUser>
                                            <SmallTextLogin>{t("nom")}</SmallTextLogin>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallInput type='text' value={firstNameInput} readOnly />
                                            </div>
                                            <br/>
                                            <SmallTextLogin>{t("prenom")}</SmallTextLogin>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallInput type='text' value={lastNameInput} readOnly />
                                            </div>
                                            <br/>
                                            <SmallTextLogin>{t("phone_number")}</SmallTextLogin>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallInput type='text' ref={userPhoneRef} value={phoneNumerInput} readOnly />
                                            </div>
                                            <br/>
                                            <SmallTextLogin>{t("prefixe_inter")}</SmallTextLogin>
                                            <div style={{display:'block',textAlign:'center'}}>
                                                <SmallInput type='text' ref={userPrefixeRef} value={prefixeInterInput} readOnly />
                                            </div>
                                        </ModalUser>
                                    </div>
                                )}
                                <br/>
                                <SmallTextLogin>{t("status_notify")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valeditdetail.status_notify} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("state_notify")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valeditdetail.state_notify} readOnly />
                                </div>

                                <div style={{display:'none',textAlign:'center'}}>
                                    <SmallInput type='text' ref={userPhoneLeaderGroupRef} value={phoneNumerLeaderInput} readOnly />
                                </div>
                                <div style={{display:'none',textAlign:'center'}}>
                                    <SmallInput type='text' ref={userPrefixeLeaderGroupRef} value={prefixeInterLeaderInput} readOnly />
                                </div>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/>
                {sellerChatGroup && (
                    <div>
                        <SmallHandlerContainerDiv>
                            <div>
                                <SmallViewButton onClick={handleSendSellerChatGroup}>
                                    <span style={{ fontSize:'30px'}}><MdPreview /></span>
                                    {t("whatsapp_call")}
                                </SmallViewButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>
                )}

                <br/><br/>
                <div id="modaloutalertsellerchatgroup"></div>
                <div id="backdropoutalertsellerchatgroup"></div>
            </ModalUser>
        </div>
    )
}

export default SellerChatGroup
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import './buyer.css';
//search by country
import SearchByCountryModal from '../reusablecomponents/componentutils/SearchByCountryModal';
//
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//english country
import Gambia from '../countries/countryflag/gambia_flag.png';
import Ghana from '../countries/countryflag/ghana_flag.png';
import Liberia from '../countries/countryflag/liberia_flag.png';
import Nigeria from '../countries/countryflag/nigeria_flag.png';
import Sierraleone from '../countries/countryflag/sierraleone_flag.png';
//french country
import Benin from '../countries/countryflag/benin_flag.jpg';
import Burkina from '../countries/countryflag/burkina_flag.png';
import Guinee from '../countries/countryflag/guinee_flag.jpg';
import Ivoire from '../countries/countryflag/ivoire_flag.png';
import Mali from '../countries/countryflag/mali_flag.jpg';
import Niger from '../countries/countryflag/niger_flag.png';
import Senegal from '../countries/countryflag/senegal_flag.png';
import Togo from '../countries/countryflag/togo_flag.png';
//portuguese country
import Capvert from '../countries/countryflag/capvert_flag.png';
import GuineeBissau from '../countries/countryflag/guineebissau_flag.png';
//
import FooterStarting from '../footer/FooterStarting';
//warning
import WarningSingleModal from '../reusablecomponents/componentutils/WarningSingleModal';
import WarningModal from '../reusablecomponents/componentutils/WarningModal';
//
import ListEventCard from '../reusablecomponents/cards/ListEventCard';
//advertise video
import ContactCardVideo from '../reusablecomponents/cards/ContactCardVideo';
import ListDiscountCard from '../reusablecomponents/cards/ListDiscountCard';
import ProductListCard from '../reusablecomponents/cards/ProductListCard';
import ListCategoryCard from '../reusablecomponents/cards/ListCategoryCard';
import ListProfileCard from '../reusablecomponents/cards/ListProfileCard';
//
import { retrieveTableQuery, retrieveCodeAndName } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { MdOutlineSearch } from 'react-icons/md';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
import PlayButtonSearch from '../reusablecomponents/buttons/PlayButtonSearch';
import LikeButton from '../reusablecomponents/buttons/LikeButton';
import { HideFooter, AlertModal, WhatsappCall, NomPays, isOnline, TranslateModeContact, EscapeApostrophe } from '../../helperfunctions/Helper';
import Trie from '../../helperfunctions/Trie'; //used for autocomplete search functionalities

import apiUrl from '../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    TermeScroller, 
    PrincipalScroller,
    PrincipalSubTitle,
    SearchHeader,
    DetailsHeader,
    ContentScroller,
    MainContainer,
    MainContainerList,
    AutoCompleteSearchOne,
    AutoCompleteSearchTwo,
    TextLogin, 
    HandlerContainerDiv, 
    BazaliButton,
    ModalUser,
    VideoModalUser,
    ModalFilter,
    ConfirmButton,
    CancelButton,
    AccueilButton,
    SmallSelectBox,
    SearchIcon,
    FooterUpperLinkDiv,
    EventScrollerContainer,
    EventScrollerMenu,
    ContentScrollerDetails,
    ContentScrollerDetailsProfile,
    DetailContainerMenu,
    DetailContainerMenuProfile,
    ContainerProfile,
} from '../ComponentStyles';

function NoSubscribeBuyer() {
    HideFooter();

    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertnosubscribebuyer";
    const elementDropId = "backdropoutalertnosubscribebuyer";
    const buttonHandler = "ouiHandlerAlertNosubscribeBuyerBtn";

    const navigateNoSubscribeBuyer = useNavigate();
    //
    const noSubscribeSearchRef = useRef(null);
    //current event views
    const [currentEventViews, setCurrentEventViews] = useState(0);
    const [currentPromotionViews, setCurrentPromotionViews] = useState(0);
    const [currentProductViews, setCurrentProductViews] = useState(0);
    //modal
    const noSubscribeSearchCityRef = useRef(null);
    const noSubscribeSearchNeighborhoodRef = useRef(null);
    const noSubscribeSearchPriceRef = useRef(null);

    //show modal
    const [showModal, setShowModal] = useState(false);
    const [showModalClose, setShowModalClose] = useState(false);

    //**********Search Header*************
    const [dictionary, setDictionary] = useState([]);
    const [prefix, setPrefix] = useState("");
    const [suggestion, setSuggestion] = useState("");
    //
    const [userSearchProducts, setUserSearchProducts] = useState([0]);
    //
    //Search Results
    const [searchResultModalOpen, setSearchResultModalOpen] = useState(false);

    //search input
    const [noSubscribeSearchModalOpen, setNoSubscribeSearchModalOpen] = useState(true);
    //
    const [cityModalOpen, setCityModalOpen] = useState(false);
    const [listcity, setListCity] = useState([0]);
    const [neighborhoodModalOpen, setNeighborhoodModalOpen] = useState(false);
    const [listNeighbord, setListNeighbord] = useState([0]);
    const [bestPricesModalOpen, setBestPricesModalOpen] = useState(false);
    const [listPrice, setListPrice] = useState([0]);

    // Define state variables for modals
    const [scrollNoSubscribeModalOpen, setScrollNoSubscribeModalOpen] = useState(true);
    // Declare state variables for input data
    const [noSubscribeSearch, setNoSubscribeSearch] = useState('');
    const [noSubscribeSearchCity, setNoSubscribeSearchCity] = useState('');
    const [noSubscribeSearchNeighborhood, setNoSubscribeSearchNeighborhood] = useState('');
    const [noSubscribeSearchPrice, setNoSubscribeSearchPrice] = useState('');

    //declare no subscribe button
    const [noSubscribeBuyerButtonModalOpen, setNoSubscribeBuyerButtonModalOpen] = useState(false);
    //all products contents
    const [productContent, setProductContent] = useState(true);
    const [productEvent, setProductEvent] = useState(false);
    const [productPromotion, setProductPromotion] = useState(false);
    const [productProducts, setProductProducts] = useState(false);
    // retrieve event data for general layout
    const [productContentDetails, setProductContentDetails] = useState(true);
    const [productContentSubDetails, setProductContentSubDetails] = useState(true);
    const [userFetchEvent, setFetchEvent] = useState([0]);
    const [userFetchPromotion, setFetchPromotion] = useState([0]);
    const [userFetchProduct, setFetchProduct] = useState([0]);
    //header scrollable
    //all products
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [allProducts, setAllProducts] = useState([0]);
    //food products
    const [showFoodProducts, setShowFoodProducts] = useState(false);
    const [foodProducts, setFoodProducts] = useState([0]);
    //beverage products
    const [showBeverageProducts, setShowBeverageProducts] = useState(false);
    const [beverageProducts, setBeverageProducts] = useState([0]);
    //apparel products
    const [showApparelProducts, setShowApparelProducts] = useState(false);
    const [apparelProducts, setApparelProducts] = useState([0]);
    //apparel products
    const [showHealthProducts, setShowHealthProducts] = useState(false);
    const [healthProducts, setHealthProducts] = useState([0]);
    //beauty products
    const [showBeautyProducts, setShowBeautyProducts] = useState(false);
    const [beautyProducts, setBeautyProducts] = useState([0]);
    //services products
    const [showServicesProducts, setShowServicesProducts] = useState(false);
    const [servicesProducts, setServicesProducts] = useState([0]);
    //electronic products
    const [showElectronicProducts, setShowElectronicProducts] = useState(false);
    const [electronicProducts, setElectronicProducts] = useState([0]);
    //automotive products
    const [showAutomotiveProducts, setShowAutomotiveProducts] = useState(false);
    const [automotiveProducts, setAutomotiveProducts] = useState([0]);
    //agriculture products
    const [showAgricultureProducts, setShowAgricultureProducts] = useState(false);
    const [agricultureProducts, setAgricultureProducts] = useState([0]);
    //breeding products
    const [showBreedingProducts, setShowBreedingProducts] = useState(false);
    const [breedingProducts, setBreedingProducts] = useState([0]);
    //house products
    const [showHouseProducts, setShowHouseProducts] = useState(false);
    const [houseProducts, setHouseProducts] = useState([0]);
    //sport products
    const [showSportProducts, setShowSportProducts] = useState(false);
    const [sportProducts, setSportProducts] = useState([0]);
    //decor products
    const [showDecorProducts, setShowDecorProducts] = useState(false);
    const [decorProducts, setDecorProducts] = useState([0]);
    //search country component state
    const [searchCountry, setSearchCountry] = useState(false);
    const [showModalCountry, setShowModalCountry] = useState(false);
    //set event modal and variables setting
    const [newEventsModalOpen, setNewEventsModalOpen] = useState(false);
    const [userEventsVideo, setUserEventsVideo] = useState([0]);
    //
    const [newPromotionsModalOpen, setNewPromotionsModalOpen] = useState(false);
    const [userPromotions, setUserPromotions] = useState([0]);
    //
    const [newProductsModalOpen, setNewProductsModalOpen] = useState(false);
    const [userProducts, setUserProducts] = useState([0]);
    //
    const [newCategoryModalOpen, setNewCategoryModalOpen] = useState(false);
    const [userCategory, setUserCategory] = useState([0]);
    //
    const [newProfileModalOpen, setNewProfileModalOpen] = useState(false);
    const [userProfile, setUserProfile] = useState([0]);
    //
    const [showModalContact, setShowModalContact] = useState(false);

    var myTrie = new Trie();

    //
    useEffect(() => {
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultat1 = 0;
        const columnName = "description_product";
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        // Fetch product descriptions from the backend
        Axios.post(`${apiUrl}/product/description/search/table`, {
            tableName:tableNameProduct,
            columnName:columnName,
        }).then((responseProduct) => {
            resultat1 = responseProduct.data.length;
            if(resultat1 === 0){
                //
            }else{
                const descriptions = responseProduct.data.map(row => row[columnName].split(' ')).flat();
                setDictionary(descriptions);
            }
        });
    }, [t]);

    (async () => {
        const words = dictionary;
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            myTrie.insert(word)
        }
    }) ();
    
    const onChange = async (e) => {
        var value = e.target.value;
        setPrefix(value);
        var words = value.split(' ');
        var trie_prefix = words[words.length - 1].toLowerCase();
        var found_words = myTrie.find(trie_prefix).sort((a, b) => {
            return a.length - b.length;
        });
        var first_word = found_words[0];
        if(
            found_words.length !== 0 &&
            value !== '' &&
            value[value.length - 1] !== ' '
        ){
            if (first_word != null){
                var remainder = first_word.slice(trie_prefix.length);
                setSuggestion(value + remainder);
            }
        }else{
            setSuggestion(value);
        }
    };
    //
    const handleKeyDown = async (e) => {
        // Key codes for arrow keys
        const leftArrow = 37;
        const upArrow = 38;
        const rightArrow = 39;
        const downArrow = 40;
        const enter = 13;
        const escape = 27;
        const space = 32;

        if (e.keyCode === leftArrow || e.keyCode === upArrow || e.keyCode === rightArrow || e.keyCode === downArrow || e.keyCode === enter || e.keyCode === escape || e.keyCode === space){
            setPrefix(suggestion);
        }
    };
    //handle clear values
    const handleClearValues = async () => {
        //clear search filter values
        document.getElementById('namesearch').innerText = "";
        document.getElementById('namecity').innerText = "";
        document.getElementById('nameneighborhood').innerText = "";
        document.getElementById('nameprice').innerText = "";
        //create variables to assing new search cleared value
        var nameSearch = document.getElementById('namesearch').innerText;
        var nameCity = document.getElementById('namesearch').innerText;
        var nameNeighborhood = document.getElementById('nameneighborhood').innerText;
        var namePrice = document.getElementById('nameprice').innerText;
        //reset useStates belonging to these variables
        setNoSubscribeSearch(nameSearch);
        setNoSubscribeSearchCity(nameCity);
        setNoSubscribeSearchNeighborhood(nameNeighborhood);
        setNoSubscribeSearchPrice(namePrice);
    }

    //********set useEffect to display content on page loading********
    //emplementation useEffect to retrieve all online events
    useEffect(() => {
        var tableName = "";
        var signupTable = [];
        var resultatEvents = 0;
        var titleAdvertise = "title_advertise";
        var valueTitle = "IS NOT NULL";
        var endDate = "date_end";
        var idAdvertise = "id_advertise";

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(countrySelected);
        if(signupTable){
            tableName = signupTable.table.table15;
        }

        Axios.post(`${apiUrl}/check/event/advertise/table`, {
            tableNameSeller:tableName,
            titleAdvertise:titleAdvertise,
            valueTitle:valueTitle,
            endDate:endDate,
            idAdvertise:idAdvertise,
        }).then((responseEvent) => {
            resultatEvents = responseEvent.data.length;
            if(resultatEvents === 0){
                setProductEvent(false);
            }else{
                setFetchEvent(responseEvent.data);
                setScrollNoSubscribeModalOpen(true);
                //productcontent
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setProductContent(true);
                setProductEvent(true);
                //
                setSearchCountry(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //show modals
                setShowModal(false);
                setShowModalCountry(false);
                setShowModalClose(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    }, []);
    //emplementation useEffect to retrieve all online promotions
    useEffect(() => {
        var tableNameDiscount = "";
        var signupTableDiscount = [];
        var resultatPromotions = 0;
        //
        const firstWhere = "date_end";
        const secondWhere = "id_discount";
        //
        const limitNumber = 300;
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table discount
        signupTableDiscount = retrieveTableQuery(countrySelected);
        if(signupTableDiscount){
            tableNameDiscount = signupTableDiscount.table.table7;
        }

        //check all seller subscription where currrent date is < date expiration
        Axios.post(`${apiUrl}/check/valid/product`, {
            tableNameOne:tableNameDiscount,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            limitNumber:limitNumber,
        }).then((responsePromotions) => {
            resultatPromotions = responsePromotions.data.length;
            if(resultatPromotions === 0){
                setProductPromotion(false);
            }else{
                setFetchPromotion(responsePromotions.data);
                setScrollNoSubscribeModalOpen(true);
                //productcontent
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setProductContent(true);
                setProductPromotion(true);
                //
                setSearchCountry(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //show modals
                setShowModal(false);
                setShowModalCountry(false);
                setShowModalClose(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    }, []);
    //emplementation useEffect to retrieve all 300 new products
    useEffect(() => {
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultatView = 0;
        //
        var tableNameProduct = "";
        var signupTableProduct = [];
        //
        const variableOne = "p";
        const variableTwo = "s";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "date_end";
        const fourthWhere = "id_product";
        //
        const limitNumber = 300;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        //check all seller subscription where currrent date is < date expiration
        Axios.post(`${apiUrl}/check/valid/subscriber/product`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            limitNumber:limitNumber,
        }).then((responseProd) => {
            resultatView = responseProd.data.length;
            if(resultatView === 0){
                setProductProducts(false);
            }else{
                setFetchProduct(responseProd.data);
                setScrollNoSubscribeModalOpen(true);
                //productcontent
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setProductContent(true);
                setProductProducts(true);
                //
                setSearchCountry(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //show modals
                setShowModal(false);
                setShowModalCountry(false);
                setShowModalClose(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    }, []);

    const handleNoSubscribeSearch = async () => {
        
        // declare product variables
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultat = 0;
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        //
        var tableNameProfile = "";
        var signupTableProfile = [];
        //
        const variableOne = "p"; //product indent
        const variableTwo = "s"; //subscribe indent
        const variableThree = "f"; //profile indent
        //
        const productUserCode = "code_user";
        const subscribeUserCode = "code_user";
        //
        const columnName = "description_product";
        //var searchWords = searchTerm;
        const dateEndColumn = "date_end";
        const idColumn = "id_product";
        //*****Use those data to query the database after******
        var nameSearchDiv = document.getElementById('namesearch');
        nameSearchDiv.innerText = noSubscribeSearchRef.current.value;
        var nameSearch = document.getElementById('namesearch').innerText;
        var searchWords = nameSearch;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }
        //
        if(!searchWords){
            const pTextePays = t("search_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            if(isOnline()){
                await Axios.post(`${apiUrl}/search/join/one/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    columnName:columnName,
                    searchWords:searchWords,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((response) => {
                    resultat = response.data.length;
                    if(resultat === 0){
                        setShowModal(true);
                        //productcontent
                        setProductContent(false);
                        //productcontentdetails
                        setProductContentDetails(false);
                        //productcontentsubdetails
                        setProductContentSubDetails(false);
                        //
                        //view all product on page mount
                        setShowAllProducts(false);
                        //
                        setSearchCountry(false);
                        //
                        setShowAllProducts(false);
                        setShowFoodProducts(false);
                        setShowBeverageProducts(false);
                        setShowApparelProducts(false);
                        setShowHealthProducts(false);
                        setShowBeautyProducts(false);
                        setShowServicesProducts(false);
                        setShowElectronicProducts(false);
                        setShowAutomotiveProducts(false);
                        setShowAgricultureProducts(false);
                        setShowBreedingProducts(false);
                        setShowHouseProducts(false);
                        setShowSportProducts(false);
                        setShowDecorProducts(false);
                        //
                        setScrollNoSubscribeModalOpen(true);
                        
                        //show modals
                        setShowModalCountry(false);
                        setShowModalClose(false);
                        //
                        setSearchResultModalOpen(false);
                    }else{
                        //
                        var nameSearchDiv = document.getElementById('namesearch');
                        nameSearchDiv.innerText = noSubscribeSearchRef.current.value;
                        var nameSearch = document.getElementById('namesearch').innerText;
                        setNoSubscribeSearch(nameSearch);
                        //productcontent
                        setProductContent(false);
                        //productcontentdetails
                        setProductContentDetails(false);
                        //productcontentsubdetails
                        setProductContentSubDetails(false);
                        //view all product on page mount
                        setShowAllProducts(false);
                        //
                        setSearchCountry(false);
                        //
                        setShowAllProducts(false);
                        setShowFoodProducts(false);
                        setShowBeverageProducts(false);
                        setShowApparelProducts(false);
                        setShowHealthProducts(false);
                        setShowBeautyProducts(false);
                        setShowServicesProducts(false);
                        setShowElectronicProducts(false);
                        setShowAutomotiveProducts(false);
                        setShowAgricultureProducts(false);
                        setShowBreedingProducts(false);
                        setShowHouseProducts(false);
                        setShowSportProducts(false);
                        setShowDecorProducts(false);
                        //
                        setScrollNoSubscribeModalOpen(true);
                        //show modals
                        setShowModal(false);
                        setShowModalCountry(false);
                        setShowModalClose(false);
                        //
                        setNoSubscribeBuyerButtonModalOpen(true);
                        setNoSubscribeSearchModalOpen(true);
                        //inner search
                        setCityModalOpen(false);
                        setNeighborhoodModalOpen(false);
                        setBestPricesModalOpen(false);
                        //search detail
                        setSearchResultModalOpen(false);
                    }
                });
            }else{
                const pTextePays = t("offline_connection");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }
        }
    }

    //Filter search container inside handling event
    const handleNoSubscribeCityFilter = async () => {
        
        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultat = 0;
        const firstParameter = "city_user";
	    const variableOne = "s";
	    const userCodeOne = "code_user";
	    const idColumn = "id_profile";
        //
        var tableNameProduct = "";
        var signupTableProduct = [];
	    const variableTwo = "p";
	    const userCodeTwo = "code_user";
	    const columnName = "description_product";
	    var nameSearch = document.getElementById('namesearch').innerText;
	    const searchWords = nameSearch;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        if(isOnline()){
            await Axios.post(`${apiUrl}/select/distinct/join/one/two/tables`, {
                firstParameter:firstParameter,
                tableNameOne:tableNameProfile,
                variableOne:variableOne,
                userCodeOne:userCodeOne,
                tableNameTwo:tableNameProduct,
                variableTwo:variableTwo,
                userCodeTwo:userCodeTwo,
                columnName:columnName,
                searchWords:searchWords,
                idColumn:idColumn,
            }).then((responseCity) => {
                resultat = responseCity.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_data");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setListCity(responseCity.data);
                    setCityModalOpen(true);
                }
            });//
        }else{
            const pTextePays = t("offline_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }
    //
    const handleNoSubscribeNeighbordFilter = async () => {
        
        var tableNameProfile = "";
        var signupTableProfile = [];
        var resultat = 0;
        const firstParameter = "quartier";
	    const variableOne = "s";
	    const userCodeOne = "code_user";
	    const idColumn = "id_profile";
        //
        var tableNameProduct = "";
        var signupTableProduct = [];
	    const variableTwo = "p";
	    const userCodeTwo = "code_user";
	    const columnName = "description_product";
	    var nameSearch = document.getElementById('namesearch').innerText;
	    const searchWords = nameSearch;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        if(isOnline()){
            await Axios.post(`${apiUrl}/select/distinct/join/one/two/tables`, {
                firstParameter:firstParameter,
                tableNameOne:tableNameProfile,
                variableOne:variableOne,
                userCodeOne:userCodeOne,
                tableNameTwo:tableNameProduct,
                variableTwo:variableTwo,
                userCodeTwo:userCodeTwo,
                columnName:columnName,
                searchWords:searchWords,
                idColumn:idColumn,
            }).then((responseNeighbord) => {
                resultat = responseNeighbord.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_data");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setListNeighbord(responseNeighbord.data);
                    setNeighborhoodModalOpen(true);
                }
            });
        }else{
            const pTextePays = t("offline_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }
    //
    const handleNoSubscribeBestPriceFilter = async () => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultat = 0;
        const firstParameter = "price_product";
		const columnName = "description_product";
		var nameSearch = document.getElementById('namesearch').innerText;
	    const searchWords = nameSearch;
		const idColumn = "id_product";

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table profile
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        if(isOnline()){
            await Axios.post(`${apiUrl}/select/distinct/price/one/table`, {
                firstParameter:firstParameter,
                tableNameOne:tableNameProduct,
                columnName:columnName,
                searchWords:searchWords,
                idColumn:idColumn,
            }).then((responsePrice) => {
                resultat = responsePrice.data.length;
                if(resultat === 0){
                    const pTextePays = t("error_data");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setListPrice(responsePrice.data);
                    setBestPricesModalOpen(true);
                }
            });
        }else{
            const pTextePays = t("offline_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }
    //
    const handleModalClose = () => {
        setCityModalOpen(false);
        setNeighborhoodModalOpen(false);
        setBestPricesModalOpen(false);
    };
    //
    const handleModalCityConfirm = async () => {

        // Handle the input value from the modal
        // Apply the filter and update the product list state accordingly
        var nameCityDiv = document.getElementById('namecity');
        nameCityDiv.innerText = noSubscribeSearchCityRef.current.value;
        var nameCity = document.getElementById('namecity').innerText;
        setNoSubscribeSearchCity(nameCity);
        handleModalClose();
    }
    //
    const handleModalNeighborhoodConfirm = async () => {

        // Handle the input value from the modal
        // Apply the filter and update the product list state accordingly
        var nameNeighborhoodDiv = document.getElementById('nameneighborhood');
        nameNeighborhoodDiv.innerText = noSubscribeSearchNeighborhoodRef.current.value;
        var nameNeighborhood = document.getElementById('nameneighborhood').innerText;
        setNoSubscribeSearchNeighborhood(nameNeighborhood);
        handleModalClose();
    }
    //
    const handleModalPricesConfirm = async () => {

        // Handle the input value from the modal
        // Apply the filter and update the product list state accordingly
        var namePriceDiv = document.getElementById('nameprice');
        namePriceDiv.innerText = noSubscribeSearchPriceRef.current.value;
        var namePrice = document.getElementById('nameprice').innerText;
        setNoSubscribeSearchPrice(parseFloat(namePrice));
        handleModalClose();
    }
    //
    const handleGoSearchNoSubscribeBuyer = async () => {
        
        // declare product variables
        var tableNameProduct = "";
        var signupTableProduct = [];
        var resultatSearch = 0;
        // declare subscribe variables 
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        //
        var tableNameProfile = "";
        var signupTableProfile = [];
        //
        const variableOne = "p"; //product indent
        const variableTwo = "s"; //subscribe indent
        const variableThree = "f"; //profile indent
        //
        const productUserCode = "code_user";
        const subscribeUserCode = "code_user";
        const profileUserCode = "code_user";
        //
        const columnName = "description_product";
        const dateEndColumn = "date_end";
        const idColumn = "id_product";
        //*****Use those data to query the database after******
        const searchWords = noSubscribeSearch;
        const searchCity = noSubscribeSearchCity;
        const searchNeighborhood = noSubscribeSearchNeighborhood;
        const searchPrice = noSubscribeSearchPrice;

        //search join two fields
        const columnNameOne = "description_product";
        const searchWordsOne = noSubscribeSearch;
        const columnNameTwo = "quartier";
        const searchWordsTwo = searchNeighborhood;
        const columnNameThree = "price_product";
        const searchWordsThree = searchPrice;
        const searchWordsFour = searchCity;
        const columnNameFour = "city_user";
        
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        if(isOnline()){
            //use case
            //case 1 only search words (OK)
            if(searchWords && !searchCity && !searchNeighborhood && !searchPrice){
                await Axios.post(`${apiUrl}/search/join/one/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    columnName:columnName,
                    searchWords:EscapeApostrophe(searchWords),
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 2 using searchNeighborhood
            if(searchWords && !searchCity && searchNeighborhood && !searchPrice){
                await Axios.post(`${apiUrl}/search/join/two/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameTwo,
                    searchWordsTwo:EscapeApostrophe(searchWordsTwo),
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 3 using searchNeighborhood & price
            if(searchWords && !searchCity && searchNeighborhood && searchPrice){
            
                await Axios.post(`${apiUrl}/search/join/three/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameTwo,
                    searchWordsTwo:EscapeApostrophe(searchWordsTwo),
                    columnNameThree:columnNameThree,
                    searchWordsThree:searchWordsThree,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 4 using only city (OK)
            if(searchWords && searchCity && !searchNeighborhood && !searchPrice){
                await Axios.post(`${apiUrl}/search/join/two/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameFour,
                    searchWordsTwo:EscapeApostrophe(searchWordsFour),
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 5 using city & neiboborhood (OK)
            if(searchWords && searchCity && searchNeighborhood && !searchPrice){
                await Axios.post(`${apiUrl}/search/join/city/neighbord/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameTwo,
                    searchWordsTwo:EscapeApostrophe(searchWordsTwo),
                    columnNameFour:columnNameFour,
                    searchWordsFour:EscapeApostrophe(searchWordsFour),
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 6 using city & neighbordhood & price OK
            if(searchWords && searchCity && searchNeighborhood && searchPrice){
            
                await Axios.post(`${apiUrl}/search/join/city/neighbord/price/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameTwo,
                    searchWordsTwo:EscapeApostrophe(searchWordsTwo),
                    columnNameFour:columnNameFour,
                    searchWordsFour:EscapeApostrophe(searchWordsFour),
                    columnNamePrice: columnNameThree,
                    searchWordsPrice:searchWordsThree,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 7 using only price (OK)
            if(searchWords && !searchCity && !searchNeighborhood && searchPrice){
            
                await Axios.post(`${apiUrl}/search/join/price/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:searchWordsOne,
                    columnNameThree:columnNameThree,
                    searchWordsThree:searchWordsThree,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //case 8 using city & price (OK)
            if(searchWords && searchCity && !searchNeighborhood && searchPrice){
            
                await Axios.post(`${apiUrl}/search/join/three/two/tables`, {
                    tableNameOne:tableNameProduct,
                    variableOne:variableOne,
                    productUserCode:productUserCode,
                    tableNameTwo:tableNameSubscribe,
                    variableTwo:variableTwo,
                    tableNameThree:tableNameProfile,
                    variableThree:variableThree,
                    subscribeUserCode:subscribeUserCode,
                    profileUserCode:profileUserCode,
                    columnNameOne:columnNameOne,
                    searchWordsOne:EscapeApostrophe(searchWordsOne),
                    columnNameTwo:columnNameFour,//
                    searchWordsTwo:EscapeApostrophe(searchWordsFour),//
                    columnNameThree:columnNameThree,
                    searchWordsThree:searchWordsThree,
                    dateEndColumn:dateEndColumn,
                    idColumn:idColumn,
                }).then((responseSearch) => {
                    resultatSearch = responseSearch.data.length;
                    if(resultatSearch === 0){
                        setShowModal(true);
                    }else{
                        setUserSearchProducts(responseSearch.data);
                    }
                });
            }
            //productcontent
            setProductContent(false);
            //productcontentdetails
            setProductContentDetails(false);
            //productcontentsubdetails
            setProductContentSubDetails(false);
            //view all product on page mount
            setShowAllProducts(false);
            //
            setSearchCountry(false);
            //
            setShowAllProducts(false);
            setShowFoodProducts(false);
            setShowBeverageProducts(false);
            setShowApparelProducts(false);
            setShowHealthProducts(false);
            setShowBeautyProducts(false);
            setShowServicesProducts(false);
            setShowElectronicProducts(false);
            setShowAutomotiveProducts(false);
            setShowAgricultureProducts(false);
            setShowBreedingProducts(false);
            setShowHouseProducts(false);
            setShowSportProducts(false);
            setShowDecorProducts(false);
            //
            setScrollNoSubscribeModalOpen(true);
            //show modals
            setShowModal(false);
            setShowModalCountry(false);
            setShowModalClose(false);
            //
            setNoSubscribeBuyerButtonModalOpen(true);
            setNoSubscribeSearchModalOpen(false);
            //inner search
            setCityModalOpen(false);
            setNeighborhoodModalOpen(false);
            setBestPricesModalOpen(false);
            //search detail
            setSearchResultModalOpen(true);
        }else{
            const pTextePays = t("offline_connection");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }
    }
    //handle search product
    const handleSearchProduct = async (productId) => {
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //cancel search when search doesn't match
    const handleCancelSearch = async () => {
        //clear all ref based on city, neighborhood and price
        var searchName = "";
        var cityName = "";
        var neighborhood = "";
        var pricename = "";
        var searchNameDiv = document.getElementById('namesearch');
        searchNameDiv.innerText = searchName;
        var nameCityDiv = document.getElementById('namecity');
        nameCityDiv.innerText = cityName;
        var nameNeighborhoodDiv = document.getElementById('nameneighborhood');
        nameNeighborhoodDiv.innerText = neighborhood;
        var namePriceDiv = document.getElementById('nameprice');
        namePriceDiv.innerText = pricename;
        //
        setShowModal(false);
        UseFetchData();
    }


    //*******content scroller**********
    //******List All products handler**********
    const noSubscribeAllHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "all";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        // declare subscribe variables 
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat1 = 0;
        //
        var tableNameProduct = "";
        var signupTableProduct = [];
        //
        const variableOne = "p";
        const variableTwo = "s";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "date_end";
        const fourthWhere = "id_product";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/valid/subscriber/product`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            limitNumber:limitNumber,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                setShowAllProducts(false);
            }else{
                setAllProducts(response1.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(true);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleAllProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Food products handler*************
    const noSubscribeFoodHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "food";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "food";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowFoodProducts(false);
            }else{
                setFoodProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(true);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                setProductContent(false);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleFoodProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Beverage products handler*************
    const noSubscribeBeverageHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "beverage";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "beverage";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowBeverageProducts(false);
            }else{
                setBeverageProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(true);
                setShowApparelProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleBeverageProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Apparel products handler*************
    const noSubscribeApparelHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "apparel";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "apparel";
        //
        const limitNumber = 30;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowApparelProducts(false);
            }else{
                setApparelProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(true);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleApparelProduct = async (productId) => {

        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Health products handler*************
    const noSubscribeHealthHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "health";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "health";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowHealthProducts(false);
            }else{
                setHealthProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(true);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleHealthProduct = async (productId) => {

        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Beauty products handler*************
    const noSubscribeBeautyHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "beauty";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "beauty";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowBeautyProducts(false);
            }else{
                setBeautyProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(true);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                setProductContent(false);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleBeautyProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setProductContent(false);
        setProductProducts(false);
            //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Services products handler*************
    const noSubscribeServicesHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "services";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "services";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowServicesProducts(false);
            }else{
                setServicesProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(true);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleServicesProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setProductContent(false);
        setProductProducts(false);
            //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }

    
    //***************List Electronic products handler*************
    const noSubscribeElectronicHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "electronic";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "electronic";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowElectronicProducts(false);
            }else{
                setElectronicProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(true);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleElectronicProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setProductContent(false);
        setProductProducts(false);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Automotive products handler*************
    const noSubscribeAutomotiveHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "automotive";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "automotive";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowAutomotiveProducts(false);
            }else{
                setAutomotiveProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(true);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleAutomotiveProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Agriculture products handler*************
    const noSubscribeAgricultureHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "agriculture";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "agriculture";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowAgricultureProducts(false);
            }else{
                setAgricultureProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(true);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleAgricultureProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Breeding products handler*************
    const noSubscribeBreedingHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "breeding";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "elevage";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowBreedingProducts(false);
            }else{
                setBreedingProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(true);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleBreedingProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //setProductProducts(false);
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List House products handler*************
    const noSubscribeHouseHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "house";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "house";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowHouseProducts(false);
            }else{
                setHouseProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(true);
                setShowSportProducts(false);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleHouseProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Sport products handler*************
    const noSubscribeSportHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "sport";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "sport";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowSportProducts(false);
            }else{
                setSportProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(true);
                setShowDecorProducts(false);
                //
                setScrollNoSubscribeModalOpen(true); 
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    };
    //
    const handleSportProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }


    //***************List Decor products handler*************
    const noSubscribeDecorHandler = async (e) => {
        e.preventDefault();

        //this allows to set the main product type selected
        var productType = "decor";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;

        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat2 = 0;

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "p";
        const variableTwo = "s";
        const variableThree = "f";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "code_user";
        const fourthWhere = "secteur";
        const fithWhere = "date_end";
        const sixthWhere = "id_product";
        //
        const firstParameter = "decor";
        //
        const limitNumber = 50;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(countrySelected);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/join/three/tables`, {
            tableNameOne:tableNameProduct,
            variableOne:variableOne,
            tableNameTwo:tableNameSubscribe,
            variableTwo:variableTwo,
            tableNameThree:tableNameProfile,
            variableThree:variableThree,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            fithWhere:fithWhere,
            sixthWhere:sixthWhere,
            firstParameter:firstParameter,
            limitNumber:limitNumber,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                setShowDecorProducts(false);
            }else{
                setDecorProducts(response2.data);
                //productcontent
                setProductContent(false);
                //productcontentdetails
                setProductContentDetails(false);
                //productcontentsubdetails
                setProductContentSubDetails(false);
                //
                setShowAllProducts(false);
                setShowFoodProducts(false);
                setShowBeverageProducts(false);
                setShowApparelProducts(false);
                setShowHealthProducts(false);
                setShowBeautyProducts(false);
                setShowServicesProducts(false);
                setShowElectronicProducts(false);
                setShowAutomotiveProducts(false);
                setShowAgricultureProducts(false);
                setShowBreedingProducts(false);
                setShowHouseProducts(false);
                setShowSportProducts(false);
                setShowDecorProducts(true);
                //
                setScrollNoSubscribeModalOpen(true);
                setProductContent(false);
                //
                setSearchCountry(false);
                setShowModalCountry(false);
                //
                setNoSubscribeBuyerButtonModalOpen(false);
                setNoSubscribeSearchModalOpen(false);
                //
                setSearchResultModalOpen(false);
            }
        });
    }
    //
    const handleDecorProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //product description
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }

    //handling event
    const handleVideo = async (videoId) => {
        var tableNameVideo = "";
        var signupTableVideo = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table name based on country
        signupTableVideo = retrieveTableQuery(countrySelected);
        if(signupTableVideo){
            tableNameVideo = signupTableVideo.table.table15;
        }

        const firstWhere = "code_user";
        const firstParameter = videoId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameVideo, firstWhere, firstParameter),
        ]);

        setUserEventsVideo(dataTable1);
        var videoLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<videoLength; i++){
            currentViews = dataTable1[i].views_advertise;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentEventViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //events
        setNewEventsModalOpen(true);
        //promotion description
        setNewPromotionsModalOpen(false);
        //product description
        setNewProductsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }
    //close event video
    const handlerCloseEventsVideo = async () => {

        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setShowModalClose(false);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
        UseFetchData();
    }
    //
    const handlerDirectCallEvent = async (videoId) => {
        //initialize variables
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";
        //
        const firstWhere = "code_user";
        const firstParameter = videoId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateNoSubscribeBuyer('/userlogin');
        }else{
            //retrieve buyer's inter prefixe, phone number and mode contact
            // Use Promise.all to execute multiple requests in parallel
            const [dataTable1] = await Promise.all([
                fetchDataFromTable(tableNameSeller, firstWhere, firstParameter),
            ]);
            resultatCont = dataTable1.length;
            viewContactData = dataTable1;
            for(let i=0; i<resultatCont; i++){
                prefixInter = viewContactData[i].prefixe;
                phoneNumber = viewContactData[i].phone;
            }

            //create a direct call feature
            var completePhone = prefixInter+phoneNumber;
            window.location.href = `tel:${completePhone}`;
        }
    }
    //handle start chat room
    const handlerChatRoomEvent = async (videoId) => {
        //initialize variables
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";
        //
        const firstWhere = "code_user";
        const firstParameter = videoId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateNoSubscribeBuyer('/userlogin');
        }else{
            //retrieve buyer's inter prefixe, phone number and mode contact
            // Use Promise.all to execute multiple requests in parallel
            const [dataTable1] = await Promise.all([
                fetchDataFromTable(tableNameSeller, firstWhere, firstParameter),
            ]);
            resultatCont = dataTable1.length;
            viewContactData = dataTable1;

            for(let i=0; i<resultatCont; i++){
                prefixInter = viewContactData[i].prefixe;
                phoneNumber = viewContactData[i].phone;
            }

            //create a whatsapp call feature
            WhatsappCall(prefixInter, phoneNumber);
        }
    }

    const handleLikeEvent = async (videoId) => {

        var tableNameVideo = "";
        var signupTableVideo = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table name based on country
        signupTableVideo = retrieveTableQuery(countrySelected);
        if(signupTableVideo){
            tableNameVideo = signupTableVideo.table.table15;
        }

        //edit table advertise incrementing views
        const firstSet = 'views_advertise';
        //const firstSetParameter = parseFloat(eventViewsRef.current.value) + 1;
        const firstWhereId = "code_user";
        const firstParameterId = videoId;
        await Axios.post(`${apiUrl}/update/one/parameter/table`, {
            tableName: tableNameVideo,
            firstSet: firstSet,
            firstSetParameter: currentEventViews.toString(),
            firstWhereId: firstWhereId,
            firstParameterId: firstParameterId,
        });
    }


    //handling promotion
    const handleDiscount = async (promotionId) => {
        var tableNameDiscount = "";
        var signupTableDiscount = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table discount
        signupTableDiscount = retrieveTableQuery(countrySelected);
        if(signupTableDiscount){
            tableNameDiscount = signupTableDiscount.table.table7;
        }
        const firstWhere = "code_user";
        const firstParameter = promotionId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameDiscount, firstWhere, firstParameter),
        ]);

        setUserPromotions(dataTable1);
        var promotionLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<promotionLength; i++){
            currentViews = dataTable1[i].views_discount;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentPromotionViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        setNewPromotionsModalOpen(true);
        setNewEventsModalOpen(false);
        setNewProductsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }
    //
    const handlerClosePromotion = async () => {

        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setShowModalClose(false);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
        UseFetchData();
    }
    //
    const handlerDirectCallPromotion = async (allId) => {
        //initialize variables
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";
        //
        const firstWhere = "code_user";
        const firstParameter = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateNoSubscribeBuyer('/userlogin');
        }else{
            //retrieve buyer's inter prefixe, phone number and mode contact
            // Use Promise.all to execute multiple requests in parallel
            const [dataTable1] = await Promise.all([
                fetchDataFromTable(tableNameSeller, firstWhere, firstParameter),
            ]);
            resultatCont = dataTable1.length;
            viewContactData = dataTable1;
            for(let i=0; i<resultatCont; i++){
                prefixInter = viewContactData[i].prefixe;
                phoneNumber = viewContactData[i].phone;
            }

            //create a direct call feature
            var completePhone = prefixInter+phoneNumber;
            window.location.href = `tel:${completePhone}`;
        }
    }
    //
    //handle start chat room
    const handlerChatRoomPromotion = async (allId) => {
        //initialize variables
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";
        //
        const firstWhere = "code_user";
        const firstParameter = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateNoSubscribeBuyer('/userlogin');
        }else{
            //retrieve buyer's inter prefixe, phone number and mode contact
            // Use Promise.all to execute multiple requests in parallel
            const [dataTable1] = await Promise.all([
                fetchDataFromTable(tableNameSeller, firstWhere, firstParameter),
            ]);
            resultatCont = dataTable1.length;
            viewContactData = dataTable1;

            for(let i=0; i<resultatCont; i++){
                prefixInter = viewContactData[i].prefixe;
                phoneNumber = viewContactData[i].phone;
            }

            //create a whatsapp call feature
            WhatsappCall(prefixInter, phoneNumber);
        }
    }

    //hadle cancel contact modal
    const handleCancelContact = async () => {
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;
        //clear all ref based on city, neighborhood and price
        var searchName = "";
        var cityName = "";
        var neighborhood = "";
        var pricename = "";
        var searchNameDiv = document.getElementById('namesearch');
        searchNameDiv.innerText = searchName;
        var nameCityDiv = document.getElementById('namecity');
        nameCityDiv.innerText = cityName;
        var nameNeighborhoodDiv = document.getElementById('nameneighborhood');
        nameNeighborhoodDiv.innerText = neighborhood;
        var namePriceDiv = document.getElementById('nameprice');
        namePriceDiv.innerText = pricename;
        //
        setShowModalContact(false);
    }
    //
    const handleLikePromotion = async (promotionId) => {
        var tableNameDiscount = "";
        var signupTableDiscount = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table discount
        signupTableDiscount = retrieveTableQuery(countrySelected);
        if(signupTableDiscount){
            tableNameDiscount = signupTableDiscount.table.table7;
        }

        //edit table advertise incrementing views
        const firstSet = 'views_discount';
        //const firstSetParameter = parseFloat(eventViewsRef.current.value) + 1;
        const firstWhereId = "code_user";
        const firstParameterId = promotionId;
        await Axios.post(`${apiUrl}/update/one/parameter/table`, {
            tableName: tableNameDiscount,
            firstSet: firstSet,
            firstSetParameter: currentPromotionViews.toString(),
            firstWhereId: firstWhereId,
            firstParameterId: firstParameterId,
        });
    }

    //handling product
    const handleProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        const firstWhere = "code_user";
        const firstParameter = productId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProduct, firstWhere, firstParameter),
        ]);

        setUserProducts(dataTable1);
        var productLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<productLength; i++){
            currentViews = dataTable1[i].views_product;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentProductViews(updatedViews);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        setNewProductsModalOpen(true);
        setNewPromotionsModalOpen(false);
        setNewEventsModalOpen(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setProductContent(false);
        setProductProducts(false);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }
    //
    const handleLikeProduct = async (productId) => {
        
        var tableNameProduct = "";
        var signupTableProduct = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }

        //edit table advertise incrementing views
        const firstSet = 'views_product';
        //const firstSetParameter = parseFloat(eventViewsRef.current.value) + 1;
        const firstWhereId = "code_user";
        const firstParameterId = productId;
        await Axios.post(`${apiUrl}/update/one/parameter/table`, {
            tableName: tableNameProduct,
            firstSet: firstSet,
            firstSetParameter: currentProductViews.toString(),
            firstWhereId: firstWhereId,
            firstParameterId: firstParameterId,
        });
    }
    //
    const handlerCloseProduct = async () => {

        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
        UseFetchData();
    }
    //
    const handlerProductCategory = async (allId) => {
        var tableNameCategory = "";
        var signupTableCategory = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table discount
        signupTableCategory = retrieveTableQuery(countrySelected);
        if(signupTableCategory){
            tableNameCategory = signupTableCategory.table.table9;
        }
        const firstWhere = "code_user";
        const firstParameter = allId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameCategory, firstWhere, firstParameter),
        ]);

        setUserCategory(dataTable1);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(false);
        //productcontentsubdetails
        setProductContentSubDetails(true);
        //category
        setNewCategoryModalOpen(true);
        //profile
        setNewProfileModalOpen(false);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }
    //
    const handlerProductProfile = async (allId) => {
        var tableNameProfile = "";
        var signupTableProfile = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table discount
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }
        const firstWhere = "code_user";
        const firstParameter = allId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameProfile, firstWhere, firstParameter),
        ]);

        setUserProfile(dataTable1);
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(false);
        //productcontentsubdetails
        setProductContentSubDetails(true);
        //category
        setNewCategoryModalOpen(false);
        //profile
        setNewProfileModalOpen(true);
        //
        setScrollNoSubscribeModalOpen(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }
    //
    const handlerCloseProfile = async () => {
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setShowModalClose(false);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
        //UseFetchData();
    }
    //
    const handlerDirectCallProduct = async (allId) => {
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = allId;
        const productUserCode = allId;
        const profileUserCode = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateNoSubscribeBuyer('/userlogin');
        }else{
            //join these three tables to retrieve specific data
            await Axios.post(`${apiUrl}/search/join/three/tables`, {
                tableNameSeller:tableNameSeller,
                variableSeller:variableOne,
                columnNameOne:columnNameOne,
                sellerUserCode:sellerUserCode,
                tableNameProduct:tableNameProduct,
                variableProduct:variableTwo,
                columnNameTwo:columnNameTwo,
                productUserCode:productUserCode,
                tableNameProfile:tableNameProfile,
                variableProfile:variableThree,
                columnNameThree:columnNameThree,
                profileUserCode:profileUserCode,
            }).then((responseCont) => {
                resultatCont = responseCont.data.length;
                if(resultatCont === 0){
                    const pTextePays = t("error_detail");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    viewContactData = responseCont.data;
                    for(let i=0; i<resultatCont; i++){
                        prefixInter = viewContactData[i].prefixe;
                        phoneNumber = viewContactData[i].phone;
                    }

                    //create a direct call feature
                    var completePhone = prefixInter+phoneNumber;
                    window.location.href = `tel:${completePhone}`;
                }
            });
        }
    }
    //
    const handlerChatRoomProduct = async (allId) => {
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = allId;
        const productUserCode = allId;
        const profileUserCode = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateNoSubscribeBuyer('/userlogin');
        }else{
            //join these three tables to retrieve specific data
            await Axios.post(`${apiUrl}/search/join/three/tables`, {
                tableNameSeller:tableNameSeller,
                variableSeller:variableOne,
                columnNameOne:columnNameOne,
                sellerUserCode:sellerUserCode,
                tableNameProduct:tableNameProduct,
                variableProduct:variableTwo,
                columnNameTwo:columnNameTwo,
                productUserCode:productUserCode,
                tableNameProfile:tableNameProfile,
                variableProfile:variableThree,
                columnNameThree:columnNameThree,
                profileUserCode:profileUserCode,
            }).then((responseCont) => {
                resultatCont = responseCont.data.length;
                if(resultatCont === 0){
                    const pTextePays = t("error_detail");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    viewContactData = responseCont.data;
                    for(let i=0; i<resultatCont; i++){
                        prefixInter = viewContactData[i].prefixe;
                        phoneNumber = viewContactData[i].phone;
                    }

                    //create a whatsapp call feature
                    WhatsappCall(prefixInter, phoneNumber);
                }
            });
        }
    }

    //emplement category*********
    const handlerCloseCategory = async () => {
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(true);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setShowModalClose(false);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
        //UseFetchData();
    }
    //
    const handlerDirectCallCategory = async (allId) => {
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = allId;
        const productUserCode = allId;
        const profileUserCode = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateNoSubscribeBuyer('/userlogin');
        }else{
            //join these three tables to retrieve specific data
            await Axios.post(`${apiUrl}/search/join/three/tables`, {
                tableNameSeller:tableNameSeller,
                variableSeller:variableOne,
                columnNameOne:columnNameOne,
                sellerUserCode:sellerUserCode,
                tableNameProduct:tableNameProduct,
                variableProduct:variableTwo,
                columnNameTwo:columnNameTwo,
                productUserCode:productUserCode,
                tableNameProfile:tableNameProfile,
                variableProfile:variableThree,
                columnNameThree:columnNameThree,
                profileUserCode:profileUserCode,
            }).then((responseCont) => {
                resultatCont = responseCont.data.length;
                if(resultatCont === 0){
                    const pTextePays = t("error_detail");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    viewContactData = responseCont.data;
                    for(let i=0; i<resultatCont; i++){
                        prefixInter = viewContactData[i].prefixe;
                        phoneNumber = viewContactData[i].phone;
                    }

                    //create a direct call feature
                    var completePhone = prefixInter+phoneNumber;
                    window.location.href = `tel:${completePhone}`;
                }
            });
        }
    }
    //
    const handlerChatRoomCategory = async (allId) => {
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";

        var tableNameProduct = "";
        var signupTableProduct = [];

        var tableNameProfile = "";
        var signupTableProfile = [];

        //
        const variableOne = "s";
        const variableTwo = "p";
        const variableThree = "f";
        //
        const columnNameOne = "code_user";
        const columnNameTwo = "code_user";
        const columnNameThree = "code_user";
        //
        const sellerUserCode = allId;
        const productUserCode = allId;
        const profileUserCode = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }
        //retrieve table product
        signupTableProduct = retrieveTableQuery(countrySelected);
        if(signupTableProduct){
            tableNameProduct = signupTableProduct.table.table4;
        }
        //retrieve table profile
        signupTableProfile = retrieveTableQuery(countrySelected);
        if(signupTableProfile){
            tableNameProfile = signupTableProfile.table.table3;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateNoSubscribeBuyer('/userlogin');
        }else{
            //join these three tables to retrieve specific data
            await Axios.post(`${apiUrl}/search/join/three/tables`, {
                tableNameSeller:tableNameSeller,
                variableSeller:variableOne,
                columnNameOne:columnNameOne,
                sellerUserCode:sellerUserCode,
                tableNameProduct:tableNameProduct,
                variableProduct:variableTwo,
                columnNameTwo:columnNameTwo,
                productUserCode:productUserCode,
                tableNameProfile:tableNameProfile,
                variableProfile:variableThree,
                columnNameThree:columnNameThree,
                profileUserCode:profileUserCode,
            }).then((responseCont) => {
                resultatCont = responseCont.data.length;
                if(resultatCont === 0){
                    const pTextePays = t("error_detail");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    viewContactData = responseCont.data;
                    for(let i=0; i<resultatCont; i++){
                        prefixInter = viewContactData[i].prefixe;
                        phoneNumber = viewContactData[i].phone;
                    }

                    //create a whatsapp call feature
                    WhatsappCall(prefixInter, phoneNumber);
                }
            });
        }
    }

    //Implement modals
    const handleClosePage = async () => {
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;
        //clear all ref based on city, neighborhood and price
        //clear search filter values
        document.getElementById('namesearch').innerText = "";
        document.getElementById('namecity').innerText = "";
        document.getElementById('nameneighborhood').innerText = "";
        document.getElementById('nameprice').innerText = "";
        //create variables to assing new search cleared value
        var nameSearch = document.getElementById('namesearch').innerText;
        var nameCity = document.getElementById('namesearch').innerText;
        var nameNeighborhood = document.getElementById('nameneighborhood').innerText;
        var namePrice = document.getElementById('nameprice').innerText;
        //reset useStates belonging to these variables
        setNoSubscribeSearch(nameSearch);
        setNoSubscribeSearchCity(nameCity);
        setNoSubscribeSearchNeighborhood(nameNeighborhood);
        setNoSubscribeSearchPrice(namePrice);
        //
        setShowModalClose(false);
        //
        navigateNoSubscribeBuyer('/starting')
    }
    //
    const handleCancelPage = async () => {
        //
        var productType = "";
        var typeProduitDiv = document.getElementById('typeproduit');
        typeProduitDiv.innerText = productType;
        //clear all ref based on city, neighborhood and price
        //clear search filter values
        document.getElementById('namesearch').innerText = "";
        document.getElementById('namecity').innerText = "";
        document.getElementById('nameneighborhood').innerText = "";
        document.getElementById('nameprice').innerText = "";
        //create variables to assing new search cleared value
        var nameSearch = document.getElementById('namesearch').innerText;
        var nameCity = document.getElementById('namesearch').innerText;
        var nameNeighborhood = document.getElementById('nameneighborhood').innerText;
        var namePrice = document.getElementById('nameprice').innerText;
        //reset useStates belonging to these variables
        setNoSubscribeSearch(nameSearch);
        setNoSubscribeSearchCity(nameCity);
        setNoSubscribeSearchNeighborhood(nameNeighborhood);
        setNoSubscribeSearchPrice(namePrice);
        //
        setShowModalClose(false);
        // Then call this custom hook wherever needed
        UseFetchData();
    }
    
    //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
    const handleGoBack = async () => {

        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setShowModalClose(false);
        //
        setSearchCountry(false);
        setShowModalCountry(true);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
        //
        document.getElementById('searchcountry').innerText = '';
        document.getElementById('countrysearch').innerText = '';
    }

    //search country handler
    //Gambia
    const handleGambia = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "gambie";
        document.getElementById('countrysearch').innerText = englishCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData(); //call function
        }
    }
    //Ghana
    const handleGhana = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "ghana";
        document.getElementById('countrysearch').innerText = englishCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //Liberia
    const handleLiberia = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "liberia";
        document.getElementById('countrysearch').innerText = englishCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //Nigeria
    const handleNigeria = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "nigeria";
        document.getElementById('countrysearch').innerText = englishCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //sierraleone
    const handleSierraleone = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "sierraleone";
        document.getElementById('countrysearch').innerText = englishCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //benin
    const handleBenin = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "benin";
        document.getElementById('countrysearch').innerText = frenchCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //burkina
    const handleBurkina = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "burkina";
        document.getElementById('countrysearch').innerText = frenchCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //guinee-conakty
    const handleGuinee = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "guinee";
        document.getElementById('countrysearch').innerText = frenchCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //ivoire
    const handleIvoire = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "ivoire";
        document.getElementById('countrysearch').innerText = frenchCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //mali
    const handleMali = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "mali";
        document.getElementById('countrysearch').innerText = frenchCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //niger
    const handleNiger = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "niger";
        document.getElementById('countrysearch').innerText = frenchCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //senegal
    const handleSenegal = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "senegal";
        document.getElementById('countrysearch').innerText = frenchCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //togo
    const handleTogo = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "togo";
        document.getElementById('countrysearch').innerText = frenchCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //cap vert
    const handleCapvert = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const portugueseCountry = "capvert";
        document.getElementById('countrysearch').innerText = portugueseCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = portugueseCountry;
        countryInfo = retrieveCodeAndName(portugueseCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(portugueseCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //guinee bissau
    const handleGuineeBissau = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const portugueseCountry = "guineebissau";
        document.getElementById('countrysearch').innerText = portugueseCountry;
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = portugueseCountry;
        countryInfo = retrieveCodeAndName(portugueseCountry);
        if(countryInfo){
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            searchCountry.innerText = t("search_country") + NomPays(portugueseCountry);
            //
            setSearchCountry(false);
            setShowModalCountry(false);
            UseFetchData();
        }
    }
    //event handler for country and search country
    const searchByCountryHandler = async () => {

        //clear search filter values
        document.getElementById('namesearch').innerText = "";
        document.getElementById('namecity').innerText = "";
        document.getElementById('nameneighborhood').innerText = "";
        document.getElementById('nameprice').innerText = "";
        //create variables to assing new search cleared value
        var nameSearch = document.getElementById('namesearch').innerText;
        var nameCity = document.getElementById('namesearch').innerText;
        var nameNeighborhood = document.getElementById('nameneighborhood').innerText;
        var namePrice = document.getElementById('nameprice').innerText;
        //reset useStates belonging to these variables
        setNoSubscribeSearch(nameSearch);
        setNoSubscribeSearchCity(nameCity);
        setNoSubscribeSearchNeighborhood(nameNeighborhood);
        setNoSubscribeSearchPrice(namePrice);
        //
        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setShowModalClose(false);
        //
        setSearchCountry(true);
        setShowModalCountry(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }
    //
    const NoSearchByCountryHandler = async () => {

        //productcontent
        setProductContent(false);
        //productcontentdetails
        setProductContentDetails(false);
        //productcontentsubdetails
        setProductContentSubDetails(false);
        //
        setShowAllProducts(false);
        setShowFoodProducts(false);
        setShowBeverageProducts(false);
        setShowApparelProducts(false);
        setShowHealthProducts(false);
        setShowBeautyProducts(false);
        setShowServicesProducts(false);
        setShowElectronicProducts(false);
        setShowAutomotiveProducts(false);
        setShowAgricultureProducts(false);
        setShowBreedingProducts(false);
        setShowHouseProducts(false);
        setShowSportProducts(false);
        setShowDecorProducts(false);
        //
        setScrollNoSubscribeModalOpen(true);
        setShowModalClose(true);
        //
        setSearchCountry(false);
        setShowModalCountry(false);
        //
        setNoSubscribeBuyerButtonModalOpen(false);
        setNoSubscribeSearchModalOpen(false);
        //
        setSearchResultModalOpen(false);
    }

    //***Internal functions****************
    const UseFetchData = async () => {
        //emplementation function to retrieve all online events
        const OnlineEvents = async () => {
            var tableName = "";
            var signupTable = [];
            var resultatEvents = 0;
            var titleAdvertise = "title_advertise";
            var valueTitle = "IS NOT NULL";
            var endDate = "date_end";
            var idAdvertise = "id_advertise";

            const codecountryDiv = document.getElementById('codecountry').innerText;
            const searchcountryDiv = document.getElementById('countrysearch').innerText;
            var countrySelected = '';
            if(searchcountryDiv){
                countrySelected = searchcountryDiv;
            }else{
                countrySelected = codecountryDiv;
            }
            //retrieve table2 to use in backend
            signupTable = retrieveTableQuery(countrySelected);
            if(signupTable){
                tableName = signupTable.table.table15;
            }
            await Axios.post(`${apiUrl}/check/event/advertise/table`, {
                tableNameSeller:tableName,
                titleAdvertise:titleAdvertise,
                valueTitle:valueTitle,
                endDate:endDate,
                idAdvertise:idAdvertise,
            }).then((responseEvent) => {
                resultatEvents = responseEvent.data.length;
                if(resultatEvents === 0){
                    setProductEvent(false);
                }else{
                    setFetchEvent(responseEvent.data);
                    //productcontent
                    setProductContent(true);
                    setProductEvent(true);
                    //productcontentdetails
                    setProductContentDetails(false);
                    //productcontentsubdetails
                    setProductContentSubDetails(false);
                    //
                    setScrollNoSubscribeModalOpen(true);
                    //
                    setSearchCountry(false);
                    setShowModalCountry(false);
                    setShowAllProducts(false);
                    setShowFoodProducts(false);
                    setShowBeverageProducts(false);
                    setShowApparelProducts(false);
                    setShowHealthProducts(false);
                    setShowBeautyProducts(false);
                    setShowServicesProducts(false);
                    setShowElectronicProducts(false);
                    setShowAutomotiveProducts(false);
                    setShowAgricultureProducts(false);
                    setShowBreedingProducts(false);
                    setShowHouseProducts(false);
                    setShowSportProducts(false);
                    setShowDecorProducts(false);
                    //
                    setNoSubscribeBuyerButtonModalOpen(false);
                    setNoSubscribeSearchModalOpen(false);
                    //
                    setSearchResultModalOpen(false);
                }
            });
        };
        //emplementation function to retrieve all online promotions
        const OnlinePromotions = async () => {
            var tableNameDiscount = "";
            var signupTableDiscount = [];
            var resultatPromotions = 0;
            //
            const firstWhere = "date_end";
            const secondWhere = "id_discount";
            //
            const limitNumber = 300;

            const codecountryDiv = document.getElementById('codecountry').innerText;
            const searchcountryDiv = document.getElementById('countrysearch').innerText;
            var countrySelected = '';
            if(searchcountryDiv){
                countrySelected = searchcountryDiv;
            }else{
                countrySelected = codecountryDiv;
            }
            //
            //retrieve table discount
            signupTableDiscount = retrieveTableQuery(countrySelected);
            if(signupTableDiscount){
                tableNameDiscount = signupTableDiscount.table.table7;
            }
            //check all seller subscription where currrent date is < date expiration
            await Axios.post(`${apiUrl}/check/valid/product`, {
                tableNameOne:tableNameDiscount,
                firstWhere:firstWhere,
                secondWhere:secondWhere,
                limitNumber:limitNumber,
            }).then((responsePromotions) => {
                resultatPromotions = responsePromotions.data.length;
                if(resultatPromotions === 0){
                    setProductPromotion(false);
                }else{
                    setFetchPromotion(responsePromotions.data);
                    //productcontent
                    setProductContent(true);
                    setProductPromotion(true);
                    //productcontentdetails
                    setProductContentDetails(false);
                    //productcontentsubdetails
                    setProductContentSubDetails(false);
                    //
                    setScrollNoSubscribeModalOpen(true);
                    //
                    setSearchCountry(false);
                    setShowModalCountry(false);
                    setShowAllProducts(false);
                    setShowFoodProducts(false);
                    setShowBeverageProducts(false);
                    setShowApparelProducts(false);
                    setShowHealthProducts(false);
                    setShowBeautyProducts(false);
                    setShowServicesProducts(false);
                    setShowElectronicProducts(false);
                    setShowAutomotiveProducts(false);
                    setShowAgricultureProducts(false);
                    setShowBreedingProducts(false);
                    setShowHouseProducts(false);
                    setShowSportProducts(false);
                    setShowDecorProducts(false);
                    //
                    setNoSubscribeBuyerButtonModalOpen(false);
                    setNoSubscribeSearchModalOpen(false);
                    //
                    setSearchResultModalOpen(false);
                }
            });
        }
        //emplementation function to retrieve all 300 new products
        const OnlineProducts = async () => {
            var tableNameSubscribe = "";
            var signupTableSubscribe = [];
            var resultatView = 0;
            //
            var tableNameProduct = "";
            var signupTableProduct = [];
            //
            const variableOne = "p";
            const variableTwo = "s";
            //
            const firstWhere = "code_user";
            const secondWhere = "code_user";
            const thirdWhere = "date_end";
            const fourthWhere = "id_product";
            //
            const limitNumber = 300;
            //
            const codecountryDiv = document.getElementById('codecountry').innerText;
            const searchcountryDiv = document.getElementById('countrysearch').innerText;
            var countrySelected = '';
            if(searchcountryDiv){
                countrySelected = searchcountryDiv;
            }else{
                countrySelected = codecountryDiv;
            }
            //
            //retrieve table subscribe
            signupTableSubscribe = retrieveTableQuery(countrySelected);
            if(signupTableSubscribe){
                tableNameSubscribe = signupTableSubscribe.table.table10;
            }
            //retrieve table product
            signupTableProduct = retrieveTableQuery(countrySelected);
            if(signupTableProduct){
                tableNameProduct = signupTableProduct.table.table4;
            }
            //check all seller subscription where currrent date is < date expiration
            await Axios.post(`${apiUrl}/check/valid/subscriber/product`, {
                tableNameOne:tableNameProduct,
                variableOne:variableOne,
                tableNameTwo:tableNameSubscribe,
                variableTwo:variableTwo,
                firstWhere:firstWhere,
                secondWhere:secondWhere,
                thirdWhere:thirdWhere,
                fourthWhere:fourthWhere,
                limitNumber:limitNumber,
            }).then((responseProd) => {
                resultatView = responseProd.data.length;
                if(resultatView === 0){
                    setProductProducts(false);
                }else{
                    setFetchProduct(responseProd.data);
                    //productcontent
                    setProductContent(true);
                    setProductProducts(true);
                    //productcontentdetails
                    setProductContentDetails(false);
                    //productcontentsubdetails
                    setProductContentSubDetails(false);
                    //
                    setScrollNoSubscribeModalOpen(true);
                    //
                    setSearchCountry(false);
                    setShowModalCountry(false);
                    setShowAllProducts(false);
                    setShowFoodProducts(false);
                    setShowBeverageProducts(false);
                    setShowApparelProducts(false);
                    setShowHealthProducts(false);
                    setShowBeautyProducts(false);
                    setShowServicesProducts(false);
                    setShowElectronicProducts(false);
                    setShowAutomotiveProducts(false);
                    setShowAgricultureProducts(false);
                    setShowBreedingProducts(false);
                    setShowHouseProducts(false);
                    setShowSportProducts(false);
                    setShowDecorProducts(false);
                    //
                    setNoSubscribeBuyerButtonModalOpen(false);
                    setNoSubscribeSearchModalOpen(false);
                    //
                    setSearchResultModalOpen(false);
                }
            });
        };

        // Call all three functions and wait for them to complete
        Promise.all([OnlineEvents(), OnlinePromotions(), OnlineProducts()]);
    };

    //Function to fetch data from the API for each table
    const fetchDataFromTable = async (tableName, firstWhere, firstParameter) => {
        try {
            const result = await Axios.post(`${apiUrl}/check/one/parameter/table`, {
                tableNameSeller:tableName,
                firstWhere:firstWhere,
                firstParameter:firstParameter,
            });
            return result.data;
        } catch (error) {
            const pTextePays = t("error_data");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            throw error;
        }
    }
    

    return (
        <div id='nosubscribebuyer'>
            <TermeScroller>
                <SearchHeader>
                    <div style={{display:'flex', flexDirection:'row', marginLeft:'10px'}}>
                        <div style={{display:'block',textAlign:'center'}}>
                            <AutoCompleteSearchOne 
                                type="text"
                                name="search-bar"
                                placeholder={t("search_placeholder")}
                                ref={noSubscribeSearchRef}
                                defaultValue={prefix}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                onClick={handleClearValues}
                            />
                            <AutoCompleteSearchTwo 
                                type="text"
                                name="search-bar"
                                value={suggestion}
                                readOnly
                            />
                        </div>
                        <SearchIcon>
                            <MdOutlineSearch style={{color:'#ffffff', fontSize:'35px'}} onClick={handleNoSubscribeSearch} />
                        </SearchIcon>
                    </div>
                </SearchHeader>
                <DetailsHeader>
                    <HandlerContainerDiv>
                        <div style={{color:'#246175', fontSize:'17px', display:'none'}} id='namesearch'></div>
                        <div style={{color:'#246175', fontSize:'17px'}} id='namecity'></div>
                        <div style={{color:'#246175', fontSize:'17px'}} id='nameneighborhood'></div>
                        <div style={{color:'#246175', fontSize:'17px'}} id='nameprice'></div>
                    </HandlerContainerDiv>
                </DetailsHeader>
                <ContentScroller>
                    {scrollNoSubscribeModalOpen && (
                        <div className="accueilmenu">
                            <div className="scrollermenu">
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeAllHandler}>{t("cat_all")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeFoodHandler}>{t("cat_food")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeBeverageHandler}>{t("cat_beverage")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeApparelHandler}>{t("cat_apparel")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeHealthHandler}>{t("cat_health")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeBeautyHandler}>{t("cat_beauty")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeServicesHandler}>{t("cat_services")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeElectronicHandler}>{t("cat_electronic")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeAutomotiveHandler}>{t("cat_automotive")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeAgricultureHandler}>{t("cat_agriculture")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeBreedingHandler}>{t("cat_breeding")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeHouseHandler}>{t("cat_house")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeSportHandler}>{t("cat_sport")}</AccueilButton>
                                </FooterUpperLinkDiv>
                                <FooterUpperLinkDiv>
                                    <AccueilButton onClick={noSubscribeDecorHandler}>{t("cat_decor")}</AccueilButton>
                                </FooterUpperLinkDiv>
                            </div>
                        </div>
                    )}
                </ContentScroller>


                {/**show scrollable all products content */}
                {productContent && (
                    <MainContainerList>
                        {/**event scroller */}
                        {productEvent && ( 
                            <div> 
                                <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("event_text")}</span>  
                                <EventScrollerContainer>
                                    <EventScrollerMenu>
                                        {userFetchEvent.map((valevent, indexevent) => (
                                            <ModalUser key={indexevent}>
                                                <ListEventCard 
                                                    url={valevent.image_advertise}
                                                    onImageClick={() => handleVideo(valevent.code_user)}
                                                    title={valevent.title_advertise}
                                                    views={valevent.views_advertise}
                                                />
                                            </ModalUser>
                                        ))}
                                    </EventScrollerMenu>
                                </EventScrollerContainer> 
                            </div>   
                        )}
                        <br/>
                        {/**promotion scroller */}
                        {productPromotion && (
                            <div>
                                <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("promotion_text")}</span>
                                <EventScrollerContainer>
                                    <EventScrollerMenu>
                                        {userFetchPromotion.map((valpromo, indexpromo) => (
                                            <ModalUser key={indexpromo}>
                                                <ListEventCard 
                                                    url={valpromo.image_discount}
                                                    onImageClick={() => handleDiscount(valpromo.code_user)}
                                                    title={valpromo.name_product}
                                                    text={valpromo.price_product}
                                                    currency={valpromo.currency_product}
                                                    views={valpromo.views_discount}
                                                />
                                            </ModalUser>
                                        ))}
                                    </EventScrollerMenu>
                                </EventScrollerContainer>
                            </div>
                        )}
                        <br/>
                        {/**product scroller */}
                        {productProducts && (
                            <div>
                                <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                                <EventScrollerContainer>
                                    <EventScrollerMenu>
                                        {userFetchProduct.map((valprod, indexprod) => (
                                            <ModalUser key={indexprod}>
                                                <ListEventCard 
                                                    url={valprod.image_product}
                                                    onImageClick={() => handleProduct(valprod.code_user)}
                                                    title={valprod.name_product}
                                                    text={valprod.price_product}
                                                    currency={valprod.currency_product}
                                                    views={valprod.views_product}
                                                />
                                            </ModalUser>
                                        ))}
                                    </EventScrollerMenu>
                                </EventScrollerContainer>
                            </div>
                        )}   
                    </MainContainerList>
                )}

                {/**show details of content on page mounts */}
                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**new events products video and description */}
                {productContentDetails && (
                    <MainContainer>
                        {newEventsModalOpen && (
                            <ContentScrollerDetails>
                                <DetailContainerMenu>
                                    <div>
                                        {userEventsVideo.map((valvideo, indexvideo) => (
                                            <VideoModalUser key={indexvideo}>
                                                <ContactCardVideo
                                                    onButtonClickClose={() => handlerCloseEventsVideo()}
                                                    onButtonClickPhoneCall={() => handlerDirectCallEvent(valvideo.code_user)}
                                                    onButtonClickPhone={() => handlerChatRoomEvent()}
                                                    videoUrl={valvideo.link_advertise}
                                                    title={valvideo.title_advertise}
                                                    supportvideo={t("unsupported_browser")} 
                                                    textDescription={valvideo.content_advertise}
                                                />
                                                <LikeButton 
                                                    confirm={() => handleLikeEvent(valvideo.code_user)}
                                                />
                                            </VideoModalUser> 
                                        ))}
                                    </div>
                                </DetailContainerMenu>
                            </ContentScrollerDetails>
                        )}

                        {/**new promotion products and description */}
                        {newPromotionsModalOpen && (
                            <ContentScrollerDetails>
                                <DetailContainerMenu>
                                    <div>
                                        {userPromotions.map((valpromotion, indexpromotion) => (
                                            <VideoModalUser key={indexpromotion}>
                                                <ListDiscountCard
                                                    onButtonClickClose={() => handlerClosePromotion()}
                                                    onButtonClickPhoneCall={() => handlerDirectCallPromotion(valpromotion.code_user)}
                                                    onButtonClickPhone={() => handlerChatRoomPromotion()}
                                                    url={valpromotion.image_discount}
                                                    title={valpromotion.name_product}
                                                    textDescription={valpromotion.description_product}
                                                    textPrice={valpromotion.new_price}
                                                    textCurrency={valpromotion.currency_product}
                                                />
                                                <LikeButton 
                                                    confirm={() => handleLikePromotion(valpromotion.code_user)}
                                                />
                                            </VideoModalUser> 
                                        ))}
                                    </div>
                                </DetailContainerMenu>
                            </ContentScrollerDetails>
                        )}

                        {/**products */}
                        {newProductsModalOpen && (
                            <ContentScrollerDetails>
                                <DetailContainerMenu>
                                    <div>
                                        {userProducts.map((valproduct, indexproduct) => (
                                            <VideoModalUser key={indexproduct}>
                                                <ProductListCard
                                                    onButtonClickClose={() => handlerCloseProduct()}
                                                    onButtonClickCategory={() => handlerProductCategory(valproduct.code_user)}
                                                    onButtonClickProfile={() => handlerProductProfile(valproduct.code_user)}
                                                    onButtonClickPhoneCall={() => handlerDirectCallProduct(valproduct.code_user)}
                                                    onButtonClickPhone={() => handlerChatRoomProduct(valproduct.code_user)}
                                                    url={valproduct.image_product}
                                                    title={valproduct.name_product}
                                                    textDescription={valproduct.description_product}
                                                    textPrice={valproduct.price_product}
                                                    textCurrency={valproduct.currency_product}
                                                />
                                                <LikeButton 
                                                    confirm={() => handleLikeProduct(valproduct.code_user)}
                                                />
                                            </VideoModalUser> 
                                        ))}
                                    </div>
                                </DetailContainerMenu>
                            </ContentScrollerDetails>
                        )}
                    </MainContainer>
                )}

                {productContentSubDetails && (
                    <MainContainer>
                        {/**category */}
                        {newCategoryModalOpen && (
                            <ContentScrollerDetails>
                                <DetailContainerMenu>
                                    <div>
                                        {userCategory.map((valcategory, indexcategory) => (
                                            <VideoModalUser key={indexcategory}>
                                                <ListCategoryCard
                                                    onButtonClickClose={() => handlerCloseCategory()}
                                                    onButtonClickPhoneCall={() => handlerDirectCallCategory(valcategory.code_user)}
                                                    onButtonClickPhone={() => handlerChatRoomCategory(valcategory.code_user)}
                                                    url={valcategory.image_product}
                                                    title={valcategory.name_product}
                                                    textDescription={valcategory.description_product}
                                                    textPrice={valcategory.price_product}
                                                    textCurrency={valcategory.currency_product}
                                                />
                                            </VideoModalUser> 
                                        ))}
                                    </div>
                                </DetailContainerMenu>
                            </ContentScrollerDetails>
                        )}

                        {/**profile */}
                        {newProfileModalOpen && (
                            <ContentScrollerDetailsProfile>
                                <DetailContainerMenuProfile>
                                    <div>
                                        {userProfile.map((valprofile, indexprofile) => (
                                            <ContainerProfile key={indexprofile}>
                                                <ListProfileCard
                                                    onButtonClickClose={() => handlerCloseProfile()}
                                                    denomination={valprofile.profile_principal}
                                                    secteur={TranslateModeContact(valprofile.secteur)}
                                                    description={valprofile.profile_description}
                                                    modeVente={TranslateModeContact(valprofile.mode_vente)}
                                                    contact={TranslateModeContact(valprofile.profile_contact)}
                                                    pays={NomPays(valprofile.code_pays)}
                                                    ville={valprofile.city_user}
                                                    village={valprofile.village}
                                                    quartier={valprofile.quartier}
                                                    localisation={valprofile.localisation}
                                                />
                                            </ContainerProfile> 
                                        ))}
                                    </div>
                                </DetailContainerMenuProfile>
                            </ContentScrollerDetailsProfile>
                        )}
                    </MainContainer>
                )}
                
                {/**All Products */}
                {showAllProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {allProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleAllProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Food Products */}
                {showFoodProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {foodProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleFoodProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Beverage Products */}
                {showBeverageProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {beverageProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleBeverageProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Apparel Products */}
                {showApparelProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {apparelProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleApparelProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Apparel Products */}
                {showHealthProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {healthProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleHealthProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Beauty Products */}
                {showBeautyProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {beautyProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleBeautyProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**services Products */}
                {showServicesProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {servicesProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleServicesProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Electronic Products */}
                {showElectronicProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {electronicProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleElectronicProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Automotive Products */}
                {showAutomotiveProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {automotiveProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleAutomotiveProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Agriculture Products */}
                {showAgricultureProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {agricultureProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleAgricultureProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Breeding Products */}
                {showBreedingProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {breedingProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleBreedingProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**House Products */}
                {showHouseProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {houseProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleHouseProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Sport Products */}
                {showSportProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {sportProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleSportProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Decor Products */}
                {showDecorProducts && (
                    <MainContainer>
                        <div>
                            <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                            <EventScrollerContainer>
                                <EventScrollerMenu>
                                    {decorProducts.map((valprod, indexprod) => (
                                        <ModalUser key={indexprod}>
                                            <ListEventCard 
                                                url={valprod.image_product}
                                                onImageClick={() => handleDecorProduct(valprod.code_user)}
                                                title={valprod.name_product}
                                                text={valprod.price_product}
                                                currency={valprod.currency_product}
                                                views={valprod.views_product}
                                            />
                                        </ModalUser>
                                    ))}
                                </EventScrollerMenu>
                            </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**Filter search container */}
                {noSubscribeSearchModalOpen && (
                    <MainContainer>
                        <br/>
                        {noSubscribeBuyerButtonModalOpen && (
                            <div>
                                <ul style={{color:'#3366ff'}}>
                                    <li>{t("filter_search_texte1")}</li>
                                </ul>
                                <HandlerContainerDiv>
                                    <div>
                                        <BazaliButton onClick={handleNoSubscribeCityFilter}>{t("filter_city")}</BazaliButton>
                                    </div>
                                    <div>
                                        <BazaliButton onClick={handleNoSubscribeNeighbordFilter}>{t("filter_neighborhood")}</BazaliButton>
                                    </div>
                                    <div>
                                        <BazaliButton onClick={handleNoSubscribeBestPriceFilter}>{t("filter_best_prices")}</BazaliButton>
                                    </div>
                                    <div>
                                        <PlayButtonSearch onClick={handleGoSearchNoSubscribeBuyer}></PlayButtonSearch>
                                    </div>
                                </HandlerContainerDiv>
                            </div>
                        )}

                        {/* City Filter Modal */}
                        {cityModalOpen && (
                            <ModalFilter>
                                <TextLogin>{t("filter_city")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <div>
                                        <SmallSelectBox ref={noSubscribeSearchCityRef}>
                                            {listcity.map((valcity, indexcity) => (
                                                <option key={indexcity} value={valcity.city_user}>{valcity.city_user}</option>
                                            ))}
                                        </SmallSelectBox>
                                    </div>
                                </div>
                                <br/>
                                <HandlerContainerDiv>
                                    <div>
                                        <ConfirmButton onClick={handleModalCityConfirm}>{t("confirm_button")}</ConfirmButton>
                                    </div>
                                    <div>
                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                    </div>
                                </HandlerContainerDiv>
                            </ModalFilter>
                        )}

                        {/* Neighborhood Filter Modal */}
                        {neighborhoodModalOpen && (
                            <ModalFilter>
                                <TextLogin>{t("filter_neighborhood")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <div>
                                        <SmallSelectBox ref={noSubscribeSearchNeighborhoodRef}>
                                            {listNeighbord.map((valneighbord, indexneighbord) => (
                                                <option key={indexneighbord} value={valneighbord.quartier}>{valneighbord.quartier}</option>
                                            ))}
                                        </SmallSelectBox>
                                    </div>
                                </div>
                                <br/>
                                <HandlerContainerDiv>
                                    <div>
                                        <ConfirmButton onClick={handleModalNeighborhoodConfirm}>{t("confirm_button")}</ConfirmButton>
                                    </div>
                                    <div>
                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                    </div>
                                </HandlerContainerDiv>
                            </ModalFilter>
                        )}

                        {/* Best Prices Filter Modal */}
                        {bestPricesModalOpen && (
                            <ModalFilter>
                                <TextLogin>{t("filter_best_prices")}</TextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <div>
                                        <SmallSelectBox ref={noSubscribeSearchPriceRef}>
                                            {listPrice.map((valprice, indexprice) => (
                                                <option key={indexprice} value={valprice.price_product}>{valprice.price_product}</option>
                                            ))}
                                        </SmallSelectBox>
                                    </div>
                                </div>
                                <br/>
                                <HandlerContainerDiv>
                                    <div>
                                        <ConfirmButton onClick={handleModalPricesConfirm}>{t("confirm_button")}</ConfirmButton>
                                    </div>
                                    <div>
                                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                                    </div>
                                </HandlerContainerDiv>
                            </ModalFilter>
                        )}
                    </MainContainer>
                )}

                {/**XXXXXXXXXXXXXXXXXXXXX */}
                {/**SEARCH RESULTS */}
                {searchResultModalOpen && (
                    <MainContainer>
                        <div>
                        <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("product_text")}</span>
                        <EventScrollerContainer>
                            <EventScrollerMenu>
                                {userSearchProducts.map((valsearch, indexsearch) => (
                                    <ModalUser key={indexsearch}>
                                        <ListEventCard 
                                            url={valsearch.image_product}
                                            onImageClick={() => handleSearchProduct(valsearch.code_user)}
                                            title={valsearch.name_product}
                                            text={valsearch.price_product}
                                            currency={valsearch.currency_product}
                                            views={valsearch.views_product}
                                        />
                                    </ModalUser>
                                ))}
                            </EventScrollerMenu>
                        </EventScrollerContainer>
                        </div>
                    </MainContainer>
                )}

                {/**show modal */}
                {showModal && (
                    <WarningSingleModal 
                        text={t("error_suggestion")}
                        textNo="OK"
                        onCancel={handleCancelSearch}
                    />
                )}

                {/**Close */}
                {showModalClose && (
                    <WarningModal 
                        text={t("close_page")}
                        onConfirm={handleClosePage}
                        textOk={t('yes')}
                        textNo={t("no")}
                        onCancel={handleCancelPage}
                    />
                )}

                {/**search country modal */}
                {showModalCountry && (
                    <SearchByCountryModal
                        text={t("text_by_country")}
                        textOk={t("yes")}
                        onConfirm={searchByCountryHandler}
                        textNo={t("no")}
                        onCancel={NoSearchByCountryHandler}
                    />
                )}

                {/**show modal contact */}
                {showModalContact && (
                    <WarningSingleModal 
                        text={t("error_contact")}
                        textNo="OK"
                        onCancel={handleCancelContact}
                    />
                )}
                
                {/**search by Country */}
                {searchCountry && (
                    <PrincipalScroller>
                        <ModalUser>
                            <br/>
                            <PrincipalSubTitle>{t("principaltexte2")}</PrincipalSubTitle>
                            <br/>
                            <div style={{display:'block',textAlign:'center'}}>
                                <CountryButton 
                                    url={Gambia}
                                    countryName="Gambie"
                                    onConfirm={handleGambia}
                                />
                                <CountryButton 
                                    url={Ghana}
                                    countryName="Ghana"
                                    onConfirm={handleGhana}
                                />
                                <CountryButton 
                                    url={Liberia}
                                    countryName="Liberia"
                                    onConfirm={handleLiberia}
                                />
                                <CountryButton 
                                    url={Nigeria}
                                    countryName="Nigeria"
                                    onConfirm={handleNigeria}
                                />
                                <CountryButton 
                                    url={Sierraleone}
                                    countryName="Sierraleone"
                                    onConfirm={handleSierraleone}
                                />
                                <CountryButton 
                                    url={Benin}
                                    countryName="Bénin"
                                    onConfirm={handleBenin}
                                />
                                <CountryButton 
                                    url={Burkina}
                                    countryName="Burkina Faso"
                                    onConfirm={handleBurkina}
                                />
                                <CountryButton 
                                    url={Guinee}
                                    countryName="Guinée-Conakry"
                                    onConfirm={handleGuinee}
                                />
                                <CountryButton 
                                    url={Ivoire}
                                    countryName="Côte d'Ivoire"
                                    onConfirm={handleIvoire}
                                />
                                <CountryButton 
                                    url={Mali}
                                    countryName="Mali"
                                    onConfirm={handleMali}
                                />
                                <CountryButton 
                                    url={Niger}
                                    countryName="Niger"
                                    onConfirm={handleNiger}
                                />
                                <CountryButton 
                                    url={Senegal}
                                    countryName="Sénégal"
                                    onConfirm={handleSenegal}
                                />
                                <CountryButton 
                                    url={Togo}
                                    countryName="Togo"
                                    onConfirm={handleTogo}
                                />
                                <CountryButton 
                                    url={Capvert}
                                    countryName="Cap Vert"
                                    onConfirm={handleCapvert}
                                />
                                <CountryButton 
                                    url={GuineeBissau}
                                    countryName="Guinée Bissau"
                                    onConfirm={handleGuineeBissau}
                                />
                            </div>
                        </ModalUser>
                    </PrincipalScroller>
                )}

                <br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={handleGoBack} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>

                <br/><br/>
                <div id="modaloutalertnosubscribebuyer"></div>
                <div id="backdropoutalertnosubscribebuyer"></div>
                {/**footer satrting */}
                <FooterStarting />
            </TermeScroller>
        </div>
    )
}

export default NoSubscribeBuyer
import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
//
import { MdLeaderboard, MdProductionQuantityLimits, MdOutlineFormatListBulleted, MdReorder, MdSettings, MdOutlineChat, MdOutlineEventSeat } from 'react-icons/md';
import { BsChatSquareDots } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
//set various dashboard component
import CrudProduct from './dashproduct/CrudProduct';
import CrudPlan from './dashplan/CrudPlan';
import CrudTopUp from './dashtopup/CrudTopUp';
import CrudSettings from './dashsettings/CrudSettings';
import CrudChats from './dashchats/CrudChats';
import CrudCategory from './dashcategory/CrudCategory';
import CrudEvents from './dashevents/CrudEvents';
//
import GobackBouton from '../../reusablecomponents/buttons/GobackBouton';
//
import { HideFooter } from '../../../helperfunctions/Helper';

import { 
    TermeScroller, 
    FooterSubTitle,
    FooterIntro,
    HandlerContainerDiv, 
    ModalSign,
    CancelButton,
    LeftSideDiv,
    RightSideDiv,
    LeftSideButton,
} from '../../ComponentStyles';

function DashboardSeller() {
    HideFooter();

    const {t} = useTranslation();

    const navigateDashSeller = useNavigate();

    //div ref
    const dashInfoSellerRef = useRef(null);
    const modalDashSellerRef = useRef(null);

    // Define state variables for modals
    const [dashboardCrudProductModalOpen, setDashboardCrudProductModalOpen] = useState(false);
    const [dashboardCrudCategoryModalOpen, setDashboardCrudCategoryModalOpen] = useState(false);
    const [dashboardCrudEventModalOpen, setDashboardCrudEventModalOpen] = useState(false);
    const [dashboardCrudPlanModalOpen, setDashboardCrudPlanModalOpen] = useState(false);
    const [dashboardCrudTopUpModalOpen, setDashboardCrudTopUpModalOpen] = useState(false);
    const [dashboardCrudSettingsModalOpen, setDashboardCrudSettingModalOpen] = useState(false);
    const [dashboardCrudChatsModalOpen, setDashboardCrudChatsModalOpen] = useState(false);

    //function show hide info
    const handleDashboardSeller = async (e) => {
        e.preventDefault();

        dashInfoSellerRef.current.style.display = 'block';
        modalDashSellerRef.current.style.display = 'none';
    }
    const handleCloseDashboardSeller = async (e) => {
        e.preventDefault();

        dashInfoSellerRef.current.style.display = 'none';
        modalDashSellerRef.current.style.display = 'block';
    }

    const handlePlanDashboardSeller = async (e) => {
        e.preventDefault();

        setDashboardCrudProductModalOpen(false);
        setDashboardCrudCategoryModalOpen(false);
        setDashboardCrudEventModalOpen(false);
        setDashboardCrudPlanModalOpen(true);
        setDashboardCrudTopUpModalOpen(false);
        setDashboardCrudSettingModalOpen(false);
        setDashboardCrudChatsModalOpen(false);
    }
    const handleProductDashboardSeller = async (e) => {
        e.preventDefault();

        setDashboardCrudProductModalOpen(true);
        setDashboardCrudCategoryModalOpen(false);
        setDashboardCrudEventModalOpen(false);
        setDashboardCrudPlanModalOpen(false);
        setDashboardCrudTopUpModalOpen(false);
        setDashboardCrudSettingModalOpen(false);
        setDashboardCrudChatsModalOpen(false);
    }
    const handleCategoryDashboardSeller = async (e) => {
        e.preventDefault();

        setDashboardCrudProductModalOpen(false);
        setDashboardCrudCategoryModalOpen(true);
        setDashboardCrudEventModalOpen(false);
        setDashboardCrudPlanModalOpen(false);
        setDashboardCrudTopUpModalOpen(false);
        setDashboardCrudSettingModalOpen(false);
        setDashboardCrudChatsModalOpen(false);
    }
    const handleEventDashboardSeller = async (e) => {
        e.preventDefault();

        setDashboardCrudProductModalOpen(false);
        setDashboardCrudCategoryModalOpen(false);
        setDashboardCrudEventModalOpen(true);
        setDashboardCrudPlanModalOpen(false);
        setDashboardCrudTopUpModalOpen(false);
        setDashboardCrudSettingModalOpen(false);
        setDashboardCrudChatsModalOpen(false);
    }
    const handleOrderDashboardSeller = async (e) => {
        e.preventDefault();

        setDashboardCrudProductModalOpen(false);
        setDashboardCrudCategoryModalOpen(false);
        setDashboardCrudEventModalOpen(false);
        setDashboardCrudPlanModalOpen(false);
        setDashboardCrudTopUpModalOpen(true);
        setDashboardCrudSettingModalOpen(false);
        setDashboardCrudChatsModalOpen(false);
    }
    const handleSettingsDashboardSeller = async (e) => {
        e.preventDefault();

        setDashboardCrudProductModalOpen(false);
        setDashboardCrudCategoryModalOpen(false);
        setDashboardCrudEventModalOpen(false);
        setDashboardCrudPlanModalOpen(false);
        setDashboardCrudTopUpModalOpen(false);
        setDashboardCrudSettingModalOpen(true);
        setDashboardCrudChatsModalOpen(false);
    }
    const handleChatDashboardSeller = async (e) => {
        e.preventDefault();

        setDashboardCrudProductModalOpen(false);
        setDashboardCrudCategoryModalOpen(false);
        setDashboardCrudEventModalOpen(false);
        setDashboardCrudPlanModalOpen(false);
        setDashboardCrudTopUpModalOpen(false);
        setDashboardCrudSettingModalOpen(false);
        setDashboardCrudChatsModalOpen(true);
    }


    const goBackDashboardSellerHandler = async () => {
        //
        navigateDashSeller('/');
        //
        document.getElementById('selectlangue').selectedIndex = 0;
        document.getElementById('namecountry').innerText = "";
        document.getElementById('codecountry').innerText = "";
        document.getElementById('codeutilisateur').innerText = "";
        document.getElementById('usernom').innerText = "";
        document.getElementById('typeutilisateur').innerText = "";
    }

    const handleModalClose = async (e) => {
        setDashboardCrudProductModalOpen(false);
        setDashboardCrudCategoryModalOpen(false);
        setDashboardCrudEventModalOpen(false);
        setDashboardCrudPlanModalOpen(false);
        setDashboardCrudTopUpModalOpen(false);
        setDashboardCrudSettingModalOpen(false);
        setDashboardCrudChatsModalOpen(false);
    }

    return (
        <div id='dashboardseller'>
            <TermeScroller>
                <br/>
                <FooterSubTitle>{t("dash_titre")}</FooterSubTitle>
                <br/>
                <div style={{float: 'left'}}>
                    <MdOutlineChat style={{color:'#246175', fontSize:'35px', cursor:'pointer'}} onClick={handleDashboardSeller} />
                </div>
                <br/>
                <div ref={dashInfoSellerRef} style={{display: 'none'}}>
                    <FooterIntro>{t("dash_section_1")}</FooterIntro>
                    <br/>
                    <FooterSubTitle>1. {t("dashsoustitre3")} <span style={{fontSize:'30px'}}><MdLeaderboard /></span></FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("dashsoustitretexte3")}</li>
                    </ul>
                    <FooterSubTitle>2. {t("dashsoustitre4")} <span style={{fontSize:'30px'}}><MdProductionQuantityLimits /></span></FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("dashsoustitretexte4")}</li>
                    </ul>
                    <FooterSubTitle>3. {t("dashsoustitre5")} <span style={{fontSize:'30px'}}><MdReorder /></span></FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("dashsoustitretexte5")}</li>
                    </ul>
                    <FooterSubTitle>4. {t("dashsoustitre6")} <span style={{fontSize:'30px'}}><MdSettings /></span></FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("dashsoustitretexte6")}</li>
                    </ul>
                    <FooterSubTitle>5. {t("dashsoustitre7")} <span style={{fontSize:'30px'}}><BsChatSquareDots /></span></FooterSubTitle>
                    <ul style={{color:'#3366ff'}}>
                        <li>{t("dashsoustitretexte7")}</li>
                    </ul>
                    <FooterIntro>{t("dash_section_2")}</FooterIntro>
                    <FooterIntro>{t("dash_section_3")}</FooterIntro>
                    <br/>
                    <div style={{marginLeft:'20px'}}>
                        <CgCloseO style={{color:'red', fontSize:'35px', cursor:'pointer'}} onClick={handleCloseDashboardSeller} />
                    </div>
                    <br/><br/>
                </div>
                <br/>
                <div ref={modalDashSellerRef}>
                    <ModalSign>
                        <LeftSideDiv>
                            <div>
                                <LeftSideButton onClick={handlePlanDashboardSeller}>
                                    <span style={{fontSize:'20px'}}><MdLeaderboard /></span>
                                    <span style={{fontSize:'15px'}}>{t("dash_plan")}</span>
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleProductDashboardSeller}>
                                    <span style={{fontSize:'20px'}}><MdProductionQuantityLimits /></span>
                                    <span style={{fontSize:'15px'}}>{t("dash_product")}</span>
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleCategoryDashboardSeller}>
                                    <span style={{fontSize:'20px'}}><MdOutlineFormatListBulleted /></span>
                                    <span style={{fontSize:'15px'}}>{t("dash_category")}</span>
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleEventDashboardSeller}>
                                    <span style={{fontSize:'20px'}}><MdOutlineEventSeat /></span>
                                    <span style={{fontSize:'15px'}}>{t("event_text")}</span>
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleOrderDashboardSeller}>
                                    <span style={{fontSize:'20px'}}><MdReorder /></span>
                                    <span style={{fontSize:'15px'}}>{t("dash_order")}</span>
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleSettingsDashboardSeller}>
                                    <span style={{fontSize:'20px'}}><MdSettings /></span>
                                    <span style={{fontSize:'15px'}}>{t("dash_settings")}</span>
                                </LeftSideButton>
                            </div>
                            <div>
                                <LeftSideButton onClick={handleChatDashboardSeller}>
                                    <span style={{fontSize:'20px'}}><BsChatSquareDots /></span>
                                    <span style={{fontSize:'15px'}}>{t("dash_chat")}</span>
                                </LeftSideButton>
                            </div>
                        </LeftSideDiv>
                        <RightSideDiv>
                            {/**Modal Crud Plan */}
                            {dashboardCrudPlanModalOpen && (
                                <CrudPlan />
                            )}

                            {/* Modal Crud Products */}
                            {dashboardCrudProductModalOpen && (
                                <CrudProduct />
                            )}

                            {/* Modal Crud Category */}
                            {dashboardCrudCategoryModalOpen && (
                                <CrudCategory />
                            )}

                            {/* Modal Crud Events */}
                            {dashboardCrudEventModalOpen && (
                                <CrudEvents />
                            )}

                            {/**Modal Crud Top-Up */}
                            {dashboardCrudTopUpModalOpen && (
                                <CrudTopUp />
                            )}

                            {/**Modal Crud Settings */}
                            {dashboardCrudSettingsModalOpen && (
                                <CrudSettings />
                            )}

                            {/**Modal Crud Chats */}
                            {dashboardCrudChatsModalOpen && (
                                <CrudChats />
                            )}
                        </RightSideDiv>
                    </ModalSign>
                </div>

                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <GobackBouton onClick={goBackDashboardSellerHandler} ></GobackBouton>
                    </div>
                    <div>
                        <CancelButton onClick={handleModalClose}>{t("cancel_button")}</CancelButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
            </TermeScroller>
        </div>
    )
}

export default DashboardSeller
import React, { /*useRef,*/ useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//import NotifyCard from '../../../reusablecomponents/cards/NotifyCard';
//import ListCard from '../../../reusablecomponents/cards/ListCard';
//
import { MdOutlineChat, MdClose, MdWhatsapp } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal, /*formatDateData, getCurrentDate,*/ WhatsappCall } from '../../../../helperfunctions/Helper';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    //SmallTextLogin,
    ModalUser,
    FooterIntro,
    //FormScrollerWidgetSm,
    //TableWidgetSmTable,
    //TableThead,
    //ThWidgetSmTh,
    //TableTbody,
    //TableTd,
    //TableButton,
    //SmallInput,
    SmallHandlerContainerDiv,
    CancelButton,
    SmallSaveButton,
    //SmallViewButton,
    CardButtonPhone,
} from '../../../ComponentStyles';

function SellerChatPrivate() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertprivatesellerchat";
    const elementDropId = "backdropoutalertprivatesellerchat";
    const buttonHandler = "ouiHandlerAlertPrivateSellerChatBtn";

    //setting input variables
    const [phoneNumerInput, setPhoneNumberInput] = useState([0]);
    const [prefixeInterInput, setPrefixeInterInput] = useState([0]);

    // Define state variables for modals
    const [startPrivateSellerChatModalOpen, setStartPrivateSellerChatModalOpen] = useState(true);
    const [sellerChatPrivateModalOpen, setSellerChatPrivateModalOpen] = useState(false);
    const [privateSellerChat, setPrivateSellerChat] = useState(false);

    //const userPhoneRef = useRef(null);
    //const userPrefixeRef = useRef(null);

    const handleStartPrivateSellerChat = async (e) => {
        e.preventDefault();

        setStartPrivateSellerChatModalOpen(false);
        setSellerChatPrivateModalOpen(true);
        setPrivateSellerChat(false);
    }

    const handleOpenChat = async (e) => {
        e.preventDefault();

        // declare subscribe variables 
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var resultat1 = 0;
        //
        var tableNameSeller = "";
        var signupTableSeller = [];
        //
        const variableOne = "s";
        const variableTwo = "u";
        //
        const firstWhere = "code_user";
        const secondWhere = "code_user";
        const thirdWhere = "date_end";
        const fourthWhere = "id_userseller";
        //
        var resultSeller = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const firstParameter = document.getElementById('codeutilisateur').innerText;
        //retrieve table subscribe
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        //retrieve table product
        signupTableSeller = retrieveTableQuery(codecountryDiv);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check all seller subscription where currrent date is < date expiration
        await Axios.post(`${apiUrl}/check/valid/subscriber/seller`, {
            tableNameOne:tableNameSubscribe,
            variableOne:variableOne,
            tableNameTwo:tableNameSeller,
            variableTwo:variableTwo,
            firstWhere:firstWhere,
            secondWhere:secondWhere,
            thirdWhere:thirdWhere,
            fourthWhere:fourthWhere,
            firstParameter:firstParameter,
        }).then((response) => {
            resultat1 = response.data.length;
            //console.log(response.data);
            if(resultat1 === 0){
                const pTextePays = t("text_expired_warning");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                resultSeller = response.data;
                //console.log(resultSeller);
                for(let i=0; i<resultat1; i++){
                    setPrefixeInterInput(resultSeller[i].prefixe);
                    setPhoneNumberInput(resultSeller[i].phone);
                }
                setStartPrivateSellerChatModalOpen(false);
                setSellerChatPrivateModalOpen(false);
                setPrivateSellerChat(true);
            }
        });
    }

    const handleCloseChat = async (e) => {
        e.preventDefault();

        setStartPrivateSellerChatModalOpen(true);
        setSellerChatPrivateModalOpen(false);
        setPrivateSellerChat(false);
    }

    const handleSendPrivateSellerChat = async (e) => {
        e.preventDefault();

        //User's phone number (include the country code)
        const userPrefixe = prefixeInterInput;
        const userPhone = phoneNumerInput;
        // Call WhatsApp call link function
        WhatsappCall(userPrefixe, userPhone);
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_private_seller_chats")}</FooterSubTitle>
                <br/>
                {startPrivateSellerChatModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartPrivateSellerChat}
                        />
                    </div>
                )}

                {sellerChatPrivateModalOpen && (
                    <div>
                        <ModalUser>
                            <FooterIntro>{t("private_seller_chat")}</FooterIntro>
                            <br/>
                            <SmallHandlerContainerDiv>
                                <div>
                                    <SmallSaveButton onClick={handleOpenChat}>
                                        <span style={{ fontSize:'30px'}}><MdOutlineChat /></span>
                                        {t("open_meeting")}
                                    </SmallSaveButton>
                                </div>
                            </SmallHandlerContainerDiv>
                            <br/>
                            <SmallHandlerContainerDiv>
                                <div>
                                    <CancelButton onClick={handleCloseChat}>
                                        <span style={{ fontSize:'30px'}}><MdClose /></span>
                                        {t("close_meeting")}
                                    </CancelButton>
                                </div>
                            </SmallHandlerContainerDiv>
                        </ModalUser>
                    </div>
                )}
                {/*<div style={{display:'none',textAlign:'center'}}>
                    <SmallInput type='text' ref={userPhoneRef} value={phoneNumerInput} readOnly />
                </div>
                <div style={{display:'none',textAlign:'center'}}>
                    <SmallInput type='text' ref={userPrefixeRef} value={prefixeInterInput} readOnly />
                </div>*/}
                {privateSellerChat && (
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                        <CardButtonPhone onClick={handleSendPrivateSellerChat}><MdWhatsapp style={{fontSize:'60px'}} /></CardButtonPhone>
                    </div>
                )}

                <br/><br/>
                <div id="modaloutalertprivatesellerchat"></div>
                <div id="backdropoutalertprivatesellerchat"></div>
            </ModalUser>
        </div>
    )
}

export default SellerChatPrivate
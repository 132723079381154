import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdOutlineSave } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal, LimitCharacters, getCurrentDate } from '../../../../helperfunctions/Helper';
//
import CrudSettings from './CrudSettings';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallSelectBox,
    SmallLanguageOption,
    SmallTextArea,
    SmallHandlerContainerDiv,
    SmallSaveButton,
} from '../../../ComponentStyles';

function CreateProfile() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertcreateprofile";
    const elementDropId = "backdropoutalertcreateprofile";
    const buttonHandler = "ouiHandlerAlertCreateProfileBtn";

    // Define state variables for modals
    const [startProfileModalOpen, setStartProfileModalOpen] = useState(true);
    const [createProfileModalOpen, setCreateProfileModalOpen] = useState(false);
    //
    const [crudSettingsModalOpen, setCrudSettingsModalOpen] = useState(false);

    const [createDetailProfile, setCreateDetailProfile] = useState([0]);

    const createProfileVilleRef = useRef(null);
    const createProfileVillageRef = useRef(null);
    const createProfileQuartierRef = useRef(null);
    const createSelectSectorRef = useRef(null);
    const createProfilePrincipalRef = useRef(null);
    const createProfileLocationRef = useRef(null);
    const createSelectModeRef = useRef(null);
    const createProfileDescritptionRef = useRef(null);
    const createSelectContactRef = useRef(null);

    //Limit the total number of characters (not words)
    const characterLimit = 1000; // Set the character limit to 250
    const [textLocation, setTextLocation] = useState('');
    const [textDescription, setTextDescription] = useState('');
    //limit characters for location
    const handleChangeLocation = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, characterLimit);
        setTextLocation(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersLocation = characterLimit - textLocation.length;

    //limit characters for description
    const handleChangeDescription = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, characterLimit);
        setTextDescription(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = characterLimit - textDescription.length;

    const handleStartProfile = async () => {
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const langueUtilsateur = localStorage.getItem('i18nextLng');
        const typeutilisateurDiv = document.getElementById('typeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }

        await Axios.post(`${apiUrl}/seller/details`, {
            tableNameSeller:tableName,
            codeUtisateur:codeutilisateurDiv,
            typeUtilisateur:typeutilisateurDiv,
            codeCountry:codecountryDiv,
            langueUtilisateur:langueUtilsateur,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setCreateDetailProfile(response1.data);
                setStartProfileModalOpen(false);
                setCreateProfileModalOpen(true);
            }
        });
    }

    const handleCreateProfile = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        var resultat2 = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table3;
        }
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const createProfileVille = createProfileVilleRef.current.value;
        const createProfileVillage = createProfileVillageRef.current.value;
        const createProfileQuartier = createProfileQuartierRef.current.value;
        const createSelectSector = createSelectSectorRef.current.value;
        const createProfilePrincipal = createProfilePrincipalRef.current.value;
        const createProfileLocation = createProfileLocationRef.current.value;
        const createSelectMode = createSelectModeRef.current.value;
        const createProfileDescritption = createProfileDescritptionRef.current.value;
        const createSelectContact = createSelectContactRef.current.value;
        const statusProfile = 'online';
        const dateProfile = getCurrentDate();
        //
        if(!codeutilisateurDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!codecountryDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createProfileVille){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createSelectSector){
            const pTextePays = t("profile_sector_select");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createProfilePrincipal){
            const pTextePays = t("profile_principal");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createProfileLocation){
            const pTextePays = t("profile_location");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createSelectMode){
            const pTextePays = t("select_profile_mode");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!createProfileDescritption){
            const pTextePays = t("profile_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!createSelectContact){
            const pTextePays = t("profile_mode_contact");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            //check if this profile already exist
            await Axios.post(`${apiUrl}/check/seller/profile`, {
                tableNameSeller:tableName,
                codeUtilisateur:codeutilisateurDiv,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    //create new profile
                    Axios.post(`${apiUrl}/create/seller/profile`, {
                        tableNameSeller:tableName,
                        codeUtilisateur:codeutilisateurDiv,
                        codeCountry:codecountryDiv,
                        createProfileVille:createProfileVille,
                        createProfileVillage:createProfileVillage,
                        createProfileQuartier:createProfileQuartier,
                        createSelectSector:createSelectSector,
                        createProfilePrincipal:createProfilePrincipal,
                        createProfileLocation:createProfileLocation,
                        createSelectMode:createSelectMode,
                        createProfileDescritption:createProfileDescritption,
                        createSelectContact:createSelectContact,
                        statusProfile:statusProfile,
                        dateProfile:dateProfile,
                    }).then(() => {
                        const pTextePays = t("profile_success");
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                        setCrudSettingsModalOpen(false);
                        setCreateProfileModalOpen(false);
                        setStartProfileModalOpen(true);
                    })
                }else{
                    const pTextePays = t("profile_exists");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                    setCrudSettingsModalOpen(false);
                    setCreateProfileModalOpen(false);
                    setStartProfileModalOpen(true);
                }
            });
        }
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_create_profile")}</FooterSubTitle>
                <br/>
                {startProfileModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartProfile}
                        />
                    </div>
                )}
                <br/>
                {createProfileModalOpen && (
                    <div>
                        {createDetailProfile.map((valdetail, indexdetail) => (
                            <ModalUser key={indexdetail}>
                                <SmallTextLogin>{t("profile_country")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdetail.pays} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_ville")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={createProfileVilleRef} defaultValue={valdetail.city_user} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_village")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={createProfileVillageRef} placeholder={t('place_village_option')} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_quartier")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={createProfileQuartierRef} placeholder={t('place_quartier_option')} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_sector")}</SmallTextLogin>
                                <SmallSelectBox ref={createSelectSectorRef} >
                                    <SmallLanguageOption value="">{t('profile_sector_select')}</SmallLanguageOption>
                                    <SmallLanguageOption value="all">{t('cat_all')}</SmallLanguageOption>
                                    <SmallLanguageOption value="food">{t('cat_food')}</SmallLanguageOption>
                                    <SmallLanguageOption value="beverage">{t('cat_beverage')}</SmallLanguageOption>
                                    <SmallLanguageOption value="apparel">{t('cat_apparel')}</SmallLanguageOption>
                                    <SmallLanguageOption value="health">{t('cat_health')}</SmallLanguageOption>
                                    <SmallLanguageOption value="beauty">{t('cat_beauty')}</SmallLanguageOption>
                                    <SmallLanguageOption value="services">{t('cat_services')}</SmallLanguageOption>
                                    <SmallLanguageOption value="electronic">{t('cat_electronic')}</SmallLanguageOption>
                                    <SmallLanguageOption value="automotive">{t('cat_automotive')}</SmallLanguageOption>
                                    <SmallLanguageOption value="agriculture">{t('cat_agriculture')}</SmallLanguageOption>
                                    <SmallLanguageOption value="elevage">{t('cat_breeding')}</SmallLanguageOption>
                                    <SmallLanguageOption value="house">{t('cat_house')}</SmallLanguageOption>
                                    <SmallLanguageOption value="sport">{t('cat_sport')}</SmallLanguageOption>
                                    <SmallLanguageOption value="decor">{t('cat_decor')}</SmallLanguageOption>
                                </SmallSelectBox>
                                <br/>
                                <SmallTextLogin>{t("profile_principal")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={createProfilePrincipalRef} placeholder={t('place_principal')} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_location")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        value={textLocation}
                                        onChange={handleChangeLocation}
                                        ref={createProfileLocationRef} 
                                        placeholder={t('place_location')} 
                                        maxLength={characterLimit} // Set the maxlength attribute
                                    />
                                    <div>
                                        {remainingCharactersLocation >= 0
                                        ? `${remainingCharactersLocation} ${t('characters_remaining')}`
                                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersLocation)}`}
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_mode")}</SmallTextLogin>
                                <SmallSelectBox ref={createSelectModeRef} >
                                    <SmallLanguageOption value="">{t('select_profile_mode')}</SmallLanguageOption>
                                    <SmallLanguageOption value="cash">{t('profile_cash')}</SmallLanguageOption>
                                    <SmallLanguageOption value="online">{t('profile_online')}</SmallLanguageOption>
                                    <SmallLanguageOption value="correspondance">{t('profile_correspondance')}</SmallLanguageOption>
                                    <SmallLanguageOption value="order">{t('profile_order')}</SmallLanguageOption>
                                    <SmallLanguageOption value="commission">{t('profile_commission')}</SmallLanguageOption>
                                    <SmallLanguageOption value="affiliate">{t('profile_affiliate')}</SmallLanguageOption>
                                    <SmallLanguageOption value="all">{t('cat_all')}</SmallLanguageOption>
                                </SmallSelectBox>
                                <br/>
                                <SmallTextLogin>{t("profile_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        value={textDescription}
                                        onChange={handleChangeDescription}
                                        ref={createProfileDescritptionRef} 
                                        placeholder={t('place_description')} 
                                        maxLength={characterLimit} // Set the maxlength attribute
                                    />
                                    <div>
                                        {remainingCharactersDescription >= 0
                                        ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_contact")}</SmallTextLogin>
                                <SmallSelectBox ref={createSelectContactRef} >
                                    <SmallLanguageOption value="">{t('profile_mode_contact')}</SmallLanguageOption>
                                    <SmallLanguageOption value="private">{t('profile_private')}</SmallLanguageOption>
                                    <SmallLanguageOption value="public">{t('profile_public')}</SmallLanguageOption>
                                    <SmallLanguageOption value="all">{t('cat_all')}</SmallLanguageOption>
                                </SmallSelectBox>
                                <br/>
                                <SmallHandlerContainerDiv>
                                    <div>
                                        <SmallSaveButton onClick={handleCreateProfile}>
                                            <span style={{ fontSize:'30px'}}><MdOutlineSave /></span>
                                            {t("save_product")}
                                        </SmallSaveButton>
                                    </div>
                                </SmallHandlerContainerDiv>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/><br/>
                <div id="modaloutalertcreateprofile"></div>
                <div id="backdropoutalertcreateprofile"></div>
            </ModalUser>
            {/**CrudSettings */}
            {crudSettingsModalOpen && (
                <CrudSettings />
            )}
        </div>
    )
}

export default CreateProfile
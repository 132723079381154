import React from 'react';
import { useNavigate } from "react-router-dom";
//import { useTranslation } from 'react-i18next';
//import "../../translations/i18n";
//
import './footer.css';
//
import { MdOutlineHome, MdOutlineRoundaboutLeft, MdOutlineConnectWithoutContact, MdOutlinePrivacyTip, MdOutlineFeedback, /*MdOutlineAccountCircle*/ } from "react-icons/md";
import { FaQuestion } from "react-icons/fa";
import { IoTerminalOutline } from "react-icons/io5";
//
import { FooterLink, FooterLinkDiv } from '../ComponentStyles';
//helperfunctions
import { ShowFooter/*, HideFooter*/ } from '../../helperfunctions/Helper';

function Footer() {
    //const {t} = useTranslation();

    const navigateFooter = useNavigate();

    //home
    const homeHandler = () => {
        ShowFooter();
        navigateFooter('/');
    }

    //about
    const aproposHandler = (e) => {
        ShowFooter();
        navigateFooter('/about');
    }

    //contact
    const contactHandler = (e) => {
        ShowFooter();
        navigateFooter('/contactme');
    }

    //faq
    const faqHandler = (e) => {
        ShowFooter();
        navigateFooter('/faq');
    }

    //terms
    const termsHandler = (e) => {
        ShowFooter();
        navigateFooter('/terms');
    }

    //privacy
    const privacyHandler = (e) => {
        ShowFooter();
        navigateFooter('/privacy');
    }

    //feedback
    const feedbackHandler = (e) => {
        ShowFooter();
        navigateFooter('/feedback');
    }

    //account
    /*const accountHandler = () => {
        HideFooter();
        navigateFooter('/starting');
    }*/

    return (
        <div id='footer'>
            <div className="footer_accueilmenu">
                <div className="footer_scrollermenu">
                    <FooterLinkDiv>
                        <FooterLink onClick={homeHandler}><MdOutlineHome style={{fontSize:'35px'}}/></FooterLink>
                    </FooterLinkDiv>
                    <FooterLinkDiv>
                        <FooterLink onClick={aproposHandler}><MdOutlineRoundaboutLeft style={{fontSize:'25px'}} /></FooterLink>
                    </FooterLinkDiv>
                    <FooterLinkDiv>
                        <FooterLink onClick={contactHandler}><MdOutlineConnectWithoutContact style={{fontSize: '25px'}} /></FooterLink>
                    </FooterLinkDiv>
                    <FooterLinkDiv>
                        <FooterLink onClick={faqHandler}><FaQuestion style={{fontSize:'25px'}} /></FooterLink>
                    </FooterLinkDiv>
                    <FooterLinkDiv>
                        <FooterLink onClick={termsHandler}><IoTerminalOutline style={{fontSize:'25px'}} /></FooterLink>
                    </FooterLinkDiv>
                    <FooterLinkDiv>
                        <FooterLink onClick={privacyHandler}><MdOutlinePrivacyTip style={{fontSize:'25px'}} /></FooterLink>
                    </FooterLinkDiv>
                    <FooterLinkDiv>
                        <FooterLink onClick={feedbackHandler}><MdOutlineFeedback style={{fontSize:'25px'}} /></FooterLink>
                    </FooterLinkDiv>
                    {/*<FooterLinkDiv>
                        <FooterLink onClick={accountHandler}><MdOutlineAccountCircle style={{fontSize:'25px'}} /></FooterLink>
                    </FooterLinkDiv>*/}
                    <FooterLinkDiv>
                        <FooterLink>{new Date().getFullYear()}</FooterLink>
                    </FooterLinkDiv>
                </div>
            </div> 
        </div>
    )
}

export default Footer
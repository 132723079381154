import React, { useState, useEffect } from 'react';
import { HandlerContainerDiv } from '../../ComponentStyles';
//
import { retrieveCodeAndName } from '../../../helperfunctions/utilityfunctions/CountryUtils';

function CountryDetail(props) {
    const { frenchCountryRef } = props; // Access the frenchCountryRef prop
    //
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [prefixe, setPrefixe] = useState('');
    const [langue, setLangue] = useState('');

    useEffect(() => {
        const frenchCountry = document.getElementById('codepays').innerText; // Access the value from frenchCountryRef
        const countryInfo = retrieveCodeAndName(frenchCountry);
        if(frenchCountry){
            const { code, name, prefixe, langue } = countryInfo;
            setCode(code);
            setName(name);
            setPrefixe(prefixe);
            setLangue(langue);
        }
    }, [frenchCountryRef]);

    return (
        <div>
            <HandlerContainerDiv>
                <div>{code}</div>
                <div>{name}</div>
                <div>{prefixe}</div>
                <div>{langue}</div>
            </HandlerContainerDiv>
        </div>
    )
}

export default CountryDetail
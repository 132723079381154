import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../../translations/i18n";
//
import OpenButton from '../../../../reusablecomponents/buttons/OpenButton';
//
import { MdOutlineSave, MdModeEdit, MdDelete, MdPreview } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import CreateDiscount from './CreateDiscount';
import EditDiscount from './EditDiscount';
import DeleteDiscount from './DeleteDiscount';
import ViewDiscount from './ViewDiscount';
//
import { retrieveTableQuery } from '../../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal } from '../../../../../helperfunctions/Helper';
import WarningModal from '../../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    RightSideButton,
    ModalUser,
    SmallHandlerColContainerDiv,
} from '../../../../ComponentStyles';

function DiscountProduct() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertdiscountproduct";
    const elementDropId = "backdropoutalertdiscountproduct";
    const buttonHandler = "ouiHandlerAlertDiscountProductBtn";

    // Define state variables for modals
    const [startDiscountProductModalOpen, setStartDiscountProductModalOpen] = useState(true);
    const [discountProductModalOpen, setDiscountProductModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    //
    const [dashboardCreateDiscountModalOpen, setDashboardCreateDiscountModalOpen] = useState(false);
    const [dashboardEditDiscountModalOpen, setDashboardEditDiscountModalOpen] = useState(false);
    const [dashboardDeleteDiscountModalOpen, setDashboardDeleteDiscountModalOpen] = useState(false);
    const [dashboardViewDiscountModalOpen, setDashboardViewDiscountModalOpen] = useState(false);

    const crudDiscountRef = useRef(null);

    const handleStartProduct = async () => {
        
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        var checkResultat = [];
        var codeProduit = "";

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //set code product
        const codeProduitDiv = document.getElementById('codeproduit');
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }

        //use the same axios.post edit/product/detals to retrieve data before showing crud discount product 
        await Axios.post(`${apiUrl}/edit/product/details`, {
            tableNameSeller:tableName,
            codeUtisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_product");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                checkResultat = response1.data;
                //retrieve code produit
                for(let i=0; i<resultat1; i++){
                    codeProduit = checkResultat[i].code_product;
                }
                //console.log(codeProduit);
                codeProduitDiv.innerText = codeProduit;
                //
                setStartDiscountProductModalOpen(false);
                setDiscountProductModalOpen(false);
                setShowModal(true);
                //
                setDashboardCreateDiscountModalOpen(false);
                setDashboardEditDiscountModalOpen(false);
                setDashboardDeleteDiscountModalOpen(false);
                setDashboardViewDiscountModalOpen(false);
            }
        });
    }

    const handleModalDiscountProduct = async (e) => {
        e.preventDefault();

        setStartDiscountProductModalOpen(false);
        setDiscountProductModalOpen(true);
        setShowModal(false);
        //
        setDashboardCreateDiscountModalOpen(false);
        setDashboardEditDiscountModalOpen(false);
        setDashboardDeleteDiscountModalOpen(false);
        setDashboardViewDiscountModalOpen(false);
    }

    const handleCancelProduct = async (e) => {
        e.preventDefault();

        setStartDiscountProductModalOpen(true);
        setDiscountProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateDiscountModalOpen(false);
        setDashboardEditDiscountModalOpen(false);
        setDashboardDeleteDiscountModalOpen(false);
        setDashboardViewDiscountModalOpen(false);
    }

    const handleCrudNewDiscount = async (e) => {
        e.preventDefault();

        setStartDiscountProductModalOpen(false);
        setDiscountProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateDiscountModalOpen(true);
        setDashboardEditDiscountModalOpen(false);
        setDashboardDeleteDiscountModalOpen(false);
        setDashboardViewDiscountModalOpen(false);
    }

    const handleCrudEditDiscount = async (e) => {
        e.preventDefault();

        setStartDiscountProductModalOpen(false);
        setDiscountProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateDiscountModalOpen(false);
        setDashboardEditDiscountModalOpen(true);
        setDashboardDeleteDiscountModalOpen(false);
        setDashboardViewDiscountModalOpen(false);
    }

    const handleCrudDeleteDiscount = async (e) => {
        e.preventDefault();

        setStartDiscountProductModalOpen(false);
        setDiscountProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateDiscountModalOpen(false);
        setDashboardEditDiscountModalOpen(false);
        setDashboardDeleteDiscountModalOpen(true);
        setDashboardViewDiscountModalOpen(false);
    }

    const handleCrudViewDiscount = async (e) => {
        e.preventDefault();

        setStartDiscountProductModalOpen(false);
        setDiscountProductModalOpen(false);
        setShowModal(false);
        //
        setDashboardCreateDiscountModalOpen(false);
        setDashboardEditDiscountModalOpen(false);
        setDashboardDeleteDiscountModalOpen(false);
        setDashboardViewDiscountModalOpen(true);
    }

    return (
        <div>
            <ModalUser ref={crudDiscountRef}>
                <FooterSubTitle>{t("dash_discount_product")}</FooterSubTitle>
                <br/>
                {startDiscountProductModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartProduct}
                        />
                    </div>
                )}
                <br/>
                {discountProductModalOpen && (
                    <div>
                        <ModalUser>
                            <SmallHandlerColContainerDiv>
                                <div>
                                    <RightSideButton onClick={handleCrudNewDiscount}>
                                        <span style={{fontSize:'30px'}}><MdOutlineSave /></span>
                                        {t("dash_new_discount")}
                                    </RightSideButton>
                                </div>
                                <br/>
                                <div>
                                    <RightSideButton onClick={handleCrudEditDiscount}>
                                        <span style={{fontSize:'30px'}}><MdModeEdit /></span>
                                        {t("dash_edit_discount")}
                                    </RightSideButton>
                                </div>
                                <br/>
                                <div>
                                    <RightSideButton onClick={handleCrudDeleteDiscount}>
                                        <span style={{fontSize:'30px'}}><MdDelete /></span>
                                        {t("dash_delete_discount")}
                                    </RightSideButton>
                                </div>
                                <br/>
                                <div>
                                    <RightSideButton onClick={handleCrudViewDiscount}>
                                        <span style={{fontSize:'30px'}}><MdPreview /></span>
                                        {t("dash_view_discount")}
                                    </RightSideButton>
                                </div>
                            </SmallHandlerColContainerDiv>
                        </ModalUser>  
                    </div>
                )}
                {showModal && (
                    <WarningModal
                        text={t("text_discount_product_warning")}
                        textOk={t("ok_discount")}
                        textNo={t("no_cancel")}
                        onConfirm={handleModalDiscountProduct}
                        onCancel={handleCancelProduct}
                    />
                )}

                {/**Create Discount */}
                {dashboardCreateDiscountModalOpen && (
                    <CreateDiscount />
                )}

                {/**Edit Discount */}
                {dashboardEditDiscountModalOpen && (
                    <EditDiscount />
                )}

                {/**Delete Discount */}
                {dashboardDeleteDiscountModalOpen && (
                    <DeleteDiscount />
                )}

                {/**View Discount */}
                {dashboardViewDiscountModalOpen && (
                    <ViewDiscount />
                )}
                <br/><br/>
                <div id="modaloutalertdiscountproduct"></div>
                <div id="backdropoutalertdiscountproduct"></div>
            </ModalUser>
        </div>
    )
}

export default DiscountProduct
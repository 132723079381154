import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import { MdOutlineSave } from 'react-icons/md';
import { BsUpload } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal, LimitCharacters, getCurrentDate, formatDateData, differenceTwoDates } from '../../../../helperfunctions/Helper';
import WarningMultiModal from '../../../reusablecomponents/componentutils/WarningMultiModal';
import WarningModal from '../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallPriceInput,
    SmallCurrencyInput,
    SmallHandlerContainerDiv,
    SmallSaveButton,
} from '../../../ComponentStyles';

function Product() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertproduct";
    const elementDropId = "backdropoutalertproduct";
    const buttonHandler = "ouiHandlerAlertProductBtn";

    const navigateSellerProduct = useNavigate();

    //div variables ref
    const handleConfirmProductRef = useRef(null);
    const createProductRef = useRef(null);

    const productNameRef = useRef(null);
    const productDescriptionRef = useRef(null);
    const productPriceRef = useRef(null);
    const productCurrencyRef = useRef(null);
    const productImageRef = useRef(null);

    const [imagePreview, setImagePreview] = useState(null);
    //
    const [showModal, setShowModal] = useState(false);
    const [showModalExpired, setShowModalExpired] = useState(false);

    //image sources and resources states
    const [src, setSrc] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    //Limit the total number of characters (not words)
    const characterLimit = 500; // Set the character limit to 500
    const [textDescription, setTextDescription] = useState('');
    //limit characters for description
    const handleChangeDescription = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, characterLimit);
        setTextDescription(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = characterLimit - textDescription.length;

    const handleImageChange = async (e) => {

        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            //
            // Transform file into blob URL
            setSrc(URL.createObjectURL(file));
            //
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

        }
    }

    // Helper function to set the subdirectory value
    const setSubDirectory = (value) => {
        Axios.post(`${apiUrl}/set-subdirectory`, {
            tableProduct: value,
        }).then(() => {
            // Log success message or handle response
            const pTextePays = t("image_uplaod");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }).catch(() => {
            // Handle error
            const pTextePays = t("image_failed");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        });
    }

    const handleConfirmProduct = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table5; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        createProductRef.current.style.display = 'block';
        handleConfirmProductRef.current.style.display = 'none';
    }

    const handleSaveProduct = async (e) =>{
        e.preventDefault();

        var tableProfile = "";
        var profileTable = [];
        var resultatProfile = 0;
        //
        var tableProduct = "";
        var productTable = [];
        //
        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;
        //
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var checkResultat3 = [];
        var resultat3 = 0;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table1 to use in backend
        profileTable = retrieveTableQuery(codecountryDiv);
        if(profileTable){
            tableProfile = profileTable.table.table3;
        }
        //
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table5; //directory to save image in server side
        }
        //
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }
        //
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }

        const firstWhere = "code_user";
        var endDate = "";

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        var createCodeProduct = "";
        const productName = productNameRef.current.value;
        const productDescription = productDescriptionRef.current.value;
        const productPrice = productPriceRef.current.value;
        const productCurrency = productCurrencyRef.current.value;
        const productImage = productImageRef.current.value;
        const createStatusProduct = 'created';
        const createDateProduct = getCurrentDate();
        const productViews = "1";
        //var numberDays = 28;
        var createDateExpired = "";
        const createPlanSeller = 'trial';
        var randomNumber = 0;
        //
        if(!codeutilisateurDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!productName){
            const pTextePays = t("product_name");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!productDescription){
            const pTextePays = t("product_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!productPrice){
            const pTextePays = t("product_price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);  
        }else if(!productCurrency){
            const pTextePays = t("currency_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!productImage){
            const pTextePays = t("upload_image");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            //check if seller has already created his profile
            await Axios.post(`${apiUrl}/check/seller/profile`, {
                tableNameSeller:tableProfile,
                codeUtilisateur:codeutilisateurDiv,
            }).then((responseProfile) => {
                resultatProfile = responseProfile.data.length;
                if(resultatProfile === 0){
                    //if profile not already exists
                    const pTextePays = t("dash_create_profile");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    //check if seller has already create his subscription
                    Axios.post(`${apiUrl}/check/one/parameter/table`, {
                        tableNameSeller:tableNameSubscribe,
                        firstWhere:firstWhere,
                        firstParameter:codeutilisateurDiv,
                    }).then((response3) => {
                        resultat3 = response3.data.length;
                        checkResultat3 = response3.data;
                        if(resultat3 === 0){
                            //set the modal that let seller to create subscriber account
                            setShowModal(true);
                            setShowModalExpired(false);
                        }else{
                            //check and compare date end to date today
                            //if date today > date end
                            for (let j=0; j<resultat3; j++){
                                endDate = formatDateData(checkResultat3[j].date_end);
                            }
                            //
                            createDateExpired = endDate;
                            var resultDiference = differenceTwoDates(endDate);
                            if(resultDiference < 0){
                                setShowModal(false);
                                setShowModalExpired(true);
                                //navigateSellerLogin('/dashboardseller');
                            }else{
                                //check if product already exists
                                Axios.post(`${apiUrl}/check/seller/product`, {
                                    tableNameSeller:tableName,
                                    codeUtilisateur:codeutilisateurDiv,
                                }).then((responseProduct) => {
                                    resultat1 = responseProduct.data.length;
                                    if(resultat1 === 0){
                                        //generate random code number
                                        randomNumber = Math.floor((Math.random() * 10000000) + 1);
                                        createCodeProduct = "V"+randomNumber;
            
                                        //upload image and inputs
                                        const formData = new FormData();
            
                                        //inputs
                                        formData.append("usercode", codeutilisateurDiv);
                                        formData.append("codepays", codecountryDiv);
                                        formData.append("codeproduct", createCodeProduct);
                                        formData.append("productname", productName);
                                        formData.append("productdescription", productDescription);
                                        formData.append("productprice", productPrice);
                                        formData.append("productcurrency", productCurrency);
                                        //file
                                        formData.append("productfile", file);
                                        formData.append("productfile", fileName);
                                        //other inputs
                                        formData.append("productstatus", createStatusProduct);
                                        formData.append("productdate", createDateProduct);
                                        formData.append("dateexpired", createDateExpired);
                                        formData.append("sellerplan", createPlanSeller);
                                        formData.append("productviews", productViews);
                                        //save image in directory belonging
                                        formData.append("tablename", tableName);
                                        formData.append("thefile", tableProduct);
                                        formData.append("apiurl", apiUrl);
            
                                        Axios.post(`${apiUrl}/create/seller/product`, formData).then(() => {
                                            const pTextePays = t("product_success");
                                            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                                        });
                                    }else{
                                        const pTextePays = t("product_exists");
                                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                                    }
                                });

                                //navigateSellerProduct('/dashboardseller');
                            }
                        }
                    });
                }
            });
        }
    };

    //subscribe modal
    const handleModalSubscribe = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        navigateSellerProduct('/sellersubscribe');
    }
    //
    const handleModalNotNow = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        navigateSellerProduct('/dashboardseller');
    }
    //
    const handleCancelModal = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
    }

    //expired subscription modal
    const handleModalExpired = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        navigateSellerProduct('/dashboardseller');
    }
    //
    const handleCancelModalExpired = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
    }

    return (
        <div id='product'>
            <ModalUser>
                <FooterSubTitle>{t("dash_new_product")}</FooterSubTitle>
                <br/>
                <SmallTextLogin>{t("product_name")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <SmallInput type='text' ref={productNameRef} placeholder={t("productname_placeholder")} />
                </div>
                <br/>
                <SmallTextLogin>{t("product_description")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <SmallTextArea 
                        type='text' 
                        value={textDescription}
                        onChange={handleChangeDescription}
                        ref={productDescriptionRef} 
                        placeholder={t('the_product_description')} 
                        maxLength={characterLimit} // Set the maxlength attribute
                    />
                    <div>
                        {remainingCharactersDescription >= 0
                        ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                    </div>    
                </div>
                <br/>
                <SmallTextLogin>{t("product_price")}</SmallTextLogin>
                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                    <div style={{display:'block',textAlign:'center'}}>
                        <SmallPriceInput type='number' ref={productPriceRef}></SmallPriceInput>
                    </div>
                    <div>
                        <SmallCurrencyInput type='text' ref={productCurrencyRef} placeholder={t("currency_placeholder")} />
                    </div>
                </div>
                <br/>
                <SmallTextLogin>{t("product_image")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <label htmlFor='upload-btn' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                        <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                        {t("upload_image")}
                    </label>
                    <SmallInput 
                        id="upload-btn"
                        type='file' 
                        ref={productImageRef} 
                        onChange={handleImageChange}
                        accept="image/*" 
                        style={{ display: 'none' }}
                    />
                </div>
                {imagePreview && (
                    <div>
                        <div src={src} style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                            <img src={imagePreview} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </div>
                        <SmallHandlerContainerDiv ref={handleConfirmProductRef}>
                            <div>
                                <SmallSaveButton onClick={handleConfirmProduct}>
                                    <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                    {t("sellertexte18")}
                                </SmallSaveButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>  
                )}

                <br/>
                <div ref={createProductRef} style={{display:'none'}}>
                    <SmallHandlerContainerDiv>
                        <div>
                            <SmallSaveButton onClick={handleSaveProduct}>
                                <span style={{ fontSize:'30px'}}><MdOutlineSave /></span>
                                {t("save_product")}
                            </SmallSaveButton>
                        </div>
                    </SmallHandlerContainerDiv>
                </div>
                {showModal && (
                    <WarningMultiModal
                        text={t("text_subscribe_warning")}
                        textOk={t("ok_create")}
                        textPause={t("not_now")}
                        textNo={t("no_cancel")}
                        onConfirm={handleModalSubscribe}
                        onPause={handleModalNotNow}
                        onCancel={handleCancelModal}
                    />
                )}
                {showModalExpired && (
                    <WarningModal
                        text={t("text_expired_warning")}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalExpired}
                        onCancel={handleCancelModalExpired}
                    />
                )}
                <br/><br/>
                <div id="modaloutalertproduct"></div>
                <div id="backdropoutalertproduct"></div>
            </ModalUser>
        </div>
    )
}

export default Product
import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//
import FooterStarting from '../footer/FooterStarting';
//
import ListEventCard from '../reusablecomponents/cards/ListEventCard';
import ContactCardVideo from '../reusablecomponents/cards/ContactCardVideo';
import LikeButton from '../reusablecomponents/buttons/LikeButton';
//
import { retrieveTableQuery } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal, HideFooter, WhatsappCall } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';

//
import { 
    TermeScroller, 
    ModalUser, 
    MainFooterContainer,
    MainFooterDetailContainer, 
    HandlerContainerDiv,
    EventScrollerContainer,
    EventScrollerMenu,
    ContentScrollerDetails,
    DetailContainerMenu,
    VideoModalUser,
} from '../ComponentStyles';

import apiUrl from '../../apiconfig/ApiConfig';
import Axios from 'axios';

function FooterEvent() {
    HideFooter();

    const {t} = useTranslation();

    const navigateFooterEvent = useNavigate();

    //alert
    const elementOutId = "modaloutalertfooterevents";
    const elementDropId = "backdropoutalertfooterevents";
    const buttonHandler = "ouiHandlerAlertFooterEventsBtn";

    const [currentEventViews, setCurrentEventViews] = useState(0);

    //display event product states
    const [productContent, setProductContent] = useState(false);
    const [productEvent, setProductEvent] = useState(false);
    const [userFetchEvent, setFetchEvent] = useState([0]);
    //
    const [newEventsModalOpen, setNewEventsModalOpen] = useState(false);
    const [userEventsVideo, setUserEventsVideo] = useState([0]);

    //emplementation useEffect to retrieve all online events
    useEffect(() => {
        const fetchData = async () => {
            var tableName = "";
            var signupTable = [];
            var resultatEvents = 0;
            var titleAdvertise = "title_advertise";
            var valueTitle = "IS NOT NULL";
            var endDate = "date_end";
            var idAdvertise = "id_advertise";

            const codecountryDiv = document.getElementById('codecountry').innerText;
            const searchcountryDiv = document.getElementById('countrysearch').innerText;
            var countrySelected = '';
            if(searchcountryDiv){
                countrySelected = searchcountryDiv;
            }else{
                countrySelected = codecountryDiv;
            }

            signupTable = retrieveTableQuery(countrySelected);
            if(signupTable){
                tableName = signupTable.table.table15;
            }

            Axios.post(`${apiUrl}/check/event/advertise/table`, {
                tableNameSeller:tableName,
                titleAdvertise:titleAdvertise,
                valueTitle:valueTitle,
                endDate:endDate,
                idAdvertise:idAdvertise,
            }).then((responseEvent) => {
                resultatEvents = responseEvent.data.length;
                //console.log(resultatEvents);
                if(resultatEvents === 0){
                    const pTextePays = t("error_events");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setFetchEvent(responseEvent.data);
                    setProductContent(true);
                    setProductEvent(true);
                    setNewEventsModalOpen(false);
                }
            });
        }
        fetchData();
    }, [t, elementOutId, elementDropId, buttonHandler]);

    //event handler to watch the video selected
    const handleVideo = async (videoId) => {
        var tableNameVideo = "";
        var signupTableVideo = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table name based on country
        signupTableVideo = retrieveTableQuery(countrySelected);
        if(signupTableVideo){
            tableNameVideo = signupTableVideo.table.table15;
        }

        const firstWhere = "code_user";
        const firstParameter = videoId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameVideo, firstWhere, firstParameter),
        ]);

        setUserEventsVideo(dataTable1);
        var videoLength = dataTable1.length;
        var currentViews = "";
        for(let i=0; i<videoLength; i++){
            currentViews = dataTable1[i].views_advertise;
        }
        // Increment view count by 1
        const updatedViews = parseFloat(currentViews) + 1;
        setCurrentEventViews(updatedViews);

        setProductContent(false);
        setProductEvent(false);
        setNewEventsModalOpen(true);
    };
    //
    const handlerCloseEventsVideo = async () => {

        navigateFooterEvent(-1);
    };
    //
    const handlerDirectCallEvent = async (allId) => {
        //initialize variables
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";
        //
        const firstWhere = "code_user";
        const firstParameter = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateFooterEvent('/userlogin');
        }else{
            //retrieve buyer's inter prefixe, phone number and mode contact
            // Use Promise.all to execute multiple requests in parallel
            const [dataTable1] = await Promise.all([
                fetchDataFromTable(tableNameSeller, firstWhere, firstParameter),
            ]);
            resultatCont = dataTable1.length;
            viewContactData = dataTable1;
            for(let i=0; i<resultatCont; i++){
                prefixInter = viewContactData[i].prefixe;
                phoneNumber = viewContactData[i].phone;
            }

            //create a direct call feature
            var completePhone = prefixInter+phoneNumber;
            window.location.href = `tel:${completePhone}`;
        }
    }
    //
    const handlerChatRoomEvent = async (allId) => {
        //initialize variables
        var tableNameSeller = "";
        var signupTableSeller = [];
        var resultatCont = 0;
        var viewContactData = [];
        //
        var prefixInter = "";
        var phoneNumber = "";
        //
        const firstWhere = "code_user";
        const firstParameter = allId;

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }

        //retrieve table subscribe
        signupTableSeller = retrieveTableQuery(countrySelected);
        if(signupTableSeller){
            tableNameSeller = signupTableSeller.table.table2;
        }

        //check if user has already login
        var codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            //redirect user to login page
            navigateFooterEvent('/userlogin');
        }else{
            //retrieve buyer's inter prefixe, phone number and mode contact
            // Use Promise.all to execute multiple requests in parallel
            const [dataTable1] = await Promise.all([
                fetchDataFromTable(tableNameSeller, firstWhere, firstParameter),
            ]);
            resultatCont = dataTable1.length;
            viewContactData = dataTable1;

            for(let i=0; i<resultatCont; i++){
                prefixInter = viewContactData[i].prefixe;
                phoneNumber = viewContactData[i].phone;
            }

            //create a whatsapp call feature
            WhatsappCall(prefixInter, phoneNumber);
        }
    }
    //
    const handleLikeEvent = async (videoId) => {

        var tableNameVideo = "";
        var signupTableVideo = [];

        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchcountryDiv = document.getElementById('countrysearch').innerText;
        var countrySelected = '';
        if(searchcountryDiv){
            countrySelected = searchcountryDiv;
        }else{
            countrySelected = codecountryDiv;
        }
        //retrieve table name based on country
        signupTableVideo = retrieveTableQuery(countrySelected);
        if(signupTableVideo){
            tableNameVideo = signupTableVideo.table.table15;
        }

        //edit table advertise incrementing views
        const firstSet = 'views_advertise';
        //const firstSetParameter = parseFloat(eventViewsRef.current.value) + 1;
        const firstWhereId = "code_user";
        const firstParameterId = videoId;
        await Axios.post(`${apiUrl}/update/one/parameter/table`, {
            tableName: tableNameVideo,
            firstSet: firstSet,
            firstSetParameter: currentEventViews.toString(),
            firstWhereId: firstWhereId,
            firstParameterId: firstParameterId,
        });
    }

    //Function to fetch data from the API for each table
    const fetchDataFromTable = async (tableName, firstWhere, firstParameter) => {
        try {
            const result = await Axios.post(`${apiUrl}/check/one/parameter/table`, {
                tableNameSeller:tableName,
                firstWhere:firstWhere,
                firstParameter:firstParameter,
            });
            return result.data;
        } catch (error) {
            const pTextePays = t("error_data");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            throw error;
        }
    }

    const goBackFooterEventHandler = async () => {

        navigateFooterEvent(-1);
    }
    return (
        <div id='footerevent'>
            <TermeScroller>
                {/**new events products */}
                {productContent && (
                    <MainFooterContainer>
                        {/**event scroller */}
                        {productEvent && (
                            <div>
                                <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("event_text")}</span>
                                <EventScrollerContainer>
                                    <EventScrollerMenu>
                                        {userFetchEvent.map((valevent, indexevent) => (
                                            <ModalUser key={indexevent}>
                                                <ListEventCard 
                                                    url={valevent.image_advertise}
                                                    onImageClick={() => handleVideo(valevent.code_user)}
                                                    title={valevent.title_advertise}
                                                    views={valevent.views_advertise}
                                                />
                                            </ModalUser>
                                        ))}
                                    </EventScrollerMenu>
                                </EventScrollerContainer>
                            </div>
                        )}
                    </MainFooterContainer>
                )}
                
                {newEventsModalOpen && (
                    <MainFooterDetailContainer>
                        <ContentScrollerDetails>
                            <DetailContainerMenu>
                                <div>
                                    {userEventsVideo.map((valvideo, indexvideo) => (
                                        <VideoModalUser key={indexvideo}>
                                            <ContactCardVideo
                                                onButtonClickClose={() => handlerCloseEventsVideo()}
                                                onButtonClickPhoneCall={() => handlerDirectCallEvent(valvideo.code_user)}
                                                onButtonClickPhone={() => handlerChatRoomEvent(valvideo.code_user)}
                                                title={valvideo.title_advertise}
                                                videoUrl={valvideo.link_advertise}
                                                supportvideo={t("unsupported_browser")} 
                                                textDescription={valvideo.content_advertise}
                                            />
                                            <LikeButton 
                                                confirm={() => handleLikeEvent(valvideo.code_user)}
                                            />
                                        </VideoModalUser> 
                                    ))}
                                </div>
                            </DetailContainerMenu>
                        </ContentScrollerDetails>
                    </MainFooterDetailContainer>
                )}
                
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackFooterEventHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>

                <FooterStarting />
                <br/><br/>
                <div id="modaloutalertfooterevents"></div>
                <div id="backdropoutalertfooterevents"></div>
            </TermeScroller>
        </div>
    )
}

export default FooterEvent
import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
//
import { GoNextPopUpButton } from '../ReusableStyles';

function PopUpForwardButton({onClick}) {

    const navigateNext = useNavigate();

    const goNext = () => {
        if (onClick && typeof onClick === 'function') {
            onClick(); // Call the provided onClick function if available
        } else {
            navigateNext('/'); // Default behavior: go back in history
        }
    };
    return (
       <GoNextPopUpButton onClick={goNext}>
           <FiArrowRight /> 
       </GoNextPopUpButton>
    )
}

export default PopUpForwardButton
import React, { useRef, /*useState*/ } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//helperfunction
import { ShowFooter } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
import SendButton from '../reusablecomponents/buttons/SendButton';

//
import { 
    TermeScroller, 
    //FooterTitle, 
    //FooterUnderTitle, 
    FooterIntro, 
    FooterSubTitle, 
    HandlerContainerDiv,
    TextLogin, 
    LargeSelect,
    LargeInput,
    TextArea,
} from '../ComponentStyles';

//import Axios from 'axios';

function Feedback() {
    const {t} = useTranslation();

    const navigateFeedback = useNavigate();

    const ratingStarRef = useRef(null);
    const textFeedBackRef = useRef(null);
    const nameFeedBackRef = useRef(null);

    const goSendHandler = async (e) => {
        //e.preventDefault();

        alert('OK')
    }

    const goBackFeedbackHandler = async (e) => {
        ShowFooter();
        navigateFeedback('/');
    }

    return (
        <div id='feedback'>
            <TermeScroller>
                <FooterSubTitle>{t("feddbacktitle")}</FooterSubTitle>
                <br/>
                <FooterIntro>
                    <strong>{t("feedbackdear")}</strong>,<br/>
                    {t("feedbacktexte1")}
                </FooterIntro>
                <br/>
                <TextLogin>{t("feedbacktexte2")}</TextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeSelect ref={ratingStarRef} id="ratingstar" name="ratingstar" >
                        <option value=''>{t("feedbacktexte3")}</option>
                        <option value='1'>⭐</option>
                        <option value='2'>⭐⭐</option>
                        <option value='3'>⭐⭐⭐</option>
                        <option value='4'>⭐⭐⭐⭐</option>
                        <option value='5'>⭐⭐⭐⭐⭐</option>
                    </LargeSelect>
                </div>
                <br/>
                <TextLogin>{t("feedbacktexte4")}</TextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <TextArea ref={textFeedBackRef} id="textfeedback" ></TextArea>
                </div>
                <br/>
                <TextLogin>{t("your_name")}</TextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeInput type='text' ref={nameFeedBackRef} id="namefeedback" ></LargeInput>
                </div>
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackFeedbackHandler} ></PopUpBackButton>
                    </div>
                    <div>
                        <SendButton onClick={goSendHandler}></SendButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
                <div id="modaloutalertfeedback"></div>
                <div id="backdropoutalertfeedback"></div>
            </TermeScroller>
            <br/><br/>
        </div>
    )
}

export default Feedback
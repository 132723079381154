import React from 'react';
//
import InnerPopUpBackButton from '../buttons/InnerPopUpBackButton';
import { MdOutlinePlayLesson } from "react-icons/md";
//
import { 
    CardDetailContainer,
    CardVideoText,
    HandlerVideoContainerDiv,
    CardButtonPhone,
    CardTextVideoBody,
    CardHeader,
    CardImageProduct,
    StyledParagraph,
} from '../../ComponentStyles';

function ListApplicationCard({ url, title, textDescription, textLink,  onButtonClickClose, onButtonClickDemo }) {
    return (
        <div>
            <CardDetailContainer>
                <CardHeader>
                    <CardImageProduct>
                        <img src={url} alt='preview' style={{ maxWidth: '100%', maxHeight: '50px', borderRadius: '10px' }} />
                        <CardVideoText>{title}</CardVideoText>
                    </CardImageProduct>
                </CardHeader>
                <HandlerVideoContainerDiv>
                    <div>
                        <InnerPopUpBackButton onClick={onButtonClickClose}></InnerPopUpBackButton>
                    </div>
                    <div>
                        <CardButtonPhone onClick={onButtonClickDemo}>
                            <a style={{color:"green", textDecoration:"none"}} href={textLink} target="_blank" rel="noopener noreferrer">
                                <MdOutlinePlayLesson style={{fontSize:'50px'}} />
                            </a>
                        </CardButtonPhone>
                    </div>
                </HandlerVideoContainerDiv>
                <CardTextVideoBody>
                    <StyledParagraph>{textDescription}</StyledParagraph>
                </CardTextVideoBody>
            </CardDetailContainer>
        </div>
    )
}

export default ListApplicationCard
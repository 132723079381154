export const TRANSLATIONS_FR = {
    langue:"Langue ?",
    francais:"Français",
    anglais:"Anglais",
    portugais:"Portugais",
    //
    allright:"Tous droits réservés",
    apropos:"À Propos",
    contact:"Contactez-nous",
    faq:"FAQ",
    privacy:"Confidentialité",
    termes:"Termes",
    //translate a propos
    apropostitre:"À Propos de Bazali",
    aproposrevision:"Dernière mise à jour septembre 2023",
    aproposintro:"La logique commerciale prône que tout vendeur est acheteur, mais tout acheteur n'est pas vendeur.",
    aproposintrosuite:"Bazali est un terme du langage de rue ivoirien (nouchi ou noussia) dérivant de la langue française et qui signifie: Vendre rapidement et à bas prix quelque chose, voire se débarrasser de quelque chose en la jetant ou l'offrant gratuitement à une autre personne.",
    aproposintroensuite:"Bazali est une platforme d'interconnexion et d'échange entre vendeurs et acheteurs d'un pays donné, de même qu'avec les 15 pays de la CEDEAO pour une population estimée à plus de 350 milions d'habitants où les systèmes de commercialisations et d'échanges de divers produits stagnent depuis 1960.",
    //
    soustitre1:"Pourquoi Bazali ?",
    soustitretexte1:"Nul ne pourrait nier qu'il ne s'est jamais trouvé dans une situation où il était difficile voire impossible d'acheter quelque chose sans savoir que la chose se trouvait non loin du lieu où il se trouvait.",
    soustitretexte2:"Combien de fois nos ménagères sont obligées de sillonner tout le marché durant des heures, pour le simple but de trouver des marchandises et produits à des prix avantageux pour leurs consommations familliales journalières.",
    soustitretexte3:"Combien il est difficile pour nos opérateurs économiques de commercer et échanger avec leurs pairs des pays de la sous-région CEDEAO quand on parle d'une intégration économique sous-régionales des peuples et des biens.",
    soustitretexte4:"Plusieurs cas de figures sont légions et la liste est non exhaustive...",
    //
    soustitre2:"Spécificités",
    soustitretexte5:"Bazali est une application mobile hybride capable de fonctionner même en cas de perte de réseau pendant son utilisation.",
    soustitretexte6:"Elle est conçue pour tous les acheteurs, vendeurs, opérateurs économiques et industries des secteurs primaires, sécondaires et tertiaires.",
    soustitretexte7:"Facile à utiliser, il suffit seulement de savoir manipuler un téléphone ou tablette portable et aussi pouvoir écrire des messages ou envoyer des messages via dispositifs mobiles pour découvrir les potentialités de Bazali dans son pays et aussi vendre ou acheter à l'international CEDEAO.",
    soustitretexte8:"Bazali est une application polyglotte (Français, Anglais, Portugais) car les langues officielles véhiculées dans les 15 pays de la CEDEAO sont celles citées ci-dessus en parenthèse. Et tout utilisateur en cas de besoin avec un autre utilisateur de langue différentes pourrait se servir aussi du traducteur intégré de son dispositif mobile pour ses resultats de recherches.",
    //
    soustitre3:"Objectifs",
    soustitretexte9:"Permettre et offrir l'opportunité au client d'etre vraiment roi comme le dit l'addage et acheter intelligemment à moindre prix.",
    soustitretexte10:"Fluidité des achats et échanges entre consommateurs et opérateurs économiques des pays membres.",
    soustitretexte11:"Créer de nouvelles opportunités d'affaires pour tous les utilisateurs de cette application.",
    soustitretexte12:"Donner la possibilité aux consommateurs d'etre orientés vers le meilleurs offres sur le marché local et intercommunautaire.",
    //translate termes
    termestitre:"Conditions d'utilisation",
    termesrevision:"Bienvenue à",
    termestexte1:"opéré par",
    termestexte2:"En accédant à l'application Bazali ou à son site Web, que ce soit par le biais d'un appareil mobile, d'une application mobile ou d'un ordinateur, vous acceptez d'être lié par les présentes Conditions d'utilisation (le présent « Accord »), que vous créiez ou non un compte Bazali. Si vous souhaitez créer un compte Bazali et utiliser le Service, veuillez lire ces Conditions d'utilisation.",
    termessoustitre1:"À propos de Bazali",
    termessoustitretexte1:"Bazali est un marché qui permet aux utilisateurs d'offrir, de vendre et d'acheter à peu près n'importe quoi dans une variété de formats de prix et d'emplacements.",
    termessoustitretexte2:"Bazali n'est pas en possession de quoi que ce soit répertorié ou vendu par l'intermédiaire de Bazali, et n'est pas impliqué dans la transaction réelle entre les acheteurs et les vendeurs. Le contrat de vente est conclu directement entre l'acheteur et le vendeur. Elle n'est pas partie à la transaction et n'est pas un commissaire-priseur traditionnel.",
    termessoustitretexte3:"Bien que nous puissions fournir des prix, des frais de port, des listes et d'autres conseils dans nos services, ces conseils sont uniquement informatifs et vous pouvez décider de les suivre ou non. Bazali n'examine pas les listes ou le contenu des utilisateurs.",
    termessoustitretexte4:"Bazali n'a aucun contrôle sur, et ne garantit pas l'existence, la qualité, la sécurité ou la légalité des articles annoncés; la véracité ou l'exactitude du contenu, des listes ou des commentaires des utilisateurs; la capacité des vendeurs à vendre des articles; la capacité des acheteurs à payer les articles; ou qu'un acheteur ou un vendeur effectuera réellement une transaction ou retournera un article.",
    termessoustitre2:"Acceptation des conditions d'utilisation",
    termessoustitretexte5:"Le présent Accord est un contrat électronique qui établit les conditions juridiquement contraignantes que vous devez accepter pour utiliser le Service. Cet accord comprend la politique de confidentialité de la société, nos conseils de sécurité et les conditions divulguées et acceptées par vous si vous achetez des produits ou services que nous proposons sur le service.",
    termessoustitretexte6:"En accédant ou en utilisant le Service, vous acceptez le présent Contrat et acceptez les termes, conditions et avis contenus ou référencés ici et consentez à ce que le présent Contrat et tous les avis vous soient fournis sous forme électronique. Pour retirer ce consentement, vous devez cesser d'utiliser le Service et résilier votre compte. Veuillez imprimer une copie de cet Accord pour vos archives. Le présent Accord peut être modifié par la Société de temps à autre, ces modifications prenant effet dès leur publication par la Société sur le Service.",
    termessoustitre3:"Admissibilité",
    termessoustitretexte7:"Aucune partie de Bazali n'est destinée aux personnes de moins de 18 ans. Vous devez avoir au moins 18 ans pour accéder et utiliser le Service. Toute utilisation du Service est nulle là où elle est interdite. En accédant et en utilisant le Service, vous déclarez et garantissez que vous avez le droit, l'autorité et la capacité de conclure le présent Accord et de respecter tous les termes et conditions du présent accord. Si vous créez un compte, vous déclarez et garantissez que vous n'avez jamais été reconnu coupable d'un crime et que vous n'êtes pas tenu de vous inscrire en tant que délinquant sexuel avec une entité gouvernementale.",
    termessoustitre4:"Créer un compte",
    termessoustitretexte8:"Pour utiliser Bazali, vous devez vous connecter lors de votre recherche de rencontres. Si vous le faites, vous nous autorisez à accéder et à utiliser certaines informations de compte, y compris, mais sans s'y limiter, votre profil public et les informations sur les amis que vous pourriez partager en commun avec autres utilisateurs de Bazali. Pour plus d'informations sur les informations que nous recueillons auprès de vous et sur la manière dont nous les utilisons, veuillez consulter notre politique de confidentialité.",
    termessoustitre5:"Durée et résiliation",
    termessoustitretexte9:"Le présent Accord restera pleinement en vigueur tant que vous utiliserez le Service et/ou aurez un compte Bazali. Vous pouvez désactiver votre compte à tout moment, pour quelque raison que ce soit, en suivant les instructions dans les Paramètres du Service. La Société peut résilier ou suspendre votre compte à tout moment sans préavis si la Société estime que vous avez enfreint le présent Accord, ou pour toute autre raison, avec ou sans motif, à sa seule discrétion.",
    termessoustitretexte10:"Lors d'une telle résiliation ou suspension, vous n'aurez droit à aucun remboursement des produits ou services achetés. La Société n'est pas tenue de divulguer, et peut être interdite par la loi de divulguer, la raison de la résiliation ou de la suspension de votre compte. Après la résiliation de votre compte pour quelque raison que ce soit, tous les termes de cet accord survivent à cette résiliation et restent pleinement en vigueur, à l'exception des conditions qui, de par leur nature, expirent ou sont pleinement satisfaites.",
    termessoustitre6:"Usage non commercial",
    termessoustitretexte11:"Le Service est réservé à un usage personnel. Les utilisateurs ne peuvent pas utiliser le Service ou tout contenu contenu dans le Service (y compris, mais sans s'y limiter, le contenu d'autres utilisateurs, les conceptions, les textes, les graphiques, les images, les vidéos, les informations, les logos, les logiciels, les fichiers audio et le code informatique) en relation avec avec toute entreprise commerciale, telle que la publicité ou la sollicitation d'un utilisateur pour acheter ou vendre des produits ou services non proposés par la Société ou solliciter d'autres personnes pour assister à des fêtes ou à d'autres activités sociales",
    termessoustitretexte12:"fonctions ou de mise en réseau à des fins commerciales. Les utilisateurs du Service ne peuvent utiliser aucune information obtenue à partir du Service pour contacter, faire de la publicité, solliciter ou vendre à tout autre utilisateur sans son consentement explicite préalable. Les organisations, sociétés et/ou entreprises ne peuvent pas utiliser le Service ou le Service à quelque fin que ce soit.",
    termessoustitretexte13:"La Société peut enquêter et prendre toute action en justice disponible en réponse à des utilisations illégales et/ou non autorisées du Service, y compris la collecte de noms d'utilisateur et/ou d'adresses e-mail d'utilisateurs par des moyens électroniques ou autres dans le but d'envoyer des e-mail et cadrage non autorisé ou lien vers le Service.",
    termessoustitre7:"Sécurité du compte",
    termessoustitretexte14:"Vous êtes responsable du maintien de la confidentialité du nom d'utilisateur et du mot de passe que vous désignez au cours du processus d'inscription, et vous êtes seul responsable de toutes les activités qui se produisent sous votre nom d'utilisateur et votre mot de passe. Vous acceptez immédiatement informez la Société de toute divulgation ou utilisation non autorisée de votre nom d'utilisateur ou de votre mot de passe ou de toute autre violation de la sécurité et assurez-vous que vous vous déconnectez de votre compte à la fin de chaque session.",
    termessoustitre8:"Droits de propriété",
    termessoustitretexte15:"La Société détient et conserve tous les droits de propriété sur le Service, ainsi que sur tous les contenus, marques de commerce, noms commerciaux, marques de service et autres droits de propriété intellectuelle qui s'y rapportent. Le service contient le matériel protégé par le droit d'auteur, les marques de commerce et d'autres droits de propriété informations de la Société et de ses concédants.",
    termessoustitretexte16:"Vous acceptez de ne pas copier, modifier, transmettre, créer des œuvres dérivées à partir de, utiliser ou reproduire de quelque manière que ce soit tout matériel protégé par le droit d'auteur, marques de commerce, noms commerciaux, marques de service ou autres Propriété intellectuelle ou informations propriétaires accessibles par le biais du Service, sans d'abord obtenir le consentement écrit préalable de la Société ou, si ces biens ne sont pas détenus par la Société, le propriétaire de ces droits intellectuels ou de la propriété.",
    termessoustitretexte17:"Vous acceptez de ne pas supprimer, masquer ou autrement modifier les avis de propriété apparaissant sur tout contenu, y compris les droits d'auteur, les marques de commerce et autres avis de propriété intellectuelle.",
    termessoustitre9:"Contenu publié par vous",
    termessoustitretexte18:"Vous êtes seul responsable du contenu et des informations que vous publiez, téléchargez, publiez, liez, transmettez, enregistrez, affichez ou mettez autrement à disposition (ci-après, publiez) sur le Service ou transmettez à d'autres utilisateurs, y compris les messages texte, le chat ou texte de profil. Vous ne pouvez pas publier dans le cadre du Service, ou transmettre à la Société ou à tout autre utilisateur (sur ou hors du Service), tout contenu offensant, inexact, incomplet, abusif, obscène, profane, menaçant, intimidant, matériel harcelant, racialement offensant ou illégal, ou tout matériel qui enfreint ou viole les droits d'une autre personne (y compris les droits de propriété intellectuelle et les droits à la vie privée et à la publicité). Vous déclarez et garantissez que toutes les informations que vous soumettez lors de la création de votre compte, y compris les informations soumises, sont exactes et véridiques.",
    termessoustitretexte19:"Vous comprenez et acceptez que la Société peut, sans y être obligée, surveiller ou réviser tout Contenu que vous publiez dans le cadre d'un Service. La Société peut supprimer tout Contenu, en tout ou en partie, qui, à son seul jugement, viole le présent Accord ou peut nuire à la réputation du Service ou de la Société.",
    termessoustitretexte20:"En publiant du Contenu dans le cadre du Service, vous accordez automatiquement à la Société, à ses sociétés affiliées, licenciés et successeurs, un droit et une licence irrévocables, perpétuels, non exclusifs, transférables, sous-licenciables, entièrement payés, mondiaux d'utilisation, copier, stocker, exécuter, afficher, reproduire, enregistrer, lire, adapter, modifier et distribuer le Contenu, préparer des œuvres dérivées du Contenu ou incorporer le Contenu dans d'autres travaux, et accorder et autoriser des sous-licences du qui précède dans tout média connu à ce jour ou créé ultérieurement. Vous déclarez et garantissez que toute publication et utilisation de votre Contenu par la Société n'enfreindra ni ne violera les droits d'un tiers.",
    termessoustitretexte21:"En plus des types de contenu décrits dans la section ci-dessus, ce qui suit est une liste partielle du type de contenu qui est interdit dans le service. Vous ne pouvez pas publier, télécharger, afficher ou autrement rendre disponible un Contenu qui :",
    termessoustitretexte22:"qui promeut le racisme, le sectarisme, la haine ou les atteintes physiques de quelque nature que ce soit contre un groupe ou un individu;",
    termessoustitretexte23:"prône le harcèlement ou l'intimidation d'une autre personne;",
    termessoustitretexte24:"demande de l'argent à d'autres utilisateurs du Service ou est destiné à les frauder d'une autre manière;",
    termessoustitretexte25:"promeut des informations fausses ou trompeuses, ou promeut des activités illégales ou des comportements diffamatoires, diffamatoires ou autrement répréhensibles;",
    termessoustitretexte26:"fait la promotion d'une copie illégale ou non autorisée de l'œuvre protégée par le droit d'auteur d'une autre personne, par exemple en fournissant des programmes informatiques piratés ou des liens vers ceux-ci, en fournissant des informations pour contourner les dispositifs de protection contre la copie installés par le fabricant;",
    termessoustitretexte27:"contient des pages d'accès restreint ou par mot de passe uniquement, ou des pages ou des images cachées (celles qui ne sont pas liées à ou à partir d'une autre page accessible);",
    termessoustitretexte28:"fournit du matériel qui exploite des personnes de manière sexuelle, violente ou illégale, ou sollicite des informations personnelles auprès de toute personne de moins de 18 ans;",
    termessoustitretexte29:"fournit des informations pédagogiques sur des activités illégales telles que la fabrication ou l'achat d'armes ou de drogues illégales, la violation de la vie privée de quelqu'un ou la fourniture, la diffusion ou la création de virus informatiques;",
    termessoustitretexte30:"contient des virus, des bombes à retardement, des chevaux de Troie, des robots d'annulation, des vers ou d'autres codes, composants ou appareils nuisibles ou perturbateurs;",
    termessoustitretexte31:"usurpe l'identité de toute personne ou entité ou dénature d'une autre manière son affiliation, son lien ou son association avec une personne ou une entité;",
    termessoustitretexte32:"fournit des informations ou des données que vous n'avez pas le droit de rendre disponibles en vertu de la loi ou de relations contractuelles ou fiduciaires (telles que des informations privilégiées, exclusives et confidentielles informations);",
    termessoustitretexte33:"perturbe le flux normal du dialogue, fait « défiler» l'écran plus rapidement que les autres utilisateurs ne sont capables de taper, ou autrement négativement affecte la capacité des autres utilisateurs à s'engager dans des échanges en temps réel;",
    termessoustitretexte34:"sollicite des mots de passe ou des informations d'identification personnelle à des fins commerciales ou illégales auprès d'autres utilisateurs ou diffuse les informations personnelles d'une autre personne sans son autorisation; et",
    termessoustitretexte35:"publie ou promeut des activités commerciales et/ou des ventes sans notre consentement écrit préalable, telles que des concours, des tirages au sort, du troc, de la publicité et des systèmes pyramidaux.",
    termessoustitretexte36:"La Société se réserve le droit, à sa seule discrétion, d'enquêter et d'engager toute action en justice contre toute personne qui enfreint cette disposition, y compris la suppression de la communication incriminée du Service et la résiliation ou la suspension du compte de ces contrevenants.",
    termessoustitretexte37:"Votre utilisation du Service, y compris tout le Contenu que vous publiez via le Service, doit être conforme à toutes les lois et réglementations applicables. Vous acceptez que la Société puisse accéder, conserver et divulguer les informations et le contenu de votre compte si la loi l'exige ou en croyant de bonne foi qu'un tel accès, conservation ou divulgation est raisonnablement nécessaire, comme:",
    termessoustitretexte38:"se conformer à la procédure légale; appliquer le présent accord; répondre aux réclamations selon lesquelles tout Contenu viole les droits de tiers; répondre à vos demandes de service client ou vous permettre d'utiliser le Service à l'avenir; ou protéger les droits, la propriété ou la sécurité personnelle de la Société ou de toute autre personne.",
    termessoustitretexte39:"Vous acceptez que tout Contenu que vous placez sur le Service puisse être vu par d'autres utilisateurs et puisse être vu par toute personne visitant ou participant au Service.",
    termessoustitretexte40:"Activités interdites",
    termessoustitretexte41:"La Société se réserve le droit d'enquêter, de suspendre et/ou de résilier votre compte si vous avez abusé du Service ou si vous vous êtes comporté d'une manière que la Société considère comme inappropriée ou illégale, y compris des actions ou des communications qui se produisent en dehors du Service mais impliquent des utilisateurs que vous rencontrez via le Service. Ce qui suit est une liste partielle des types d'actions que vous ne pouvez pas entreprendre en ce qui concerne le Service. Tu ne vas pas:",
    termessoustitretexte42:"usurper l'identité de toute personne ou entité.",
    termessoustitretexte43:"solliciter de l'argent de tous les utilisateurs.",
    termessoustitretexte44:"publier tout Contenu interdit par la Section.",
    termessoustitretexte45:"traquer ou harceler autrement une personne.",
    termessoustitretexte46:"exprimez ou impliquez que toutes les déclarations que vous faites sont approuvées par la Société sans notre consentement écrit préalable spécifique.",
    termessoustitretexte47:"utiliser le Service de manière illégale ou pour commettre un acte illégal;",
    termessoustitretexte48:"demander ou utiliser les utilisateurs pour dissimuler l'identité, la source ou la destination de tout argent ou produit obtenu illégalement.",
    termessoustitretexte49:"utiliser un robot, une araignée, une application de recherche/récupération de site ou tout autre dispositif ou processus manuel ou automatique pour récupérer, indexer, extraire des données ou reproduire ou contourner de quelque manière que ce soit la structure de navigation ou la présentation du Service ou de son contenu.",
    termessoustitretexte50:"collecter les noms d'utilisateur et/ou les adresses e-mail des utilisateurs par des moyens électroniques ou autres dans le but d'envoyer des e-mails non sollicités ou d'encadrer ou de créer des liens non autorisés vers le Service.",
    termessoustitretexte51:"Interférer avec ou perturber le Service ou les serveurs ou réseaux connectés au Service.",
    termessoustitretexte52:"envoyer par e-mail ou autrement transmettre tout matériel contenant des virus logiciels ou tout autre code informatique, fichier ou programme conçu pour interrompre, détruire ou limiter la fonctionnalité de tout logiciel ou matériel informatique ou équipement de télécommunication.",
    termessoustitretexte53:"falsifier des en-têtes ou autrement manipuler des identifiants afin de dissimuler l'origine de toute information transmise à ou via le Service (soit directement, soit indirectement par l'utilisation de tiers Logiciel).",
    termessoustitretexte54:"encadrer ou refléter toute partie du Service, sans l'autorisation écrite préalable de la Société.",
    termessoustitretexte55:"utiliser des balises méta ou du code ou d'autres dispositifs contenant une référence à la Société ou au Service (ou à toute marque commerciale, nom commercial, marque de service, logo ou slogan de la Société) pour diriger toute personne vers tout autre site Web à quelque fin que ce soit.",
    termessoustitretexte56:"modifier, adapter, concéder en sous-licence, traduire, vendre, désosser, déchiffrer, décompiler ou autrement désassembler toute partie du Service, tout logiciel utilisé sur ou pour le Service, ou obliger d'autres à le faire.",
    termessoustitretexte57:"publier, utiliser, transmettre ou distribuer, directement ou indirectement (par exemple, capture d'écran) de quelque manière que ce soit ou sur tout support, tout contenu ou information obtenu à partir du Service autrement qu'uniquement en relation avec votre utilisation du Service conformément au présent Accord.",
    termessoustitretexte58:"Service Clients",
    termessoustitretexte59:"par l'intermédiaire de ses représentants du service à la clientèle. Lorsque vous communiquez avec nos représentants du service client, vous acceptez de ne pas être abusif, obscène, profane, offensant, sexiste, menaçant, harcelant, raciste ou de ne pas vous comporter autrement de manière inappropriée. Si nous pensons que votre comportement envers l'un de nos représentants du service client ou d'autres employés est à tout moment menaçant ou offensant, nous nous réservons le droit de résilier immédiatement votre compte.",
    termessoustitretexte60:"Modifications du service",
    termessoustitretexte61:"La Société se réserve le droit à tout moment de modifier ou interrompre, temporairement ou définitivement, le Service (ou toute partie de celui-ci) avec ou sans préavis. Vous acceptez que la Société ne soit pas responsable envers vous ou envers un tiers pour toute modification, suspension ou interruption du Service. Pour protéger l'intégrité du Service, la Société se réserve le droit, à tout moment et à sa seule discrétion, d'empêcher les utilisateurs de certaines adresses IP d'accéder au Service.",
    termessoustitretexte62:"Politique de droit d'auteur; Avis et procédure pour faire des réclamations pour violation du droit d'auteur",
    termessoustitretexte63:"Vous ne pouvez pas publier, distribuer ou reproduire de quelque manière que ce soit du matériel protégé par le droit d'auteur, des marques de commerce ou d'autres informations exclusives sans avoir obtenu le consentement écrit préalable du propriétaire de ces droits de propriété. Sans limiter ce qui précède, si vous pensez que votre travail a été copié et publié sur le Service d'une manière qui constitue une violation du droit d'auteur, veuillez fournir notre Copyright Agent avec les informations suivantes :",
    termessoustitretexte64:"une signature électronique ou physique de la personne autorisée à agir au nom du titulaire du droit d'auteur;",
    termessoustitretexte65:"une description de l'œuvre protégée par le droit d'auteur qui, selon vous, a été enfreinte;",
    termessoustitretexte66:"une description de l'endroit où se trouve le matériel que vous prétendez violer sur le Service (et cette description doit être raisonnablement suffisante pour permettre à la Société de trouver le matériel prétendument contrefait, comme une URL);",
    termessoustitretexte67:"votre adresse, numéro de téléphone et adresse e-mail;",
    termessoustitretexte68:"une déclaration écrite de votre part indiquant que vous pensez de bonne foi que l'utilisation contestée n'est pas autorisée par le titulaire du droit d'auteur, son agent ou la loi; et",
    termessoustitretexte69:"une déclaration de votre part, faite sous peine de parjure, que les informations ci-dessus dans votre avis sont exactes et que vous êtes le titulaire du droit d'auteur ou autorisé à agir au nom du titulaire du droit d'auteur.",
    termessoustitretexte70:"La Société résiliera les comptes des contrevenants récidivistes.",
    termessoustitretexte71:"Vous reconnaissez et acceptez que ni la Société ni ses sociétés affiliées et partenaires tiers ne sont responsables et n'auront aucune responsabilité, directe ou indirecte, pour toute perte ou dommage, y compris les blessures corporelles ou la mort, résultant ou présumé être le résultat de tout contenu incorrect ou inexact publié dans le service, qu'il soit causé par les utilisateurs ou par l'un des équipements ou programmes associés ou utilisés dans le service;",
    termessoustitretexte72:"l'opportunité, la suppression ou le retrait, la livraison incorrecte ou le défaut de stockage de tout contenu, communication ou paramètres de personnalisation; la conduite, en ligne ou hors ligne, de tout utilisateur; toute erreur, omission ou défaut, interruption, suppression, altération, retard de fonctionnement ou de transmission, vol ou destruction ou accès non autorisé à tout utilisateur ou communication d'utilisateur;",
    termessoustitretexte73:"ou tout problème, panne ou dysfonctionnement technique de tout réseau ou lignes téléphoniques, systèmes informatiques en ligne, serveurs ou fournisseurs, équipement informatique, logiciel, panne de courrier électronique ou de lecteurs sur compte de problèmes techniques ou d'embouteillages sur Internet ou sur tout site Web ou une combinaison de ceux-ci, y compris des blessures ou des dommages aux utilisateurs ou à l'ordinateur ou à l'appareil de toute autre personne liés à ou résultant de la participation ou du téléchargement de matériel en relation avec Internet et/ou en connexion avec le Service.",
    termessoustitretexte74:"DANS LA MESURE MAXIMALE AUTORISÉE PAR LA LOI APPLICABLE, LA SOCIÉTÉ FOURNIT LE SERVICE « EN L'ÉTAT » ET « SELON LA DISPONIBILITÉ » ET N'ACCORDE AUCUNE GARANTIE D'AUCUNE SORTE, QU'ELLE SOIT EXPRESSE, IMPLICITE, LÉGALE OU AUTRE EN CE QUI CONCERNE LE SERVICE (Y COMPRIS TOUS CONTENU QU'IL CONTIENT), Y COMPRIS (SANS LIMITATION) TOUT IMPLICITE GARANTIES DE QUALITÉ SATISFAISANTE, DE QUALITÉ MARCHANDE, ADÉQUATION À UN USAGE PARTICULIER OU NON CONTREFAÇON. LA SOCIÉTÉ NE DÉCLARE NI NE GARANTIT QUE LE SERVICE SERA ININTERROMPU OU SANS ERREUR, SÉCURISÉ OU QUE TOUT DÉFAUT OU ERREUR DANS LE SERVICE SERA CORRIGÉ.",
    termessoustitretexte75:"TOUT MATÉRIEL TÉLÉCHARGÉ OU OBTENU AUTREMENT PAR L'UTILISATION DU SERVICE EST ACCÉDÉ À VOTRE PROPRE DISCRÉTION ET À VOS RISQUES, ET VOUS SEREZ SEUL RESPONSABLE ET RENONCEZ À TOUTES LES RÉCLAMATIONS ET CAUSES D'ACTION CONCERNANT TOUT DOMMAGE À VOTRE APPAREIL, SYSTÈME INFORMATIQUE , ACCÈS À INTERNET, TÉLÉCHARGEMENT OU DISPOSITIF D'AFFICHAGE, OU PERTE OU CORRUPTION DE DONNÉES RÉSULTANT OU POUVANT RÉSULTER DU TÉLÉCHARGEMENT D'UN TEL MATÉRIEL. SI VOUS N'ACCEPTEZ PAS CETTE LIMITATION DE RESPONSABILITÉ, VOUS N'ÊTES PAS AUTORISÉ À TÉLÉCHARGER OU À OBTENIR DU MATÉRIEL VIA LE SERVICE.",
    termessoustitretexte76:"De temps à autre, la Société peut mettre à disposition via le Service des opinions, des conseils, des déclarations, des offres ou d'autres informations ou contenus de tiers. Tout contenu tiers relève de la responsabilité de leurs auteurs respectifs et ne doit pas nécessairement être invoqué. Ces auteurs tiers sont seuls responsables de ce contenu.",
    termessoustitretexte77:"LA SOCIÉTÉ NE GARANTIT PAS L'EXACTITUDE, L'EXHAUSTIVITÉ OU L'UTILITÉ DE TOUT CONTENU TIERS FOURNI VIA LE SERVICE, NI N'ADOPTE, APPROUVER OU ACCEPTER LA RESPONSABILITÉ DE L'EXACTITUDE OU DE LA FIABILITÉ DE TOUT AVIS, CONSEIL OU DÉCLARATION FAITE PAR TOUTE PARTIE APPARAISSANT DANS LE SERVICE. SOUS LE N ° CIRCONSTANCES LA SOCIÉTÉ OU SES FILIALES SERONT-ELLES RESPONSABLES DE TOUTE PERTE OU DOMMAGE RÉSULTANT DE VOTRE CONFIANCE AUX INFORMATIONS OU AUTRE CONTENU PUBLIÉ DANS LE SERVICE, OU TRANSMIS À OU PAR DES UTILISATEURS.",
    termessoustitretexte78:"En plus du paragraphe précédent et des autres dispositions du présent Accord, tout conseil pouvant être publié dans le Service est uniquement à des fins d'information et de divertissement et n'est pas destiné à remplacer ou à se substituer à tout conseil professionnel financier, médical, juridique ou autre. La Société ne fait aucune déclaration ou garanties et décline expressément toute responsabilité concernant tout traitement, action par ou effet sur toute personne suite aux informations proposées ou fournies dans ou via le Service. Si vous avez des inquiétudes spécifiques ou si une situation se présente dans laquelle vous avez besoin de conseils professionnels ou médicaux, vous devriez consulter un spécialiste dûment formé et qualifié.",
    termessoustitretexte79:"Liens",
    termessoustitretexte80:"Le Service peut contenir, et le Service ou des tiers peuvent fournir, des publicités et des promotions proposées par des tiers et des liens vers d'autres sites Web ou ressources. Vous reconnaissez et acceptez que la Société n'est pas responsable de la disponibilité de ces sites Web ou ressources externes, et n'approuve pas et n'est pas responsable de tout contenu, information, déclaration, publicité, bien ou service, ou autre matériel sur ou disponible à partir de ces sites Web ou ressources. Votre correspondance ou vos transactions commerciales avec, ou votre participation à des promotions de tiers trouvés dans ou via le Service, y compris le paiement et la livraison de biens ou services connexes, et toutes autres conditions, garanties ou les représentations associées à ces transactions, sont uniquement entre vous et ce tiers. Vous reconnaissez et acceptez en outre que la Société ne sera pas responsable, directement ou indirectement, de tout dommage ou perte causé ou présumé avoir été causé par ou en relation avec l'utilisation ou la confiance accordée à ces contenus, informations, déclarations , la publicité, les biens ou services ou tout autre matériel disponible sur ou via un tel site Web ou une telle ressource.",
    termessoustitretexte81:"Limitation de responsabilité. DANS TOUTE LA MESURE AUTORISÉE PAR LA LOI APPLICABLE, EN AUCUN CAS LA SOCIÉTÉ, SES AFFILIÉS, PARTENAIRES COMMERCIAUX, CONCÉDANTS OU SERVICE LES FOURNISSEURS NE SERONT RESPONSABLES ENVERS VOUS OU TOUT TIERS DE TOUT DOMMAGE INDIRECT, DE CONFIANCE, CONSÉCUTIF, EXEMPLAIRE, ACCESSOIRE, SPÉCIAL OU PUNITIF, Y COMPRIS, SANS S'Y LIMITER, LA PERTE DE PROFITS, LA PERTE DE BONNE VOLONTÉ, LES DOMMAGES POUR PERTE, LA CORRUPTION OU LES VIOLATIONS DE DONNÉES OU DE PROGRAMMES , INTERRUPTIONS DE SERVICE ET OBTENTION DE REMPLACEMENT SERVICES, MÊME SI LA SOCIÉTÉ A ÉTÉ AVISÉE DE LA POSSIBILITÉ DE TELS DOMMAGES. NONOBSTANT TOUTE DISPOSITION CONTRAIRE CONTENUE DANS LE PRÉSENT DOCUMENT, LA SOCIÉTÉ LA RESPONSABILITÉ ENVERS VOUS POUR QUELQUE CAUSE QUE CE SOIT, ET QUELLE QUE SOIT LA FORME DE L'ACTION, SERA À TOUT MOMENT LIMITÉE AU MONTANT PAYÉ, LE CAS ÉCHÉANT, PAR VOUS A L'ENTREPRISE POUR LE SERVICE PENDANT QUE VOUS AVEZ UN COMPTE. VOUS ACCEPTEZ QUE, INDÉPENDAMMENT DE TOUT STATUT OU LOI AU CONTRAIRE, TOUTE RÉCLAMATION OU CAUSE D'ACTION RÉSULTANT DE OU LIÉE À L'UTILISATION DU SERVICE OU DES CONDITIONS DE CET ACCORD DOIT ÊTRE DÉPOSÉE DANS UN DÉLAI D'UN AN APRÈS QUE CETTE RÉCLAMATION OU CAUSE D'ACTION SOIT SURVENUE OU ÊTRE À TOUJOURS ANNULÉE.",
    termessoustitretexte82:"Le moyen exclusif de résoudre tout litige ou réclamation découlant de ou lié au présent Accord (y compris toute violation présumée de celui-ci) ou au Service sera l'ARBITRAGE EXÉCUTOIRE administré par l'American Arbitration Association. La seule exception à l'exclusivité de l'arbitrage est que vous avez le droit d'apporter une réclamation individuelle contre la Société devant un tribunal des petites créances de juridiction compétente. Mais que vous choisissiez l'arbitrage ou le tribunal des petites créances, vous ne pouvez en aucun cas intenter ou maintenir contre la Société un recours collectif, un arbitrage collectif ou toute autre action ou procédure représentative.",
    termessoustitretexte83:"En utilisant le Service de quelque manière que ce soit, vous acceptez la convention d'arbitrage ci-dessus. Ce faisant, VOUS RENONCEZ À VOTRE DROIT D'ALLER EN JUSTICE pour faire valoir ou défendre toute réclamation entre vous et la Société (à l'exception des affaires pouvant être portées devant le tribunal des petites créances). VOUS RENONCEZ ÉGALEMENT À VOTRE DROIT DE PARTICIPER À UN RECOURS COLLECTIF OU AUTRE RECOURS COLLECTIF. Vos droits seront déterminés par un ARBITRE NEUTRE, PAS UN JUGE OU UN JURY. Vous avez droit à une audience équitable devant l'arbitre. L'arbitre peut accorder toute réparation qu'un tribunal peut, mais vous devez noter que les procédures d'arbitrage sont généralement plus simples et plus rationalisées que les procès et autres procédures judiciaires. Les décisions de l'arbitre sont exécutoires devant les tribunaux et ne peuvent être annulées par un tribunal que pour des motifs très limités. Pour plus de détails sur le processus d'arbitrage, consultez nos Procédures d'arbitrage.",
    termessoustitretexte84:"Toute procédure visant à faire appliquer la présente convention d'arbitrage, y compris toute procédure visant à confirmer, modifier ou annuler une sentence arbitrale, peut être engagée devant tout tribunal compétent.",
    termessoustitretexte85:"Remarquer",
    termessoustitretexte86:"La Société peut vous fournir des avis, y compris ceux concernant les modifications apportées au présent Accord, en utilisant tout moyen raisonnable maintenant connu ou développé ultérieurement, y compris par e-mail, courrier ordinaire, SMS, MMS, message texte ou affichage dans le Service. Ces avis peuvent ne pas être reçus si vous violez le présent accord en accédant au service de manière non autorisée. Vous convenez que vous êtes réputé avoir reçu toutes les notifications qui auraient été livrées si vous aviez accédé au Service de manière autorisée.",
    termessoustitretexte87:"Accord complet; Autre",
    termessoustitretexte88:"Le présent accord, avec la politique de confidentialité et toutes les directives ou règles spécifiques qui sont publiées séparément pour des services ou des offres particuliers dans le service, contient l'intégralité de l'accord entre vous et la société concernant l'utilisation du service. Si une disposition du présent accord est jugée invalide, le reste du présent accord restera en vigueur et de plein effet. L'incapacité de la Société à exercer ou à appliquer tout droit ou disposition du présent Contrat ne constitue pas une renonciation à ce droit ou à cette disposition. Vous êtes d'accord que votre compte en ligne n'est pas transférable et tous vos droits sur votre profil ou le contenu de votre compte prennent fin à votre décès. Aucune agence, partenariat, coentreprise ou emploi n'est créé à la suite du présent Accord et vous ne pouvez faire aucune déclaration ou lier la Société de quelque manière que ce soit.",
    termessoustitretexte89:"Amendement",
    termessoustitretexte90:"Le présent Accord est susceptible d'être modifié par la Société à tout moment.",

    //feedback
    feddbacktitle:"Commentaires",
    feedbackdear:"Chers utilisateurs",
    feedbacktexte1:"Bazali vous invite à le soutenir en lui faisant part de vos suggestions et propositions qui pourraient nous permettre d'améliorer votre expérience utilisateur et de développer cette application.",
    feedbacktexte2:"Votre évaluation",
    feedbacktexte3:"Sélectionnez votre évaluation",
    feedbacktexte4:"Pourquoi cette note",
    feedbacktexte5:"Soumettre",
    feedbacktexte6:"Page précédente",
    feedbacktexte7:"Page suivante",

    //principal
    principaltitle:"Sélectionnez votre langue",
    principaltexte1:"Veuillez sélectionner votre langue chaque fois que vous démarrez bazali.",
    principaltexte2:"Sélectionnez votre Pays",
    select_country_texte1:"Veuillez sélectionner votre pays en fonction de la langue sélectionnée et cliquez sur Page suivante.",
    principalbutton:"Confirmer",

    //starteruser
    demarrersubtitle:"Démarrez",
    demarrertexte1:"Sélectionnez votre action pour démarrer",
    demarrertexte2:"Acheteur",
    demarrertexte3:"Vendeur",

    //buyer principal
    buyertexte1:"Il est obligatoire d'avoir un compte acheteur avant de continuer. Procéder si vous en disposer sinon créer en un et utiliser vos détails pour continuer.",
    buyertexte2:"Accès Acheteur",
    buyertexte3:"Compte Acheteur",
    //crud buyer account
    buyertexte4:"Gestion Compte Acheteur",
    buyertexte5:"Créer, Consulter, Modifier et Supprimer gratuitement votre compte acheteur",
    buyertexte6:"Créer Compte",
    buyertexte7:"Consulter Compte",
    buyertexte8:"Modifier Compte",
    buyertexte9:"Supprimer Compte",
    //add/create buyer account
    buyertexte10:"Créer Compte Gratuit",
    buyertexte11:"Tous les champs avec un asterix (*) sont obligatoires.",
    buyertexte12:"Nom",
    buyertexte13:"Entrer votre nom",
    buyertexte14:"Prénoms",
    buyertexte15:"Entrer vos prénoms",
    buyertexte16:"Numéro Mobile",
    buyertexte17:"Sans préfixe international",
    buyertexte18:"Entrez votre e-mail",
    buyertexte19:"Entrez votre password",
    buyertexte20:"Confirmer Password",
    buyertexte21:"Confirmez votre password",
    buyertexte22:"Créer",
    //edit buyer account
    buyertexte23:"Modifer Compte",
    buyertexte24:"Modifier",
    //view buyer account
    buyertexte25:"Consulter Compte",
    buyertexte26:"Consulter",
    //delete buyer account
    buyertexte27:"Supprimer Compte",
    buyertexte28:"Supprimer",
    //connection buyer
    buyertexte29:"Connexion Acheteur",
    buyertexte30:"Connexion",
    //Acheteur
    buyertexte31:"Où acheter?",
    buyertexte32:"Vous avez les options acheter national ou international (pays CEDEAO). Effectuez votre choix.",
    buyertexte33:"Acheter National",
    buyertexte34:"Acheter International",
    //acheter national
    buyertexte35:"Quoi acheter?",
    buyertexte36:"Vous avez les options acheter dans votre pays de résidence voire, s'informer sur diverses tendances et une aide sur comment effectuer vos recherches.",
    buyertexte37:"Achats toutes Catégories",
    buyertexte38:"Acheter Localement",
    buyertexte39:"Acheter autres villes",
    buyertexte40:"Tendances populaires",
    buyertexte41:"Nouvelles tendances",
    buyertexte42:"Quoi de Neuf ? ",
    buyertexte43:"Besoin d'aides?",
    //acheter international
    buyertexte44:"Vous avez les options acheter dans tout pays de la CEDEAO voire, s'informer sur diverses tendances et une aide sur comment effectuer vos recherches.",
    buyertexte45:"Cherchez",
    //vendeur national et international
    sellertexte1:"Il est obligatoire d'avoir un compte vendeur avant de continuer. Le compte vendeur international par contre vous permet de vendre au sein des autes pays de la CEDEAO. Procéder si vous en disposer sinon créer en un et utiliser vos détails pour continuer.",
    sellertexte2:"Accès Vendeur",
    sellertexte3:"Compte Vendeur",
    sellertexte4:"Créer, Consulter, Modifier et Supprimer gratuitement votre compte vendeur.",
    sellertexte5:"Gestion Compte Vendeur",
    sellertexte6:"Sélectionner type compte",
    sellertexte7:"Compte National",
    sellertexte8:"Compte CEDEAO",
    sellertexte9:"Validez",
    sellertexte10:"Connexion Vendeur",
    sellertexte11:"Connexion",
    //vendeur
    sellertexte12:"Tableau de bord",
    sellertexte13:"Toutes vos activités comme vendeur se trouvent ici. Cliquez pour interagir.",
    sellertexte14:"Mes crédits unités",
    sellertexte15:"Ma vitrine",
    sellertexte16:"Mes messages",
    sellertexte17:"Pays",
    sellertexte18:"Confirmer",
    sellertexte19:"Êtes-vous sur de supprimer ce compte?",
    sellertexte20:"Type compte",
    //contactme
    your_name:"Entrez votre nom",
    //contactme
    contactme: "Contactez-nous",
    contactintro: "Avez-vous des questions, des commentaires ou des suggestions? Nous aimerions recevoir de vos nouvelles! N'hésitez pas à contacter le service client de Bazali en utilisant l'une des méthodes suivantes :",
    contact_1: "Appel direct : Vous pouvez appeler directement le numéro de téléphone du service client de Bazali.",
    contact_2: "Envoyer un e-mail : Si vous préférez communiquer par e-mail, veuillez envoyer un message.",
    contact_3: "Appel WhatsApp : Si vous utilisez WhatsApp, vous pouvez également passer un appel ou discuter sur ce numéro WhatsApp.",
    contactconclued_1: "Nous sommes toujours heureux d'avoir de nouvelles personnes et nous ferons de notre mieux pour vous répondre dès que possible.",
    contactconclued_2: "Au plaisir de vous entendre bientôt!",
    //
    buyer:"Acheteur",
    seller:"Vendeur",
    languageInitials:"fr",
    //privacy
    privacytitre:"politique de confidentialité",
    privacytexte2:"Chez Bazali, nous nous engageons à protéger la confidentialité et la sécurité de nos utilisateurs. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos informations personnelles lorsque vous utilisez notre plateforme. En utilisant Bazali, vous consentez aux pratiques décrites dans cette politique.",
    privacysoustitre1:"Informations que nous recueillons :",
    privacysoustitretexte1:"Informations personnelles : Lorsque vous créez un compte sur Bazali, nous pouvons collecter certaines informations personnelles telles que votre nom, votre adresse e-mail et vos coordonnées. Ces informations sont nécessaires pour faciliter la communication entre acheteurs et vendeurs.",
    privacysoustitretexte2:"Informations sur la transaction : lorsque vous effectuez un achat ou une vente sur Bazali, nous collectons les détails de la transaction, y compris les produits concernés et les informations de paiement. Cependant, nous ne stockons aucune information de carte de paiement. Toutes les transactions de paiement sont traitées en toute sécurité par nos partenaires de passerelle de paiement de confiance.",
    privacysoustitre2:"Comment nous utilisons vos informations :",
    privacysoustitretexte3:"Communication : Nous pouvons utiliser vos informations personnelles pour communiquer avec vous concernant votre compte, vos commandes et vos demandes.",
    privacysoustitretexte4:"Amélioration de l'expérience utilisateur : nous analysons le comportement et les préférences des utilisateurs pour améliorer l'expérience utilisateur sur Bazali. Cela comprend la personnalisation des recommandations de produits et l'affichage de contenu pertinent.",
    privacysoustitretexte5:"Prévention de la fraude : nous pouvons utiliser les informations collectées pour détecter et empêcher les activités frauduleuses ou l'accès non autorisé à notre plate-forme.",
    privacysoustitre3:"Partage d'informations :",
    privacysoustitretexte6:"Communication vendeur-acheteur : lorsque vous effectuez des transactions sur Bazali, certaines informations personnelles peuvent être partagées avec le vendeur ou l'acheteur concerné pour faciliter la transaction.",
    privacysoustitretexte7:"Fournisseurs de services tiers : nous pouvons partager vos informations avec des fournisseurs de services tiers de confiance qui nous aident à exploiter notre plate-forme, à traiter les paiements et à fournir une assistance à la clientèle.",
    privacysoustitre4:"Sécurité des données :",
    privacysoustitretexte8:"Nous utilisons des mesures de sécurité conformes aux normes de l'industrie pour protéger vos informations personnelles contre tout accès, divulgation ou modification non autorisés. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est sécurisée à 100 %.",
    privacysoustitre5:"Vos choix et droits :",
    privacysoustitretexte9:"Paramètres du compte : vous pouvez consulter et mettre à jour les informations de votre compte via la page des paramètres du compte sur Bazali.",
    privacysoustitretexte10:"Communications marketing : Vous avez la possibilité de refuser de recevoir des communications marketing de Bazali.",
    privacysoustitre6:"Conservation des données :",
    privacysoustitretexte11:"Nous conservons vos informations personnelles aussi longtemps que nécessaire pour atteindre les objectifs décrits dans la présente politique de confidentialité, à moins qu'une période de conservation plus longue ne soit requise ou autorisée par la loi.",
    //startingbuyer
    buyer_section_1:"Bienvenue Acheteur !",
    buyer_section_2:"Veuillez choisir l'une des options suivantes :",
    buyersoustitre1:"Non-inscrit :",
    buyersoustitretexte1:"Si vous n'êtes pas un utilisateur inscrit, vous pouvez explorer notre plateforme et parcourir les produits et services disponibles. Cependant, veuillez noter que certaines fonctionnalités peuvent être limitées pour les non-inscrits.",
    buyersoustitre2:"Inscrit",
    buyersoustitretexte2:"Si vous êtes déjà inscrit, cliquez sur ce bouton pour accéder à toutes les fonctionnalités et avantages premium disponibles exclusivement pour nos utilisateurs enregistrés. Profitez d'une expérience transparente avec des fonctionnalités améliorées.",
    buyersoustitre3:"S'inscrire",
    buyersoustitretexte3:"Si vous êtes nouveau ici, cliquez sur ce bouton pour créer un nouveau compte gratuitement. En créant un compte, vous pouvez débloquer des fonctionnalités supplémentaires, personnaliser votre expérience et gérer facilement vos préférences.",
    buyersoustitretexte4:"N'hésitez pas à cliquer sur l'un des boutons ci-dessus pour continuer. Si vous souhaitez revenir à la page précédente, cliquez sur le bouton <<Page Précédente>>.",
    noSubscriberUser:"Non-inscrit",
    subscriberUser:"Inscrit",
    createNewAccount:"S'inscrire",
    //startingseller
    seller_section_1:"Bienvenue Vendeur!",
    seller_section_2:"Veuillez choisir l'une des options suivantes :",
    sellersoustitre1:"Connectez-vous :",
    sellersoustitretexte1:"Si vous avez déjà un compte, cliquez sur ce bouton pour vous connecter et accéder à votre tableau de bord vendeur. Vous pouvez gérer vos produits, suivre les ventes et interagir avec les acheteurs.",
    sellersoustitre2:"S'inscrire :",
    sellersoustitretexte2:"Si vous êtes nouveau sur notre plateforme, cliquez sur ce bouton pour créer un compte vendeur. En vous inscrivant, vous pouvez commencer à vendre vos produits ou services à notre large base d'acheteurs. Gagnez en visibilité, atteignez des acheteurs potentiels et développez votre activité.",
    sellersoustitre3:"S'abonner :",
    sellersoustitretexte3:"Cliquez sur ce bouton pour vous abonner à notre plan vendeur de base, standard et premium. En tant qu'abonné, vous débloquerez des fonctionnalités et des avantages supplémentaires conçus pour augmenter vos ventes et rationaliser votre expérience de vente.",
    sellersoustitretexte4:"N'hésitez pas à cliquer sur l'un des boutons ci-dessus pour continuer. Si vous souhaitez revenir à la page précédente, cliquez sur le bouton <<Page Précédente>>.",
    singinUser:"Connexion",
    signUpUser:"S'inscrire",
    subscribe:"S'abonner",
    search_placeholder:"N'écrivez que le nom du produit",
    filter_city:"Ville",
    filter_neighborhood:"Quartier",
    filter_best_prices:"Prix",
    play_ok:"OK",
    buyer_search_texte1:"Veuillez entrer le nom du produit que vous recherchez...",
    buyer_search_texte2:"Vous pouvez affiner votre recherche en appliquant des filtres si nécessaire.",
    buyer_search_texte3:"Cliquez sur <<🔍>> pour afficher la liste des produits que vous recherchez.",
    product_name:"Nom du produit",
    searchcity_placeholder:"Saisissez le nom de la ville",
    searchneighborhood_placeholder:"Entrez le nom du quartier",
    searchprice_placeholder:"Entrez votre meilleur prix préféré",
    confirm_button:"Confirmer",
    cancel_button:"Annuler",
    //authentication
    user_section_1:"Bienvenue, acheteurs enregistrés ! 😊",
    user_phone_texte:"Veuillez entrer votre numéro de téléphone portable (sans le préfixe international).",
    user_password_texte:"S'il vous plait entrez votre mot de passe.",
    user_section_2:"Une fois que vous avez rempli tous les champs obligatoires, cliquez sur le bouton <<Connectez-vous>>.",
    //
    signup_section_1:"Voici un petit guide sur la façon dont vous pouvez vous inscrire pour un nouveau compte dans le formulaire d'inscription Bazali :",
    signupsoustitre1:"Bienvenue sur Bazali Inscrivez-vous !",
    signupsoustitretexte1:"Merci d'avoir choisi Bazali ! Nous sommes ravis que vous rejoigniez notre plateforme. Suivez les étapes ci-dessous pour créer et gérer votre nouveau compte acheteur gratuit.",
    signupsoustitre2:"Informations personnelles :",
    signupsoustitretexte2:"Entrez votre nom et prénom(s) dans les champs fournis.",
    signupsoustitretexte3:"Entrez votre numéro de téléphone mobile WhatsApp valide sans le préfixe international. Nous utiliserons ce numéro pour vous authentifier et envoyer des notifications et des mises à jour importantes.",
    signupsoustitretexte4:"Indiquez votre ville. Ces informations nous aideront à adapter nos services à votre emplacement.",
    signupsoustitretexte5:"Choisissez un mot de passe fort pour sécuriser votre compte. Assurez-vous qu'il répond aux exigences spécifiées en matière de longueur et de complexité, qu'il soit capable et facile à retenir.",
    signupsoustitre3:"Termes et conditions :",
    signupsoustitretexte6:"Lisez les conditions générales et la politique de confidentialité. Il est essentiel de comprendre nos politiques et comment vos données seront utilisées.",
    signupsoustitre4:"Terminez l'inscription :",
    signupsoustitretexte7:"Vérifiez l'exactitude de toutes les informations que vous avez saisies.",
    signupsoustitretexte8:"Une fois que vous êtes prêt, cliquez sur le bouton <<S'inscrire>> pour créer votre compte.",
    signupsoustitre5:"Vérification du compte :",
    signupsoustitretexte9:"Après votre inscription, vous recevrez un message Whatsapp sur votre téléphone avec votre code d'utilisateur secret. Copiez-le et revenez à bazali et entrez ce code une fois pour vérifier votre code et activer votre compte.",
    signupsoustitre6:"Félicitations ! Vous êtes maintenant un utilisateur de Bazali.",
    signupsoustitretexte10:"Une fois votre compte vérifié, vous pouvez vous connecter en utilisant votre numéro de téléphone et votre mot de passe.",
    signupsoustitretexte11:"Explorez notre plateforme, découvrez des produits et profitez des avantages d'être un utilisateur Bazali.",
    signup_section_2:"Si vous avez des questions ou rencontrez des problèmes lors du processus d'inscription, n'hésitez pas à contacter notre équipe d'assistance pour obtenir de l'aide.",
    signup_section_3:"Remarque : Assurez-vous de fournir des informations exactes et à jour lors du processus d'inscription pour garantir une expérience fluide et personnalisée sur la plateforme Bazali.",
    create:"Créer",
    edit:"Modifier",
    delete:"Supprimer",
    view:"Aperçu",
    //
    create_account:"Créer un compte",
    edit_account:"Modifier un Compte",
    delete_account:"Supprimer un Compte",
    view_account:"Visualiser un Compte",
    first_name:"Nom",
    firstname_placeholder:"Entrer le nom",
    last_name:"Prénom(s)",
    lastname_placeholder:"Entrez votre prénom(s)",
    send_code:"Envoyer Code",
    code_user:"Code utilisateur",
    codeuser_placeholder:"Insérer code utilisateur",
    //
    continuer:"Continuer après vérification",
    please_number:"Remplissez tous les champs avant de continuer.",
    whatsapp_authentication:"Votre numéro Whatsapp est valide. Maintenant, revenez à Bazali et cliquez sur <<Continuer après l'authentification>>.",
    signup_error:"Le code utilisateur inséré n'est pas correct. Veuillez vérifier votre message Whatsapp pour le code d'utilisateur correct ou répétez votre processus d'inscription.",
    buyer_exists:"Vous recevez cet avertissement car vous ne pouvez pas utiliser le même numéro de téléphone pour créer deux comptes.",
    buyer_success:"Vous avez créé avec succès votre compte gratuit. N'oubliez pas d'utiliser votre numéro de téléphone et votre mot de passe pour vous connecter à chaque fois.",
    error_login:"Vos identifiants de connexion ne sont pas corrects. Vérifiez et réessayez. Si ce n'est pas le cas, créez votre compte utilisateur gratuit.",
    //
    cat_all:"Tout",
    cat_food:"Aliments",
    cat_beverage:"Boissons",
    cat_apparel:"Vêtements",
    cat_health:"Santé",
    cat_beauty:"Beauté",
    cat_services:"Prestations",
    cat_electronic:"Électronique",
    cat_automotive:"Automobile",
    cat_agriculture:"Agriculture",
    cat_breeding:"Élevage",
    cat_house:"Maisons",
    cat_sport:"Sport",
    cat_decor:"Décor",
    //
    subscribe_section_2:"Bienvenue dans la section Acheteur enregistré !",
    subscribe_section_3:"Pour rechercher un produit, suivez ces étapes simples :",
    subscribesoustitre1:"Sélectionnez la catégorie appropriée :",
    subscribe_search_texte1:"Utilisez la barre d'outils déroulante pour naviguer dans les différentes catégories. Choisissez la catégorie qui correspond le mieux au produit souhaité.",
    subscribesoustitre2:"Explorez les catégories :",
    subscribe_search_texte2:"Chaque catégorie représente un groupe spécifique de produits. Parcourez les options disponibles pour trouver ce que vous cherchez.",
    subscribesoustitre3:"Vous ne pouvez pas définir la catégorie ?",
    subscribe_search_texte3:"Utilisez le bouton <<Tous>> : Si vous n'êtes pas sûr de la catégorie spécifique, cliquez sur le bouton <<Tous>>. Il vous redirigera vers la barre de recherche globale.",
    subscribesoustitre4:"Saisissez le nom du produit :",
    subscribe_search_texte4:"Dans la barre de recherche globale, entrez le nom du produit qui vous intéresse. Appuyez sur Entrée ou cliquez sur l'icône de recherche pour lancer la recherche.",
    //Dashboard
    dash_titre:"Gérer votre présence",
    dash_section_1:"Gérer votre présence sur Bazali vous permet d'atteindre un public plus large et de présenter vos produits à des acheteurs potentiels de différents pays. Grâce à l'interface conviviale de Bazali, vous pouvez facilement créer et personnaliser votre boutique en ligne, la rendant attrayante et unique. En tirant parti des fonctionnalités marketing et promotionnelles de Bazali, vous pouvez augmenter la visibilité de votre marque et augmenter vos ventes. De plus, Bazali offre un support client fiable et des options d'abonnement sécurisées, vous garantissant une expérience de visibilité fluide et fiable.",
    dashsoustitre1:"Administration",
    dashsoustitretexte1:"Dans le panneau d'administration du vendeur Bazali, vous avez accès à un ensemble complet d'outils et de fonctionnalités pour gérer efficacement votre boutique en ligne et la visibilité de vos produits.",
    dashsoustitre2:"Tableau de bord",
    dashsoustitretexte2:"Dans le tableau de bord Bazali, vous pouvez facilement gérer vos produits, mettre à jour l'inventaire et suivre les performances des ventes. vous pouvez également surveiller les interactions avec les clients, répondre aux demandes de renseignements et traiter efficacement les commandes. Le tableau de bord fournit des informations précieuses grâce à des outils d'analyse et de reporting, vous aidant à prendre des décisions basées sur les données pour développer avec succès votre présence commerciale en ligne. De plus, vous pouvez accéder à des fonctionnalités marketing et à des outils promotionnels pour améliorer la visibilité de votre marque et attirer des acheteurs potentiels.",
    dashsoustitre3:"Plan",
    dashsoustitretexte3:"Avec les services de plan payant de Bazali, vous pouvez débloquer une gamme de fonctionnalités de base, standard et premium, telles que le support client prioritaire, des analyses avancées et des outils marketing améliorés. Les abonnés peuvent profiter d'un espace de stockage étendu et d'un accès à des modèles exclusifs pour créer de superbes pages de produits. De plus, le plan payant vous permet d'intégrer des applications tierces et des passerelles de paiement pour des transactions transparentes et sécurisées. De plus, vous pouvez bénéficier d'une liste prioritaire dans les résultats de recherche, ce qui donne à vos produits une visibilité accrue et un potentiel de ventes plus élevées. Une fois votre période d'essai expirée, vous pouvez continuer à profiter de ces fonctionnalités et services en vous abonnant à l'un de nos plans payants abordables adaptés aux besoins de votre entreprise.",
    dashsoustitre4:"Produits",
    dashsoustitretexte4:"Dans les produits de Bazali, vous pouvez créer et gérer facilement votre boutique en ligne, ajouter, mettre à jour, supprimer, afficher et présenter les détails des produits de manière visuellement attrayante. Vous avez la possibilité d'organiser les produits en catégories et d'appliquer divers filtres pour aider les acheteurs à trouver rapidement ce dont ils ont besoin. Vous pouvez également définir des prix, proposer des remises et gérer les options d'expédition pour offrir une expérience d'achat fluide à vos acheteurs. Avec les produits de Bazali, vous pouvez atteindre le public de la CEDEAO, étendre la portée de votre marque et augmenter les ventes grâce à une plateforme conviviale et riche en fonctionnalités.",
    dashsoustitre5:"Recharges",
    dashsoustitretexte5:"Dans la fonction de recharge de Bazali, vous pouvez facilement ajouter des fonds à votre compte, ce qui vous permet de payer des abonnements, d'accéder à des fonctionnalités premium et d'augmenter la visibilité de votre magasin. La recharge fournit un processus de paiement transparent et sécurisé, vous permettant de gérer vos finances de manière pratique au sein de la plateforme. Vous pouvez surveiller le solde de votre compte et l'historique des transactions pour suivre vos dépenses et garantir une expérience fluide et ininterrompue sur Bazali. Avec la recharge, vous pouvez profiter pleinement des services payants de Bazali et améliorer votre présence en ligne pour atteindre un public plus large et générer plus de ventes.",
    dashsoustitre6:"Configurer",
    dashsoustitretexte6:"Dans les paramètres de Bazali, vous pouvez personnaliser votre compte en mettant à jour les informations de profil, telles que le nom, les coordonnées et la description du magasin. Vous pouvez également configurer les préférences de notification pour rester informé des mises à jour des commandes, des messages et des annonces de la plateforme. La section des paramètres vous permet également d'ajuster les paramètres de confidentialité et de connecter des comptes de médias sociaux, permettant une expérience personnalisée et sécurisée sur Bazali.",
    dashsoustitre7:"Messages",
    dashsoustitretexte7:"Dans les chats de Bazali, vous pouvez communiquer directement avec les acheteurs ou d'autres vendeurs pour discuter des détails du produit, négocier les prix et répondre à toute demande de renseignements. La fonction de chat facilite les interactions en temps réel, favorisant des transactions plus fluides et renforçant la confiance entre les parties. Vous pouvez également accéder à l'historique des discussions précédentes pour vous référer aux discussions importantes et maintenir une communication transparente tout au long du processus d'achat et de vente. De plus, le système de chat garantit une plate-forme sécurisée et privée pour toutes les conversations, améliorant votre expérience et facilitant les transactions réussies sur Bazali.",
    dash_section_2:"En conclusion, la gestion de votre présence sur Bazali vous permet d'étendre votre portée et de vous connecter avec un public diversifié d'acheteurs potentiels de la CEDEAO. Avec des outils conviviaux et des options de personnalisation, vous pouvez créer une boutique en ligne attrayante et unique. Tirer parti des fonctionnalités marketing de Bazali permet d'accroître la visibilité de la marque et de stimuler les ventes.",
    dash_section_3:"De plus, notre support client fiable et nos options d'abonnement sécurisées garantissent une expérience transparente et digne de confiance, vous permettant de prospérer dans le commerce électronique de la CEDEAO.",
    //left side
    dash_admin:"Admin",
    dash_board:"À bord",
    dash_plan:"Plan",
    dash_product:"Produits",
    dash_order:"Recharges",
    dash_settings:"Configurer",
    dash_chat:"Messages", 
    //
    dash_manage_product:"Gestion des Produits",
    dash_new_product:"Nouveau Produit",
    dash_discount_product:"Soldes/Promotions",
    dash_edit_product:"Modifier Produit",
    dash_delete_product:"Supprimer Produit",
    dash_view_product:"Voir Produit",
    product_image:"Télécharger Image",
    upload_image:"Sélectionnez Image",
    //
    //product_name:"Nom Produit",
    productname_placeholder:"Entrez nom produit",
    product_description:"Description",
    product_price:"Prix",
    currency_placeholder:"Devise",
    save_product:"Sauvegarder", 
    //admin panel
    dash_admin_panel:"Panneau Administration",
    dash_product_magement:"Gestion Produits",
    dash_order_processing:"Commande en Cours",
    dash_inventory_control:"Contrôle de Inventaire",
    dash_marketing_promotion:"Marketing & Promotions",
    dash_customer_management:"Gestion Clientèle",
    dash_analytic_reports:"Analyses & Rapports",
    dash_customer_support:"Service Client",
    //dashboard
    dash_dashboard:"Tableau de bord",
    dash_update_inventory:"Mise à jour Inventaire",
    dash_sales_performance:"Performance Ventes",
    dash_customer_interaction:"Interactions avec Clients",
    dash_customer_inquiries:"Requêtes Clients",
    //plan
    dash_basic_plan:"Plan Basic",
    dash_standard_plan:"Plan Standard",
    dash_premium_plan:"Plan Premium",
    dash_special_event:"Événement Spécial",
    dash_third_party:"Application Tierce",
    //top-up
    dash_basic_topup:"Recharge Basique",
    dash_standard_topup:"Recharge Standard",
    dash_premium_topup:"Recharge Premium",
    dash_special_topup:"Recharge Spéciale",
    dash_third_topup:"Recharge Tierce",
    //settings
    dash_create_profile:"Créer Profil",
    dash_edit_profile:"Modifier Profil",
    dash_delete_profile:"Supprimer Profil",
    dash_view_profile:"Voir Profil",
    //chats
    dash_buyer_chats:"Messagerie Acheteur",
    dash_seller_chats:"Messagerie Vendeur",
    //category
    dash_category:"Catégorie",
    dash_new_category:"Nouvelle catégorie",
    dash_discount_category:"Ventes/Promotions",
    dash_edit_category:"Modifier Catégorie",
    dash_delete_category:"Supprimer Catégorie",
    dash_view_category:"Afficher Catégorie",
    //
    category_name:"Nom Catégorie",
    categoryname_placeholder:"Entrez Nom Catégorie",
    category_description:"Description",
    category_price:"Prix",
    //profile
    profile_country:"Pays",
    profile_ville:"Ville",
    profile_village:"Village",
    place_village_option:"Nom du village (facultatif)",
    profile_quartier:"Quartier",
    place_quartier_option:"Quartier (facultatif)",
    profile_sector:"Secteur d'activité",
    profile_sector_select:"Sélectionnez le secteur d'activité",
    profile_principal:"Nom de l'activité",
    place_principal:"Entrez le nom de l'activité",
    profile_location:"Emplacement",
    place_location:"Expliquez aux acheteurs où ils pourraient vous trouver",
    profile_mode:"Mode de vente",
    select_profile_mode:"Sélectionnez le mode de vente",
    profile_description:"Description",
    place_description:"Décrivez votre activité",
    profile_contact:"Comment être contacté ?",
    profile_mode_contact:"Sélectionnez le mode de contact",
    profile_private:"Privé",
    profile_public:"Publique",
    //
    profile_cash:"Espèces",
    profile_online:"En ligne",
    profile_correspondance:"Correspondance",
    profile_order:"Commande",
    profile_commission:"Commission",
    profile_affiliate:"Affiliation",
    characters_remaining:"caractères restants",
    Exceeded_character:"Limite de caractères dépassée",
    profile_success:"Vous avez enregistré votre profil avec succès",
    profile_exists:"Votre profil existe déjà. Vous ne pouvez que le modifier.",
    //warning
    text_warning:"Êtes-vous sûr de vouloir supprimer votre profil ? Cette action ne peut pas être annulée.",
    ok_delete:"Supprimer",
    no_cancel:"Annuler",
    //produit
    product_exists:"Vous avez déjà créé votre produit principal. Vous pouvez le modifier si nécessaire",
    image_uplaod:"Image téléchargée avec succès",
    image_failed:"Échec du téléchargement de l'image",
    product_success:"Votre produit principal enregistré avec succès",
    previous_image:"Image précédente",
    replace_image:"Remplacer l'image",
    image:"Image",
    edit_product_success:"Votre produit principal a été mis à jour avec succès",
    text_product_warning:"Êtes-vous sûr de vouloir supprimer votre produit principal ? Cette action le désactivera et l'activera si vous en avez besoin.",
    text_view_product_warning:"Voulez-vous activer votre produit principal ? Cliquez ensuite sur Activer pour le faire.",
    ok_enable:"Activer",
    //discount product
    error_product:"Avant de procéder à la création de produits pour les soldes ou les promotions, il est essentiel de configurer votre produit principal.",
    dash_new_discount:"Créer soldes/promo",
    dash_edit_discount:"Modifier soldes/promo",
    dash_delete_discount:"Supprimer soldes/promo",
    dash_view_discount:"Voir soldes/promo",
    //
    text_discount_product_warning:"Veuillez noter que les soldes et les promotions sont actives pour une durée de deux semaines. De plus, des frais d'abonnement nominaux sont requis pour garantir que vos offres sont en ligne et accessibles aux acheteurs potentiels.",
    ok_discount:"Confirmer",
    //
    the_product_description:"Décrivez votre produit",
    product_Previous_price:"Prix précédent",
    discount_rate:"Taux de remise %",
    calculate:"Calculer",
    new_price:"Nouveau prix",
    rate_placeholder:"Seul numéro",
    date_start:"Date de début",
    date_end:"Date de fin",
    new_price_placeholder:"Nouveau prix",
    limit_product_discount:"Pendant votre période d'essai, vous pouvez créer et publier un seul produit en solde ou promotionnel.",
    error_date:"Veuillez sélectionner la date après :",
    error_end_date:"Veuillez sélectionner une autre date de début car hors plage",
    discount_success:"Votre produit de vente ou de promotion enregistré avec succès",
    //Category
    text_category_product_warning:"Vos catégories de produits fonctionnent comme des sous-produits de votre produit principal. Avec nos plans d'essai, de base, standard et premium, vous recevez des catégories complémentaires valables jusqu'au même jour que votre produit principal. Pour un accès élargi à des catégories supplémentaires, disponibles pour les acheteurs potentiels, des frais d'abonnement nominaux sont requis.",
    category_success:"Votre catégorie de produit enregistrée avec succès",
    limit_category:"Vous avez droit à une catégorie de produits en plan d'essai.",
    edit_category_success:"Votre catégorie de produit modifiée avec succès",
    text_category_warning:"Etes-vous sûr de vouloir supprimer votre catégorie de produit ? Cette action le supprimera définitivement ainsi que toutes les données appartenant à cette catégorie.",
    error_data:"Votre requête n'est pas disponible.",
    //
    product:"Produit",
    action:"Action",
    //
    code_pin_texte:"Veuillez saisir le code PIN de votre forfait sans espace et confirmer",
    code_pin:"Code PIN",
    codepin_placeholder:"Votre code PIN",
    open:"Ouvrir",
    //subscibe seller
    error_connection:"Veuillez vous connecter avant d'accéder à la session d'abonnement.",
    //
    text_subscribe_warning:"Il semble que vous n'ayez pas créé votre compte d'abonné gratuit de 28 jours. Voulez-vous le faire maintenant ?",
    ok_create:"Oui",
    not_now:"Plutard",
    //subscription expired
    text_expired_warning:"Votre abonnement est expiré. Veuillez contacter votre revendeur le plus proche pour recharger et renouveler votre plan d'abonnement afin d'augmenter votre visibilité et vos ventes.",
    //
    start_subscribe:"Activer",
    user_code:"Code Vendeur",
    plan_pincode:"Plan Abonnement",
    type_plan:"Abonnement Essai",
    success_subscribe:"Votre abonnement d'essai gratuit a été créé avec succès.",
    //
    text_notexpired_warning:"Votre abonnement n'est pas expiré. Attendez le lendemain de la date d'expiration pour renouveler votre abonnement. Date d'expiration: ",
    //
    error_codepin:"Ce Pin Code renseigné n'est pas valide, réessayez avec le bon qui vous a été envoyé après votre recharge.",
    //
    success_subscription:"Votre abonnement a été renouvelé avec succès. Il se terminera le jour :",
    //
    subscribe_event:"Activer l'événement",
    type_plan_event:"Événement spécial",
    //
    dash_private_seller_chats:"Discussion Privée",
    dash_seller_group_chats:"Discussion Groupe",
    //chat messages
    dash_meetings:"Réunions",
    chat_admin:"Direction Générale",
    chat_country:"Représentant Pays",
    chat_city:"Directeur Ville",
    chat_reseller:"Agent Revendeur",
    //
    chat_group_admin:"Groupe Admin",
    chat_group_country:"Groupe Pays",
    chat_group_city:"Groupe Ville",
    chat_group_reseller:"Groupe Revendeurs",
    //
    private_seller_chat:"En cliquant sur Accéder, vous ouvrez votre compte WhatsApp et vous pouvez afficher tous les messages de vos acheteurs, discuter avec eux ou passer des appels pour discuter de leurs demandes afin de mieux vendre et satisfaire leurs besoins.",
    meeting_info:"Toutes les réunions sont fixées à un jour et une heure précis. Vérifiez dans Notification pour voir quand cela aura lieu. Toutes les réunions seront actives 30 minutes avant le début. Cliquez sur le type de réunion relatif pour rejoindre la salle de réunion si vous êtes invité et confirmé.",
    meeting_info_group:"Toutes les réunions sont fixées à un jour et une heure précis. Vérifiez dans Notification pour voir quand cela aura lieu. Toutes les réunions seront actives 30 minutes avant le début. Cliquez sur le type de réunion relatif pour rejoindre la salle de réunion si vous êtes invité et confirmé.",
    open_meeting:"Accéder",
    close_meeting:"Fermer",
    //
    chat_Seller:"Vendeur",
    chat_group_seller:"Groupe Vendeur",
    //notifications
    title:"Titre",
    content_notify:"Contenu Notification",
    due_date:"Date Échéance",
    due_time:"Délai Heure",
    status_notify:"Statut Notification",
    status_offline:"Hors ligne",
    status_online:"En ligne",
    level_notify:"Niveau Notification",
    select_level:"Choisir Niveau",
    level_urgent:"Urgent",
    level_normal:"Normale",
    state_notify:"État Notification",
    select_state:"Sélectionnez État",
    state_waiting:"En attente",
    state_confirmed:"Confirmé",
    notifiy:"Notifier",
    activate:"Activer",
    //
    notify_success:"Cible notifiée avec succès",
    already_notified:"Cette cible est déjà notifiée",
    //
    dash_schedule:"Réunions",
    //
    schedule_activate:"Activer Réunions",
    schedule_edit:"Modifier Réunions",
    schedule_delete:"Supprimer Réunions",
    schedule_view:"Afficher Réunions",
    //
    error_meeting:"Il n'y a pas de réunion prévue pour aujourd'hui",
    success_delete:"Cette colonne a été supprimée avec succès.",
    success_pause:"Cette colonne a été désactivée avec succès.",
    //
    list_schedule:"Liste Réunions",
    //call
    whatsapp_call:"Appeler",
    //Buyers
    info_new_product:"Nouveaux vendeurs en ligne par catégories de produits",
    filter_search_texte1:"Filtrez votre recherche si nécessaire pour en tirer le meilleur parti ou cliquez simplement sur OK pour générer toutes les vues sur ce produit.",
    //
    error_detail:"Le vendeur n'a rien publié sur ce contenu.",
    localisation:"Localisation géographique",
    //autocomplete search errors
    error_suggestion:"Désolé, aucun résultat pour cette requête de recherche. S'il vous plait, vérifiez l'orthographe et essayez à nouveau. Si le mot que vous recherchez contient une apostrophe, veuillez l'écrire avec deux apostrophes. Par exemple, si vous recherchez N'gattakro, saisissez-le sous la forme N''gattakro dans le champ de recherche.",
    //
    please_connect:"S'il vous plait Connectez-vous d'abord.",
    //
    error_contact:"Le vendeur n'a pas autorisé les acheteurs à appeler et à discuter.",
    //
    close_page:"Voulez-vous fermer cette page?",
    yes:"Oui",
    no:"Non",
    //notifications
    notification_content:"Voici tous les événements et activités promotionnelles. Appuyez sur pour afficher ou ignorer pour continuer vos tâches.",
    event_text:"Événements",
    promotion_text:"Promotions",
    skip_text:"Sauter",
    //special events
    subscribe_special:"Activer Événements Spécial",
    dash_events_topup:"Recharge Événements",
    dash_new_events:"Créer Événement",
    dash_edit_events:"Modifier Événement",
    dash_delete_events:"Supprimer Événement",
    dash_view_events:"Voir Événement",
    //
    event_logo:"Logo Événement",
    upload_logo:"Télécharger Logo",
    event_name:"Titre Événement",
    eventname_placeholder:"Entrez le titre",
    event_description:"Décrire Événement",
    the_event_description:"Décrivez brièvement votre événement",
    event_video:"Vidéo Événement",
    upload_video:"Télécharger Vidéo",
    //
    unsupported_browser:"Désolé, votre navigateur ne prend pas en charge les vidéos intégrées.",
    //
    error_limit:"Erreur lors du chargement de la vidéo.",
    alert_duration:"La durée de la vidéo dépasse la limite autorisée. Veuillez choisir une vidéo d'une durée de 2 minutes ou moins.",
    //
    text_event_warning:"Cette action supprimera tout le contenu de votre annonce et ne sera pas disponible pour votre période couverte. Vous devez la réécrire pour être en ligne avant l'expiration de la date. Êtes-vous sûr de supprimer l'annonce ?",
    //
    create_event_warning:"Votre annonce n'existe pas. Voulez-vous la créer maintenant ?",
    //
    error_event:"Vous ne pouvez pas aller plus loin car vous n'avez pas configuré votre abonnement événement/annonce. S'il vous plaît, faites-le avant.",
    //
    application_text:"Logiciels",
    //
    text_by_country:"Voulez-vous faire une recherche pour un autre pays ?",
    //
    search_country:"Pays de recherche: ",
    //landing page slogan
    slogan_one:"Acheteurs et Vendeurs de la CEDEAO",
    slogan_two:"Interconnectez-vous et Prospérez Ensemble !",
    //offline warning
    offline_connection:"Oups, pas d'Internet. Essayez de vous reconnecter avant.",
    //error event promotion application
    error_events:"Aucun événement disponible pour le moment.",
    error_promotions:"Aucune promotion des vendeurs disponible pour le moment.",
    error_applications:"Aucun logiciel disponible pour le moment.",
    //
    product_text:"Ventes récentes",
}
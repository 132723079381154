import React from 'react';
//
import { MdOutlineViewSidebar } from "react-icons/md";
//import { ImProfile } from "react-icons/im";
//
import { 
    ImageProductWrapper,
    ImageWrapper,
    CardProductImage,
    CardTextImageProductWrapper,
    CardTextProductBody,
    //CardTextProductTitle,
    //CardButtonProductWrapper,
    //CardButton,
} from '../../ComponentStyles';

function ListEventCard({ url, onImageClick, title, text, currency, views }) {
    return (
        <div>
            <ImageProductWrapper>
                <ImageWrapper style={{ backgroundImage: `url(${url})` }} onClick={onImageClick}>
                    <CardTextImageProductWrapper>
                        <CardProductImage>
                            <img src={url} alt='preview' style={{ maxWidth: '50px', maxHeight: '100px', borderRadius: '50px' }} />
                        </CardProductImage>
                    </CardTextImageProductWrapper>
                </ImageWrapper>
                <CardTextProductBody>
                    <span style={{width:'275px', fontSize:'20px', fontWeight:'600', color:'#000000' }}>{title}</span>
                    <p style={{width:'250px', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', fontSize:'17px', color:'#000000'}}>{text} {currency}&nbsp;&nbsp; &nbsp;&nbsp;<MdOutlineViewSidebar /> {views}</p>
                </CardTextProductBody>
            </ImageProductWrapper>
        </div>
    )
}

export default ListEventCard
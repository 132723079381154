import styled, { keyframes } from "styled-components";

export const PrincipalScroller = styled.div`
    top:100px;
    position: relative;
    height: 480px;
    overflow-y: scroll;
`;

export const PrincipalTitle = styled.span`
   font-size: 20px;
   font-weight: 600;
   color: #3366ff;
   margin-left: 15px;
`;

export const PrincipalUnderTitle = styled.span`
    font-size: 12px;
    color: gray;
    margin-left: 15px;
`;

export const PrincipalIntro = styled.p`
    font-size: 15px;
    margin-left: 15px;
    text-align: center;
    color: #3366ff;
`;

export const PrincipalSubTitle = styled.span`
    font-size: 18px;
    font-weight: 600;
    display:flex;
    align-items:center;
    justify-content:center;
    color: #3366ff;
`;

export const TextLogin = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    color: #3366ff;
`;

export const LargeSelect = styled.select`
    width:310px;
    height:45px;
    border-radius:10px;
    font-size: 18px;
    padding: 8px 16px;
    border: none;
    border-bottom: 2px solid #3366ff;
    background-color: #ffffff;
    color: #3366ff;
    outline: none;
    cursor: pointer;
    transition: border-color 0.3s;

    @media screen and (max-width: 640px){
        width:210px;
    }

    @media screen and (max-width: 768px){
        width:240px;
    }
`;

export const LanguageLargeOption = styled.option``;

export const ButtonStart = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 250px;
    height: 50px;
    border-radius: 20px;
    border-bottom: 2px solid #3366ff;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: rgb(63, 119, 172);
    cursor: pointer;
`;

export const BackStart = styled.div`
    border: none;
    color: white;
    display: flex;
    flex-direction:row;
    width: 250px;
    height: 50px;
    border-radius: 20px;
    border-bottom: 2px solid #3366ff;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #8c8c8c;
    cursor: pointer;
`;

export const BazaliButton = styled.div`
    border-radius: 4px;
    padding: 8px;
    background-color: #63b4cf;
    color: white;
    border: 1px solid #ccc;
    border-bottom: 2px solid #3366ff;
    border-radius: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const HandlerContainerDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 640px){
        justify-content: space-around;
    }

    @media screen and (max-width: 768px){
        justify-content: space-around;
    }
`;

export const HandlerColumnContainerDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 640px){
        justify-content: space-around;
    }

    @media screen and (max-width: 768px){
        justify-content: space-around;
    }
`;

/**footer */
export const FooterContainer = styled.footer`
    background-color: lightblue;
    padding: 20px;
    color: #333;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FooterText = styled.p`
    color: #3366ff;
    font-size: 14px;
`;

export const FooterLink = styled.button`
    color: #3366ff;
    text-decoration: none;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

export const FooterUpperLinkDiv = styled.div`
    margin-right: 20px;

    @media screen and (max-width: 320px){
        margin-right: 15px;
    }

    @media screen and (max-width: 420px){
        margin-right: 15px;
    }

    @media screen and (max-width: 640px){
        margin-right: 15px;
    }

    @media screen and (min-width: 768px){
        margin-right: 15px;
    }
`;

export const FooterLinkDiv = styled.div`
    margin-right: 110px;

    @media screen and (max-width: 640px){
        margin-right: 15px;
    }

    @media screen and (max-width: 768px){
        margin-right: 15px;
    }
`;

export const TermeScroller = styled.div`
    top:100px;
    width: 100%;
    position: relative;
    maxi-height: 600px;
    overflow-y: scroll;

    @media screen and (max-width: 640px){
        width:350px;
    }

    @media screen and (max-width: 768px){
        width:350px;
    }
`;

export const SearchHeader = styled.div`
    top:150px;
    position: fixed;
    width:100%;
`;

export const DetailsHeader = styled.div`
    top:190px;
    position: fixed;
    width:100%;
`;

export const ContentScroller = styled.div`
    margin-top:100px;
    position:absolute;
    height:100px;
    overflow-y: scroll;
`;

export const FooterTitle = styled.span`
   font-size: 20px;
   font-weight: 600;
   color: rgb(63, 119, 172);
   margin-left: 15px;
`;

export const FooterUnderTitle = styled.span`
    font-size: 12px;
    color: gray;
    margin-left: 15px;
`;

export const FooterIntro = styled.p`
    font-size: 15px;
    margin-left: 15px;
    color: #3366ff;
`;

export const FooterSubTitle = styled.span`
    font-size: 22px;
    font-weight: 600;
    margin-left: 15px;
    color: #3366ff;
`;

export const TextArea = styled.textarea`
   width: 310px;
   height: 100px;
   border-radius: 10px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
`;

export const LargeInput = styled.input`
    width:300px;
    height:30px;
    border-radius:10px;
    border: none;
    border-bottom: 2px solid #3366ff;
    outline: none;
    font-size: 18px;
    color: #3366ff;

    @media screen and (max-width: 640px){
        width:290px;
    }

    @media screen and (max-width: 768px){
        width:290px;
    }
`;

export const PasswordInput = styled.input`
    width:270px;
    height:30px;
    border-radius:10px;
    font-size: 18px;
    border: none;
    border-bottom: 2px solid #3366ff;
    outline: none;
    color: #3366ff;

    @media screen and (max-width: 640px){
        width:260px;
    }

    @media screen and (max-width: 768px){
        width:260px;
    }
`;

export const SearchInput = styled.input`
    width:300px;
    height:30px;
    border-top-left-radius:15px;
    border-bottom-left-radius:15px;
    font-size: 17px;
    border: 2px solid #009973;
    background-color: #ffffff;
    color: #3366ff;
    &:focus{
        outline:none;
    }

    @media screen and (max-width: 640px){
        width:290px;
    }

    @media screen and (max-width: 768px){
        width:290px;
    }
`;

//implementation of @keyframes animation in styled components
// Define the keyframes animation
const animateItems = keyframes`
    0% {
        transform: rotateZ(-90deg);
    }
    100% {
        transform: rotateZ(0);
    }
`;
// Apply the animation to a styled component
export const AnimatedComponent = styled.div`
    animation: ${animateItems} 1s linear forwards;
    color:#3366ff;
`;

export const ContactLinkDiv = styled.div`
    width: 100%;
    display: flex;
    height: 50px;
    justify-content: space-around;
    align-items: center;
    padding: 30px 0;
    color: white;
`;

//faq styling
export const FaqContainer = styled.div`
    background-color: #f5f5f5;
    padding: 20px;
`;

export const FaqItem = styled.div`
    margin-bottom: 20px;
`;

export const FaqQuestion = styled.div`
    font-weight: bold;
    cursor: pointer;
    color: #3366ff;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

export const FaqAnswer = styled.div`
    display: none;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

export const FaqAnswerContent = styled.div`
    color: #555;
    /* Add your answer content styles here */
`;

export const FaqQuestionActive = styled(FaqQuestion)`
    background-color: #eee;
`;

export const FaqAnswerActive = styled(FaqAnswer)`
    display: block;
`;

//modal
export const ModalFilter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ConfirmButton = styled.div`
    border-radius: 10px;
    padding: 8px;
    width: 110px;
    background-color: #00b33c;
    color: white;
    border: 1px solid #ccc;
    border-bottom: 2px solid #3366ff;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const CancelButton = styled.div`
    border-radius: 10px;
    padding: 8px;
    background-color: #ff3300;
    color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    border-bottom: 2px solid #3366ff;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

//authentication
export const ModalUser = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
`;

export const ModalUserSpecial = styled.div`
    top:200px;
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
`;

export const ModalSign = styled.div`
    top:10px;
    position:relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
`;
export const LeftSideDiv = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    background-color: #0000ff;

    @media screen and (max-width: 640px){
        width: 35%;
        justify-content: space-around;
    }

    @media screen and (max-width: 768px){
        width: 35%;
        justify-content: space-around;
    }
`;

export const RightSideDiv = styled.div`
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    background-color: #0000ff;

    @media screen and (max-width: 640px){
        width: 65%;
        justify-content: space-around;
    }

    @media screen and (max-width: 768px){
        width: 65%;
        justify-content: space-around;
    }
`;

export const LeftSideButton = styled.div`
    border-radius: 4px;
    padding: 8px;
    background-color: #0000ff;
    color: white;
    border: 1px solid #ccc;
    border-radius: 0px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: white;
        color: black;
    }
`;

export const RightSideButton = styled.div`
    border-radius: 4px;
    padding: 0px;
    width: 190px;
    background-color: #0000ff;
    color: white;
    border: 1px solid #ccc;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

// sign up smaller fieds inputs
export const SmallTextLogin = styled.span`
    width: 65%;
    font-size: 1.1rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    color: #3366ff;

    @media screen and (max-width: 640px){
        margin-left: -50px;
    }

    @media screen and (max-width: 768px){
        margin-left: -50px;
    }
`;

// sign up smaller fieds inputs
export const SmallCodeTextLogin = styled.span`
    width: 65%;
    font-size: 0.9rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    color: #3366ff;

    @media screen and (max-width: 640px){
        margin-left: -30px;
    }

    @media screen and (max-width: 768px){
        margin-left: -30px;
    }
`;

export const SmallInput = styled.input`
    width:210px;
    height:30px;
    border-radius:5px;
    font-size: 18px;
    margin-left: 0px;
    color: #3366ff;

    @media screen and (max-width: 640px){
        width:190px;
    }

    @media screen and (max-width: 768px){
        width:190px;
    }
`;

export const SmallPriceInput = styled.input`
    width:130px;
    height:30px;
    border-radius:5px;
    font-size: 18px;
    margin-left: -40px;
    color: #3366ff;

    @media screen and (max-width: 640px){
        width:115px;
    }

    @media screen and (max-width: 768px){
        width:115px;
    }
`;

export const SmallCurrencyInput = styled.input`
    width:75px;
    height:30px;
    border-radius:5px;
    font-size: 18px;
    margin-left: 0px;
    color: #3366ff;

    @media screen and (max-width: 640px){
        width:65px;
    }

    @media screen and (max-width: 768px){
        width:65px;
    }
`;

export const SmallPasswordInput = styled.input`
    width:200px;
    height:30px;
    border-radius:5px;
    font-size: 18px;
    margin-left: -40px;
    color: #3366ff;

    @media screen and (max-width: 640px){
        width:180px;
    }

    @media screen and (max-width: 768px){
        width:180px;
    }
`;

export const SmallHandlerContainerDiv = styled.div`
    width: 65%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 640px){
        justify-content: space-between;
    }

    @media screen and (max-width: 768px){
        justify-content: space-between;
    }
`;

export const SmallHandlerColContainerDiv = styled.div`
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 640px){
        justify-content: space-between;
    }

    @media screen and (max-width: 768px){
        justify-content: space-between;
    }
`;

export const AccueilButton = styled.button`
    
    color: black;
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid green;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: #00b33c;
    color: white;
    cursor: pointer;
    :hover{
      background: transparent;
      color: #00a8ff;
      border-color: var(--color-white);
      transition: var(--transition);
    }
    .active{
      color: #00a8ff;
    }
`;

export const SmallTextArea = styled.textarea`
   width: 210px;
   height: 100px;
   border-radius: 5px;
   font-size: 18px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    @media screen and (max-width: 640px){
        width:190px;
    }

    @media screen and (max-width: 768px){
        width:190px;
    }
`;

export const SmallSaveButton = styled.div`
    border-radius: 10px;
    padding: 8px;
    width: 130px;
    background-color: #00b33c;
    color: white;
    border: 1px solid #ccc;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const SmallEditButton = styled.div`
    border-radius: 10px;
    padding: 8px;
    width: 130px;
    background-color: #0000ff;
    color: white;
    border: 1px solid #ccc;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const SmallDeleteButton = styled.div`
    border-radius: 10px;
    padding: 8px;
    width: 130px;
    background-color: #ff3300;
    color: white;
    border: 1px solid #ccc;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const SmallViewButton = styled.div`
    border-radius: 10px;
    padding: 8px;
    width: 130px;
    background-color: gray;
    color: white;
    border: 1px solid #ccc;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const SmallSelectBox = styled.select`
    width: 210px;
    height: 40px;
    font-size: 18px;
    padding: 8px 16px;
    border: none;
    border-bottom: 2px solid #3366ff;
    background-color: lightblue;
    color: #246175;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    transition: border-color 0.3s;

    @media screen and (max-width: 640px){
        width:190px;
    }

    @media screen and (max-width: 768px){
        width:190px;
    }
`;

export const SmallLanguageOption = styled.option``;

//warning modal
export const ModalBackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContainer = styled.div`
    background-color: #e6e6ff;
    padding: 20px;
    border-radius: 8px;
`;

export const ModalSearchContainer = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
`;

export const ModalText = styled.p`
    margin-bottom: 20px;
    font-size:18px;
`;

export const ModalButton = styled.button`
    background-color: ${props => (props.danger ? 'red' : '#009973')};
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    font-size:20px;
`;

export const SmallProductLeftInput = styled.input`
    width:130px;
    height:30px;
    border-radius:5px;
    font-size: 18px;
    margin-left: -40px;
    color: #3366ff;

    @media screen and (max-width: 640px){
        width:100px;
    }

    @media screen and (max-width: 768px){
        width:100px;
    }
`;

export const SmallProductRightInput = styled.input`
    width:130px;
    height:30px;
    border-radius:5px;
    font-size: 18px;
    margin-left: 0px;
    color: #3366ff;

    @media screen and (max-width: 640px){
        width:100px;
    }

    @media screen and (max-width: 768px){
        width:100px;
    }
`;

export const FormScrollerWidgetSm = styled.form`
    width: 100%;
    height: auto;
    overflow-y: scroll;
`;

export const TableWidgetSmTable = styled.table`
    width:200px;
    border-spacing:0px;
`;

export const TableThead = styled.thead`
    background-color: #0000ff;
    width:210px;
`;

export const TableTbody = styled.tbody`
    background-color: #9dd0e1;
`;

export const TableTd = styled.td`
    font-size: 18px;
`;

export const TableButton = styled.button`
    width: 110px;
    background-color: #0000ff;
    border-radius:5px;
    height: 30px;
    font-size: 18px;
    color: white;
    cursor: pointer;
`;

export const ThWidgetSmTh = styled.th`
    text-align: left;
    color: white;
    font-size: 18px;
`;

export const ModalRefundText = styled.input`
    margin-bottom: 20px;
    font-size:18px;
    font-weight: 600;
    color: #3366ff;
`;

export const ModalMultiContainer = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
`;

export const ModalConfirmButton = styled.button`
    background-color: green;
    color: white;
    padding: 8px;
    width: 130px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    font-size:20px;
    transition: border-color 0.3s;
`;

export const ModalPauseButton = styled.button`
    background-color: gray;
    color: white;
    padding: 8px;
    width: 130px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    font-size:20px;
    transition: border-color 0.3s;
`;

export const ModalCancelButton = styled.button`
    background-color: red;
    color: white;
    padding: 8px;
    width: 130px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    font-size:20px;
    transition: border-color 0.3s;
`;

//notify card styles
export const CardWrapper = styled.div`
    width: 220px;
    background: #000;
    text-align: center;
    border-radius: 10px;
`;

export const CardImage = styled.div`
    grid-area: image;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-size: cover;
`;

export const CardTextWrapper = styled.div`
    grid-area: text;
    margin: 25px;
`;

export const CardTextDate = styled.span`
    color: rgb(255, 7, 110);
    font-size: 15px;
`;

export const CardTextTitle = styled.h2`
    margin-top: 0px;
    font-size: 1.2rem;
    box-sizing: border-box;
    min-width: 0px;
    line-height: 1.2;
    margin: 0px;
    background: linear-gradient(
        110.78deg,
        rgb(118, 230, 80) -1.13%,
        rgb(249, 214, 73) 15.22%,
        rgb(240, 142, 53) 32.09%,
        rgb(236, 81, 87) 48.96%,
        rgb(255, 24, 189) 67.94%,
        rgb(26, 75, 255) 85.34%,
        rgb(98, 216, 249) 99.57%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`;

export const CardTextBody = styled.div`
    color: white;
    font-size: 15px;
    font-weight: 300;
    border: 5px solid;
    margin: auto;
    width: 80%;
    padding: 20px;
`;

export const CardStatWrapper = styled.div`
    grid-area: stats;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background: #5930e5;
`;

export const CardStats = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    padding: 10px;
`;

export const LinkText = styled.a`
    color: #fff;
    text-decoration: none;
`;

//product card styles
export const CardProductWrapper = styled.div`
    width: 340px;
    background: #000;
    height:200px;
    border-radius: 10px;
`;

//image card styles
export const ImageProductWrapper = styled.div`
    width: 100%;
    background: #ffffff;
    height:270px;
    border-radius: 10px;
    
    @media screen and (max-width: 640px){
        width: 300px;
    }

    @media screen and (max-width: 768px){
        width: 300px;
    }
`;

//image detail card styles
export const ImageDetalWrapper = styled.div`
    width: 340px;
    background: #ffffff;
    min-height:300px;
    border-radius: 10px;
    border: 2px solid #000;
`;

export const ImageWrapper = styled.div`
    width: 300px;
    background: url('');
    height:150px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
`;

export const CardTextProductWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    margin: 15px;
`;

export const CardTextImageProductWrapper = styled.div`
    display: flex;
    margin-left: 8px;
`;

export const CardProductImage = styled.div`
    grid-area: image;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-size: cover;
`;

export const CardTextProductTitle = styled.h2`
    margin-top: 0px;
    font-size: 1.2rem;
    box-sizing: border-box;
    min-width: 0px;
    line-height: 1.2;
    margin: 0px;
    background: linear-gradient(
        110.78deg,
        rgb(118, 230, 80) -1.13%,
        rgb(249, 214, 73) 15.22%,
        rgb(240, 142, 53) 32.09%,
        rgb(236, 81, 87) 48.96%,
        rgb(255, 24, 189) 67.94%,
        rgb(26, 75, 255) 85.34%,
        rgb(98, 216, 249) 99.57%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`;

export const CardTextProductBody = styled.div`
    color: white;
    font-size: 15px;
    margin: auto;
    width: 80%;
    padding: 20px;
`;

export const CardButtonProductWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    margin: 15px;
`;

export const CardButton = styled.button`
    border: none;
    color: white;
    display: flex;
    width: 100px;
    height: 30px;
    border-radius: 20px;
    border-bottom: 2px solid #3366ff;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background-color: rgb(63, 119, 172);
    cursor: pointer;
`;

//card style to describe*****
export const CardContainer = styled.div`
    width: 350px;
    background-color: #e6e6e6;
    height:auto;
    border-radius: 10px;
    border: 2px solid #000;
`;

export const CardImageProduct = styled.div`
    grid-area: image;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

export const CardButtonClose = styled.button`
    border: none;
    color: red;
    width: 50px;
    height: 50px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
`; 

export const CardBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const CardTitle = styled.div`
    margin-top: 0px;
    font-size: 1.4rem;
    box-sizing: border-box;
    min-width: 0px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0px;
    color: black;
`;

export const CardText = styled.div`
    color: black;
    font-size: 17px;
    font-weight: 600;
    margin: auto;
    width: 80%;
    padding: 20px;
`;

//card contact
export const ContactHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 15px;
    background-color: blue;
`;

export const ContactCardTitle = styled.div`
    margin-top: 0px;
    font-size: 1.4rem;
    box-sizing: border-box;
    min-width: 0px;
    font-weight: 600;
    line-height: 1.2;
    margin: 0px;
    color: white;
`;

export const CardButtonPhone = styled.button`
    border: none;
    color: green;
    width: 60px;
    height: 60px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
`; 

export const ContactCardText = styled.div`
    color: black;
    font-size: 20px;
    font-weight: 600;
    margin: auto;
    width: 80%;
    padding: 20px;
`;

//notification styles
export const ModalNotificationButton = styled.button`
    background-color: green;
    color: white;
    padding: 8px;
    width: 130px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    font-size:20px;
    transition: border-color 0.3s;
`;

//autocomplete one search bar
export const AutoCompleteSearchOne = styled.input`
    font-size: inherit;
    padding-left: 0.5rem;
    padding-right: 1rem;
    height: 2.2rem;
    width: 90%;
    font-size: 17px;
    overflow-wrap: break-word;
    border: 1px solid gray;
    position: absolute;
    border-radius: 50px;
    word-wrap: break-word;
    background-color: transparent;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    @media screen and (max-width: 640px){
        width:80%;
    }

    @media screen and (max-width: 768px){
        width:80%;
    }
`;

//autocomplete two search bar that cover autocomplete one
export const AutoCompleteSearchTwo = styled.input`
    font-size: inherit;
    padding-left: 0.5rem;
    padding-right: 1rem;
    height: 2.2rem;
    width: 90%;
    font-size: 17px;
    overflow-wrap: break-word;
    border: 1px solid gray;
    position: absolute;
    border-radius: 50px;
    word-wrap: break-word;
    background-color: transparent;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    z-index: -1;
    color: gray;
    cursor: none;

    @media screen and (max-width: 640px){
        width:80%;
    }

    @media screen and (max-width: 768px){
        width:80%;
    }
`;

export const SearchIcon = styled.div`
    background-color: #246175;
    margin-left: 93.7%;
    position: relative;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    @media screen and (max-width: 640px){
        margin-left: 89%;
    }

    @media screen and (max-width: 768px){
        margin-left: 88%;
    }
`;

export const MainContainer = styled.div`
    top:260px;
    position:fixed;
    max-width:1000px;
    max-height:300px;
    overflow-x: auto;
    overflow-y: auto;
    z-index: 999;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const MainContainerList = styled.div`
    top:260px;
    position:fixed;
    max-width:1000px;
    max-height:300px;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

//horizontal scrolling for card layout
//event scroller
export const EventScrollerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-height: 280px;
    background: white;

    overflow-x: auto;
    overflow-y: hidden;
    z-index: 999;
`;

export const DetailContainerMenu = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 280px;
    background: white;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const DetailContainerMenuPromotion = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 260px;
    background: white;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const DetailContainerMenuProfile = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: white;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const EventScrollerMenu = styled.div`
    bottom: 0;
    left: 0;
    z-index: 10;
    
    width: 100%;
    display: flex;
    align-items: center;
    max-height: 290px;
    background: white;
    color: white;
    

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

//video content layout styles
export const ContentScrollerDetails = styled.div`
    top:260px;
    width: 100%;
    position:fixed;
    height:300px;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const ContentScrollerDetailsPromotion = styled.div`
    top:260px;
    width: 100%;
    position:fixed;
    height:500px;
    overflow-y: auto;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const ContentScrollerDetailsProfile = styled.div`
    top:260px;
    width: 100%;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const HandlerVideoContainerDiv = styled.div`
    width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const HandlerOneContainerDiv = styled.div`
    width: 1000px;
    display: flex;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const CardDetailContainer = styled.div`
    margin-left: 15px;
    width: 100%;
    background-color: #ffffff;
    height:auto;
    border-radius: 10px;
    border: 0px solid #000;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const CardDetailProfileContainer = styled.div`
    margin-left: 15px;
    width: 100%;
    background-color: #ffffff;
    height:auto;
    border-radius: 10px;
    border: 0px solid #000;
`;

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 15px;
`;

export const CardDetailHeader = styled.div`
    display: flex;
    align-items: center;
    margin: 15px;
`;

export const CardVideoBody = styled.div`
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const CardVideoText = styled.div`
    color: black;
    font-size: 18px;
    font-weight: 600;
    margin: auto;
    width: auto;
    padding: 20px;
`;

export const CardTextVideoBody = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    color: black;
    width: 100%;
    height: 100px;
    max-width: 1000px;
    white-space: normal;
    overflow-y: auto;
    padding: 10px;
    margin: 0 auto;
    
    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const StyledParagraph = styled.p`
    width: 100%;
    font-size: 17px;
    margin: 5px 0;
    white-space: normal;
    word-wrap: break-word;

    @media screen and (max-width: 640px){
        width: 300px;
    }

    @media screen and (max-width: 768px){
        width: 300px;
    }
`;

export const VideoModalUser = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    box-sizing: content-box;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

export const VideoContainer = styled.video`
    width: 100%;
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    
    @media screen and (max-width: 640px){
        width: 350px;
        height: auto;
    }

    @media screen and (max-width: 768px){
        width: 350px;
        height: auto;
    }
`;

export const ContainerProfile = styled.div`
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 300px;
    box-sizing: content-box;

    @media screen and (max-width: 640px){
        width: 350px;
    }

    @media screen and (max-width: 768px){
        width: 350px;
    }
`;

//style small footer fro event, promotion and application
export const MainFooterContainer = styled.div`
    top:160px;
    position:fixed;
    height:auto;
    overflow-x: scroll;
`;

export const MainFooterDetailContainer = styled.div`
    top:160px;
    position:fixed;
    height:auto;
    overflow-x: scroll;
`;

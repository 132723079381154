import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../../translations/i18n";
//
import OpenButton from '../../../../reusablecomponents/buttons/OpenButton';
//
import { MdDelete } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal, frenchDateFormat } from '../../../../../helperfunctions/Helper';
import WarningModal from '../../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    ModalUser,
    FormScrollerWidgetSm,
    TableWidgetSmTable,
    TableThead,
    ThWidgetSmTh,
    TableTbody,
    TableTd,
    TableButton,
    SmallTextLogin,
    SmallInput,
    SmallTextArea,
    SmallPriceInput,
    SmallCurrencyInput,
    SmallHandlerContainerDiv,
    SmallDeleteButton,
} from '../../../../ComponentStyles';

function DeleteDiscount() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertdeletediscount";
    const elementDropId = "backdropoutalertdeletediscount";
    const buttonHandler = "ouiHandlerAlertDeleteDiscountBtn";

    // Define state variables for modals
    const [startDeleteDiscountModalOpen, setStartDeleteDiscountModalOpen] = useState(true);
    const [deleteDiscountTableModalOpen, setDeleteDiscountTableModalOpen] = useState(false);
    const [deleteDiscountModalOpen, setDeleteDiscountModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [userDeleteDiscount, setUserDeleteDiscount] = useState([0]);
    const [deleteDetailDiscount, setDeleteDetailDiscount] = useState([0]);

    const idDeleteDiscountRef = useRef(null);

    const deleteDiscountRef = useRef(null);


    const handleStartDiscount = async () => {
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        //var checkResultat = [];

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const codeproduitDiv = document.getElementById('codeproduit').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table7;
        }

        await Axios.post(`${apiUrl}/edit/discount/table`, {
            tableNameSeller:tableName,
            codeUtilisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
            codeProduct:codeproduitDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                
                setUserDeleteDiscount(response1.data);
                setStartDeleteDiscountModalOpen(false);
                setDeleteDiscountTableModalOpen(true);
                setDeleteDiscountModalOpen(false);
                setShowModal(false);
                deleteDiscountRef.current.style.display = 'none';
            }
        });
    }

    const handleDeleteDiscount = async (discountId) => {
        var tableName = "";
        var signupTable = [];
        var resultat2 = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table7;
        }

        var idDiscount = discountId;

        //use to retrieve data before deleting
        await Axios.post(`${apiUrl}/edit/discount/detail`, {
            tableNameSeller:tableName,
            idDiscount:idDiscount,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                
                setDeleteDetailDiscount(response2.data);
                setStartDeleteDiscountModalOpen(false);
                setDeleteDiscountTableModalOpen(false);
                setDeleteDiscountModalOpen(true);
                setShowModal(false);
                deleteDiscountRef.current.style.display = 'block';
            }
        });
    }

    const handleDeleteDiscountProduct = async (e) => {
        e.preventDefault();

        setShowModal(true);
    }

    const handleModalDeleteDiscount = async (e) => {
        e.preventDefault();

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const idDeleteProduct = idDeleteDiscountRef.current.value;
        const statusProduct = 'pause';
        //
        var tableName = "";
        var signupTable = [];
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table7;
        }

        await Axios.post(`${apiUrl}/delete/seller/discount`, {
            tableNameSeller:tableName,
            statusProduct:statusProduct,
            deleteId:idDeleteProduct,
        }).then(() => {
            setStartDeleteDiscountModalOpen(true);
            setDeleteDiscountTableModalOpen(false);
            setDeleteDiscountModalOpen(false);
            setShowModal(false);
            deleteDiscountRef.current.style.display = 'none';
        });
    }

    const handleCancelDiscount = async (e) => {
        e.preventDefault();

        setStartDeleteDiscountModalOpen(true);
        setDeleteDiscountTableModalOpen(false);
        setDeleteDiscountModalOpen(false);
        setShowModal(false);
        deleteDiscountRef.current.style.display = 'none';
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_delete_discount")}</FooterSubTitle>
                <br/>
                {startDeleteDiscountModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartDiscount}
                        />
                    </div>
                )}

                {deleteDiscountTableModalOpen && (
                    <div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <TableWidgetSmTable>
                                <TableThead>
                                    <tr>
                                        <ThWidgetSmTh>{t("product")}</ThWidgetSmTh>
                                        <ThWidgetSmTh>{t("action")}</ThWidgetSmTh>
                                    </tr>
                                </TableThead>
                                <TableTbody>
                                    {userDeleteDiscount.map((valedit, index) => {
                                        return(
                                            <tr key={index}>
                                                <TableTd>{valedit.name_product}</TableTd>
                                                <TableTd ><TableButton type="button" onClick={() => handleDeleteDiscount(valedit.id_discount)}>{t("delete")}</TableButton></TableTd>
                                            </tr>
                                        )
                                    })}
                                </TableTbody>
                            </TableWidgetSmTable>
                        </FormScrollerWidgetSm>
                    </div>
                )}

                {deleteDiscountModalOpen && (
                    <div>
                        {deleteDetailDiscount.map((valdeletedetail, indexdeletedetail) => (
                            <ModalUser key={indexdeletedetail}>
                                <input style={{display:'none'}} ref={idDeleteDiscountRef} defaultValue={valdeletedetail.id_discount} readOnly />
                                <br/>
                                <SmallTextLogin>{t("product_name")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.name_product} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        defaultValue={valdeletedetail.description_product}
                                        readOnly
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_Previous_price")}</SmallTextLogin>
                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallPriceInput type='number' defaultValue={valdeletedetail.price_product} readOnly />
                                    </div>
                                    <div>
                                        <SmallCurrencyInput type='text' defaultValue={valdeletedetail.currency_product} readOnly />
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("discount_rate")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='number' defaultValue={valdeletedetail.percent_discount} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("new_price")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput 
                                        type='number' 
                                        defaultValue={valdeletedetail.new_price}
                                        readOnly
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("date_start")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={frenchDateFormat(valdeletedetail.date_start)} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("date_end")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput 
                                        type='text' 
                                        defaultValue={frenchDateFormat(valdeletedetail.date_end)}
                                        readOnly
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_image")}</SmallTextLogin>
                                <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                    <img src={valdeletedetail.image_discount} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                </div>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/>
                <div ref={deleteDiscountRef} style={{display:'none'}}>
                    <SmallHandlerContainerDiv>
                        <div>
                            <SmallDeleteButton onClick={handleDeleteDiscountProduct}>
                                <span style={{ fontSize:'30px'}}><MdDelete /></span>
                                {t("delete")}
                            </SmallDeleteButton>
                        </div>
                    </SmallHandlerContainerDiv>
                    {showModal && (
                        <WarningModal
                            text={t("text_product_warning")}
                            textOk={t("ok_delete")}
                            textNo={t("no_cancel")}
                            onConfirm={handleModalDeleteDiscount}
                            onCancel={handleCancelDiscount}
                        />
                    )}
                </div>
                <br/><br/>
                <div id="modaloutalertdeletediscount"></div>
                <div id="backdropoutalertdeletediscount"></div>
            </ModalUser>
        </div>
    )
}

export default DeleteDiscount
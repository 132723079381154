import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { MdOutlineStart, MdOutlineModeStandby, MdWorkspacePremium, MdOutlineFolderSpecial, MdOutlineEventSeat } from 'react-icons/md';
import { GiPodiumThird } from 'react-icons/gi';
//
import BasicTopUp from './BasicTopUp';
import StandardTopUp from './StandardTopUp';
import PremiumTopUp from './PremiumTopUp';
import SpecialTopUp from './SpecialTopUp';
import EventsTopUp from './EventsTopUp';
import ThirdTopUp from './ThirdTopUp';

import { 
    FooterSubTitle,
    RightSideButton,
    ModalUser,
    SmallHandlerColContainerDiv,
} from '../../../ComponentStyles';

function CrudTopUp() {
    const {t} = useTranslation();

    //div ref
    const crudTopUpRef = useRef(null);

    // Define state variables for modals
    const [dashBasicTopUpModalOpen, setDashBasicToUpModalOpen] = useState(false);
    const [dashStandardTopUpModalOpen, setDashStandardToUpModalOpen] = useState(false);
    const [dashPremiumTopUpModalOpen, setDashPremiumToUpModalOpen] = useState(false);
    const [dashSpecialTopUpModalOpen, setDashSpecialToUpModalOpen] = useState(false);
    const [dashEventsTopUpModalOpen, setDashEventsToUpModalOpen] = useState(false);
    const [dashThirdTopUpModalOpen, setDashThirdToUpModalOpen] = useState(false);

    const handleCrudBasicTopUp = async (e) => {
        e.preventDefault();

        crudTopUpRef.current.style.display = 'none';
        setDashBasicToUpModalOpen(true);
        setDashStandardToUpModalOpen(false);
        setDashPremiumToUpModalOpen(false);
        setDashSpecialToUpModalOpen(false);
        setDashEventsToUpModalOpen(false);
        setDashThirdToUpModalOpen(false);
    }

    const handleCrudStandardTopUp = async (e) => {
        e.preventDefault();

        crudTopUpRef.current.style.display = 'none';
        setDashBasicToUpModalOpen(false);
        setDashStandardToUpModalOpen(true);
        setDashPremiumToUpModalOpen(false);
        setDashSpecialToUpModalOpen(false);
        setDashEventsToUpModalOpen(false);
        setDashThirdToUpModalOpen(false);
    }

    const handleCrudPremiumTopUp = async (e) => {
        e.preventDefault();

        crudTopUpRef.current.style.display = 'none';
        setDashBasicToUpModalOpen(false);
        setDashStandardToUpModalOpen(false);
        setDashPremiumToUpModalOpen(true);
        setDashSpecialToUpModalOpen(false);
        setDashEventsToUpModalOpen(false);
        setDashThirdToUpModalOpen(false);
    }

    const handleCrudSpecialTopUp = async (e) => {
        e.preventDefault();

        crudTopUpRef.current.style.display = 'none';
        setDashBasicToUpModalOpen(false);
        setDashStandardToUpModalOpen(false);
        setDashPremiumToUpModalOpen(false);
        setDashSpecialToUpModalOpen(true);
        setDashEventsToUpModalOpen(false);
        setDashThirdToUpModalOpen(false);
    }

    const handleCrudEventsTopUp = async (e) => {
        e.preventDefault();

        crudTopUpRef.current.style.display = 'none';
        setDashBasicToUpModalOpen(false);
        setDashStandardToUpModalOpen(false);
        setDashPremiumToUpModalOpen(false);
        setDashSpecialToUpModalOpen(false);
        setDashEventsToUpModalOpen(true);
        setDashThirdToUpModalOpen(false);
    }

    const handleCrudThirdTopUp = async (e) => {
        e.preventDefault();

        crudTopUpRef.current.style.display = 'none';
        setDashBasicToUpModalOpen(false);
        setDashStandardToUpModalOpen(false);
        setDashPremiumToUpModalOpen(false);
        setDashSpecialToUpModalOpen(false);
        setDashEventsToUpModalOpen(false);
        setDashThirdToUpModalOpen(true);
    }

    return (
        <div>
            <ModalUser ref={crudTopUpRef}>
                <FooterSubTitle>{t("dash_order")}</FooterSubTitle>
                <br/>
                <SmallHandlerColContainerDiv>
                    <div>
                        <RightSideButton onClick={handleCrudBasicTopUp}>
                            <span style={{fontSize:'30px'}}><MdOutlineStart /></span>
                            {t("dash_basic_topup")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudStandardTopUp}>
                            <span style={{fontSize:'30px'}}><MdOutlineModeStandby /></span>
                            {t("dash_standard_topup")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudPremiumTopUp}>
                            <span style={{fontSize:'30px'}}><MdWorkspacePremium /></span>
                            {t("dash_premium_topup")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudSpecialTopUp}>
                            <span style={{fontSize:'30px'}}><MdOutlineFolderSpecial /></span>
                            {t("dash_special_topup")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudEventsTopUp}>
                            <span style={{fontSize:'30px'}}><MdOutlineEventSeat /></span>
                            {t("dash_events_topup")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudThirdTopUp}>
                            <span style={{fontSize:'30px'}}><GiPodiumThird /></span>
                            {t("dash_third_topup")}
                        </RightSideButton>
                    </div>
                </SmallHandlerColContainerDiv>
            </ModalUser>
            {/**basic topup */}
            {dashBasicTopUpModalOpen && (
                <BasicTopUp />
            )}

            {/**standard topup */}
            {dashStandardTopUpModalOpen && (
                <StandardTopUp />
            )}

            {/**premium topup */}
            {dashPremiumTopUpModalOpen && (
                <PremiumTopUp />
            )}

            {/**special topup */}
            {dashSpecialTopUpModalOpen && (
                <SpecialTopUp />
            )}

            {/**events topup */}
            {dashEventsTopUpModalOpen && (
                <EventsTopUp />
            )}

            {/**third topup */}
            {dashThirdTopUpModalOpen && (
                <ThirdTopUp />
            )}
        </div>
    )
}

export default CrudTopUp
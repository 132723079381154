import React from 'react';
//
import { ButtonContainer, BigButtonCountry, CountryFlagImage, CountryModalName } from '../ReusableStyles';

function CountryButton({ url, countryName, onConfirm }) {
    return (
        <div>
            <ButtonContainer>
                <BigButtonCountry onClick={onConfirm}>
                    <CountryFlagImage>
                        <img src={url} alt='preview' style={{ maxWidth: '60%', maxHeight: '50px' }} />
                    </CountryFlagImage>
                    <CountryModalName>{countryName}</CountryModalName>
                </BigButtonCountry>
            </ButtonContainer>
        </div>
    )
}

export default CountryButton
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { BsChatSquareDots } from 'react-icons/bs';
//
import SellerChatPrivate from './SellerChatPrivate';
import SellerChats from './SellerChats';
import SellerChatGroup from './SellerChatGroup';

import { 
    FooterSubTitle,
    RightSideButton,
    ModalUser,
    SmallHandlerColContainerDiv,
} from '../../../ComponentStyles';

function CrudChats() {
    const {t} = useTranslation();

    //div ref
    const crudChatsRef = useRef(null);

    // Define state variables for modals
    const [dashSellerChatsPrivateModalOpen, setDashSellerChatsPrivateModalOpen] = useState(false);
    const [dashSellerChatsModalOpen, setDashSellerChatsModalOpen] = useState(false);
    const [dashSellerChatsGroupModalOpen, setDashSellerChatsGroupModalOpen] = useState(false);

    const handleCrudPrivateSellerChat = async (e) => {
        e.preventDefault();

        crudChatsRef.current.style.display = 'none';
        setDashSellerChatsPrivateModalOpen(true);
        setDashSellerChatsModalOpen(false);
        setDashSellerChatsGroupModalOpen(false);
    }

    const handleCrudSellerChat = async (e) => {
        e.preventDefault();

        crudChatsRef.current.style.display = 'none';
        setDashSellerChatsPrivateModalOpen(false);
        setDashSellerChatsModalOpen(true);
        setDashSellerChatsGroupModalOpen(false);
    }

    const handleCrudSellerChatGroup = async (e) => {
        e.preventDefault();

        crudChatsRef.current.style.display = 'none';
        setDashSellerChatsPrivateModalOpen(false);
        setDashSellerChatsModalOpen(false);
        setDashSellerChatsGroupModalOpen(true);
    }

    return (
        <div>
            <ModalUser ref={crudChatsRef}>
                <FooterSubTitle>{t("dash_chat")}</FooterSubTitle>
                <br/>
                <SmallHandlerColContainerDiv>
                    <div>
                        <RightSideButton onClick={handleCrudPrivateSellerChat}>
                            <span style={{fontSize:'30px'}}><BsChatSquareDots /></span>
                            {t("dash_private_seller_chats")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudSellerChat}>
                            <span style={{fontSize:'30px'}}><BsChatSquareDots /></span>
                            {t("dash_seller_chats")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudSellerChatGroup}>
                            <span style={{fontSize:'30px'}}><BsChatSquareDots /></span>
                            {t("dash_seller_group_chats")}
                        </RightSideButton>
                    </div>
                </SmallHandlerColContainerDiv>
            </ModalUser>

            {/**Create Private Seller Chat Room */}
            {dashSellerChatsPrivateModalOpen && (
                <SellerChatPrivate />
            )}

            {/**Create Seller Chat Room */}
            {dashSellerChatsModalOpen && (
                <SellerChats />
            )}

            {/**Create Seller Group Chat Room */}
            {dashSellerChatsGroupModalOpen && (
                <SellerChatGroup />
            )}
        </div>
    )
}

export default CrudChats
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { MdOutlineSave, MdModeEdit, MdDelete, MdPreview, MdOutlineDiscount } from 'react-icons/md';
//
import Product from './Product';
import DiscountProduct from './discountproduct/DiscountProduct';
import EditProduct from './EditProduct';
import DeleteProduct from './DeleteProduct';
import ViewProduct from './ViewProduct';

import { 
    FooterSubTitle,
    RightSideButton,
    ModalUser,
    SmallHandlerColContainerDiv,
} from '../../../ComponentStyles';

function CrudProduct() {
    const {t} = useTranslation();

    //div ref
    const crudProductRef = useRef(null);

    // Define state variables for modals
    const [dashboardProductModalOpen, setDashboardProductModalOpen] = useState(false);
    const [dashboardDiscountModalOpen, setDashboardDiscountModalOpen] = useState(false);
    const [dashboardEditProductModalOpen, setDashboardEditProductModalOpen] = useState(false);
    const [dashboardDeleteProductModalOpen, setDashboardDeleteProductModalOpen] = useState(false);
    const [dashboardViewProductModalOpen, setDashboardViewProductModalOpen] = useState(false);

    const handleCrudNewProduct = async (e) => {
        e.preventDefault();

        crudProductRef.current.style.display = 'none';
        setDashboardProductModalOpen(true);
        setDashboardDiscountModalOpen(false);
        setDashboardEditProductModalOpen(false);
        setDashboardDeleteProductModalOpen(false);
        setDashboardViewProductModalOpen(false);
    }

    const handleCrudDiscountProduct = async (e) => {
        e.preventDefault();

        crudProductRef.current.style.display = 'none';
        setDashboardProductModalOpen(false);
        setDashboardDiscountModalOpen(true);
        setDashboardEditProductModalOpen(false);
        setDashboardDeleteProductModalOpen(false);
        setDashboardViewProductModalOpen(false);
    }

    const handleCrudEditProduct = async (e) => {
        e.preventDefault();

        crudProductRef.current.style.display = 'none';
        setDashboardProductModalOpen(false);
        setDashboardDiscountModalOpen(false);
        setDashboardEditProductModalOpen(true);
        setDashboardDeleteProductModalOpen(false);
        setDashboardViewProductModalOpen(false);
    }

    const handleCrudDeleteProduct = async (e) => {
        e.preventDefault();

        crudProductRef.current.style.display = 'none';
        setDashboardProductModalOpen(false);
        setDashboardDiscountModalOpen(false);
        setDashboardEditProductModalOpen(false);
        setDashboardDeleteProductModalOpen(true);
        setDashboardViewProductModalOpen(false);
    }

    const handleCrudViewProduct = async (e) => {
        e.preventDefault();

        crudProductRef.current.style.display = 'none';
        setDashboardProductModalOpen(false);
        setDashboardDiscountModalOpen(false);
        setDashboardEditProductModalOpen(false);
        setDashboardDeleteProductModalOpen(false);
        setDashboardViewProductModalOpen(true);
    }

    return (
        <div>
            <ModalUser ref={crudProductRef}>
                <FooterSubTitle>{t("dash_manage_product")}</FooterSubTitle>
                <br/>
                <SmallHandlerColContainerDiv>
                    <div>
                        <RightSideButton onClick={handleCrudNewProduct}>
                            <span style={{fontSize:'30px'}}><MdOutlineSave /></span>
                            {t("dash_new_product")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudDiscountProduct}>
                            <span style={{fontSize:'30px'}}><MdOutlineDiscount /></span>
                            {t("dash_discount_product")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudEditProduct}>
                            <span style={{fontSize:'30px'}}><MdModeEdit /></span>
                            {t("dash_edit_product")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudDeleteProduct}>
                            <span style={{fontSize:'30px'}}><MdDelete /></span>
                            {t("dash_delete_product")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudViewProduct}>
                            <span style={{fontSize:'30px'}}><MdPreview /></span>
                            {t("dash_view_product")}
                        </RightSideButton>
                    </div>
                </SmallHandlerColContainerDiv>
            </ModalUser>
            {/**Add new product */}
            {dashboardProductModalOpen && (
                <Product />
            )}

            {/**Discount product */}
            {dashboardDiscountModalOpen && (
                <DiscountProduct />
            )}

            {/**Edit product */}
            {dashboardEditProductModalOpen && (
                <EditProduct />
            )}

            {/**Delete product */}
            {dashboardDeleteProductModalOpen && (
                <DeleteProduct />
            )}

            {/**View product */}
            {dashboardViewProductModalOpen && (
                <ViewProduct />
            )}
        </div>
    )
}

export default CrudProduct
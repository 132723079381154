import React from 'react';
//
import InnerPopUpBackButton from '../buttons/InnerPopUpBackButton';
import { MdPhoneEnabled, MdWhatsapp } from "react-icons/md";
//
import { 
    CardDetailContainer,
    VideoContainer,
    CardVideoText,
    HandlerVideoContainerDiv,
    CardButtonPhone,
    CardTextVideoBody,
    StyledParagraph,
} from '../../ComponentStyles';

function ContactCardVideo({ title, textDescription,  onButtonClickClose, onButtonClickPhoneCall, onButtonClickPhone, videoUrl, supportvideo }) {
    
    
    return (
        <div>
            <CardDetailContainer>
                <VideoContainer src={videoUrl} controls>
                    {supportvideo}
                </VideoContainer>
                <CardVideoText>{title}</CardVideoText>
                <HandlerVideoContainerDiv>
                    <div>
                        <InnerPopUpBackButton onClick={onButtonClickClose}></InnerPopUpBackButton>
                    </div>
                    <div>
                        <CardButtonPhone onClick={onButtonClickPhoneCall}><MdPhoneEnabled style={{fontSize:'50px'}} /></CardButtonPhone>
                    </div>
                    <div>
                        <CardButtonPhone onClick={onButtonClickPhone}><MdWhatsapp style={{fontSize:'50px'}} /></CardButtonPhone>
                    </div>
                </HandlerVideoContainerDiv>
                <CardTextVideoBody>
                    <StyledParagraph>{textDescription}</StyledParagraph>
                </CardTextVideoBody>
            </CardDetailContainer>
        </div>
    )
}

export default ContactCardVideo
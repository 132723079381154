import React from 'react';
import { MdPlayCircleOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import "../../../translations/i18n";
//
import { GoNextButtonSearch, GoNextText } from '../ReusableStyles';

function PlayButtonSearch({onClick}) {
    const {t} = useTranslation();

    const navigateNextSearch = useNavigate();

    const goNext = () => {
        if (onClick && typeof onClick === 'function') {
            onClick(); // Call the provided onClick function if available
        } else {
            navigateNextSearch("/"); // Default behavior: go back in history
        }
    };
    return (
        <GoNextButtonSearch onClick={goNext}>
            <GoNextText>{t("play_ok")}</GoNextText>
            <MdPlayCircleOutline style={{fontSize:'25px'}} />
        </GoNextButtonSearch>
    )
}

export default PlayButtonSearch
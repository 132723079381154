import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//
import logoImage from '../../bazali.jpg';
//
import FooterStarting from '../footer/FooterStarting';
//
import ListEventCard from '../reusablecomponents/cards/ListEventCard';
//
import ListApplicationCard from '../reusablecomponents/cards/ListApplicationCard';
//
import { AlertModal, HideFooter } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';

//
import { 
    TermeScroller, 
    ModalUser, 
    MainFooterContainer,
    MainFooterDetailContainer, 
    HandlerContainerDiv,
    EventScrollerContainer,
    EventScrollerMenu,
    ContentScrollerDetails,
    DetailContainerMenu,
    VideoModalUser,
} from '../ComponentStyles';

import apiUrl from '../../apiconfig/ApiConfig';
import Axios from 'axios';

function FooterApplication() {
    HideFooter();

    const {t} = useTranslation();

    const navigateFooterApplication = useNavigate();

    //alert
    const elementOutId = "modaloutalertfooterapplications";
    const elementDropId = "backdropoutalertfooterapplications";
    const buttonHandler = "ouiHandlerAlertFooterApplicationsBtn";

    const [productContent, setProductContent] = useState(false);
    const [productApplication, setProductApplication] = useState(false);
    const [fetchApplication, setFetchApplication] = useState([0]);

    const [newApplicationModalOpen, setNewApplicationModalOpen] = useState(false);
    const [userApplication, setUserApplication] = useState([0]);
    //get localstorage language value for application
    const storedLangue = localStorage.getItem('i18nextLng');

    //emplementation useEffect to retrieve all online application
    useEffect(() => {
        const fetchData = async () => {
            var tableName = "bazali_app";
            var resultatApp = '';
            const statusThird = "online";

            Axios.post(`${apiUrl}/third/topup/table`, {
                tableNameSeller:tableName,
                statusThird:statusThird,
            }).then((responseApp) => {
                resultatApp = responseApp.data.length;
                if(resultatApp === 0){
                    const pTextePays = t("error_applications");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    setFetchApplication(responseApp.data);
                    setProductContent(true);
                    setProductApplication(true);
                    setNewApplicationModalOpen(false);
                }
            });
        }
        fetchData();
    }, [t, elementOutId, elementDropId, buttonHandler])
    //
    const handleApplication = async (applicId) => {
        var tableNameApp = "bazali_app";

        const firstWhere = "id_app";
        const firstParameter = applicId;

        // Use Promise.all to execute multiple requests in parallel
        const [dataTable1] = await Promise.all([
            fetchDataFromTable(tableNameApp, firstWhere, firstParameter),
        ]);

        setUserApplication(dataTable1);
        setProductContent(false);
        setProductApplication(false);
        setNewApplicationModalOpen(true);
    }
    //
    const handlerCloseApplication = async () => {
        
        navigateFooterApplication(-1);
    }
    //
    const handlerDemoApplication = async () => {
        //void event handler that has been emplemented in ListApplicationCard
    }

    //Function to fetch data from the API for each table
    const fetchDataFromTable = async (tableName, firstWhere, firstParameter) => {
        try {
            const result = await Axios.post(`${apiUrl}/check/one/parameter/table`, {
                tableNameSeller:tableName,
                firstWhere:firstWhere,
                firstParameter:firstParameter,
            });
            return result.data;
        } catch (error) {
            const pTextePays = t("error_data");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            throw error;
        }
    }

    const goBackFooterApplicationHandler = async () => {

        navigateFooterApplication('/starting');
    }

    return (
        <div id='footerapplication'>
            <TermeScroller>
                {/**new application products */}
                {productContent && (
                    <MainFooterContainer>
                        {/**event scroller */}
                        {productApplication && (
                            <div>
                                <span style={{color:'#246175', fontSize:'20px', marginLeft:'10px'}}>{t("application_text")}</span>
                                <EventScrollerContainer>
                                    <EventScrollerMenu>
                                        {fetchApplication.map((valappli, indexappli) => (
                                            <ModalUser key={indexappli}>
                                                <ListEventCard 
                                                    url={logoImage}
                                                    onImageClick={() => handleApplication(valappli.id_app)}
                                                    title={valappli.name_app}
                                                    text={valappli[`description_${storedLangue}`]}
                                                />
                                            </ModalUser>
                                        ))}
                                    </EventScrollerMenu>
                                </EventScrollerContainer>
                            </div>
                        )}
                    </MainFooterContainer>
                )}

                {newApplicationModalOpen && (
                    <MainFooterDetailContainer>
                        <ContentScrollerDetails>
                            <DetailContainerMenu>
                                <div>
                                    {userApplication.map((valapplication, indexapplication) => (
                                        <VideoModalUser key={indexapplication}>
                                            <ListApplicationCard
                                                onButtonClickClose={() => handlerCloseApplication()}
                                                onButtonClickDemo={() => handlerDemoApplication()}
                                                url={logoImage}
                                                title={valapplication.name_app}
                                                textDescription={valapplication[`description_${storedLangue}`]}
                                                textLink={valapplication.link_app}
                                            />
                                        </VideoModalUser>
                                    ))}
                                </div>
                            </DetailContainerMenu>
                        </ContentScrollerDetails>
                    </MainFooterDetailContainer>
                )}

                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackFooterApplicationHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>

                <FooterStarting />
                <br/><br/>
                <div id="modaloutalertfooterapplications"></div>
                <div id="backdropoutalertfooterapplications"></div>
            </TermeScroller>
        </div>
    )
}

export default FooterApplication
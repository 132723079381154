import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//import './buyer.css';
import SearchByCountryModal from '../reusablecomponents/componentutils/SearchByCountryModal';
//
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//english country
import Gambia from '../countries/countryflag/gambia_flag.png';
import Ghana from '../countries/countryflag/ghana_flag.png';
import Liberia from '../countries/countryflag/liberia_flag.png';
import Nigeria from '../countries/countryflag/nigeria_flag.png';
import Sierraleone from '../countries/countryflag/sierraleone_flag.png';
//french country
import Benin from '../countries/countryflag/benin_flag.jpg';
import Burkina from '../countries/countryflag/burkina_flag.png';
import Guinee from '../countries/countryflag/guinee_flag.jpg';
import Ivoire from '../countries/countryflag/ivoire_flag.png';
import Mali from '../countries/countryflag/mali_flag.jpg';
import Niger from '../countries/countryflag/niger_flag.png';
import Senegal from '../countries/countryflag/senegal_flag.png';
import Togo from '../countries/countryflag/togo_flag.png';
//portuguese country
import Capvert from '../countries/countryflag/capvert_flag.png';
import GuineeBissau from '../countries/countryflag/guineebissau_flag.png';
//
import FooterStarting from '../footer/FooterStarting';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
//
import { BiShow } from 'react-icons/bi';
//
//import ForwardButton from '../reusablecomponents/buttons/ForwardButton';
//import PlayButton from '../reusablecomponents/buttons/PlayButton';
import { HideFooter, AlertModal, NomPays } from '../../helperfunctions/Helper';
//
import { retrieveTableQuery, retrieveCodeAndName } from '../../helperfunctions/utilityfunctions/CountryUtils';
import Axios from 'axios';
import apiUrl from '../../apiconfig/ApiConfig';

//
import { 
    PrincipalScroller,
    PrincipalSubTitle,
    TermeScroller, 
    FooterSubTitle,
    FooterIntro,
    LargeInput,
    PasswordInput,
    //SearchInput,
    //FaqContainer,
    TextLogin, 
    HandlerContainerDiv, 
    //BazaliButton,
    //Modal
    ModalUser,
    ConfirmButton,
    CancelButton,
} from '../ComponentStyles';

function UserLogin() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertuserlogin";
    const elementDropId = "backdropoutalertuserlogin";
    const buttonHandler = "ouiHandlerAlertuserloginBtn";

    const navigateUserLogin = useNavigate();

    const userPhoneRef = useRef(null);
    const userPasswordRef = useRef(null);

    const [userAuthentication, setUserAuthentication] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    //show modal
    const [showModal, setShowModal] = useState(false);
    //search country component state
    const [searchCountry, setSearchCountry] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleUserLogin = async (e) => {
        e.preventDefault();

        //clear usercode utilisateur
        var divClearCode = document.getElementById('codeutilisateur');
        while(divClearCode.hasChildNodes()) {
            divClearCode.removeChild(divClearCode.childNodes[0]);
        }
        //clear usernom utilisateur
        var divClearNom = document.getElementById('usernom');
        while(divClearNom.hasChildNodes()) {
            divClearNom.removeChild(divClearNom.childNodes[0]);
        }
        //clear typeutilisateurDiv utilisateur
        var divClearUtilisateur = document.getElementById('typeutilisateur');
        while(divClearUtilisateur.hasChildNodes()) {
            divClearUtilisateur.removeChild(divClearUtilisateur.childNodes[0]);
        }
        //clear category utilisateur
        var divClearCategory = document.getElementById('categoryutilisateur');
        while(divClearCategory.hasChildNodes()) {
            divClearCategory.removeChild(divClearCategory.childNodes[0]);
        }

        var tableName = "";
        var signupTable = [];
        var checkResultat = [];
        var resultat = '';
        var userName = "";
        var codeUser = "";
        var roleUser = "";
        var typeutilisateur = "";
        //
        const userPhone = userPhoneRef.current.value;
        const userPassword = userPasswordRef.current.value;
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const codeUtilisateur = document.getElementById('codeutilisateur').innerText;
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur');
        const usernomDiv = document.getElementById('usernom');
        const typeutilisateurDiv = document.getElementById('typeutilisateur');
        const categoryutilisateurDiv = document.getElementById('categoryutilisateur');
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table1;
        }
        //
        if(!codeUtilisateur){
            if(!userPhone){
                const pTextePays = t("please_number");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!userPassword){
                const pTextePays = t("please_number");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                await Axios.post(`${apiUrl}/buyer/connexion`, {
                    userPhone:userPhone,
                    userPassword:userPassword,
                    tableNameBuyer:tableName,
                }).then((response) => {
                    resultat = response.data.length;
                    checkResultat = response.data;
                    //console.log(checkResultat);
                    if(resultat === 0){
                        const pTextePays = t("error_login");
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                    }else{
                        for(let i=0; i<resultat; i++){
                            userName = checkResultat[i].nom;
                            codeUser = checkResultat[i].code_user;
                            roleUser = checkResultat[i].sigle;
                        }
                        codeutilisateurDiv.innerText = codeUser;
                        usernomDiv.innerText = userName;
                        typeutilisateurDiv.innerText = roleUser;
                        if(roleUser === 'buyer'){
                            typeutilisateur = t("buyer");
                        }
                        categoryutilisateurDiv.innerText = typeutilisateur;
                        //ask search preference
                        setShowModal(true);
                        setSearchCountry(false);
                        //navigateUserLogin('/subscribebuyer');
                    }
                });
            }
        }else{
            navigateUserLogin('/nosubscribebuyer');
        }
        
    }

    const handleUserClose = async (e) => {
        e.preventDefault();

        HideFooter();
        navigateUserLogin(-1);
    }

    const handleGoBack = async () => {
        HideFooter();
        navigateUserLogin(-1);
    }

    /*const goNextHandler = async () => {
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        if(!codeutilisateurDiv){
            const pTextePays = t("please_connect");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            HideFooter();
            navigateUserLogin(1);
        }
    }*/

    //search by country handler
    const searchByCountryHandler = async (e) => {
        e.preventDefault();

        setUserAuthentication(false);
        setShowModal(false);
        setSearchCountry(true);
    }
    //search country handler
    //Gambia
    const handleGambia = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "gambie";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //searchCountry.innerText = NomPays(englishCountry);
            //
            navigateUserLogin('/nosubscribebuyer');
        }
    }
    //Ghana
    const handleGhana = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "ghana";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
        }
    }
    //Liberia
    const handleLiberia = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "liberia";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
        }
    }
    //Nigeria
    const handleNigeria = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "nigeria";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
        }
    }
    //sierraleone
    const handleSierraleone = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "sierraleone";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(englishCountry);
        }
    }
    //benin
    const handleBenin = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "benin";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
        }
    }
    //burkina
    const handleBurkina = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "burkina";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
        }
    }
    //guinee-conakty
    const handleGuinee = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "guinee";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
        }
    }
    //ivoire
    const handleIvoire = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "ivoire";
        //const searchCountry = document.getElementById('searchcountry');
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
        }
    }
    //mali
    const handleMali = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "mali";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
        }
    }
    //niger
    const handleNiger = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "niger";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
        }
    }
    //senegal
    const handleSenegal = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "senegal";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
        }
    }
    //togo
    const handleTogo = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "togo";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(frenchCountry);
        }
    }
    //cap vert
    const handleCapvert = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const portugueseCountry = "capvert";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = portugueseCountry;
        countryInfo = retrieveCodeAndName(portugueseCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(portugueseCountry);
        }
    }
    //guinee bissau
    const handleGuineeBissau = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const portugueseCountry = "guineebissau";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = portugueseCountry;
        countryInfo = retrieveCodeAndName(portugueseCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            const searchCountry = document.getElementById('searchcountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //
            navigateUserLogin('/nosubscribebuyer');
            searchCountry.innerText = t("search_country") + NomPays(portugueseCountry);
        }
    }
    //
    const NoSearchByCountryHandler = async (e) => {
        e.preventDefault();
        
        navigateUserLogin('/nosubscribebuyer');
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const searchCountry = document.getElementById('searchcountry');
        searchCountry.innerText = t("search_country") + NomPays(codecountryDiv);
    }

    //create an account
    const hanldeCreateAccount = async () => {
        navigateUserLogin('/signupbuyer');
    }

    return (
        <div id='userlogin'>
            <TermeScroller>
                <br/>
                <div style={{textAlign:'center'}}>
                    <FooterSubTitle>{t("buyer")} {t("subscriberUser")}</FooterSubTitle>
                </div>
                <FooterIntro>{t("user_section_1")}</FooterIntro>
                {/*<ul style={{color:'#3366ff'}}>
                    <li>{t("user_phone_texte")}</li>
                    <li>{t("user_password_texte")}</li>
                </ul>
                <FooterIntro>{t("user_section_2")}</FooterIntro>*/}
                <br/>
                {userAuthentication && (
                    <ModalUser>
                        <br/>
                        <TextLogin>{t("buyertexte16")}</TextLogin>
                        <div style={{display:'block',textAlign:'center'}}>
                            <LargeInput type='number' ref={userPhoneRef} placeholder={t("buyertexte17")} />
                        </div>
                        <br/>
                        <TextLogin>Password</TextLogin>
                        <div style={{display:'flex', flexDirection:'row', marginLeft:'10px'}}>
                            <div style={{display:'block',textAlign:'center'}}>
                                <PasswordInput type={showPassword ? 'text' : 'password'} ref={userPasswordRef} placeholder={t("buyertexte19")} />
                            </div>
                            <div>
                                <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                    {showPassword ? 'Hide' : 'Show'}
                                </BiShow>
                            </div>
                        </div>
                        <br/>
                        <HandlerContainerDiv>
                            <div>
                                <ConfirmButton onClick={handleUserLogin}>{t("singinUser")}</ConfirmButton>
                            </div>
                            <div>
                                <CancelButton onClick={handleUserClose}>{t("cancel_button")}</CancelButton>
                            </div>
                        </HandlerContainerDiv>
                        <br/>
                        <p onClick={hanldeCreateAccount} style={{color:'blue', fontSize:'20px', fontFamily:'serif', cursor:'pointer'}}>{t("buyer")} {t("createNewAccount")}</p>
                    </ModalUser>
                )}
                
                {/**search country modal */}
                {showModal && (
                    <SearchByCountryModal
                        text={t("text_by_country")}
                        textOk={t("yes")}
                        onConfirm={searchByCountryHandler}
                        textNo={t("no")}
                        onCancel={NoSearchByCountryHandler}
                    />
                )}
                {/**search by Country */}
                {searchCountry && (
                    <PrincipalScroller>
                        <ModalUser>
                            <br/>
                            <PrincipalSubTitle>{t("principaltexte2")}</PrincipalSubTitle>
                            <br/>
                            <div style={{display:'block',textAlign:'center'}}>
                                <CountryButton 
                                    url={Gambia}
                                    countryName="Gambie"
                                    onConfirm={handleGambia}
                                />
                                <CountryButton 
                                    url={Ghana}
                                    countryName="Ghana"
                                    onConfirm={handleGhana}
                                />
                                <CountryButton 
                                    url={Liberia}
                                    countryName="Liberia"
                                    onConfirm={handleLiberia}
                                />
                                <CountryButton 
                                    url={Nigeria}
                                    countryName="Nigeria"
                                    onConfirm={handleNigeria}
                                />
                                <CountryButton 
                                    url={Sierraleone}
                                    countryName="Sierraleone"
                                    onConfirm={handleSierraleone}
                                />
                                <CountryButton 
                                    url={Benin}
                                    countryName="Bénin"
                                    onConfirm={handleBenin}
                                />
                                <CountryButton 
                                    url={Burkina}
                                    countryName="Burkina Faso"
                                    onConfirm={handleBurkina}
                                />
                                <CountryButton 
                                    url={Guinee}
                                    countryName="Guinée-Conakry"
                                    onConfirm={handleGuinee}
                                />
                                <CountryButton 
                                    url={Ivoire}
                                    countryName="Côte d'Ivoire"
                                    onConfirm={handleIvoire}
                                />
                                <CountryButton 
                                    url={Mali}
                                    countryName="Mali"
                                    onConfirm={handleMali}
                                />
                                <CountryButton 
                                    url={Niger}
                                    countryName="Niger"
                                    onConfirm={handleNiger}
                                />
                                <CountryButton 
                                    url={Senegal}
                                    countryName="Sénégal"
                                    onConfirm={handleSenegal}
                                />
                                <CountryButton 
                                    url={Togo}
                                    countryName="Togo"
                                    onConfirm={handleTogo}
                                />
                                <CountryButton 
                                    url={Capvert}
                                    countryName="Cap Vert"
                                    onConfirm={handleCapvert}
                                />
                                <CountryButton 
                                    url={GuineeBissau}
                                    countryName="Guinée Bissau"
                                    onConfirm={handleGuineeBissau}
                                />
                            </div>
                        </ModalUser>
                    </PrincipalScroller>
                )}
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={handleGoBack} ></PopUpBackButton>
                    </div>
                    {/*<div>
                        <ForwardButton onClick={goNextHandler}></ForwardButton>
                    </div>*/}
                </HandlerContainerDiv>

                <FooterStarting />
                <br/><br/>
                <div id="modaloutalertuserlogin"></div>
                <div id="backdropoutalertuserlogin"></div>
            </TermeScroller>
        </div>
    )
}

export default UserLogin
import React from 'react';
import { ModalBackDrop, ModalContainer, ModalText, ModalButton } from '../../ComponentStyles';

function SearchByCountryModal({ text, textOk, textNo, onConfirm, onCancel }) {
  return (
    <div>
        <ModalBackDrop>
            <ModalContainer>
                <ModalText>{text}</ModalText>
                <ModalButton onClick={onConfirm}>
                    {textOk}
                </ModalButton>
                <ModalButton onClick={onCancel}>{textNo}</ModalButton>
            </ModalContainer>
        </ModalBackDrop>
    </div>
  )
}

export default SearchByCountryModal
import React from 'react';
import { AiOutlineLike } from "react-icons/ai";

//
import { GoLikeButton } from '../ReusableStyles';

function LikeButton({confirm}) {
    return (
        <GoLikeButton onClick={confirm}>
            <AiOutlineLike />
        </GoLikeButton>
    )
}

export default LikeButton
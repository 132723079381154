import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import { MdOutlineSave } from 'react-icons/md';
import { BsUpload } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal, LimitCharacters, getCurrentDate } from '../../../../helperfunctions/Helper';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallPriceInput,
    SmallCurrencyInput,
    SmallHandlerContainerDiv,
    SmallSaveButton,
} from '../../../ComponentStyles';

function NewCategory() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertnewcategory";
    const elementDropId = "backdropoutalertnewcategory";
    const buttonHandler = "ouiHandlerAlertNewCategoryBtn";

    //div variables ref
    const handleConfirmNewCategoryRef = useRef(null);
    const createNewCategoryRef = useRef(null);

    const newCategoryNameRef = useRef(null);
    const newCategoryDescriptionRef = useRef(null);
    const newCategoryPriceRef = useRef(null);
    const newCategoryCurrencyRef = useRef(null);
    const newCategoryImageRef = useRef(null);

    const [imagePreview, setImagePreview] = useState(null);

    //image sources and resources states
    const [src, setSrc] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    //Limit the total number of characters (not words)
    const characterLimit = 500; // Set the character limit to 500
    const [textDescription, setTextDescription] = useState('');
    //limit characters for description
    const handleChangeDescription = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, characterLimit);
        setTextDescription(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = characterLimit - textDescription.length;

    const handleImageChange = async (e) => {

        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            //
            // Transform file into blob URL
            setSrc(URL.createObjectURL(file));
            //
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

        }
    }

    // Helper function to set the subdirectory value
    const setSubDirectory = (value) => {
        Axios.post(`${apiUrl}/set-subdirectory`, {
            tableProduct: value,
        }).then(() => {
            // Log success message or handle response
            const pTextePays = t("image_uplaod");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }).catch(() => {
            // Handle error
            const pTextePays = t("image_failed");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        });
    }

    const handleConfirmCategory = async (e) => {
        e.preventDefault();

        var tableProduct = "";
        var productTable = [];
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table6; //directory to save image in server side
        }

        // Set the subdirectory value on the server side
        setSubDirectory(tableProduct);

        createNewCategoryRef.current.style.display = 'block';
        handleConfirmNewCategoryRef.current.style.display = 'none';
    }

    const handleSaveNewCategory = async (e) =>{
        e.preventDefault();

        var tableProfile = "";
        var profileTable = [];
        var resultatProfile = 0;
        //
        var tableProduct = "";
        var productTable = [];
        //var resultatProduct = 0;
        //
        var tableName = "";
        var signupTable = [];
        var resultat1 = 0;

        var tableStatus = "";
        var signupStatus = [];
        var resultatStatus = 0;
        var checkStatus = [];
        var planSeller = "";

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table1 to use in backend
        profileTable = retrieveTableQuery(codecountryDiv);
        if(profileTable){
            tableProfile = profileTable.table.table3;
        }
        //
        productTable = retrieveTableQuery(codecountryDiv);
        if(productTable){
            tableProduct = productTable.table.table6; //directory to save image in server side
        }
        //
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table9;
        }
        //
        signupStatus = retrieveTableQuery(codecountryDiv);
        if(signupStatus){
            tableStatus = signupStatus.table.table4;
        }
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codeproduitDiv = document.getElementById('codeproduit').innerText;
        //
        const newCategoryName = newCategoryNameRef.current.value;
        const newCategoryDescription = newCategoryDescriptionRef.current.value;
        const newCategoryPrice = newCategoryPriceRef.current.value;
        const newCategoryCurrency = newCategoryCurrencyRef.current.value;
        const newCategoryImage = newCategoryImageRef.current.value;
        const categoryViews = "1";
        const newCategoryDate = getCurrentDate();
        //
        if(!codeutilisateurDiv){
            const pTextePays = t("connect_before");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!newCategoryName){
            const pTextePays = t("product_name");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!newCategoryDescription){
            const pTextePays = t("product_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!newCategoryPrice){
            const pTextePays = t("product_price");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);  
        }else if(!newCategoryCurrency){
            const pTextePays = t("currency_placeholder");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!newCategoryImage){
            const pTextePays = t("upload_image");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            //check if seller has already created his profile
            await Axios.post(`${apiUrl}/check/seller/profile`, {
                tableNameSeller:tableProfile,
                codeUtilisateur:codeutilisateurDiv,
            }).then((responseProfile) => {
                resultatProfile = responseProfile.data.length;
                if(resultatProfile === 0){
                    //if profile not already exists
                    const pTextePays = t("dash_create_profile");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    //check if product category already exists
                    Axios.post(`${apiUrl}/check/seller/category`, {
                        tableNameSeller:tableName,
                        codeUtilisateur:codeutilisateurDiv,
                    }).then((responsecategory) => {
                        resultat1 = responsecategory.data.length;
                        if(resultat1 === 0){
                            //upload image and inputs
                            const formData = new FormData();

                            //inputs
                            formData.append("codeutilisateur", codeutilisateurDiv);
                            formData.append("codecountry", codecountryDiv);
                            formData.append("codeproduct", codeproduitDiv);
                            formData.append("categoryname", newCategoryName);
                            formData.append("categorydescription", newCategoryDescription);
                            formData.append("categoryprice", newCategoryPrice);
                            formData.append("categorycurrency", newCategoryCurrency);
                            formData.append("categoryviews", categoryViews);
                            //file
                            formData.append("productfile", file);
                            formData.append("productfile", fileName);
                            //other input
                            formData.append("datecategory", newCategoryDate);
                            //save image in directory belonging
                            formData.append("tablename", tableName);
                            formData.append("thefile", tableProduct);
                            formData.append("apiurl", apiUrl);
                            //add new category
                            Axios.post(`${apiUrl}/create/category/product`, formData).then(() => {
                                const pTextePays = t("category_success");
                                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                            });
                        }else{
                            //check seller plan in product
                            Axios.post(`${apiUrl}/check/status/product`, {
                                tableNameSeller:tableStatus,
                                codeUtilisateur:codeutilisateurDiv,
                                codeProduct:codeproduitDiv,
                            }).then((responseProduit) => {
                                resultatStatus = responseProduit.data.length;
                                if(resultatStatus === 0){
                                    const pTextePays = t("error_product");
                                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                                }else{
                                    checkStatus = responseProduit.data;
                                    for(let i=0; i<resultatStatus; i++){
                                        planSeller = checkStatus[i].plan_seller;
                                    }
                                    //if product planSeller = 'trial' then only one product category to create
                                    if(planSeller === 'trial'){
                                        const pTextePays = t("limit_category");
                                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                                    }else{
                                        const pTextePays = "Based on type plan, check the number of product category allowed and check if one of them already exists to procees.To emplement after";
                                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                                    }
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_new_category")}</FooterSubTitle>
                <br/>
                <SmallTextLogin>{t("category_name")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <SmallInput type='text' ref={newCategoryNameRef} placeholder={t("categoryname_placeholder")} />
                </div>
                <br/>
                <SmallTextLogin>{t("category_description")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <SmallTextArea
                        type='text'
                        value={textDescription}
                        onChange={handleChangeDescription}
                        ref={newCategoryDescriptionRef}
                        placeholder={t('the_product_description')}
                        maxLength={characterLimit} // Set the maxlength attribute 
                    />
                    <div>
                        {remainingCharactersDescription >= 0
                        ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                    </div>
                </div>
                <br/>
                <SmallTextLogin>{t("category_price")}</SmallTextLogin>
                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                    <div style={{display:'block',textAlign:'center'}}>
                        <SmallPriceInput type='number' ref={newCategoryPriceRef}></SmallPriceInput>
                    </div>
                    <div>
                        <SmallCurrencyInput type='text' ref={newCategoryCurrencyRef} placeholder={t("currency_placeholder")} />
                    </div>
                </div>
                <br/>
                <SmallTextLogin>{t("upload_image")}</SmallTextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <label htmlFor='upload-btn' style={{ cursor: 'pointer', color:'#3366ff', fontSize:'18px' }}>
                        <BsUpload style={{ marginRight: '5px', fontSize:'30px' }} />
                        {t("upload_image")}
                    </label>
                    <SmallInput 
                        id="upload-btn"
                        type='file' 
                        ref={newCategoryImageRef} 
                        onChange={handleImageChange} 
                        accept="image/*" 
                        style={{ display: 'none' }}
                    />
                </div>
                {imagePreview && (
                    <div>
                        <div src={src} style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                            <img src={imagePreview} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                        </div>
                        <SmallHandlerContainerDiv ref={handleConfirmNewCategoryRef}>
                            <div>
                                <SmallSaveButton onClick={handleConfirmCategory}>
                                    <span style={{ fontSize:'30px'}}><GiConfirmed /></span>
                                    {t("sellertexte18")}
                                </SmallSaveButton>
                            </div>
                        </SmallHandlerContainerDiv>
                    </div>
                )}

                <br/>
                <div ref={createNewCategoryRef} style={{display:'none'}}>
                    <SmallHandlerContainerDiv>
                        <div>
                            <SmallSaveButton onClick={handleSaveNewCategory}>
                                <span style={{ fontSize:'30px'}}><MdOutlineSave /></span>
                                {t("save_product")}
                            </SmallSaveButton>
                        </div>
                    </SmallHandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalertnewcategory"></div>
                <div id="backdropoutalertnewcategory"></div>
            </ModalUser>
        </div>
    )
}

export default NewCategory
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//helperfunction
import { ShowFooter } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';

//
import { 
    TermeScroller, 
    FooterTitle, 
    FooterUnderTitle, 
    FooterIntro, 
    FooterSubTitle, 
    HandlerContainerDiv,
} from '../ComponentStyles';

function About() {
    const {t} = useTranslation();

    const navigateAbout = useNavigate();

    const goBackAboutHandler = async (e) => {
        ShowFooter();
        navigateAbout('/');
    }

    return (
        <div id='about'>
            <TermeScroller>
                <br/>
                <FooterTitle>{t("apropostitre")}</FooterTitle>
                <br/>
                <FooterUnderTitle>{t("aproposrevision")}</FooterUnderTitle>
                <br/><br/>
                <FooterIntro>
                    <strong>{t("aproposintro")}</strong>
                    <br/><br/>
                    {t("aproposintrosuite")}
                    <br/>
                    {t("aproposintroensuite")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>1. {t("soustitre1")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("soustitretexte1")}</li>
                    <li>{t("soustitretexte2")}</li>
                    <li>{t("soustitretexte3")}</li>
                    <li>{t("soustitretexte4")}</li>
                </ul>
                <br/>
                <FooterSubTitle>2. {t("soustitre2")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("soustitretexte5")}</li>
                    <li>{t("soustitretexte6")}</li>
                    <li>{t("soustitretexte7")}</li>
                    <li>{t("soustitretexte8")}</li>
                </ul>
                <br/>
                <FooterSubTitle>3. {t("soustitre3")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("soustitretexte9")}</li>
                    <li>{t("soustitretexte10")}</li>
                    <li>{t("soustitretexte11")}</li>
                    <li>{t("soustitretexte12")}</li>
                </ul>

                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackAboutHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
            </TermeScroller>
        </div>
    )
}

export default About
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//helperfunction
import { ShowFooter } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';

//
import { 
    TermeScroller, 
    FooterTitle, 
    FooterUnderTitle, 
    FooterIntro, 
    FooterSubTitle, 
    HandlerContainerDiv,
} from '../ComponentStyles';

function Privacy() {
    const {t} = useTranslation();

    const navigatePrivacy = useNavigate();

    const goBackPrivacyHandler = async (e) => {
        ShowFooter();
        navigatePrivacy('/');
    }

    return (
        <div id='privacy'>
            <TermeScroller>
                <br/>
                <FooterTitle>{t("privacytitre")}</FooterTitle>
                <br/>
                <FooterUnderTitle>{t("aproposrevision")}</FooterUnderTitle>
                <br/><br/>
                <FooterIntro>
                    {t("termesrevision")} <strong>Bazali</strong>, {t("termestexte1")} <a style={{color:'#3f77ac', textDecoration:'none', fontWeight:'600'}} href="https://www.micarions.carions.net/">Carion's.</a>
                    <br/><br/>
                    {t("privacytexte2")}
                </FooterIntro>
                <br/>
                <FooterSubTitle>1. {t("privacysoustitre1")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("privacysoustitretexte1")}</li>
                    <li>{t("privacysoustitretexte2")}</li>
                </ul>
                <br/>
                <FooterSubTitle>2. {t("privacysoustitre2")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("privacysoustitretexte3")}</li>
                    <li>{t("privacysoustitretexte4")}</li>
                    <li>{t("privacysoustitretexte5")}</li>
                </ul>
                <br/>
                <FooterSubTitle>3. {t("privacysoustitre3")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("privacysoustitretexte6")}</li>
                    <li>{t("privacysoustitretexte7")}</li>
                </ul>
                <br/>
                <FooterSubTitle>4. {t("privacysoustitre4")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("privacysoustitretexte8")}</li>
                </ul>
                <br/>
                <FooterSubTitle>5. {t("privacysoustitre5")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("privacysoustitretexte9")}</li>
                    <li>{t("privacysoustitretexte10")}</li>
                </ul>
                <br/>
                <FooterSubTitle>6. {t("privacysoustitre6")}</FooterSubTitle>
                <ul style={{color:'#3366ff'}}>
                    <li>{t("privacysoustitretexte11")}</li>
                </ul>
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackPrivacyHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
            </TermeScroller>
        </div>
    )
}

export default Privacy
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import faqData from './faqData.json';
//helperfunction
import { ShowFooter } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
//
import { 
    TermeScroller, 
    FooterSubTitle,
    HandlerContainerDiv,
    FaqContainer, 
    FaqQuestion, 
    FaqAnswer, 
    FaqAnswerContent, 
    BazaliButton, 
} from '../ComponentStyles';

import './footer.css';

const FAQ_CATEGORIES = {
    BUYER: 'buyer',
    SELLER: 'seller',
};

function Faq() {
    const {t} = useTranslation();

    const navigateFaq = useNavigate();

    const [selectedCategory, setSelectedCategory] = useState(FAQ_CATEGORIES.BUYER);
    const [expandedQuestion, setExpandedQuestion] = useState(null);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setExpandedQuestion(null);
    };

    const toggleQuestion = (index) => {
        setExpandedQuestion((prevIndex) => (prevIndex === index ? null : index));
    };

    const filteredFAQs = faqData.filter((faq) => faq.category === selectedCategory);

    const goBackFaqHandler = async () => {
        ShowFooter();
        navigateFaq('/');
    }

    return (
        <div id='faq'>
            <TermeScroller>
                <br/>
                <FooterSubTitle>Faq</FooterSubTitle>
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <BazaliButton onClick={() => handleCategoryChange(FAQ_CATEGORIES.BUYER)}>{t("buyer")}</BazaliButton>
                    </div>
                    <div>
                        <BazaliButton onClick={() => handleCategoryChange(FAQ_CATEGORIES.SELLER)}>{t("seller")}</BazaliButton>
                    </div>
                </HandlerContainerDiv>
                {/**faq output */}
                <FaqContainer>
                    {filteredFAQs.map((faq, index) => (
                        <React.Fragment key={index}>
                            <FaqQuestion 
                                onClick={() => toggleQuestion(index)}
                                className={`FaqQuestion ${expandedQuestion === index ? 'active' : ''}`}
                            >
                                {faq.question[t('languageInitials')]}
                            </FaqQuestion>
                            {expandedQuestion === index && (
                                <FaqAnswer className={`FaqAnswer ${expandedQuestion === index ? 'active' : ''}`}>
                                    <FaqAnswerContent>{faq.answer[t('languageInitials')]}</FaqAnswerContent>
                                </FaqAnswer>
                            )}
                        </React.Fragment>
                    ))}
                </FaqContainer>
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackFaqHandler} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>
                <br/><br/>
            </TermeScroller>
        </div>
    )
}

export default Faq
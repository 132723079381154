import React from 'react';
import { ModalBackDrop, ModalContainer, ModalText, ModalCancelButton, ModalConfirmButton, ModalRefundText } from '../../ComponentStyles';

function WarningRefundModal({ text, textOk, textNo, onConfirm, onCancel, textRefund }) {
    return (
        <ModalBackDrop>
            <ModalContainer>
                <ModalText>{text}</ModalText>
                <ModalRefundText type='text' value={textRefund} readOnly />
                <ModalConfirmButton onClick={onConfirm}>
                    {textOk}
                </ModalConfirmButton>
                <ModalCancelButton onClick={onCancel}>{textNo}</ModalCancelButton>
            </ModalContainer>
        </ModalBackDrop>
    )
}

export default WarningRefundModal
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdPreview } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal } from '../../../../helperfunctions/Helper';
//import WarningModal from '../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    FormScrollerWidgetSm,
    TableWidgetSmTable,
    TableThead,
    ThWidgetSmTh,
    TableTbody,
    TableTd,
    TableButton,
    SmallInput,
    SmallTextArea,
    SmallPriceInput,
    SmallCurrencyInput,
    SmallHandlerContainerDiv,
    SmallViewButton,
} from '../../../ComponentStyles';

function ViewCategory() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertviewcategory";
    const elementDropId = "backdropoutalertviewcategory";
    const buttonHandler = "ouiHandlerAlertViewCategoryBtn";

    // Define state variables for modals
    const [startViewCategoryModalOpen, setStartViewCategoryModalOpen] = useState(true);
    const [viewCategoryTableModalOpen, setViewCategoryTableModalOpen] = useState(false);
    const [viewCategoryModalOpen, setViewCategoryModalOpen] = useState(false);

    const [userViewCategory, setUserViewCategory] = useState([0]);
    const [viewDetailCategory, setViewDetailCategory] = useState([0]);

    const viewCategoryRef = useRef(null);

    const handleStartCategory = async () => {
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        const codeproduitDiv = document.getElementById('codeproduit').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table9;
        }

        //use the same axios.post edit/product/detals to retrieve data before deleting 
        await Axios.post(`${apiUrl}/edit/category/table`, {
            tableNameSeller:tableName,
            codeUtilisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
            codeProduct:codeproduitDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserViewCategory(response1.data);
                setStartViewCategoryModalOpen(false);
                setViewCategoryTableModalOpen(true);
                setViewCategoryModalOpen(false);
                viewCategoryRef.current.style.display = 'none';
            }
        });
    }

    const handleViewCategoryProduct = async (categoryId) => {
        var tableName = "";
        var signupTable = [];
        var resultat2 = '';

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table9;
        }

        var idCategory = categoryId;

        //use to retrieve data before deleting
        await Axios.post(`${apiUrl}/edit/category/detail`, {
            tableNameSeller:tableName,
            idCategory:idCategory,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                
                setViewDetailCategory(response2.data);
                setStartViewCategoryModalOpen(false);
                setViewCategoryTableModalOpen(false);
                setViewCategoryModalOpen(true);
                viewCategoryRef.current.style.display = 'block';
            }
        });
    }

    const handleViewCategory = async (e) => {
        e.preventDefault();

        setStartViewCategoryModalOpen(false);
        setViewCategoryTableModalOpen(true);
        setViewCategoryModalOpen(false);
        viewCategoryRef.current.style.display = 'none';
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_view_category")}</FooterSubTitle>
                <br/>
                {startViewCategoryModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartCategory}
                        />
                    </div>
                )}

                {viewCategoryTableModalOpen && (
                    <div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <TableWidgetSmTable>
                                <TableThead>
                                    <tr>
                                        <ThWidgetSmTh>{t("product")}</ThWidgetSmTh>
                                        <ThWidgetSmTh>{t("action")}</ThWidgetSmTh>
                                    </tr>
                                </TableThead>
                                <TableTbody>
                                    {userViewCategory.map((valedit, index) => {
                                        return(
                                            <tr key={index}>
                                                <TableTd>{valedit.name_product}</TableTd>
                                                <TableTd ><TableButton type="button" onClick={() => handleViewCategoryProduct(valedit.id_category)}>OK</TableButton></TableTd>
                                            </tr>
                                        )
                                    })}
                                </TableTbody>
                            </TableWidgetSmTable>
                        </FormScrollerWidgetSm>
                    </div>
                )}
                <br/>
                {viewCategoryModalOpen && (
                    <div>
                        {viewDetailCategory.map((valdeletedetail, indexdeletedetail) => (
                            <ModalUser key={indexdeletedetail}>
                                <SmallTextLogin>{t("product_name")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.name_product} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        defaultValue={valdeletedetail.description_product}
                                        readOnly  
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_price")}</SmallTextLogin>
                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallPriceInput type='number' defaultValue={valdeletedetail.price_product} readOnly></SmallPriceInput>
                                    </div>
                                    <div>
                                        <SmallCurrencyInput type='text' defaultValue={valdeletedetail.currency_product} readOnly />
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_image")}</SmallTextLogin>
                                <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                    <img src={valdeletedetail.image_product} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                </div>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/>
                <div ref={viewCategoryRef} style={{display:'none'}}>
                    <SmallHandlerContainerDiv>
                        <div>
                            <SmallViewButton onClick={handleViewCategory}>
                                <span style={{ fontSize:'30px'}}><MdPreview /></span>
                                OK
                            </SmallViewButton>
                        </div>
                    </SmallHandlerContainerDiv>
                </div>
                <br/><br/>
                <div id="modaloutalertviewcategory"></div>
                <div id="backdropoutalertviewcategory"></div>
            </ModalUser>
        </div>
    )
}

export default ViewCategory
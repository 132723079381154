import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdDelete } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { /*HideFooter,*/ AlertModal } from '../../../../helperfunctions/Helper';
import WarningModal from '../../../reusablecomponents/componentutils/WarningModal';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallTextArea,
    SmallPriceInput,
    SmallCurrencyInput,
    SmallHandlerContainerDiv,
    SmallDeleteButton,
} from '../../../ComponentStyles';

function DeleteProduct() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertdeleteproduct";
    const elementDropId = "backdropoutalertdeleteproduct";
    const buttonHandler = "ouiHandlerAlertDeleteProductBtn";

    // Define state variables for modals
    const [startDeleteProductModalOpen, setStartDeleteProductModalOpen] = useState(true);
    const [deleteProductModalOpen, setDeleteProductModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [deleteDetailProduct, setDeleteDetailProduct] = useState([0]);

    const idDeleteProductRef = useRef(null);

    const deleteProductRef = useRef(null);

    const handleStartProduct = async () => {
        
        var tableName = "";
        var signupTable = [];
        var resultat1 = '';

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }

        //use the same axios.post edit/product/detals to retrieve data before deleting 
        await Axios.post(`${apiUrl}/edit/product/details`, {
            tableNameSeller:tableName,
            codeUtisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setDeleteDetailProduct(response1.data);
                setStartDeleteProductModalOpen(false);
                setDeleteProductModalOpen(true);
                deleteProductRef.current.style.display = 'block';
            }
        });
    }

    const handleDeleteProduct = async (e) => {
        e.preventDefault();

        setShowModal(true);
    }

    const handleModalDeleteProduct = async (e) => {
        e.preventDefault();

        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const idDeleteProduct = idDeleteProductRef.current.value;
        const statusProduct = 'pause';
        //
        var tableName = "";
        var signupTable = [];
        //var resultat = '';
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table4;
        }

        await Axios.post(`${apiUrl}/delete/seller/product`, {
            tableNameSeller:tableName,
            statusProduct:statusProduct,
            deleteId:idDeleteProduct,
        }).then(() => {
            setStartDeleteProductModalOpen(true);
            setDeleteProductModalOpen(false);
            deleteProductRef.current.style.display = 'none';
        });
    }

    const handleCancelProduct = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setStartDeleteProductModalOpen(true);
        setDeleteProductModalOpen(false);
        deleteProductRef.current.style.display = 'none';
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_delete_product")}</FooterSubTitle>
                <br/>
                {startDeleteProductModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartProduct}
                        />
                    </div>
                )}
                <br/>
                {deleteProductModalOpen && (
                    <div>
                        {deleteDetailProduct.map((valdeletedetail, indexdeletedetail) => (
                            <ModalUser key={indexdeletedetail}>
                                <input style={{display:'none'}} ref={idDeleteProductRef} defaultValue={valdeletedetail.id_product} />
                                <br/>
                                <SmallTextLogin>{t("product_name")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valdeletedetail.name_product} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        defaultValue={valdeletedetail.description_product}
                                        readOnly  
                                    />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_price")}</SmallTextLogin>
                                <div style={{display:'flex', flexDirection:'row', marginLeft:'40px'}}>
                                    <div style={{display:'block',textAlign:'center'}}>
                                        <SmallPriceInput type='number' defaultValue={valdeletedetail.price_product} readOnly></SmallPriceInput>
                                    </div>
                                    <div>
                                        <SmallCurrencyInput type='text' defaultValue={valdeletedetail.currency_product} readOnly />
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("product_image")}</SmallTextLogin>
                                <div style={{ textAlign:'center', marginTop:'10px', border:'2px solid #3366ff', borderRadius:'10px' }}>
                                    <img src={valdeletedetail.image_product} alt='preview' style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                </div>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/>
                <div ref={deleteProductRef} style={{display:'none'}}>
                    <SmallHandlerContainerDiv>
                        <div>
                            <SmallDeleteButton onClick={handleDeleteProduct}>
                                <span style={{ fontSize:'30px'}}><MdDelete /></span>
                                {t("delete")}
                            </SmallDeleteButton>
                        </div>
                    </SmallHandlerContainerDiv>
                    {showModal && (
                        <WarningModal
                            text={t("text_product_warning")}
                            textOk={t("ok_delete")}
                            textNo={t("no_cancel")}
                            onConfirm={handleModalDeleteProduct}
                            onCancel={handleCancelProduct}
                        />
                    )}
                </div>
                <br/><br/>
                <div id="modaloutalertdeleteproduct"></div>
                <div id="backdropoutalertdeleteproduct"></div>
            </ModalUser>
        </div>
    )
}

export default DeleteProduct
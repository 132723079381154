export const TRANSLATIONS_EN = {
    langue:"Language ?",
    francais:"French",
    anglais:"English",
    portugais:"Portuguese",
    //
    allright:"All rights reserved",
    apropos:"About",
    contact:"Contact Us",
    faq:"FAQ",
    privacy:"Privacy",
    termes:"Terms",
    //translate a propos
    apropostitre:"About Bazali",
    aproposrevision:"Last revised September 2023",
    aproposintro:"Business logic dictates that every seller is a buyer, but not every buyer is a seller.",
    aproposintrosuite:"Bazali is a term from the Ivorian street language (nouchi or noussia) deriving from the French language and which means: To sell something quickly and cheaply, even to get rid of something by throwing it away or offering it for free to another person .",
    aproposintroensuite:"Bazali is a platform for interconnection and exchange between sellers and buyers in a given country, as well as with the 15 ECOWAS countries for a population estimated at more than 350 million inhabitants where the marketing and trade in various products has stagnated since 1960.",
    //
    soustitre1:"Why Bazali?",
    soustitretexte1:"No one could deny that he has never found himself in a situation where it was difficult or even impossible to buy something without knowing that the thing was not far from where he was.",
    soustitretexte2:"How many times our housewives are forced to roam the entire market for hours, for the simple purpose of finding goods and products at advantageous prices for their daily family consumption.",
    soustitretexte3:"How difficult it is for our economic operators to trade and exchange with their peers in the countries of the ECOWAS sub-region when we are talking about sub-regional economic integration of peoples and goods.",
    soustitretexte4:"Several scenarios are legion and the list is not exhaustive...",
    //
    soustitre2:"Specificities",
    soustitretexte5:"Bazali is a hybrid mobile application capable of working even in the event of a network loss during its use.",
    soustitretexte6:"It is designed for all buyers, sellers, economic operators and industries in the primary, secondary and tertiary sectors.",
    soustitretexte7:"Easy to use, you just need to know how to handle a mobile phone or tablet and also be able to write messages or send messages via mobile devices to discover Bazali's potential in your country and also sell or buy internationally ECOWAS.",
    soustitretexte8:"Bazali is a multilingual application (French, English, Portuguese) because the official languages conveyed in the 15 ECOWAS countries are those mentioned above in brackets. And any user in case of need with another user of different language could also use the integrated translator of his mobile device for his search results.",
    //
    soustitre3:"Goals",
    soustitretexte9:"Allow and offer the opportunity to the customer to be really king as the saying goes and buy intelligently at a lower price.",
    soustitretexte10:"Fluidity of purchases and exchanges between consumers and economic operators in member countries.",
    soustitretexte11:"Create new business opportunities for all users of this app.",
    soustitretexte12:"Give consumers the opportunity to be oriented towards the best offers on the local and inter-community market.",
    //translate termes
    termestitre:"Terms of Use",
    termesrevision:"Welcome to",
    termestexte1:"operated by",
    termestexte2:"By accessing the Bazali application or its website, whether through a mobile device, mobile application or computer, you agree to be bound by these Terms of Use (this “Agreement”), whether or not you create a Bazali account. If you wish to create a Bazali account and make use of the Service, please read these Terms of Use.",
    termessoustitre1:"About Bazali",
    termessoustitretexte1:"Bazali is a marketplace that allows users to offer, sell and buy just about anything in a variety of pricing formats and locations.",
    termessoustitretexte2:"Bazali does not have possession of anything listed or sold through Bazali, and is not involved in the actual transaction between buyers and sellers. The contract for the sale is directly between buyer and seller. She is not a party to the transaction and is not a traditional auctioneer.",
    termessoustitretexte3:"While we may provide pricing, postage, listing and other guidance in our Services, such guidance is solely informational and you may decide to follow it or not. Bazali does not review users' listings or content.",
    termessoustitretexte4:"Bazali has no control over, and does not guarantee the existence, quality, safety or legality of, items advertised; the truth or accuracy of users' content, listings or feedback; the ability of sellers to sell items; the ability of buyers to pay for items; or that a buyer or seller will actually complete a transaction or return an item.",
    termessoustitre2:"Acceptance of Terms of Use",
    termessoustitretexte5:"This Agreement is an electronic contract that establishes the legally binding terms you must accept to use the Service. This Agreement includes the Company’s Privacy Policy, our Safety Tips and terms disclosed and agreed to by you if you purchase products or services we offer on the Service.",
    termessoustitretexte6:"By accessing or using the Service, you accept this Agreement and agree to the terms, conditions and notices contained or referenced herein and consent to have this Agreement and all notices provided to you in electronic form. To withdraw this consent, you must cease using the Service and terminate your account. Please print a copy of this Agreement for your records. This Agreement may be modified by the Company from time to time, such modifications to be effective upon posting by the Company in the Service.",
    termessoustitre3:"Eligibility",
    termessoustitretexte7:"No part of Bazali is directed to persons under the age of 18. You must be at least 18 years of age to access and use the Service. Any use of the Service is void where prohibited. By accessing and using the Service, you represent and warrant that you have the right, authority and capacity to enter into this Agreement and to abide by all of the terms and conditions of this Agreement. If you create an account, you represent and warrant that you have never been convicted of a felony and that you are not required to register as a sex offender with any government entity.",
    termessoustitre4:"Creating an Account",
    termessoustitretexte8:"In order to use Bazali, you must sign in when making your dating search. If you do so, you authorize us to access and use certain account information, including but not limited to your public profile and information about friends you might share in common with other Rishta users. For more information regarding the information we collect from you and how we use it, please consult our Privacy Policy.",
    termessoustitre5:"Term and Termination",
    termessoustitretexte9:"This Agreement will remain in full force and effect while you use the Service and/or have a Bazali account. You may disable your account at any time, for any reason, by following the instructions in Settings in the Service. The Company may terminate or suspend your account at any time without notice if the Company believes that you have breached this Agreement, or for any other reason, with or without cause, in its sole discretion.",
    termessoustitretexte10:"Upon such termination or suspension, you will not be entitled to any refund of any products or services purchased. The Company is not required to disclose, and may be prohibited by law from disclosing, the reason for the termination or suspension of your account. After your account is terminated for any reason, all terms of this agreement survive such termination, and continue in full force and effect, except for any terms that by their nature expire or are fully satisfied.",
    termessoustitre6:"Non-commercial Use",
    termessoustitretexte11:"The Service is for personal use only. Users may not use the Service or any content contained in the Service (including, but not limited to, content of other users, designs, text, graphics, images, video, information, logos, software, audio files and computer code) in connection with any commercial endeavors, such as advertising or soliciting any user to buy or sell any products or services not offered by the Company or soliciting others to attend parties or other social",
    termessoustitretexte12:"functions, or networking, for commercial purposes. Users of the Service may not use any information obtained from the Service to contact, advertise to, solicit, or sell to any other user without his or her prior explicit consent. Organizations, companies, and/or businesses may not use the Service or the Service for any purpose.",
    termessoustitretexte13:"The Company may investigate and take any available legal action in response to illegal and/or unauthorized uses of the Service, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email and unauthorized framing of or linking to the Service.",
    termessoustitre7:"Account Security",
    termessoustitretexte14:"You are responsible for maintaining the confidentiality of the username and password you designate during the registration process, and you are solely responsible for all activities that occur under your username and password. You agree to immediately notify the Company of any disclosure or unauthorized use of your username or password or any other breach of security and ensure that you log out from your account at the end of each session.",
    termessoustitre8:"Proprietary Rights",
    termessoustitretexte15:"The Company owns and retains all proprietary rights in the Service, and in all content, trademarks, trade names, service marks and other intellectual property rights related thereto. The Service contains the copyrighted material, trademarks, and other proprietary information of the Company and its licensors.",
    termessoustitretexte16:"You agree to not copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, trademarks, trade names, service marks, or other intellectual property or proprietary information accessible through the Service, without first obtaining the prior written consent of the Company or, if such property is not owned by the Company, the owner of such intellectual property or proprietary rights.",
    termessoustitretexte17:"You agree to not remove, obscure or otherwise alter any proprietary notices appearing on any content, including copyright, trademark and other intellectual property notices.",
    termessoustitre9:"Content Posted by You",
    termessoustitretexte18:"You are solely responsible for the content and information that you post, upload, publish, link to, transmit, record, display or otherwise make available (hereinafter, post) on the Service or transmit to other users, including text messages, chat, or profile text. You may not post as part of the Service, or transmit to the Company or any other user (either on or off the Service), any offensive, inaccurate, incomplete, abusive, obscene, profane, threatening, intimidating, harassing, racially offensive, or illegal material, or any material that infringes or violates another person’s rights (including intellectual property rights, and rights of privacy and publicity). You represent and warrant that all information that you submit upon creation of your account, including information submitted is accurate and truthful.",
    termessoustitretexte19:"You understand and agree that the Company may, but is not obligated to, monitor or review any Content you post as part of a Service. The Company may delete any Content, in whole or in part, that in the sole judgment of the Company violates this Agreement or may harm the reputation of the Service or the Company.",
    termessoustitretexte20:"By posting Content as part of the Service, you automatically grant to the Company, its affiliates, licensees and successors, an irrevocable, perpetual, non- exclusive, transferable, sub-licensable, fully paid-up, worldwide right and license to use, copy, store, perform, display, reproduce, record, play, adapt, modify and distribute the Content, prepare derivative works of the Content or incorporate the Content into other works, and grant and authorize sublicenses of the foregoing in any media now known or hereafter created. You represent and warrant that any posting and use of your Content by the Company will not infringe or violate the rights of any third party.",
    termessoustitretexte21:"In addition to the types of Content described in Section above, the following is a partial list of the kind of Content that is prohibited in the Service. You may not post, upload, display or otherwise make available Content that:",
    termessoustitretexte22:"that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;",
    termessoustitretexte23:"advocates harassment or intimidation of another person;",
    termessoustitretexte24:"requests money from, or is intended to otherwise defraud, other users of the Service;",
    termessoustitretexte25:"promotes information that is false or misleading, or promotes illegal activities or conduct that is defamatory, libelous or otherwise objectionable;",
    termessoustitretexte26:"promotes an illegal or unauthorized copy of another person’s copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices;",
    termessoustitretexte27:"contains restricted or password only access pages, or hidden pages or images (those not linked to or from another accessible page);",
    termessoustitretexte28:"provides material that exploits people in a sexual, violent or other illegal manner, or solicits personal information from anyone under the age of 18;",
    termessoustitretexte29:"provides instructional information about illegal activities such as making or buying illegal weapons or drugs, violating someone’s privacy, or providing, disseminating or creating computer viruses;",
    termessoustitretexte30:"contains viruses, time bombs, trojan horses, cancelbots, worms or other harmful, or disruptive codes, components or devices;",
    termessoustitretexte31:"impersonates, or otherwise misrepresents affiliation, connection or association with, any person or entity;",
    termessoustitretexte32:"provides information or data you do not have a right to make available under law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information);",
    termessoustitretexte33:"disrupts the normal flow of dialogue, causes a screen to “scroll” faster than other users are able to type, or otherwise negatively affects other users’ ability to engage in real time exchanges;",
    termessoustitretexte34:"solicits passwords or personal identifying information for commercial or unlawful purposes from other users or disseminates another person’s personal information without his or her permission; and",
    termessoustitretexte35:"publicizes or promotes commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising, and pyramid schemes.",
    termessoustitretexte36:"The Company reserves the right, in its sole discretion, to investigate and take any legal action against anyone who violates this provision, including removing the offending communication from the Service and terminating or suspending the account of such violators.",
    termessoustitretexte37:"Your use of the Service, including all Content you post through the Service, must comply with all applicable laws and regulations. You agree that the Company may access, preserve and disclose your account information and Content if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary, such as to:",
    termessoustitretexte38:"comply with legal process; enforce this Agreement; respond to claims that any Content violates the rights of third parties; respond to your requests for customer service or allow you to use the Service in the future; or protect the rights, property or personal safety of the Company or any other person.",
    termessoustitretexte39:"You agree that any Content you place on the Service may be viewed by other users and may be viewed by any person visiting or participating in the Service.",
    termessoustitretexte40:"Prohibited Activities",
    termessoustitretexte41:"The Company reserves the right to investigate, suspend and/or terminate your account if you have misused the Service or behaved in a way the Company regards as inappropriate or unlawful, including actions or communications the occur off the Service but involve users you meet through the Service. The following is a partial list of the type of actions that you may not engage in with respect to the Service. You will not:",
    termessoustitretexte42:"impersonate any person or entity.",
    termessoustitretexte43:"solicit money from any users.",
    termessoustitretexte44:"post any Content that is prohibited by Section.",
    termessoustitretexte45:"stalk or otherwise harass any person.",
    termessoustitretexte46:"express or imply that any statements you make are endorsed by the Company without our specific prior written consent.",
    termessoustitretexte47:"use the Service in an illegal manner or to commit an illegal act;",
    termessoustitretexte48:"ask or use users to conceal the identity, source, or destination of any illegally gained money or products.",
    termessoustitretexte49:"use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, data mine, or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents.",
    termessoustitretexte50:"collect usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email or unauthorized framing of or linking to the Service.",
    termessoustitretexte51:"interfere with or disrupt the Service or the servers or networks connected to the Service.",
    termessoustitretexte52:"email or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment.",
    termessoustitretexte53:"forge headers or otherwise manipulate identifiers in order to disguise the origin of any information transmitted to or through the Service (either directly or indirectly through use of third party software).",
    termessoustitretexte54:"frame or mirror any part of the Service, without the Company's prior written authorization.",
    termessoustitretexte55:"use meta tags or code or other devices containing any reference to the Company or the Service (or any trademark, trade name, service mark, logo or slogan of the Company) to direct any person to any other website for any purpose.",
    termessoustitretexte56:"modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Service any software used on or for the Service, or cause others to do so.",
    termessoustitretexte57:"post, use, transmit or distribute, directly or indirectly, (e.g. screen scrape) in any manner or media any content or information obtained from the Service other than solely in connection with your use of the Service in accordance with this Agreement.",
    termessoustitretexte58:"Customer Service",
    termessoustitretexte59:"through its customer care representatives. When communicating with our customer care representatives, you agree to not be abusive, obscene, profane, offensive, sexist, threatening, harassing, racially offensive, or to not otherwise behave inappropriately. If we feel that your behavior towards any of our customer care representatives or other employees is at any time threatening or offensive, we reserve the right to immediately terminate your account.",
    termessoustitretexte60:"Modifications to Service",
    termessoustitretexte61:"The Company reserves the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that the Company shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service. To protect the integrity of the Service, the Company reserves the right at any time in its sole discretion to block users from certain IP addresses from accessing the Service.",
    termessoustitretexte62:"Copyright Policy; Notice and Procedure for Making Claims of Copyright Infringement",
    termessoustitretexte63:"You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Without limiting the foregoing, if you believe that your work has been copied and posted on the Service in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information:",
    termessoustitretexte64:"an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;",
    termessoustitretexte65:"a description of the copyrighted work that you claim has been infringed;",
    termessoustitretexte66:"a description of where the material that you claim is infringing is located on the Service (and such description must be reasonably sufficient to enable the Company to find the alleged infringing material, such as a url);",
    termessoustitretexte67:"your address, telephone number and email address;",
    termessoustitretexte68:"a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and",
    termessoustitretexte69:"a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.",
    termessoustitretexte70:"The Company will terminate the accounts of repeat infringers.",
    termessoustitretexte71:"You acknowledge and agree that neither the Company nor its affiliates and third party partners are responsible for and shall not have any liability, directly or indirectly, for any loss or damage, including personal injury or death, as a result of or alleged to be the result of any incorrect or inaccurate Content posted in the Service, whether caused by users or any of the equipment or programming associated with or utilized in the Service;",
    termessoustitretexte72:"the timeliness, deletion or removal, incorrect delivery or failure to store any Content, communications or personalization settings; the conduct, whether online or offline, of any user;  any error, omission or defect in, interruption, deletion, alteration, delay in operation or transmission, theft or destruction of, or unauthorized access to, any user or user communications;",
    termessoustitretexte73:"or any problems, failure or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to users or to any other person’s computer or device related to or resulting from participating or downloading materials in connection with the Internet and/or in connection with the Service.",
    termessoustitretexte74:"TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, THE COMPANY PROVIDES THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS AND GRANTS NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN), INCLUDING (WITHOUT LIMITATION) ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE COMPANY DOES NOT REPRESENT OR WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR FREE, SECURE OR THAT ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED.",
    termessoustitretexte75:"ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR DEVICE, COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD OR DISPLAY DEVICE, OR LOSS OR CORRUPTION OF DATA THAT RESULTS OR MAY RESULT FROM THE DOWNLOAD OF ANY SUCH MATERIAL. IF YOU DO NOT ACCEPT THIS LIMITATION OF LIABILITY, YOU ARE NOT AUTHORIZED TO DOWNLOAD OR OBTAIN ANY MATERIAL THROUGH THE SERVICE.",
    termessoustitretexte76:"From time to time, the Company may make third party opinions, advice, statements, offers, or other third party information or content available through the Service. All third party content is the responsibility of the respective authors thereof and should not necessarily be relied upon. Such third party authors are solely responsible for such content.",
    termessoustitretexte77:"THE COMPANY DOES NOT: GUARANTEE THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY THIRD PARTY CONTENT PROVIDED THROUGH THE SERVICE, OR ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY THAT APPEARS IN THE SERVICE. UNDER NO CIRCUMSTANCES WILL THE COMPANY OR ITS AFFILIATES BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE RESULTING FROM YOUR RELIANCE ON INFORMATION OR OTHER CONTENT POSTED IN THE SERVICE, OR TRANSMITTED TO OR BY ANY USERS.",
    termessoustitretexte78:"In addition to the preceding paragraph and other provisions of this Agreement, any advice that may be posted in the Service is for informational and entertainment purposes only and is not intended to replace or substitute for any professional financial, medical, legal, or other advice. The Company makes no representations or warranties and expressly disclaims any and all liability concerning any treatment, action by, or effect on any person following informations offered or provided within or through the Service. If you have specific concerns or a situation arises in which you require professional or medical advice, you should consult with an appropriately trained and qualified specialist.",
    termessoustitretexte79:"Links",
    termessoustitretexte80:"The Service may contain, and the Service or third parties may provide, advertisements and promotions offered by third parties and links to other web sites or resources. You acknowledge and agree that the Company is not responsible for the availability of such external websites or resources, and does not endorse and is not responsible or liable for any content, information, statements, advertising, goods or services, or other materials on or available from such websites or resources. Your correspondence or business dealings with, or participation in promotions of, third parties found in or through the Service, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such third party. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance upon, any such content, information, statements, advertising, goods or services or other materials available on or through any such website or resource.",
    termessoustitretexte81:"Limitation on Liability. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, BUSINESS PARTNERS, LICENSORS OR SERVICE PROVIDERS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, RELIANCE, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS, LOSS OF GOODWILL, DAMAGES FOR LOSS, CORRUPTION OR BREACHES OF DATA OR PROGRAMS, SERVICE INTERRUPTIONS AND PROCUREMENT OF SUBSTITUTE SERVICES, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE COMPANY'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO THE COMPANY FOR THE SERVICE WHILE YOU HAVE AN ACCOUNT. YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE OF THE SERVICE OR THE TERMS OF THIS AGREEMENT MUST BE FILED WITHIN ONE YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION AROSE OR BE FOREVER BARRED.",
    termessoustitretexte82:"The exclusive means of resolving any dispute or claim arising out of or relating to this Agreement (including any alleged breach thereof) or the Service shall be BINDING ARBITRATION administered by the American Arbitration Association. The one exception to the exclusivity of arbitration is that you have the right to bring an individual claim against the Company in a small-claims court of competent jurisdiction. But whether you choose arbitration or small-claims court, you may not under any circumstances commence or maintain against the Company any class action, class arbitration, or other representative action or proceeding.",
    termessoustitretexte83:"By using the Service in any manner, you agree to the above arbitration agreement. In doing so, YOU GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend any claims between you and the Company (except for matters that may be taken to small-claims court). YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING. Your rights will be determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR JURY. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant any relief that a court can, but you should note that arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons. For details on the arbitration process, see our Arbitration Procedures.",
    termessoustitretexte84:"Any proceeding to enforce this arbitration agreement, including any proceeding to confirm, modify, or vacate an arbitration award, may be commenced in any court of competent jurisdiction.",
    termessoustitretexte85:"Notice",
    termessoustitretexte86:"The Company may provide you with notices, including those regarding changes to this Agreement, using any reasonable means now known or hereafter developed, including by email, regular mail, SMS, MMS, text message or postings in the Service. Such notices may not be received if you violate this Agreement by accessing the Service in an unauthorized manner. You agree that you are deemed to have received any and all notices that would have been delivered had you accessed the Service in an authorized manner.",
    termessoustitretexte87:"Entire Agreement; Other",
    termessoustitretexte88:"This Agreement, with the Privacy Policy and any specific guidelines or rules that are separately posted for particular services or offers in the Service, contains the entire agreement between you and the Company regarding the use of the Service. If any provision of this Agreement is held invalid, the remainder of this Agreement shall continue in full force and effect. The failure of the Company to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. You agree that your online account is non-transferable and all of your rights to your profile or contents within your account terminate upon your death. No agency, partnership, joint venture or employment is created as a result of this Agreement and you may not make any representations or bind the Company in any manner.",
    termessoustitretexte89:"Amendment",
    termessoustitretexte90:"This Agreement is subject to change by the Company at any time.",

    //feedback
    feddbacktitle:"Feedbacks",
    feedbackdear:"Dear users",
    feedbacktexte1:"Bazali invites you to support him by providing suggestions and proposals that could allow us to improve your user experience and develop this app",
    feedbacktexte2:"Your rating",
    feedbacktexte3:"Select your rating",
    feedbacktexte4:"Why this rating",
    feedbacktexte5:"Submit",
    feedbacktexte6:"Previous Page",
    feedbacktexte7:"Next Page",

    //principal
    principaltitle:"Select your language",
    principaltexte1:"Please select your language every time you start bazali.",
    principaltexte2:"Choose your country",
    select_country_texte1:"Please select your country based on the language selected and click Next Page.",
    principalbutton:"Confirm",

    //starteruser
    demarrersubtitle:"Get started",
    demarrertexte1:"Select your action to get started",
    demarrertexte2:"Buyer",
    demarrertexte3:"Seller",

    //buyer principal
    buyertexte1:"It is mandatory to have a buyer account before continuing. Proceed if you have one otherwise create one and use your details to continue.",
    buyertexte2:"Buyer Access",
    buyertexte3:"Buyer Account",
    //crud buyer account
    buyertexte4:"Buyer Account Management",
    buyertexte5:"Create, View, Modify and Delete your buyer account for free",
    buyertexte6:"Create Account",
    buyertexte7:"View Account",
    buyertexte8:"Edit Account",
    buyertexte9:"Remove Account",
    //add/create buyer account
    buyertexte10:"Create Free Account",
    buyertexte11:"All fields with an asterix (*) are required.",
    buyertexte12:"First name",
    buyertexte13:"Enter your first name",
    buyertexte14:"Last names",
    buyertexte15:"Enter your last names",
    buyertexte16:"Mobile number",
    buyertexte17:"Without international prefix",
    buyertexte18:"Enter your e-mail",
    buyertexte19:"Enter your password",
    buyertexte20:"Confirm Password",
    buyertexte21:"Confirm your password",
    buyertexte22:"Create",
    //edit buyer account
    buyertexte23:"Edit Account",
    buyertexte24:"Edit",
    //view buyer account
    buyertexte25:"View Account",
    buyertexte26:"View",
    //delete buyer account
    buyertexte27:"Remove Account",
    buyertexte28:"Delete",
    //connection buyer
    buyertexte29:"Buyer Login",
    buyertexte30:"Login",
    //Acheteur
    buyertexte31:"Where to buy?",
    buyertexte32:"You have the options to buy national or international (ECOWAS countries). Make your choice.",
    buyertexte33:"Buy National",
    buyertexte34:"Buy International",
    //acheter national
    buyertexte35:"What to buy?",
    buyertexte36:"You have the options to buy in your country of residence or even learn about various trends and help on how to do your research.",
    buyertexte37:"Purchases all Categories",
    buyertexte38:"Buy Local",
    buyertexte39:"Buy other cities",
    buyertexte40:"popular trends",
    buyertexte41:"New trends",
    buyertexte42:"What's up ?",
    buyertexte43:"Need help?",
    //acheter international
    buyertexte44:"You have the options to buy in any ECOWAS country even, to learn about various trends and help on how to carry out your research.",
    buyertexte45:"Search",
    //vendeur national et international
    sellertexte1:"It is mandatory to have a seller account before continuing. The international seller account, on the other hand, allows you to sell within other ECOWAS countries. Proceed if you have one, otherwise create one and use your details to continue.",
    sellertexte2:"Seller Access",
    sellertexte3:"Seller Account",
    sellertexte4:"Create, View, Modify and Delete your seller account for free.",
    sellertexte5:"Seller Account Management",
    sellertexte6:"Select account type",
    sellertexte7:"National Account",
    sellertexte8:"ECOWAS Account",
    sellertexte9:"Validate",
    sellertexte10:"Seller Login",
    sellertexte11:"Login",
    //vendeur
    sellertexte12:"Dashboard",
    sellertexte13:"All your activities as a seller can be found here. Click to interact.",
    sellertexte14:"My unit credits",
    sellertexte15:"My showcase",
    sellertexte16:"My messages",
    sellertexte17:"Country",
    sellertexte18:"Confirm",
    sellertexte19:"Are you sure to delete this account?",
    sellertexte20:"Account type",
    //contactme
    your_name:"Enter your Name",
    //contactme
    contactme: "Contact Us",
    contactintro: "Do you have any questions, comments, or suggestions? we'd love to hear from you! Please feel free to reach out to Bazali's customer service  using any of the following methods:",
    contact_1: "Direct Call: You can give a call directly on Bazali's customer service phone number.",
    contact_2: "Send Email: If you prefer to communicate via email, please send a message.",
    contact_3: "WhatsApp Call: If you use WhatsApp, you can also give a call or chat on this WhatsApp number.",
    contactconclued_1: "We're always happy to hear from new people and we'll do our best to get back to you as soon as possible.",
    contactconclued_2: "Looking forward to hearing from you soon!",
    //
    buyer:"Buyer",
    seller:"Seller",
    languageInitials:"en",
    //privacy
    privacytitre:"Privacy Policy",
    privacytexte2:"At Bazali, we are committed to protecting the privacy and security of our users. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our platform. By using Bazali, you consent to the practices described in this policy.",
    privacysoustitre1:"Information We Collect:",
    privacysoustitretexte1:"Personal Information: When you create an account on Bazali, we may collect certain personal information such as your name, email address, and contact details. This information is necessary to facilitate communication between buyers and sellers.",
    privacysoustitretexte2:"Transaction Information: When you make a purchase or sale on Bazali, we collect transaction details, including the products involved and payment information. However, we do not store any payment card information. All payment transactions are securely processed by our trusted payment gateway partners.",
    privacysoustitre2:"How We Use Your Information:",
    privacysoustitretexte3:"Communication: We may use your personal information to communicate with you regarding your account, orders, and inquiries.",
    privacysoustitretexte4:"Improving User Experience: We analyze user behavior and preferences to enhance the user experience on Bazali. This includes personalizing product recommendations and displaying relevant content.",
    privacysoustitretexte5:"Fraud Prevention: We may use collected information to detect and prevent fraudulent activities or unauthorized access to our platform.",
    privacysoustitre3:"Sharing of Information:",
    privacysoustitretexte6:"Seller-Buyer Communication: When you engage in transactions on Bazali, certain personal information may be shared with the relevant seller or buyer to facilitate the transaction.",
    privacysoustitretexte7:"Third-Party Service Providers: We may share your information with trusted third-party service providers who assist us in operating our platform, processing payments, and providing customer support.",
    privacysoustitre4:"Data Security:",
    privacysoustitretexte8:"We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, please note that no method of transmission over the internet or electronic storage is 100% secure.",
    privacysoustitre5:"Your Choices and Rights:",
    privacysoustitretexte9:"Account Settings: You can review and update your account information through the account settings page on Bazali.",
    privacysoustitretexte10:"Marketing Communications: You have the option to opt out of receiving marketing communications from Bazali.",
    privacysoustitre6:"Data Retention:",
    privacysoustitretexte11:"We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.",
    //startingbuyer
    buyer_section_1:"Welcome Buyer!",
    buyer_section_2:"Please choose one of the following options:",
    buyersoustitre1:" Unregistered:",
    buyersoustitretexte1:"If you are not a registered user, you can explore our platform and browse through available products and services. However, please note that some features may be limited for non-registered.",
    buyersoustitre2:"Registered:",
    buyersoustitretexte2:"If you already have a sign up, click this button to access all the premium features and benefits available exclusively to our registered users. Enjoy a seamless experience with enhanced functionality.",
    buyersoustitre3:"Sign up",
    buyersoustitretexte3:"If you're new here, click this button to create a new account for free. By creating an account, you can unlock additional features, personalize your experience, and easily manage your preferences.",
    buyersoustitretexte4:"Feel free to click any of the buttons above to proceed. If you want to go back to the previous page, click the <<Previous Page>> button.",
    noSubscriberUser:"Unregistered",
    subscriberUser:"Registered",
    createNewAccount:"Sign up",
    //startingseller
    seller_section_1:"Welcome Seller",
    seller_section_2:"Please choose one of the following options:",
    sellersoustitre1:"Sign In:",
    sellersoustitretexte1:"If you already have an account, click this button to sign in and access your seller dashboard. You can manage your products, track sales, and interact with buyers.",
    sellersoustitre2:"Sign Up",
    sellersoustitretexte2:"If you are new to our platform, click this button to create a seller account. By signing up, you can start selling your products or services to our wide buyer base. Gain visibility, reach potential buyers, and grow your business.",
    sellersoustitre3:"Subscribe:",
    sellersoustitretexte3:"Click this button to subscribe to our basic, standard and premium seller plan. As a subscriber, you'll unlock additional features and benefits designed to boost your sales and streamline your selling experience.",
    sellersoustitretexte4:"Feel free to click any of the buttons above to proceed. If you want to go back to the previous page, click the <<Previous Page>> button.",
    singinUser:"Log In",
    signUpUser:"Sign Up",
    subscribe:"Subscribe",
    search_placeholder:"Write only product's name",
    filter_city:"City",
    filter_neighborhood:"Neighborhood",
    filter_best_prices:"Price",
    play_ok:"OK",
    buyer_search_texte1:"Please enter the name of the product you are looking for...",
    buyer_search_texte2:"You can further refine your search by applying filters if needed.",
    buyer_search_texte3:"Click <<🔍>> to display the list of products you're looking for.",
    product_name:"Product's name",
    searchcity_placeholder:"Enter city's name",
    searchneighborhood_placeholder:"Enter neighborhood's name",
    searchprice_placeholder:"Enter your prefered best price",
    confirm_button:"Confirm",
    cancel_button:"Cancel",
    //authentication
    user_section_1:"Welcome, registered buyers! 😊",
    user_phone_texte:"Please enter your mobile phone number (without the international prefix).",
    user_password_texte:"Please enter your password.",
    user_section_2:"Once you have entered all the required fields, click on the <<LogIn>> button.",
    //
    signup_section_1:"Here's a small guide on how you can sign up for a new account in the Bazali sign-up form:",
    signupsoustitre1:"Welcome to Bazali Sign Up!",
    signupsoustitretexte1:"Thank you for choosing Bazali! We're excited to have you join our platform. Follow the steps below to create and manage your new free buyer account.",
    signupsoustitre2:"Personal Information:",
    signupsoustitretexte2:"Enter your first and last name in the provided fields.",
    signupsoustitretexte3:"Enter your valid whatsapp mobile phone number without the international prefix. We will use this number to authenticate you and send important notifications and updates.",
    signupsoustitretexte4:"Provide your city. This information will help us tailor our services to your location.",
    signupsoustitretexte5:"Choose a strong password to secure your account. Make sure it meets the specified requirements for length and complexity that able and easy to remember.",
    signupsoustitre3:"Terms and Conditions:",
    signupsoustitretexte6:"Read the Terms and Conditions and Privacy Policy. It's essential to understand our policies and how your data will be used.",
    signupsoustitre4:"Complete Sign Up:",
    signupsoustitretexte7:"Double-check all the information you've entered for accuracy.",
    signupsoustitretexte8:"Once you are ready, click on the <<Sign Up>> button to create your account.",
    signupsoustitre5:"Account Verification:",
    signupsoustitretexte9:"After signing up, you'll receive a Whatsapp message on your phone with your secret user code. Copy it and turn back to bazali and enter that code once to verify your code and activate your account.",
    signupsoustitre6:"Congratulations! You're now a Bazali user.",
    signupsoustitretexte10:"Once your account is verified, you can log in using your phone number and password.",
    signupsoustitretexte11:"Explore our platform, discover products, and enjoy the benefits of being a Bazali user.",
    signup_section_2:"If you have any questions or encounter any issues during the sign-up process, feel free to contact our support team for assistance.",
    signup_section_3:"Note: Ensure that you provide accurate and up-to-date information during the sign-up process to ensure a smooth and personalized experience on the Bazali platform.",
    create:"Create",
    edit:"Update",
    delete:"Delete",
    view:"Preview",
    //
    create_account:"Create Account",
    edit_account:"Edit Account",
    delete_account:"Delete Account",
    view_account:"View Account",
    first_name:"First name",
    firstname_placeholder:"Enter first name",
    last_name:"Last name",
    lastname_placeholder:"Enter last name",
    send_code:"Send Code",
    code_user:"User Code",
    codeuser_placeholder:"Insert user code",
    //
    continuer:"Continue after authentication",
    please_number:"Enter all fields before continuing.",
    whatsapp_authentication:"Your Whatsapp number is valid. Now, turn back to Bazali and click <<Continue after authentication>>.",
    signup_error:"The user code inserted not correct. Please check your Whatsapp message for the correct user code or repeat your signup process.",
    buyer_exists:"You get this warning because you can't use the same phone number to create two accounts.",
    buyer_success:"You've successfully created your free account. Remember to use your phone number and password to login every time.",
    error_login:"Your login credentials aren't correct. Check and retry again. If not please, create your user free account.",
    //
    cat_all:"All",
    cat_food:"Foods",
    cat_beverage:"Beverages",
    cat_apparel:"Apparel",
    cat_health:"Health",
    cat_beauty:"Beauty",
    cat_services:"Services",
    cat_electronic:"Electronics",
    cat_automotive:"Automotive",
    cat_agriculture:"Agriculture",
    cat_breeding:"Breeding",
    cat_house:"Houses",
    cat_sport:"Sports",
    cat_decor:"Decor",
    //
    subscribe_section_2:"Welcome to the Registered Buyer section!",
    subscribe_section_3:"To search for a product, follow these simple steps:",
    subscribesoustitre1:"Select the appropriate category:",
    subscribe_search_texte1:"Use the scrollable toolbar to navigate through different categories. Choose the category that best matches your desired product.",
    subscribesoustitre2:"Explore the Categories:",
    subscribe_search_texte2:"Each category represents a specific group of products. Browse through the available options to find what you're looking for.",
    subscribesoustitre3:"Can't Define the Category?",
    subscribe_search_texte3:"Use the <<All>> Button: If you're unsure about the specific category, click on the <<All>> button. It will redirect you to the global search bar.",
    subscribesoustitre4:"Enter the Product Name:",
    subscribe_search_texte4:"In the global search bar, enter the name of the product you're interested in. Hit enter or click the search icon to initiate the search.",
    //Dashboard
    dash_titre:"Manage your presence",
    dash_section_1:"Managing your presence on Bazali allows you to reach a larger audience and showcase your products to potential buyers from various countries. With Bazali's user-friendly interface, you can easily create and customize your online store, making it appealing and unique. By leveraging Bazali's marketing and promotional features, you can boost your brand visibility and increase sales. Furthermore, Bazali offers reliable customer support and secure subscription options, ensuring a smooth and trustworthy visibility experience for you.",
    dashsoustitre1:"Administration",
    dashsoustitretexte1:"In the Bazali seller's admin panel, you have access to a comprehensive set of tools and features to efficiently manage your online store and products visibilty.",
    dashsoustitre2:"Dashboard",
    dashsoustitretexte2:"In the Bazali dashboard, you can easily manage your products, update inventory, and track sales performance. you can also monitor customer interactions, respond to inquiries, and process orders efficiently. The dashboard provides valuable insights through analytics and reporting tools, helping you make data-driven decisions to grow your online business presence successfully. Additionally, you can access marketing features and promotional tools to enhance your brand visibility and attract potential buyers.",
    dashsoustitre3:"Plan",
    dashsoustitretexte3:"With Bazali's paid plan services, you can unlock a range of basic, standard and premium features, such as priority customer support, advanced analytics, and enhanced marketing tools. Subscribers can enjoy expanded storage and access to exclusive templates for creating stunning product pages. Moreover, the paid plan allows you to integrate with third-party applications and payment gateways for seamless and secure transactions. Additionally, you can benefit from priority listing in search results, giving your products increased visibility and potential for higher sales. Once your trial period expires, you can continue to enjoy these features and services by subscribing to one of our affordable paid plans tailored to meet your business needs.",
    dashsoustitre4:"Products",
    dashsoustitretexte4:"In Bazali's products, you can create and manage your online store with ease, adding, updating, deleting, viewing and showcasing products details in a visually appealing manner. You have the flexibility to organize products into categories and apply various filters to help buyers find what they need quickly. You can also set prices, offer discounts, and manage shipping options to provide a seamless shopping experience for your buyers. With Bazali's products, you can reach ECOWAS audience, expand your brand's reach, and boost sales through a user-friendly and feature-rich platform.",
    dashsoustitre5:"Top-up",
    dashsoustitretexte5:"In Bazali's top-up feature, you can easily add funds to your account, enabling you to pay for subscriptions, access premium features, and boost your store's visibility. Top-up provides a seamless and secure payment process, allowing you to manage your finances conveniently within the platform. You can monitor your account balance and transaction history to keep track of your spending and ensure a smooth and uninterrupted experience on Bazali. With top-up, you can take full advantage of Bazali's paid services and enhance your online presence to reach a broader audience and drive more sales.",
    dashsoustitre6:"Settings",
    dashsoustitretexte6:"In Bazali's settings, you can personalize your account by updating profile information, such as name, contact details, and store description. You can also configure notification preferences to stay informed about order updates, messages, and platform announcements. The settings section also allows you to adjust privacy settings, and connect social media accounts, enabling a tailored and secure experience on Bazali.",
    dashsoustitre7:"Messages",
    dashsoustitretexte7:"In Bazali's chats, you can communicate directly with buyers or other sellers to discuss product details, negotiate prices, and address any inquiries. The chat feature facilitates real-time interactions, fostering smoother transactions and building trust between parties. You can also access previous chat history to refer back to important discussions and maintain seamless communication throughout the buying and selling process. Additionally, the chat system ensures a secure and private platform for all conversations, enhancing you experience and facilitating successful transactions on Bazali.",
    dash_section_2:"In conclusion, managing your presence on Bazali empowers you to expand your reach and connect with a diverse ECOWAS audience of potential buyers. With user-friendly tools and customization options, you can create an appealing and unique online store. Leveraging Bazali's marketing features helps boost brand visibility and drive sales.",
    dash_section_3:"Moreover, our reliable customer support and secure subscription options ensure a seamless and trustworthy experience, enabling you to thrive in the ECOWAS e-commerce.",
    //left side
    dash_admin:"Admin",
    dash_board:"Board",
    dash_plan:"Plan",
    dash_product:"Products",
    dash_order:"Top-up",
    dash_settings:"Settings",
    dash_chat:"Messages",
    //
    dash_manage_product:"Products Management",
    dash_new_product:"New Product",
    dash_discount_product:"Sales/Promotions",
    dash_edit_product:"Edit Product",
    dash_delete_product:"Delete Product",
    dash_view_product:"View Product",
    product_image:"Download Image",
    upload_image:"Select Image",
    //
    productname_placeholder:"Enter Product name",
    product_description:"Description",
    product_price:"Price",
    currency_placeholder:"Currency",
    save_product:"Save",
    //admin panel
    dash_admin_panel:"Admin Panel",
    dash_product_magement:"Product Management",
    dash_order_processing:"Order Processing",
    dash_inventory_control:"Inventory Control",
    dash_marketing_promotion:"Marketing & Promotions",
    dash_customer_management:"Customer Management",
    dash_analytic_reports:"Analytics & Reports",
    dash_customer_support:"Customer Support",
    //dashboard
    dash_dashboard:"Dashboard",
    dash_update_inventory:"Update Inventory",
    dash_sales_performance:"Sales Performance",
    dash_customer_interaction:"Customer Interactions",
    dash_customer_inquiries:"Customer Inquiries",
    //plan
    dash_basic_plan:"Basic Plan",
    dash_standard_plan:"Standard Plan",
    dash_premium_plan:"Premium Plan",
    dash_special_event:"Special Event",
    dash_third_party:"Third-Party App",
    //top-up
    dash_basic_topup:"Basic Top-up",
    dash_standard_topup:"Standard Top-up",
    dash_premium_topup:"Premium Top-up",
    dash_special_topup:"Special Top-up",
    dash_third_topup:"Third-party Top-up",
    //settings
    dash_create_profile:"Create Profile",
    dash_edit_profile:"Edit Profile",
    dash_delete_profile:"Delete Profile",
    dash_view_profile:"View Profile",
    //chats
    dash_buyer_chats:"Buyer Messaging",
    dash_seller_chats:"Seller Messaging",
    //category
    dash_category:"Category",
    dash_new_category:"New Category",
    dash_discount_category:"Sales/Promotions",
    dash_edit_category:"Edit Category",
    dash_delete_category:"Delete Category",
    dash_view_category:"View Category",
    //
    category_name:"Category Name",
    categoryname_placeholder:"Enter Category name",
    category_description:"Description",
    category_price:"Price",
    //profile
    profile_country:"Country",
    profile_ville:"City",
    profile_village:"Village",
    place_village_option:"Village Name(optional)",
    profile_quartier:"Neighborhood",
    place_quartier_option:"Neighborhood(optional)",
    profile_sector:"Business Sector",
    profile_sector_select:"Select Business Sector",
    profile_principal:"Business Name",
    place_principal:"Enter Business Name",
    profile_location:"Location",
    place_location:"Explain to buyers where they could find you",
    profile_mode:"Sales Mode",
    select_profile_mode:"Select Sales Mode",
    profile_description:"Description",
    place_description:"Describe your activity",
    profile_contact:"How to be contacted?",
    profile_mode_contact:"Select Contact Mode",
    profile_private:"Private",
    profile_public:"Public",
    //
    profile_cash:"Cash",
    profile_online:"Online",
    profile_correspondance:"Correspondance",
    profile_order:"Order",
    profile_commission:"Commision",
    profile_affiliate:"Affiliation",
    characters_remaining:"characters remaining",
    Exceeded_character:"Exceeded character limit",
    profile_success:"You've saved your profile successfully",
    profile_exists:"Your profile already exists. You can only edit it.",
    //warning
    text_warning:"Are you sure you want to delete your profile? This action cannot be undone.",
    ok_delete:"OK, Delete",
    no_cancel:"No, Cancel",
    //produit
    product_exists:"You've already created your main product. You could edit it if needed",
    image_uplaod:"Image uploaded successfully",
    image_failed:"Image uploaded failed",
    product_success:"Your main product saved successfully",
    previous_image:"Previous image",
    replace_image:"Replace image",
    image:"Image",
    edit_product_success:"Your main product updated successfully",
    text_product_warning:"Are you sure you want to delete your main product ? This action will desable and enable it if you needed.",
    text_view_product_warning:"Do you want to enable your main product ? Then click ok to do it.",
    ok_enable:"Ok, Enable",
    //discount product
    error_product:"Before you proceed to create products for sales or promotions, it's essential to set up your main product.",
    dash_new_discount:"Create sales/promo",
    dash_edit_discount:"Edit sales/promo",
    dash_delete_discount:"Delete sales/promo",
    dash_view_discount:"View sales/promo",
    //
    text_discount_product_warning:"Please note that sales and promotions are active for a duration of two weeks. Additionally, there is a nominal subscription fee required to ensure your offerings are online and accessible to potential buyers.",
    ok_discount:"Confirm",
    //
    the_product_description:"Describe your product",
    product_Previous_price:"Previous price",
    discount_rate:"Discount rate %",
    calculate:"Calculate",
    new_price:"New price",
    rate_placeholder:"Only number",
    date_start:"Start date",
    date_end:"End date",
    new_price_placeholder:"New Price",
    limit_product_discount:"During your trial period, you can create and publish a single sales or promotional product.",
    error_date:"Please select the date after:",
    error_end_date:"Please select another start date because out of range",
    discount_success:"Your sales or promotional product saved successfully",
    //Category
    text_category_product_warning:"Your category of products function as sub-products of your main product. With our trial, basic, standard, and premium plans, you receive complimentary categories that are valid until the same day as your main product. For expanded access to additional categories, available to potential buyers, a nominal subscription fee is required.",
    category_success:"Your category of product saved successfully",
    limit_category:"You're entitled to one category of product in trial plan.",
    edit_category_success:"Your category of product edited successfully",
    text_category_warning:"Are you sure you want to delete your category of product ? This action will delete it for ever and all data belonging to this category.",
    error_data:"Your query is not available.",
    //
    product:"Product",
    action:"Action",
    //
    code_pin_texte:"Please enter your plan code pin without space and confirm",
    code_pin:"Code Pin",
    codepin_placeholder:"Your Code Pin",
    open:"Open",
    //subscibe seller
    error_connection:"Please login before accessing to subscibe session.",
    //
    text_subscribe_warning:"It seems that you've not created your 28 days free subscriber account. Do you want to do it now?",
    ok_create:"Yes",
    not_now:"Later",
    //subscription expired
    text_expired_warning:"Your subscription is expired. Please, reach your near reseller to topup and renew your subscription plan to increase your visibility and sales.",
    //
    start_subscribe:"Activate",
    user_code:"Seller Code",
    plan_pincode:"Subscription Plan",
    type_plan:"Trial subscription",
    success_subscribe:"Your free trial subscription created successfully.",
    //
    text_notexpired_warning:"Your subscription is not expired. Wait until the day after the expired date to renew your subscription. Expiration Date:",
    //
    error_codepin:"This Pin Code entered is not valid, try again with the right one that has been sent after your top up.",
    //
    success_subscription:"Your subscription has been reniewed successfully. It will end on date:",
    //
    subscribe_event:"Activate Event",
    type_plan_event:"Special Event",
    //
    dash_private_seller_chats:"Private Chat",
    dash_seller_group_chats:"Group Chat",
    //chat messages
    dash_meetings:"Meetings",
    chat_admin:"Executive Management",
    chat_country:"Country Representative",
    chat_city:"City Manager",
    chat_reseller:"Reseller Agent",
    //
    chat_group_admin:"Admin's Group",
    chat_group_country:"Country's Group",
    chat_group_city:"City's Group",
    chat_group_reseller:"Reseller's Group",
    //
    private_seller_chat:"Clicking Access opens your whatsaap account and allow you to view all messages from your buyers, chat with them or make calls to discuss about their inquiries for setting better sells and satisfy their needs.",
    meeting_info:"All meetings are set on a specific day and time. Check into Notification to see when it will take place. All meetings will be active 30 minutes before it starts. Click the relative type of meeting to join the meeting room if your are invited and confirmed.",
    meeting_info_group:"All meetings are set on a specific day and time. Check into Notification to see when it will take place. All meetings will be active 30 minutes before it starts. Click the relative type of meeting to join the meeting room if your are invited and confirmed.",
    open_meeting:"Access",
    close_meeting:"Close",
    //
    chat_Seller:"Seller",
    chat_group_seller:"Seller's Group",
    //notifications
    title:"Title",
    content_notify:"Notification Content",
    due_date:"Date due",
    due_time:"Time Due",
    status_notify:"Notification Status",
    status_offline:"Offline",
    status_online:"Online",
    level_notify:"Notification Level",
    select_level:"Select Level",
    level_urgent:"Urgent",
    level_normal:"Normal",
    state_notify:"Notification State",
    select_state:"Select State",
    state_waiting:"Waiting",
    state_confirmed:"Confirmed",
    notifiy:"Notify",
    activate:"Activate",
    //
    notify_success:"Target successfully notified",
    already_notified:"This target already be notified",
    //
    dash_schedule:"Meetings",
    //
    schedule_activate:"Activate Meetings",
    schedule_edit:"Edit Meetings",
    schedule_delete:"Delete Meetings",
    schedule_view:"View Meetings",
    //
    error_meeting:"There is no meeting set for today",
    success_delete:"This Column has been sucessfully deleted.",
    success_pause:"This Column has been sucessfully desabled.",
    //
    list_schedule:"Meeting List",
    //call
    whatsapp_call:"Call",
    //
    phone_number:"Whatsapp",
    //Buyers
    info_new_product:"New Online sellers by catagories of products",
    filter_search_texte1:"Filter your search if needed to get the most or simply click OK to generate all views on that product.",
    //
    error_detail:"The seller hasn't published nothing about this content.",
    //
    localisation:"Geographical location",
    //autocomplete search errors
    error_suggestion:"Sorry, No result for this search query. Please check the spelling and try again. If the word you're searching contains an apostrophe, please write it with two apostrophes. For instance, if you're searching for N'gattakro, enter it as N''gattakro in the search field.",
    //
    please_connect:"Please login first.",
    //
    error_contact:"The seller has not allowed buyers to call and chat.",
    //
    close_page:"Do you want to close this page?",
    yes:"Yes",
    no:"No",
    //notifications
    notification_content:"Here are all events and promotional activities. Tap on to view or skip to continue your tasks.",
    event_text:"Events",
    promotion_text:"Promotions",
    skip_text:"Skip",
    //special events
    subscribe_special:"Activate Special Event",
    dash_events_topup:"Events Topup",
    dash_new_events:"Create Event",
    dash_edit_events:"Edit Event",
    dash_delete_events:"Delete Event",
    dash_view_events:"View Event",
    //
    event_logo:"Event Logo",
    upload_logo:"Upload Logo",
    event_name:"Event Title",
    eventname_placeholder:"Enter Title",
    event_description:"Describe Event",
    the_event_description:"Describe briefly your Event",
    event_video:"Event Video",
    upload_video:"Upload Video",
    //
    unsupported_browser:"Sorry, your browser does not support embedded videos.",
    //
    error_limit:"Error loading the video.",
    alert_duration:"Video duration exceeds the allowed limit. Please choose a video with a duration of 2 minutes or less.",
    //
    text_event_warning:"This action will delete all your advertise's content and not available for your covered period. You should rewrite it again to be online before date expires. Are you sure to delete advert?",
    //
    create_event_warning:"Your advert not exists. Do you want to create it now?",
    //
    error_event:"You can't go further because you haven't set up your event/advert subscription. Please do it before.",
    //
    application_text:"Softwares",
    //
    text_by_country:"Do you want to make search for another country?",
    //
    search_country:"Search Country: ",
    //landing page slogan
    slogan_one:"ECOWAS Buyers and Sellers",
    slogan_two:"Interconnect, and Prosper Together!",
    //offline warning
    offline_connection:"Oops no internet.Try to reconnect again before.",
    //error event promotion application
    error_events:"No events available at the moment.",
    error_promotions:"No sellers promotions available at the moment.",
    error_applications:"No software available at the moment.",
    //
    product_text:"Recent Sells",
}
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//import country button
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//country flags
import Benin from '../countries/countryflag/benin_flag.jpg';
import Burkina from '../countries/countryflag/burkina_flag.png';
import Guinee from '../countries/countryflag/guinee_flag.jpg';
import Ivoire from '../countries/countryflag/ivoire_flag.png';
import Mali from '../countries/countryflag/mali_flag.jpg';
import Niger from '../countries/countryflag/niger_flag.png';
import Senegal from '../countries/countryflag/senegal_flag.png';
import Togo from '../countries/countryflag/togo_flag.png';
//
import { retrieveCodeAndName } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { HideFooter, AlertModal } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
import PopUpForwardButton from '../reusablecomponents/buttons/PopUpForwardButton';

//
import { 
    PrincipalScroller,
    //PrincipalIntro, 
    PrincipalSubTitle,
    HandlerContainerDiv,
} from '../ComponentStyles';

function FrenchCountries() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertfrenchcountries";
    const elementDropId = "backdropoutalertfrenchcountries";
    const buttonHandler = "ouiHandlerAlertFrenchCountriesBtn";

    const navigateFrenchCountries = useNavigate();

    //benin
    const handleBenin = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "benin";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateFrenchCountries('/starting');
            HideFooter();
        }
    }

    //burkina
    const handleBurkina = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "burkina";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateFrenchCountries('/starting');
            HideFooter();
        }
    }

    //guinee-conakty
    const handleGuinee = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "guinee";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateFrenchCountries('/starting');
            HideFooter();
        }
    }

    //ivoire
    const handleIvoire = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "ivoire";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateFrenchCountries('/starting');
            HideFooter();
        }
    }

    //mali
    const handleMali = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "mali";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateFrenchCountries('/starting');
            HideFooter();
        }
    }

    //niger
    const handleNiger = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "niger";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateFrenchCountries('/starting');
            HideFooter();
        }
    }

    //senegal
    const handleSenegal = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "senegal";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateFrenchCountries('/starting');
            HideFooter();
        }
    }

    //togo
    const handleTogo = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const frenchCountry = "togo";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = frenchCountry;
        countryInfo = retrieveCodeAndName(frenchCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateFrenchCountries('/starting');
            HideFooter();
        }
    }

    const goBackHandler = async () => {

        navigateFrenchCountries('/');
        //window.location.reload(false);
        document.getElementById('selectlangue').selectedIndex = 0;
    }

    const goNextHandler = async () => {
        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const namecountryDiv = document.getElementById('namecountry').innerText;
        const frenchCountry = namecountryDiv;
        //
        if(!namecountryDiv){
            if(!choixlangueDiv){
            const storedLangue = localStorage.getItem('i18nextLng');
            document.getElementById('choixlangue').innerText = storedLangue;
            if(!storedLangue){
                const pTextePays = t("principaltexte1");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                if(!frenchCountry){
                    const pTextePays = t("principaltexte2");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    navigateFrenchCountries('/starting');
                }
            }
        }else{
            if(!frenchCountry){
                const pTextePays = t("principaltexte2");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else if(!choixlangueDiv){
                const pTextePays = t("principaltexte1");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                navigateFrenchCountries('/starting');
            }
        }
        }else{
            navigateFrenchCountries('/starting');
        }
    }

    return (
      <div id='frenchcountries'>
            <PrincipalScroller>
                <br/>
                <PrincipalSubTitle>{t("principaltexte2")}</PrincipalSubTitle>
                {/*<br/>
                <PrincipalIntro>{t("select_country_texte1")}</PrincipalIntro>*/}
                <br/>
                <div style={{display:'block',textAlign:'center'}}>
                    <CountryButton 
                        url={Benin}
                        countryName="Bénin"
                        onConfirm={handleBenin}
                    />
                    <CountryButton 
                        url={Burkina}
                        countryName="Burkina Faso"
                        onConfirm={handleBurkina}
                    />
                    <CountryButton 
                        url={Guinee}
                        countryName="Guinée-Conakry"
                        onConfirm={handleGuinee}
                    />
                    <CountryButton 
                        url={Ivoire}
                        countryName="Côte d'Ivoire"
                        onConfirm={handleIvoire}
                    />
                    <CountryButton 
                        url={Mali}
                        countryName="Mali"
                        onConfirm={handleMali}
                    />
                    <CountryButton 
                        url={Niger}
                        countryName="Niger"
                        onConfirm={handleNiger}
                    />
                    <CountryButton 
                        url={Senegal}
                        countryName="Sénégal"
                        onConfirm={handleSenegal}
                    />
                    <CountryButton 
                        url={Togo}
                        countryName="Togo"
                        onConfirm={handleTogo}
                    />
                </div>
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackHandler}></PopUpBackButton>
                    </div>
                    <div>
                        <PopUpForwardButton onClick={goNextHandler}></PopUpForwardButton>
                    </div>
                </HandlerContainerDiv>
                <br/>
                <div id="modaloutalertfrenchcountries"></div>
                <div id="backdropoutalertfrenchcountries"></div>
            </PrincipalScroller>
      </div>
    )
}

export default FrenchCountries
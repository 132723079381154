import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//
import FooterStarting from '../footer/FooterStarting';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
//
import { BiShow } from 'react-icons/bi';
//
import { HideFooter, AlertModal, formatDateData, differenceTwoDates } from '../../helperfunctions/Helper';
import WarningMultiModal from '../reusablecomponents/componentutils/WarningMultiModal';
import WarningModal from '../reusablecomponents/componentutils/WarningModal';

//
import { retrieveTableQuery } from '../../helperfunctions/utilityfunctions/CountryUtils';
import Axios from 'axios';
import apiUrl from '../../apiconfig/ApiConfig';

//
import { 
    TermeScroller, 
    FooterSubTitle,
    FooterIntro,
    LargeInput,
    PasswordInput,
    TextLogin, 
    HandlerContainerDiv, 
    ModalUser,
    ConfirmButton,
    CancelButton,
} from '../ComponentStyles';

function SellerLogin() {
    HideFooter();
    
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertsellerlogin";
    const elementDropId = "backdropoutalertsellerlogin";
    const buttonHandler = "ouiHandlerAlertSellerLoginBtn";

    const navigateSellerLogin = useNavigate();

    const userPhoneRef = useRef(null);
    const userPasswordRef = useRef(null);

    const [showPassword, setShowPassword] = useState(false);
    //
    const [showModal, setShowModal] = useState(false);
    const [showModalExpired, setShowModalExpired] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
        setShowModal(false);
        setShowModalExpired(false);
    };

    const handleUserLogin = async (e) => {
        e.preventDefault();

        //clear usercode utilisateur
        var divClearCode = document.getElementById('codeutilisateur');
        while(divClearCode.hasChildNodes()) {
            divClearCode.removeChild(divClearCode.childNodes[0]);
        }
        //clear usernom utilisateur
        var divClearNom = document.getElementById('usernom');
        while(divClearNom.hasChildNodes()) {
            divClearNom.removeChild(divClearNom.childNodes[0]);
        }
        //clear typeutilisateurDiv utilisateur
        var divClearUtilisateur = document.getElementById('typeutilisateur');
        while(divClearUtilisateur.hasChildNodes()) {
            divClearUtilisateur.removeChild(divClearUtilisateur.childNodes[0]);
        }
        //clear category utilisateur
        var divClearCategory = document.getElementById('categoryutilisateur');
        while(divClearCategory.hasChildNodes()) {
            divClearCategory.removeChild(divClearCategory.childNodes[0]);
        }

        var tableName = "";
        var signupTable = [];
        var checkResultat = [];
        var resultat = 0;
        var userName = "";
        var codeUser = "";
        var roleUser = "";
        var typeutilisateur = "";
        //
        var tableNameSubscribe = "";
        var signupTableSubscribe = [];
        var checkResultat1 = [];
        var resultat1 = 0;
        var endDate = "";
        //
        //const dateToDay = getCurrentDate();
        //
        const userPhone = userPhoneRef.current.value;
        const userPassword = userPasswordRef.current.value;
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //
        const codeutilisateurDiv = document.getElementById('codeutilisateur');
        const usernomDiv = document.getElementById('usernom');
        const typeutilisateurDiv = document.getElementById('typeutilisateur');
        const categoryutilisateurDiv = document.getElementById('categoryutilisateur');
        //retrieve table1 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table2;
        }
        //
        signupTableSubscribe = retrieveTableQuery(codecountryDiv);
        if(signupTableSubscribe){
            tableNameSubscribe = signupTableSubscribe.table.table10;
        }
        const firstWhere = "code_user";
        //
        if(!userPhone){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!userPassword){
            const pTextePays = t("please_number");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else{
            await Axios.post(`${apiUrl}/seller/connexion`, {
                userPhone:userPhone,
                userPassword:userPassword,
                tableNameSeller:tableName,
            }).then((response) => {
                resultat = response.data.length;
                checkResultat = response.data;
                if(resultat === 0){
                    const pTextePays = t("error_login");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    for(let i=0; i<resultat; i++){
                        userName = checkResultat[i].nom;
                        codeUser = checkResultat[i].code_user;
                        roleUser = checkResultat[i].sigle;
                    }
                    codeutilisateurDiv.innerText = codeUser;
                    usernomDiv.innerText = userName;
                    typeutilisateurDiv.innerText = roleUser;
                    if(roleUser === 'seller'){
                        typeutilisateur = t("seller");
                    }else{
                        typeutilisateur = t("buyer");
                    }
                    categoryutilisateurDiv.innerText = typeutilisateur;
                    //check in subscribe table if this user code already exist
                    Axios.post(`${apiUrl}/check/one/parameter/table`, {
                        tableNameSeller:tableNameSubscribe,
                        firstWhere:firstWhere,
                        firstParameter:codeUser,
                    }).then((response1) => {
                        resultat1 = response1.data.length;
                        checkResultat1 = response1.data;
                        if(resultat1 === 0){
                            //set the modal that let seller to create subscriber account
                            setShowModal(true);
                            setShowModalExpired(false);
                        }else{
                            //check and compare date end to date today
                            //if date today > date end
                            for (let j=0; j<resultat1; j++){
                                endDate = formatDateData(checkResultat1[j].date_end);
                            }
                            //
                            var resultDiference = differenceTwoDates(endDate);
                            if(resultDiference < 0){
                                setShowModal(false);
                                setShowModalExpired(true);
                                //navigateSellerLogin('/dashboardseller');
                            }else{
                                navigateSellerLogin('/dashboardseller');
                            }
                        }
                    });
                }
            });

        }
    }

    //subscribe decision
    const handleModalSubscribe = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        navigateSellerLogin('/sellersubscribe');
    }

    const handleModalNotNow = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        navigateSellerLogin('/dashboardseller');
    }

    const handleCancelModal = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
    }

    //subscribe expired
    const handleModalExpired = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
        navigateSellerLogin('/dashboardseller');
    }

    const handleCancelModalExpired = async (e) => {
        e.preventDefault();

        setShowModal(false);
        setShowModalExpired(false);
    }

    const handleUserClose = async (e) => {
        e.preventDefault();

        navigateSellerLogin(-1);
    }

    const handleGoBack = async () => {
        navigateSellerLogin(-1);
        //
        document.getElementById('selectlangue').selectedIndex = 0;
        document.getElementById('namecountry').innerText = "";
        document.getElementById('codecountry').innerText = "";
        document.getElementById('codeutilisateur').innerText = "";
        document.getElementById('usernom').innerText = "";
        document.getElementById('typeutilisateur').innerText = "";
    }

    return (
        <div id='sellerlogin'>
            <TermeScroller>
                <br/>
                <div style={{textAlign:'center'}}>
                    <FooterSubTitle>{t("seller")} {t("subscriberUser")}</FooterSubTitle>
                </div>
                <FooterIntro>{t("seller_section_1")}</FooterIntro>
                <br/>
                <ModalUser>
                    <br/>
                    <TextLogin>{t("buyertexte16")}</TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <LargeInput type='number' ref={userPhoneRef} placeholder={t("buyertexte17")} />
                    </div>
                    <br/>
                    <TextLogin>Password</TextLogin>
                    <div style={{display:'flex', flexDirection:'row', marginLeft:'10px'}}>
                        <div style={{display:'block',textAlign:'center'}}>
                            <PasswordInput type={showPassword ? 'text' : 'password'} ref={userPasswordRef} placeholder={t("buyertexte19")} />
                        </div>
                        <div>
                            <BiShow style={{color:'#246175', fontSize:'35px'}} onClick={togglePasswordVisibility}>
                                {showPassword ? 'Hide' : 'Show'}
                            </BiShow>
                        </div>
                    </div>
                    <br/>
                    <HandlerContainerDiv>
                        <div>
                            <ConfirmButton onClick={handleUserLogin}>{t("singinUser")}</ConfirmButton>
                        </div>
                        <div>
                            <CancelButton onClick={handleUserClose}>{t("cancel_button")}</CancelButton>
                        </div>
                    </HandlerContainerDiv>
                    <br/>
                </ModalUser>
                <br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={handleGoBack} ></PopUpBackButton>
                    </div>
                </HandlerContainerDiv>
                {showModal && (
                    <WarningMultiModal
                        text={t("text_subscribe_warning")}
                        textOk={t("ok_create")}
                        textPause={t("not_now")}
                        textNo={t("no_cancel")}
                        onConfirm={handleModalSubscribe}
                        onPause={handleModalNotNow}
                        onCancel={handleCancelModal}
                    />
                )}
                {showModalExpired && (
                    <WarningModal
                        text={t("text_expired_warning")}
                        textOk="OK"
                        textNo={t("no_cancel")}
                        onConfirm={handleModalExpired}
                        onCancel={handleCancelModalExpired}
                    />
                )}

                <FooterStarting />
                <br/><br/>
                <div id="modaloutalertsellerlogin"></div>
                <div id="backdropoutalertsellerlogin"></div>
            </TermeScroller>
        </div>
    )
}

export default SellerLogin
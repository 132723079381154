import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import planData from './planData.json';
//helperfunction
//import { ShowFooter, /*useLanguageSelection*/ } from '../../../../helperfunctions/Helper';
//
//import GobackBouton from '../../../reusablecomponents/buttons/GobackBouton';

//
import { 
    FooterSubTitle,
    ModalUser,
    //TermeScroller, 
    HandlerContainerDiv,
    FaqContainer, 
    FaqQuestion, 
    FaqAnswer, 
    FaqAnswerContent, 
    BazaliButton,
} from '../../../ComponentStyles';

const FAQ_PLAN = {
    BASIC: 'basic',
};

function BasicPlan() {
    const {t} = useTranslation();

    const [selectedCategory, setSelectedCategory] = useState(FAQ_PLAN.BASIC);
    const [expandedQuestion, setExpandedQuestion] = useState(null);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setExpandedQuestion(null);
    };

    const toggleQuestion = (index) => {
        setExpandedQuestion((prevIndex) => (prevIndex === index ? null : index));
    };

    const filteredFAQs = planData.filter((faq) => faq.category === selectedCategory);

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_basic_plan")}</FooterSubTitle>
                <br/>
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <BazaliButton onClick={() => handleCategoryChange(FAQ_PLAN.BASIC)}>{t("seller")}</BazaliButton>
                    </div>
                </HandlerContainerDiv>
                {/**basic output */}
                <FaqContainer>
                    {filteredFAQs.map((faq, index) => (
                        <React.Fragment key={index}>
                            <FaqQuestion 
                                onClick={() => toggleQuestion(index)}
                                className={`FaqQuestion ${expandedQuestion === index ? 'active' : ''}`}
                            >
                                {faq.question[t('languageInitials')]}
                            </FaqQuestion>
                            {expandedQuestion === index && (
                                <FaqAnswer className={`FaqAnswer ${expandedQuestion === index ? 'active' : ''}`}>
                                    <FaqAnswerContent>{faq.answer[t('languageInitials')]}</FaqAnswerContent>
                                </FaqAnswer>
                            )}
                        </React.Fragment>
                    ))}
                </FaqContainer>
            </ModalUser>
        </div>
    )
}

export default BasicPlan
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
//
import { MdModeEdit } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { retrieveTableQuery } from '../../../../helperfunctions/utilityfunctions/CountryUtils';
//
import { AlertModal, LimitCharacters, /*getCurrentDate*/ } from '../../../../helperfunctions/Helper';
//
import CrudSettings from './CrudSettings';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    SmallTextLogin,
    ModalUser,
    SmallInput,
    SmallSelectBox,
    SmallLanguageOption,
    SmallTextArea,
    SmallHandlerContainerDiv,
    SmallEditButton,
} from '../../../ComponentStyles';

function EditProfile() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalerteditprofile";
    const elementDropId = "backdropoutalerteditprofile";
    const buttonHandler = "ouiHandlerAlertEditProfileBtn";

    // Define state variables for modals
    const [startEditProfileModalOpen, setStartEditProfileModalOpen] = useState(true);
    const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
    //
    const [crudSettingsModalOpen, setCrudSettingsModalOpen] = useState(false);

    const [editDetailProfile, setEditDetailProfile] = useState([0]);

    const idEditProfileRef = useRef(null);
    const editProfileVillageRef = useRef(null);
    const editProfileQuartierRef = useRef(null);
    const editSelectSectorRef = useRef(null);
    const editProfilePrincipalRef = useRef(null);
    const editProfileLocationRef = useRef(null);
    const editSelectModeRef = useRef(null);
    const editProfileDescritptionRef = useRef(null);
    const editSelectContactRef = useRef(null);

    //Limit the total number of characters (not words)
    //var characterLimit = 1000; // Set the character limit to 1000
    var locationWords = 0;
    var descriptionWords = 0;
    var newCharacterLimitLocation = 1000 - locationWords;
    var newCharacterLimitDescription = 1000 - descriptionWords;
    const [textLocation, setTextLocation] = useState('');
    const [textDescription, setTextDescription] = useState('');
    //limit characters for location
    const handleChangeLocation = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, newCharacterLimitLocation);
        setTextLocation(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersLocation = newCharacterLimitLocation - textLocation.length;

    //limit characters for description
    const handleChangeDescription = (e) => {
        const inputText = e.target.value;
    
        const limitedText = LimitCharacters(inputText, newCharacterLimitDescription);
        setTextDescription(limitedText);
    };
    // Calculate the remaining characters based on the character limit and the current text length
    const remainingCharactersDescription = newCharacterLimitDescription - textDescription.length;

    const handleStartProfile = async () => {

        var tableName = "";
        var signupTable = [];
        var resultat1 = '';
        var checkResultat = [];

        const codeutilisateurDiv = document.getElementById('codeutilisateur').innerText;
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table3;
        }

        await Axios.post(`${apiUrl}/edit/profile/details`, {
            tableNameSeller:tableName,
            codeUtisateur:codeutilisateurDiv,
            codeCountry:codecountryDiv,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                checkResultat = response1.data;
                //retrieve location words length
                for(let i=0; i<resultat1; i++){
                    locationWords = (checkResultat[i].localisation).length;
                    descriptionWords = (checkResultat[i].profile_description).length;
                }
                setEditDetailProfile(response1.data);
                setStartEditProfileModalOpen(false);
                setEditProfileModalOpen(true);
            }
        });
    }

    const handleEditProfile = async (e) => {
        e.preventDefault();

        var tableName = "";
        var signupTable = [];
        //var resultat1 = '';

        const idEditProfile = idEditProfileRef.current.value;
        const editProfileVillage = editProfileVillageRef.current.value;
        const editProfileQuartier = editProfileQuartierRef.current.value;
        const editSelectSector = editSelectSectorRef.current.value;
        const editProfilePrincipal = editProfilePrincipalRef.current.value;
        const editProfileLocation = editProfileLocationRef.current.value;
        const editSelectMode = editSelectModeRef.current.value;
        const editProfileDescritption = editProfileDescritptionRef.current.value;
        const editSelectContact = editSelectContactRef.current.value;
        //
        const codecountryDiv = document.getElementById('codecountry').innerText;
        //retrieve table2 to use in backend
        signupTable = retrieveTableQuery(codecountryDiv);
        if(signupTable){
            tableName = signupTable.table.table3;
        }

        if(!editSelectSector){
            const pTextePays = t("profile_sector_select");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editProfilePrincipal){
            const pTextePays = t("profile_principal");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editProfileLocation){
            const pTextePays = t("profile_location");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editSelectMode){
            const pTextePays = t("select_profile_mode");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
        }else if(!editProfileDescritption){
            const pTextePays = t("profile_description");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else if(!editSelectContact){
            const pTextePays = t("profile_mode_contact");
            AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler); 
        }else{
            await Axios.post(`${apiUrl}/edit/seller/profile`, {
                tableNameSeller:tableName,
                idEditProfile:idEditProfile,
                editProfileVillage:editProfileVillage,
                editProfileQuartier:editProfileQuartier,
                editSelectSector:editSelectSector,
                editProfilePrincipal:editProfilePrincipal,
                editProfileLocation:editProfileLocation,
                editSelectMode:editSelectMode,
                editProfileDescritption:editProfileDescritption,
                editSelectContact:editSelectContact,
            }).then(() => {
                const pTextePays = t("buyer_success");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                setCrudSettingsModalOpen(false);
                setEditProfileModalOpen(false);
                setStartEditProfileModalOpen(true);
            });
        }
    }

    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_edit_profile")}</FooterSubTitle>
                <br/>
                {startEditProfileModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartProfile}
                        />
                    </div>
                )}
                <br/>
                {editProfileModalOpen && (
                    <div>
                        {editDetailProfile.map((valeditdetail, indexeditdetail) => (
                            <ModalUser key={indexeditdetail}>
                                <input style={{display:'none'}} ref={idEditProfileRef} defaultValue={valeditdetail.id_profile} />
                                <SmallTextLogin>{t("profile_ville")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' defaultValue={valeditdetail.city_user} readOnly />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_village")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={editProfileVillageRef} defaultValue={valeditdetail.village} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_quartier")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={editProfileQuartierRef} defaultValue={valeditdetail.quartier} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_sector")}</SmallTextLogin>
                                <SmallSelectBox ref={editSelectSectorRef} >
                                    <SmallLanguageOption value="">{t('profile_sector_select')}</SmallLanguageOption>
                                    <SmallLanguageOption value="all">{t('cat_all')}</SmallLanguageOption>
                                    <SmallLanguageOption value="food">{t('cat_food')}</SmallLanguageOption>
                                    <SmallLanguageOption value="beverage">{t('cat_beverage')}</SmallLanguageOption>
                                    <SmallLanguageOption value="apparel">{t('cat_apparel')}</SmallLanguageOption>
                                    <SmallLanguageOption value="health">{t('cat_health')}</SmallLanguageOption>
                                    <SmallLanguageOption value="beauty">{t('cat_beauty')}</SmallLanguageOption>
                                    <SmallLanguageOption value="services">{t('cat_services')}</SmallLanguageOption>
                                    <SmallLanguageOption value="electronic">{t('cat_electronic')}</SmallLanguageOption>
                                    <SmallLanguageOption value="automotive">{t('cat_automotive')}</SmallLanguageOption>
                                    <SmallLanguageOption value="agriculture">{t('cat_agriculture')}</SmallLanguageOption>
                                    <SmallLanguageOption value="elevage">{t('cat_breeding')}</SmallLanguageOption>
                                    <SmallLanguageOption value="elevage">{t('cat_breeding')}</SmallLanguageOption>
                                    <SmallLanguageOption value="house">{t('cat_house')}</SmallLanguageOption>
                                    <SmallLanguageOption value="sport">{t('cat_sport')}</SmallLanguageOption>
                                    <SmallLanguageOption value="decor">{t('cat_decor')}</SmallLanguageOption>
                                </SmallSelectBox>
                                <br/>
                                <SmallTextLogin>{t("profile_principal")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallInput type='text' ref={editProfilePrincipalRef} defaultValue={valeditdetail.profile_principal} />
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_location")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        value={textLocation || valeditdetail.localisation}
                                        onChange={handleChangeLocation}
                                        ref={editProfileLocationRef}
                                        //defaultValue={valeditdetail.localisation}
                                        maxLength={newCharacterLimitLocation} // Set the maxlength attribute
                                    />
                                    <div>
                                        {remainingCharactersLocation >= 0
                                        ? `${remainingCharactersLocation} ${t('characters_remaining')}`
                                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersLocation)}`}
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_mode")}</SmallTextLogin>
                                <SmallSelectBox ref={editSelectModeRef} >
                                    <SmallLanguageOption value="">{t('select_profile_mode')}</SmallLanguageOption>
                                    <SmallLanguageOption value="cash">{t('profile_cash')}</SmallLanguageOption>
                                    <SmallLanguageOption value="online">{t('profile_online')}</SmallLanguageOption>
                                    <SmallLanguageOption value="correspondance">{t('profile_correspondance')}</SmallLanguageOption>
                                    <SmallLanguageOption value="order">{t('profile_order')}</SmallLanguageOption>
                                    <SmallLanguageOption value="commission">{t('profile_commission')}</SmallLanguageOption>
                                    <SmallLanguageOption value="affiliate">{t('profile_affiliate')}</SmallLanguageOption>
                                    <SmallLanguageOption value="all">{t('cat_all')}</SmallLanguageOption>
                                </SmallSelectBox>
                                <br/>
                                <SmallTextLogin>{t("profile_description")}</SmallTextLogin>
                                <div style={{display:'block',textAlign:'center'}}>
                                    <SmallTextArea 
                                        type='text' 
                                        value={textDescription || valeditdetail.profile_description}
                                        onChange={handleChangeDescription}
                                        ref={editProfileDescritptionRef} 
                                        //defaultValue={valeditdetail.profile_description}
                                        maxLength={newCharacterLimitDescription} // Set the maxlength attribute
                                    />
                                    <div>
                                        {remainingCharactersDescription >= 0
                                        ? `${remainingCharactersDescription} ${t('characters_remaining')}`
                                        : `${t('Exceeded_character')} ${Math.abs(remainingCharactersDescription)}`}
                                    </div>
                                </div>
                                <br/>
                                <SmallTextLogin>{t("profile_contact")}</SmallTextLogin>
                                <SmallSelectBox ref={editSelectContactRef} >
                                    <SmallLanguageOption value="">{t('profile_mode_contact')}</SmallLanguageOption>
                                    <SmallLanguageOption value="private">{t('profile_private')}</SmallLanguageOption>
                                    <SmallLanguageOption value="public">{t('profile_public')}</SmallLanguageOption>
                                    <SmallLanguageOption value="all">{t('cat_all')}</SmallLanguageOption>
                                </SmallSelectBox>
                                <br/>
                                <SmallHandlerContainerDiv>
                                    <div>
                                        <SmallEditButton onClick={handleEditProfile}>
                                            <span style={{ fontSize:'30px'}}><MdModeEdit /></span>
                                            {t("edit")}
                                        </SmallEditButton>
                                    </div>
                                </SmallHandlerContainerDiv>
                            </ModalUser>
                        ))}
                    </div>
                )}
                <br/><br/>
                <div id="modaloutalerteditprofile"></div>
                <div id="backdropoutalerteditprofile"></div>
            </ModalUser>
            {/**CrudSettings */}
            {crudSettingsModalOpen && (
                <CrudSettings />
            )}
        </div>
    )
}

export default EditProfile
/*function TrieNode(letter){
    this.letter = letter;
    this.prevLetter = null;
    this.nextLetters = {};
    this.isComplete = false;

    this.getWord = getWord;

    function getWord(){
        var node = this;
        var wordLetters = [];
        while (node.prevLetter){
            wordLetters.unshift(node.letter);
            node = node.prevLetter;
        }
        return wordLetters.join('');
    }
}*/

class TrieNode {
    constructor(letter) {
        this.letter = letter;
        this.prevLetter = null;
        this.nextLetters = {};
        this.isComplete = false;
    }

    getWord() {
        let node = this;
        const wordLetters = [];
        while (node.prevLetter) {
            wordLetters.unshift(node.letter);
            node = node.prevLetter;
        }
        return wordLetters.join('');
    }
}

/*function Trie(){
    this.root = new TrieNode(null);

    this.insert = insert;
    this.contains = contains;
    this.find = find;

    function insert(word){
        var node = this.root;
        for (let i = 0; i < word.length; i++){
            const current_letter = word[i];
            if (!node.nextLetters[current_letter]){
                node.nextLetters[current_letter] = new TrieNode(current_letter);
                node.nextLetters[current_letter].prevLetter = node;
            }
            node = node.nextLetters[current_letter];
            if (i === word.length - 1){
                node.isComplete = true;
            }
        }
    }

    function contains(word){
        var node = this.root;
        for (let i = 0; i < word.length; i++){
            const current_letter = word[i];
            let next_node = node.nextLetters[current_letter];
            if (next_node){
                node = next_node;
            }else{
                return false;
            }
        }
        return node.isComplete;
    }

    function find(clue_letters){
        var node = this.root;
        var output = [];
        for (let i = 0; i < clue_letters.length; i++){
            const clue_letter = clue_letters[i];
            let next_node = node.nextLetters[clue_letter];
            if (next_node){
                node = next_node;
            }else{
                return output;
            }
        }
        findAllWords(node, output);
        return output;
    }

    function findAllWords(node, arr){
        if (node.isComplete){
            arr.unshift(node.getWord());
        }

        for (var next_letter in node.nextLetters){
            findAllWords(node.nextLetters[next_letter], arr);
        }
    }
}*/

class Trie {
    constructor() {
        this.root = new TrieNode(null);
    }

    insert(word) {
        let node = this.root;
        for (let i = 0; i < word.length; i++) {
            const currentLetter = word[i];
            if (!node.nextLetters[currentLetter]) {
                node.nextLetters[currentLetter] = new TrieNode(currentLetter);
                node.nextLetters[currentLetter].prevLetter = node;
            }
            node = node.nextLetters[currentLetter];
            if (i === word.length - 1) {
                node.isComplete = true;
            }
        }
    }

    contains(word) {
        let node = this.root;
        for (let i = 0; i < word.length; i++) {
            const currentLetter = word[i];
            const nextNode = node.nextLetters[currentLetter];
            if (nextNode) {
                node = nextNode;
            } else {
                return false;
            }
        }
        return node.isComplete;
    }

    find(clueLetters) {
        let node = this.root;
        const output = [];
        for (let i = 0; i < clueLetters.length; i++) {
            const clueLetter = clueLetters[i];
            const nextNode = node.nextLetters[clueLetter];
            if (nextNode) {
                node = nextNode;
            } else {
                return output;
            }
        }
        this.findAllWords(node, output);
        return output;
    }

    findAllWords(node, arr) {
        if (node.isComplete) {
            arr.unshift(node.getWord());
        }

        for (const nextLetter in node.nextLetters) {
            this.findAllWords(node.nextLetters[nextLetter], arr);
        }
    }
}
  
export default Trie;
import React, { /*useRef,*/ useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
//
import OpenButton from '../../../reusablecomponents/buttons/OpenButton';
import { MdPreview } from 'react-icons/md';
import { FaBookOpen } from "react-icons/fa";
//
import { AlertModal } from '../../../../helperfunctions/Helper';

import apiUrl from '../../../../apiconfig/ApiConfig';
import Axios from 'axios';

//
import { 
    FooterSubTitle,
    FaqAnswerContent,
    SmallTextLogin,
    ModalUser,
    FormScrollerWidgetSm,
    TableWidgetSmTable,
    TableThead,
    ThWidgetSmTh,
    TableTbody,
    TableTd,
    TableButton,
    SmallHandlerContainerDiv,
    SmallEditButton,
} from '../../../ComponentStyles';

function ThirdTopUp() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertthird";
    const elementDropId = "backdropoutalertthird";
    const buttonHandler = "ouiHandlerAlertThirdBtn";

    // Define state variables for modals
    const [startThirdModalOpen, setStartThirdModalOpen] = useState(true);
    const [thirdTableModalOpen, setThirdTableModalOpen] = useState(false);
    const [thirdModalOpen, setThirdModalOpen] = useState(false);

    const [userThird, setUserThird] = useState([0]);
    const [thirdDetail, setThirdDetail] = useState([0]);

    //get localstorage language value
    const storedLangue = localStorage.getItem('i18nextLng'); 

    const handleStartThird = async () => {

        var tableName = "bazali_app";
        //var signupTable = [];
        var resultat1 = '';

        //const storedLangue = localStorage.getItem('i18nextLng'); 
        //const descriptionThird = `description_${storedLangue}`;
        const statusThird = "online";
        //
        await Axios.post(`${apiUrl}/third/topup/table`, {
            tableNameSeller:tableName,
            //descriptionThird:descriptionThird,
            //storedLangue:storedLangue,
            statusThird:statusThird,
        }).then((responsethird) => {
            resultat1 = responsethird.data.length;
            if(resultat1 === 0){
                const pTextePays = t("error_data");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                setUserThird(responsethird.data);
                setStartThirdModalOpen(false);
                setThirdTableModalOpen(true);
                setThirdModalOpen(false);
            }
        })
    }

    const handleThirdProduct = async (thirdId) => {

        var tableName = "bazali_app";
        //var signupTable = [];
        var resultat2 = '';

        var idThird = thirdId;
        //console.log(idThird);

        await Axios.post(`${apiUrl}/third/topup/detail`, {
            tableNameSeller:tableName,
            idThird:idThird,
        }).then((response2) => {
            resultat2 = response2.data.length;
            if(resultat2 === 0){
                const pTextePays = t("error_login");
                AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
            }else{
                
                setThirdDetail(response2.data);
                setStartThirdModalOpen(false);
                setThirdTableModalOpen(true);
                setThirdModalOpen(true);
            }
        });
    }

    
    return (
        <div>
            <ModalUser>
                <FooterSubTitle>{t("dash_third_topup")}</FooterSubTitle>
                <br/>
                {startThirdModalOpen && (
                    <div style={{float:'right'}}>
                        <OpenButton
                            iconLink={<FaBookOpen />}
                            openText={t("open")}
                            onConfirm={handleStartThird}
                        />
                    </div>
                )}

                {thirdTableModalOpen && (
                    <div>
                        <FormScrollerWidgetSm>
                            <br/>
                            <TableWidgetSmTable>
                                <TableThead>
                                    <tr>
                                        <ThWidgetSmTh>{t("product")}</ThWidgetSmTh>
                                        <ThWidgetSmTh>{t("action")}</ThWidgetSmTh>
                                    </tr>
                                </TableThead>
                                <TableTbody>
                                    {userThird.map((valthird, index) => {
                                        return(
                                            <tr key={index}>
                                                <TableTd>{valthird.name_app}</TableTd>
                                                <TableTd ><TableButton type="button" onClick={() => handleThirdProduct(valthird.id_app)}>{t("open")}</TableButton></TableTd>
                                            </tr>
                                        )
                                    })}
                                </TableTbody>
                            </TableWidgetSmTable>
                        </FormScrollerWidgetSm>
                    </div>
                )}
                <br/>
                {thirdModalOpen && (
                    <div>
                        {thirdDetail.map((valdetail, indexdetail) => (
                            <ModalUser key={indexdetail}>
                                <SmallTextLogin>{valdetail.name_app}</SmallTextLogin>
                                <br/>
                                <FaqAnswerContent>{valdetail[`description_${storedLangue}`]}</FaqAnswerContent>
                                <br/>
                                <SmallHandlerContainerDiv>
                                    <div>
                                        <SmallEditButton>
                                            <a style={{color:"white", textDecoration:"none"}} href={valdetail.link_app} target="_blank" rel="noopener noreferrer">
                                                <span style={{ fontSize:'30px'}}><MdPreview /></span>
                                                Demo
                                            </a>
                                        </SmallEditButton>
                                    </div>
                                </SmallHandlerContainerDiv>
                            </ModalUser>
                        ))}
                    </div>
                )}

                <br/><br/>
                <div id="modaloutalertthird"></div>
                <div id="backdropoutalertthird"></div>
            </ModalUser>
        </div>
    )
}

export default ThirdTopUp
import styled from 'styled-components';

export const GoBackButton = styled.button`
    border-radius: 4px;
    padding: 8px;
    background-color: #9dd0e1;
    color: white;
    border: 1px solid #ccc;
    border-bottom: 2px solid #3366ff;
    border-radius: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;
export const GoBackText = styled.span`
    margin-left: 4px;
`;

export const GoNextButton = styled.button`
    border-radius: 4px;
    padding: 8px;
    background-color: #63b4cf;
    color: white;
    border: 1px solid #ccc;
    border-bottom: 2px solid #3366ff;
    border-radius: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const GoNextButtonSearch = styled.button`
    border-radius: 4px;
    padding: 8px;
    background-color: green;
    color: white;
    border: 1px solid #ccc;
    border-bottom: 2px solid #3366ff;
    border-radius: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const GoNextText = styled.span`
    margin-right: 4px;
`;

export const GoSendButton = styled.button`
    border-radius: 4px;
    padding: 8px;
    background-color: #63b4cf;
    color: white;
    border: 1px solid #ccc;
    border-bottom: 2px solid #3366ff;
    border-radius: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;
export const GoSendText = styled.span`
    margin-right: 4px;
`;

//Normal styles***************
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const BigButtonCountry = styled.button`
    margin-top: 4%;
    display: flex;
    width: 340px;
    height: 50px;
    border-radius: 10px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    border: 2px solid  #2fb433cd;
    font-size: 18px;
    background-color: #4d4dff;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const CountryFlagImage = styled.div`
    grid-area: image;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-size: cover;
`;

export const CountryModalName = styled.p`
    margin-bottom: 20px;
    font-size:18px;
`;

export const OpenSideButton = styled.div`
    margin-top: 4%;
    display: flex;
    border-radius: 10px;
    width: 190px;
    height: 40px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    background-color: green;
    color: white;
    border: 1px solid #ccc;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const GoBackPopupButton = styled.button`
    padding: 8px;
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 50px;
    font-size: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: 0.4;

    &:hover {
        background-color: #e0e0e0;
    }

    position: fixed;
    left: 20px;
    top: 110px;
    z-index: 999;
`;

export const GoNextPopUpButton = styled.button`
    padding: 8px;
    background-color: black;
    color: white;
    border: 1px solid black;
    border-radius: 50px;
    font-size: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: 0.4;

    &:hover {
        background-color: #e0e0e0;
    }

    position: fixed;
    right: 20px;
    top: 110px;
    z-index: 999;
`;

export const InnerGoNextPopUpButton = styled.button`
    padding: 8px;
    background-color: blue;
    color: white;
    border: 1px solid blue;
    border-radius: 50px;
    font-size: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: 0.4;

    &:hover {
        background-color: #e0e0e0;
    }
`;

export const GoLikeButton = styled.button`
    padding: 8px;
    background-color: red;
    color: white;
    border: 1px solid red;
    border-radius: 50px;
    font-size: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: 0.8;

    &:hover {
        background-color: #e0e0e0;
    }

    position: fixed;
    left: 20px;
    top: 300px;
    z-index: 999;
`;
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "../../../../translations/i18n";
import { MdOutlineSave, MdModeEdit, MdDelete, MdPreview } from 'react-icons/md';
//
import CreateProfile from './CreateProfile';
import EditProfile from './EditProfile';
import DeleteProfile from './DeleteProfile';
import ViewProfile from './ViewProfile';

import { 
    FooterSubTitle,
    RightSideButton,
    ModalUser,
    SmallHandlerColContainerDiv,
} from '../../../ComponentStyles';

function CrudSettings() {
    const {t} = useTranslation();

    //div ref
    const crudSettingsRef = useRef(null);

    // Define state variables for modals
    const [dashCreateProfileModalOpen, setDashCreateProfileModalOpen] = useState(false);
    const [dashEditProfileModalOpen, setDashEditProfileModalOpen] = useState(false);
    const [dashDeleteProfileModalOpen, setDashDeleteProfileModalOpen] = useState(false);
    const [dashViewProfileModalOpen, setDashViewProfileModalOpen] = useState(false);

    const handleCrudCreateProfile = async (e) => {
        e.preventDefault();

        crudSettingsRef.current.style.display = 'none';
        setDashCreateProfileModalOpen(true);
        setDashEditProfileModalOpen(false);
        setDashDeleteProfileModalOpen(false);
        setDashViewProfileModalOpen(false);
    }

    const handleCrudEditProfile = async (e) => {
        e.preventDefault();

        crudSettingsRef.current.style.display = 'none';
        setDashCreateProfileModalOpen(false);
        setDashEditProfileModalOpen(true);
        setDashDeleteProfileModalOpen(false);
        setDashViewProfileModalOpen(false);
    }

    const handleCrudDeleteProfile = async (e) => {
        e.preventDefault();

        crudSettingsRef.current.style.display = 'none';
        setDashCreateProfileModalOpen(false);
        setDashEditProfileModalOpen(false);
        setDashDeleteProfileModalOpen(true);
        setDashViewProfileModalOpen(false);
    }

    const handleCrudViewProfile = async (e) => {
        e.preventDefault();

        crudSettingsRef.current.style.display = 'none';
        setDashCreateProfileModalOpen(false);
        setDashEditProfileModalOpen(false);
        setDashDeleteProfileModalOpen(false);
        setDashViewProfileModalOpen(true);
    }

    return (
        <div>
            <ModalUser ref={crudSettingsRef}>
                <FooterSubTitle>{t("dash_settings")}</FooterSubTitle>
                <br/>
                <SmallHandlerColContainerDiv>
                    <div>
                        <RightSideButton onClick={handleCrudCreateProfile}>
                            <span style={{fontSize:'30px'}}><MdOutlineSave /></span>
                            {t("dash_create_profile")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudEditProfile}>
                            <span style={{fontSize:'30px'}}><MdModeEdit /></span>
                            {t("dash_edit_profile")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudDeleteProfile}>
                            <span style={{fontSize:'30px'}}><MdDelete /></span>
                            {t("dash_delete_profile")}
                        </RightSideButton>
                    </div>
                    <br/>
                    <div>
                        <RightSideButton onClick={handleCrudViewProfile}>
                            <span style={{fontSize:'30px'}}><MdPreview /></span>
                            {t("dash_view_profile")}
                        </RightSideButton>
                    </div>
                </SmallHandlerColContainerDiv>
            </ModalUser>
            {/**Create Profile */}
            {dashCreateProfileModalOpen && (
                <CreateProfile />
            )}

            {/**Edit Profile */}
            {dashEditProfileModalOpen && (
                <EditProfile />
            )}

            {/**Delete Profile */}
            {dashDeleteProfileModalOpen && (
                <DeleteProfile />
            )}

            {/**View Profile */}
            {dashViewProfileModalOpen && (
                <ViewProfile />
            )}
        </div>
    )
}

export default CrudSettings
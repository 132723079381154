import React from 'react';
//
import { GiPreviousButton, GiNextButton } from "react-icons/gi";
//
import { TableButton, ModalSearchContainer, ModalUser, } from '../../ComponentStyles';

function SearchPagination({ nPages, currentPage, setCurrentPage }) {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

    const goToNextPage = () => {
        if (currentPage < nPages) setCurrentPage(currentPage + 1);
    }

    const goToPrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    }

    return (
        <div>
            <ModalUser>
                <ModalSearchContainer>
                    <div>
                        <TableButton 
                            onClick={goToPrevPage}
                            disabled={currentPage === 1}
                        >
                            <GiPreviousButton/>
                        </TableButton>
                    </div>
                    <div style={{display:"none"}}>
                        {pageNumbers.map(pgNumber => (
                            <TableButton 
                                key={pgNumber} 
                                className= {`page-item ${currentPage === pgNumber ? 'active' : ''} `}
                                onClick={() => setCurrentPage(pgNumber)}
                            >
                                {pgNumber}   
                            </TableButton>
                        ))}
                    </div>
                    <div>
                        <TableButton 
                            onClick={goToNextPage}
                        >
                            <GiNextButton />
                        </TableButton>
                    </div>
                </ModalSearchContainer>
            </ModalUser>
        </div>
    )
}

export default SearchPagination
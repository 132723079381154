import React from 'react';
//
import { OpenSideButton, CountryModalName } from '../ReusableStyles';

function OpenButton({ iconLink, openText, onConfirm }) {
    return (
        <div>
            <OpenSideButton onClick={onConfirm}>
                <span style={{ fontSize:'30px' }}>{iconLink}</span>
                <CountryModalName>{openText}</CountryModalName>
            </OpenSideButton>
        </div>
    )
}

export default OpenButton
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
//import country button
import CountryButton from '../reusablecomponents/buttons/CountryButton';
//country flags
import Gambia from '../countries/countryflag/gambia_flag.png';
import Ghana from '../countries/countryflag/ghana_flag.png';
import Liberia from '../countries/countryflag/liberia_flag.png';
import Nigeria from '../countries/countryflag/nigeria_flag.png';
import Sierraleone from '../countries/countryflag/sierraleone_flag.png';
//
import { retrieveCodeAndName } from '../../helperfunctions/utilityfunctions/CountryUtils';
//
import { HideFooter, AlertModal } from '../../helperfunctions/Helper';
//
import PopUpBackButton from '../reusablecomponents/buttons/PopUpBackButton';
import PopUpForwardButton from '../reusablecomponents/buttons/PopUpForwardButton';
//
import { 
    PrincipalScroller,
    //PrincipalIntro, 
    PrincipalSubTitle,
    HandlerContainerDiv,
} from '../ComponentStyles';

function EnglishCountries() {
    const {t} = useTranslation();

    //alert
    const elementOutId = "modaloutalertenglishcountries";
    const elementDropId = "backdropoutalertenglishcountries";
    const buttonHandler = "ouiHandlerAlertEnglishCountriesBtn";

    const navigateEnglishCountries = useNavigate();

    //gambia
    const handleGambia = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "gambie";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateEnglishCountries('/starting');
            HideFooter();
        }
    };

    //ghana
    const handleGhana = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "ghana";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateEnglishCountries('/starting');
            HideFooter();
        }
    }

    //liberia
    const handleLiberia = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "liberia";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateEnglishCountries('/starting');
            HideFooter();
        }
    }

    //nigeria
    const handleNigeria = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "nigeria";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateEnglishCountries('/starting');
            HideFooter();
        }
    }

    //sierraleone
    const handleSierraleone = async (e) => {
        e.preventDefault();

        var countryInfo = [];
        //
        const englishCountry = "sierraleone";
        const codepaysDiv = document.getElementById('codepays');
        codepaysDiv.innerText = englishCountry;
        countryInfo = retrieveCodeAndName(englishCountry);
        if(countryInfo){
            const codecountryDiv = document.getElementById('codecountry');
            const namecountryDiv = document.getElementById('namecountry');
            const prefixecountryDiv = document.getElementById('prefixecountry');
            const languecountryDiv = document.getElementById('languecountry');
            //
            codecountryDiv.innerText = countryInfo.code;
            namecountryDiv.innerText = countryInfo.name;
            prefixecountryDiv.innerText = countryInfo.prefixe;
            languecountryDiv.innerText = countryInfo.langue;
            //routing
            navigateEnglishCountries('/starting');
            HideFooter();
        }
    }

    const goBackHandler = async (e) => {

        navigateEnglishCountries('/');
        //window.location.reload(false);
        document.getElementById('selectlangue').selectedIndex = 0;
    }

    const goNextHandler = async () => {

        const choixlangueDiv = document.getElementById('choixlangue').innerText;
        const namecountryDiv = document.getElementById('namecountry').innerText;
        const englishCountry = namecountryDiv;
        //
        if(!namecountryDiv){
            if(!choixlangueDiv){
                const storedLangue = localStorage.getItem('i18nextLng');
                document.getElementById('choixlangue').innerText = storedLangue;
                if(!storedLangue){
                    const pTextePays = t("principaltexte1");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    if(!englishCountry){
                        const pTextePays = t("principaltexte2");
                        AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                    }else{
                        navigateEnglishCountries('/starting');
                    }
                }
            }else{
                if(!englishCountry){
                    const pTextePays = t("principaltexte2");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else if(!choixlangueDiv){
                    const pTextePays = t("principaltexte1");
                    AlertModal(elementOutId,elementDropId,pTextePays,buttonHandler);
                }else{
                    navigateEnglishCountries('/starting');
                }
            }
        }else{
            navigateEnglishCountries('/starting'); 
        }
    }

    return (
        <div id='englishcountries'>
            <PrincipalScroller>
                <br/>
                <PrincipalSubTitle>{t("principaltexte2")}</PrincipalSubTitle>
                {/*<br/>
                <PrincipalIntro>{t("select_country_texte1")}</PrincipalIntro>*/}
                <br/>
                <div style={{display:'block',textAlign:'center'}}>
                    <CountryButton 
                        url={Gambia}
                        countryName="Gambie"
                        onConfirm={handleGambia}
                    />
                    <CountryButton 
                        url={Ghana}
                        countryName="Ghana"
                        onConfirm={handleGhana}
                    />
                    <CountryButton 
                        url={Liberia}
                        countryName="Liberia"
                        onConfirm={handleLiberia}
                    />
                    <CountryButton 
                        url={Nigeria}
                        countryName="Nigeria"
                        onConfirm={handleNigeria}
                    />
                    <CountryButton 
                        url={Sierraleone}
                        countryName="Sierraleone"
                        onConfirm={handleSierraleone}
                    />
                </div>
                <br/><br/>
                <HandlerContainerDiv>
                    <div>
                        <PopUpBackButton onClick={goBackHandler}></PopUpBackButton>
                    </div>
                    <div>
                        <PopUpForwardButton onClick={goNextHandler}></PopUpForwardButton>
                    </div>
                    
                </HandlerContainerDiv>
                <br/>
                <div id="modaloutalertenglishcountries"></div>
                <div id="backdropoutalertenglishcountries"></div>
            </PrincipalScroller>
        </div>
    )
}

export default EnglishCountries
import countryData from '../../jsonutils/country.json';
import sellData from '../../jsonutils/sellPrice.json';
import monthData from '../../jsonutils/month.json';

// Function to retrieve country code and name and name based on selected code
export const retrieveCodeAndName = (selectedCode) => {
    // Find the country object with the matching code
    const selectedCountry = countryData.find((country) => country.code === selectedCode);
    if(selectedCountry){
        const { code, name, prefixe, langue } = selectedCountry;
        return { code, name, prefixe, langue };
    }
}

// Function to retrieve tabale to query based on selected code
export const retrieveTableQuery = (selectedCode) => {
    // Find the country object with the matching code
    const selectedCountry = countryData.find((country) => country.code === selectedCode);
    if(selectedCountry){
        const { table } = selectedCountry;
        return { table };
    }
}

// Function to retrieve laguage prefix to query based on selected code
export const retrieveLanguageQuery = (selectedCode) => {
    // Find the country object with the matching code
    const selectedCountry = countryData.find((country) => country.name === selectedCode);
    if(selectedCountry){
        const { langue } = selectedCountry;
        return { langue };
    }
}

// Function to retrieve laguage prefix to query based on selected code
export const retrievePrefixeQuery = (selectedCode) => {
    // Find the country object with the matching code
    const selectedCountry = countryData.find((country) => country.name === selectedCode);
    if(selectedCountry){
        const { prefixe } = selectedCountry;
        return { prefixe };
    }
}

// Function to retrieve codecountry to query based on selected code
export const retrieveCodeCountryQuery = (selectedCode) => {
    // Find the country object with the matching code
    const selectedCountry = countryData.find((country) => country.name === selectedCode);
    if(selectedCountry){
        const { code } = selectedCountry;
        return { code };
    }
}

//******Orders calculation*******
// Function to retrieve order code country to query based on selected code
export const retrieveOrderCodeCountryQuery = (selectedCode) => {
    // Find the country object with the matching code
    const selectedCountry = sellData.find((country) => country.name === selectedCode);
    if(selectedCountry){
        const { code } = selectedCountry;
        return { code };
    }
}

// Function to retrieve days validity to query based on selected code
export const retrieveSellDaysQuery = (selectedCode, selectedPlan) => {
    // Find the country object with the matching code
    const selectedCountry = sellData.find((country) => country.code === selectedCode);
  
    if (selectedCountry) {
      // Check if the selected plan exists in sell1, sell2, or sell3
      const { sell1, sell2, sell3, sell4, sell5 } = selectedCountry;
      let selectedDays = null;
  
      if (sell1.plan === selectedPlan) {
        selectedDays = sell1.days;
      } else if (sell2.plan === selectedPlan) {
        selectedDays = sell2.days;
      } else if (sell3.plan === selectedPlan) {
        selectedDays = sell3.days;
      } else if (sell4.plan === selectedPlan) {
        selectedDays = sell4.days;
      }else if (sell5.plan === selectedPlan){
        selectedDays = sell5.days;
      }
  
      return selectedDays;
    }
  
    // Return null or an appropriate default value if no match is found
    return null;
};

// Function to retrieve prices to query based on selected code
export const retrievePriceQuery = (selectedCode, selectedPlan) => {
    // Find the country object with the matching code
    const selectedCountry = sellData.find((country) => country.code === selectedCode);
  
    if (selectedCountry) {
      // Check if the selected plan exists in sell1, sell2, or sell3
      const { sell1, sell2, sell3, sell4 } = selectedCountry;
      let selectedPrices = null;
  
      if (sell1.plan === selectedPlan) {
        selectedPrices = sell1.price;
      } else if (sell2.plan === selectedPlan) {
        selectedPrices = sell2.price;
      } else if (sell3.plan === selectedPlan) {
        selectedPrices = sell3.price;
      } else if (sell4.plan === selectedPlan) {
        selectedPrices = sell4.price;
      }
  
      return selectedPrices;
    }
  
    // Return null or an appropriate default value if no match is found
    return null;
};

// Function to retrieve country to query based on selected code
export const retrieveCountryQuery = (selectedCode, selectedPlan) => {
    // Find the country object with the matching code
    const selectedCountry = sellData.find((country) => country.code === selectedCode);
  
    if (selectedCountry) {
      // Check if the selected plan exists in sell1, sell2, or sell3
      const { sell1, sell2, sell3, sell4 } = selectedCountry;
      let selectedCountryPercent = null;
  
      if (sell1.plan === selectedPlan) {
        selectedCountryPercent = sell1.country;
      } else if (sell2.plan === selectedPlan) {
        selectedCountryPercent = sell2.country;
      } else if (sell3.plan === selectedPlan) {
        selectedCountryPercent = sell3.country;
      } else if (sell4.plan === selectedPlan) {
        selectedCountryPercent = sell4.country;
      }
  
      return selectedCountryPercent;
    }
  
    // Return null or an appropriate default value if no match is found
    return null;
};

// Function to retrieve city to query based on selected code
export const retrieveCityQuery = (selectedCode, selectedPlan) => {
  // Find the country object with the matching code
  const selectedCity = sellData.find((country) => country.code === selectedCode);

  if (selectedCity) {
    // Check if the selected plan exists in sell1, sell2, or sell3
    const { sell1, sell2, sell3, sell4 } = selectedCity;
    let selectedCityPercent = null;

    if (sell1.plan === selectedPlan) {
      selectedCityPercent = sell1.city;
    } else if (sell2.plan === selectedPlan) {
      selectedCityPercent = sell2.city;
    } else if (sell3.plan === selectedPlan) {
      selectedCityPercent = sell3.city;
    } else if (sell4.plan === selectedPlan) {
      selectedCityPercent = sell4.city;
    }

    return selectedCityPercent;
  }

  // Return null or an appropriate default value if no match is found
  return null;
};

// Function to retrieve city to query based on selected code
export const retrieveResellerQuery = (selectedCode, selectedPlan) => {
  // Find the country object with the matching code
  const selectedCity = sellData.find((country) => country.code === selectedCode);

  if (selectedCity) {
    // Check if the selected plan exists in sell1, sell2, or sell3
    const { sell1, sell2, sell3, sell4 } = selectedCity;
    let selectedResellerPercent = null;

    if (sell1.plan === selectedPlan) {
      selectedResellerPercent = sell1.reseller;
    } else if (sell2.plan === selectedPlan) {
      selectedResellerPercent = sell2.reseller;
    } else if (sell3.plan === selectedPlan) {
      selectedResellerPercent = sell3.reseller;
    } else if (sell4.plan === selectedPlan) {
      selectedResellerPercent = sell4.reseller;
    }

    return selectedResellerPercent;
  }

  // Return null or an appropriate default value if no match is found
  return null;
};

// Function to retrieve month details based on selected id
export const retrieveMonthDetails = (selectedMonth) => {
  // Find the country object with the matching code
  const selectedMonthly = monthData.find((monthly) => monthly.month === selectedMonth);
  if(selectedMonthly){
    const { details } = selectedMonthly;
    let selectMois = null;
    selectMois = details.mois;
    return selectMois;
  }
}

// Function to retrieve month details based on selected id
export const retrieveMonthDebutDetails = (selectedMonth) => {
  // Find the country object with the matching code
  const selectedMonthly = monthData.find((monthly) => monthly.month === selectedMonth);
  if(selectedMonthly){
    const { details } = selectedMonthly;
    let selectDebut = null;
    selectDebut = details.debut;
    return selectDebut;
  }
}

// Function to retrieve month details based on selected id
export const retrieveMonthFinDetails = (selectedMonth) => {
  // Find the country object with the matching code
  const selectedMonthly = monthData.find((monthly) => monthly.month === selectedMonth);
  if(selectedMonthly){
    const { details } = selectedMonthly;
    let selectFin = null;
    selectFin = details.fin;
    return selectFin;
  }
}
import React from 'react';

//
import { 
    CardWrapper,
    CardTextWrapper,
    CardTextTitle,
    CardTextDate,
} from '../../ComponentStyles';

function ListCard({ title, leveltext, typetext, datetext, timetext }) {
    return (
        <div>
            <CardWrapper>
                <CardTextWrapper>
                    <CardTextTitle>{title}</CardTextTitle>
                    <CardTextDate>{datetext} : {timetext}</CardTextDate>
                    <CardTextTitle>{leveltext} : {typetext}</CardTextTitle>
                </CardTextWrapper>
            </CardWrapper>
        </div>
    )
}

export default ListCard